import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { store } from "../../../redux/oldStore";
import { IState, ReduxMap } from "../../../redux/redux";
import { ServerResponse } from "../../../utils/Server";
import { IJudge } from "../../JudgingDashboard/Dashboard";
import { ICategoryCaptainInfoSummary, IJudgeInfoSummary, IListItem } from "./CategoryCaptainTypes";

interface IAssignJudgesToCategoryWizardProps extends IState{

}

interface icategoryStat {
    id:number;
    numberOfProjects:number;
    numberOfJudges:number;
    name:string;
}

const AssignJudgesToCategoryWizard = (props:IAssignJudgesToCategoryWizardProps) =>{
    const [info, setInfo] = useState<ICategoryCaptainInfoSummary>();
    const [categoryStats, setCategoryStats] = useState<Record<number,icategoryStat>>({});
    const [stats, setStats] = useState<icategoryStat[]>([]);

    useEffect(()=>{
        init();
    },[]);

    
    useEffect(()=>{
        let stats:Record<number,icategoryStat> = {};

        if(info && info.Judges){

            let list:icategoryStat[] = [];
            info.ProjectsByCategoryStats.forEach(x=>{
                stats[x.CategoryId]={id:+x.CategoryId, numberOfJudges:0,numberOfProjects:x.Number,name:x.CategoryName};
                list.push(stats[x.CategoryId]);
            });
            
            info.Judges.forEach(x=>{
                if(x.AssignedCategory && stats[x.AssignedCategory]){
                    stats[x.AssignedCategory].numberOfJudges++;
                }
            });

            setCategoryStats(stats);
            setStats(list);
        }
    },[info, info?.Judges]);

    const init = () =>{
        store.server
        .getApi<ServerResponse<ICategoryCaptainInfoSummary>>("../JudgingCaptain/InfoSummary")
        .then((x) => {
          if (x.Success) {
            setInfo(x.Value);
          } else {
            toast.error(x.Message, { autoClose: false });
          }
        });
    };

    const updateCategory = (judge:IJudgeInfoSummary, category:number) =>{
        store.server
        .postApi<ServerResponse<boolean>>("../JudgingCaptain/AssignCategory", {judgeId:judge.PersonId, categoryId:category})
        .then((x) => {
          if (x.Success) {
            let judges = [...info?.Judges ?? []];
            for(let i = 0; i < judges.length; i++){
                let j = judges[i];
                if(j.PersonId === judge.PersonId){
                    j.AssignedCategory = category;
                    break;
                }
            }
            if(info) setInfo({...info,Judges:judges});
          } else {
            toast.error(x.Message, { autoClose: false });
          }
        });
    };

    const buildJudge = (judge:IJudgeInfoSummary, i:number) =>{
        return <div key={`judge-sum-${i}`} className="judge-info">
            <div className="flex-between">
            <span className="name">
            {judge.LastName}, {judge.FirstName}
            </span>
            {/* <td>{judge.Email}</td>
            <td>{judge.Phone}</td> */}
            <div>
                <select className="form-control"
                    value={judge.AssignedCategory?.toString() ?? '0'}
                    onChange={(e)=>{
                        updateCategory(judge, +e.target.value);
                    }}
                >
                    <option value="0">select</option>
                    {info?.Categories.map((x,i)=>{
                        return <option key={`${judge.PersonId}-cate-id-${i}`} value={x.id}>{x.name}</option>
                    })}
                </select>
                </div>
            </div>
            <div className="judge-prefs">
            <span>{info?.CategoryDic[judge.Category1]}</span>
            <span>{info?.CategoryDic[judge.Category2]}</span>
            <span>{info?.CategoryDic[judge.Category3]}</span>
            </div>
        </div>
    }
    
    return (<>
    <div className="judge-assign-cate-title-bar sticky">
    <h3>Assign Judges to Their Category</h3>
    <div className="stats">
        {stats.map((x,i)=>{
            return <div key={`jactb-s-${i}`}><strong>{x.name}</strong> <span>Judges: {x.numberOfJudges} Projects: {x.numberOfProjects}</span></div>
        })}
    </div>
    </div>
        

        <div className="assign-judge-wiz">
            {/* <div className="search-bar">
                search goes here
            </div> */}
            <div className="judge-list">
                {info && <>
                {info?.Judges?.map(buildJudge)}
                </>
                }
            </div>
        </div>
    </>);
};

export default ReduxMap(AssignJudgesToCategoryWizard);