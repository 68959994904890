import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { store } from '../../redux/oldStore';
import { ServerResponse } from '../../utils/Server';
import Icon, { IconType } from '../Icon/Icon';

interface IUserMiscellaneousProps {
  UserId: string;
  CanSave: boolean;
}

interface IMiscResponse {
  CanRemoveSignatures: boolean;
  MediaRelease: ISignResponse;
  HoldHarmless: ISignResponse;
}

interface ISignResponse {
  Enabled: boolean;
  Signed: boolean;
  At: string;
  Notes: string;
  Approved: boolean;
  Link: string;
  Signer: string;
  EntryId: number;
}

const UserMiscellaneous = (props: IUserMiscellaneousProps) => {
  const [data, setData] = useState<IMiscResponse>();

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    if (props.UserId)
      store.server.postApi<ServerResponse<IMiscResponse>>(`../UserProfile/Miscellaneous`, { userId: props.UserId }).then((x) => {
        if (x.Success) {
          setData(x.Value);
        } else {
          toast.error(`Error getting information: ${x.Message}`, { autoClose: false });
        }
      });
  };

  const RequestSignature = (type: string) => {
    store.server.postApi<ServerResponse<IMiscResponse>>(`../UserProfile/RequestSignature`, { userId: props.UserId, type: type }).then((x) => {
      if (x.Success) {
        toast.success(`Signature requested!`);
      } else {
        toast.error(`Error requesting signature: ${x.Message}`, { autoClose: false });
      }
    });
  };

  const RemoveSignature = (type: string, entryId: number) => {
    if(!window.confirm(`Are you sure you want to remove this signature? \r\nThis cannot be undone.`)){
        return;
    }
    store.server.postApi<ServerResponse<IMiscResponse>>(`../UserProfile/RemoveSignature`, { userId: props.UserId, type: type, entryId: entryId }).then((x) => {
      if (x.Success) {
        init();
        toast.success(`Signature removed!`);
      } else {
        toast.error(`Error requesting signature: ${x.Message}`, { autoClose: false });
      }
    });
  };

  return (
    <>
      <h4>Miscellaneous User Information</h4>
      <hr />
      {data?.HoldHarmless.Enabled && (
        <div>
          <h3>Hold Harmless</h3>
          {data.HoldHarmless.Signed ? (
            <div className="alert alert-success flex-between">
              <div>
                <h3>
                  <Icon type={IconType.checkSquareO} /> Signed: {data.HoldHarmless.At}
                </h3>
                {data.HoldHarmless.Signer && <div>
                  by <strong>{data.HoldHarmless.Signer}</strong>
                </div>}
                <div>{data.HoldHarmless.Notes}</div>
                {data.HoldHarmless.Link && (
                  <div>
                    <a href={data.HoldHarmless.Link} target="_blank">
                      View Siganture
                    </a>
                  </div>
                )}
              </div>
              <div>
                {data.CanRemoveSignatures && <button type='button' className='btn btn-xs btn-danger' onClick={()=>{
                    RemoveSignature('HoldHarmless', data.HoldHarmless.EntryId);
                }
                    }>
                    <Icon type={IconType.trashO} /> Remove Signature
                </button>}
              </div>
            </div>
          ) : (
            <div className="alert alert-danger flex-between">
              <div>
                <h3>
                  <Icon type={IconType.warning} /> Not Signed.
                </h3>
                {data.HoldHarmless.Link && (
                  <div>
                    <strong>
                      {data.HoldHarmless.Link}{' '}
                      <button
                        type="button"
                        className="btn btn-xs btn-secondary"
                        onClick={() => {
                          window.navigator.clipboard.writeText(`${data.HoldHarmless.Link}`);
                        }}
                        title={`Click to copy link`}>
                        <Icon type={IconType.copy} /> Copy Link
                      </button>
                    </strong>
                  </div>
                )}
              </div>
              <div>
                <button
                  type="button"
                  onClick={() => {
                    RequestSignature('HoldHarmless');
                  }}
                  className="btn btn-secondary"
                  title="Request hold harmless again.">
                  <Icon type={IconType.sign} /> Request
                </button>
              </div>
            </div>
          )}
        </div>
      )}
      {data?.MediaRelease.Enabled && (
        <div>
          <h4>Media Release</h4>
          {data.MediaRelease.Signed ? (
            <div className="alert alert-success flex-between">
              <div>
                <div>
                  <h3>
                    <Icon type={IconType.checkSquareO} /> Signed: {data.MediaRelease.At}
                  </h3>
                </div>
                {data.MediaRelease.Signer && <div>
                  by <strong>{data.MediaRelease.Signer}</strong>
                </div>}
                {data.MediaRelease.Approved ? (
                  <div>
                    <Icon type={IconType.checkSquareO} /> Allowed{' '}
                  </div>
                ) : (
                  <div className="text-danger">
                    <Icon type={IconType.squareO} /> Not Allowed
                  </div>
                )}
                <div>{data.MediaRelease.Notes}</div>
              </div>
              <div>
                {data.CanRemoveSignatures && <button type='button' className='btn btn-xs btn-danger' onClick={()=>{
                    RemoveSignature('MediaRelease', data.MediaRelease.EntryId);
                }
                    }>
                    <Icon type={IconType.trashO} /> Remove Signature
                </button>}
              </div>
            </div>
          ) : (
            <div className="alert alert-danger flex-between">
              <div>
                <h3>
                  <Icon type={IconType.warning} /> Not Signed.
                </h3>
                {data.MediaRelease.Link && (
                  <div>
                    <strong>
                      {data.MediaRelease.Link}{' '}
                      <button
                        type="button"
                        className="btn btn-xs btn-secondary"
                        onClick={() => {
                          window.navigator.clipboard.writeText(`${data.MediaRelease.Link}`);
                        }}
                        title={`Click to copy link`}>
                        <Icon type={IconType.copy} /> Copy Link
                      </button>
                    </strong>
                  </div>
                )}
              </div>
              <div>
                <button
                  type="button"
                  onClick={() => {
                    RequestSignature('MediaRelease');
                  }}
                  className="btn btn-secondary"
                  title="Request media release again.">
                  <Icon type={IconType.sign} /> Request
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default UserMiscellaneous;
