import React, { FunctionComponent, useState, useMemo, useEffect } from 'react';
import { project, fileType, uploadedFile, button, updateFileHookDef, uploadFileHookDef, requirementUpdateHookDef, fileRemovedHookDef, paperworkProjectsWrapper } from './paperworkTypes';
import { buildButtons, getFileStatusClass, getFileStatusIconClass, getPacketStatusIconClass, projectHeader } from './paperworkReviewUtilities';
import './paperworkStyles.css';
import Modal from './Modal';
import FileUploadComponent from './FileUploadComponent';
import FileViewComponent from './FileViewComponent';
import ManageProjectPaperworkComponent from './ManageProjectPaperworkComponent';
import { Route } from 'react-router-dom';
import Loading from '../LoadingScreen/loading';
import { toast } from 'react-toastify';
import StoreX from '../../redux/oldStore';
import { ServerResponse } from '../../utils/Server';
import Icon, { IconType } from '../Icon/Icon';
import GenericFormsLanding from '../GenericForms/GenericFormsLanding';
import { IState } from '../../redux/redux';
import Modal1 from '../../Components/Modal/Modal';

type listItemProps = {
  state: IState;
  project: project;
  fileTypes: fileType[];
  baseUrl: string;
  fileUploadHook: uploadFileHookDef;
  fileUpdateHook: updateFileHookDef;
  fileRemovedHook: fileRemovedHookDef;
  requirementUpdateHook: requirementUpdateHookDef;
  projectUpdateHook: Function;
  canUpload: boolean;
  canMarkReady: boolean;
  canMarkReadyToJudge: boolean;
  data: paperworkProjectsWrapper;
  canManageEntryStatus: boolean;
};

type modalData = {
  component: JSX.Element;
  header: JSX.Element;
  selectedFile?: uploadedFile;
  selectedFileType?: number;
};

type listItemState = {
  showModal: boolean;
  modalData: modalData;
  hover: boolean;
};

const ParticipantPaperworkReivewProjectListItem: FunctionComponent<listItemProps> = (props: listItemProps) => {
  const { project, fileTypes, baseUrl, fileUploadHook, fileUpdateHook, fileRemovedHook, requirementUpdateHook, projectUpdateHook, canUpload, canMarkReady, canMarkReadyToJudge } = props;

  const [showManageStatus, setShowManageStatus] = useState<boolean>(false);
  const [entryStatusValue, setEntryStatusValue] = useState<string>();
  const [buttons, setButtons] = useState<button[]>([]);

  useEffect(() => {
    setEntryStatusValue(props.project.EntryStatus);
  }, [props.project]);

  const SaveNewStatus = () => {
    //todo call server and o stuff....
    StoreX.instance.server
      .postApi<ServerResponse<any>>(`../PaperworkReview/SetStatus`, {
        status: entryStatusValue,
        projectId: project.ProjectIntId,
      })
      .then((x) => {
        if (x.Success) {
          let p = project;
          p.EntryStatus = entryStatusValue ?? 'Pending';
          projectUpdateHook(p);
          setShowManageStatus(false);
        } else {
          toast.error(x.Message, { autoClose: false });
        }
      });
  };

  const initialState: listItemState = {
    modalData: {
      component: <div>...</div>,
      header: <>File Review</>,
    },
    showModal: false,
    hover: false,
  };

  const [myState, setMyState] = useState(initialState);
  const [myFileTypes, setMyFileTypes] = useState<fileType[]>([]);

  useEffect(() => {
    var types = fileTypes.filter((x) => project.Files.find((r) => r == x.id));
    setMyFileTypes([...types]);

    let btns = buildButtons(project.UploadedFiles, myFileTypes, project.FileTypeRequirementOverrides, false
      , props.data, project, project.formSummary, project.requiredFiles);
      setButtons(btns ?? []);

  }, [fileTypes, project]);


  const toggleReadyForReview = (evt) => {
    evt.stopPropagation();

    if (!canMarkReady) {
      return;
    }
    StoreX.instance.server
      .postApi<ServerResponse<any>>(`../PaperworkReview/PaperworkReadyForReview`, {
        ready: !project.PaperworkReadyForReview,
        projectId: project.ProjectIntId,
      })
      .then((x) => {
        if (x.Success) {
          let p = project;
          p.PaperworkReadyForReview = !project.PaperworkReadyForReview;
          projectUpdateHook(p);
        } else {
          toast.error(x.Message, { autoClose: false });
        }
      });
  };

  const toggleApproveForJudging = (evt) => {
    evt.stopPropagation();

    if (!canMarkReadyToJudge) {
      return;
    }

    StoreX.instance.server
      .postApi<ServerResponse<any>>(`../PaperworkReview/ReadyForJudging`, {
        ready: !project.ReadyForJudging,
        projectId: project.ProjectIntId,
      })
      .then((x) => {
        if (x.Success) {
          let p = project;
          p.ReadyForJudging = !project.ReadyForJudging;
          projectUpdateHook(p);
        } else {
          toast.error(x.Message, { autoClose: false });
        }
      });
  };

  const fileUpdate = (file: uploadedFile) => {
    for (let i = 0; i < project.UploadedFiles.length; i++) {
      if (project.UploadedFiles[i].id === file.id) {
        project.UploadedFiles[i] = file;
        break;
      }
    }

    let files: any[] = [];

    project.UploadedFiles.forEach((x: any) => {
      files.push(x);
    });

    fileUpdateHook(file);
  };
  const fileRemoved = (file: uploadedFile) => {
    let files: any[] = [];

    project.UploadedFiles.forEach((x: any) => {
      if (file.id !== x.id) {
        files.push(x);
      }
    });
    fileRemovedHook(file);
  };

  const fileUpload = () => {
    fileUploadHook();
  };

  const getFile = (fileId: number) => {
    return project.UploadedFiles.filter(function (f) {
      return f.id === fileId;
    })[0];
  };

  const displayFileUploadModal = (button: button, evt: React.MouseEvent) => {
    evt.stopPropagation();

    if (button.Packet) {
      
      setMyState({
        ...myState,
        modalData: {
          component: (
            <div className="full-screen-preview">
              <div>
                <GenericFormsLanding
                  isReview={true}
                  packetId={button.Packet}
                  selectedFileId={button.fileId}
                  resultsId={button.resultsId}
                  ownerId={project.ProjectKey}
                  isReviewer={true}
                  mode='review'
                  statusUpdated={(status) => {
                    let p = project;
                    let form = p.formSummary?.find((x) => x.PacketId === button.PacketId);
                    if (form) form.Status = status;
                    projectUpdateHook(p);
                  }}
                  close={closeModalHook}
                />
              </div>
            </div>
          ),
          header: projectHeader(project),
          selectedFile: undefined,
          selectedFileType: button.fileType,
        },
        showModal: true,
      });

      return;
    }

    if (button.fileId && +(button.fileId) > -1) {
      const selectedFile = getFile(+(button.fileId));
      setMyState({
        ...myState,
        modalData: {
          component: (
            <FileViewComponent
              filetypes={myFileTypes}
              fileX={getFile(+button.fileId)}
              closeModalHook={closeModalHook}
              fileUpdateHook={fileUpdate}
              fileUploadHook={fileUpload}
              fileRemovedHook={fileRemoved}
              project={project}
            />
          ),
          header: projectHeader(project),
          selectedFile: selectedFile,
          selectedFileType: button.fileType,
        },
        showModal: true,
      });
    } else if (canUpload) {
      setMyState({
        ...myState,
        modalData: {
          component: (
            <FileUploadComponent
              fileTypes={myFileTypes}
              fileId={+(button.fileId || '0')}
              selectedFileTypeId={button.fileType}
              project={project}
              closeModalHook={closeModalHook}
              fileUploadedHook={fileUploadHook}
            />
          ),
          header: projectHeader(project),
          selectedFileType: button.fileType,
        },
        showModal: true,
      });
    } else {
      toast.warning('Information freeze is in effect, you cannot upload files at this time.');
    }
  };

  const displayProjectModal = (evt: React.MouseEvent) => {
    evt.stopPropagation();
    setMyState({
      ...myState,
      modalData: {
        component: (
          <ManageProjectPaperworkComponent
            fileTypes={myFileTypes}
            project={project}
            fileUpdateHook={fileUpdateHook}
            fileUploadHook={fileUploadHook}
            fileRemovedHook={fileRemovedHook}
            requirementUpdateHook={requirementUpdateHook}
            parentCloseModalHook={closeModalHook}
          />
        ),
        header: projectHeader(project),
      },
      showModal: true,
    });
  };

  const downloadProjectFiles = async (evt: React.MouseEvent) => {
    evt.stopPropagation();
    document.location.href = baseUrl + `/PaperworkReview/GetCombinedProjectFiles/${project.ProjectIntId}`;
  };

  const turnOnHover = () => {
    setMyState({ ...myState, hover: true });
  };

  const turnOffHover = () => {
    setMyState({ ...myState, hover: false });
  };

  const closeModalHook = () => {
    setMyState({ ...myState, showModal: false });
  };

  const fileAbbrs = {
    'ISEF Form 1  - Adult Sponsor / Safety Assessment': '1',
    'ISEF Form 1A - Checklist / Research Plan': '1A',
    'ISEF Form 1B - Approval': '1B',
    'ISEF Form 1C - Research Institutional / Industrial Setting': '1C',
    'ISEF Form 2  - Qualified Scientist': '2',
    'ISEF Form 3  - Risk Assessment': '3',
    'ISEF Form 4  - Human Subjects & Consent': '4',
    'ISEF Form 5  - Vertebrate Animal': '5',
    'ISEF Form 6A - Biological Agents': '6A',
    'ISEF Form 6B - Human & Vertebrate Animal Tissue': '6B',
    'ISEF Form 7  - Continuation Projects': '7',
    'Project Abstract': 'PA',
    'Project Plan': 'PP',
    '10': '1',
    '11': '1A',
    '12': '1B',
    '19': '1C',
    '20': '2',
    '30': '3',
    '40': '4',
    '41': '4A',
    '50': '5',
    '51': '5',
    '61': '6A',
    '62': '6B',
    '70': '7',
    '111': 'PA',
    '222': 'PP',
  };

  const getFileAbbr = (id: any, abbr: string, filename: any) => {
    if(abbr) return abbr;
    
    let x = fileAbbrs[id] || fileAbbrs[filename] || null; //filename.replace('ISEF','').replace('Form','').replace(/[^A-Z0-9]/g, '');
    if (!x) {
      if(filename.length <= 4) return filename;

      x = filename
        .replace('ISEF', '')
        .replace('Form', '')
        .replace(/[^A-Z0-9]/g, '');
      if (x && x.length > 2) x = x.substring(0, 2);
    }
    return x;
  };

  const entryStatus = (p: project) => {
    switch (p.EntryStatus) {
      case 'Approved':
        return <span className="entry-status">{props.state.Settings?.text.EntryStatusApproved}</span>;

      case 'Dropped':
        return <span className="entry-status">{props.state.Settings?.text.EntryStatusDropped}</span>;
      case 'DoNotAdvance':
        return <span className="entry-status">{props.state.Settings?.text.EntryStatusDNA}</span>;
      case 'Pending':
      default:
        return <span className="entry-status">{props.state.Settings?.text.EntryStatusPending}</span>;
    }
  };

  const entryStatusClass = (p: project) => {
    switch (p.EntryStatus) {
      case 'Approved':
        return 'entry-status-approved';
      case 'Dropped':
        return 'entry-status-dropped';
      case 'DoNotAdvance':
        return 'entry-status-dna';
      case 'Pending':
      default:
        return '';
    }
  };

  return (
    <>
      <div
        className={`project-tile ${project.PaperworkReadyForReview ? 'review-ready' : ''} ${project.ReadyForJudging ? 'judging-ready' : ''} ${entryStatusClass(project)}`}
        // onClick={displayProjectModal}
        style={{ listStyle: 'none' }}
        onMouseOver={turnOnHover}
        onMouseLeave={turnOffHover}>
        <div className={`row `}>
          <div className="col-sm-4">
            <strong>
              {project.ProjectId}: {project.Category.Name}
            </strong>{' '}
            - {project.Title}
            <br />
            <div>
              {project.TeamMembers.map((teamMember, index) => (
                <span className="person-label" key={index}>
                  {teamMember.firstName} {teamMember.lastName}
                </span>
              ))}
            </div>
            <div className="small">{project.School}</div>
          </div>

          <div className={`col-sm-2`}>
            {
              <>
                {(props.canManageEntryStatus || project.EntryStatus) && (
                  <div
                    className="span-button click"
                    title="Change Entry Status"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (props.canManageEntryStatus) {
                        setShowManageStatus(true);
                      }
                    }}>
                    <Icon type={IconType.status} /> {'  '}
                    {entryStatus(project)}
                  </div>
                )}
                { (props.state.Settings?.UsePaperwork ?? false) && <div className="span-button click" data-placement="top" title="Upload file" onClick={displayProjectModal}>
                  {' '}
                  <i className="fal fa-cog" /> manage
                </div>}

                {canMarkReady && <div className="span-button click mark-ready-box" onClick={toggleReadyForReview}>
                  {!project.PaperworkReadyForReview ? (
                    <>
                      <Icon type={IconType.squareO} /> Mark ready
                    </>
                  ) : (
                    <>
                      <Icon type={IconType.checkSquareO} /> Ready for review
                    </>
                  )}
                </div>}
                {canMarkReadyToJudge && (
                  <div className="span-button click approve-for-judging-box" onClick={toggleApproveForJudging}>
                    {!project.ReadyForJudging ? (
                      <>
                        <Icon type={IconType.squareO} /> Approve for judging
                      </>
                    ) : (
                      <>
                        <Icon type={IconType.checkSquareO} /> Ready for judging
                      </>
                    )}
                  </div>
                )}
                {(props.state.Settings?.UsePaperwork ?? false) && <div className="span-button click text-muted download-all-files-btn" data-placement="top" title="Download All Project Files" onClick={downloadProjectFiles}>
                  {' '}
                  <i className="fal fa-cloud-download" /> download all
                </div>}
              </>
            }
          </div>
          <div className='col-sm-6'>
          <div className="uploaded-buttons">
            {buttons.map((button, index) => {
              let isPacket = button.Packet ? (button.fileId??'0') === '---' ? true : false : false;
              if(isPacket) return <></>
              return (
                <div
                  key={index}
                  className={`span-button ${button.fileId ? '' : 'upload-new-file'} isef-${button.ISEFFileID} ${button.Packet ? 'packet' : ''} ` + getFileStatusClass(button.status)}
                  onClick={(evt) => displayFileUploadModal(button, evt)}
                  data-toggle="tooltip"
                  data-placement="top"
                  title={button.fileName}>
                  <i className={button.Packet ? getPacketStatusIconClass(button.status) : getFileStatusIconClass(button.status)}></i>
                  <br />
                  {isPacket ? 'Packet' : getFileAbbr(button.ISEFFileID, button.fileAbbriviation, button.fileName)}
                  
                </div>
              );
            })}
            </div>
            <div className='packet-download-all-links'>
            {buttons.filter((x) => x.Packet && (x.fileId??'0')==='---').map((button, index) => {
              let url = `/genericforms/downloadformgroup/?f=${props.state.Settings?.fairId}&groupId=${button.PacketId}&projectKey=${props.project.ProjectKey}`
              return <div key={`packet-${index}`} 
                  className={`packet-download-all`}>
                    <a href={baseUrl + url} target='_blank'>
                    <i className="fal fa-cloud-download" /> download all {button.fileName}</a>
                  </div>
            })}
            </div>
          </div>
        </div>
      </div>
      {myState.showModal && <Modal header={<span>{myState.modalData.header}</span>} body={myState.modalData.component} footer={<>&nbsp;</>} closeModalHook={closeModalHook}></Modal>}
      {showManageStatus && (
        <Modal1 setModalOpen={setShowManageStatus} title={`Status of ${project.ProjectId}`} size="m">
          <div className="form-horizontal">
            <div className="form-group">
              <label className="col-sm-3 control-label" htmlFor="ppli-ps-status">
                Status
              </label>
              <div className="col-sm-9">
                <select
                  id={`ppli-ps-status`}
                  autoFocus={true}
                  className="form-control"
                  value={entryStatusValue}
                  onChange={(x) => {
                    setEntryStatusValue(x.target.value);
                  }}>
                  {[
                    { value: 'Pending', label: props.state.Settings?.text.EntryStatusPending },
                    { value: 'Approved', label: props.state.Settings?.text.EntryStatusApproved },
                    { value: 'DoNotAdvance', label: props.state.Settings?.text.EntryStatusDNA },
                    { value: 'Dropped', label: props.state.Settings?.text.EntryStatusDropped },
                  ].map((x, i) => {
                    return (
                      <option key={`ppw-es-${i}`} value={x.value}>
                        {x.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="form-group">
              <div className="col-sm-3"></div>
              <div className="col-sm-9">
                <button type={'button'} className="btn btn-secondary" onClick={SaveNewStatus}>
                  <Icon type={IconType.save} /> Save
                </button>
              </div>
            </div>
          </div>
        </Modal1>
      )}
    </>
  );
};

export default ParticipantPaperworkReivewProjectListItem;
