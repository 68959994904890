import React, { useEffect, useState } from "react";
import { GetPeoplePerson } from "../../utils/CommonTypes/PeoplePageTypes";
import Select from "react-select";
import { Selectable } from "../../utils/CommonTypes/CommonTypes";
import Icon, { IconType } from "../Icon/Icon";
import StoreX, { store } from "../../redux/oldStore";
import { toast } from "react-toastify";
import _ from "lodash";
import { RotationTypes } from "pdf-lib";
import { ServerResponse } from "../../utils/Server";

interface LinkProjectProps {
  person: GetPeoplePerson;
  setModalOpen: (open: boolean) => void;
  refreshAll: () => void;
}

interface ProjectInLinkProject {
  Category: string;
  Id: string;
  ProjectId: string;
  Team: boolean;
  Title: string;
}

const LinkProject = (props: LinkProjectProps) => {
  const [allProjects, setAllProjects] = useState<ProjectInLinkProject[]>([]);
  const [projectOptions, setProjectOptions] = useState<Selectable[]>([]);
  const [selectedProject, setSelectedProject] = useState<Selectable>();
  const [addToUser, setAddToUser] = useState<boolean>(false);

  useEffect(() => {
    store.server.getApi("../Project/GetProjectList").then((res: any) => {
      console.log(res);
      let sortedProjects = _.sortBy(res?.Value, "ProjectId");
      setAllProjects(sortedProjects);
    });
  }, []);

  useEffect(() => {
    let mappedProjects = allProjects?.map((p) => {
      return {
        label: `${p.ProjectId} - ${p.Title} ${p.Team ? "(team project)" : ""}`,
        value: p.Id,
      };
    });
    setProjectOptions(mappedProjects);
  }, [allProjects]);

  const handleLinkProjectClick = () => {
    let submitObj = {
      personId: props.person.Id,
      projectId: selectedProject?.value,
      addToUser: addToUser,
    };

    if (!submitObj.projectId) {
      toast.warning(
        `You need to select a project to link ${props.person.FirstName} too.`
      );
      return;
    }

    store.server
      .postApi<ServerResponse<any>>("../Person/LinkProject", submitObj)
      .then((x) => {
        if (x.Success) {
          toast.success("Project linked!");
          props.refreshAll();
          props.setModalOpen(false);
        } else {
          toast.error(`${x.Message}`, {autoClose:false});
        }
      });
  };

  return (
    <div className="make-the-modal-be-bigger">
      <div className="alert alert-default">
        Search for the project you want to link to{" "}
        <strong>
          {props.person.FirstName} {props.person.LastName}
        </strong>
        .
      </div>
      <div className="form-group">
        <label htmlFor="project-link-search" className="col-sm-4 control-label">
          Project Search
        </label>
        <div className="col-sm-8">
          <Select
            id="project-link-search"
            onChange={setSelectedProject}
            value={selectedProject}
            options={projectOptions}
            placeholder={"Choose a project to link"}
            
          />
        </div>
      </div>
      {StoreX.Settings?.AllowMultipleEntries && (
        <div className="form-group">
          <div className="col-sm-8 col-sm-offset-4">
            <input
              type="checkbox"
              id="project-link-search-addtouser"
              onChange={(e) => {
                setAddToUser(e.target.checked);
              }}
              checked={addToUser}
            />
            <label
              htmlFor="project-link-search-addtouser"
              className="control-label"
            >
              Add as additional project for {props.person.FirstName}
            </label>
          </div>
        </div>
      )}
      <div className="bumper-vert-out-l flex-center">
        <button className="btn btn-success" onClick={handleLinkProjectClick}>
          <Icon type={IconType.link} /> Link Project
        </button>
      </div>
    </div>
  );
};

export default LinkProject;
