import {
  SET_PROJECTS
  ,SET_PROJECTS_BY_KEY
  ,SET_CATEGORIES
  ,SET_ROUNDS
  ,SET_SELECTED_ROUND
  ,SET_SELECTED_ROUND_INDEX
  ,SET_PAGE
  ,SET_SELECTED_PROJECT
  ,SET_SELECTED_JUDGE
  ,SET_JUDGES
  ,SET_JUDGES_BY_KEY
  ,SET_ASSIGNMENTS
  ,SET_PROJECTS_ASSIGNMENTS
  ,SET_JUDGES_ASSIGNMENTS
  ,SET_JUDGING_ROUND_EDIT_MODAL_SHOW
  ,SET_ASSIGNMENT_MODAL_SHOW
  ,SET_PROJECTS_BY_CATEGORY
} from './types'


let initialState = {
  projects: [],
  categories: [],
  rounds: [],
  selectedRound: {},
  selectedRoundIndex: null,
  page: 'round',
  selectedProject: {},
  selectedJudge: {},
  judges: [],
  judgesByKey: {},
  projectsByKey: {},
  projectsByCategory: {},
  assignments: [],
  projectsAssignments: {},
  judgesAssignments: {},
  judgingRoundEditModalShow: false,
  assignmentModalShow: false,
}


export default function reducer(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case SET_PROJECTS:
      return Object.assign({}, state, {
        projects: payload
      });
    case SET_CATEGORIES:
      return Object.assign({}, state, {
        categories: payload
      });
    case SET_ROUNDS:
      return Object.assign({}, state, {
        rounds: payload
      });
    case SET_SELECTED_ROUND:
      return Object.assign({}, state, {
        selectedRound: payload
      });
    case SET_SELECTED_ROUND_INDEX:
      return Object.assign({}, state, {
        selectedRoundIndex: payload
      });
    case SET_PAGE:
      return Object.assign({}, state, {
        page: payload
      });
    case SET_SELECTED_PROJECT:
      return Object.assign({}, state, {
        selectedProject: payload
      });
    case SET_SELECTED_JUDGE:
      return Object.assign({}, state, {
        selectedJudge: payload
      });
    case SET_JUDGES:
      return Object.assign({}, state, {
        judges: payload
      });
    case SET_JUDGES_BY_KEY:
      return Object.assign({}, state, {
        judgesByKey: payload
      });
    case SET_PROJECTS_BY_KEY:
      return Object.assign({}, state, {
        projectsByKey: payload
      });
    case SET_PROJECTS_BY_CATEGORY:
      return Object.assign({}, state, {
        projectsByCategory: payload
      });
    case SET_ASSIGNMENTS:
      return Object.assign({}, state, {
        assignments: payload
      });
    case SET_PROJECTS_ASSIGNMENTS:
      return Object.assign({}, state, {
        projectsAssignments: payload
      });
    case SET_JUDGES_ASSIGNMENTS:
      return Object.assign({}, state, {
        judgesAssignments: payload
      });
    case SET_JUDGING_ROUND_EDIT_MODAL_SHOW:
      return Object.assign({}, state, {
        judgingRoundEditModalShow: payload
      });
    case SET_ASSIGNMENT_MODAL_SHOW:
      return Object.assign({}, state, {
        assignmentModalShow: payload
      });
    

    default:
      return state
  }
}