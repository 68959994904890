import { useParams } from "react-router-dom";
import { IState, ReduxMap } from "../../redux/redux";
import SetupWizard from "./SetupWizard";

interface IProps extends IState {}

const ConfigGroupViewer = (props: IProps) => {
    const {groupKey} = useParams();


    return (

        <div>
            <>
            {groupKey && <SetupWizard wizardKey={groupKey} />}
            {!groupKey && <h1>Group Not Found!</h1>}
            </>
        </div>
    )
}

export default ReduxMap(ConfigGroupViewer);