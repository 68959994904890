import React, { useState, useEffect } from "react";
import { store } from "../../redux/oldStore";
import { toast } from "react-toastify";
import Editor from "../GenericForms/Editor";

export default function Setup(props) {
  const [categories, setCategories] = useState<any>(null);
  const [category, setCategory] = useState<any>(null);
  const [preInstructionsEditorState, setpreInstructionsEditorState] = useState('');
  const [postInstructionsEditorState, setPostInstructionsEditorState] = useState('');
  //const [editorState, setEditorState] = useState('');

  useEffect(() => {
    //call sever to get setup information...
    store.server.getApi<any>("../Biliteracy/Setup").then((x) => {
      console.log(x);
      if (x.Success) {
        setCategories(x.Value.categories);
      } else {
        toast.error(x.Message);
      }
    });
  }, []);

  const onEditorStateChanged = (state) => {
    setpreInstructionsEditorState(state);
  };

  const onPostEditorStateChanged = (state) => {
    setPostInstructionsEditorState(state);
  };
  const toHtml = (post?) => {
    if(post){
      return postInstructionsEditorState;
    }
    return preInstructionsEditorState;
  };

  const clickSubmit = () => {
    let c = categories.find((x) => x.id == category.id);
    c.instructions = toHtml();
c.postStartInstructions = toHtml(true);
    store.server
      .postApi<any>("../Biliteracy/SubmitCategoryInstructions", {
        categoryId: c.id,
        Instructions: c.instructions,
        PostStartInstructions: c.postStartInstructions,
      })
      .then((x) => {
        if (x.Success) {
          setCategory(c);
          toast.success(`${c.name} saved.`);
        } else {
          toast.error(x.Message);
        }
      });

    //TODO send to server to save
  };

  const selectCategory = (categoryId) => {
    let c = categories.find((x) => x.id == categoryId);
    setpreInstructionsEditorState(c.instructions);
    setPostInstructionsEditorState(c.postStartInstructions);

    setCategory(c);
  };

  const closeEditor = () => {
    setCategory(null);
  };

  return (
    <>
      <div className="biliteracy-container">
        {categories && !category && (
          <div className="biliteracy-panel">
            <h2>Categories</h2>
            <div>
              Click edit to set the instructions for the selected cateogry.
            </div>
            <div className="head-room-l">
              {categories.map((x, i) => {
                return (
                  <div key={i} className="category">
                    <h4>{x.name}</h4>
                    <button
                      className="button"
                      type="button"
                      onClick={() => {
                        selectCategory(x.id);
                      }}
                    >
                      Edit
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {category && (
          <div className="Biliteracy-panel">
            <div>
              <h2>{category.name}</h2>
              <div className="flex-between">
              <div className="editor">
                <label>Pre-start Instructions</label>
                <div>
                  <Editor value={preInstructionsEditorState} options={"full"} changed={setpreInstructionsEditorState} height={"short"} />
                </div>
              </div>
              <div>
                <label>In Essay Instructions</label>
                <div>
                <Editor value={postInstructionsEditorState} options={"full"} changed={setPostInstructionsEditorState} height={"short"} />
                </div>
              </div>
              </div>
              <div className="flex-between">
                <button className="button" type="button" onClick={clickSubmit}>
                  Submit
                </button>
                <button className="button" type="button" onClick={closeEditor}>
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
