import React from "react";
import { Participant } from "../../utils/DocTogetherVideo";
import MyTimer from "./MyTimer";
import TimerControls from "./TimerControls";


interface JustVideoAdminPanelProps {
  participants: Participant[];
  sendRoomMessage: (body: any) => void;
  isModerator: boolean;
  socket: any;
  projectId: string;
  settings:any;
}

const JustVideoAdminPanel = (props: JustVideoAdminPanelProps) => {
  return (
    <div>
      {(props.isModerator || !props.settings.onlineJudgingHideStudentVideoButtonMuteAll) &&
      <div className="participant-control-center">
        {props.participants.filter(x => x.userId !== '1234').map((x: any, i: number) => {
          return (
            <div className="participant" key={`participant-${x.userId}-${i}`}>
              <div className="user">{x.username}</div>
              <div className="actions">
                <span
                  className="action"
                  title={`mute`}
                  onClick={() => props.sendRoomMessage({ mute: x.userId })}
                >
                  <i className="fa fa-microphone-slash"></i>
                </span>
                <span
                  className="action"
                  title={`turn off video`}
                  onClick={() => props.sendRoomMessage({ muteVideo: x.userId })}
                >
                  <i className="fa fa-video-slash"></i>
                </span>
                <span
                  className="action"
                  title={`kick out of meeting`}
                  onClick={() => {
                    if (window.confirm(`Are you sure you want to KICK ${x.username}?`)) props.sendRoomMessage({ kick: x.userId })
                  }}
                >
                  <i className="fa fa-ban"></i>
                </span>
              </div>
            </div>
          )
        })}
      </div>}
      {props.isModerator && props.settings.isAcaDeca
        && (
          <div className='timer-controls'>
            <TimerControls  
              socket={props.socket}
              projectId={props.projectId}
            />

          </div>
        )
      }
    </div>

  )
}

export default JustVideoAdminPanel