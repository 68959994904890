import React, { useEffect, useRef, useState } from 'react';
import io from "socket.io-client";
import { NoGateVideo, Participant } from "../../utils/DocTogetherVideo";
import StoreX, { store } from "../../redux/oldStore";
import { toast } from "react-toastify";
import Modal from '../Modal/Modal';
import JustVideoAdminPanel from './JustVideoAdminPanel';
import { ScreenRecorder } from '../ScreenRecording/ScreenRecorder';
import { ServerResponse } from '../../utils/Server';
import SupportChat from "../SupportChat/SupportChats";

import audioPlayer from "../../utils/AudioPlayer";
import ProjectInfo from './ProjectInfo';
import {formatPin} from '../../utils/Tools';
import { HingeInfo, IVirtualRoom, JustVideoContainerState, RoomInfo } from './JustVideoTypes';
import GatedEntryControler from './GatedEntryControler';
import RequestModeratorInterpreter from '../AdvancedJudging/RequestModeratorInterpreter';

interface IVideoContainerProps{
    roomId:string;
    autoStart:boolean;
    roomPin?:string;
}


//const VideoContainer extends React.Component<RouteComponentProps, JustVideoContainerState> {
const VideoContainer = (props:IVideoContainerProps)=> {
    const [info, setInfo] = useState<HingeInfo>();
    const [roomInfo, setRoomInfo] = useState<RoomInfo>();
    const docVideo = useRef<NoGateVideo>();
    //const [docVideo, setDocVideo] = useState<NoGateVideo>();
    const [joined, setJoined] = useState<boolean>(false);
    const [participants, setParticipantsState] = useState<Participant[]>([]);
    const [showCallInPin, setShowCallInPin] = useState<boolean>(false);

    useEffect(()=>{
        init();
    }, []);

    const init = () => {

        let video = new NoGateVideo("#docVideo", {
            dev: store.server.isDev,
            hangUp: hungUp,
            connectCallback: connected,
            setParticipants: setParticipants,
            jitsi: true,
          });
          video.init();
          docVideo.current = video;
//          setDocVideo(video);

        store.server
        .postApi<ServerResponse<HingeInfo>>("../Hinge/Info", {})
        .then((x) => {
          if (!x.Success) {
            toast.error(x.Message, { autoClose: false });
            return;
          }
  
          setInfo(x.Value);
          if(props.autoStart){
            window.setTimeout(() => {
                start();
            }, 500);
          }
          
        })
        .catch((x) => {
            console.log(x);
            toast.error(`Error getting room info`, {autoClose:false});}
        );
    };

    const getRoomInfo = async () => {

        await store.server
          .postApi<ServerResponse<RoomInfo>>(`../Hinge/GetVideoRoomInfo`, {id: props.roomId, roomPin:props.roomPin ?? null})
          .then((x) => {
            if (x.Success) {
                setRoomInfo(x.Value);

              //if(x.Value.PinPass) 
              connectToMeeting(x.Value);    
              
            } else {
              toast.error(x.Message)
            }
          })
          .catch((x) => {
              console.log(x);
              toast.error(`Error getting room details.`, {autoClose:false});
            });
      };

    const start = ()=>{
        getRoomInfo();
    };

  const connectToMeeting = (room:RoomInfo) => {
    docVideo?.current?.connect(room, info?.settings.enableChat ?? true);
    setJoined(true);
  }

  const connected = () => {
    setJoined(true);
  };

  const hungUp = () => {
    setJoined(false)
  };

  

  const handleHangup = () => {
    docVideo?.current?.hangUp();
  };


  const setParticipants = (participants: Array<Participant>) => {
    setParticipantsState(participants);
  };



    return (
      <div>
        <div>
          {!joined ? (
            <div className="flex-center">
              <button
                className="btn btn-primary"
                onClick={start}
              >
                <i className="fad fa-person-booth"></i>
                   Join Meeting Room
                </button>
            </div>
          ) : (
              <>
              <div className="flex-center">
              <span className="btn-group">
                <button className="btn btn-danger" onClick={handleHangup}>
                  <i className="fas fa-phone hangup-button"></i>
                  Hang up
                </button>
                {info?.settings.onlineVideoShowCallInNumber
                  && <button type="button" className="btn btn-secondary" onClick={() => setShowCallInPin(true)} title="dial into meeting"><i className="fas fa-phone-rotary text-secondary"  /></button>
                }
                </span>
                </div>
              </>
            )}
        </div>
                
        <div
          id="docVideo"
          className={`${!joined ? "hide" : ""}`}
        ></div>
        <div>
          {roomInfo?.additional && <>
            <ProjectInfo settings={info?.settings} project={roomInfo.additional}></ProjectInfo>
            
          </>}
        </div>

        {(showCallInPin && roomInfo)
          && (
            <Modal
              title='Conference call in number'
              setModalOpen={setShowCallInPin}
            >
              <div>
                <div className="call-in-info-line">
                  
                <span className="emph">Phone number: </span>
                
                <select>
                {roomInfo.phoneNumber?.map(x => <option key={x}>{x}</option>)}

                </select>
                </div>
                <div className="call-in-info-line">
                  <span className="emph">PIN:</span>
                   {roomInfo.conferencePin && formatPin(roomInfo.conferencePin)}
                  </div>
              </div>
            </Modal>
          )

        }

      </div>
    )
}

export default VideoContainer