import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { store } from "../../redux/oldStore";
import { ServerResponse } from '../../utils/Server';

const JudgingRoundSetup = (props) => {
  const {
    judges
    , round
    , divisions
    , categories
    , projects
    , projectsByCategory
    , judgingSessions
  } = props

  const [assignOptionPool, setAssignOptionPool] = useState('All');
  const [assignOptionSession, setAssignOptionSession] = useState('0');
  const [assignOptionJudgeMin, setAssignOptionJudgeMin] = useState(3);
  const [assignOptionJudgeMax, setAssignOptionJudgeMax] = useState(5);
  const [division, setDivision] = useState(-1);
  const [maxNumberOfAssignmentsPerJudge, setMaxNumberOfAssignmentsPerJudge] = useState(15);
  const [judgeAssignment, setJudgeAssignment] = useState('MixedAvoid');
  const [judgeJoint, setJudgeJoint] = useState('separate');
  const [judgeGroupSize,setJudgesGroupSize] = useState(1);
  const [judgesAtATime, setJudgesAtATime] = useState(1);
  const [respectSessions, setRespectSessions] = useState(false);
  const [respectPreferences, setRespectPreferences] = useState(false);



  console.log("=================", judgingSessions)

  const generate = () => {
    let request = {
      RoundId: round.Id,
      JudgePool: assignOptionPool,
      JudgeSession: assignOptionSession,
      JudgeTimesMin: assignOptionJudgeMin,
      JudgeTimesMax: assignOptionJudgeMax,
      JudgeAssignment: judgeAssignment,
      JudgeJointly: judgeJoint === "same",
      MaxNumberOfAssignmentsPerJudge: maxNumberOfAssignmentsPerJudge,
      Division: division,
      judgesAtATime,
      judgeGroupSize,
      respectSessions,
      respectPreferences
    }

    store.server.postApi<ServerResponse<any>>('../JudgingManagement/AutoAssignRound', request)
      .then(res => {
        console.log(res)
        props.setAssignments(res.Value.assignments)
        toast.success("Judging assignments have been made.");
      })
      .catch(err => {
        console.error(err)
        toast.warning('something went wrong with the auto assign')
      });


  }

  const clearRound = () => {
    if (!window.confirm(`Are you sure you want to clear all assignments from round "${round.Name}?"`)) {
      return;
    };

    store.server.postApi('../JudgingManagement/ClearRound', { roundId: round.Id })
      .then(res => {
        console.log(res)
        props.setAssignments([])

        toast.success("Judging assignments have been cleared.");
      })
      .catch(err => {
        console.error(err);
        toast.warning('something went wrong clearing the assignments')
      });
  }

  const mapSessions = judgingSessions.map((x, i) => {
    return (<option value={x.Id} key={i}>{x.Name}</option>);
  });





  

  return (
    <div>
      <div className="row">
        <div className="col-sm-8">
          <div className="soft-border">
            <h4>Auto Assign Judges</h4>
            <hr />
            <div className="head-room">
              <div className="form-horizontal">
                <div className="form-group">
                  <label className="control-label col-sm-4" htmlFor="auto-judge-pool">Judge Pool</label>
                  <div className="col-sm-6">
                    <select
                      className="form-control"
                      id="auto-judge-pool"
                      onChange={(e) => setAssignOptionPool(e.target.value)}
                      value={assignOptionPool}
                    >
                      <option value="All">All</option>
                      <option value="CheckedIn">Checked-in</option>
                      <option value="Active">Active (registered or signed in within the last 6 months)</option>
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-sm-4" htmlFor="auto-judge-session">Judge Session</label>
                  <div className="col-sm-6">
                    <select
                      className="form-control"
                      id="auto-judge-session"
                      onChange={(e) => setAssignOptionSession(e.target.value)}
                      value={assignOptionSession}
                    >
                      <option value="0">All</option>
                      {mapSessions}
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-sm-4" htmlFor="auto-judge-min">Judges For Each Project</label>
                  <div className="col-sm-5">
                    <div className="input-group">
                      <span className="input-group-addon">Min</span>
                      <input
                        id="auto-judge-min"
                        type="number"
                        step="1"
                        min="0"
                        max="50"
                        className="form-control"
                        onChange={(e) => setAssignOptionJudgeMin(e.target.valueAsNumber)}
                        value={assignOptionJudgeMin}
                      />
                      <span className="input-group-addon">Max</span>
                      <input
                        id="auto-judge-max"
                        type="number"
                        step="1"
                        min="0"
                        max="50"
                        className="form-control"
                        onChange={(e) => setAssignOptionJudgeMax(e.target.valueAsNumber)}
                        value={assignOptionJudgeMax}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label
                    className="control-label col-sm-4"
                    htmlFor="auto-judge-assign-max"
                  >
                    Max Projects Per Judge
                  </label>
                  <div className="col-sm-3">
                    <input
                      id="auto-judge-assign-max"
                      type="number"
                      step="1"
                      min="0"
                      max="100"
                      className="form-control"
                      onChange={(e) => setMaxNumberOfAssignmentsPerJudge(e.target.valueAsNumber)}
                      value={maxNumberOfAssignmentsPerJudge}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-sm-4" htmlFor="auto-judge-mix">Judges Project Mix</label>
                  <div className="col-sm-6">
                    <select
                      className="form-control"
                      id="auto-judge-mix"
                      onChange={(e) => setJudgeAssignment(e.target.value)}
                      value={judgeAssignment}
                    >
                      <option value="MixedAvoid">Single category (mixed avoid)</option>
                      <option value="Single">Single category (no mixed)</option>
                      <option value="Mixed">Mixed categories</option>
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-sm-4" htmlFor="auto-division">Division</label>
                  <div className="col-sm-6">
                    <select
                      className="form-control"
                      id="auto-division"
                      onChange={(e) => setDivision(+e.target.value)}
                      value={division}
                    >
                      <option value="-1">all divisions</option>
                      {divisions.map((x, i) => {
                        return (<option value={x.Id}>{x.Name}</option>);
                      })}
                    </select>
                  </div>
                </div>
                
                <div className="form-group">
                  <div className="col-sm-6 col-sm-offset-4">
                    <input
                      type="checkbox"
                      id="auto-judge-respect-pref"
                      onChange={(e) => setRespectPreferences(e.target.checked)}
                      defaultChecked={respectPreferences}
                    />
                  <label className="control-label" htmlFor="auto-judge-respect-pref">Respect judges preferences</label>
                  </div>
                </div>

                <div className="form-group">
                  <div className="col-sm-6 col-sm-offset-4">
                    <input
                      type="checkbox"
                      id="auto-judge-respect"
                      onChange={(e) => setRespectSessions(e.target.checked)}
                      defaultChecked={respectSessions}
                    />
                  <label className="control-label" htmlFor="auto-judge-respect">Respect judges sessions</label>
                  </div>
                </div>

                <div className="form-group">
                <label className="control-label col-sm-7" htmlFor="auto-judge-group-size">Assign judges to a group of size</label>
                <div className="col-sm-5">
                <input
                id="auto-judge-group-size"
                type="number"
                step="1"
                min="1"
                max="20"
                style={{width:'60px'}}
                className="form-control"
                onChange={(e) => setJudgesGroupSize(e.target.valueAsNumber)}
                value={judgeGroupSize}
              />
              <div className="text-muted">These judges will all be assiged the same projects and work together. 
              Default is 1, judges will all be assigned their own list of assignments.
              Group size larger then 1 will only respect the category preferneces of one of the judges at random.
              </div>
                </div>
              </div>


                {props.round && props.round.Values && props.round.Values.SchedulesInterviews &&
                  <>                  
                  <div className="form-group hide">
                    <label className="control-label col-sm-4" htmlFor="auto-judge-joint">Schedule</label>
                    <div className="col-sm-6">
                      <select
                        className="form-control"
                        id="auto-judge-joint"
                        onChange={(e) => setJudgeJoint(e.target.value)}
                      >
                        <option value="separate">judges assigned to a project at separate times</option>
                        <option value="same">judges assiged to a project at the same time</option>
                      </select>
                    </div>
                  </div>

                  {/* <div className="form-group">
                  <div className="col-sm-6 col-sm-offset-4">
                    <input
                      type="checkbox"
                      id="auto-judge-respect"
                      onChange={(e) => setRespectSessions(e.target.checked)}
                      defaultChecked={respectSessions}
                    />
                  <label className="control-label" htmlFor="auto-judge-respect">Respect judges sessions</label>
                  </div>
                </div> */}

                {/* <div className="form-group">
                <label className="control-label col-sm-7" htmlFor="auto-judge-group-size">Assign judges to a group of size</label>
                <div className="col-sm-5">
                <input
                id="auto-judge-group-size"
                type="number"
                step="1"
                min="1"
                max="20"
                style={{width:'60px'}}
                className="form-control"
                onChange={(e) => setJudgesGroupSize(e.target.value)}
                value={judgeGroupSize}
              />
              <div className="text-muted">These judges will all be assiged the same projects and work together. 
              Default is 1, judges will all be assigned their own list of assignments.
              Group size larger then 1 will only respect the category preferneces of one of the judges at random.
              </div>
                </div>
              </div> */}

              <div className="form-group">
              <label className="control-label col-sm-7" htmlFor="auto-judge-at-a-time">Number of Judges with a project at a time.</label>
              <div className="col-sm-5">
              <input
              id="auto-judge-at-a-time"
              type="number"
              step="1"
              min="1"
              max="10"
              style={{width:'60px'}}
              className="form-control"
              onChange={(e) => setJudgesAtATime(e.target.valueAsNumber)}
              value={judgesAtATime}
            />
            <div className="text-muted">You can set how many judges you want with a project at a time. Default is 1, only one judge will be scheduled with a project at a time.</div>
              </div>
            </div>
                </>
                
                }

                <div className="form-group">
                  <div className="head-room"></div>
                  <div className="col-sm-8 col-sm-offset-4">
                    <button
                      type="button"
                      className="btn btn-primary assign-judges-btn"
                      onClick={generate}
                    >
                      <i className="fad fa-bomb"></i>Assign Judges*
                    </button>
                    <button
                      type="button"
                      className="btn btn-default clear-assignments-btn"
                      onClick={clearRound}
                    >
                      <i className="text-danger fad fa-broom"></i>Clear assignments
                    </button>
                  </div>
                  <div className="col-sm-8 col-sm-offset-4">
                    <p>*Clicking this will clear the current assignments and start fresh</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-6">
            <div className="soft-border">
              Projects: {projects.length}<br />
                Judges: {judges.length}<br />
                categories: {categories.length}<br />
              <ul>
                {categories.map((c, i) => {
                  return (
                    <li key={`${c.Id}${i}`}>
                      {c.Name} - {projectsByCategory[c.Id]?.length}
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default JudgingRoundSetup