import Icon, { IconType } from "../Icon/Icon";
import { JudgingAssignmentsReportStickers } from "./JudgingAssignmentsReportStickers";

interface IReportProps{
    type: string;
}

export const ReportViewer = (props:IReportProps) =>{

    return (<>
        <div className="no-print flex-between">
            <div></div>
            <div>
            <div className="no-print"><button type="button" className="btn btn-secondary" onClick={()=>{window.print()}}><Icon type={IconType.print}/> Print</button></div>
            </div>
        </div>
        <div className={`report-viewer landscape`}>
            <JudgingAssignmentsReportStickers />
        </div>
    </>);
};