import React, { useEffect, useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { formatMoney } from '../../utils/Tools';
import { useWindowDimensions } from '../../utils/Hooks';
import { ServerResponse } from '../../utils/Server';
import Icon, { IconType } from '../Icon/Icon';
import StoreX, { store } from '../../redux/oldStore';
import { ProductsResponse, InvoicePaymentRequestResponse } from '../../utils/CommonTypes/StoreTypes';
import { toast } from 'react-toastify';
import { IState, ReduxMap } from '../../redux/redux';
import { connect } from 'react-redux';

// let pk_Test = 'pk_test_70p1xtDZ1dAv5prFesnSwfSu'
// https://stripe.com/docs/testing -- test cards for various errors

let style = {
  base: {
    color: '#32325d',
    lineHeight: '18px',
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4',
    },
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
  },
};

interface PaymentProps extends IState {
  paymentAmount: number;
  ifPaymentAmountIsZeroAutoProcess?: boolean;
  setModalOpen: (open: boolean) => void;
  fullProductResponse: ProductsResponse;
  orderId: string | null;
  onPaymentMade?: () => void;
  nextPath?: string;
}


const Payment = (props: PaymentProps) => {
  const {userId} = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const [donation, setDonation] = useState<number>(0);
  const [amount, setAmount] = useState<number>();

  const stripe = useStripe();
  const elements = useElements();
  const { height, width } = useWindowDimensions();
  const [orderId, setOrderId] = useState<string>();

  useEffect(() => {
    //stupid syntax so it's added not concat..
    let x = +donation + +props.paymentAmount;
    console.log(x);
    setAmount(x);
  }, [donation]);

  useEffect(() => {
    if (props.paymentAmount === 0 && props.ifPaymentAmountIsZeroAutoProcess === true) {
      setTimeout(() => {
        let process = {
          orderId: props.orderId,
          userId: userId,
          makeInvoice: true,          
        };
        ProcessPayment(process);
      }, 100);
    }
  }, [props.paymentAmount, props.ifPaymentAmountIsZeroAutoProcess]);

  const handlePayNow = async (e) => {
    e.preventDefault();

    //do I have the info I want?
    let errors: string[] = [];
    if (!firstName || firstName?.length === 0) errors.push(`First name required`);
    if (!lastName || lastName?.length === 0) errors.push(`Last name required`);

    if (errors.length > 0) {
      alert(errors.join(', '));
      return;
    }

    if (!stripe || !elements) {
      return;
    }
    let thisElement = elements.getElement(CardElement);
    if (thisElement === null) return;

    stripe.createToken(thisElement).then((x) => {
      if (x.error) {
        console.log(x.error);
        alert(x.error.message);
      } else {
        let process = {
          year: x?.token?.card?.exp_year,
          month: x.token.card?.exp_month,
          last4: x.token.card?.last4,
          zip: x.token.card?.address_zip,
          token: x.token.id,
          firstName,
          lastName,
          donation,
          email,
          phone,
          orderId: orderId ?? props.orderId,
          userId: userId,
        };
        ProcessPayment(process);
      }
    });
  };

  const ProcessPayment = (payload) => {
    let url = '';
    if (props.fullProductResponse?.NewRecord) {
      url = '../Store/ProcessPaymentAndRegister';
    } else {
      url = '../Store/ProcessPayment';
    }

    store.server.postApi<ServerResponse<InvoicePaymentRequestResponse>>(url, payload).then((x) => {
      if (x.Success) {
        if (!x.Value.Pass) {
          setOrderId(x.Value.OrderId);
          toast.warning(`Payment Failed: ${x.Value.Message}`, { autoClose: false });

          return;
        } else if (x.Value.NextRecordPath) {
          navigate(`${x.Value.NextRecordPath}/${location.search}`);
          return;
        } else if (props.nextPath) {
          navigate(`${props.nextPath}/${location.search}`);
        } else {
          let redirectUrl = StoreX.BuildUrl(`/purchase/getinvoice/${x.Value.OrderId}`);
          window.location.href = redirectUrl;
        }
      } else {
        if (x.Value?.Message) {
          toast.error(`${x.Value.Message}`, { autoClose: false });
          setOrderId(x.Value.OrderId);
        }
      }
    });
  };

  let formattedAmount = formatMoney.format(props.paymentAmount);

  const handleInvoiceClick = (e) => {
    e?.preventDefault();

    let process = {
      orderId: props.orderId,
      userId: userId,
      makeInvoice: true,
    };
    ProcessPayment(process);
  };

  return (
    <section className="purchase-payment-section">
      <div className="form-horizontal">
        <div className="form-group">
          <label className="control-label col-sm-4" htmlFor="Amount">
            Amount
          </label>
          <div className="col-sm-4">
            <input id="Amount" className="form-control" maxLength={20} readOnly={true} value={formattedAmount}></input>
          </div>
        </div>
        {props.Settings && props.Settings.AcceptPayment && (
          <>
            <div className="form-group">
              <label className="control-label col-sm-4" htmlFor="FirstName">
                Name:
              </label>
              <div className="col-sm-4">
                <input
                  id="FirstName"
                  className="form-control"
                  maxLength={20}
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}></input>
              </div>
              <div className="col-sm-4">
                <input
                  id="LastName"
                  className="form-control"
                  maxLength={20}
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}></input>
              </div>
            </div>
            <div className="form-group">
              <label className="control-label col-sm-4" htmlFor="Email">
                Email:
              </label>
              <div className="col-sm-5">
                <input
                  id="Email"
                  type="email"
                  className="form-control"
                  maxLength={150}
                  placeholder="Card Holder's Email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}></input>
              </div>
            </div>
            <div className="form-group">
              <label className="control-label col-sm-4" htmlFor="Phone">
                Phone:
              </label>
              <div className="col-sm-4">
                <input
                  id="Phone"
                  type="tel"
                  className="form-control"
                  maxLength={20}
                  placeholder="Card Holder's Phone"
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}></input>
              </div>
            </div>
            {props.Settings.CollectDonationsAtCheckout && <div id="collectDonationsPanel">
              {props.Settings.text.checkhoutDonationHeader}
              <div className="form-group">
                <label className="control-label col-sm-4" htmlFor="DonationTextbox">
                  {props.Settings.text.checkoutDonationLabel}
                </label>
                <div className="col-sm-5">
                  <input
                    id="DonationTextbox"
                    type="number"
                    className="form-control"
                    placeholder="Your additional donations make this contest possible."
                    value={donation}
                    min={0}
                    max={2000}
                    step={1}
                    onChange={(e) => {
                      setDonation(+e.target.value);
                    }}></input>
                </div>
              </div>
            </div>}
            <div className="form-group">
              <label className="control-label col-sm-4" htmlFor="Total">
                Total
              </label>
              <div className="col-sm-4">
                <input id="Total" className="form-control" maxLength={20} readOnly={true} value={formatMoney.format(amount ?? 0)}></input>
              </div>
            </div>

            <div className="form-group">
              <label className="control-label col-sm-4" htmlFor="Stripe">
                Credit / Debit Card
              </label>
              <div className="col-sm-8">
                <div className="form-control">
                  <CardElement
                    options={{
                      style: style,
                    }}
                    className="card"
                  />
                </div>
              </div>
            </div>
          </>
        )}
        <div className="form-group">
          <div className="text-right">
            {props.Settings && props.Settings.AcceptPayment && (
              <>
                <button className="btn btn-success" onClick={handlePayNow} disabled={!stripe}>
                  <Icon type={IconType.payNow} />
                  {props.fullProductResponse?.NewRecord ? <>Pay & Register</> : <>pay now</>}
                </button>
              </>
            )}

            {props.Settings && props.Settings.GenerateInvoice && (
              <>
                <button className="btn btn-secondary" onClick={(e) => handleInvoiceClick(e)}>
                  <Icon type={IconType.fileInvoice} />
                  {props.fullProductResponse?.NewRecord ? <>Generate Invoice & Register</> : <>Generate Invoice</>}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return { ...state.main };
};


export default connect(mapStateToProps)(Payment);

//export default ReduxMap(Payment);
