 export interface IRound {
  IsSubround: boolean;
  Name: string;
  Id: number;
  IsRemoved: boolean;
  UseRubic: any;
  Values: any;
  RoundValue: number;
  circles?: ICircle[];
  stats?:IJudgingStats;
}

export interface IJudgingStats{
  Categories:IJudgingStat[];
  Divisions:IJudgingStat[];
  NumberOfAssignments:number;
  NumberOfCompletedAssignments:number;
  NumberOfScoresRecorded:number;
  PercentComplete:string;
}

export interface IJudgingStat{
  Name:string,
  Done:number,
  Needed:number,
  Percent:string,
}

export interface ICircle {
  Name: string; //"xxx-1"
  JudgeId: number; //1240
  CircleId: number; //1593
  RoundId: number; //1

}

export interface IJudge {
  AverageScore: number; //123
  FirstName: string; //"Judge"
  Id: number; //1241
  LastName: string; //"7"
  Scores: IScore[]; //[{…}]
  Veteran: boolean; //true
  ZScore: number; //1.23
  MyZScoreIsValid: boolean;
}

export interface IScore {
  judgeId: number; //1241
  noShow: boolean; //false
  projectId: number; //10653
  score: number; //75.71428571428572
  Category: string; // "Websites"
  JudgeFirstName: string; // "Judge"
  JudgeLastName: string; // "7"
  at:string;
  ProjectIdDisplay: string; // "BO-245"
  Title: string; // "Conflict and Compromise: Creating a Nation"
  zScore: number;
  zScoreIsValid: boolean;
  scoreId?: number;

}

export interface IProject {
  AverageScore: number; //123
  Category: string; //{Id: 2, Name: "Website Website"}
  CategoryId:number;
  DivisionId:number;
  SubCategory:string|null;
  Grade: number; //9
  ProjectId: string; //"11501"
  ProjectIntId: number; //72
  ProjectKey: string; //"d33100c4-8420-47f3-9f4b-0c414fe667de"
  Scores: IScore[]; //null
  Team: boolean; //false
  Title: string; //"Whats the best Hair Gel"
  AdjustedScore: number;
  AdjustedZScore: number;
  Tags?: number[];
  Students:string[]|null|undefined;
  StudentsSearch:string|null|undefined;
  Schools:string[]|null|undefined;
  CircleName?:string;
}

export interface ITag {
  FairId: number; //1
  Id: number; //1
  Type: string; //"project"
  Value: string; //
}

export interface IAward {
  Id: number;
  Name: string;
  Points: number;
}

export interface IResultsRoundInfo{
  CategoryAwards:IJRCategoryAward[];
  Judges: IJudge[];
  Projects: IProject[];
  Round: IJRRoundInfo[];
  Categories: IListItem[];
  Divisions: IListItem[];
}

export interface IListItem{
  id:string;
  name:string;
}

export interface IJRRoundInfo{
  
}

export interface IJRCategoryAward{
  ProjectKey:string;
  AwardId:number;
}



  

export const SET_ROUNDS = 'SET_ROUNDS'