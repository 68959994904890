import React, { useRef, useState } from "react";
import Icon, { IconType } from "../Icon/Icon";
import { useEffect } from "react";
import Modal from "../Modal/Modal";
import { IFileDetails, UiFormField } from "../../utils/CommonTypes/UserTypes";
import StoreX, { store } from "../../redux/oldStore";
import { ServerResponse } from "../../utils/Server";
import { toast } from "react-toastify";
import placeholderImage from '../../../public/Assets/Images/image-sharp-thin.svg';

interface IFileInputProps {
  input: UiFormField;
  targetId?: string;
  fieldKey: string;
  canSave: boolean;
  fileType:string;
  inputFilesChanged: Function;
  exts:string;
  handleChange: Function;
  maxSize?:number;
}

const FileInput = (props: IFileInputProps) => {
  const [files, setFiles] = useState<IFileDetails[]>(
    props.input.Files?.filter((x) => !x.IsImage && !x.IsPdf) ?? []
  );
  const [images, setImages] = useState<IFileDetails[]>(
    props.input.Files?.filter((x) => x.IsImage || x.IsPdf) ?? []
  );
  const [input, setInput] = useState<UiFormField>(props.input);
  const [showUpload, setShowUpload] = useState<boolean>(false);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [filePreview, setFilePreview] = useState<IFileDetails>();
  const [caption, setCaption] = useState<string>();
  const [fileDetails, setFileDetails] = useState<string>();
  const [newFileInfo, setNewFileInfo] = useState<IFileDetails | null>();
  const [flipKey, setFlipKey] = useState<number>(0);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(()=>{
    props.handleChange(input.Key, props.input.Files?.length > 0 ? [props.input.Files?.length] : ["<null value>"] );
  },[props.input?.Files])

  const mapFiles = files.map((file: IFileDetails, i: number) => {
    return (
      <div  key={`fif-${i}`} className="gallery-file" onClick={() => showPreviewClick(file)}>
        {file.IsPdf && <Icon type={IconType.filePdf}></Icon>}
        {file.Name}
        {/* <a href={Store.BuildUrl(`/File/Image/${file.Key}`)} target='_blank' title='view file'>{file.Name}</a> */}
      </div>
    );
  });
  const mapImages = images.map((image: IFileDetails, i: number) => {
    return (
      <div key={`fii-${i}`} className="gallery-image" onClick={() => showPreviewClick(image)}>
        <img src={StoreX.BuildUrl(`/File/${image.Key}/true/115?${image.FlipKey ?? ''}`)}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
              currentTarget.src = placeholderImage;
            }
          }
        />
        {/* <span className="caption">{image.Caption}</span> */}
      </div>
    );
  });

  const showPreviewClick = (file: IFileDetails) => {
    setShowPreview(true);
    setFilePreview(file);
  };

  const showUploadModalClick = () => {
    setShowUpload(true);
  };

  const handleFileChange = (e) => {
    if (e.target.files?.[0]) {
      let file = e.target.files[0];
      let size = (file.size / 1024 / 1024).toFixed(1);
      let name = file.name;
      let ext = name.substring(name.lastIndexOf(".")).toLowerCase();
      let isPdf = ext === ".pdf";
      let isImage =
        ext === ".jpg" ||
        ext === ".jpeg" ||
        ext === ".png" ||
        ext === ".tif" ||
        ext === ".tiff" ||
        ext === ".gif";
      setNewFileInfo({
        Caption: caption ?? "",
        Extension: ext,
        IsImage: isImage,
        IsPdf: isPdf,
        Key: "",
        Name: name,
      });

      if (!isFileValid(file)) {
        e.target.files = null;
        if (fileInputRef.current) fileInputRef.current.value = "";
        //setFileDetails(`the selected file is not valid;`);
        setNewFileInfo(null);
        return;
      }

      setFileDetails(`${name} ${size} MB`);
    } else {
      setFileDetails(``);
    }
  };

  const isFileValid = (file) => {
    let size = (file.size / 1024 / 1024);
    let name = file.name;
    let ext = name.substring(name.lastIndexOf(".")).toLowerCase();
    let isPdf = ext === ".pdf";
    let isImage =
      ext === ".jpg" ||
      ext === ".jpeg" ||
      ext === ".png" ||
      ext === ".tif" ||
      ext === ".tiff" ||
      ext === ".gif";
      let isMp4 = ext === ".mp4" || ext ===".mov";

      if(!isPdf && !isMp4 && !isImage) {
        
        toast.warning(`Sorry, the file "${name}" is not valid.`);
        return false;
      }

      let maxSize = 20;
      if(props.maxSize && props.maxSize > 0) maxSize = props.maxSize;
      else if(isMp4) maxSize = 1024;

      if(size > maxSize){
        toast.warning(`The file "${name}" is too large. The max file size is ${maxSize.toFixed(2)} MB`);
        return false;
      }

      if(isMp4 && !StoreX.Settings?.ProjectFilesAllowVideo && props.exts.indexOf(".mp4") === -1){
        toast.warning(`The file "${name}" type is not allowed.`);
        return false;
      }

      if(isPdf && !StoreX.Settings?.ProjectFilesAllowPdf && props.exts.indexOf(".pdf") === -1){
        console.log(props.exts);
        toast.warning(`The file "${name}" type is not allowed.`);
        return false;
      }
    
    
    return true;
  };

  const uploadFileClick = () => {
    handleFilesForSaving();
  };

  const handleFilesForSaving = () => {
    let myFiles = [fileInputRef.current?.files];
    let file = myFiles[0];
    let newFile = newFileInfo;
    if (
      !fileInputRef.current ||
      !fileInputRef.current.files ||
      !file ||
      !newFile
    )
      return;

    newFile.Caption = caption ?? "";

    let submitObj = {
      projectId: props.targetId,
      caption:caption ?? '',
      fileType:props.fileType,
    };

    store.server
      .postApi<ServerResponse<any>>(`../Project/UploadFile`, submitObj, [
        ...fileInputRef.current.files,
      ])
      .then((res) => {
        if (res.Success) {
          if (!newFile) return;
          newFile.Key = res.Value;

          let inputFiles = input.Files;
          inputFiles.push(newFile);
          props.inputFilesChanged(input.Key, inputFiles);
          props.handleChange(input.Key, [inputFiles.length] );
          if (newFile.IsImage || newFile.IsPdf) {
            let ix = images;
            ix.push(newFile);
            setImages(ix);
          } else {
            let ix = files;
            ix.push(newFile);
            setFiles(ix);
          }
          
          if (fileInputRef.current) fileInputRef.current.value = "";
          toast.success("file uploaded...");
          setCaption("");

          setShowUpload(false);
          setFileDetails("");
          setNewFileInfo(null);
        } else {
          toast.error(`${res.Message}`, { autoClose: false });
        }
      });
  };

  const handleDeleteFile = () => {
    if (
      window.confirm(
        `Are you sure you want to delete your file "${filePreview?.Name}"?`
      )
    ) {
      store.server
        .postApi<ServerResponse<any>>("../Project/RemoveProjectFile", {
          fileKey: filePreview?.Key,
        })
        .then((res) => {
          if (res.Success) {
            if (filePreview?.IsImage || filePreview?.IsPdf)
              setImages(images.filter((x) => x.Key !== filePreview.Key));
            else setFiles(files.filter((x) => x.Key !== filePreview?.Key));
            props.inputFilesChanged(
              input.Key,
              input.Files.filter((x) => x.Key !== filePreview?.Key)
            );
            setShowPreview(false);
          } else {
            toast.error(`${res.Message}`, { autoClose: false });
          }
        });
    }
  };

  const rotateImage = (deg) => {
    let imgs = images.filter(x=>x.Key !== filePreview?.Key);
    let img = images.find((x) => x.Key == filePreview?.Key);
    let files = input.Files;
    let imgFromFile = files.find((x) => x.Key == filePreview?.Key);
if(img)
    store.server
      .postApi<ServerResponse<any>>("../API/image/UpdateImage", {
        key: img.Key,
        rotate: deg,
      })
      .then((res) => {
        if (res.Success && img) {
          //this doesn't work....
          // if(img.Key.indexOf('?') > -1) img.Key += '&'+deg;
          // else img.Key += '?'+deg;
          setFlipKey(flipKey + 1);
          props.inputFilesChanged(input.Key, files);
          imgs.push(img);
          setImages(imgs);
          setFilePreview(img);
        }
      });
  };

  return (
    <>
      <div className="file-input-container">
        <div className="file-input-images">
          {images && images.length > 0 ? (
            <>
              <div className="gallery">{mapImages}</div>
            </>
          ) : (
            <>
              {/* <div className="alert alert-info">No Images found.</div> */}
            </>
          )}
        </div>
        <div className="file-input-files">
          {files && files.length > 0 ? (
            <>
              <div className="gallery-files">{mapFiles}</div>
            </>
          ) : (
            <>
              {/* <div className="alert alert-info">No Images found.</div> */}
            </>
          )}
        </div>
      </div>
      <div className="flex-between">
        {props.canSave && input && input.MaxLength > input.Files?.length ? (
          <div>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={showUploadModalClick}
              title="add a file"
            >
              <Icon type={IconType.upload} /> Add File
            </button>
          </div>
        ) : (
          <div></div>
        )}
        {input.MaxLength > 1 && <span className="text-muted">
          {input.Files?.length ?? 0} of {input.MaxLength} files
        </span>}
      </div>
        {input.AdditionalInformation && <div><div dangerouslySetInnerHTML={{__html:input.AdditionalInformation}}/></div>}
      {showUpload && (
        <>
          <Modal
            setModalOpen={setShowUpload}
            title="File Upload"
            className="file-input-modal-sizer"
          >
            <div className="form-horizontal">
              <div>{props.input?.AdditionalInformation && <div dangerouslySetInnerHTML={{__html:props.input.AdditionalInformation}}></div>}</div>
              <div className="form-group">
                <label
                  className="control-label col-sm-4"
                  htmlFor={`${input.Key}`}
                >
                  File
                </label>
                <div className="col-sm-8">
                  <input
                    id={`${input.Key}`}
                    type="file"
                    className="form-control"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    accept={props.exts}
                                      />
                  <div className="text-muted">{fileDetails}</div>
                </div>
              </div>
              <div className="form-group">
                <label
                  className="control-label col-sm-4"
                  htmlFor={`${input.Key}`}
                >
                  Caption
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    maxLength={1000}
                    onChange={(e) => setCaption(e.target.value)}
                    value={caption}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="col-sm-8 col-sm-offset-4">
                  <button
                    type="button"
                    className="btn btn-secondary upload-file-btn"
                    title="click to upload file."
                    onClick={uploadFileClick}
                  >
                    <Icon type={IconType.upload} /> Upload File
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </>
      )}

      {showPreview && filePreview && (
        <>
          <Modal
            setModalOpen={setShowPreview}
            title={filePreview.Name.substring(
              0,
              Math.min(50, filePreview.Name.length)
            )}
            className="file-input-preview-sizer"
          >
            <>
              <div className="file-preview">
                {filePreview.IsImage && (
                  <img
                    className="gallery-image-x file-preview-grow"
                    src={StoreX.BuildUrl(`/File/${filePreview.Key}?${flipKey ?? ''}`)}
                  />
                )}
                {filePreview.IsPdf && (
                  <>
                  <embed 
                  className="gallery-file-x file-preview-grow"
            src={`${StoreX.BuildUrl('/File/'+filePreview.Key)}#toolbar=0&navpanes=0&view=FitH`}
            width="100%"
            height="100%"
            type="application/pdf"
            
          />
                  </>
                )}
                {filePreview.Extension === ".mov" && (
                  <>
                  <div className="alert alert-info">
                    <h3><Icon type={IconType.warning}/> Processing... </h3>
                    <hr/>
                We are working on processing this video into a format that you can see in your browser. Please check back at a later time.
                You can download the file now by clicking Open below. 
                
                </div>
                  </>
                )}
                
                {filePreview.Extension === ".mp4" && (
                  <>
                <video width="100%" height="100%" controls>
                  <source src={`${StoreX.BuildUrl('/File/Image/'+filePreview.Key)}`} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                  </>
                )}
                {filePreview.Caption && (
                  <div className="gallery-prevew-caption">
                    {filePreview.Caption}
                  </div>
                )}
                <div className="gallery-control-panel">
                  {props.canSave && (
                    <button
                      type="button"
                      className="btn btn-default"
                      title={`Delete file ${filePreview.Name}`}
                      onClick={handleDeleteFile}
                    >
                      <Icon type={IconType.delete} />
                      Delete File
                    </button>
                  )}
                  <a
                    href={StoreX.BuildUrl(`/File/Image/${filePreview.Key}`)}
                    target="_blank"
                    title="view file"
                  >
                    Open
                  </a>

                  {filePreview.IsImage && (
                    <div>
                      <span
                        title="rotate image left"
                                                onClick={() => {
                          rotateImage(270);
                        }}
                      >
                        <Icon type={IconType.redo} flipHorizontal={true} />
                      </span>
                      <span
                        title="rotate image right"
                        onClick={() => {
                          rotateImage(90);
                        }}
                      >
                        <Icon type={IconType.redo} />
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </>
          </Modal>
        </>
      )}
    </>
  );
};

export default FileInput;
