import React, { useState, useEffect } from "react";
import { store } from "../../redux/oldStore";
import { toast } from "react-toastify";
import { setJudges } from "../../redux/judgingSetup/actions";
import { IRound, ISettings } from "./Dashboard";

interface RoundListProps {
  rounds: IRound[];
  settings: ISettings | undefined;
  setChosenRound: Function;
}

const RoundList = (props: RoundListProps) => {
  const { rounds, settings } = props;
  const [searchVal, setSearchVal] = useState('')
  const [filteredRounds, setFilteredRounds] = useState<IRound[]>([])

  useEffect(() => {
    if (!searchVal) {
      return setFilteredRounds(rounds)
    } else {
      // off first name or last name
      let updatedJudges = rounds.filter(round =>
        round.RoundName.toLowerCase().includes(searchVal.toLowerCase())
      )
      setFilteredRounds(updatedJudges)
    }
  }, [rounds, searchVal])

  const mapRounds = filteredRounds.map((x, i: number) => {
    return (
      <div 
        className="project" 
        key={`${i}-round`}
        onClick={() => props.setChosenRound(x)}
      >
        <div className="id">
          {x.RoundName}
        </div>
      </div>
    )
  });

  return (
    <div>
      <div className="">
        <div className="input-group">
          <input
            // v-model="judgeSearchValue"
            onChange={(e) => setSearchVal(e.target.value)}
            value={searchVal}
            className="form-control "
            placeholder="search"
          />
          <span className="input-group-addon">
            <i className="fa fa-search"></i>
          </span>
        </div>
      </div>
      <div className="online-judging-project-list">
        {mapRounds}
      </div>
    </div>);
};

export default RoundList;
