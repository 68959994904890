import React, { useState, useEffect } from "react";
import { store } from "../../redux/oldStore.ts";

export default function ProjectView(props) {
  const project = props.project;
  const school = project.Schools[0];

  const onSelect = ()=>{
    if(props.onSelect){
        props.onSelect(project);
    }
  }

  return (
    <div className="project-view">
      <div className="flex-between">
        <div className="header">
          <span className="click" onClick={onSelect}>{project.ProjectId}</span> - {project.Category}
        </div>
      </div>
      <div className="title">{project.Title}</div>
      <div className="school">{props.schools[school]}</div>
      <div>
        {project.StudentNames.map((x, i) => {
          return <span className="person" key={i}>{x}</span>;
        })}
      </div>
    </div>
  );
}
