import { useEffect, useState } from 'react';
import { IState, ReduxMap } from '../../redux/redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Icon, { IconType } from '../Icon/Icon';
import { toast } from 'react-toastify';
import { ServerResponse } from '../../utils/Server';
import { store } from '../../redux/oldStore';
import { IJudgingAssessment, IJudgingFeedbackInfo, IJudgingFeedbackPreview, IJudgingFeedbackWorkRequest, IJudgingSendFeedback, IJudgingSendResponse } from './JudgingResultsTypes';
import { IProjectInfo } from '../_Core/CoreTypes';
import Modal from '../Modal/Modal';
import SetupWizard from '../Setup/SetupWizard';

interface IProps extends IState {}

const defaultInstrctions = () => {
  return {
    ReviewedOnly: true,
    SendToParticipants: true,
    Subject:"Judging Feedback for [entry id]",
    Body: `Hi [first name] [last name], \r\n\r\nBelow we have included feedback provided by the judges that reviewed your entry "[entry title]". We hope you find this feedback useful. \r\n\r\n[feedback] \r\n\r\nThank you.`,
  };
};

const JudgingFeedback = (props: IProps) => {
  const [info, setInfo] = useState<IJudgingFeedbackInfo>();
  const [projectDic, setProjectDic] = useState<Record<number, IProjectInfo>>({});
  const [work, setWork] = useState<IJudgingFeedbackWorkRequest>();
  const [assessments, setAssessments] = useState<IJudgingAssessment[]>([]);
  const [selectedAssessment, setSelectedAssessment] = useState<IJudgingAssessment>();
  const [showReview, setShowReview] = useState<boolean>(false);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [previewIndex, setPreviewIndex] = useState<number>(0);
  const [feedbackPreview, setFeedbackPreview] = useState<IJudgingFeedbackPreview[]>([]);
  const [sendInstructions, setSendInstructions] = useState<IJudgingSendFeedback>(defaultInstrctions());
  const [showSend, setShowSend] = useState<boolean>(false);
  const [sendResponse, setSendResponse] = useState<IJudgingSendResponse>();

  useEffect(() => {
    if (props.IsReady) {
      init();
      loadSubmissions();
    }
  }, [props.IsReady]);

  useEffect(() => {
    if (work && work.Assessments) {
      let sortedAssessments = work.Assessments.sort((a, b) => {
        let aR = a.RawNotes ? true : false;
        let bR = b.RawNotes ? true : false;
        if (aR !== bR) return aR ? -1 : 1;

        aR = a.ReviewedAtUtc ? true : false;
        bR = b.ReviewedAtUtc ? true : false;
        if (aR !== bR) return aR ? 1 : -1;

        return projectDic[a.ProjectId]?.projectId.toLowerCase() > projectDic[b.ProjectId]?.projectId.toLowerCase() ? -1 : 1;
      });

      setAssessments(sortedAssessments);
    } else {
      setAssessments([]);
    }
  }, [work, projectDic]);

  const init = () => {
    toast.dismiss();
    store.server.getApi<ServerResponse<IJudgingFeedbackInfo>>(`../JudgingSubmissions/Info`).then((x) => {
      if (x.Success) {
        setInfo(x.Value);
        let dic = {};
        x.Value.Projects.forEach((p) => {
          dic[p.id] = p;
        });
        setProjectDic(dic);
        //console.log('Judges', x.Value.JudgesDic);
      } else {
        toast.error(x.Message, { autoClose: false });
      }
    });
  };

  const loadSubmissions = () => {
    store.server.getApi<ServerResponse<IJudgingFeedbackWorkRequest>>(`../JudgingSubmissions/Submissions`).then((x) => {
      if (x.Success) {
        setWork(x.Value);
      } else {
        toast.error(x.Message, { autoClose: false });
      }
    });
  };

  const saveReview = () => {
    toast.dismiss();
    let submission = selectedAssessment;
    store.server.postApi<ServerResponse<IJudgingAssessment>>(`../JudgingSubmissions/ReviewedSubmission`, submission).then((x) => {
      if (x.Success) {
        let a = [...(work?.Assessments ?? [])];
        a = a.filter((r) => r.Id !== x.Value.Id);
        a.push(x.Value);
        setWork({ ...work, Assessments: a });
        setSelectedAssessment(x.Value);
      } else {
        toast.error(x.Message, { autoClose: false });
      }
    });
  };

  const preview = (projectId: number) => {
    toast.dismiss(); //GetSampleFeedback
    store.server.postApi<ServerResponse<IJudgingFeedbackPreview>>(`../JudgingSubmissions/GetSampleFeedback`, { projectId: projectId }).then((x) => {
      if (x.Success) {
        setShowPreview(true);
        setSendResponse(undefined);
        setFeedbackPreview([x.Value]);
        setPreviewIndex(0);
      } else {
        toast.error(x.Message, { autoClose: false });
      }
    });
  };

  const sendIt = () => {
    toast.dismiss();
    let errors: string[] = [];
    if (!sendInstructions.Subject) errors.push('Subject is required.');
    if (!sendInstructions.Body) errors.push('Message is required.');
    else if (sendInstructions.Body.indexOf('[feedback]') === -1) errors.push('The key "[feedback]" in message is required.');

    if (errors.length > 0) {
      toast.warning(
        <div>
          <ul>
            {errors.map((x, i) => {
              return <li key={`jfb-s-err-${i}`}>{x}</li>;
            })}
          </ul>
        </div>
      );
      return;
    }

    if (!window.confirm(`${props.User?.firstName}, are you sure you want to send out feedback? This cannot be undone.`)) {
      return;
    }
    sendFeedbackNow(true);
  };
  const sendFeedbackNow = (send: boolean) => {
    toast.dismiss(); //GetSampleFeedback
    sendInstructions.Send = send;
    store.server.postApi<ServerResponse<IJudgingSendResponse>>(`../JudgingSubmissions/SendFeedback`, sendInstructions).then((x) => {
      if (x.Success) {
        setShowPreview(true);
        setShowSend(false);
        setSendResponse(x.Value);
        setFeedbackPreview(x.Value.messages);
        setPreviewIndex(0);
      } else {
        toast.error(x.Message, { autoClose: false });
      }
    });
  };

  const mapAssessments = () => {
    if (assessments.length === 0) {
      return (
        <div>
          <div className="alert alert-info">
            <h3>No Judge Assessments</h3>
            Currently there are no assessments to be reviewed.
          </div>
        </div>
      );
    } else {
      return (
        <>
          <div>
            You can send your judges feedback to participants. Use this page to Review their feedback 
            to ensure it's appropriate, then click the "Open Send Feedback Instructions" button at the bottom to 
            choose what feedback to share.
          </div>
          <div className="flex-center">
            <strong>Key:</strong>
            <div className="text-warning">
              <Icon type={IconType.warning} />
              Needs Review
            </div>
            <div className="text-success">
              <Icon type={IconType.checkCircle} />
              Reviewed
            </div>
            <div className="text-default">
              <Icon type={IconType.checkCircle} />
              Nothing to Review
            </div>
          </div>
          <div className="judge-assessment-submission-list">
            {assessments.map((x, i) => {
              let project = projectDic[x.ProjectId];
              let judge = info?.JudgesDic[x.JudgeId + ''];
              if (!project) {
                //console.log(x);
                return undefined;
              }
              return (
                <div className="judge-submission flex-between" key={`jasl-js-${i}`}>
                  <div className="flex">
                    <div className="status">
                      <button
                        type={'button'}
                        className="btn btn-xs btn-default"
                        onClick={() => {
                          setShowReview(true);
                          setSelectedAssessment(x);
                        }}>
                        {x.ReviewedAtUtc ? (
                          <div className="text-success">
                            <Icon type={IconType.checkCircle} /> Reviewed
                          </div>
                        ) : !x.RawNotes ? (
                          <div className="text-default">
                            <Icon type={IconType.checkCircle} /> Review
                          </div>
                        ) : (
                          <div className="text-warning">
                            <Icon type={IconType.warning} /> Review
                          </div>
                        )}
                      </button>
                      <button
                        type={'button'}
                        className="btn btn-default btn-xs"
                        onClick={() => {
                          preview(x.ProjectId);
                        }}>
                        <Icon type={IconType.fileInvoice} /> Preview
                      </button>
                    </div>
                    <div>
                      <strong>{project.projectId}</strong>
                      <div className="details">{project.categoryName}</div>
                      <div className="details">{project.title}</div>
                    </div>
                  </div>
                  <div className="text-right">
                    {x.NoShow ? (
                      <div>
                        <Icon type={IconType.eyeNo} /> No Show
                      </div>
                    ) : (
                      <div>{(x.Score ?? 0).toFixed(2)}%</div>
                    )}
                    <div>
                      {judge?.firstName} {judge?.lastName}
                    </div>
                    <div>{judge?.email}</div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="flex-center">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                setShowSend(true);
              }}>
              <Icon type={IconType.send} /> Open Send Feedback Instructions
            </button>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <Tabs>
        <TabList>
          <Tab>
            <Icon type={IconType.fileInvoice} /> Feedback
          </Tab>
          {/* <Tab><Icon type={IconType.configure}/> Settings</Tab> */}
        </TabList>
        <TabPanel>{mapAssessments()}</TabPanel>
        <TabPanel></TabPanel>
      </Tabs>
      {showReview && selectedAssessment && (
        <Modal setModalOpen={setShowReview} title={`Review ${projectDic[selectedAssessment.ProjectId].projectId}`} size="l">
          <div className="form-horizontal">
            <div className="form-group">
              <label className="col-sm-4 control-label" htmlFor="jfb-ta-notes">
                Orginal Comments
              </label>
              <div className="col-sm-8">
                <textarea id="jfb-ta-notes" className="form-control" rows={6} value={selectedAssessment.RawNotes} readOnly={true} />
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-4 control-label" htmlFor="jfb-ta-notes.revised">
                Revised Comments
              </label>
              <div className="col-sm-8">
                <textarea
                  id="jfb-ta-notes.revised"
                  className="form-control"
                  value={selectedAssessment.EditedNotes}
                  rows={6}
                  onChange={(x) => {
                    setSelectedAssessment({ ...selectedAssessment, EditedNotes: x.target.value });
                  }}
                />
              </div>
            </div>
            <div className="form-group">
              <div className="col-sm-offset-4 col-sm-8">
                <input
                  type={'checkbox'}
                  id="jfb-ta-notes.removed"
                  className="form-controlx"
                  checked={selectedAssessment.RemoveNotes}
                  onChange={(x) => {
                    setSelectedAssessment({ ...selectedAssessment, RemoveNotes: x.target.checked });
                  }}
                />
                <label className="control-label" htmlFor="jfb-ta-notes.removed">
                  Do not share these comments.
                </label>
              </div>
            </div>
            <div className="form-group">
              <div className="col-sm-4"></div>
              <div className="col-sm-8">
                <button type="button" className="btn btn-secondary" onClick={saveReview}>
                  <Icon type={IconType.save} /> Save
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {showPreview && feedbackPreview.length > 0 && (
        <Modal setModalOpen={setShowPreview} title="Feedback Preview" size="xl">
          {sendResponse && (
            <div className="flex-between alert alert-info">
              <h4>Send Feedback Results</h4>
              <div>Messages: {sendResponse.messagesSent}</div>
              <div>People: {sendResponse.peopleSentTo}</div>
              <div>Failed: {sendResponse.failedMessage}</div>
            </div>
          )}

          {feedbackPreview.length > previewIndex && (
            <div className="judging-feedback-preview">
              <div className="info">
                <strong>{feedbackPreview[previewIndex].ProjectId}</strong> {feedbackPreview[previewIndex].Title}
                <div>
                  {feedbackPreview[previewIndex].Emails.map((x, i) => {
                    return (
                      <span key={`jfb-e-pv-${i}`}>
                        <Icon type={IconType.user} /> {x}
                      </span>
                    );
                  })}
                </div>
              </div>
              <div className="email">
                <div className="subject">{feedbackPreview[previewIndex].Subject}</div>
                <hr />
                <div className="body">
                  <div dangerouslySetInnerHTML={{ __html: feedbackPreview[previewIndex].Body ?? '' }} />
                </div>
              </div>
            </div>
          )}

          <div className="flex-between">
            <div>
              <button
                type={'button'}
                className="btn btn-default"
                onClick={() => {
                  setShowPreview(false);
                }}>
                <Icon type={IconType.close} />
                Close
              </button>
              {sendResponse && sendResponse.messagesSent === 0 && (
                <>
                <button
                  type={'button'}
                  className="btn btn-default"
                  onClick={() => {
                    setShowPreview(false);
                    setShowSend(true);
                  }}>
                  <Icon type={IconType.configure} />
                 Adjust Feedback
                </button>
                <button
                  type={'button'}
                  className="btn btn-secondary"
                  onClick={() => {
                    sendIt();
                  }}>
                  <Icon type={IconType.send} />
                  Send Feedback
                </button>
                </>
              )}
            </div>

            <div className="text-center">
              <div>
                <button
                  type={'button'}
                  className="btn btn-default btn-xs"
                  onClick={() => {
                    setPreviewIndex(Math.max(0, previewIndex - 1));
                  }}>
                  <Icon type={IconType.back} />
                </button>
                <button
                  type={'button'}
                  className="btn btn-default btn-xs"
                  onClick={() => {
                    setPreviewIndex(Math.min(feedbackPreview.length - 1, previewIndex + 1));
                  }}>
                  <Icon type={IconType.next} />
                </button>
              </div>
              <div>
                {previewIndex + 1} of {feedbackPreview.length}
              </div>
            </div>
          </div>
        </Modal>
      )}

      {showSend && (
        <Modal setModalOpen={setShowSend} title={`Send Feedback`} size="xl">
          <Tabs>
            <TabList>
              <Tab><Icon type={IconType.send}/> Message</Tab>
              <Tab><Icon type={IconType.configure}/> Settings</Tab>
            </TabList>
            <TabPanel>
              <div className="form-horizontal">
                <div className="form-group">
                  <label className="col-sm-4 control-label" htmlFor="jfb-ta-subject-send">
                    Subject
                  </label>
                  <div className="col-sm-8">
                    <input
                      type={'text'}
                      id="jfb-ta-subject-send"
                      className="form-control"
                      value={sendInstructions.Subject ?? ''}
                      onChange={(x) => {
                        setSendInstructions({ ...sendInstructions, Subject: x.target.value });
                      }}
                    />
                    <span className="label label-default">Keys: [entry title], and [entry id]</span>
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-sm-4 control-label" htmlFor="jfb-ta-message-send">
                    Message
                  </label>
                  <div className="col-sm-8">
                    <textarea
                      id="jfb-ta-message-send"
                      className="form-control"
                      rows={8}
                      value={sendInstructions.Body ?? ''}
                      onChange={(x) => {
                        setSendInstructions({ ...sendInstructions, Body: x.target.value });
                      }}
                    />

                    <span className="label label-default">Keys: [first name], [last name], [feedback], [entry title] and [entry id]</span>
                  </div>
                </div>
                <h3>What feedback should be shared?</h3>
                <div className="form-group">
                  <div className="col-sm-offset-4 col-sm-8">
                    <input
                      type={'checkbox'}
                      id="jfb-ta-send-onlyReviewed"
                      className="form-controlx"
                      checked={sendInstructions.ReviewedOnly ?? false}
                      onChange={(x) => {
                        setSendInstructions({ ...sendInstructions, ReviewedOnly: x.target.checked });
                      }}
                    />
                    <label className="control-label" htmlFor="jfb-ta-send-onlyReviewed">
                      Only send reviewed feedback.
                    </label>
                  </div>
                </div>
                {(info?.EntryTypes?.length ?? 0) > 0 && (
                  <div className="form-group">
                    <label className="col-sm-4 control-label" htmlFor="jfb-ta-who-entryTYpe">
                      Entry Type
                    </label>
                    <div className="col-sm-8">
                      <select
                        id="jfb-ta-who-entryTYpe"
                        className="form-control"
                        value={sendInstructions.EntryType ?? ''}
                        onChange={(x) => {
                          let value: number | undefined = +x.target.value;
                          if (value === 0) value = undefined;
                          setSendInstructions({ ...sendInstructions, EntryType: value });
                        }}>
                        <option value={'0'}>All Entry Types</option>
                        {info?.EntryTypes.map((x, i) => {
                          return (
                            <option value={x.id} key={`jfb-o-entryTYpe-${i}`}>
                              {x.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                )}
                {(info?.Divisions?.length ?? 0) > 0 && (
                  <div className="form-group">
                    <label className="col-sm-4 control-label" htmlFor="jfb-ta-who-divisions">
                      Division
                    </label>
                    <div className="col-sm-8">
                      <select
                        id="jfb-ta-who-divisions"
                        className="form-control"
                        value={sendInstructions.Division ?? ''}
                        onChange={(x) => {
                          let value: number | undefined = +x.target.value;
                          if (value === 0) value = undefined;
                          setSendInstructions({ ...sendInstructions, Division: value });
                        }}>
                        <option value={'0'}>All Divisions</option>
                        {info?.Divisions.map((x, i) => {
                          return (
                            <option value={x.id} key={`jfb-o-divisions-${i}`}>
                              {x.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                )}
                <div className="form-group">
                  <label className="col-sm-4 control-label" htmlFor="jfb-ta-who-rounds">
                    Rounds
                  </label>
                  <div className="col-sm-8">
                    <select
                      id="jfb-ta-who-rounds"
                      className="form-control"
                      value={sendInstructions.Round ?? ''}
                      onChange={(x) => {
                        let value: number | undefined = +x.target.value;
                        if (value === 0) value = undefined;
                        setSendInstructions({ ...sendInstructions, Round: value });
                      }}>
                      <option value={'0'}>All Rounds</option>
                      {info?.Rounds.map((x, i) => {
                        return (
                          <option value={x.id} key={`jfb-o-round-${i}`}>
                            {x.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <h3>Who should recieve the feedback?</h3>
                <div className="form-group">
                  <div className="col-sm-offset-4 col-sm-8">
                    <input
                      type={'checkbox'}
                      id="jfb-ta-send-to-participants"
                      className="form-controlx"
                      checked={sendInstructions.SendToParticipants ?? false}
                      onChange={(x) => {
                        setSendInstructions({ ...sendInstructions, SendToParticipants: x.target.checked });
                      }}
                    />
                    <label className="control-label" htmlFor="jfb-ta-send-to-participants">
                      Send to Participants(Students)
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-sm-offset-4 col-sm-8">
                    <input
                      type={'checkbox'}
                      id="jfb-ta-send-to-parent"
                      className="form-controlx"
                      checked={sendInstructions.CopyParent ?? false}
                      onChange={(x) => {
                        setSendInstructions({ ...sendInstructions, CopyParent: x.target.checked });
                      }}
                    />
                    <label className="control-label" htmlFor="jfb-ta-send-to-parent">
                      Send to Parents
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-sm-offset-4 col-sm-8">
                    <input
                      type={'checkbox'}
                      id="jfb-ta-send-to-teacher"
                      className="form-controlx"
                      checked={sendInstructions.CopyTeacher ?? false}
                      onChange={(x) => {
                        setSendInstructions({ ...sendInstructions, CopyTeacher: x.target.checked });
                      }}
                    />
                    <label className="control-label" htmlFor="jfb-ta-send-to-teacher">
                      Send to Teachers
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-sm-4"></div>
                  <div className="col-sm-8">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => {
                        sendFeedbackNow(false);
                      }}>
                      <Icon type={IconType.search} /> Preview Feedback
                    </button>
                    <button type="button" className="btn btn-defalt" onClick={sendIt}>
                      <Icon type={IconType.send} /> Send Feedback Now
                    </button>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
                <SetupWizard wizardKey='FeedbackSettings' />
            </TabPanel>
          </Tabs>
        </Modal>
      )}
    </>
  );
};

export default ReduxMap(JudgingFeedback);
