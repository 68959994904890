class EnvironmentConstants {
  
  public emailRegex = /\w+([-+.'/]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;

  public EMPTY_GUID = "00000000-0000-0000-0000-000000000000";

  public PAGE_SIZE = 100;

}

const Constants = new EnvironmentConstants();

export default Constants