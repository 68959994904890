import { useEffect, useState } from 'react';
import { IScheduleRoundRoomTimeSlot, IScheduledRoundEntry } from './NhdTypes';
import { FormatDate } from '../../../utils/Tools';
import Icon, { IconType } from '../../Icon/Icon';
import Modal from '../../Modal/Modal';
import { toast } from 'react-toastify';

interface IProps {
  slot: IScheduleRoundRoomTimeSlot;
  entries: Record<string, IScheduledRoundEntry>;
  active: boolean;
  onSwap: (id: number) => void;
  onClearEntry: (id: number) => void;
  onAssignId: (entryId: string, id: string) => void;
  onAssignRank: (entryId: string, rank?:number) => void;
}

const NhdScheduleSlot = (props: IProps) => {
  const [entry, setEntry] = useState<IScheduledRoundEntry>();
  const [showProjectId, setShowProjectId] = useState<boolean>(false);
  const [showRank, setShowRank] = useState<Boolean>(false);
  const [projectId, setProjectId] = useState<string>('');
  const [rank, setRank] = useState<number>();

  useEffect(() => {
    setEntry(props.entries[props.slot.entryPublicKey ?? '']);
    setProjectId(entry?.entryId ?? '');
    setRank(entry?.rank);
  }, [props.slot.entryPublicKey, props.slot.rank]);

  const onChangeProjectId = () => {
    if (!projectId) {
      toast.warning('Please provide a vaild entry id.');
      return;
    }
    if (entry) props.onAssignId(entry.publicId, projectId);
    setShowProjectId(false);
  };

  const onChangeRank = () =>{
    if(entry) props.onAssignRank(entry.publicId, rank);    
  };

  return (
    <>
      <div className={`nhd-schedule-slot ${entry && props.slot.id > 0 ? 'has-entry' : ''} ${props.active ? 'selected' : ''}`}>
        
      {entry && entry.early && <span className='slot-tag'>{entry.early}</span>}
        <div className="flex-between">
          <div>
            {entry && (
              <>
                <strong
                  className="click"
                  onClick={() => {
                    setShowProjectId(true);
                  }}>
                  <Icon type={IconType.idBadge} />
                  {entry.entryId}
                </strong>
                <span
                  className="text-danger click"
                  title="Clear Entry From Spot"
                  onClick={() => {
                    props.onClearEntry(props.slot.id);
                  }}>
                  <Icon type={IconType.cancel} />
                </span>
              </>
            )}
          </div>
            {entry && <div className='text-info click' title='Click to set ranking' onClick={()=>{
              setShowRank(true);
            }}>
                <Icon type={IconType.award}/> {entry.rank ?? ''}
              </div>} 

          <div className='flex'>
            <div>
            {!props.active ? (
              <span
                className="btn btn-default btn-xs"
                title="Swap"
                onClick={() => {
                  props.onSwap(props.slot.id);
                }}>
                <Icon type={IconType.random} />
              </span>
            ) : props.slot.id === -1 ? (
              <></>
            ) : (
              <span
                className="btn btn-default btn-xs"
                title="Cancel Swap"
                onClick={() => {
                  props.onSwap(props.slot.id);
                }}>
                <Icon type={IconType.cancel} />
              </span>
            )}</div>
            <div className='text-right'>
            {FormatDate(props.slot.at, 'time', true)}            
            </div>
          </div>
        </div>
        {!entry && props.slot.isBreak ? (
          <div className="flex-center">
            <h4 className='text-info'>
              <Icon type={IconType.stopWatch} /> Break
            </h4>
          </div>
        ) : entry ? (
          <div className="text-center">
            <div className="flex-Between">
              <div className="title" title="Entry Title">
                {entry.title}
              </div>
              <div title="Student(s)">{entry.students}</div>
              <div className="slot-footer">
                <div title="Teacher(s)" className="">
                  {entry.teachers}
                </div>
                {props.slot.isBreak && (
                  <div className="flex-center">
                    <h4>
                      <Icon type={IconType.stopWatch} /> Break
                    </h4>
                  </div>
                )}
                <div>
                  {entry.school && (
                    <div className="flex-between" title="School & District">
                      <div>{entry.school}</div>
                      <div className="text-right">{entry.district}</div>
                      {entry.affiliate && <div className="text-right" title='Affiliate'>{entry.affiliate}</div>}
                    </div>
                  )}
                  {!entry.school && entry.affiliate && <div>
                      <div className="text-right" title='Affiliate'>{entry.affiliate}</div>
                    </div>}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="text-warning flex-center">
            <h4>
              <Icon type={IconType.warning} /> Empty Slot
            </h4>
          </div>
        )}
      </div>

      {showProjectId && entry && (
        <Modal setModalOpen={setShowProjectId} title={`Set Entry Id:`} size='l'>
          <div className="form-horizontal">
            <div className="form-group">
              <label className="control-label col-sm-4">Title</label>
              <div className="col-sm-8 form-control-static">
                {entry.title}
              </div>
            </div>
            <div className="form-group">
              <label className="control-label col-sm-4">Student(s)</label>
              <div className="col-sm-8 form-control-static">
                {entry.students}
              </div>
            </div>
            <div className="form-group">
              <label className="control-label col-sm-4" htmlFor='nhd-ss-entry-id'>Entry Id</label>
              <div className="col-sm-4">
                <input
                id='nhd-ss-entry-id'
                  type={'text'}
                  maxLength={20}
                  placeholder="Entry Id"
                  className="form-control"
                  value={projectId}
                  onChange={(x) => {
                    setProjectId(x.target.value);
                  }}
                />
              </div>
            </div>
            <div className="form-group">
              <div className="col-sm-8 col-sm-offset-4">
                <button type="button" className="btn btn-secondary" onClick={onChangeProjectId}>
                  <Icon type={IconType.save} /> Save
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}

      {showRank && entry && (
        <Modal setModalOpen={setShowRank} title={`Set Rank for ${entry.entryId} `} size='l'>
          <div className="form-horizontal">
            <div className="form-group">
              <label className="control-label col-sm-4">Title</label>
              <div className="col-sm-8 form-control-static">
                {entry.title}
              </div>
            </div>
            <div className="form-group">
              <label className="control-label col-sm-4">Student(s)</label>
              <div className="col-sm-8 form-control-static">
                {entry.students}
              </div>
            </div>
            <div className="form-group">
              <label className="control-label col-sm-4" htmlFor='nhd-ss-rank'>Rank</label>
              <div className="col-sm-4">
                <input
                id='nhd-ss-rank'
                  type={'number'}
                  min={0}
                  max={100}
                  maxLength={10}
                  placeholder="Rank"
                  autoFocus={true}
                  className="form-control"
                  value={rank}
                  onChange={(x) => {
                    setRank(x.target.valueAsNumber);
                  }}
                />
              </div>
            </div>
            <div className="form-group">
              <div className="col-sm-8 col-sm-offset-4">
                <button type="button" className="btn btn-secondary" onClick={onChangeRank}>
                  <Icon type={IconType.save} /> Save
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default NhdScheduleSlot;
