import React, { useState, useRef } from "react";
import { Selectable } from "../../utils/CommonTypes/CommonTypes";
import {
  FieldTypes,
  FileUploadData,
  UiFormField,
  UiFormSection,
  UiValuePairs,
} from "../../utils/CommonTypes/UserTypes";
import Select from "react-select";
import LabelHtmlRender from "./LabelHtmlRender";
import ImageLoading, { IImageInfo } from "../ImageEditor/ImageLoading";
import { useEffect } from "react";
import { store } from "../../redux/oldStore";
import { toast } from "react-toastify";
import Icon, { IconType } from "../Icon/Icon";

interface INotListedModuleProps {
  type: string;
  callBack: Function;
  field: UiFormField;
  schools?: UiValuePairs[];
}

const NotListedModule = (props: INotListedModuleProps) => {
  const [formSchoolName, setFormSchoolName] = useState<string>();
  const [formSchool, setFormSchool] = useState<string>();
  const [formTeacherFirst, setFormTeacherFirst] = useState<string>();
  const [formTeacherLast, setFormTeacherLast] = useState<string>();

  const saveClick = () => {
    let data = {
      schoolName: formSchoolName,
      schoolId: formSchool,
      firstName: formTeacherFirst,
      lastName: formTeacherLast,
    };

    let url = "";
    let error = "";
    if (props.type == "Teacher") {
      url = "../Info/AddTeacher";
      if (
        !data.firstName ||
        !data.lastName ||
        !data.schoolId ||
        data.schoolId === "<null value>"
      ) {
        error = "Please provide all of the requested information";
      }
    } else if (props.type == "School") {
      url = "../Info/AddSchool";
      if (!data.schoolName) {
        error = "Please provide all of the requested information";
      }
    }

    if (error) {
      toast.error(error, { autoClose: 15000 });
      return;
    }

    store.server.postApiWithServerResponse<any>(url, data).then((x) => {
      if (x.Success) {
          let field = props.field;
          let name = 'Unknown Name';
          if(props.type == "Teacher") name = `${data.firstName} ${data.lastName}`;
          else if (props.type === "School") name = data.schoolName ?? 'Unknown School'
          field.ValueOptions?.push({Name:name, Value:`${x.Value}`})
        props.callBack(field, x.Value);
      } else {
        toast.error(x.Message);
      }
    });
  };

  return (
    <>
      <div className="form-horizontal">
        <h3>
          <Icon type={IconType.edit}></Icon> Add {props.type}
        </h3>

        {props.type === "Teacher" && (
          <>
            <div className="form-group">
              <label
                className="control-label col-sm-4"
                htmlFor="notlisted-school"
              >
                School
              </label>
              <div className="col-sm-8">
                <select
                  id="notlisted-school"
                  value={formSchool}
                  onChange={(e) => setFormSchool(e.target.value)}
                  className="form-control"
                >
                  <option value="<null value>">select</option>
                  {props.schools?.map((opt, i) => {
                    return (
                      <option value={opt.Value} key={i}>
                        {opt.Name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="form-group">
              <label
                className="control-label col-sm-4"
                htmlFor="notlisted-first"
              >
                Teacher's First Name
              </label>
              <div className="col-sm-8">
                <input
                  id="notlisted-first"
                  type="text"
                  className="form-control"
                  maxLength={40}
                  value={formTeacherFirst}
                  onChange={(e) => setFormTeacherFirst(e.target.value)}
                ></input>
              </div>
            </div>
            <div className="form-group">
              <label
                className="control-label col-sm-4"
                htmlFor="notlisted-last"
              >
                Teacher's Last Name
              </label>
              <div className="col-sm-8">
                <input
                  id="notlisted-last"
                  type="text"
                  className="form-control"
                  maxLength={40}
                  value={formTeacherLast}
                  onChange={(e) => setFormTeacherLast(e.target.value)}
                ></input>
              </div>
            </div>
          </>
        )}
        {props.type === "School" && (
          <>
            <div className="form-group">
              <label
                className="control-label col-sm-4"
                htmlFor="notlisted-schoolname"
              >
                School Name
              </label>
              <div className="col-sm-8">
                <input
                  id="notlisted-schoolname"
                  type="text"
                  className="form-control"
                  maxLength={100}
                  value={formSchoolName}
                  onChange={(e) => setFormSchoolName(e.target.value)}
                ></input>
              </div>
            </div>
          </>
        )}
        <div className="form-group">
          <div className="col-sm-8 col-sm-offset-4">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={saveClick}
            >
              <Icon type={IconType.edit}></Icon> Add {props.type}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotListedModule;
