import { SecurityRole } from "./PeoplePageTypes";

export interface UiFormData {
  targetId: string;
  flowId:string;
  Tabs: UiFormTab[];
  NewRecord: boolean;
  FirstTimeLogin:boolean;
  ByPassRequired:boolean;
  NextRecordPath: string;
  Requirements: ParticipantRequirement[] | null;
  CanSave:boolean;  
  SubForm:UiFormData;
  VerifyEmail:boolean;
  PageTitle:string;
  role:SecurityRole;
  BackTo:UiBackTo;
  Header?:string;
  Footer?:string;
  ButtonText?:string;
  ButtonIcon?:string;
  ButtonTextNew?:string;
}

export interface UiBackTo
{
    ClassName:string;
    Label:string;
    Path:string;
}

export interface SavePersonFormResponse{
  errors: string[],
  targetId: string;
  role: SecurityRole;
  redirectTo:string;
}

export interface UiFormTab {
  Id:string;
  Label: string;
  HtmlBody:string;
  Active: boolean;
  Order: number;
  Sections: UiFormSection[];
  Show: boolean;  
  SpecialType:string;
  DataPoint:string;
  DataPoint2:string;
  targetId: string;
  ButtonText?:string;
  ButtonIcon?:string;
  ButtonTextNew?:string;
}

export interface UiFormSection {
  Label: string;
  Active: boolean;
  Order: number;
  ReadOnly: boolean;
  Fields: UiFormField[];
  HtmlBody:string;
}

export interface UiFormField {
  DefaultValue: string | null;
  GroupId: string | null;
  Order: number;
  Confirm: boolean;
  Required: boolean;
  ReadOnly: boolean;
  Label: string;
  Placeholder: string;
  Key: string;
  Value: string[];
  Files: IFileDetails[];
  FileType:string;
  FileExts:string;
  FileSizeMaxMB?:number;
  Type: FieldTypes;
  ValueOptions: UiValuePairs[] | null;
  MaxLength: number;
  IsParent: boolean;
  OnChangeCallUpdate: Record<string,string> // key = api call, value = key of input to update
  OnChangeCallUpdateQueryString:string;
  // OnChangeCall: string;
  // OnChangeUpdate: string;
  RequireMinLength: number;
  RequireAltCase: boolean;
  RequireNumber: boolean;
  RequireSpecialChar: boolean;
  SpecialChars: string[]|null;
  HeaderBootstrapSize:number;
  BodyBootstrapSize:number;
  ValidationRegularExpression:string;
  Show:boolean;
  NotListSpecial:string;
  NotListedSchoolsDataKey:string;
  AdditionalInformation:string;
  AdjustsTabs:boolean;
  AdjustsTabsQueryString?:string;
  ShowRemaining:boolean;
  Content?:string;
}

export interface IFileDetails{
  IsImage:boolean;
  IsPdf:boolean;
  Caption:string;
  Key:string;
  Extension:string;
  Name:string;
  FlipKey?:number;
  File?:File;
  Base64Url?:string;
}

export enum FieldTypes {
  Textbox = 0,
  Textarea = 1,
  Checkbox = 2,
  DropDown = 3,
  PhoneNumber = 4,
  Date = 5,
  Picture = 6,
  Header = 7,
  Button = 8,
  MultiSelect = 9,
  ProfileImage = 10,
  ProjectFile = 11,
  Password = 12,
  AlertInfo = 13,
  Alert = 14,
  ReCaptcha = 15,
  Color = 16,
  HTML = 17,
  DateTime = 18,
  Number = 19,
  GradeRange = 20,
  File = 21,
  Time = 22,
  HTMLFull = 23,
  ContentHtml = 25,
  ContentVideoLink = 26,
  ContentVideo = 27,
  ContentImageLink = 28,
  ContentImage = 29,
}

export interface UiValuePairs {
  Value: string;
  Name: string;
}

export interface ParticipantRequirement{
  description:string,
  label:string,
  inputType: RequirementInputType,
  value: string,
  sort:number,
  key:string,
}

export enum RequirementInputType{
  checkbox = 0,
  yesNoDropdown =1,
  displayOnly=2,
  SignatureTyped=3,
}

export interface FileUploadData {
      caption: string;
      dataUrl: string;
      filename: string;
    }