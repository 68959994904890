import jsPDF from "jspdf"

export type outputType = "bloburi" | "blob"


export const convertImagesToPdf = async (imageFiles: File[], outputFileName?: string, outputType?: outputType) => {

    let doc: jsPDF | undefined = undefined;
    let first = true;
    const images = await getImagesFromImageFiles(imageFiles);

    //todo:  We may want to loop through all the images and figure a common width to use for all of them.

    for (let i = 0; i < images.length; i++) {
        const image = images[i];
        const portatOrLandscape = calculatePortaitOrLandscape(image)
        console.log('Image:',i, image.height, image.width);
        if (first) {
            doc = new jsPDF(portatOrLandscape, "px", [image.height, image.width]);
            first = false;
        } else {
            doc?.addPage([image.height, image.width], portatOrLandscape);
        }
        doc?.addImage(image, "", 0, 0, image.width, image.height); //todo:  Figure out what dimentions to use.
        // doc.addImage(imageData) //todo:  Figure out what dimentions to use.
    }
    // const dataUrl = doc?.output('datauristring', {filename: outputFileName}) as string;
    //use blob url seems to work best.  When the data url gets too big, the browser seems to have problems rendering it, or the maybe there are problems with producing the dataurl string.
    const output = doc?.output(outputType || "bloburi", { filename: outputFileName || imageFiles[0].name }) as string | Blob;
    // const dataUrl = doc?.output('dataurlnewwindow', {filename: outputFileName}) as string;
    return output;
}

export const convertImagesToPdfAsBlob = async (imageFiles: File[], outputFileName?: string) => {
    return (await convertImagesToPdf(imageFiles, outputFileName, "blob")) as Blob
}

export const convertImagesToPdfAsBlobUri = async (imageFiles: File[], outputFileName?: string) => {
    return (await convertImagesToPdf(imageFiles, outputFileName, "bloburi")) as string
}

export const convertImagesToPdfAsFile = async (imageFiles: File[]) => {
    const filesToReturn: File[] = [];
    for (let i = 0; i < imageFiles.length; i++) {
        const pdfFile = await convertSingleImageToPdfAsFile(imageFiles[i])
        filesToReturn.push(pdfFile);
    }
    return filesToReturn;
}

export const convertSingleImageToPdfAsFile = async (imageFile: File) => {
    const pos = imageFile.name.lastIndexOf(".");
    const currentFileName = imageFile.name.substr(0, pos < 0 ? imageFile.name.length : pos) + ".pdf";
    const blob = await convertImagesToPdfAsBlob([imageFile], currentFileName);
    return new File([blob], currentFileName);
}

const calculatePortaitOrLandscape = (image: HTMLImageElement) => {
    if (image.height > image.width) {
        return "portrait";
    } else {
        return "landscape";
    }
}

const getImagesFromImageFiles = async (imageFiles: File[]) => {
    const images: HTMLImageElement[] = [];
    for (let i = 0; i < imageFiles.length; i++) {
        const file = imageFiles[i]
        // const imageData = await openFile(file);
        const imageData = window.URL.createObjectURL(file);
        const image = await getImage(imageData);

        images.push(image);
    }
    return images;
}

export const getImage = (imageUrl: string) => {
    const image = new Image();
    image.src = imageUrl;
    const promise = new Promise<HTMLImageElement>((resolve, reject) => {
        image.onload = () => {
            //resolve(resizeImage(image,1056,1056));
            resolve(resizeImage(image,750,750));
        }
        image.onerror = (evt) => {
            reject(evt);
        }
    });
    return promise;
}

export const resizeImage = (image:HTMLImageElement, maxWidth:number, maxHeight:number):HTMLImageElement => {
            let width = image.width;
            let height = image.height;
            
            if (width <= maxWidth && height <= maxHeight) {
                return image;
            }

            let newWidth;
            let newHeight;

            if (width > height) {
                newHeight = height * (maxWidth / width);
                newWidth = maxWidth;
            } else {
                newWidth = width * (maxHeight / height);
                newHeight = maxHeight;
            }

            let canvas = document.createElement('canvas');
            canvas.width = newWidth;
            canvas.height = newHeight;

            let context = canvas.getContext('2d');

            if(context!=null){
                context.drawImage(image, 0, 0, newWidth, newHeight);
                image.src = canvas.toDataURL("image/jpeg", );
                console.log('Image was resized.', newWidth, newHeight, width, height);
		        return image;
            }

            return image;
    };


const openFile = (file: File) => {
    const promise = new Promise<Uint8Array>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            const array = new Uint8Array(reader.result as ArrayBuffer);
            resolve(array);
        }
        reader.onerror = () => {
            reject(reader.error);
        }
        reader.readAsArrayBuffer(file);
    })

    return promise;
}

// const openFile = (file: File) => {
//     const promise = new Promise<string>((resolve, reject) => {
//         const reader = new FileReader();
//         reader.onload = () => {
//             const array = reader.result as string;
//             resolve(array);
//         }
//         reader.onerror = () => {
//             reject(reader.error);
//         }
//         reader.readAsDataURL(file);
//     })

//     return promise;
// }