import Editor from "@monaco-editor/react";
import { useState } from "react";

interface IProps {
    valueChanged:(value:any)=>void;
    value?:any;
    type:'javascript'|'css'|'html'|'json'|'typescript'|'less'|'scss';
    className?:string;
    label?:string;
}


const XEditor = (props:IProps) =>{
    const [editor, setEditor] = useState<any>();

    return <>
    {props.label && <h4>{props.label}</h4>}
    <div className={`x-editior ${props.className??''}`}>
        <Editor
      language={props.type}
      theme="vs-dark"
      value={props.value}
      onChange={(x,e)=>{
        props.valueChanged(x);
      }}
      onMount={(x)=>{
        setEditor(x);
        console.log('editor=>',x);
      }}
    />
    
    </div>
    </>

};

export default XEditor;