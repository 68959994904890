import React, { FunctionComponent } from "react";

type LoadingCogProps = {
    loadingMessage: string
}

const LoadingCog: FunctionComponent<LoadingCogProps> = ({loadingMessage}:LoadingCogProps ) => {
    return (
        <div className="col-md-8 col-md-offset-2 text-center">
            <h1>{loadingMessage}</h1>
            <h3>
                <i className="fa fa-cog  fa-spin fa-3x fa-fw"></i>
            </h3>
        </div>
    )
}
export default LoadingCog;