import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { store } from "../../redux/oldStore";
import {
  ParticipantRequirement,
  RequirementInputType,
  UiFormData,
} from "../../utils/CommonTypes/UserTypes";
import { ServerResponse } from "../../utils/Server";
import Icon, { IconType } from "../Icon/Icon";

interface ParticipantRequirementsProps{}

const ParticipantRequirements = (props: ParticipantRequirementsProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const {userId} = useParams();
  const [formData, setFormData] = useState<UiFormData>();
  const [requirements, setRequirements] = useState<ParticipantRequirement[]>(
    []
  );
  // const [formInputs, setFormInputs] = useState<ParticipantRequirementWithKey[]>(
  //   []
  // );
  const [formValues, setFormValues] = useState<Record<string, string>>({});
  const [requiredMissing, setRequiredMissing] = useState(false);

  useEffect(() => {
    getDataFromApi();
  }, []);

  useEffect(() => {
    if(formData?.Requirements && formData.Requirements.length > 0){
      return;
    }
    if (formData && formData.NewRecord && formData.NextRecordPath) {
      navigate(`${formData?.NextRecordPath}/${location.search}`);
    }
  }, [requirements, formData]);

  const getDataFromApi = async () => {
    store.server
      .postApi<ServerResponse<UiFormData>>("../UserProfile/GetRequirements", {
        userId: userId,
      })
      .then((result) => {
        if (!result.Success) {
          toast.error(result.Message, { autoClose: false });
          return;
        }
        setFormData(result.Value);
        let requirements = result.Value.Requirements ?? [];
        setRequirements(requirements);
      });
  };

  const handleFormChange = (key: string, value: string) => {
    setFormValues({ ...formValues, [key]: value });
  };

  const buildInputFromType = (
    inputToBuild: ParticipantRequirement,
    i: number
  ) => {
    switch (inputToBuild.inputType) {
      case RequirementInputType.checkbox:
        return (
          <div className="" key={inputToBuild.key + i}>
            <div className="form-group">
              {inputToBuild.description && (
                <div
                  className=""
                  dangerouslySetInnerHTML={{ __html: inputToBuild.description }}
                ></div>
              )}
              <div className="requirement-flex">
                <input
                  id={inputToBuild.key}
                  name={inputToBuild.key}
                  className={`requirement-checkbox rk-${inputToBuild.key} `}
                  type="checkbox"
                  checked={
                    formValues[inputToBuild.key]?.toLowerCase() === "true"
                  }
                  onChange={(e) =>
                    handleFormChange(inputToBuild.key, `${e.target.checked}`)
                  }
                />
                <label
                  htmlFor={inputToBuild.key}
                  className="control-label"
                  dangerouslySetInnerHTML={{ __html: inputToBuild.label }}
                ></label>
              </div>
            </div>
          </div>
        );
      case RequirementInputType.displayOnly:
        return (
          <div className="" key={inputToBuild.key + i}>
            <div className="form-group">
              {inputToBuild.description && (
                <div
                  className=""
                  dangerouslySetInnerHTML={{ __html: inputToBuild.description }}
                ></div>
              )}
              <div
                className=""
                dangerouslySetInnerHTML={{ __html: inputToBuild.label }}
              ></div>
            </div>
          </div>
        );
        break;
      case RequirementInputType.yesNoDropdown:
        return (
          <div className="" key={inputToBuild.key + i}>
            <div
              className={`form-group
                ${
                  requiredMissing &&
                  (formValues[inputToBuild.key] === "-1" ||
                    !formValues[inputToBuild.key])
                    ? "has-error"
                    : ""
                }
              `}
            >
              {inputToBuild.description && (
                <div
                  className=""
                  dangerouslySetInnerHTML={{ __html: inputToBuild.description }}
                ></div>
              )}
              <label
                htmlFor={inputToBuild.key}
                className=""
                dangerouslySetInnerHTML={{ __html: inputToBuild.label }}
              ></label>
              <div className="col-sm-3">
                <select
                  className={`form-control requirement-yes_no rk-${inputToBuild.key} `}
                  id={inputToBuild.key}
                  name={inputToBuild.key}
                  value={formValues[inputToBuild.key]}
                  onChange={(e) =>
                    handleFormChange(inputToBuild.key, e.target.value)
                  }
                >
                  <option value="-1">choose answer...</option>
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
            </div>
            <div className="group-group clearfix">
              <label className="control-label">If no, why?</label>
              <div className="">
                <input
                  type="text"
                  maxLength={500}
                  className="form-control"
                  placeholder="why..."
                  value={formValues[inputToBuild.key+'-why']}
                  onChange={(e) =>
                    handleFormChange(inputToBuild.key+'-why', e.target.value)
                  }
                ></input>
              </div>
            </div>
            <br />
          </div>
        );
        break;
      case RequirementInputType.SignatureTyped:
        return (
          <div className="form-horizontal" key={inputToBuild.key + i}>
            <div
              className={`form-group
                ${
                  requiredMissing &&
                  (formValues[inputToBuild.key] === "-1" ||
                    !formValues[inputToBuild.key])
                    ? "has-error"
                    : ""
                }
              `}
            >
              {inputToBuild.description && (
                <div
                  className=""
                  dangerouslySetInnerHTML={{ __html: inputToBuild.description }}
                ></div>
              )}
              <div className="form-group">
              <label
                htmlFor={inputToBuild.key}
                className="col-sm-4"
                dangerouslySetInnerHTML={{ __html: inputToBuild.label }}
              ></label>
              <div className="col-sm-3">
                <input
                  className={`form-control requirement-text rk-${inputToBuild.key} `}
                  id={inputToBuild.key}
                  name={inputToBuild.key}
                  value={formValues[inputToBuild.key]}
                  onChange={(e) =>
                    handleFormChange(inputToBuild.key, e.target.value)
                  }
                  maxLength={100}
                  type="text"    
                  placeholder="type your signature"              
                />
              </div>
              </div>
            </div>
            <br />
          </div>
        );
        break;
    }
  };

  const checkIfAllRequiredFilledIn = () => {
    let notAll = false;
    Object.entries(formValues).forEach((keyVal) => {
      if (notAll === true) return;
      if (keyVal[1] === "-1") {
        notAll = true;
      }
    });
    setRequiredMissing(notAll);
    return notAll;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (checkIfAllRequiredFilledIn()) {
      return toast.warning("not all required fields answered.");
    }
    let submitObj = {
      userId: userId,
      responses: formValues,
    };
    let serialized = JSON.stringify(submitObj);
    //store.server.postApi<ServerResponse<any>>('../UserProfile/SaveRequirementResponses', { jsonData: serialized }).then((res) => {
    store.server
      .postApi<ServerResponse<any>>(
        "../UserProfile/SaveRequirementResponses",
        submitObj
      )
      .then((res) => {
        if (res.Success) {
          toast.success("Saved.");
          if(res.Value.redirectTo){
            window.location.href = res.Value.redirectTo;
            return;
          }
          if (formData && formData.NewRecord && formData.NextRecordPath) {
            navigate(`${formData?.NextRecordPath}/${location.search}`);
          }
        } else {
          toast.warning(`${res.Message}`, { autoClose: false });
        }
      });
  };

  return (
    <div className="user-requirements-container">
      {formData && formData.Header && <div className="requirements-header" dangerouslySetInnerHTML={{__html:formData.Header}}></div>}
      <div className="form-horizontal user-requirements">
        {requirements.map((x, i) => buildInputFromType(x, i))}
      </div>
      <div className="form-group">
        <button
          type="submit"
          className="btn btn-success save-and-continue requirements-page"
          onClick={handleSubmit}
        >
          <Icon type={IconType.save} /> Save{" "}
          {formData?.NewRecord ? "& continue" : ""}
        </button>
      </div>
      {formData && formData.Footer && <div className="requirements-footer" dangerouslySetInnerHTML={{__html:formData.Footer}}></div>}
    </div>
  );
};

export default ParticipantRequirements;
