import { IState } from '../../../redux/redux';
import Icon, { IconType } from '../../Icon/Icon';
import { ICircleGeneratorGradeInfo } from './JudgingCirlcesTypes';

interface IProps {
  state: IState;
  gradeInfo: ICircleGeneratorGradeInfo;
  onSet: (info: ICircleGeneratorGradeInfo) => void;
}

const JudgingCircleGenatorGrade = (props: IProps) => {
  const getGrade = (grade?: number) => {
    switch (grade) {
      case 0:
        return 'K';
      case 1:
        return '1st';
      case 2:
        return '2nd';
      case 3:
        return '3rd';
      case 4:
        return '4th';
      case 5:
        return '5th';
      case 6:
        return '6th';
      case 7:
        return '7th';
      case 8:
        return '8th';
      case 9:
        return '9th';
      case 10:
        return '10th';
      case 11:
        return '11th';
      case 12:
        return '12th';
      default:
        return '';
    }
  };

  const getId=(str:string)=>{
    return `${props.gradeInfo.maxGrade}:${str}`;
  };
  return (
    <>
      <tr className="">
        <td>{getGrade(props.gradeInfo.maxGrade)}</td>
        <td>
          <input
            id={getId('gggg-active')}
            type="checkbox"
            checked={props.gradeInfo.active ?? false}
            onChange={(x) => {
              props.onSet({ ...props.gradeInfo, active: x.target.checked });
            }}
          />
          <label className="control-label" htmlFor={getId('gggg-active')}>
            Build <Icon type={IconType.circle} />
          </label>
        </td>
        <td>
          <input
            id={'gggg-prefix'}
            type="text"
            maxLength={10}
            placeholder="prefix"
            value={props.gradeInfo.prefix ?? ''}
            onChange={(x) => {
              props.onSet({ ...props.gradeInfo, prefix: x.target.value });
            }}
          />
        </td>
        <td>
          <select
            id={'gggg-numberOfJudges'}
            value={props.gradeInfo.numberOfJudges}
            onChange={(x) => {
              props.onSet({ ...props.gradeInfo, numberOfJudges: +x.target.value });
            }}>
            <option value="1">1 Judge</option>
            <option value="2">2 Judges</option>
            <option value="3">3 Judges</option>
            <option value="4">4 Judges</option>
            <option value="5">5 Judges</option>
            <option value="6">6 Judges</option>
            <option value="7">7 Judges</option>
            <option value="8">8 Judges</option>
            <option value="9">9 Judges</option>
            <option value="10">10 Judges</option>
            <option value="11">11 Judges</option>
            <option value="12">12 Judges</option>
          </select>
        </td>
        <td>
          <select
            id={'gggg-numberOfProjects'}
            value={props.gradeInfo.numberOfProjects?.toString() ?? '3'}
            onChange={(x) => {
              props.onSet({ ...props.gradeInfo, numberOfProjects: +x.target.value });
            }}>
            <option value="1">1 Project</option>
            <option value="2">2 Projects</option>
            <option value="3">3 Projects</option>
            <option value="4">4 Projects</option>
            <option value="5">5 Projects</option>
            <option value="6">6 Projects</option>
            <option value="7">7 Projects</option>
            <option value="8">8 Projects</option>
            <option value="9">9 Projects</option>
            <option value="10">10 Projects</option>
            <option value="11">11 Projects</option>
            <option value="12">12 Projects</option>
            <option value="15">15 Projects</option>
            <option value="20">20 Projects</option>
            <option value="25">25 Projects</option>
            <option value="30">30 Projects</option>
            <option value="35">35 Projects</option>
            <option value="40">40 Projects</option>
            <option value="45">45 Projects</option>
            <option value="50">50 Projects</option>
            <option value="55">55 Projects</option>
            <option value="60">60 Projects</option>
            <option value="65">65 Projects</option>
            <option value="70">70 Projects</option>
            <option value="75">75 Projects</option>
            <option value="80">80 Projects</option>
            <option value="85">85 Projects</option>
            <option value="90">90 Projects</option>
            <option value="95">95 Projects</option>
            <option value="100">100 Projects</option>
            <option value="105">105 Projects</option>
            <option value="110">110 Projects</option>
            <option value="115">115 Projects</option>
            <option value="120">120 Projects</option>
            <option value="125">125 Projects</option>
            <option value="130">130 Projects</option>
            <option value="135">135 Projects</option>
            <option value="140">140 Projects</option>
            <option value="145">145 Projects</option>
            <option value="150">150 Projects</option>
            <option value="155">155 Projects</option>
            <option value="160">160 Projects</option>
            <option value="165">165 Projects</option>
            <option value="170">170 Projects</option>
            <option value="175">175 Projects</option>
            <option value="180">180 Projects</option>
            <option value="185">185 Projects</option>
            <option value="190">190 Projects</option>
            <option value="195">195 Projects</option>
            <option value="200">200 Projects</option>
          </select>
        </td>
      </tr>
    </>
  );
};

export default JudgingCircleGenatorGrade;
