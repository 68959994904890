import { stringify } from "query-string";
import React, { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import StoreX, { store } from "../../redux/oldStore";
import { ServerResponse } from "../../utils/Server";
import { formatMoney } from "../../utils/Tools";
import Icon, { IconType } from "../Icon/Icon";

interface IOrderHistoryProps{
  userId?: string;
  showWhenEmpty?:boolean;
}

interface IOrderHistoryResponse {
  Orders: IOrder[];
}
interface IOrder {
  Refunded: boolean;
  Paid: boolean;
  AtString: string;
  handlingLabelUrl: string;
  Url: string;
  Id: string;
  Number: number;
  Total: number;
  Items: IItemDetail[];
}

interface IItemDetail {
  name: string;
  details: string[];
  quantity: number;
  cost: number;
  total: number;
  refunded:boolean;
}

const OrderHistory = (props: IOrderHistoryProps) => {
  const [ready, setReady] = useState<boolean>();
  const [orders, setOrders] = useState<IOrder[]>([]);

  useEffect(() => {
    store.server
      .getApi<ServerResponse<IOrderHistoryResponse>>(
        `../purchase/History/${props.userId}`
      )
      .then((res) => handleHistoryResponse(res.Value));
  }, []);

  const handleHistoryResponse = (history: IOrderHistoryResponse) => {
    setOrders(history.Orders);
    setReady(true);
  };

  const mappedProducts = orders.map((prod, i) => {
    return (
      <div key={`${prod.Id}-${i}`} className="order panel panel-default">
        <div className="panel-heading">
          <div className="flex-between">
            <span>Total: {formatMoney.format(prod.Total)}</span>
            <span>
              <Icon type={IconType.fileInvoice} /> {StoreX.Text.OrderIdLabel}:{" "}
              <a href={prod.Url} target="_blank" title="view invoice / PO">
                {prod.Id}
              </a>
            </span>
            <span>{prod.AtString}</span>
          </div>
        </div>
        <div className="panel-body">
          {!prod.Paid && (
            <div className="alert alert-warning">
              <strong>
                <Icon type={IconType.warning}></Icon> Pending Order:
              </strong>
              This order is still pending; please send payment with invoice.
            </div>
          )}
          {prod.Refunded && (
            <div className="alert alert-danger">
              <strong>
                <Icon type={IconType.refund}></Icon> Order Refunded:
              </strong>
              This order has been refunded.
            </div>
          )}

          {prod.handlingLabelUrl && (
            <div>
              <a
                href={prod.handlingLabelUrl}
                className="btn btn-xs btn-primary"
                target="_blank"
                title="print handling labels"
              >
                Print handling Labels
              </a>
            </div>
          )}
          <div className="col-sm-12">
            <table className="table table-striped">
              <tr>
                <th>Item</th>
                <th></th>
                <th>Quantity</th>
                <th className="text-right">Cost</th>
                <th className="text-right">Line Total</th>
              </tr>
              {prod.Items &&
                prod.Items.length > 0 &&
                prod.Items.map((x, i: number) => {
                  
                  return (
                    <tr key={`item-${i}`} className={x.refunded ? 'order-item-refunded text-muted':''} title={x.refunded ? 'Item removed':''}>
                      <td>
                        {x.refunded && <Icon type={IconType.trashO} />}
                        {x.name}</td>
                      <td dangerouslySetInnerHTML={{__html:x.details?.join("<br/>")}}></td>
                      <td>{x.quantity}</td>
                      <td className="text-right">
                        {formatMoney.format(x.cost)}
                      </td>
                      <td className="text-right">
                        {formatMoney.format(x.total)}
                      </td>
                    </tr>
                  );
                })}
            </table>
          </div>
        </div>
      </div>
    );
  });

  return (
    <>
      {ready && orders && orders.length > 0 && (
        <>
          <div className="order-history-panel">
            <h4>Order History</h4>
            {mappedProducts}
          </div>
        </>
      )}
      {ready && props.showWhenEmpty && (!orders || orders.length === 0) && <>
        <div className="order-history-panel">
            <h4>Order History</h4>
            No orders.
          </div>
      </>}
    </>
  );
};

export default OrderHistory;
