import { useParams } from "react-router-dom";
import {IState, ReduxMap } from "../../../redux/redux";
import FlexFlowManager from "../../../Components/FlexFlow/Setup/FlexFlowManager";

interface IProps extends IState{

}

const FlexFlowManagerPage = (props:IProps) =>{
  const {flowId} = useParams();

    return <>
        {props.IsReady && flowId && <FlexFlowManager state={props} flowId={flowId} />}
    </>;
};

export default ReduxMap(FlexFlowManagerPage);