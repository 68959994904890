import React, { useRef, useState, useEffect } from 'react';
import Icon, { IconType } from '../Icon/Icon';
import Modal from '../Modal/Modal';
import { IFileDetails, UiFormField } from '../../utils/CommonTypes/UserTypes';
import StoreX, { store } from '../../redux/oldStore';
import { ServerResponse } from '../../utils/Server';
import { toast } from 'react-toastify';
import PdfBasicViewer from '../PdfViewer/PdfBasicViewer';
import { convertSingleImageToPdfAsFile } from '../../utils/PdfConverter';

interface IFileUploadProps {
  fileUrl?: string;
  fileKey?: string;
  fileType: string;
  exts: string[];
  uploadFile: Function;
  removeFile: Function;
  formNotImage?: boolean;
  formShowPreview: boolean;
  maxSize?: number;
  convertToPdf?: boolean;
}

const FileUpload = (props: IFileUploadProps) => {
  const [showUpload, setShowUpload] = useState<boolean>(false);
  const [fileDetails, setFileDetails] = useState<string>();
  const [newFileInfo, setNewFileInfo] = useState<IFileDetails | null>();
  const [fileDataUrl, setFileDataUrl] = useState<any>();
  const [selectedFile, setSelectedFile] = useState<File>();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [baseDir, setBaseDir] = useState<string>(props.formNotImage ? 'Form' : 'Image');
  const [showPreview, setShowPreview] = useState<boolean>(props.formShowPreview);

  useEffect(() => {
    setFileDetails(``);
  }, []);

  useEffect(() => {
    if (selectedFile) {
      const objectUrl = URL.createObjectURL(selectedFile);
      setFileDataUrl(objectUrl);

      // free memory when ever this component is unmounted
      return () => URL.revokeObjectURL(objectUrl);
    } else {
      setFileDataUrl(undefined);
    }
  }, [selectedFile]);

  const showUploadModalClick = () => {
    setShowUpload(true);
  };

  const handleFileChange = (e) => {
    if (e.target.files?.[0]) {
      let file = e.target.files[0];
      let size = (file.size / 1024 / 1024).toFixed(1);
      let name = file.name;
      let ext = name.substring(name.lastIndexOf('.')).toLowerCase();
      let isPdf = ext === '.pdf';
      let isImage = ext === '.jpg' || ext === '.jpeg' || ext === '.png' || ext === '.tif' || ext === '.tiff' || ext === '.gif';
      setNewFileInfo({
        Caption: '',
        Extension: ext,
        IsImage: isImage,
        IsPdf: isPdf,
        Key: '',
        Name: name,
      });

      if (!isFileValid(file)) {
        e.target.files = null;
        if (fileInputRef.current) fileInputRef.current.value = '';
        //setFileDetails(`the selected file is not valid;`);
        setNewFileInfo(null);
        setFileDetails(``);
        return;
      }

      if (props.convertToPdf && isImage) {
        convertSingleImageToPdfAsFile(file).then((pdfFile: File) => {
          setSelectedFile(pdfFile);
          name = pdfFile.name.substring(0, pdfFile.name.lastIndexOf('.')).toLowerCase() + '.pdf';
          setNewFileInfo({
            Caption: '',
            Extension: '.pdf',
            IsImage: false,
            IsPdf: true,
            Key: '',
            Name: name,
          });

          setFileDetails(`${name} ${size} MB`);
        });
      } else {
        setFileDetails(`${name} ${size} MB`);
        setSelectedFile(file);
      }
    } else {
      setFileDetails(``);
      setSelectedFile(undefined);
    }
  };

  const isFileValid = (file) => {
    let size = file.size / 1024 / 1024;
    let name = file.name;
    let ext = name.substring(name.lastIndexOf('.')).toLowerCase();
    let isPdf = ext === '.pdf';
    let isImage = ext === '.jpg' || ext === '.jpeg' || ext === '.png' || ext === '.tif' || ext === '.tiff' || ext === '.gif';
    let isMp4 = ext === '.mp4' || ext === '.mov';

    if (!isPdf && !isMp4 && !isImage) {
      toast.warning(`Sorry, the file "${name}" is not valid.`);
      return false;
    }

    let maxSize = 20;
    if (props.maxSize && props.maxSize > 0) maxSize = props.maxSize;
    else if (isMp4) maxSize = 1024;

    if (size > maxSize) {
      toast.warning(`The file "${name}" is too large. The max file size is ${maxSize.toFixed(2)} MB`);
      return false;
    }

    if (!props.exts.find((x) => x.toLowerCase() === ext)) {
      toast.warning(`The selected file type is not permitted.`);
      return false;
    }

    return true;
  };

  const uploadFileClick = () => {
    handleFilesForSaving();
    setShowUpload(false);
  };

  const handleFilesForSaving = () => {
    let file = selectedFile; 
    let newFile = newFileInfo;

    if (!file || !newFile) return;

    props.uploadFile([file]);
  };

  const handleDeleteFile = () => {
    if (window.confirm(`Are you sure you want to delete this file?`)) {
      props.removeFile();
    }
  };

  return (
    <>
      <div className="file-input-container">
        <div className="file-input-files"></div>
      </div>
      <div className="flex-between">
        {!props.fileKey && (
          <div>
            <button type="button" className="btn btn-default" onClick={showUploadModalClick} title="add a file">
              <Icon type={IconType.upload} /> Upload File
            </button>
          </div>
        )}
      </div>
      {props.fileKey && showPreview && (
        <>
          <div className="file-input-preview-sizer">
            <div className="file-preview">
              <embed
                className="gallery-file-x file-preview-grow"
                src={`${StoreX.BuildUrl(`/File/${baseDir}/${props.fileKey}`)}#toolbar=0&navpanes=0&view=FitH`}
                width="100%"
                height="100%"
                type="application/pdf"
              />
              <div className="gallery-control-panel">
                <button type="button" className="btn btn-default" title={`Delete this file`} onClick={handleDeleteFile}>
                  <Icon type={IconType.delete} />
                  Delete File
                </button>
                <a href={StoreX.BuildUrl(`/File/${baseDir}/${props.fileKey}`)} target="_blank" title="view file">
                  Open
                </a>
              </div>
            </div>
          </div>
        </>
      )}

      {props.fileKey && !showPreview && (
        <>
          <div className="">
            <button type="button" className="btn btn-default" title={`Delete this file`} onClick={handleDeleteFile}>
              <Icon type={IconType.delete} />
              Delete File
            </button>
            <a href={StoreX.BuildUrl(`/File/${baseDir}/${props.fileKey}`)} target="_blank" title="view file" className="btn btn-default">
              <Icon type={IconType.filePdf} />
              Open
            </a>
          </div>
        </>
      )}

      {showUpload && (
        <>
          <Modal setModalOpen={setShowUpload} title="File Upload" className="file-input-modal-sizer">
            <div className="form-horizontal">
              <div className="form-group">
                <label className="control-label col-sm-4" htmlFor={`select-file`}>
                  File
                </label>
                <div className="col-sm-8">
                  <input id={`select-file`} type="file" className="form-control" ref={fileInputRef} onChange={handleFileChange} accept={props.exts?.join(',') ?? '*'} />
                  <div className="text-muted flex-between">
                    <div>{fileDetails}</div>
                    <div>
                      {' '}
                      {newFileInfo && fileDataUrl && (
                        <div className="">
                          <div className="text-center">
                            <strong>Preview</strong>
                          </div>
                          <PdfBasicViewer dataUrl={fileDataUrl} scale={0.15} />
                          {/* <img src={selectedFile.stream}/> */}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="col-sm-8 col-sm-offset-4">
                  <button type="button" className="btn btn-secondary upload-file-btn" title="click to upload file." onClick={uploadFileClick}>
                    <Icon type={IconType.upload} /> Upload File
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default FileUpload;
