import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import StoreX from '../../../redux/oldStore';
import { IState, ReduxMap } from '../../../redux/redux';
import './styles.css';
import Icon, { IconType } from '../../../Components/Icon/Icon';
import NhdSchedule from './NhdSchedule';
import JudgeGroupManager from '../../../Components/JudgingSetup/JudgeGroup/JudgeGroupManager';
import NhdAdminPanel from '../../../Components/Client/NHD/NhdAdminPanel';

interface IProps extends IState {}

const NhdScheduleAndJudging = (props: IProps) => {
  return (
    <>
      <Tabs>
        <TabList>
          <Tab>
            <Icon type={IconType.build} /> Judging Schedule
          </Tab>
          <Tab>
            <Icon type={IconType.gavelDuo} /> Judge Groups
          </Tab>
          <Tab>
            <Icon type={IconType.dashboard} /> Admin Panel
          </Tab>
        </TabList>
        <TabPanel>
          <NhdSchedule />
        </TabPanel>
        <TabPanel>
          <JudgeGroupManager state={props} />
        </TabPanel>
        <TabPanel>
          <NhdAdminPanel state={props} />
        </TabPanel>
      </Tabs>
    </>
  );
};

export default ReduxMap(NhdScheduleAndJudging);
