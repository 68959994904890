import React, { useState } from 'react'

interface ISuperQuizContainerProps {

}


const SuperQuizContainer = (props: ISuperQuizContainerProps) => {
  const isJudge = true;
  const teamMembers = ['Steve', 'Susan', 'Gertrude', "O'Doyle"]

  const mappedTeamMembers = teamMembers.map( (person: string, i: number) => {
    return(
      <div className="user-card flex-column" key={i}>
        <i className="fas fa-user fa-3x" />
        {person}
      </div>
    )
  })


  return (
    <div className='super-quiz-container round-thick-border'>
      { isJudge
        ?
        <div>
          <div className='judge-top-section '>
            <div className="video-box round-thick-border">
              video area
              <div className="user-card flex-column ">
                <i className="fas fa-user fa-3x" />
                Judge
              </div>
            </div>
            <div className='judge-quiz-box round-thick-border'>
              <h4>Question 1</h4>
              <hr />
              <div className='question'>Who shot the sheriff?</div>
              <div className='answer'>A. these are presentational because this is a judge</div>
              <div className='answer'>*B. I did, but not the deputy //flagged for the correct answer</div>
              <div className='answer'>C. It wasn't me. (AKA The Shaggy Defense)</div>
              <div className='answer'>D. Wait, the sheriff was shot?</div>
              <button>Show Next</button>
            </div>
          </div>

          <div className='bottom-section '>
            <div className='team-box round-thick-border'>
              <h4>Team A</h4>
              <div className='team-member-area'>
                {mappedTeamMembers}
              </div>
            </div>
            <div className='team-box round-thick-border'>
              <h4>Team B</h4>
              <div className='team-member-area'>
                {mappedTeamMembers}
              </div>
            </div>
            <div className='team-box round-thick-border'>
              <h4>Team C</h4>
              <div className='team-member-area'>
                {mappedTeamMembers}
              </div>
            </div>
          </div>


        </div>
        :
        <div className='flex'>

          <div className='student-left-section'>
            <div className='video-box round-thick-border'>
              video area
              <div className="user-card flex-column">
                <i className="fas fa-user fa-3x" />
                Judge
              </div>
            </div>
            <div className='team-box bumper-out-l round-thick-border'>
              <h4>Team A</h4>
              <div className='team-member-area'>
                {mappedTeamMembers}
              </div>
            </div>
          </div>
          <div className='student-quiz-box round-thick-border'>
            <h4>Question 1</h4>
            <hr />
            <div className='question'>Who shot the sheriff?</div>
            <div className='question-selection'>
              <input
                id="event-type1"
                type="radio"
                name="event-type"
                value="stem"
                // onClick={e => setContestType(e.target.value)}
                // checked={contestType === 'stem'}
              />
              <label htmlFor='event-type1'>
                A. these should be radio buttons
              </label>
            </div>
            <div className='question-selection'>
              <input
                id="event-type2"
                type="radio"
                name="event-type"
                value="stem"
                // onClick={e => setContestType(e.target.value)}
                // checked={contestType === 'stem'}
              />
              <label htmlFor='event-type2'>
                B. I did, but not the deputy
              </label>
            </div>
            <div className='question-selection'>
              <input
                id="event-type3"
                type="radio"
                name="event-type"
                value="stem"
                // onClick={e => setContestType(e.target.value)}
                // checked={contestType === 'stem'}
              />
              <label htmlFor='event-type3'>
                C. It wasn't me. (AKA The Shaggy Defense)
              </label>
            </div>
            <div className='question-selection'>
              <input
                id="event-type4"
                type="radio"
                name="event-type"
                value="stem"
                // onClick={e => setContestType(e.target.value)}
                // checked={contestType === 'stem'}
              />
              <label htmlFor='event-type4'>
                D. Wait, the sheriff was shot?
              </label>
            </div>

            <button>Submit</button>
          </div>

        </div>


      }
    </div>
  )
}

export default SuperQuizContainer