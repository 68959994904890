import React from "react";
import io from "socket.io-client";
import { DocTogetherVideo, Participant } from "../../utils/DocTogetherVideo";
import StoreX, { store } from "../../redux/oldStore";
import { toast } from "react-toastify";
import DangerousInnerHtml from "./DangerousInnerHtml";
import audioPlayer from "../../utils/AudioPlayer";
// import ParticipantTimer from "./ParticipantTimer";
// import Modal from "../Modal/Modal";
import TimerContainer from "./TimerContainer";
import { getDefaultFormatCodeSettings } from "typescript";
import Modal from "../Modal/Modal";
import FirstTimeModal from "./FirstTimeModal";
import JustVideoAdminPanel from "../JustVideo/JustVideoAdminPanel";
import SupportChat from "../SupportChat/SupportChats";

import { formatPin } from "../../utils/Tools";
import Icon, { IconType } from "../Icon/Icon";
import { ScreenRecorder } from "../ScreenRecording/ScreenRecorder";
import { ServerResponse } from "../../utils/Server";
import ScheduleRequestedInterview from "../AdvancedJudging/ScheduleRequestedInterview";
import RequestModeratorInterpreter from "../AdvancedJudging/RequestModeratorInterpreter";
import { ScreenRecorder2 } from "../ScreenRecording/ScreenRecorder2";

type person = {
  personId: string;
  judge: boolean;
};

type roomList = { people: person[]; roomId: string; projectId?: string };
interface IPlopLine {
  TakenAt: string;
  Line: ILineDetail[];
}
interface ILineDetail {
  CategoryId: number;
  TimesJudged: number;
  ProjectId: string;
  Waiting: string;
}

type videoContainerState = {
  fairId: string;
  clientId: string;
  personId: string;
  projectId: string;
  meetingId:string;
  customText: string;
  isVideoAllowed: boolean;
  joined: boolean;
  showPreparedSpeechTimer: boolean;
  showImpromptuPrepTimer: boolean;
  showImpromptuSpeechTimer: boolean;
  showInterviewTimer: boolean;
  showPromptModal: boolean;
  topics: any[];
  settings: any;
  everythingInfo: any;
  categories: any[];
  category: any;
  readyToJoinAutoPlop: boolean;
  plopHandle: number | null;
  popApiUrl: string | null;
  gateOverridden: boolean;
  roomList: roomList | person[];
  participants: Participant[];
  plopTimeWaiting: string | null;
  plopLine: IPlopLine | null;
  plopMetaDataLoaded: boolean;
  showFirstTimeModal: boolean;
  videoGateIntervalHandle: number;
  videoCallInModalOpen: boolean;
  videoCallInPhoneNumber: string[];
  videoCallInPin: string;
  roomInfoDump: any;
  user: IUser | null;
  interviewTimes: IInterviewTime[] | null;
  requestedInterviews: IInterviewTime[];
  showJudgeFeedbackModal: boolean;
  interviewForm:IInterviewTime;
  selectedJudgeFeedback:any;
  showSetInterviewSchedule:boolean;

};

interface IInterviewTime{
  at:string,
  id:number,
  type:string,
  interview:string,
  UseJudgesVirtualRoom?:boolean;
  ShowBanner?:boolean;
  InterviewRoomLink?:string;
}

interface IUser {
  id: string;
  firstName: string;
  lastname: string;
  isStaff: boolean;
  email: string;
}

type videoContainerProps = any;

let docTo: DocTogetherVideo;

class ParticipantVideoContainer extends React.Component<
  videoContainerProps,
  videoContainerState
> {
  docVideo?: DocTogetherVideo;
  socketMgr;
  _videoGateInterval;
  // audioPlayer;
  // TIMER_START_SOUND: () => void;
  // TIMER_30_SECOND_WARNING: () => void;
  // TIMER_TIMES_UP: () => void;
  constructor(props) {
    super(props);
    this.state = {
      fairId: "",
      clientId: "",
      personId: "",
      projectId: "",
      meetingId:"",
      customText: "",
      joined: false,
      isVideoAllowed: true,
      showPreparedSpeechTimer: false,
      showImpromptuPrepTimer: false,
      showImpromptuSpeechTimer: false,
      showInterviewTimer: false,
      showPromptModal: true,
      topics: [],
      settings: null,
      everythingInfo: {},
      categories: [],
      category: null,
      readyToJoinAutoPlop: false,
      plopHandle: null,
      popApiUrl: null,
      gateOverridden: false,
      roomList: [],
      participants: [],
      plopTimeWaiting: null,
      plopLine: null,
      plopMetaDataLoaded: false,
      showFirstTimeModal: false,
      videoGateIntervalHandle: 0,
      videoCallInModalOpen: false,
      videoCallInPhoneNumber: [],
      videoCallInPin: "",
      roomInfoDump: {},
      user: null,
      interviewTimes: [],
      interviewForm:{at:'',id:0,type:'',interview:''},
      selectedJudgeFeedback:null,
      showJudgeFeedbackModal:false,
      requestedInterviews:[],
      showSetInterviewSchedule:false,
    };

    this.socketMgr = {
      init: () => {
        let url =
          window.location.host.indexOf("localhost") > -1
            ? "http://localhost:9191/online-judging"
            : "https://socket.zfairs.com/online-judging";
        let socket = (this.socketMgr.socket = io(url, {
          query: {
            fairId: this.state.fairId,
            clientId: this.state.clientId,
            personId: this.state.personId,
            projectId: this.state.projectId,
            judge: false,
          },
          transports: ["websocket"],
        }));

        console.log("!!!!!!!!", socket);

        socket.emit("intro", {
          fairId: this.state.fairId,
          clientId: this.state.clientId,
          personId: this.state.personId,
          projectId: this.state.projectId,
          judge: false,
        });

        socket.on("who", (x) => {
          console.log("Asked who", x);
          socket.emit("intro", {
            fairId: this.state.fairId,
            clientId: this.state.clientId,
            personId: this.state.personId,
            projectId: this.state.projectId,
            judge: false,
          });
        });

        console.log('init - on room message')
        socket.on("room-message", (x: any) => {          
          console.log('init - room message called')
          this.handleRoomMessage(x);
        });
        socket.on("room-message2", (x: any) => {          
          console.log('init - room message 2 called')
          this.handleRoomMessage(x);
        });

        socket.on("video-gate-override", (x) => {
          this.setState({ gateOverridden: x.on });
          console.log("Socket---> video-gate-override: ", x);
        });

        socket.on("dashboard-announcement", (data) => {
          switch (data.type) {
            case "bell":
              if (
                !data.id ||
                data.id === this.state.projectId ||
                data.id === "projects"
              ) {
                toast.info(data.message || "Ring, Ring, Ring...", {
                  autoClose: 7000,
                });
                audioPlayer.sound.bell();
              }
              break;
            case "message":
              if (
                !data.id ||
                data.id === this.state.projectId ||
                data.id === "projects"
              ) {
                toast.info(data.message, { autoClose: false });
                audioPlayer.sound.attention();
              }
              break;

            case "reload":
              if (
                !data.id ||
                data.id === this.state.projectId ||
                data.id === "projects" ||
                this.state.personId === data.id
              ) {
                toast.error("Refreshing the page.");
                window.setTimeout(() => {
                  window.location.reload();
                }, 3000);
              }
              break;
            case "force-logout":
              if (
                !data.id ||
                data.id === this.state.projectId ||
                data.id === "projects" ||
                this.state.personId === data.id
              ) {
                toast.error(`You are being signed out.`);
                store.server.postApi("../User/SignOut", {});
                window.setTimeout(() => {
                  window.location.href = window.location.origin;
                }, 3000);
              }
              break;
            default:
              break;
          }
        });
      },

      hangUp: () => {        
        if(this.state.meetingId) {
          console.log('init - leave meeting room');          
          this.socketMgr.socket.emit('person-out-conference', {meetingId:this.state.meetingId});
        }
      },
      connected: () => {
        
        this.socketMgr.socket.emit('person-in-conference', {meetingId:this.state.meetingId});
        if(this.state.meetingId) {
          console.log('init - joining meeting room');          
          this.socketMgr.socket.emit("join-meeting-room",{roomId:this.state.meetingId});
        }
      },
      closeGate: () => {
        this.socketMgr.socket.emit("video-gate-override", {
          on: false,
          projectId: this.state.projectId,
        });
      },
    };
  }

  private JoinedChanged = () => {
    const joined = this.state.joined;
    const appEl = document.querySelector("body");
    if (joined) appEl?.classList.add("participant-video");
    else appEl?.classList.remove("participant-video");
  };

  componentDidMount() {
    this.doThisFirst();
    window.document.title = `Participant Video`;
  }

  componentDidUpdate(
    prevProps: videoContainerProps,
    prevState: videoContainerState
  ) {
    if (this.state.fairId !== prevState.fairId) {
      this.init();
      this.checkFirstTime();
    }

    if (this.state.settings.onlineJudgingeVideoGate) {
      let numJudges = this.state.participants.filter(
        (p) => p.username && !p.username.startsWith("Student")
      ).length;
      let locked = numJudges === 1;

      if (this.state.gateOverridden) locked = false;

      if (locked && prevState.isVideoAllowed) {
        this.docVideo?.muteAudio();
        this.docVideo?.muteVideo();

        //todo:  fix this interval.  It doesn't work like this because this.state.isVideoAllowed will always be the initial value inside the set interval.
        const handle = window.setInterval(() => {
          if (!this.GetIsVideoOpen()) {
            this.docVideo?.muteAudio();
            this.docVideo?.muteVideo();
          }
        }, 2000);
        this.setState({
          isVideoAllowed: false,
          videoGateIntervalHandle: handle,
        });

        if (this.state.participants.length > 1) {
          this.docVideo?.muteAllVideo();
          this.docVideo?.muteAllAudio();
        }
      } else if (!locked && !prevState.isVideoAllowed) {
        if (this.state.videoGateIntervalHandle)
          window.clearInterval(this.state.videoGateIntervalHandle);

        this.setState({ isVideoAllowed: true, videoGateIntervalHandle: 0 });
        this.docVideo?.unMuteAudio();
        this.docVideo?.unMuteVideo();

        if (this.state.participants.length > 1) {
          // this.docVideo?.unmuteAllVideo();
          // this.docVideo?.unmuteAllAudio();
        }
      }
    }

    if (this.state.joined !== prevState.joined) {
      if (this.state.joined) this.docVideo?.implementStyles();
    }
  }

  GetIsVideoOpen() {
    return this.state.isVideoAllowed;
  }

  componentWillUnmount() {
    window.clearInterval(this._videoGateInterval);
    this.socketMgr?.socket?.close();

    if (this.state.videoGateIntervalHandle)
      window.clearInterval(this.state.videoGateIntervalHandle);
  }

  getMyRoomCall = async (meetingId?:string) => {
    store.server
      .postApi<any>("../Hinge/GetMyRoom", {})
      .then((res) => {
        let accessToken = res.Value;
        if(meetingId) accessToken.id = meetingId;        
        this.docVideo?.connect(accessToken, this.state.settings.enableChat);
        this.setState({ joined: true }, this.JoinedChanged);
        this.setState({ roomInfoDump: accessToken });
        if (this.state.settings.onlineVideoShowCallInNumber) {
          this.setState({
            videoCallInPhoneNumber: accessToken.phoneNumber,
            videoCallInPin: accessToken.conferencePin,
          });
          // this.getRoomPhoneCallPin(accessToken.id)
        }
      })
      .catch((err) => {
        toast.error("something went wrong getting my room");
        console.log("my room failed", err);
      });
  };

  submitInterviewFeedback = () =>{
    let feedback = this.state.interviewForm;
    let errors:string[] = [];
    
    if(!feedback.interview) errors.push('Interview');

    if(errors.length > 0){
      toast.warn(<>Missing fields:
      <ul>
        {errors.map((x,i)=>{
          return (<li>{x}</li>)
        })}
        </ul>
      </>)
      return;
    }

    store.server.postApi<ServerResponse<any>>('../hinge/ParticpantsFeedbackOnJudge', feedback)
    .then(x=>{
      if(x.Success){
        //what do we want to do...

        let times = [...this.state.interviewTimes ?? []];
        for(let i = 0; i < times.length; i++){
          let t = times[i];
          if(t.id === feedback.id && t.type === feedback.type){
            t.interview = feedback.interview;
            break;
          }
        }
        this.setState({interviewTimes:times, showJudgeFeedbackModal:false});


      } else {
        toast.error(x.Message, {autoClose:false});
      }
    });

  };

  getOtherInfo = async () => {
    store.server
      .postApi<any>("../Hinge/Info", {})
      .then((res) => {
        console.log("info response", res);
        if(res.Success){

        let interviewTimesTemp:IInterviewTime[] = [];
        let requestedInterviews:IInterviewTime[] = [];
        let timeDic = {};
        if (res.Value.assignments) {
          res.Value.assignments.forEach((x: IInterviewTime, i: number) => {
            if(x.UseJudgesVirtualRoom) {
              requestedInterviews.push(x);
            }
            if (x.at && !timeDic[x.at]) {
              timeDic[x.at] = true;
              interviewTimesTemp.push(x);
            }
          });
        }



        this.setState({
          fairId: res.Value.fairId,
          clientId: res.Value.clientId,
          personId: res.Value.user.id,
          projectId: res.Value.projectKey,
          customText: res.Value.customTextBefore,
          settings: res.Value.settings,
          categories: res.Value.categories,
          everythingInfo: res.Value,
          category: res.Value.project.category_id,
          popApiUrl: res.Value.popApiUrl,
          user: res.Value.user,
          interviewTimes: interviewTimesTemp,
          meetingId : res.Value.meetingId,
          requestedInterviews: requestedInterviews,
          showSetInterviewSchedule:false,
        });
      }else {
        toast.error(res.Message, {autoClose:false});
      }
      })
      .catch((err) => console.log(err));
  };

  changeCategory = (categoryId) => {
    if (this.state.readyToJoinAutoPlop) {
      toast.warning(
        "You can't change your category while ready to join a meeting."
      );
      return;
    }
    store.server
      .getApi<any>("../Hinge/UpdateProjectCategory/" + categoryId)
      .then((res) => {
        this.setState({
          category: categoryId,
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to change category.", { autoClose: false });
      });
  };

  doThisFirst = async () => {
    await this.getOtherInfo();
  };

  connected = () => {
    this.socketMgr.connected();
    this.setState(
      {
        joined: true,
        gateOverridden: false,
        //isVideoAllowed: !this.state.settings.onlineJudgingeVideoGate,
      },
      this.JoinedChanged
    );
    if(this.startRecording) this.startRecording();
  };
  hungUp = () => {
    this.socketMgr.hangUp();
    this.setState({ joined: false, gateOverridden: false }, this.JoinedChanged);
    if(this.stopRecording) this.stopRecording();
  };

  handleHangup = () => {
    this.docVideo?.hangUp();
    if(this.stopRecording) this.stopRecording();
  };

  stopRecording:Function = ()=>{};
  startRecording:Function = ()=>{};
  recordingCallbacks= (stopRecording:Function, startRecording:Function)=>{
    this.stopRecording = stopRecording;
    this.startRecording = startRecording;
  };

  init = async () => {
    this.socketMgr.init();

    if (!docTo) {
      docTo = new DocTogetherVideo("#docVideo", {
        dev: false, //Change this back to false before merging to master
        hangUp: this.hungUp,
        connectCallback: this.connected,
        setParticipants: this.setParticipants,
        jitsi: this.state.settings.useJitsi,
        hideMuteAll:
          this.state.settings.onlineJudgingHideStudentVideoButtonMuteAll,
        // hideButtons: true,
      });
    } else {
      docTo.connectCallback = this.connected;
      docTo.hangUpCallback = this.hungUp;
      docTo.initComplete = false;
      docTo.setParticipants = this.setParticipants;
    }

    this.docVideo = docTo;
    this.docVideo.init();

    let gateElement = window.document.querySelector(".video-gate");
    if (gateElement) {
      gateElement.innerHTML = `<div><i class="fad fa-lock-alt></i> video locked while we wait for judges.</div>`;
    }
  };

  handleStartVideoClick = async (meetingId?:string) => {    
     await this.docVideo?.init();
    await this.getMyRoomCall(meetingId);
    if(this.state.settings.autoRecording){

    }
  };

  handleOverrideOverride = () => {
    this.socketMgr.closeGate();
    this.setState({ gateOverridden: false });
  };

  setParticipants = (participants: Array<Participant>) => {
    this.setState({ participants: participants }, () => {});
  };
  setShowJudgeFeedbackModal = (bool: boolean)=>{
    this.setState({ showJudgeFeedbackModal: bool });
  }

  setScheduleIntervalModal = (on:boolean, assignment:IInterviewTime)=>{
    this.setState({showSetInterviewSchedule:on, interviewForm:assignment});
  }
  setShowFirstTimeModal = (bool: boolean) => {
    this.setState({ showFirstTimeModal: bool });
  };

  handleRoomMessage = (body: any) => {
    console.log('Room Message', body);
    if (body.message) {
      toast.info(body.message);
    }

    const myId = this.docVideo?.jitsiApi?._myUserID;

    if (body.mute === myId) {
      this.docVideo?.muteAudio();
      toast.dark(`Your microphone has been turned off.`);
    }
    if (body.muteVideo === myId) {
      this.docVideo?.muteVideo();
      toast.dark(`Your camera has been turned off.`);
    }
    if (body.kick === myId) {
      this.docVideo?.hangUp();
      toast.dark(`You've been kicked from this meeting.`);
    }
  };

  sendRoomMessage = (body: any) => {
    body.projectId = this.state.projectId;
    this.socketMgr.socket?.emit("room-message", body);
    if(this.state.meetingId)
    this.socketMgr.socket?.emit("room-message", {...body,projectId:this.state.meetingId});
  };

  setVideoCallInModalOpen = (modalOpen: boolean) => {
    this.setState({ videoCallInModalOpen: modalOpen });
  };

  handleAutoPlopToggle = () => {
    let on = !this.state.readyToJoinAutoPlop;
    let handle: number | null = this.state.plopHandle;
    this.setState({ readyToJoinAutoPlop: on });
    const self = this;

    if (!on && handle) {
      window.clearInterval(handle);
      handle = null;
    } else if (on && !handle) {
      this.sendRoomMessage({
        message: "This project has entered into the queue for auto plop...",        
      });
      let timesChecked = 0;
      self.setState({ plopTimeWaiting: null, plopLine: null });

      // handle = window.setInterval(() => {
      // }, 4500);
      const lookForMatch = () => {
        fetch(
          `${this.state.popApiUrl}/Plop/Project?fairId=${this.state.fairId}&ProjectId=${this.state.projectId}&CategoryId=${this.state.category}`
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.Success) {
              this.setState({
                plopTimeWaiting: data.Value.timeWaiting,
                plopMetaDataLoaded: data.Value.MetaDataLoaded,
                plopLine: data.Value.Line,
              });

              if (data.Value.LimitReached) {
                window.clearInterval(handle || 0);
                self.setState({
                  readyToJoinAutoPlop: false,
                  plopHandle: null,
                });

                toast.error("You have reached your limit.", {
                  autoClose: false,
                });
                return;
              }
            }

            timesChecked++;
            if (data.Success && data.Value.match) {
              const match = data.Value.match;
              toast.info("Match found");
              toast.info("Starting the connection process now..");

              window.clearInterval(handle || 0);
              self.setState({
                readyToJoinAutoPlop: false,
                plopHandle: null,
                meetingId:match.MeetingId ?? "",
              });

              window.setTimeout(() => {
                toast.info("Hold on...");
              }, 3000);

              window.setTimeout(() => {
                toast.info(
                  "Connecting to everyone in the room can take upto 30 or 40 seconds, hold tight.",
                  { autoClose: 20000 }
                );
              }, 6000);

              window.setTimeout(() => {
                toast.info(
                  'If you failed to connect, press the red "Hang up" button, then press the blue "Enter Judging" button to rejoin.',
                  { autoClose: 15000 }
                );
              }, 25000);

              window.setTimeout(() => {
                toast.info("Thank you.", { autoClose: 10000 });
              }, 10000);
              
              self.handleStartVideoClick(match.MeetingId ?? "");
              return;
            }

            if (timesChecked > 360) {
              window.clearInterval(handle || 0);
              self.setState({
                readyToJoinAutoPlop: false,
                plopHandle: null,
              });

              return;
            }

            //check again...
            if (this.isReadyToJoin()) {
              window.setTimeout(lookForMatch, 3500);
            }
          })
          .catch((error) => {});
      };

      lookForMatch();
    }

    this.setState({
      readyToJoinAutoPlop: on,
      plopHandle: handle,
    });
  };

  isReadyToJoin = () => {
    return this.state.readyToJoinAutoPlop;
  };

  // onlineJudgingeVideoGate
  // onlineJudgingeAcaDecaPlaySounds - Both
  // onlineJudgingeAcaDecaStudentHideClock - Student

  checkFirstTime = () => {
    if (this.state.settings?.onlineJudgingShowTipsAndTricks) {
      let rawJson = window.localStorage?.getItem("eventApp");
      if (rawJson) {
        let storageObj = JSON.parse(rawJson);
        if (storageObj) {
          if (storageObj.fairId === this.state.fairId) {
            this.setShowFirstTimeModal(!storageObj.dontShow);
          }
        }
      } else {
        this.setShowFirstTimeModal(true);
      }
    }
  };

  render() {
    return (
      <div>
        <div className="big-text-box">
          <div className={`${this.state.joined ? "hide" : ""}`}>
            
            {this.state?.interviewTimes?.filter(x=>x.ShowBanner).map((x,i)=>{
              return <div key={`interview-banner-${i}`} className="alert alert-info">
                <h4><i className="fa fa-exclamation-triangle text-danger"></i> Upcoming Interview! </h4>
                You have an interview scheduled for {x.at}. 
                
                {x.InterviewRoomLink && <div className="headroom-xl">
                            <a className="btn btn-secondary" href={x.InterviewRoomLink} title="click to go to interview room" target={'_blank'}>
                              <Icon type={IconType.star}/>
                              Go to interview room
                              </a>
                          </div>}
              </div>
            })}

            <DangerousInnerHtml customText={this.state.customText} />
            {this.state.settings && !this.state.settings.isAcaDeca && (
              <div className="alert alert-info head-room-l">
                <h4>
                  <i className="fa fa-exclamation-triangle text-danger"></i>{" "}
                  {this.state.settings && !this.state.settings.isAcaDeca ? (
                    <>Virtual Judging and Interview Rules</>
                  ) : (
                    <>Virtual Meeting Room</>
                  )}
                </h4>
                By using this service you agree to all of the rules of this
                contest and the following guidelines.
                <ul>
                  <li>Do not share where you live.</li>
                  <li>
                    Do not give out your contact information; including your
                    phone number(s), address, and email address.
                  </li>
                  <li>Do not share private or sensitive information.</li>
                  <li>
                    Conduct yourself with the highest levels of professionalism.
                  </li>
                </ul>
              </div>
            )}
            <div className="head-room-l"></div>
          </div>
          <div>
            {!this.state.joined ? (
              <>
                <div className="flex-between">
                  {this.state.settings && (
                    <div>
                      <span className="btn-group">
                        {this.state.settings && !this.state.settings.fixedRooms && <button
                          className="btn btn-primary enter-rejoin-virtual-meeting-room"
                          onClick={()=>{this.handleStartVideoClick()}}
                        >
                          <i className="fad fa-person-booth"></i>
                          {this.state.settings &&
                          !this.state.settings.isAcaDeca ? (
                            <>Enter Virtual Judging / Interview</>
                          ) : (
                            <>Rejoin virtual Meeting Room</>
                          )}
                        </button>}
                        <button
                          type="button"
                          title="see help tips"
                          className="btn btn-secondary"
                          onClick={() => this.setShowFirstTimeModal(true)}
                        >
                          <i className="far fa-question-circle text-secondary"></i>
                        </button>
                      </span>
                    </div>
                  )}
                  <div>
                    {this.state.settings && this.state.settings.autoPlop && (
                      <>
                        <button
                          type="button"
                          onClick={this.handleAutoPlopToggle}
                          className={`btn ${
                            !this.state.readyToJoinAutoPlop
                              ? "btn-success"
                              : "btn-danger"
                          }`}
                        >
                          {!this.state.readyToJoinAutoPlop ? (
                            <>I'm Ready</>
                          ) : (
                            <>I'm Not Ready</>
                          )}
                        </button>
                        {this.state.readyToJoinAutoPlop && (
                          <>looking... {this.state.plopTimeWaiting}</>
                        )}
                      </>
                    )}
                  </div>
                </div>
                  <div className="head-room-l">
                    {this.state.settings &&
                      this.state.settings.isAcaDeca &&
                      !this.state.readyToJoinAutoPlop && (
                        <>
                          <div className="btn-group">
                            {this.state.categories.map((x, i) => {
                              if (x.Id == this.state.category) {
                                return (
                                  <button
                                    type="button"
                                    className="btn btn-success"
                                  >
                                    <strong>
                                      <i className="fa fa-flag"></i> {x.Name}
                                    </strong>
                                  </button>
                                );
                              } else {
                                return (
                                  <button
                                    type="button"
                                    className="btn btn-default"
                                    onClick={() => {
                                      this.changeCategory(x.Id);
                                    }}
                                  >
                                    {x.Name}
                                  </button>
                                );
                              }
                            })}
                          </div>
                        </>
                      )}
                  </div>

                {this.state.plopLine && this.state.readyToJoinAutoPlop && (
                  <div className="head-room-l">
                    
                    <h3>Waiting line</h3>
                      Below you will see the line, you will see each entry that is waiting to enter. 
                      Entries are taken in order of least number of times judged 
                      (<Icon type={IconType.gavelDuo}/> ) and then based on time of arival. Entries are also 
                      taken by category so if you see entries are disapreaing that are not
                       in the first slot it is likely they are a different category that was called up first. 
                    <div className="plop-line">
                      {this.state.plopLine.Line.map((l, i) => {
                        let me = l.ProjectId === this.state.projectId;
                        let category = this.state.categories.find(
                          (x) => (x.Id == l.CategoryId)
                        );
                        return (
                          <div className={`plop-line-item ${me ? 'me':''}`} key={`lineItem-${i}`}>
                            <div className="plop-line-number">{i + 1} 
                            {me && <> (you)</>}
                            </div>
                            <div className="plop-line-name" title='category name'>
                              {category?.Name}
                            </div>
                            <div className="plop-line-time">
                              <span title='time waiting'>{l.Waiting}</span>
                              {!this.state.settings.isAcaDeca && <span title="times judged"><Icon type={IconType.gavelDuo}/> {l.TimesJudged}</span>}
                              </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="flex-between">
                  <div>
                    <span className="btn-group bumper-out-l">
                      <button
                        className="btn btn-danger"
                        onClick={this.handleHangup}
                      >
                        <i className="fas fa-phone hangup-button"></i>
                        Hang up
                      </button>
                      <button
                        type="button"
                        title="see help tips"
                        className="btn btn-secondary"
                        onClick={() => this.setShowFirstTimeModal(true)}
                      >
                        <i className="far fa-question-circle text-secondary"></i>
                      </button>
                      {this.state.settings.onlineVideoShowCallInNumber && (
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => this.setVideoCallInModalOpen(true)}
                          title="dial into meeting"
                        >
                          <i className="fas fa-phone-rotary text-secondary" />
                        </button>
                      )}
                    </span>
                  </div>
                  {this.state.settings && (
                    <TimerContainer
                      socket={this.socketMgr.socket}
                      settings={this.state.settings}
                    />
                  )}
                  

                </div>
                {this.state.gateOverridden && (
                  <button
                    className="btn btn-danger bumper-out-l"
                    onClick={this.handleOverrideOverride}
                    title="The Video Room is gated from < 2 judges usually. This puts the gate back."
                  >
                    <i className="far fa-dungeon"></i>
                    Gate Video Room
                  </button>
                )}
              </>
            )}
          </div>
        </div>
        <div
          className={`flex-column-reverse ${
            this.state.isVideoAllowed ? "unlocked" : "locked"
          }`}
        >
          <div
            id="docVideo"
            className={`${!this.state.joined ? "hide" : ""}`}
          ></div>
          <div className="gate">
            <h1>Currently Video is gated</h1>Once more judges arrive video will
            open.
          </div>
        </div>
        {this.state.joined &&
          !this.state.settings.onlineJudgingHideStudentVideoButtonMuteAll && (
            <JustVideoAdminPanel
              socket={null}
              projectId=""
              participants={this.state.participants}
              sendRoomMessage={this.sendRoomMessage}
              isModerator={this.state.everythingInfo.moderator}
              settings={this.state.settings}
            />
          )}
          {this.state.settings && this.state.settings.autoRecording && this.state.meetingId &&  <div className="head-room-l flex-center">
         <span>
                <ScreenRecorder 
                        autoSaveEveryXSeconds={15} 
                        uploadUrl="https://site.nhd.org/recording/SaveThis" 
                        fileName={this.state.meetingId} 
                        recordingStopped={this.handleHangup}
                        autoStart={false}
                        hideButtons={true}
                        callback={this.recordingCallbacks}                        
                     />
                  </span>
          </div>}
        
        <div className="head-room-l flex-center">
          {this.state.everythingInfo && this.state.everythingInfo.project && (
            <div className="video-project-id">
              {this.state.everythingInfo.project.project_id}
            </div>
          )}
          {this.state.everythingInfo &&
            this.state.everythingInfo.assignments &&
            this.state.everythingInfo.assignments.length > 0 &&
            !this.state.settings.isAcaDeca && (
              <div className="video-assignments">
                <strong>Scheduled Interviews</strong>
                {this.state.interviewTimes?.map((x, i) => {
                  return (
                    <div className={`assignment ${x.UseJudgesVirtualRoom ? 'other-room':''}`} key={`v-a-${i}`}>
                      <div className="flex-between">
                      <span>
                        
                      {x.interview === 'Not Interviewed' && <span className="text-danger" title="Judge did not show"><Icon type={IconType.warning} /> </span>}
                        {x.interview === 'Yes Interviewed' && <span className="text-success"><Icon type={IconType.checkCircle} /> </span>}
                        {x.at}</span>
                      {this.state.settings.IndicateJudgeShowedForScheduled && <div>
                          <button type="button" className="btn btn-xs btn-default" onClick={()=>{
                            this.setShowJudgeFeedbackModal(true);
                            let feedback = {
                              type:x.type,
                              id:x.id,
                              interview:x.interview,
                              at:x.at,
                            };
                            this.setState({interviewForm:feedback});
                          }}>Did Show</button>
                        </div>}
                        </div>
                        {x.UseJudgesVirtualRoom && <div>
                          Interview Request # {x.id} 
                  <button type="button" className="btn btn-xs btn-default" onClick={()=>{
                      if(!this.state.settings.isLead){
                        toast.warning('Only the team lead can schedule this.');
                        return;
                      }
                            this.setScheduleIntervalModal(true, x);
                          }}><Icon type={IconType.edit} /> Schedule</button>
                          </div>
                          }
                        {x.InterviewRoomLink && <div>
                            <a className="" href={x.InterviewRoomLink} title="click to go to interview room" target={'_blank'}>Go to interview room</a>
                          </div>}
                    </div>
                  );
                })}
                
            {this.state.requestedInterviews?.length > 0 && this.state.requestedInterviews.filter(x=>!x.at).map((x,i)=>{
              return (<div className="assignment flex-between" key={`ri-i-${i}`}>
                  Interview Request # {x.id} 
                  <button type="button" className="btn btn-xs btn-info" onClick={()=>{
                      if(!this.state.settings.isLead){
                        toast.warning('Only the team lead can schedule this.');
                        return;
                      }
                            this.setScheduleIntervalModal(true, x);
                          }}><Icon type={IconType.calendar} /> Schedule</button>
                </div>);
            })}
            
              </div>
            )}
        </div>
        {this.state.showJudgeFeedbackModal && <Modal title="Interview Feedback" setModalOpen={this.setShowJudgeFeedbackModal} >
          <div className="form-horizontal">
            <div className="form-group">
              <label className="control-label col-sm-4" htmlFor="interview-feedback-interview">Interview</label>
              <div className="col-sm-8">
                <select className="form-control" id="interview-feedback-interview"
                  value={this.state.interviewForm.interview}                
                  onChange={(e)=>{
                    let feedback = {
                      ...this.state.interviewForm,
                      interview:''
                    };
                    this.setState({interviewForm:feedback});
                    this.setState({interviewForm:{...this.state.interviewForm, interview:e.target.value}});
                  }}
                >
                  <option value={''}>select...</option>
                  <option value={'Not Interviewed'}>Not interviewed, Judge(s) never showed up</option>
                  <option value={'Yes Interviewed'}>Yes interviewed, Judge(s) showed up</option>
                </select>
              </div>
            </div>
            <div className="form-group">
              <div className="col-sm-offset-4 col-sm-8">
                <button type="button" className="btn btn-secondary" onClick={this.submitInterviewFeedback}>
                  <Icon type={IconType.save} /> Submit Feedback
                </button>
              </div>
            </div>
          </div>
          </Modal>}
        {this.state.showFirstTimeModal && (
          <Modal
            title="Online Virtual Judging"
            setModalOpen={this.setShowFirstTimeModal}
          >
            <FirstTimeModal
              setModalOpen={this.setShowFirstTimeModal}
              fairId={this.state.fairId}
            />
          </Modal>
        )}
        {this.state.videoCallInModalOpen && (
          <Modal
            title="Conference call in number"
            setModalOpen={this.setVideoCallInModalOpen}
          >
            <div>
              <div className="call-in-info-line">
                <span className="emph">Phone number: </span>

                <select>
                  {this.state.videoCallInPhoneNumber.map((x) => (
                    <option key={x}>{x}</option>
                  ))}
                </select>
              </div>
              <div className="call-in-info-line">
                <span className="emph">PIN:</span>
                {this.state.videoCallInPin &&
                  formatPin(this.state.videoCallInPin)}
              </div>
            </div>
          </Modal>
        )}
        {this.state.settings && this.state.settings.enableSupportChat && (
          <SupportChat
            socket={null}
            isSupportAgent={false}
            fairId={this.state.fairId}
            clientId={this.state.clientId}
            personId={this.state.personId}
            projectId={this.state.projectId}
            name={`${this.state.user?.firstName} ${this.state.user?.lastname}`}
          ></SupportChat>
        )}

        
{this.state.settings?.enableWaitRoom && this.state.meetingId && <RequestModeratorInterpreter settings={StoreX.Settings} languages={this.state.settings.languages ?? ['English']} meetingId={this.state.meetingId} />}


        {this.state.settings && this.state.showSetInterviewSchedule && this.state.interviewForm && 
        <Modal title={`Interview Request # ${this.state.interviewForm.id}`}
        size="m"
        setModalOpen={this.setScheduleIntervalModal}
        >
            <ScheduleRequestedInterview assignmentId={this.state.interviewForm.id} callOnSet={this.getOtherInfo} projectId={this.state.projectId} ></ScheduleRequestedInterview>
          </Modal>}
      </div>
    );
  }
}

export default ParticipantVideoContainer;
