import React, { useState } from 'react'

const Accordion = (props) => {
  const [expanded, setExpanded] = useState(props.expanded || false)

  const handleExpand = () => {
    if (!props.alwaysExpanded) setExpanded(!expanded)
  }

  const handleSubTitleFunctionClick = (e) => {
    e.stopPropagation();
    props.subTitleFunction();
  }

  return (
    <div className='accordion'>
      <div
        className='accordion-header flex-between'
        onClick={handleExpand}
      >
        <span className='accordion-title'>{props.title || 'accordion title here'}</span>
        <div>
        {props.subTitleFunction 
          ? <button 
              className='btn btn-secondary' 
              onClick={handleSubTitleFunctionClick}
            >
              <i className="far fa-edit edit-category-btn"></i>
              {props.subTitle || ''}
            </button>
          : <span className='accordion-title'>{props.subTitle || ''}</span>
        }
          
          {!props.alwaysExpanded
            && <span className='bumper-out-sides'>
              {!expanded ? <i className="far fa-plus-circle" /> : <i className="far fa-minus-circle" />}
            </span>
          }
        </div>
      </div>
      {expanded
        &&
        <div className="accordion-body">
          {props.children}
        </div>

      }

    </div>
  )
}

export default Accordion