import React, { useState } from 'react';
import { store } from "../../redux/oldStore";
import { toast } from 'react-toastify'
import TagsContainer from '../Tags/TagsContainer';

const JudgingAssignmentLoad = (props: any) => {
  const [fileText, setFileText] = useState<any>(null);
  const [headersIncluded, setHeadersIncluded] = useState(false)
  const [loaded, setLoaded] = useState(false);
  const [parsingErrors, setParsingErrors] = useState<string[]>([]);
  const [clearAssignments, setClearAssignments] = useState<boolean>(false);
  const [hasTimes, setHasTimes] = useState<boolean>(true);
  const [form, setForm] = useState<any>({});

  interface IJudgeAssignmentCSVFileSelectedProps{
    judges:any[];
    round:any;
    categories:any[];
    divisions:any[];
    projectsByCategory:any;
    projects:any[];
    setAssignments:any;               
    judgingSessions:any;
  }


  const judgeAssignmentCSVFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    let file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = async (e: any) => {
      const text = e.target.result;
      let lines: any[] = [];
      const perrors: string[] = [];
      let formattedText = text.replaceAll('\r\n','\n').replaceAll('\r', '\n');
      const rawLines = formattedText.split("\n");
      let hasTimes = false;

      rawLines.forEach((l, index) => {
        if (l.length > 0) {
          const line = l.split(',');
          const columnsPerLine = 3;
          if (line.length >= columnsPerLine) {
            //The line looks good
            lines.push(line);
            if(!hasTimes && line[2].length > 0 && index > 0){
              hasTimes = true;
            }
          } else if (line.length == columnsPerLine -1 ){
            //line is just missing time to schedule
            line.push('')
            lines.push(line);
          } else {
            const errorMessage = `Line ${index + 1} has ${line.length} columns, but should have ${columnsPerLine}`
            console.error(errorMessage);
            perrors.push(errorMessage);
          }
        }
      });

      setHasTimes(hasTimes);
      setForm({});

      if (perrors.length) {
        setParsingErrors(perrors);
        setFileText(null);
      } else {
        setFileText(lines);
      }
    };
    reader.readAsText(file);
  };

  const load = (e) => {
    console.log('Sending to server...');
    // What is the new api address?
    store.server.postApi("../JudgingManagement/UploadJudgingAssignments", 
    { 
      roundId: props.round?.Id, 
      data: fileText,
      clearAssignments: clearAssignments,
      scheduleToRules:form.schedule,
     }).then((x: any) => {
      if (x.Success) {
        toast.success("Your assignments have been loaded.");
        props.setAssignments(x.Value.assignments)
        setLoaded(true);
        if(x.Value.message){
          toast.info(x.Value.message);
        }
      } else {
        toast.error(x.Message);
      }
    }).catch(err => {
      console.log(err);
      toast.error(err);
    });
  };

  return (
    <div className="loading-container soft-border bumper-l bumper-out-l">
      <h1>Loading Information</h1>
      <div>
        You can load information for your judging assignments using a CSV file.
        You can save Excel and Google Doc files as CSV files.
      <ul>
          <li>
            <strong>Google Sheets</strong> Select File, then Download then
              as Comma-separated values (.csv)
        </li>
          <li>
            <strong>Excel</strong> Select File, Save as, then change type to
              (*.csv)
        </li>
        </ul>
      </div>
      <div>

        <h3>Load Judging Assignments</h3>

        If you want to upload your judging schedule you can do that here. Fill out the form below to be able to upload it.

        <h4>File Judging Assignments</h4>
        <div>
          The Judge Assignments CSV file should include the following columns in the following
          order.
        <ul>
            <li>
              Project's Id​ <span> </span>
              <a
                href={`${window.location.host.indexOf("localhost") > -1 ? `http://localhost:8081/JudgingManagement/GetProjectFile/${props.round?.Id}` : `/JudgingManagement/GetProjectFile/${props.round?.Id}`}`}
                target="_blank"
                rel='noreferrer noopener'
                className='download-project-ids'
                download
              >
                download all project IDs
              </a> (Id column not Project Id)
            </li>
            <li>
              Judge ID​ <span> </span>
              <a
                href={`${window.location.host.indexOf("localhost") > -1 ? `http://localhost:8081/JudgingManagement/GetJudgeFile/${props.round?.Id}` : `/JudgingManagement/GetJudgeFile/${props.round?.Id}`}`}
                target="_blank"
                rel='noreferrer noopener'
                className='download-judge-ids'
                download
              >
                download all Judge IDs
            </a>
            </li>
            <li>At (yyyy-mm-dd hh:mm tt)</li>
          </ul>


          <a
            href={`${window.location.host.indexOf("localhost") > -1 ? `http://localhost:8081/JudgingManagement/GetAssignmentTemplate` : `/JudgingManagement/GetAssignmentTemplate`}`}
            target="_blank"
            rel='noreferrer noopener'
            className='download-template-file'
            download
          >
            <i className="fal fa-file"></i>
          Template File
        </a>

        </div>
        {!loaded && <div className="">
          <div className='bumper-l'>

            <label className=''>Judge Assignments CSV File:</label>
            <input
              type="file"
              accept=".csv"

              onChange={judgeAssignmentCSVFileSelected}
              onClick={(evt: any) => evt.target.value = null}
            // Set target to null so the onchange event will fire again even if the user picks the same file.
            />
          </div>
          <div className='flex bumper-l'>
            <label htmlFor='clear'>Clear existing assignmentments for this round?</label>
            <input
              type='checkbox'
              name="clear"
              id='clear'
              className='form-input bumper-sides-out-l'
              onChange={e => setClearAssignments(e.target.checked)}
              defaultChecked={clearAssignments}
            />
          </div>
          <div className='flex bumper-l'>
            <label htmlFor='headers'>Does the file include headers?</label>
            <input
              type='checkbox'
              name="headers"
              id='headers'
              className='form-input bumper-sides-out-l'
              onChange={e => setHeadersIncluded(e.target.checked)}
              defaultChecked={headersIncluded}
            />
          </div>


          {fileText && fileText.length > 0 && (
            <div className="csv-file-preview">
              <table>
                {!headersIncluded
                  &&
                  <thead>
                    <tr>
                      <th>Project ID​</th>
                      <th>Judge ID​</th>
                      <th>At (yyyy-mm-dd hh:mm tt)</th>
                    </tr>
                  </thead>

                }
                <tbody>

                  {fileText.map((r: string[], ri: number) => {
                    return (
                      <tr key={'r' + ri}>
                        {r.map((v: string, vi: number) => {
                          if (headersIncluded && ri === 0) {
                            return <th key={ri + '-' + vi}>{v}</th>;
                          }
                          return <td key={ri + '-' + vi}>{v}</td>;
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {/* <h4>If the above file content looks good. Press Load</h4>
                <button type="button" className="button" onClick={load}>
                  Load
                </button> */}
            </div>
          )}
          {parsingErrors.length > 0 &&
            <table>
              {parsingErrors.map((error, index) => (
                <tr key={index}>
                  <td>{error}</td>
                </tr>
              ))}
            </table>
          }
        </div>}
        {!loaded && (fileText && fileText.length > 0)
          && (
            <div className='ready-to-load-area'>
              <h4>If the Judge Assignments CSV file contents look good, press Load</h4>

              {props.round.Values.SchedulesInterviews && !hasTimes && <div>
                
                <div className='form-horizontal'>
                  <div className='from-group'>
                    <input id={'schedule-it'} 
                    type={'checkbox'} 
                    checked={form?.schedule ?? false} 
                    onChange={(e)=>{setForm({...form,schedule:e.target.checked});}}
                    />
                    <label htmlFor='schedule-it' className='control-label'>
                      Have us schedule these assignments, according the rules of this round.
                    </label>
                    </div>
                </div>

                </div>}

              <button type="button" className="btn btn-secondary" onClick={load}>
                <i className="fad fa-cloud-upload"></i>
                    Load
                  </button>
            </div>
          )}
        {loaded &&
          <div>
            <h1>Judge Assignments loaded!</h1>
          If you need to load more judges refresh your page.
        </div>}
      </div>
    </div>

  )

}

export default JudgingAssignmentLoad