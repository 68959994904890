import React from 'react';
import { IChatContainer, IMsg } from './SupportChats'

interface IndividualChatProps {
  c: IChatContainer;
  ci: number;
  isSupportAgent: boolean;
  closeThisChat: (roomId: string) => void;
  sendClearRoom: (roomId: string) => void;
  closeChat: (roomId: string) => void;
  personId: string;
  onEnterPress: (e: any, roomId: string) => void;
}

const IndividualChat = (props: IndividualChatProps) => {
  const { c, ci } = props


  return (
    <>
      {c.isOpen &&
        <div className="support-chat-open" key={`chat-key-${ci}`}>
          <div className="chat-header">
            {!props.isSupportAgent ? <div>Need Help?</div> :
              <div>{c.name}</div>
            }
            <div>
              {props.isSupportAgent && <i className="fad fa-sign-out-alt click" title="close chat" onClick={() => { props.closeThisChat(c.roomId) }} ></i>}
              <i className="fad fa-eraser click" title="clear chat" onClick={() => { props.sendClearRoom(c.roomId) }} ></i>
              <i className="fa fa-times-circle click" title="close chat" onClick={() => { props.closeChat(c.roomId) }} ></i>

            </div>
          </div>
          <div className="chat-body" id={`message-tap${c.roomId}`}>
            <div className="message-tape">
              {c.messages && c.messages.length > 0 && c.messages.map((x: IMsg, i: number) => {
                const isSelf = x.personId === props.personId;
                return (<div key={`message-${ci}-${i}`} className={`message-item ${isSelf ? 'self' : ''}`}>
                  <div className="message">{x.message}</div>

                  <div className="from-name">{!isSelf ? x.name : 'me'}</div>
                </div>);
              })}
              {(!c.messages || c.messages.length === 0) && <div className="message-item system">
                <div className="message">Please let us know how we can help...</div>

                <div className="from-name">...</div>
              </div>

              }
            </div>

          </div>
          <div className="chat-footer">
            <textarea
              placeholder="How can we help?"
              onKeyDown={(e) => { props.onEnterPress(e, c.roomId); }} /*value={this.state.currentMessage}*/
              onChange={(e) => { /*this.setCurrentMessage(e.target.value)*/ }}
            ></textarea>
            {/* <div onClick={this.sendMessage} title="click to send message" className="fa fa-2x fa-send click"></div> */}
          </div>
        </div>
      }
    </>

  )
}

export default IndividualChat