import React, { useState, useEffect } from 'react'


const JudgingRoundProjectAssignment = (props) => {
  const { project
    , assignments
    , judges
    , round } = props
  const [avgScore, setAvgScore] = useState(0)
  const [score, setScore] = useState(0)

  useEffect(() => {
    if (assignments?.length > 0) {
      let scored = assignments.filter(x => x.Score > 0);
      // console.log('is this not a number?', scored.length)
      // console.log('what about this one?', assignments.length)
      setScore(+(+scored.length / +assignments.length * 100).toFixed(2));
      let sum = scored.reduce( (acc, curr) => acc += +curr.Score, 0)
      setAvgScore(+(sum / scored.length).toFixed(2))
    }
  }, [assignments])



  const mappedAssignments = assignments?.map((a, i) => {
    if (judges[a.JudgeId]) {
      return (
        <span 
          className="judge-assignment"
          key={`${a.JudgeId}${i}${judges[a.JudgeId].FirstName}`}
        >
        
          {a.Score
            &&
            <span className="hover-success"><i className="fa fa-check-square"></i>{a.Score.toFixed(2)}</span>
          }
          {judges[a.JudgeId].FirstName} {judges[a.JudgeId].LastName}
          {round.Values.SchedulesInterviews
            ?
            <span className="interview-time">&nbsp;{a.InterviewAt}</span>
            :
            a.FaildToSetInterview
            &&
            <span className="interview-time">&nbsp;<i className="text-danger fad fa-calendar-day"></i></span>

          }
          <span
            className="hover-danger"
            onClick={() => props.editAssignment(a.Id)}
            title="edit assignment"
          >
            <i className="fa fa-edit"></i>
          </span>
          <span
            className="hover-danger"
            onClick={() => props.removeAssignment(a.Id)}
            title="Remove assignment"
          >
            <i className="fa fa-trash-can"></i>
          </span>
        </span>

      )
    } else {
      return <></>
    }
  })

  const openMakeAssignment = () => {
    console.log('openMakeAssignment pressed')
    props.setSelectedProject(project)
    props.setSelectedJudge({})
    props.setModalOpen(true)
  }

  return (
    <div className="hover-shadow row-padding soft-border">
      <div className="row">
        <div className="col-sm-2"><strong>{project.ProjectId}</strong></div>
        <div className="col-sm-2 text-center">
          <span 
            className="hover-success" 
            onClick={openMakeAssignment} 
            title="Make assignment"
          >
            <i className="fad fa-user-plus make-judge-asgnm"></i>
          </span>
          {assignments?.length > 0
            &&
            <span className="badge">{assignments.length}</span>
          }
        </div>
        <div className="col-sm-8 text-right">
          {isNaN(avgScore) ? `` : `${avgScore} Avg Raw Score`}<br />
          {isNaN(avgScore) ? `` : `${score}% Complete`}<br />
          {project.Title}
        </div>
      </div>
      <div className="">
        <div className="judging-assignment-list">
          {mappedAssignments}
        </div>
      </div>
    </div >
  )
}

export default JudgingRoundProjectAssignment