import React, { useEffect, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { store } from "../../redux/oldStore";
import { IFair, IFeedRequest } from "./FairPromotionLinkingContainer";
import Request from "./Request";

interface MakeARequestProps {
  fairs: IFair[];
  feedRequests: IFeedRequest[];
  setFeedRequests: Function;
}

const MakeARequest = (props: MakeARequestProps) => {
  const [selectVal, setSelectVal] =
    useState<{ value: number; label: string }>();
  const [selectedFair, setSelectedFair] = useState<IFair>();
  // ../FairPromotion/MakeLink/${id} -- fair id
  // ../FairPromotion/RemoveLink/${id} --link id

  useEffect(() => {
    let [chosenFair] = props.fairs.filter(
      (fair) => fair.Id === selectVal?.value
    );
    setSelectedFair(chosenFair);
  }, [selectVal]);

  const handleRequestLink = () => {
    if (!selectedFair?.Id) return toast.warn("you must choose a fair");

    store.server
      .getApi(`../FairPromotion/MakeLink/${selectedFair.Id}`)
      .then((res: any) => {
        console.log(res);
        // setFeedRequests with the return
        toast.info("request sent");
        props.setFeedRequests([res.Value.link, ...props.feedRequests]);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err);
      });
  };

  const handleRemoveLink = (request: IFeedRequest) => {
    store.server
      .getApi(`../FairPromotion/RemoveLink/${request.Id}`)
      .then((res) => {
        let newRequests = props.feedRequests.filter((x) => x.Id !== request.Id);
        toast.info("request removed");
        props.setFeedRequests(newRequests);
      })
      .catch((err) => toast.warning(err));
  };

  const mappedFairOptions = props.fairs.map((fair) => {
    return {
      value: fair.Id,
      label: `${fair.Name}, - ${fair.State ? fair.State : ""}, ${fair.Website}`,
    };
  });

  const mappedFeedRequests = props.feedRequests.map((request) => {
    return (
      <Request
        key={request.Id}
        request={request}
        requests={props.feedRequests}
        setRequests={props.setFeedRequests}
        remove={handleRemoveLink}
      />
    );
  });

  return (
    <div className="bumper-l bumper-out-l">
      <h4>Make a request</h4>
      <div>
        Here you can request authorization to promot participants from your
        event to another. You can also see your approved & pending requests. At
        anytime you can also remove your request.
      </div>
      <div className="soft-border">
        <div className="bumper-out-l">
          <label>Search Fair by Name or URL (ex. 'try.zfairs.com'): </label>
          <div className="flex-between ">
            <div className="flex-grow">
            <Select
              onChange={setSelectVal}
              value={selectVal}
              options={mappedFairOptions}
              placeholder={"Choose fair to request a link"}
            />
            </div>
            <div>
              <button
                className="btn btn-success bumper-out-l"
                onClick={handleRequestLink}
              >
                Request Link
              </button>
            </div>
          </div>
        </div>
      </div>
      <div>{mappedFeedRequests}</div>
    </div>
  );
};

export default MakeARequest;
