import { useEffect, useState } from 'react';
import { IState } from '../../redux/redux';
import { store } from '../../redux/oldStore';
import { ServerResponse } from '../../utils/Server';
import { IFileDetails, UiFormField, UiFormSection } from '../../utils/CommonTypes/UserTypes';
import { toast } from 'react-toastify';
import FormSection from '../FormFromApiData/FormSection';
import React from 'react';
import { isFieldRegexSatisfied, isFieldSatisfied } from '../FormFromApiData/FormFromApiData';

interface IProps {
  personId: string;
  recordExists: boolean;
  isFristTimeLogin: boolean;
  isNewUser: boolean;
  canSave: boolean;
  onContinue: () => void;
}

interface IFormInfo {
  PersonId: string;
  Form: UiFormSection[];
  HasRecord: boolean;
}

const NhdMedicalForm = (props: IProps) => {
  const [showForm, setShowForm] = useState<boolean>(props.recordExists ? true : false);
  const [formLoaded, setFormLoaded] = useState<boolean>(false);
  const [form, setForm] = useState<IFormInfo>();
  const [formValues, setFormValues] = useState<Record<string, string[]>>({});
  const [groupIds, setGroupIds] = useState<Record<string, UiFormField[]>>({});
  const [formErrors, setFormErrors] = useState<Record<string, UiFormField>>({});

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (showForm) {
      //get form info...
    }
  }, [showForm]);

  const init = () => {
    store.server.getApi<ServerResponse<IFormInfo>>(`../Student/GetMedicalForm/${props.personId}`).then((x) => {
      if (x.Success) {
        setForm(x.Value);
        setShowForm(!x.Value.HasRecord);
        setFormLoaded(true);
      } else {
        toast.error(x.Message, { autoClose: false });
      }
    });
  };

  const saveForm = () => {
    let data: any = {
      values: formValues,
      targetId: form?.PersonId,
    };
    let requiredMissing = false;
    let missingFields:string[] = [];
    let invalid = false;
    let invalidFields: string[]=[];

    form?.Form.forEach(sect=>{
        sect.Fields.forEach((field) => {
            if (!isFieldSatisfied(field, formValues)) {
              formErrors[field.Key] = field;
              requiredMissing = true;
              missingFields.push(field.Label);
            }
            if (!isFieldRegexSatisfied(field, formValues)) {
              formErrors[field.Key] = field;
              invalid = true;
              invalidFields.push(field.Label);
            }
          });
    });

    if (requiredMissing) {
        toast.warning(<>Oops, Looks like some fields are missing.
              <ul>
                {missingFields.map((x,i)=>{
                  return <li key={`missing-field-${i}`} dangerouslySetInnerHTML={{__html:x}}></li>
                })}
              </ul>
        </>);
      }
      if (invalid) {
        toast.warning(<>Oops, Some fields contain invalid data.
              <ul>
                {invalidFields.map((x,i)=>{
                  return <li key={`missing-field-${i}`}dangerouslySetInnerHTML={{__html:x}}></li>
                })}
              </ul>
        </>);
      } 

      if(requiredMissing || invalid) return;


    store.server.postApi<ServerResponse<boolean>>(`../Student/SaveMedicalForm`, {jsonData:JSON.stringify(data)}).then((x) => {
      if (x.Success) {
        props.onContinue();
      } else {
        toast.error(x.Message, { autoClose: false });
      }
    });
  };

  const skip = () => {
    props.onContinue();
  };

  const handleFieldChange = (key: string, value: string[]) => {
    if (value[0] === '<null value>') value = [];
    let updatedFormValues = { ...formValues, [key]: value };
    setFormValues(updatedFormValues);
  };

  const handleSelectChangeWithCall = async (field: UiFormField, key: string, value: string[]) => {
    handleFieldChange(key, value);
  };

  const projectFileChanged = (key: string, files: IFileDetails[]) => {};

  return (
    <>
      {!showForm && formLoaded && (
        <div id="medical-info-about" className="">
          <h3>Medical Info / Release</h3>
          If you need to resubmit or make changes to your medical information, you can do that here by clicking the Update Medical Info button below. Please note that for your security, you will need
          to complete this form in its entirety.
          <br />
          <br />
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              setShowForm(true);
            }}>
            <i className="fa fa-heartbeat"></i>&nbsp;Update Medical Info
          </button>
          {(props.isFristTimeLogin || props.isNewUser) && <button type="button" className="btn btn-default" onClick={skip}>
              <i className="fa fa-heartbeat"></i>&nbsp; Continue
            </button>}
        </div>
      )}

      {showForm && form && (
        <>
          <div className='form-horizontal'>
            {form.Form.map((x, i) => {
              if (!x.Active || x.Fields.length === 0) return <React.Fragment key={`${x.Label}-${i}`}></React.Fragment>;
              return (
                <FormSection
                  key={`x-${x.Label}-${i}`}
                  section={x}
                  groupIds={groupIds}
                  formValues={formValues}
                  handleFieldChange={handleFieldChange}
                  handleSelectChangeWithCall={handleSelectChangeWithCall}
                  idCalledInApi={form.PersonId}
                  targetId={form.PersonId}
                  formErrors={formErrors}
                  canSave={props.canSave}
                  projectFileChanged={projectFileChanged}
                  handleFileChange={() => {}}
                />
              );
            })}
          </div>

          <div>
            <button type="button" className="btn btn-primary" onClick={saveForm}>
              <i className="fa fa-heartbeat"></i>&nbsp; Submit
            </button>
            <button type="button" className="btn btn-default" onClick={skip}>
              <i className="fa fa-heartbeat"></i>&nbsp; Complete Later
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default NhdMedicalForm;
