import React, {useState, useEffect} from 'react'
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';



const AddOrEditAssessment = (props) => {
  const { 
    setModalOpen,
    assessment,
  } = props

  const Id = assessment.Id || uuidv4() 
  const [Question, setQuestion] = useState(assessment.Question || '') 
  const [Description, setDescription] = useState(assessment.Description || '') 
  const [questionError, setQuestionError] = useState(false);
  const [ValueOverride, setValueOverride] = useState(assessment.ValueOverride || 0) 
  const [Sort, setSort] = useState(assessment.Sort || 0) 
  const [AllowNa, setAllowNa] = useState(assessment.AllowNa || false) 
  const [descending, setDescending] = useState(assessment.Descending || false) 
  const [MinValue, setMinValue] = useState(assessment.MinValue || 0) 
  const [MaxValue, setMaxValue] = useState(assessment.MaxValue || 1) 
  const [ScoreType, setScoreType] = useState(assessment.ScoreType || 1)
  const [IsPenaltyAssessment, setIsPenaltyAssessment] = useState(assessment.IsPenaltyAssessment || false)

  useEffect(() => {
    let [oneIWant] = scoreTypes.filter(type => type.value === ScoreType);
    console.log(ScoreType, oneIWant)
    setMinValue(oneIWant.MinValue);
    setMaxValue(oneIWant.MaxValue);
  }, [ScoreType])


  const scoreTypes = [
  {value: 1, MinValue:0, MaxValue:1, range: 'Yes / No'}, 
  {value: 2, MinValue:0, MaxValue:2, range: 'Range: 1 - 3'}, 
  {value: 3, MinValue:0, MaxValue:3, range: 'Range: 1 - 4'}, 
  {value: 4, MinValue:0, MaxValue:4, range: 'Range: 1 - 5'}, 
  {value: 5, MinValue:0, MaxValue:5, range: 'Range: 1 - 6'}, 
  {value: 6, MinValue:0, MaxValue:6, range: 'Range: 1 - 7'}, 
  {value: 7, MinValue:0, MaxValue:7, range: 'Range: 1 - 8'}, 
  {value: 8, MinValue:0, MaxValue:8, range: 'Range: 1 - 9'}, 
  {value: 9, MinValue:0, MaxValue:9, range: 'Range: 1 - 10'}, 
  {value: 10, MinValue:0, MaxValue:14, range: 'Range: 1 - 15'}, 
  {value: 11, MinValue:0, MaxValue:19, range: 'Range: 1 - 20'}, 
  {value: 12, MinValue:0, MaxValue:24, range: 'Range: 1 - 25'}, 
  {value: 13, MinValue:-1, MaxValue:1, range: 'Range: 0 - 2'}, 
  {value: 14, MinValue:-1, MaxValue:2, range: 'Range: 0 - 3'}, 
  {value: 15, MinValue:-1, MaxValue:3, range: 'Range: 0 - 4'}, 
  {value: 16, MinValue:-1, MaxValue:4, range: 'Range: 0 - 5'}, 
  {value: 17, MinValue:-1, MaxValue:5, range: 'Range: 0 - 6'}, 
  {value: 18, MinValue:-1, MaxValue:6, range: 'Range: 0 - 7'}, 
  {value: 19, MinValue:-1, MaxValue:7, range: 'Range: 0 - 8'}, 
  {value: 20, MinValue:-1, MaxValue:8, range: 'Range: 0 - 9'}, 
  {value: 21, MinValue:-1, MaxValue:9, range: 'Range: 0 - 10'}, 
  {value: 22, MinValue:-1, MaxValue:14, range: 'Range: 0 - 15'}, 
  {value: 23, MinValue:-1, MaxValue:19, range: 'Range: 0 - 20'}, 
  {value: 24, MinValue:-1, MaxValue:24, range: 'Range: 0 - 25'}, 
  {value: 25, MinValue:-1, MaxValue:99, range: 'User Enter'},  
  {value: 25, MinValue:-1, MaxValue:99, range: 'User Enter'},
  { value: 26, MinValue: -1, MaxValue: 29, range: "Range: 0 - 30" },
  { value: 27, MinValue: -1, MaxValue: 39, range: "Range: 0 - 40" },
  { value: 12, MinValue: 0, MaxValue: 24, range: "Range: 1 - 25" },
  { value: 28, MinValue: 0, MaxValue: 29, range: "Range: 1 - 30" },
  ]

  const handleSave = () => {
    if(!Question) {
      setQuestionError(true);
      toast.warning('Assessment must have a value');
      return;
    }
    let assessmentToSave = {
      Id,
      Question, //need
      ValueOverride,
      Sort,
      AllowNa,
      MinValue, // need this
      MaxValue, // need this
      ScoreType, // min and max come from this.
      IsPenaltyAssessment,
      Description,
      descending
    };
    // TODO: form validation

    props.handleSave(assessmentToSave);
    setModalOpen(false);

  }

  const handleDelete = () => {
    if(!window.confirm(`are you sure you want to delete this?`)) return;
    props.handleDelete(Id);
    props.setModalOpen(false);
  };


  const handleFocus = (e) => {
    e.target.select();
  }
  
  return(
    <div>
      <div className='form-horizontal'>

      <div className={`form-group ${questionError ? 'error' : ''}`}>
        <label className="control-label col-sm-4" htmlFor='Question'>Assessment: </label>
        <div className='col-sm-8'>
        <textarea 
          name='Question' 
          id='Question' 
          className='form-control'
          onChange={(e) => setQuestion(e.target.value)} 
          value={Question}
          onFocus={handleFocus}
          autoFocus={true}
        />
      </div>
      </div>
      <div className='form-group'>
        <label className="control-label col-sm-4" htmlFor='Description'>Description: </label>
        <div className='col-sm-8'>
        <textarea 
          name='Description' 
          id='Description' 
          className='form-control'
          onChange={(e) => setDescription(e.target.value)} 
          value={Description}
        />
        </div>
      </div>
      <div className='form-group'>
        <label className="control-label col-sm-4" htmlFor='Sort'>Sort Order: </label>
        <div className='col-sm-8'>
        <input 
          type='number'
          name='Sort' 
          id='Sort' 
          className='form-control'
          min={0}
          max={100}
          step={1}
          onChange={(e) => setSort(+e.target.value)} 
          value={Sort}
        /></div>
      </div>
      <div className='form-group'>
        <label className="control-label col-sm-4" htmlFor='scoreType'>Score Type: </label>
        <div className='col-sm-8'>
        <select 
          name='scoreType' 
          id='scoreType' 
          className='form-control'
          onChange={(e) => {console.log(e.target.value); setScoreType(+e.target.value)}}
          value={ScoreType}
        >
          { scoreTypes.map( type => <option key={type.value} value={type.value} >{type.range}</option> ) }
        </select>
        </div>
      </div>
      <div className='form-group'>

        <div className='col-sm-8 col-sm-offset-4'>
        <input 
          type='checkbox' 
          name='DisplayDescending' 
          id='DisplayDescending' 
          onChange={(e) => setDescending(e.target.checked)} 
          defaultChecked={descending}
        />
        <label className="control-label" htmlFor='DisplayDescending'>Display options in descending order</label>
        </div>
      </div>
      <div className='form-group'>

        <div className='col-sm-8 col-sm-offset-4'>
        <input 
          type='checkbox' 
          name='AllowNa' 
          id='AllowNa' 
          onChange={(e) => setAllowNa(e.target.checked)} 
          defaultChecked={AllowNa}
        />
        <label className="control-label" htmlFor='AllowNa'>Allow N/A</label>
        </div>
      </div>
      <div className='form-group'>
        
        <div className='col-sm-8 col-sm-offset-4'>
        <input 
          type='checkbox' 
          name='IsPenaltyAssessment' 
          id='IsPenaltyAssessment' 
          onChange={(e) => setIsPenaltyAssessment(e.target.checked)} 
          defaultChecked={IsPenaltyAssessment}
        />
      
        <label className="control-label" htmlFor='IsPenaltyAssessment'>
          
          Penalty Assessment
          <i className="fas fa-siren-on text-danger"></i> 
        </label>
        </div>
      </div>
      <div className='form-group'>
        <label className="control-label col-sm-4" htmlFor='ValueOverride'>Assessment Weight: </label>
        <div className='col-sm-8'>
        <input 
          type='number' 
          name='ValueOverride' 
          className='form-control'
          id='ValueOverride' 
          onChange={(e) => setValueOverride(+e.target.value)} 
          min='0'
          max='100'
          placeholder='0 - 100'
        />
      </div>
      </div>
      <div className='ValueOverride-info bumper-l bumper-out-l'>
        <i className="fa fa-exclamation-triangle" aria-hidden="true" />
        If you set Assessment Weight, this question will be worth that many points within its category. Leave blank or enter 0 for default. You can use this feature to weight questions more without having to give them larger point ranges.
      </div>
 

      </div>
      <div className='buttons-area'>
        <button 
          className='btn btn-success save-assessment-btn' 
          onClick={handleSave}
        >
          <i className="far fa-save"></i>
          save
        </button>
        <button 
          className='btn btn-secondary' 
          onClick={() => setModalOpen(false)}
        >
          <i className="far fa-times-circle"></i>
          cancel
        </button>
        <button 
          className='btn btn-danger delete-assessment-btn' 
          onClick={handleDelete}
        >
          <i className="far fa-trash-alt"></i>
          delete
        </button>
      </div>
    </div>

  )
}

export default AddOrEditAssessment