import { Icons } from "react-toastify";
import Icon, { IconType } from "../../Icon/Icon";


const NewSite = ()=>{

    return (
        <div>
            TODO: Mike it goes here.
        </div>
    );

};

export default NewSite;