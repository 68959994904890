import { fabric } from "fabric";
import { PdfSelfSignExtendedInputs } from "./PdfSelfSignExtendedInputs";
import { PdfSelfSignState } from "./PdfSelfSign";
import { DrawingEditor } from "./FabricDrawers";
import { CreateFieldOptions } from "./CreateFieldOptions";


export enum InputPlacementMode {
    none="none",
    datePlacement = "datePlacement", 
    namePlacement = "namePlacement",
    signaturePlacement = "signaturePlacement",
    checkBoxPlacement = "checkBoxPlacement",
    freeformTextPlacement = "freeformTextPlacement"
}

export interface CanvasThingie {
    fabricCanvas: fabric.Canvas;
    canvas: HTMLCanvasElement;
    bgImageDataUrl: string;
    specialCursor?: fabric.Object;
    drawingEditor: DrawingEditor;
}


export type CanvasEventFunction = { (self: PdfSelfSignExtendedInputs, canvas: CanvasThingie, options?: CreateFieldOptions): void }

export type GetPlacementMode = { (self: PdfSelfSignExtendedInputs): InputPlacementMode }
export type DoesPlacementModeApply = { (self: PdfSelfSignExtendedInputs, placmentMode: InputPlacementMode): boolean }



export const getPlacementMode = (pdfSelfSignState: PdfSelfSignState) => {
    return pdfSelfSignState.inputPlacementMode;
}

export const doesPlacementModeApply = (pdfSelfSignState: PdfSelfSignState, placementMode: InputPlacementMode) => {
    let currentPlacementMode = getPlacementMode(pdfSelfSignState);
    return currentPlacementMode === placementMode && pdfSelfSignState.isPlacementMode;
}

export const PlaceCursor = (canvas: CanvasThingie, o: fabric.IEvent, cursor: fabric.Group, cursorText?: string) => {
    canvas.specialCursor = cursor;
    let pointer = canvas.fabricCanvas.getPointer(o.e);
    cursor.set({
        left: pointer.x,
        top: pointer.y,
    });
    // signatureCursor.item(1).set({
    //     text: 
    // })
    // let newObj = {
    //     text: this.state.signer
    // }
    // debugger 
    if (cursorText) {
        (cursor.getObjects()[0] as fabric.IText).set({ text: `${cursorText}` });
    }
    // (signatureCursor.getObjects()[0] as fabric.Rect).set({
    //     fill: '#aaaaaa'
    // });
    // (signatureCursor.getObjects()[1] as fabric.Circle).set({
    //     fill: '#aaaaaa'
    // });
    if (!cursor.canvas) {
        canvas.fabricCanvas.add(cursor);
    }
    canvas.fabricCanvas.renderAll();
}