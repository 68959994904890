import React from 'react'

const ExternalJudge = (props) => {
  const {
    judge,
    approveJudge,
    denyJudge,
    removeJudge
  } = props


  // ApprovedAtUtc null
  // CreatedAtUtc "/Date(1601417666763)/"
  // RemovedAtUtc null
  // accountCreatedWhenUtc "/Date(1565127265310)/"
  // email "bj@hi.com"
  // firstName "Bobby"
  // jsonDetails null
  // lastName "Jones"
  // phone null
  // portalId 11
  // username "bj@hi.com"


  return (
    <div
      className='external-judge'
    >
      <div className='card-header'>
        <h2>
          {judge.firstName} {judge.lastName} &nbsp;&nbsp;&nbsp;
          {judge.approved && !judge.removed && !judge.denied && <i className="far fa-check-circle text-success"></i>} 
          {judge.removed && <i className="far fa-times-circle text-danger"></i>} 
          {judge.denied && <div className="text-danger denied">DENIED</div>}
        </h2> 
        <div  className="person-info">
        {judge.PersonState && <span><strong>{judge.PersonState}</strong></span>}
        <span>{judge.email}</span>
        {judge.phone && <span>{judge.phone}</span>}
        </div>
        <div className="contest-info">
        {judge.ContestState && <span><strong>{judge.ContestState}</strong></span>}
        {judge.ContestName && <span>{judge.ContestName}</span>}
        {judge.ContestUrl && <span><a target="_blank" href={`https://${judge.ContestUrl}`}>{judge.ContestUrl}</a></span>}
        </div>
        
        
      </div>
      <div className='card-body'>
        
        <div>
          {!judge.approved && !judge.denied
            &&
            <>
              <button
                className='btn btn-success'
                onClick={() => approveJudge(judge.portalId, judge.email)}
              >
                <i className="far fa-thumbs-up"></i>
              Approve
            </button>
              <button
                className='btn btn-secondary'
                onClick={() => denyJudge(judge.portalId, judge.email)}
              >
                <i className="far fa-thumbs-down"></i>
              Deny
            </button>

            </>
          }
          {!judge.removed
            &&
            <button
              className='btn btn-danger'
              onClick={() => removeJudge(judge.portalId, judge.email)}
            >
              <i className="far fa-trash-alt"></i>
            remove
          </button>

          }
        </div>

      </div>
    </div>
  )
}


export default ExternalJudge