import { fabric } from "fabric";
import { v4 as uuidv4 } from "uuid";
import { CanvasEventFunction, doesPlacementModeApply, PlaceCursor, CanvasThingie, InputPlacementMode } from "./PdfSelfSignEventMethods";
import { PdfSelfSignExtendedInputs } from "./PdfSelfSignExtendedInputs";
import { CreateDateFieldOptions } from "./CreateFieldOptions";

export const handlePlaceDateField: CanvasEventFunction = (pdfSelfSign: PdfSelfSignExtendedInputs, canvasThingie: CanvasThingie) => {
    const options: CreateDateFieldOptions = {
        signerLabel: pdfSelfSign.state.activeSigner.label,
        // fill: pdfSelfSign.state.activeSigner.color,
        fontFamily: "Helvetica",
        backgroundColor: pdfSelfSign.state.activeSigner.color,
        fontSize: pdfSelfSign.state.fontSize * pdfSelfSign.scale,
        fontColor: pdfSelfSign.state.fontColor,
    }
    const dateCursor = buildDateCursor(options);
    const placementMode: InputPlacementMode = InputPlacementMode.datePlacement;
    canvasThingie.fabricCanvas.on("mouse:move", (o) => {
        if (doesPlacementModeApply(o.pdfSelfSignState, placementMode)) {
            PlaceCursor(canvasThingie, o, dateCursor);
        }
    });

    canvasThingie.fabricCanvas.on("mouse:out", (o) => {
        if (doesPlacementModeApply(o.pdfSelfSignState, placementMode)) {
            canvasThingie.fabricCanvas.remove(dateCursor);
        }
    });

    canvasThingie.fabricCanvas.on("mouse:up", (o) => {
        if (o.button === 1) {
            if (!o.target && !o.pdfSelfSignState.isPlacementMode) {
                if (o.pdfSelfSignState.inputPlacementMode === placementMode) {
                    PlaceCursor(canvasThingie, o, dateCursor);
                }
                return;
            }
            if (doesPlacementModeApply(o.pdfSelfSignState, placementMode)) {
                let dateBlock = buildDateCursor(options);
                dateBlock.uniqueId = uuidv4();
                dateBlock.set({
                    left: dateCursor.left,
                    top: dateCursor.top
                });
                console.log("Added Date to canvas");

                canvasThingie.fabricCanvas.add(dateBlock);
                canvasThingie.fabricCanvas.renderAll();
                pdfSelfSign.saveInput(dateBlock);
            }
        }
    });
}

//TODO:  probably should not pass in the pdfSelfSign object but instead pass in the text options.
const buildDateCursor = (options?: CreateDateFieldOptions) => {
    // let color = pdfSelfSign.state.signers[signerName].color;
    let text = new fabric.IText(`${new Date().toLocaleDateString()}`, {
        fontFamily: options?.fontFamily || "Brush Script MT",
        fontSize: options?.fontSize,
        fill: options?.fontColor,
        originX: 'center',
        originY: 'center',
        left: 0,
        lockRotation: true,
        hasRotatingPoint: false,
        editable: false
    });

    let rect = new fabric.Rect({
        originX: 'center',
        originY: 'center',
        fill: "white", //todo:  fill color
        height: text.height,
        width: text.width
    });

    let group = new fabric.Group([rect, text]);
    group.inputType = "Date";
    group._signerLabel = options?.signerLabel || "signer";

    return group;
}