import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { IState } from "../../../redux/redux";
import StoreX, { store } from "../../../redux/oldStore";
import { ServerResponse } from "../../../utils/Server";
import { IAssignmentInfo, ICirlceInfoApiCall, IJudgeAssignment, IProjectAssignment } from "./JudgingCirlcesTypes";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import Icon, { IconType } from "../../Icon/Icon";
import { ICircleInfo, IListItem } from "../../_Core/CoreTypes";
import Modal from "../../Modal/Modal";
import JudgingCircleManager from "./JudgingCircleManager";
import JudgingCircleGenator from "./JudgingCircleGenator";


interface IProps {
    state : IState;
}


const JudgingCirclesManager = (props:IProps) =>{
    const [info, setInfo] = useState<ICirlceInfoApiCall>();
    const [cateDic, setCateDic] = useState<Record<string, string>>({});
    const [roundDic, setRoundDic] = useState<Record<string, string>>({});
    const [divDic, setDivDic] = useState<Record<string, string>>({});
    const [judgeGroupDic, setJudgeGroupDic] = useState<Record<string, string>>({});
    const [selectedRound, setSelectedRound] = useState<number>();
    const [selectedCircle, setSelectedCircle] = useState<ICircleInfo>();
    const [showCircleManager, setShowCircleManager] = useState<boolean>(false);
    const [showCircleGenerator, setShowCircleGenerator] = useState<boolean>(false);

    useEffect(()=>{
        init();        
    },[]);

    const init = () =>{
        store.server.getApi<ServerResponse<ICirlceInfoApiCall>>(`../JudgingCircles/CircleInfo`)
        .then(x=>{
            if(x.Success){
                let cDic = {};
                let dDic = {};
                let rDic = {};
                let jgDic = {};
                x.Value.Categories.forEach(y=>{ cDic[y.id] = y.name});
                x.Value.Rounds.forEach(y=>{ rDic[y.id] = y.name});
                x.Value.JudgeGroups.forEach(y=>{ jgDic[y.id] = y.name});
                x.Value.Divisions.forEach(y=>{ dDic[y.id] = y.name});
                setInfo(x.Value);
                setCateDic(cDic);
                setRoundDic(rDic);
                setDivDic(dDic);
                setJudgeGroupDic(jgDic);
            } else {
                toast.error(x.Message);
            }
        });
    };

    const OpenEditCircle = (circle?:ICircleInfo)=>{
        setShowCircleManager(true);
        setSelectedRound(circle?.roundId ?? undefined)        
        setSelectedCircle(circle);
    };
    const OpenGenerateCircles = (round:IListItem)=>{
        setSelectedRound(+round.id);
        setShowCircleGenerator(true);
    };

    const ClearRound = (round:IListItem)=>{
        toast.dismiss();
        if(!window.confirm(`Are you sure you want to clear this round's entries assignments?`)){
            return;
        }

        store.server.postApi<ServerResponse<boolean>>(`../JudgingCircles/RoundClearCircles`, {roundId:round.id})
        .then(x=>{
            if(x.Success){
                toast.info(`Round's entry assignments have been cleared`);
                init();
            }else {
                toast.error(x.Message);
            }

        });
    };

    const AssignByCategory = (round:IListItem)=>{
        setSelectedRound(+round.id);
        toast.dismiss();
        if(!window.confirm(`Are you sure you want to reassign this round's entries by category? This will clear all existing entry assignments for this round.`)){
            return;
        }

        store.server.postApi<ServerResponse<boolean>>(`../JudgingCircles/RoundAssignCirclesByCategory`, {roundId:round.id})
        .then(x=>{
            if(x.Success){
                toast.info(`Entry Assignments to this round have been made!`);
                init();
            }else {
                toast.error(x.Message);
            }

        });
    };

    const mapCircles = (round:number) =>{
        let circles = info?.Circles.filter(x=>x.roundId === round) ?? [];

        if(circles.length === 0){
            return <div className="alert alert-info">
            <h4><Icon type={IconType.warning}/> No Circles</h4>        
            You have not created any circles for this round yet! Press the "Generate Cirlces" button or press the "Add New Judging Circle" button.
        </div>
        } else {
            return <div className="round-circle-list">
                {circles.map((x,i)=>{
                    return <div className="circle-item" key={`jcmri-cui-${i}`}>
                        <div className="flex-between">
                            <div>
                                <h5>{x.name}</h5>
                            </div>
                            <div>
                                <button type={'button'} className="btn btn-xs" onClick={()=>{
                                    OpenEditCircle(x);
                                }}>
                                    <Icon type={IconType.edit}/>
                                </button>
                            </div>
                        </div>
                        <div className="info">
                            {x.categoryId && <div className="details"><Icon type={IconType.squareO}/>{cateDic[x.categoryId]}</div>}
                            {x.divisionId && <div className="details">{divDic[x.divisionId]}</div>}
                            {(+(x.judgeGroup??0) > 0) && <div className="details"><Icon type={IconType.users}/> {judgeGroupDic[x.judgeGroup?.toString()??'']}</div>}
                        </div>
                    </div>
                })}
            </div>
        }

    }

    const mapRounds = () =>{
        if(!info || info.Rounds.length === 0){
            return <div className="alert alert-info">
                <h4><Icon type={IconType.warning}/> No Judging Rounds</h4>
                No judging rounds have been defined for this event, please creat at least one round to add judging circles.
            </div>
        }
        else {
            return <>
                {info.Rounds.map((x,i)=>{
                    let circles = info.Circles.filter(c=>c.roundId === +x.id);
                    return <div className="round-item" key={`jcmri-${i}`}>
                        <div className="flex-between header">
                            <div>
                                <h4>
                                    {x.name}
                                </h4>
                                {circles.length} Circles
                            </div>
                            <div className="text-right">
                                <div className="btn-group">
                                <a href={StoreX.BuildUrl(`../app/CirclesScheduler/${x.id}`)} className="btn btn-xs btn-default">
                                    <Icon type={IconType.calendar}/> Adv. Scheduling Tools
                                </a>
                                <button type={'button'} className="btn btn-xs btn-default"  onClick={()=>{AssignByCategory(x)}}>
                                    <Icon type={IconType.magic}/> Assign Projects to Circles by Category
                                </button>
                                </div>
                                <br/>
                                <div className="btn-group">
                                <a href={StoreX.BuildUrl(`../JudgingCircles/Print?roundid=${x.id}`)} className="btn btn-xs btn-default">
                                    <Icon type={IconType.print}/> Judging Rubric
                                </a>
                                <button type={'button'} className="btn btn-xs btn-info"  onClick={()=>{OpenGenerateCircles(x)}}>
                                    <Icon type={IconType.configure}/> Generate Circles
                                </button>
                                { circles.length > 0 && <button type={'button'} className="btn btn-xs btn-danger" onClick={()=>{ClearRound(x)}}>
                                    <Icon type={IconType.trashO}/> Clear
                                </button>}
                                </div>
                            </div>
                        </div>
                        {true && <div>
                                {mapCircles(+x.id)}
                            </div>}
                    </div>
                })}
            </>;
        }
    };

    return <>
    <div>
        <div className="flex-between">
            <div>
                <h3>Circles</h3>
            </div>
            <div>
                <button type={'button'} className="btn btn-default" onClick={()=>{OpenEditCircle()}}>
                    <Icon type={IconType.plus}/> Add New Judging Circle
                </button>
            </div>
        </div>
        <div className="judging-circle-round-list">
            {mapRounds()}
        </div>
    </div>

    {showCircleManager && info && <Modal setModalOpen={setShowCircleManager} size="m" title="Judging Circle Management">
            <JudgingCircleManager 
                state={props.state}
                info={info}
                circle={selectedCircle}
                onRemove={(x:ICircleInfo)=>{
                    setInfo({...info,Circles:info.Circles.filter(c=>c.publicId !== x.publicId)});
                    setShowCircleManager(false);
                }}
                onUpdate={(x:ICircleInfo)=>{
                    let circles = info.Circles.filter(c=>c.publicId !== x.publicId);
                    circles.push(x);
                    circles = circles.sort((a,b)=>{
                        return (a.name ?? '').toLowerCase() > (b.name ?? '').toLowerCase() ? 1:-1;
                    })
                    setInfo({...info,Circles:circles});
                }}
            />
        </Modal>}

        {showCircleGenerator && info && <Modal
            setModalOpen={setShowCircleGenerator}
            size="xl"
            title={`Auto Generate Circles Wizard for ${roundDic[(selectedRound ?? 0).toString()]}`}
        >
                <JudgingCircleGenator
                    state={props.state}
                    roundId={selectedRound ?? 0}
                    onRun={()=>{init();}}                    
                />
            </Modal>}

    </>;
};


export default JudgingCirclesManager;