import react, { useState } from 'react';
import Icon, { IconType } from '../Components/Icon/Icon';

interface IProps {
  src: string;
  alt?: string;
  className?: string;
  icon?: IconType;
}

const XImage = (props: IProps) => {
  const [error, setError] = useState<boolean>(false);

  return (
    <>
      {!error ? (
        <img
          alt={props.alt}
          className={props.className}
          src={props.src}
          onError={() => {
            setError(true);
          }}
        />
      ) : (
        <Icon type={props.icon ?? IconType.image} addClass={`error-image ${props.className}`} />
      )}
    </>
  );
};

export default XImage;
