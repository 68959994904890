import React, {useState, useEffect} from "react"
import { toast } from "react-toastify";
import { store } from "../../redux/oldStore";
import { ServerResponse } from "../../utils/Server";
import { ProjectDetails2 } from "./projectDetails2";
import { ICustomQuestions, IProject, IShowcaseSettings } from "./ShowcaseTypes";
import { useParams } from "react-router-dom";

interface IProjectDetailsWraperProps {
    ProjectKey?:string;
}

interface IProjectShowcaseData{
    projectCustomQuestions: ICustomQuestions[];
    settings: IShowcaseSettings;
    project: IProject;    
    myLikes: any;
    likes:any;
}

const ProjectDetailsWraper = (props:IProjectDetailsWraperProps) =>{
  const {projectKey} = useParams();
    const [data, setData] = useState<IProjectShowcaseData>();
    const [projectLikesDic, setProjectLikesDic] = useState<any>({});
    const [myLikesDic, setMyLikesDic] = useState<any>({});

    useEffect(()=>{
        LoadData(props.ProjectKey ?? projectKey ?? '');
    }, []);

    const LoadData = (key:string)=>{
        store.server.postApi<ServerResponse<IProjectShowcaseData>>('../Showcase/ProjectData', {projectKey:key})
        .then(x=>{
            if(x.Success){
                setData(x.Value);
                setProjectLikesDic(x.Value.likes);
                let myLikes = {};
                if (x.Value.myLikes) {
                  x.Value.myLikes.forEach((x) => {
                    myLikes[x] = "true";
                  });
                }
                setMyLikesDic(myLikes);
            } else {
                toast.error(x.Message, {autoClose:false});
            }
        });
    };

    
  const likeProject = (projectId: number, like: boolean) => {
    const request = { projectId, like };
    store.server.postApi("../showcase/LikeProject", request).then((x: any) => {
      if (x.Success) {
        let dic = projectLikesDic;
        let cv = dic[projectId] || 0;
        if (like) cv++;
        else cv--;

        dic[projectId] = cv;
        setProjectLikesDic(dic);

        let myLikes = myLikesDic;
        myLikes[projectId] = like;
        setMyLikesDic(myLikes);
      }
    });
  };

    return (
        <div className="showcase-project-container">
            {!data && <>loading...</>}
            {data && <>
                <ProjectDetails2 
                    project={data.project}
                    settings={data.settings}
                    projectQuestions={data.projectCustomQuestions} 
                    likes={projectLikesDic[data.project.projectIntId] || 0}
                    myLikes={myLikesDic}
                    likeProject={likeProject} 
                                   />
            </>}

        </div>
    );
}

export default ProjectDetailsWraper;