import { useEffect, useState } from 'react';
import { store } from '../../../redux/oldStore';
import { IState } from '../../../redux/redux';
import { ServerResponse } from '../../../utils/Server';
import { IJudgingRound, IJudgingRoundsApi } from '../JudgingCircles/JudgingCirlcesTypes';
import { toast } from 'react-toastify';
import Icon, { IconType } from '../../Icon/Icon';
import Modal from '../../Modal/Modal';

interface IProps {
  state: IState;
}

const JudgingRoundsManager = (props: IProps) => {
  const [info, setInfo] = useState<IJudgingRoundsApi>();
  const [showEditRound, setShowEditRound] = useState<boolean>(false);
  const [selectedRound, setSelectedRound] = useState<IJudgingRound>();
  const [rubricDic, setRubricDic] = useState<Record<number, string>>({});

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    store.server
      .postApi<ServerResponse<IJudgingRoundsApi>>('../JudgingManagement/JudgingRounds', {})
      .then((x) => {
        setInfo(x.Value);
        let rDic = {};
        x.Value.Rubrics.forEach((r) => {
          rDic[+r.id] = r.name;
        });
        setRubricDic(rDic);
        // toast.success('got rubric info')
      })
      .catch((err) => {
        console.error(err);
        toast.warning('did not get rubric info');
      });
  };

  const onSaveRound = () => {
    toast.dismiss();
    let errors: string[] = [];
    if ((selectedRound?.Name?.length ?? 0) === 0) errors.push(`Judging round name is required.`);

    if (errors.length > 0) {
      toast.warning(
        <>
          <ul>
            {errors.map((x, i) => {
              return <li key={`jrm-e-err-${i}`}>{x}</li>;
            })}
          </ul>
        </>
      );
      return;
    }

    store.server.postApi<ServerResponse<IJudgingRound>>(`../JudgingCircles/RoundUpdate`, {...selectedRound,RoundName:selectedRound?.Name}).then((x) => {
      if (x.Success) {
        let rounds = info?.Rounds.filter((r) => r.Id !== x.Value.Id) ?? [];
        if (info) {
          rounds.push(x.Value);
          rounds = rounds?.sort((a, b) => {return (a.Name?.toLowerCase()??'') > (b.Name?.toLowerCase()??'') ? 1:-1});
          setInfo({ ...info, Rounds: rounds });
        }
        setSelectedRound(x.Value);        
        toast.success(`Judging round "${selectedRound?.Name}" saved.`);
      } else {
        toast.error(x.Message, { autoClose: false });
      }
    });
  };
  const onRemoveRound = () => {

    if(!window.confirm(`${props.state.User?.firstName} are you sure you want to remove "${selectedRound?.Name}" judging round?`)){
        return;
    }

    store.server.postApi<ServerResponse<boolean>>(`../JudgingCircles/RoundRemove`, {roundId:selectedRound?.Id}).then((x) => {
        if (x.Success) {
          let rounds = info?.Rounds.filter((r) => r.Id !== selectedRound?.Id) ?? [];
          if (info) {
            setInfo({ ...info, Rounds: rounds });            
          }
          toast.info(`Judging round "${selectedRound?.Name}" removed.`);
          setSelectedRound(undefined);
          setShowEditRound(false);
        } else {
          toast.error(x.Message, { autoClose: false });
        }
      });
  };

  const mapRounds = () => {
    if (!info || (info.Rounds?.length ?? 0) === 0) {
      return (
        <div className="alert alert-info">
          <h3>
            <Icon type={IconType.warning} /> No Rounds
          </h3>
          There are not judging rounds defined, you will need to make judging rounds before you can setup any judging circles. Press the "Add Judging Round" button to add a new judging round.
        </div>
      );
    } else {
      return (
        <div className="judging-setup-rounds-list">
          {info.Rounds.filter(x=>!x.IsRemoved).map((x, i) => {
            return (
              <div className="judging-round-item" key={`jsrl-jr-${i}`}>
                <div className="flex-between">
                  <h4>
                    {x.Name}
                    {x.IsSubround && <Icon type={IconType.filter} title="This is a sub-round" />}
                  </h4>
                  <div>
                    <span
                      className="click"
                      onClick={() => {
                        setSelectedRound(x);
                        setShowEditRound(true);
                      }}>
                      <Icon type={IconType.edit} />
                    </span>
                  </div>
                </div>
                <div>
                  {(x.RoundValue ?? 0) > 0 && <div className="info">Round Value: {x.RoundValue}</div>}
                  {(x.UseRubric ?? 0) > 0 && <div className="info">Rubric: {rubricDic[x.UseRubric ?? 0]}</div>}
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  };

  return (
    <>
      <div className="flex-between">
        <h3>Judging Rounds</h3>
        <div>
          <button
            type={'button'}
            className="btn btn-default"
            onClick={() => {
              setSelectedRound({});
              setShowEditRound(true);
            }}>
            <Icon type={IconType.plus} /> Add Judging Round
          </button>
        </div>
      </div>
      <div>{mapRounds()}</div>

      {showEditRound && info && (
        <Modal setModalOpen={setShowEditRound} size="m" title="Judging Round Manager">
          <div className="form-horizontal">
            <div className="form-group">
              <label className="col-sm-4 control-label" htmlFor="jrmx-Name">
                Name
              </label>
              <div className="col-sm-8">
                <input
                  id={'jrmx-Name'}
                  type={'text'}
                  className="form-control"
                  maxLength={250}
                  value={selectedRound?.Name ?? ''}
                  onChange={(x) => {
                    setSelectedRound({ ...selectedRound, Name: x.target.value });
                  }}
                />
              </div>
            </div>
            <div className="form-group">
              <div className="col-sm-4"></div>
              <div className="col-sm-8">
                <input
                  id={'jrmx-IsSubRound'}
                  type={'checkbox'}
                  className="form-controlx"
                  checked={selectedRound?.IsSubround ?? false}
                  onChange={(x) => {
                    setSelectedRound({ ...selectedRound, IsSubround: x.target.checked });
                  }}
                />
                <label className="control-label" htmlFor="jrmx-IsSubRound">
                  This is a sub-round
                </label>
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-4 control-label" htmlFor="jrmx-Weight">
                Weight
              </label>
              <div className="col-sm-3">
                <input
                  id={'jrmx-Weight'}
                  type={'number'}
                  className="form-control"
                  max={100}
                  min={0}
                  step={5}
                  value={selectedRound?.RoundValue ?? ''}
                  onChange={(x) => {
                    setSelectedRound({ ...selectedRound, RoundValue: +x.target.value });
                  }}
                />
              </div>
            </div>

            <div className="form-group">
              <div className="col-sm-4"></div>
              <div className="col-sm-8"></div>
            </div>

            <div className="form-group">
              <div className="col-sm-4"></div>
              <div className="col-sm-8">
                <button type={'button'} className="btn btn-secondary" onClick={onSaveRound}>
                  <Icon type={IconType.save} /> Save
                </button>
                {(selectedRound?.Id ?? 0) > 0 && (
                  <button type={'button'} className="btn btn-default" onClick={onRemoveRound}>
                    <Icon type={IconType.trashO} /> Remove
                  </button>
                )}
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default JudgingRoundsManager;
