import React, { useState, useEffect } from "react";
import { store } from "../../redux/oldStore.ts";
import { toast } from "react-toastify";

export default function Load(props) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileText, setFileText] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [parsingErrors, setParsingErrors] = useState([]);
  useEffect(() => { }, []);

  const fileSelected = (e) => {
    let file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target.result;
      let lines = [];
      const perrors = [];
      let formattedText = text.replaceAll('\r\n','\n').replaceAll('\r', '\n');
      const rawLines = formattedText.split("\n");
      rawLines.forEach((l, index) => {
        const line = l.split(',');
        const columsPerLine = 10;
        if (line.length === columsPerLine) {
          //The line looks good
          lines.push(line);
        } else {
          const errorMessage = `Line ${index + 1} has ${line.length} colums, but should have ${columsPerLine}`
          console.error(errorMessage);
          perrors.push(errorMessage);
        }
      });
      if (perrors.length) {
        setParsingErrors(perrors);
      } else {
        setFileText(lines);
      }
    };
    reader.readAsText(file);
  };

  const load = (e) => {
    console.log('Sending to server...');
    store.server.postApi("../Biliteracy/load", { data: fileText }).then((x) => {
      if (x.Success) {
        toast.success("Your student's have been loaded.");
        setLoaded(true);
      } else {
        toast.error(x.Message);
      }
    }).catch(err => {
      console.log(err);
      toast.error(err);
    });
  };

  return (
    <>
      <div className="biliteracy-container">
        <div>
          <h3>Load Students</h3>
          <div>
            You can load students for your Seal of Biliteracy, using a CSV file.
            You can save Excel and Google Doc files as CSV files.
            <ul>
              <li>
                <strong>Google Sheets</strong> Select File, then Download then
                as Comma-separated values (.csv)
              </li>
              <li>
                <strong>Excel</strong> Select File, Save as, then chnage type to
                (*.csv)
              </li>
            </ul>
          </div>
          <h4>File Format</h4>
          <div>
            The CSV file should include the following columns in the following
            order.
            <ul>
              <li>First Name</li>
              <li>Last Name</li>
              <li>Email</li>
              <li>Username (Must be unique, you may use email)</li>
              <li>Password</li>
              <li>Language (language they will test in)</li>
              <li>School Name</li>
              <li>Teacher First Name (optional)</li>
              <li>Teacher Last Name (optional)</li>
              <li>Teacher Email (optional)</li>
            </ul>
          </div>
          {!loaded && <div className="">
            <h4>File</h4>
            <input type="file" accept=".csv" onChange={fileSelected} onClick={(evt)=>evt.target.value=null} /> {/*Set target to null so the onchange event will fire again even if the user picks the same file.*/ }

            {fileText && fileText.length > 0 && (
              <div className="csv-file-preview">
                <table>
                  {fileText.map((r, ri) => {
                    return (
                      <tr key={'r' + ri}>
                        {r.map((v, vi) => {
                          if (ri === 0) {
                            return <th key={ri + '-' + vi}>{v}</th>;
                          }
                          return <td key={ri + '-' + vi}>{v}</td>;
                        })}
                      </tr>
                    );
                  })}
                </table>
                <h4>If the above file content looks good. Press Load</h4>
                <button type="button" className="button" onClick={load}>
                  Load
                </button>
              </div>
            )}
            {parsingErrors.length > 0  &&
              <table>
                {parsingErrors.map((error, index) => (
                  <tr key={index}>
                    <td>{error}</td>
                  </tr>
                ))}
              </table>
            }
          </div>}
          {loaded && <div>
            <h1>Student's loaded!</h1>
            If you need to load more students refresh your page.
            </div>}
        </div>
      </div>
    </>
  );
}
