import React, { useState, useEffect } from "react";
import { store } from "../../redux/oldStore.ts";
import { toast } from "react-toastify";

import io from "socket.io-client";

export default function AdultReviewPortal(props) {
  const[inQueue, setInQueue] = useState(false);
  const[socket, setSocket] = useState();
  const[info, setInfo] = useState();
  const[line, setLine] = useState();

  useEffect(() => { 


      let url =
        window.location.host.indexOf("localhost") > -1
          ? "http://localhost:9191/online-judging"
          // ? "http://10.25.0.1:9191/online-judging"
          : "https://socket.zfairs.com/online-judging";

    let info;
    store.server
      .postApi("../Hinge/Info", {})
      .then((res) => {
        console.log("info response", res);
        if(res.Success){
        info = res.Value;

        //TODO: update information for the adult...
        let socket = io(url, {
          query: {
            fairId: info.fairId,
            clientId: info.clientId,
            personId: info.user.id,
            isReviewBoardMember:true,
            reviewBoard:true,
          }  ,
          transports: ['websocket', 'polling']    
        });
        setSocket(socket);
        setInfo(info);

        socket.on('projects-in-', (x=>{

          
        }));
      }else {
        toast.error(res.Message, {autoClose:false});
      }

      })
      .catch((err) => console.log(err));


  }, []);

  const enterQueue = () =>{
    socket.emit('review-board-waiting-room-joined', {        
      isReviewBoardMember:true,
    })

    setInQueue(true);
    toast.info('Entered review room');
  };
 
  const leaveQueue = () =>{
    socket.emit('review-board-waiting-room-left', {  
    })

    toast.info('Left review room');
    setInQueue(false);
  };
  return (
    <>
      Adult Review...
      {!inQueue && <button type="button" onClick={enterQueue}>Enter Queue</button>}
      {inQueue && <button type="button" onClick={leaveQueue}>Leave Queue</button>}

      <div>
        {line && <div className="project-line">
          {line.map((x,i)=>{
            return (<div>{i+1} - {x}</div>);
          })}
        </div>}
      </div>
    </>
  );
}
