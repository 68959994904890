import React, { useState, useEffect } from "react";
import { store } from "../../redux/oldStore";
import { toast } from "react-toastify";
import FileDownload from 'js-file-download';
import { UploadType, OtherUserType } from './ParticipantsUpload';
import { splitCSV } from '../../utils/Tools';


const ModeratorInterpreterUpload = (props: any) => {
  // Lifted straight from AcaDeca LoadJudges

  const [fileText, setFileText] = useState<any>(null);
  const [headersIncluded, setHeadersIncluded] = useState(false)
  const [loaded, setLoaded] = useState(false);
  const [parsingErrors, setParsingErrors] = useState<any[] | null>([]);
  const [uploadType, setUploadType] = useState<UploadType>('comma');


  useEffect(() => {

  }, []);

  const judgeCSVFileSelected = (e: any) => {
    let file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = async (e: any) => {
      const text = e.target.result;
      let lines: any[] = [];
      const perrors: string[] = [];
      let formattedText = text.replaceAll('\r\n',' \n').replaceAll('\r', ' \n');
      const rawLines = formattedText.split("\n");
      rawLines.forEach((l, index) => {
        if(l.length > 0){
          const line = splitCSV(l, uploadType);
          const columnsPerLine = 2;
          if (line.length === columnsPerLine) {
            //The line looks good
            lines.push(line);
          } else {
            const errorMessage = `Line ${index + 1} has ${line.length} columns, but should have ${columnsPerLine}`
            console.error(errorMessage);
            perrors.push(errorMessage);
          }
        }
      });
      if (perrors.length) {
        setParsingErrors(perrors);
      } else {
        setFileText(lines);
        setParsingErrors(null);
      }
    };
    reader.readAsText(file, 'utf-8-sig');
  };

  const load = (e) => {
    console.log('Sending to server...', fileText);
    // bring in the schoolText somewhere
    let realText = headersIncluded ? fileText.slice(1) : fileText
    store.server.postApi("../Import/ModeratorInterpreterAssignments", { 
        Data: realText,
      }).then((x: any) => {
      if (x.Success) {
        toast.success("Your users have been loaded.");
        setLoaded(true);
      } else {
        toast.error(x.Message);
      }
    }).catch(err => {
      console.log(err);
      toast.error(err);
    });
  };

  const downloadTemplateClick = () => { 
    const items = [{
      ['Judge Id']: '',
      ['Project Id']: ''     
     }]
      
     const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
     const header = Object.keys(items[0])
     const csv = [
       header.join(','), // header row first
       ...items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
     ].join('\r\n')
     
     console.log(csv)
     // downloady stuff here
     FileDownload(csv, 'other_users_upload_template.csv');
   }

  return (<>
      <div className="loading-container">
        <h1>Upload Proctor / Intrepreter Assignments</h1>
        <div>
          You can load information for your contest using a CSV file.
          You can save Excel and Google Doc files as CSV files.
          <ul>
            <li>
              <strong>Google Sheets</strong> Select File, then Download then
              as Comma-separated values (.csv)
            </li>
            <li>
              <strong>Excel</strong> Select File, Save as, then change type to
              (*.csv)
            </li>
          </ul>
        </div>
        <div className=''>

          <h3>Load Proctor and Interpreter Assignments</h3>
          <p>
          Instead of manually assign your proctors and interpreter you can upload your assignents here.
          </p>
          <div className="flex-between">
                    <h4>File Format</h4>
                    <button className='btn btn-secondary btn-xs' onClick={downloadTemplateClick}>Download Template</button>
          </div>
          <div>
            The other users CSV file should include the following columns in the following
            order.
            <ul>
              <li>Proctor Id <a
                href={`${window.location.host.indexOf("localhost") > -1 ? `http://localhost:8081/JudgingManagement/GetModeratorFile` : `../JudgingManagement/GetModeratorFile`}`}
                target="_blank"
                rel='noreferrer noopener'
                download
              >
                download all Proctors IDs
            </a></li>
              <li>Interpreter Id <a
                href={`${window.location.host.indexOf("localhost") > -1 ? `http://localhost:8081/JudgingManagement/GetInterpreterFile` : `../JudgingManagement/GetInterpreterFile`}`}
                target="_blank"
                rel='noreferrer noopener'
                download
              >
                download all Interpreter IDs
            </a></li>
              <li>Project Id <a
                href={`${window.location.host.indexOf("localhost") > -1 ? `http://localhost:8081/JudgingManagement/GetProjectFile` : `../JudgingManagement/GetProjectFile`}`}
                target="_blank"
                rel='noreferrer noopener'
                download
              >
                download all project IDs
              </a> (Id column not Project Id)</li>
            </ul>
            <div className='bumper-l'>

          </div>
          {!loaded && <div className="">

            <div className='bumper-l'>
            <label>Delimiter: </label>
              <select className="form-control" onChange={ e => setUploadType((e.target.value as UploadType))} value={uploadType}>
                <option value={'comma'}>Comma</option>
                <option value={'tab'}>Tab</option>
              </select>
            </div>
            <div className='bumper-l'>

            <label className=''>Users CSV File:</label>
              <input
                type="file"
                accept=".csv"

                onChange={judgeCSVFileSelected}
                onClick={(evt: any) => evt.target.value = null}
              // Set target to null so the onchange event will fire again even if the user picks the same file.
              />
            </div>
            

            <div className='flex bumper-l'>
              <label htmlFor='headers'>Does the file include headers?</label>
              <input
                type='checkbox'
                name="headers"
                id='headers'
                className='form-input bumper-sides-out-l'
                onChange={e => setHeadersIncluded(e.target.checked)}
                defaultChecked={headersIncluded}
              />
            </div>


            {fileText && fileText.length > 0 && (
              <div className="csv-file-preview">
                <table>
                  {!headersIncluded
                    &&
                    <thead>
                      <tr>
                        <th>Judge Id</th>
                        <th>Project Id</th>
                      </tr>
                    </thead>

                  }
                  <tbody>

                    {fileText.map((r, ri) => {
                      return (
                        <tr key={'r' + ri}>
                          {r.map((v, vi) => {
                            if (headersIncluded && ri === 0) {
                              return <th key={ri + '-' + vi}>{v}</th>;
                            }
                            return <td key={ri + '-' + vi}>{v}</td>;
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
            {parsingErrors && parsingErrors.length > 0 &&
              <table>
                {parsingErrors.map((error, index) => (
                  <tr key={index}>
                    <td>{error}</td>
                  </tr>
                ))}
              </table>
            }
          </div>}
          {!loaded && (fileText && fileText.length > 0) 
            && (
              <div className='ready-to-load-area'>
                <h4>If the contents look good, press upload</h4>
                <button type="button" className="btn btn-success" onClick={load}>
                  <i className="fad fa-cloud-upload"></i>
                  upload
                </button>
              </div>
           )
          }
          {loaded && <div>
            <h1>Users loaded!</h1>
            If you need to load more users refresh your page.
            </div>}
        </div>

      </div>
      </div>
    </>
  );
}

export default ModeratorInterpreterUpload 