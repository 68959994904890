export type videoMessageType =
  | "HANG_UP"
  | "MUTE_AUDIO"
  | "UNMUTE_AUDIO"
  | "MUTE_VIDEO"
  | "UNMUTE_VIDEO"
  | "SHARE_SCREEN"
  | "STOP_SHARE_SCREEN"
  | "MESSAGE"
  | "MUTE_ALL_AUDIO"
  | "MUTE_ALL_VIDEO"
  | "UNMUTE_ALL_AUDIO"
  | "UNMUTE_ALL_VIDEO"
  | "STYLE_OVERRIDE"
  | "HIDE_BUTTONS"
  | "HIDE_MUTE_ALL_BUTTON";

export type message = {
  type: videoMessageType;
  payload: any;
};

export interface Participant {
  // display?: string; // "Lead Judge Admin Admin|6b48faf6-2746-4f2b-a728-18fdad210774"
  // id: string; // "d81eab0f-7091-4891-9268-7c61f26710aa"
  // publisher: boolean; // true
  // talking: boolean; // false
  action: string;
  userId: string;
  username: string;
}

let docIframe: HTMLIFrameElement;
let __jitsi: any;

window.onbeforeunload = () => {
  if (__jitsi) {
    __jitsi.dispose();
  }
};

export interface VideoRoomInfo {
  userId: string;
  userName: string;
  id: string;
  videoServerUrl: string;
  socketUrl: string;
  socketToken: string;
  janusToken: string;
  joinAsObserver?: boolean;
  phoneNumber: string[];
  conferencePin: string;
}

export class DocTogetherVideo {
  uiSelector;
  initComplete: boolean;
  url: string;
  hangUpCallback;
  connectCallback;
  public setParticipants;
  iframe;
  videoWindow;
  el;
  styleOverride: string;
  hideButtons: boolean;
  useJitsi: boolean;
  participants: any;
  hideMuteAll: boolean;
  jitsiApi: any;

  constructor(uiSelector, options) {
    const emptyFunction = () => {};
    this.uiSelector = uiSelector;
    this.initComplete = false;
    this.participants = [{ username: "Student (me)", userId: "1234" }];

    const url = new URL(window.location.href);

    this.url =
      options.dev || url.searchParams.get("local")
        ? "http://localhost:3002"
        : "https://conference.doctogether.com";

    this.hangUpCallback = options.hangUp;
    this.connectCallback = options.connectCallback;
    this.setParticipants = options.setParticipants ?? emptyFunction;
    this.styleOverride = options.styleOverride;
    this.hideButtons = options.hideButtons;
    this.hideMuteAll = options.hideMuteAll;
    this.useJitsi = options.jitsi;
  }

  init() {
    if (this.initComplete) return;

    let el = (this.el = document.querySelector(this.uiSelector));
    if (!this.el) {
      console.log(`Could not find element window.${this.uiSelector}`);
      return;
    }

    if (this.useJitsi) {
      return this.LoadJitsi(el);
    }

    if (!docIframe) {
      docIframe = document.createElement("iframe");
    }
    let iframe = docIframe;

    const promise = new Promise<void>((resolve) => {
      iframe.onload = () => {
        let processMsg = (x) => {
          let { data } = x;
          if (data.type) console.log("parent message from react", data);
          switch (data.type) {
            case "HANG_UP":
              console.log("parent window hanging up");
              this.hangUp();
              break;
            case "MUTE_AUDIO":
              console.log("type:", data.type, "payload", data.payload);
              break;
            case "UNMUTE_AUDIO":
              console.log("type:", data.type, "payload", data.payload);
              break;
            case "UNMUTE_ALL_VIDEO":
              // debugger;
              console.log("type:", data.type, "payload", data.payload);
              break;
            case "MUTE_VIDEO":
              console.log("type:", data.type, "payload", data.payload);
              break;
            case "UNMUTE_VIDEO":
              console.log("type:", data.type, "payload", data.payload);
              break;
            case "SHARE_SCREEN":
              console.log("type:", data.type, "payload", data.payload);
              break;
            case "STOP_SHARE_SCREEN":
              console.log("type:", data.type, "payload", data.payload);
              break;
            case "MESSAGE":
              console.log("type:", data.type, "payload", data.payload);
              break;
            case "PARTICIPANTS":
              //this.setParticipants(data.payload);
              break;
            case "PERSON-JOINED":
              console.log("PERSON-JOINED", data);
              this.UpdateParticipantList(data.payload.action, false);
              break;
            case "PERSON-LEFT":
              console.log("PERSON-LEFT", data);
              this.UpdateParticipantList(data.payload.action, true);
              break;
            default:
              break;
          }
        };
        window.addEventListener("message", processMsg);
        this.initComplete = true;
        // if (this.hideButtons) this.hideThemButtons();
        if (this.hideMuteAll) this.hideMuteAllButton(); // this could be refactored into an object that contains which buttons to hide. Leaving this way for now because only the muteall makes sense to toggle
        resolve();
      };
    });

    iframe.src = `${this.url}/video-conference?123`;
    iframe.style.width = "100%";
    iframe.style.height = "100%";
    iframe.style.border = "none";
    iframe.allow = "camera; microphone; autoplay; fullscreen";

    (this.el as HTMLElement).appendChild(iframe);
    this.iframe = el.children[0];
    el.insertAdjacentHTML(
      "beforeend",
      `<div class="video-gate"><div>Video locked while we wait for judges.</div></div>`
    );
    this.videoWindow = this.iframe.contentWindow;

    return promise;
  }

  UpdateParticipantList(user: any, remove: boolean) {
    if (user && (!user.username || !user.userId)) {
      user.username = user.details.username;
      user.userId = user.details.userid;
    }
    if (!user || !user.username || !user.userId) return;

    let p = this.participants.find((x) => x.userId === user.userId);
    if (remove && p) {
      this.participants = this.participants.filter(
        (x) => x.userId !== user.userId
      );
      this.setParticipants(this.participants);
    } else if (!remove && !p) {
      this.participants.push(user);
      this.setParticipants(this.participants);
    }
  }

  LoadJitsi(el) {
    const loadJitsiScript = () =>
      new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = "https://jitsi.zfairs.com/external_api.js";
        script.async = true;
        script.onload = resolve;
        document.body.appendChild(script);

        console.log("Jitsi Loaded");
      });
    console.log("Callled LoadJitsi");

    return loadJitsiScript();
  }

  connect(accessToken, enableChat: boolean) {
    if (this.useJitsi) {
      const config = JitsivarInterfaceConfig;
      if (enableChat) {
        console.log("Chat Enabled for Video");
        config.TOOLBAR_BUTTONS.push("chat");
      }
      let self = this;
      let isDev = window.location.host.indexOf("localhost") > -1;
      const w: any = window;
      const api = new w.JitsiMeetExternalAPI("jitsi.zFairs.com", {
        parentNode: this.el,
        roomName: accessToken.id,
        interfaceConfigOverwrite: config,
        userInfo: {
          displayName: accessToken.userName,
        },
        onload: (x) => {
          console.log("Jitsi API Ready---", x, api);
          if (isDev) api.executeCommand("muteEveryone", "audio");
          api.executeCommand("subject", " ");
          api.executeCommand("displayName", accessToken.userName);
        },
      });
      self.jitsiApi = api;
      __jitsi = api;

      api.on("readyToClose", () => {
        self.hangUp();
      });

      api.on("participantJoined", (x) => {
        console.log(`********* Joined`, x);
        self.UpdateParticipantList(
          { username: x.displayName || "Unknown", userId: x.id },
          false
        );
      });
      api.on("participantLeft", (x) => {
        console.log(`********* Left`, x);
        self.UpdateParticipantList({ username: "-", userId: x.id }, true);
        if (window.location.host.indexOf("localhost:3000") > -1) {
          api.executeCommand("muteEveryone", accessToken.userName);
        }
      });

      if (self.connectCallback) {
        self.connectCallback();
      }

      return;
    }

    if (this.videoWindow) {
      const url = new URL(window.location.href);

      if (url.searchParams.get("local"))
        accessToken.socketUrl = "http://localhost:9191";

      this.videoWindow.postMessage(
        { type: "INITIAL_LOAD", payload: accessToken },
        "*"
      );

      if (this.connectCallback) {
        this.connectCallback();
      }
    } else {
      console.log("Not able to connect");
    }
  }

  sendMsg(type: videoMessageType, payload?) {
    if (this.videoWindow) {
      this.videoWindow.postMessage({ type, payload }, "*");
    }
  }

  hangUp() {
    this.participants = [{ username: "Student (me)", userId: "1234" }];
    this.setParticipants([]);

    if (this.useJitsi) {
      __jitsi.executeCommand("hangup");
      __jitsi.dispose();
      if (this.hangUpCallback) {
        this.hangUpCallback();
      }
      return;
    }
    if (this.videoWindow) {
      this.videoWindow.postMessage({ type: "HANG_UP" }, "*");

      if (this.hangUpCallback) {
        this.hangUpCallback();
      }
    } else {
      console.log("Not able to hangup");
    }
  }

  muteAudio() {
    if (this.useJitsi) {
      this.jitsiApi.isAudioMuted().then((muted) => {
        if (!muted) this.jitsiApi.executeCommand("toggleAudio");
      });
      return;
    }
    this.sendMsg("MUTE_AUDIO");
  }

  muteVideo() {
    if (this.useJitsi) {
      this.jitsiApi.isVideoMuted().then((muted) => {
        if (!muted) this.jitsiApi.executeCommand("toggleVideo");
      });
      return;
    }
    this.sendMsg("MUTE_VIDEO");
  }

  unMuteVideo() {
    if (this.useJitsi) {
      this.jitsiApi.isVideoMuted().then((muted) => {
        if (muted) this.jitsiApi.executeCommand("toggleVideo");
      });
      return;
    }
    this.sendMsg("UNMUTE_VIDEO");
  }

  unMuteAudio() {
    if (this.useJitsi) {
      this.jitsiApi.isAudioMuted().then((muted) => {
        if (muted) this.jitsiApi.executeCommand("toggleAudio");
      });
      return;
    }
    this.sendMsg("UNMUTE_AUDIO");
  }

  muteAllAudio() {
    this.sendMsg("MUTE_ALL_AUDIO");
  }

  muteAllVideo() {
    this.sendMsg("MUTE_ALL_VIDEO");
  }

  unmuteAllVideo() {
    // debugger;
    this.sendMsg("UNMUTE_ALL_VIDEO");
  }

  unmuteAllAudio() {
    this.sendMsg("UNMUTE_ALL_AUDIO");
  }

  hideThemButtons() {
    this.sendMsg("HIDE_BUTTONS");
  }

  hideMuteAllButton() {
    this.sendMsg("HIDE_MUTE_ALL_BUTTON");
  }

  implementStyles() {
    this.sendMsg("STYLE_OVERRIDE", { style: this.styleOverride });
  }
}

export class NoGateVideo extends DocTogetherVideo {
  async init() {
    await super.init();
    return;
  }
}

export const JitsivarInterfaceConfig = {
  APP_NAME: "zFairs",
  AUDIO_LEVEL_PRIMARY_COLOR: "rgba(255,255,255,0.4)",
  AUDIO_LEVEL_SECONDARY_COLOR: "rgba(255,255,255,0.2)",

  /**
   * A UX mode where the last screen share participant is automatically
   * pinned. Valid values are the string "remote-only" so remote participants
   * get pinned but not local, otherwise any truthy value for all participants,
   * and any falsy value to disable the feature.
   *
   * Note: this mode is experimental and subject to breakage.
   */
  AUTO_PIN_LATEST_SCREEN_SHARE: "remote-only",
  BRAND_WATERMARK_LINK: "",

  CLOSE_PAGE_GUEST_HINT: false, // A html text to be shown to guests on the close page, false disables it
  /**
   * Whether the connection indicator icon should hide itself based on
   * connection strength. If true, the connection indicator will remain
   * displayed while the participant has a weak connection and will hide
   * itself after the CONNECTION_INDICATOR_HIDE_TIMEOUT when the connection is
   * strong.
   *
   * @type {boolean}
   */
  CONNECTION_INDICATOR_AUTO_HIDE_ENABLED: true,

  /**
   * How long the connection indicator should remain displayed before hiding.
   * Used in conjunction with CONNECTION_INDICATOR_AUTOHIDE_ENABLED.
   *
   * @type {number}
   */
  CONNECTION_INDICATOR_AUTO_HIDE_TIMEOUT: 5000,

  /**
   * If true, hides the connection indicators completely.
   *
   * @type {boolean}
   */
  CONNECTION_INDICATOR_DISABLED: false,

  DEFAULT_BACKGROUND: "#474747",
  DEFAULT_LOCAL_DISPLAY_NAME: "me",
  DEFAULT_LOGO_URL: "",
  DEFAULT_REMOTE_DISPLAY_NAME: "",
  DEFAULT_WELCOME_PAGE_LOGO_URL: "",

  DISABLE_DOMINANT_SPEAKER_INDICATOR: false,

  DISABLE_FOCUS_INDICATOR: true,

  /**
   * If true, notifications regarding joining/leaving are no longer displayed.
   */
  DISABLE_JOIN_LEAVE_NOTIFICATIONS: false,

  /**
   * If true, presence status: busy, calling, connected etc. is not displayed.
   */
  DISABLE_PRESENCE_STATUS: false,

  /**
   * Whether the ringing sound in the call/ring overlay is disabled. If
   * {@code undefined}, defaults to {@code false}.
   *
   * @type {boolean}
   */
  DISABLE_RINGING: false,

  /**
   * Whether the speech to text transcription subtitles panel is disabled.
   * If {@code undefined}, defaults to {@code false}.
   *
   * @type {boolean}
   */
  DISABLE_TRANSCRIPTION_SUBTITLES: false,

  /**
   * Whether or not the blurred video background for large video should be
   * displayed on browsers that can support it.
   */
  DISABLE_VIDEO_BACKGROUND: false,

  DISPLAY_WELCOME_FOOTER: false,
  DISPLAY_WELCOME_PAGE_ADDITIONAL_CARD: false,
  DISPLAY_WELCOME_PAGE_CONTENT: false,
  DISPLAY_WELCOME_PAGE_TOOLBAR_ADDITIONAL_CONTENT: false,

  ENABLE_DIAL_OUT: false,

  ENABLE_FEEDBACK_ANIMATION: false, // Enables feedback star animation.

  FILM_STRIP_MAX_HEIGHT: 120,

  GENERATE_ROOMNAMES_ON_WELCOME_PAGE: false,

  /**
   * Hide the logo on the deep linking pages.
   */
  HIDE_DEEP_LINKING_LOGO: true,

  /**
   * Hide the invite prompt in the header when alone in the meeting.
   */
  HIDE_INVITE_MORE_HEADER: false,

  INITIAL_TOOLBAR_TIMEOUT: 20000,
  JITSI_WATERMARK_LINK: "",

  LANG_DETECTION: true, // Allow i18n to detect the system language
  LIVE_STREAMING_HELP_LINK: "https://jitsi.org/live", // Documentation reference for the live streaming feature.
  LOCAL_THUMBNAIL_RATIO: 16 / 9, // 16:9

  /**
   * Maximum coefficient of the ratio of the large video to the visible area
   * after the large video is scaled to fit the window.
   *
   * @type {number}
   */
  MAXIMUM_ZOOMING_COEFFICIENT: 1.3,

  /**
   * Whether the mobile app Jitsi Meet is to be promoted to participants
   * attempting to join a conference in a mobile Web browser. If
   * {@code undefined}, defaults to {@code true}.
   *
   * @type {boolean}
   */
  MOBILE_APP_PROMO: false,

  /**
   * Specify custom URL for downloading android mobile app.
   */
  MOBILE_DOWNLOAD_LINK_ANDROID:
    "https://play.google.com/store/apps/details?id=org.jitsi.meet",

  /**
   * Specify custom URL for downloading f droid app.
   */
  MOBILE_DOWNLOAD_LINK_F_DROID:
    "https://f-droid.org/en/packages/org.jitsi.meet/",

  /**
   * Specify URL for downloading ios mobile app.
   */
  MOBILE_DOWNLOAD_LINK_IOS:
    "https://itunes.apple.com/us/app/jitsi-meet/id1165103905",

  NATIVE_APP_NAME: "zFairs",

  // Names of browsers which should show a warning stating the current browser
  // has a suboptimal experience. Browsers which are not listed as optimal or
  // unsupported are considered suboptimal. Valid values are:
  // chrome, chromium, edge, electron, firefox, nwjs, opera, safari
  OPTIMAL_BROWSERS: [
    "chrome",
    "chromium",
    "firefox",
    "nwjs",
    "electron",
    "safari",
  ],

  POLICY_LOGO: null,
  PROVIDER_NAME: "zFairs",

  /**
   * If true, will display recent list
   *
   * @type {boolean}
   */
  RECENT_LIST_ENABLED: false,
  REMOTE_THUMBNAIL_RATIO: 1, // 1:1

  SETTINGS_SECTIONS: ["devices"],
  SHOW_BRAND_WATERMARK: false,

  /**
   * Decides whether the chrome extension banner should be rendered on the landing page and during the meeting.
   * If this is set to false, the banner will not be rendered at all. If set to true, the check for extension(s)
   * being already installed is done before rendering.
   */
  SHOW_CHROME_EXTENSION_BANNER: false,

  SHOW_DEEP_LINKING_IMAGE: false,

  SHOW_JITSI_WATERMARK: true,
  SHOW_POWERED_BY: false,
  SHOW_PROMOTIONAL_CLOSE_PAGE: false,

  /*
   * If indicated some of the error dialogs may point to the support URL for
   * help.
   */
  SUPPORT_URL: "",

  TOOLBAR_ALWAYS_VISIBLE: false,

  /**
   * The name of the toolbar buttons to display in the toolbar, including the
   * "More actions" menu. If present, the button will display. Exceptions are
   * "livestreaming" and "recording" which also require being a moderator and
   * some values in config.js to be enabled. Also, the "profile" button will
   * not display for users with a JWT.
   * Notes:
   * - it's impossible to choose which buttons go in the "More actions" menu
   * - it's impossible to control the placement of buttons
   * - 'desktop' controls the "Share your screen" button
   */
  TOOLBAR_BUTTONS: [
    "microphone",
    "camera",
    "hangup",
    "desktop",
    "fodeviceselection", //'chat',
    "settings",
    "raisehand",
    "filmstrip",
    "tileview",
    //, 'mute-everyone'
  ],

  TOOLBAR_TIMEOUT: 4000,

  // Browsers, in addition to those which do not fully support WebRTC, that
  // are not supported and should show the unsupported browser page.
  UNSUPPORTED_BROWSERS: [],

  /**
   * Whether to show thumbnails in filmstrip as a column instead of as a row.
   */
  VERTICAL_FILMSTRIP: true,

  // Determines how the video would fit the screen. 'both' would fit the whole
  // screen, 'height' would fit the original video height to the height of the
  // screen, 'width' would fit the original video width to the width of the
  // screen respecting ratio.
  VIDEO_LAYOUT_FIT: "both",

  /**
   * If true, hides the video quality label indicating the resolution status
   * of the current large video.
   *
   * @type {boolean}
   */
  VIDEO_QUALITY_LABEL_DISABLED: false,

  /**
   * How many columns the tile view can expand to. The respected range is
   * between 1 and 5.
   */
  // TILE_VIEW_MAX_COLUMNS: 5,

  /**
   * Specify Firebase dynamic link properties for the mobile apps.
   */
  // MOBILE_DYNAMIC_LINK: {
  //    APN: 'org.jitsi.meet',
  //    APP_CODE: 'w2atb',
  //    CUSTOM_DOMAIN: undefined,
  //    IBI: 'com.atlassian.JitsiMeet.ios',
  //    ISI: '1165103905'
  // },

  /**
   * Specify mobile app scheme for opening the app from the mobile browser.
   */
  // APP_SCHEME: 'org.jitsi.meet',

  /**
   * Specify the Android app package name.
   */
  // ANDROID_APP_PACKAGE: 'org.jitsi.meet',

  /**
   * Override the behavior of some notifications to remain displayed until
   * explicitly dismissed through a user action. The value is how long, in
   * milliseconds, those notifications should remain displayed.
   */
  // ENFORCE_NOTIFICATION_AUTO_DISMISS_TIMEOUT: 15000,

  // List of undocumented settings
  /**
   INDICATOR_FONT_SIZES
   PHONE_NUMBER_REGEX
  */

  // Allow all above example options to include a trailing comma and
  // prevent fear when commenting out the last value.
  // eslint-disable-next-line sort-keys
  makeJsonParserHappy: "even if last key had a trailing comma",

  // No configuration value should follow this line.
};
