import React, { useState, useEffect, useCallback, Component, useRef, useMemo } from 'react';
import Icon, { IconType } from '../Icon/Icon';
import { toast } from 'react-toastify';
import { UiValuePairs } from '../../utils/CommonTypes/UserTypes';
import { EasyImage, ExpandableImg, FileType } from '../Tools/ExpandableDiv';
import { store } from '../../redux/oldStore';
import { ServerResponse } from '../../utils/Server';
import { IFileInfo } from '../../redux/types';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { NewGuid } from '../../utils/Tools';
import Modal from '../Modal/Modal';
import XEditor from '../Tools/xEditor';

interface IEditorProps {
  value: string;
  options: 'simple' | 'full';
  changed: Function;
  height: 'normal' | 'short';
  onblur?: (value?: any) => void;
  readOnly?: boolean;
  saveBeforeChange?: boolean;
  close?: () => void;
  hideExpand?: boolean;
  placeholder?: string;
}

const Editor = (props: IEditorProps) => {
  const id = NewGuid();
  const quillRef = useRef<ReactQuill>();
  const [editorState, setEditorState] = useState<string>('');
  const [expanded, setExpanded] = useState<boolean>(false);
  const [showSource, setShowSource] = useState<boolean>(false);
  const [sourceContent, setSourceContent] = useState<string>('');

  useEffect(() => {
    if (props.value) {
      setEditorState(props.value);
    } else {
    }
  }, []);

  const onBlur = (event, editorState) => {
    if (!props.saveBeforeChange && props.onblur) props.onblur();
  };

  const savePress = () => {
    let val = editorState;
    props.changed(val);
    if (props.onblur) props.onblur(val);
  };
  const closePress = () => {
    if (props.close) props.close();
  };
  const closeSavePress = () => {
    savePress();
    closePress();
  };

  const imageClickHandler = () => {
    let editor = quillRef.current?.getEditor();
    if (editor) {
      let range = editor.getSelection(true);
      let value = prompt('Enter the image URL');
      if (value) {
        editor.insertEmbed(range.index, 'image', value);
      }
    }
  };

  const sourceClickHandler = () => {
    if(!showSource) {
      setSourceContent(quillRef.current?.getEditor().root.innerHTML);
    }
    else {
      let editor = quillRef.current?.getEditor();
      if (editor) {
        editor.clipboard.dangerouslyPasteHTML(sourceContent);
        toast.success('Source applied');
      } else alert('No editor found');
    }

    setShowSource(!showSource);
  };

  const buildToolBar = () => {
    switch (props.options) {
      case 'full':
        return (
          <>
            <div className="ql-toolbar ql-snow" id={`menu-${id}`}>
              <span className="ql-formats">
                <button type="button" className="ql-bold"></button>
                <button type="button" className="ql-italic"></button>
                <button type="button" className="ql-underline"></button>
                <button type="button" className="ql-strike"></button>
              </span>
              <span className="ql-formats">
                <select className="ql-align">
                  <option selected={true}></option>
                  <option value="center"></option>
                  <option value="right"></option>
                  <option value="justify"></option>
                </select>
                <select className="ql-size">
                  <option value="small"></option>
                  <option selected={true}></option>
                  <option value="large"></option>
                  <option value="huge"></option>
                </select>
                <select className="ql-header">
                  <option value="1"></option>
                  <option value="2"></option>
                  <option value="3"></option>
                  <option value="4"></option>
                  <option value="5"></option>
                  <option value="6"></option>
                  <option selected={true}></option>
                </select>
              </span>
              <span className="ql-formats">
                <button type="button" className="ql-list" value="ordered"></button>
                <button type="button" className="ql-list" value="bullet"></button>
                <button type="button" className="ql-list" value="check"></button>
              </span>
              <span className="ql-formats">
                <button type="button" className="ql-indent" value="-1"></button>
                <button type="button" className="ql-indent" value="+1"></button>
              </span>
              <span className="ql-formats">
                <button type="button" className="ql-link"></button>
                <button type="button" className="ql-image"></button>
                <button type="button" className="ql-video"></button>
              </span>
              <span className="ql-formats">
                <button type="button" className="ql-clean"></button>
              </span>
              <span className="ql-formats">
                <button type="button" className="ql-source"><Icon type={IconType.code}/></button>
              </span>
              {props.hideExpand ? false : true && <span className="ql-formats">
                    <button type="button" className="ql-expand">
                      <Icon type={IconType.expand} />
                    </button> 
                    <button type="button" className="ql-colapse">
                      <Icon type={IconType.compress} />
                    </button>
                  </span>}
            </div>
          </>
        );
      case 'simple':
          return (
            <>
              <div className="ql-toolbar ql-snow" id={`menu-${id}`}>
                <span className="ql-formats">
                  <button type="button" className="ql-bold"></button>
                  <button type="button" className="ql-italic"></button>
                  <button type="button" className="ql-underline"></button>
                  <button type="button" className="ql-strike"></button>
                </span>
                <span className="ql-formats">
                  <select className="ql-align">
                    <option selected={true}></option>
                    <option value="center"></option>
                    <option value="right"></option>
                    <option value="justify"></option>
                  </select>
                </span>
                <span className="ql-formats">
                  <button type="button" className="ql-list" value="ordered"></button>
                  <button type="button" className="ql-list" value="bullet"></button>
                  <button type="button" className="ql-list" value="check"></button>
                </span>
                <span className="ql-formats">
                  <button type="button" className="ql-clean"></button>
                </span>
                {props.hideExpand ? false : true && <span className="ql-formats">
                    <button type="button" className="ql-expand">
                      <Icon type={IconType.expand} />
                    </button> 
                    <button type="button" className="ql-colapse">
                      <Icon type={IconType.compress} />
                    </button>
                  </span>}
              </div>
            </>
          );
    }
  };

  const modules = useMemo(() => {
    return {
      toolbar: {
        container: `#menu-${id}`,
        handlers: {
          image: imageClickHandler,
          source: sourceClickHandler,
          expand: () => { setExpanded(true); },
          colapse: () => { setExpanded(false);},
        },
      },
    };
  }, [props.options]);

  const getHeightClass = () => {
    if (props.height === 'short') {
      return 'editor-short';
    }
    return 'editor-normal';
  };

  return (
    <>
      <div
        className={expanded ? 'wysiwg-expanded' : ''}
        onMouseDown={() => {
          setExpanded(false);
        }}>
        <div
          className="wysiwg-container"
          onMouseDown={(e) => {
            e.stopPropagation();
          }}>
          {buildToolBar()}
          <ReactQuill theme="snow" 
          value={editorState} 
          onChange={(x)=>{setEditorState(x); props.changed?.(x)}} 
          className={`wysiwyg ${getHeightClass()}`} 
          onBlur={onBlur} 
          ref={quillRef} 
          modules={modules}
          placeholder={props.placeholder}
           />

          {props.saveBeforeChange && (
            <button type="button" className="btn btn-secondary" onClick={savePress}>
              <Icon type={IconType.save} /> Save{' '}
            </button>
          )}
          {props.close && props.saveBeforeChange && (
            <button type="button" className="btn btn-default" onClick={closeSavePress}>
              <Icon type={IconType.save} /> Save & Close{' '}
            </button>
          )}
          {props.close && (
            <button type="button" className="btn btn-default" onClick={closePress}>
              <Icon type={IconType.close} /> Close{' '}
            </button>
          )}
        </div>
      </div>

      {showSource && sourceContent && <Modal title='View Source' setModalOpen={sourceClickHandler} size='xxl'>
          <XEditor valueChanged={(x) => {
            setSourceContent(x);
          }} value={sourceContent} type='html' className='page-editor-view-source' />
        </Modal>}
    </>
  );
};

export default Editor;

interface IFilePickerProps {
  fileTypes: 'image' | 'pdf' | 'video' | 'all';
  valueSelected: Function;
}

interface IfilePickerResponse {
  files: IClientFile[];
}

export interface IClientFile {
  Value: string;
  Name: string;
  Id: string;
  IsImage: boolean;
}

export const FilePicker = (props: IFilePickerProps) => {
  const [info, setFileInfo] = useState<IFileInfo>();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [showOptions, setShowOptions] = useState<boolean>();
  const [files, setFiles] = useState<IClientFile[]>([]);

  useEffect(() => {
    Init();
  }, [props.fileTypes]);

  const Init = () => {
    store.server.getApi<ServerResponse<IfilePickerResponse>>(`../file/clientFiles`).then((x) => {
      if (x.Success) {
        setFiles(x.Value.files);
      } else {
        toast.warning(x.Message);
      }
    });
  };
  const selectExistingFile = (x: IClientFile) => {
    let fi = getFileInfo(x.Value);
    if (props.valueSelected) props.valueSelected(x.Value);
    setFileInfo(fi ?? undefined);
    setShowOptions(false);
  };

  const removeFile = (file: IClientFile) => {
    if (window.confirm(`Are you sure you want to remove this "${file.Name}" file?`)) {
      store.server.postApi<ServerResponse<IfilePickerResponse>>(`../File/RemoveClientFile/${file.Id}`, {}).then((x) => {
        if (x.Success) {
          Init();
        } else {
          toast.warning(x.Message);
        }
      });
    }
  };

  const onChangeFile = async (e) => {
    if (e.target.files) {
      let file = await isFileValid(e.target.files);

      if (!file) {
        e.target.files = null;
        if (fileInputRef.current) fileInputRef.current.value = '';
        return;
      }

      if (e.target && e.target.value) e.target.value = null;

      if (fileInputRef.current) fileInputRef.current.value = '';

      store.server.postApi<ServerResponse<IfilePickerResponse>>(`../File/SaveClientFile`, {}, [file.file]).then((x) => {
        if (x.Success) {
          Init();
        } else {
          toast.warning(x.Message);
        }
      });

      setFileInfo(file.fileInfo);
      setShowOptions(false);
    }
  };

  const isFileValid = async (files: FileList) => {
    if (!files) return;
    let file = files[0];

    let size = file.size / 1024 / 1024;
    let name = file.name;
    let fileInfo = getFileInfo(file.name);
    if (!fileInfo) return null;

    if (fileInfo?.type === 'video' && size > 1024) {
      //to big
      toast.warning(`The file "${name}" is too large. The max file size is 1 GB`);
      return null;
    } else if (fileInfo?.type === 'pdf' && size > 50) {
      //to big
      toast.warning(`The file "${name}" is too large. The max file size is 50 MB`);
      return null;
    } else if (size > 20) {
      //to big
      toast.warning(`The file "${name}" is too large. The max file size is 20 MB`);
      return null;
    }

    fileInfo.urlSource = (await readURL(file)) as string;

    return { file, fileInfo };
  };

  const getFileInfo = (filePath: string | undefined) => {
    if (!filePath || typeof filePath !== typeof ' ') return null;

    let ext = filePath.substring(filePath.lastIndexOf('.')).toLowerCase();
    let isPdf = ext === '.pdf';
    let isImage = ext === '.jpg' || ext === '.jpeg' || ext === '.png' || ext === '.tif' || ext === '.tiff' || ext === '.gif';
    let isVideo = ['.mov', '.mp4'].find((x) => x === ext);
    let isText = ['.csv', '.xml', '.txt'].find((x) => x === ext);
    let type: FileType = isPdf ? 'pdf' : isImage ? 'image' : isVideo ? 'video' : isText ? 'text' : 'other';

    let result: IFileInfo = { type: type, path: filePath, ext: ext };
    return result;
  };

  const readURL = (file) => {
    return new Promise((res, rej) => {
      const reader = new FileReader();
      reader.onload = (e) => res(e.target.result);
      reader.onerror = (e) => rej(e);
      reader.readAsDataURL(file);
    });
  };

  const nameOrFile = (x: UiValuePairs) => {
    let file = getFileInfo(x.Value);
    if (file && file.path) {
      switch (file.type) {
        case 'image':
          return (
            <div className="flex-between">
              <div className="thumbnail-1 click">
                <EasyImage src={file.path} />
              </div>
              {/* <div className="thumbnail-1 click"><EasyImage imageSrc={[{alt:x.Name, path:file.path}]} name={x.Name} onClick={()=>{selectExistingFile(x)}}  /></div> */}
            </div>
          );
        case 'pdf':
          return (
            <div>
              <a href={file.path}>
                <Icon type={IconType.filePdf} /> {x.Name}
              </a>
            </div>
          );

        case 'video':
          return (
            <div>
              <a href={file.path} target={'_blank'}>
                <Icon type={IconType.video} /> {x.Name}
              </a>
            </div>
          );
        case 'text':
        case 'other':
          return (
            <div>
              <a href={file.path} target={'_blank'}>
                <Icon type={IconType.fileInvoice} /> {x.Name}
              </a>
            </div>
          );
          break;
      }
    }
    return <strong>{x.Name}</strong>;
  };

  return (
    <>
      <div className="file-picker existing-file-container">
        <div className="images">
          {files
            .filter((x) => x.IsImage)
            .map((x, i) => {
              return (
                <div key={`efo-${i}`} className="existing-file-item">
                  {nameOrFile(x)}
                  <span
                    className="remove text-danger"
                    onClick={() => {
                      removeFile(x);
                    }}>
                    <Icon type={IconType.trashO} />
                  </span>
                </div>
              );
            })}
          {files
            .filter((x) => !x.IsImage)
            .map((x, i) => {
              return (
                <div key={`efo-${i}`} className="existing-file-item">
                  {nameOrFile(x)}
                  <span
                    className="remove text-danger"
                    onClick={() => {
                      removeFile(x);
                    }}>
                    <Icon type={IconType.trashO} />
                  </span>
                </div>
              );
            })}
        </div>

        <div className="addmore">
          <h4>Upload File</h4>
          <input type="file" className="form-control" ref={fileInputRef} onChange={onChangeFile} accept={'.pdf,.jpg,.txt,.xml,.docx,.doc,.xlsx,.xls,.png,.gif,.mp4,.jpeg,.csv,.ppt,.pptx'} />
        </div>
      </div>
    </>
  );
};
