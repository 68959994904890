import React, { useState, useEffect } from "react";
import { store } from "../../redux/oldStore.ts";
import ProjectView from "./ProjectView";
import "./project.css";
export default function ProjectList(props) {
  const [shownProjects, setShownProjects] = useState(props.projects);
  const projects = props.projects;
  const schools = {};
  let searchString = "";
  let selectedSchool = 0;

  props.schools.forEach((x) => {
    schools[x.id] = x.name;
  });

  const filter = () => {
    let s = [];

    projects.forEach((x) => {
      if (x.Search.indexOf(searchString) > -1) {
        if (selectedSchool == 0 || x.SchoolsCsv.indexOf(selectedSchool) > -1) {
          s.push(x);
        }
      }
    });

    setShownProjects(s);
  };

  const searchChanged = (e) => {
    searchString = e.target.value.toLowerCase();
    filter();
  };
  const schoolChanged = (e) => {
    selectedSchool = e.target.value;
    filter();
  };

  return (
    <div className="project-list">
      <div className="search">
        <div className="inputs">
          <i className="fal fa-search"></i>
          <input
            type="text"
            className="form-control"
            onChange={searchChanged}
            placeholder="search"
          />
          {props.schools && props.schools.length > 0 && (
            <select className="form-control" onChange={schoolChanged}>
              <option value="0">all schools</option>
              {props.schools.map((x) => {
                return <option value={x.id} key={x.id}>{x.name}</option>;
              })}
            </select>
          )}
        </div>
      </div>
      <div className="content">
        {shownProjects.map((x) => {
          return <ProjectView key={x.Id} project={x} schools={schools} onSelect={props.onSelect}></ProjectView>;
        })}
      </div>
    </div>
  );
}
