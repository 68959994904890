import React, { useEffect, useState } from 'react';
import { QueryStuff } from '../../../QuestionCreation/QuestionCreator';
import { JudgingQuickComment, QuickCommentLevel, SubCategoryOptions } from './QuickComments';
import queryString from 'query-string'
import { store } from '../../../../redux/oldStore';
import { toast } from 'react-toastify';



interface AddOrEditQuickCommentProps {
  selectedComment?: JudgingQuickComment
  setSelectedComment: (comment: JudgingQuickComment | undefined) => void;
  setModalOpen: (open: boolean) => void;
  commentLevel: QuickCommentLevel;
  availableCategories: string[];
  availableSubCategories: SubCategoryOptions[];
  refreshQuickComments: () => void;
}

const AddOrEditQuickComment = (props: AddOrEditQuickCommentProps) => {
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [comment, setComment] = useState('---');
  const [sortVal, setSortVal] = useState(1);

  useEffect(() => {
    if (props.selectedComment) {
      setCategory(props.selectedComment.ParentGroup);
      setSubCategory(props.selectedComment.ParentGroupSub);
      setComment(props.selectedComment.Comment);
      setSortVal(props.selectedComment.Sort);
    }
  }, [props.selectedComment])


  const builtForm = {
    Category() {
      return (
        <div className='form-group'>
          <label htmlFor='category'>Category: </label>
          <input id='category' className='form-control' value={category} onChange={e => setCategory(e.target.value)} />
        </div>
      )
    },
    SubCategory() {
      return (
        <div>
          <div className='form-group'>
            <label htmlFor='category'>Category: </label>
            <select
              id='category'
              className='form-control'
              value={category}
              onChange={e => setCategory(e.target.value)}
            >
              <option value={''}> </option>
              {props.availableCategories.map(cat => {
                return (<option key={cat} value={cat}>{cat}</option>)
              })}
            </select>
          </div>
          <div className='form-group'>
            <label htmlFor='subcategory'>Subcategory: </label>
            <input id='subcategory' className='form-control' value={subCategory} onChange={e => setSubCategory(e.target.value)} />
          </div>
        </div>
      )
    },
    Comment() {
      return (
        <div>
          <div className='form-group'>
            <label htmlFor='category'>Category: </label>
            <select
              id='category'
              className='form-control'
              value={category}
              onChange={e => setCategory(e.target.value)}
            >
              <option value={''}> </option>
              {props.availableCategories.map(cat => {
                return (<option key={cat} value={cat}>{cat}</option>)
              })}
            </select>
          </div>
          <div className='form-group'>
            <label htmlFor='subcategory'>Subcategory: </label>
            <select
              id='subcategory'
              className='form-control'
              value={subCategory}
              onChange={e => setSubCategory(e.target.value)}
            >
              <option value={''}> </option>
              {props.availableSubCategories
                .filter(x => x.parent === category)
                .filter(y => Boolean(y.sub))
                .map(obj => {
                  return (<option key={obj.sub} value={obj.sub}>{obj.sub}</option>)
                })
              }
            </select>
          </div>
          <div className='form-group'>
            <label htmlFor='comment-name'>Comment: </label>
            <input
              type='text'
              id='comment-name'
              className='form-control'
              value={comment}
              onChange={e => setComment(e.target.value)}
            />
          </div>
        </div>
      )
    }
  }

  const getQueryStuff = () => {
    const queryValues: QueryStuff = queryString.parse(window.location.search);
    return queryValues;
  }

  const handleSaveClick = () => {
    console.log('handleSaveClick clicked');
    if (!category) return toast.error('must have Category');
    let saveObject: any = {
      Active: true,
      ParentGroup: category,
      Sort: sortVal,
      Comment: comment,
      RubricId: getQueryStuff().rubricId
    };
    if (subCategory) saveObject.ParentGroupSub = subCategory;
    if (props.selectedComment) saveObject.Id = props.selectedComment.Id;

    store.server.postApi('../OnlineJudgingSetup/AddQuickCommentJson', saveObject)
      .then(res => {
        // console.log(res); 
        props.refreshQuickComments();
        props.setModalOpen(false);
      })
      .catch(err => {
        console.error(err)
        props.refreshQuickComments();
        props.setModalOpen(false);
      })
  }

  const handleDeleteClick = () => {
    if (window.confirm(`Are you sure you want to delete that ${props.commentLevel}?`)) {
      store.server.postApi('../OnlineJudgingSetup/RemoveQuickCommentJson', { Id: props.selectedComment.Id })
        .then(res => {
          props.refreshQuickComments();
          props.setModalOpen(false);
        })
        .catch(err => console.error(err))
    }
  }



  return (
    <div>
      {builtForm[props.commentLevel]()}
      <div className='form-group'>
        <label htmlFor='sort-value'>Sort: </label>
        <input
          className='form-control'
          type='number'
          id='sort-value'
          value={sortVal}
          onChange={e => setSortVal((e.target.valueAsNumber))}
        />
      </div>
      <div>
        <button
          className='btn btn-primary'
          onClick={handleSaveClick}
        >
          <i className='fal fa-save' /> Save
        </button>
        {props.selectedComment &&
          <button
            className='btn btn-danger'
            onClick={handleDeleteClick}
          >
            <i className='fal fa-trash-alt' /> Delete
          </button>
        }
      </div>
    </div>
  )
}




export default AddOrEditQuickComment;