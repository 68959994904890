import {useState, useEffect, useRef} from 'react'
import StoreX, { store } from '../../redux/oldStore';
import { UiValuePairs } from '../../utils/CommonTypes/UserTypes';
import { Server, ServerResponse } from '../../utils/Server';
import { toast } from 'react-toastify';
import Modal from '../Modal/Modal';
import Icon, { IconType } from '../Icon/Icon';
import { CKEditor, CKEditorInstance } from 'ckeditor4-react';
import { FilePicker } from '../GenericForms/Editor';

interface IHtmlEditorProProps {
  value: string;
  changed: (content:string)=>void;
  id?: string;
  btnClass?:string;
  autoOpen?:boolean;
  onClose?:()=>void;
  show?:boolean;
}


interface IfilePickerResponse {
  files:UiValuePairs[];
}

export const HtmlEditorPro2 = (props: IHtmlEditorProProps) => {
  const [assets, setAssets] = useState<any>([])
  const [value, setValue] = useState<string>(props.value);
  const [showEditor, setShowEditor] = useState<boolean>();
  const [editorUrl, setEditorUrl] = useState<string>();
  const [sourceMode, setSourceMode] = useState<boolean>(false);

  useEffect(()=>{
    let url = StoreX.BuildUrl(`../assets/ckeditor/ckeditor.js`);
    setEditorUrl(url);
  },[])

  useEffect(()=>{
    if(props.autoOpen) setShowEditor(true);
  },[props.autoOpen]);

  useEffect(()=>{
    if(props.show) setShowEditor(true);
  },[props.show]);


  useEffect(()=>{
    if(showEditor)    Init();    
  },[showEditor]);

  useEffect(()=>{
 
  },[assets]);

  useEffect(()=>{
    if(props.value !== value){
      //value changed
      setValue(props.value);
    }
  },[props.value]);

  function Init() {

    store.server.getApi<ServerResponse<IfilePickerResponse>>(`../file/clientImages`)
      .then(x => {
        if (x.Success) {
          setAssets(x.Value.files.map(x => { return { type: 'image', src: x.Value.substring(0, x.Value.length - 9) }; }));
        }
        else {
          toast.warning(x.Message);
        }
      });

  }

  const cleanHtml = (str:string)=>{
    let html = str;
    let clean = html;
    if(!clean) {
      return clean;
      return '';
    };    
    let selfClosingTags = ['br','hr','img']
    let tag:string = '';
    let tags:string[] = [];
    let noTags:any[] = [];
    //console.log(html);
    for(let i = 0; i < html.length; i++){
        if(html[i] === '<'){
            tag = html.substr(i+1, html.slice(i+1).search(/([>\s\\/])/));
            tags.push(tag);
            let openTagEnd = html.slice(i+1).search(/[>]/);
            let selfClose = html.slice(i+1).search(/[\/][>]/);
            let closeTag = html.slice(i+1).indexOf(`</${tag}>`);
            if(selfClosingTags.find(t=>t===tag)){
              //console.log('Self closing tag found')
              if(selfClose !== -1){
                tags.pop();
                i = i+openTagEnd+1;
                continue;
              }
              
              i = i+openTagEnd+1;
              noTags.push({s:i,e:-1,self:true});
              continue;
              // tags.pop();
              // if(html.charAt(i+3))
            } else  
            if(selfClose > -1 && openTagEnd > selfClose){
                tags.pop();
                //console.log('Self Close', tag, i);
                tag = '';
                i = selfClose + i +2;
                continue;
            } else if (closeTag > -1){
                tags.pop();
                let tagLength = tag.length + 3;
                let len = closeTag + tagLength + 1;
                //console.log('tag: ' + html.substring(i, i+len));
                //console.log(html.substring(i));
                //console.log(html.substring(i, len));
                //console.log(tag, i, len);
                i = i + len -1;
                continue;
            }
            console.log('Error: tag', tag, openTagEnd, selfClose, closeTag);
            //console.log('Error: tags', tags);
            //console.log('Error: no ending tag');
        } else {
            let endLength = html.slice(i+1).indexOf('<');
            if(endLength === -1){
                console.log('Rest not tagged', i);
                let endIndex = html.length;
                noTags.push({s:i,e:endIndex});
                i = html.length - 1;
            } else {
                let tag = html.substr(i+1, html.slice(i+1).search(/([>\s\\/])/));
                
                let endIndex = i + endLength + 1;
                noTags.push({s:i,e:endIndex});
                console.log('No tag: ' + html.substring(i, endIndex));
                i = endIndex-1;
            }
        }
    }
    //console.log(clean);
    //console.log(noTags)
    let x = noTags.pop();
    while(x){
      if(x.self){
        let start = x.s;
        
        //console.log('Self', start);
        html = `${html.slice(0, start)}/${html.slice(start)}`;
      }else{
        html = `${html.slice(0, x.e)}</div>${html.slice(x.e)}`;
        html = `${html.slice(0, x.s)}<div>${html.slice(x.s)}`;
      }
      x = noTags.pop();
    }
    //console.log(html);

    return html;
  };


  const uploadAsset = (e:any) =>{
    if(!e.target?.files) return;

    let file = e.target?.files[0];

    store.server.postApi<ServerResponse<IfilePickerResponse>>(`../File/SaveClientFile`,{},[file])
    .then(x=>{
      if(x.Success){
        setAssets(x.Value.files.map(x=>{return {type:'image',src:x.Value.substring(0, x.Value.length - 9) }}));
      }
      else {
        toast.warning(x.Message);
      }
    });    
  };

  const save = (e:any)=>{
      //if(props.changed) props.changed(html);
      //console.log('save', e);
      let data = e.editor.getData();
      //console.log('Data', data);
      setValue(data);
  }

  const SaveIt=(keepOpen:boolean)=>{
      
      if(props.changed) props.changed(value);
      setShowEditor(keepOpen);
      if(!keepOpen) onClose();
  };

  const onClose = () =>{
    setShowEditor(false);
    if(props.onClose) props.onClose();
  };

  return (
    <>
    {showEditor ? <Modal setModalOpen={onClose} title={'Page Editor'} size='full' noClose={true}>
      <div className='grapes-js-container'>
        <div className='flex'>
          <div className='grow'>
        {true && <CKEditor 
        config={{height:'100%'}}
         name={props.id ?? 'editor-123'}
         initData={value ?? ''}
         //onLoaded={onload}
         editorUrl={editorUrl}
         onChange={save}
         onMode={(e)=>{setSourceMode(e.editor?.mode === "source");}}
        />}
      </div>
      <div>
        <FilePicker fileTypes={'all'} valueSelected={(x)=>{console.log('File Selectged', x)}} />
      </div>
      </div>
      </div>
     {!sourceMode && <div className='grapes-js-button-bar'>
        <button type='button' className='btn btn-secondary ' onClick={()=>{SaveIt(true)}}><Icon type={IconType.save} />Save</button>
        <button type='button' className='btn btn-default save-close-btn ' onClick={()=>{SaveIt(false)}}><Icon type={IconType.save} />Save & Close</button>
        <button type='button' className='btn btn-default ' onClick={onClose}><Icon type={IconType.close} />Close</button>
        </div>}
      </Modal>
    :
    <>
      <button type='button' className={`btn btn-secondary ${props.btnClass ?? ''}`} onClick={()=>{setShowEditor(true)}}><Icon type={IconType.edit}/> Edit Page</button>
    </>  
    }
    </>
  );
};

