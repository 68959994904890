import React, { useEffect, useState } from 'react'
import { store } from '../../redux/oldStore';
import DangerousInnerHtml from '../ParticipantVideo/DangerousInnerHtml';
import Notification from './Notification';
import './Notification.css';


type NotificationType = 'System' | 'Client' | 'Message'

export interface INotification {
  type: NotificationType;
  publicId: string;
  intId: number;
  subject: string;
  body: string;
  sentAtUtc: string;
  readAtUtc: string;
}

const NotificationHubContainer = (props: any) => {
  // need two api calls
  // first - Get All notifications
  // display all those notifications
  // on notification click, 
  // second - get notification/:id
  // getting this (can be quiet) will flag it as read for that user
  // maybe some bold styling for unread ones
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [selectedNotification, setSelectedNotification] = useState<INotification>();

  useEffect(() => {
    console.log("Init Message/list")
    store.server.getApi('../Message/List')
      .then((res: any) => {
        if(res.Success){
          console.log(res.Value);
          setNotifications(res.Value.messages)
          console.log("Got it")
        }
      })
  }, [])

  useEffect(() => {
    if (selectedNotification?.publicId && !selectedNotification.body) {
      store.server.getApiQuiet(`../Message/GetMessage/${selectedNotification.publicId}`)
        .then((res: any) => {
          console.log(res);
          setSelectedNotification(res.Value.message);
          let updatedArr = notifications.map( x => x.publicId === res.Value.message.publicId ? res.Value.message : x)
          setNotifications(updatedArr)
          //TODO: grab a method from the props to update this notification to have new Date(); for the readAtUtc for style reasons
        })
        .catch(err => {
          console.error(err);
        })
    }
  }, [selectedNotification])



  const mappedNotifications = notifications.map((notification) => {
    return (
      <div
        key={notification.publicId}
        onClick={() => setSelectedNotification(notification)}
        className={`view-message ${notification.readAtUtc ? '' : ' unread'} ${notification == selectedNotification ? ' selected' :''}`} // unread: like an inbox
      >
          {notification.readAtUtc ? 
                    <i className="fad fa-envelope-open-text"></i>
                    : <i className="fad fa-envelope text-primary"></i>
          }
        <div>
          <div> {notification.subject}</div>
          <div className="time-stamp">{notification.sentAtUtc} <span className="type">{notification.type}</span></div>
        </div>
        {/* Notification */}
        {/* <td className="inbox-small-cells"><i className="fa fa-star inbox-started"></i></td> */}
        {/* <td className="view-message dont-show"></td> */}
        {/* <td className="view-message">{notification.subject}</td> */}
        {/* <td className="view-message inbox-small-cells"><i className="fa fa-paperclip"></i></td> */}
        {/* <td className="view-message text-right">{notification.sentAtUtc}</td>  */}

    </div>
    )
  })

  return (
    <div className='bumper-l bumper-out-l'>
      <h2>Messages</h2>
      <div className='row'>

        <div className='col-sm-3 contained'>
              {notifications && notifications.length > 0 ? <>{mappedNotifications}</>
              :<div className="view-message">
          No Messages
          </div>
        }
        </div>

        <div className='col-sm-9'>
          {selectedNotification?.body 
            ?
            <div className="view-message-details">
              <h3>{selectedNotification.subject}</h3>
              <hr></hr>
              <DangerousInnerHtml customText={selectedNotification.body} />
            </div>
            
            : <div className="alert alert-default">
              <h3><i className="fad fa-envelope"></i> Select a Message</h3>
            </div>
          }
        </div>
      </div>


    </div>
  )
}



export default NotificationHubContainer