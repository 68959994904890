import { StringLiteralLike } from "typescript";
import { IListItem } from "../../Components/FloorPlanner/FloorPlannerTypes";
import { IEntryType } from "../../ObjectTypes/SetupTypes";
import { ToDate } from "../Tools";
import { Selectable } from "./CommonTypes";
import { IListItemAlt } from "../../Components/_Core/CoreTypes";

export interface FullGetPeopleValue {
  PeopleDataStillParsing:boolean;
  GrabRestId:string;
  Settings: IPeopleSettings;
  EntryTypes: IEntryType[];
  Steps: any;
  Females: number;
  Males: number;
  NumberOfProjects: number;
  NumberOfStudents: number;
  People: GetPeoplePerson[]; 
  PersonTags: Tag[]; 
  ProjectTags: Tag[]; 
  AutoTags: Tag[]; 
  Teams: number;
  Categories:IListItem[];
  SpeicalAwards: IListItem[];
  MarkReadyToJudge:boolean;
  MarkReady:boolean;
  ShowEntriesPage:boolean;
  Divisions:IListItem[];
  FlexFlows:IListItem[];
  Grades:IListItem[];
  Schools:IListItem[];
  FileTypes:IListItemAlt[];
  InPersonAsked:boolean;
}

export interface IPeopleSettings{
  CanRemove:boolean;
  LoginAs:boolean;
  ShowTags:boolean;
  HasRegistrationFee:boolean;
  AdvancedUserRoles:boolean;
  AskIfTeacherIsAttending:boolean;
  TeacherRegistrationFee:boolean;
  TrackEntryStatus:boolean;
}

export interface GetPeoplePerson {
  AccountCreatedAtUtc:string;
  IsPlaceHolder: boolean;
  CategoryId: number;
  JudgingReady: boolean;
  PaperworkReady: boolean;
  Adult: boolean;
  Affiliate: string | null;
  Category: string;
  CheckIn: string;
  CurrentTab:string;
  PrecentComplete:number;
  CreateAccountNextStep: string;
  CreateAccountPercentage: number;
  CreateContinueLink: string | null;
  Email: string;
  EmailVerified: boolean;
  FirstName: string;
  Gender: string | null;
  Grade: string | null;
  HasInvoiceWithRegistrationFee: boolean;
  HoldHarmlessAt: string | null;
  Id: string;
  IsefRegionalApprovalAt: string|null;
  IsefStateApprovalAt: string|null;
  IsExternalJudge: boolean;
  IsInterpreter: boolean;
  IsModerator: boolean;
  IsSpecialAwardJudge: boolean;
  IsTeacher: boolean;
  LastLoginAt: string | null;
  LastName: string;
  LastUpdate: string | null;
  MaxRole: string;
  MediaReleaseAllowed: string | null;
  MediaReleaseAt: string | null;
  Medical: boolean;
  Paid: boolean;
  ParentsEmail: string | null;
  PersonId: number;
  PersonTags: number[];
  Phone: string;
  ProjectId: string;
  ProjectIdInt: string | null;
  ProjectKey: string | null;
  ProjectTags: number[];
  AutoTags: number[];
  PromotedAt: string | null;
  School: string | null;
  Search: string;
  ShownPermissionsAt: string | null;
  TeacherEmail: string | null;
  TeacherIsAttending: string;
  TeacherName: string;
  TeacherPaid: boolean;
  Team: boolean;
  TeamLeader: boolean;
  TempPerson: boolean;
  Title: string;
  Username: string;
  NumberOnTeam:number;
  EntryTypeId:number;
  PrimaryLanguage:string;
  LastCheckedInAt:string;
  JudgePrefs:IJudgeDetail;
  InPerson:boolean;
  Division:number;
  FlowName?:string;
  FlowId?:number;
  EntryStatus?:string;
  EntryStatusSetAtUtc?:string;
}

export interface IJudgeDetail{
  id :number;
  fairId :number;
  category1 :boolean;
  category2 :boolean;
  category3 :boolean;
  notes :string;
  personId :number;
  availableAllDay :boolean;
  availableStartHour :string;
  availableUntilHour :string;
  checkedInAtFair :boolean;
  roamingJudgeWilling :boolean;
  judgeCaptainWilling :boolean;
  previousJudgingExperience :boolean;
  noConflictOfInterest :string;
  createdAt :string;
  VeteranJudge :boolean;
  JsonDetails :string;
  CategoricalJudge :boolean;
  ProfessionalSociety :number;
  ProfessionalSocietyJudge :boolean;
  GrandAwardJudge :boolean;
  GrandAwardJudgeType1 :boolean;
  GrandAwardJudgeType2 :boolean;
  SeasonsSummaries :IJudgeSeasonSum[];
}

export interface IJudgeSeasonSum{
  Id:number;
  PublicId:string;
  CreatedAtUtc:string;
  PersonId:number;
  FairId:number;
  Season:string;
  FirstName:string;
  LastName:string;
  JobTitle:string;
  Company:string;
  Email:string;
  Phone:string;
  Captain:boolean;
  AssessmentsCompleted:number;
  AverageScore:number;
  Team:string;
  SpecialAward:string;
  Comments:string;
  Card:string;
  NoShowed:boolean;
  YearsOfService:number;
  Ban:boolean;
  CategoryPref1Id:number;
  CategoryPref1Name:string;
  CategoryPref2Id:number;
  CategoryPref2Name:string;
  CategoryPref3Id:number;
  CategoryPref3Name:string;
  CategoriesJudgedIdsCsv:string;
  CategoriesJudgedNamesCsv:string;
  JsonDetails:string;
  
  SpecialAwardJudgePref :boolean;
  SpecialAwardJudge :boolean;
  CaptainPref :boolean;
  CategoryJudgePref :boolean;
  CategoryJudge :boolean;
  GrandAwardPref :boolean;
  RoamingWillingPref :boolean;
  PreviousExperience :boolean;
  SpecialAwardJudgeOrg :string;
}


export enum IFilterTypes {
  personTag, projectTag, autoTag, userType, entryType,
  Category,
  ready
}

export interface IFitlerDisplay{
  type:IFilterTypes 
  name:string;
  value:any;
  key:string;
  children:IFitlerDisplay[]|undefined|null;
}

export interface Tag {
  FairId: number; // 1
  Id: number; // 2
  Type: string; // "person"
  Value: string; // "Best Judge"
  Color?: string;
  SystemTag?: string;
}

export interface PasswordRequirements {
  MinLength: number;
  RequireAltCase: boolean;
  RequireNumber: boolean;
  RequireSpecialChar: boolean;
  specialChars: string;
}

export enum SecurityRole {
    Yoda = 100,
    Admin = 10,
    Staff = 7,
    DistrictCoordinator = 6,
    Judge = 5,
    Volunteer = 3,
    Teacher = 1,
    Student = 0,
    Attendee = -100,
    Unknown = -1000
}

export enum FilterComparison { and, or, greaterThan, lessThan, equal, notEqual, contains, notContains, before, after}

export interface IFilterGroup{
  Filters: IFilter[];
  ComparisonType:FilterComparison;  
}

export interface IFilter{
  Properties:IFilterProperty[];
  ComparisonType:FilterComparison;  
  Value?:string|number;
  ValueOption?:Selectable;
  ParentComparisonType:'or'|'and';
}

export interface IFilterProperty{
  Key:string;
  Parent?:IFilterProperty;
}

export const getComparisonFields = (type?:dataType, data?:FullGetPeopleValue, value?:string) => {
  let fields:Selectable[] = [];

  let options = value && data ? getSearchableFieldDataOptions(value, data) : undefined;

  if (options) {
    fields.push({value:'equal', label:'equal'});
    fields.push({value:'notEqual', label:'not equal'});
  }
  else if (type === dataType.bool){
      // fields.push({value:'true', label:'true'});
      // fields.push({value:'false', label:'false'});
      fields.push({value:'equal', label:'equal'});
      fields.push({value:'notEqual', label:'not equal'});
  } else if (type === dataType.date) {
    fields.push({value:'before', label:'before'});
    fields.push({value:'after', label:'after'});
  }else if (type === dataType.number) {
    fields.push({value:'equal', label:'equal'});
    fields.push({value:'greaterThan', label:'greater Than'});
    fields.push({value:'lessThan', label:'less Than'});
  }
  else {

  fields.push({value:'contains', label:'contains'});
  fields.push({value:'notContains', label:'not Contains'});
  fields.push({value:'equal', label:'equal'});
  }
  // fields.push({value:'greaterThan', label:'greater Than'});
  // fields.push({value:'lessThan', label:'less Than'});
  // fields.push({value:'before', label:'before'});
  // fields.push({value:'after', label:'after'});

  return fields;
}

export const getAndOrFields = () => {
  let fields:Selectable[] = [];

  fields.push({value:'and', label:'and'});
  fields.push({value:'or', label:'or'});

  return fields;
}


export const getSearchableFields = () => {
  let fields:Selectable[] = [];

  fields.push({label:'Category', value:'m-CategoryId'});
  fields.push({label:'Judging Ready', value:'m-JudgingReady'});
  fields.push({label:'Paperwork Ready', value:'m-PaperworkReady'});
  fields.push({label:'Adult', value:'m-Adult'});
  fields.push({label:'Affiliate', value:'m-Affiliate'});
  //fields.push({label:'Category', value:'m-Category'});
  fields.push({label:'CheckIn', value:'m-CheckIn'});
  fields.push({label:'Email', value:'m-Email'});
  fields.push({label:'First Name', value:'m-FirstName'});
  fields.push({label:'Gender', value:'m-Gender'});
  fields.push({label:'Grade', value:'m-Grade'});
  fields.push({label:'Division', value:'m-Division'});
  fields.push({label:'Flex Flow', value:'m-FlexFlow'})
  fields.push({label:'In Person / Virtual', value:'m-InPerson'});

  fields.push({label:'Has Invoice With Registration Fee', value:'m-HasInvoiceWithRegistrationFee'});
  fields.push({label:'Id (Person Public Id)', value:'m-Id'});
  fields.push({label:'Isef Regional Approval At', value:'m-IsefRegionalApprovalAt'});
  fields.push({label:'Isef State Approval At', value:'m-IsefStateApprovalAt'});
  fields.push({label:'Is ExternalJudge', value:'m-IsExternalJudge'});
  fields.push({label:'Is Interpreter', value:'m-IsInterpreter'});
  fields.push({label:'Is Moderator', value:'m-IsModerator'});
  fields.push({label:'Is Special Award Judge', value:'m-IsSpecialAwardJudge'});
  fields.push({label:'Is Teacher', value:'m-IsTeacher'});
  fields.push({label:'Last Login At', value:'m-LastLoginAt'});
  fields.push({label:'Account Created At', value:'m-AccountCreatedAtUtc'});
  fields.push({label:'Last Name', value:'m-LastName'});
  fields.push({label:'Last Update', value:'m-LastUpdate'});
  fields.push({label:'Max Role', value:'m-MaxRole'});
  //fields.push({label:'Media Release Allowed', value:'m-MediaReleaseAllowed'});
  //fields.push({label:'Media Release At', value:'m-MediaReleaseAt'});
  fields.push({label:'Paid', value:'m-Paid'});
  fields.push({label:'Parents Email', value:'m-ParentsEmail'});
  fields.push({label:'Person Id', value:'m-PersonId'});
  fields.push({label:'Person Tags', value:'m-PersonTags'});
  fields.push({label:'Phone', value:'m-Phone'});
  fields.push({label:'Project Id', value:'m-ProjectId'});
  fields.push({label:'Project Key', value:'m-ProjectKey'});
  fields.push({label:'Project Tags', value:'m-ProjectTags'});
  fields.push({label:'Promoted At', value:'m-PromotedAt'});
  fields.push({label:'School', value:'m-School'});
  fields.push({label:'Teacher Email', value:'m-TeacherEmail'});
  fields.push({label:'Teacher Is Attending', value:'m-TeacherIsAttending'});
  fields.push({label:'Teacher Name', value:'m-TeacherName'});
  fields.push({label:'Teacher Paid', value:'m-TeacherPaid'});
  fields.push({label:'Team', value:'m-Team'});
  fields.push({label:'Team Leader', value:'m-TeamLeader'});
  fields.push({label:'Temp Person', value:'m-TempPerson'});
  fields.push({label:'Title', value:'m-Title'});
  fields.push({label:'Username', value:'m-Username'});
  fields.push({label:'Number On Team', value:'m-NumberOnTeam'});
  //fields.push({label:'Entry Type', value:'m-EntryTypeId'});
  fields.push({label:'Primary Language', value:'m-PrimaryLanguage'});
  //fields.push({label:'Last Checked In At', value:'m-LastCheckedInAt'});


  fields.push({label:'Judge Pref: category 1' , value:'jp-category1'});
  fields.push({label:'Judge Pref: category 2' , value:'jp-category2'});
  fields.push({label:'Judge Pref: category 3' , value:'jp-category3'});
  fields.push({label:'Judge Pref: person Id' , value:'jp-personId'});
  fields.push({label:'Judge Pref: available All Day' , value:'jp-availableAllDay'});
  fields.push({label:'Judge Pref: available Start Hour' , value:'jp-availableStartHour'});
  fields.push({label:'Judge Pref: available Until Hour' , value:'jp-availableUntilHour'});
  //fields.push({label:'Judge Pref: checked In At Fair' , value:'jp-checkedInAtFair'});
  fields.push({label:'Judge Pref: roaming Judge Willing' , value:'jp-roamingJudgeWilling'});
  fields.push({label:'Judge Pref: judge Captain Willing' , value:'jp-judgeCaptainWilling'});
  fields.push({label:'Judge Pref: previous Judging Experience' , value:'jp-previousJudgingExperience'});
  fields.push({label:'Judge Pref: no Conflict Of Interest' , value:'jp-noConflictOfInterest'});
  fields.push({label:'Judge Pref: Veteran Judge' , value:'jp-VeteranJudge'});
  fields.push({label:'Judge Pref: Categorical Judge' , value:'jp-CategoricalJudge'});
  fields.push({label:'Judge Pref: Professional Society' , value:'jp-ProfessionalSociety'});
  fields.push({label:'Judge Pref: Professional Society Judge' , value:'jp-ProfessionalSocietyJudge'});
  fields.push({label:'Judge Pref: Grand Award Judge' , value:'jp-GrandAwardJudge'});
  //fields.push({label:'Judge Pref: GrandAwardJudgeType1' , value:'jp-GrandAwardJudgeType1'});
  //fields.push({label:'Judge Pref: GrandAwardJudgeType2' , value:'jp-GrandAwardJudgeType2'});

  fields.push({label:'Judge Season: Season', value:'jss-Season'});
  fields.push({label:'Judge Season: First Name', value:'jss-FirstName'});
  fields.push({label:'Judge Season: Last Name', value:'jss-LastName'});
  fields.push({label:'Judge Season: Job Title', value:'jss-JobTitle'});
  fields.push({label:'Judge Season: Company', value:'jss-Company'});
  fields.push({label:'Judge Season: Email', value:'jss-Email'});
  fields.push({label:'Judge Season: Phone', value:'jss-Phone'});
  fields.push({label:'Judge Season: Captain', value:'jss-Captain'});
  fields.push({label:'Judge Season: Assessments Completed', value:'jss-AssessmentsCompleted'});
  fields.push({label:'Judge Season: Average Score', value:'jss-AverageScore'});
  fields.push({label:'Judge Season: Team', value:'jss-Team'});
  fields.push({label:'Judge Season: Comments', value:'jss-Comments'});
  fields.push({label:'Judge Season: Card', value:'jss-Card'});
  fields.push({label:'Judge Season: No Showed', value:'jss-NoShowed'});
  fields.push({label:'Judge Season: Years Of Service', value:'jss-YearsOfService'});
  fields.push({label:'Judge Season: Ban', value:'jss-Ban'});
  //fields.push({label:'Judge Season: CategoryPref1Id', value:'jss-CategoryPref1Id'});
  fields.push({label:'Judge Season: Category 1', value:'jss-CategoryPref1Name'});
  //fields.push({label:'Judge Season: CategoryPref2Id', value:'jss-CategoryPref2Id'});
  fields.push({label:'Judge Season: Category 2', value:'jss-CategoryPref2Name'});
  //fields.push({label:'Judge Season: CategoryPref3Id', value:'jss-CategoryPref3Id'});
  fields.push({label:'Judge Season: Category 3', value:'jss-CategoryPref3Name'});
  //fields.push({label:'Judge Season: CategoriesJudgedIdsCsv', value:'jss-CategoriesJudgedIdsCsv'});
  //fields.push({label:'Judge Season: CategoriesJudgedNamesCsv', value:'jss-CategoriesJudgedNamesCsv'});
  fields.push({label:'Judge Season: Special Award Judge Pref' , value:'jss-SpecialAwardJudgePref'});
  fields.push({label:'Judge Season: Special Award Judge' , value:'jss-SpecialAwardJudge'});
  fields.push({label:'Judge Season: Captain Pref' , value:'jss-CaptainPref'});
  fields.push({label:'Judge Season: Category Judge Pref' , value:'jss-CategoryJudgePref'});
  fields.push({label:'Judge Season: Category Judge' , value:'jss-CategoryJudge'});
  fields.push({label:'Judge Season: Grand Award Pref' , value:'jss-GrandAwardPref'});
  fields.push({label:'Judge Season: Roaming Willing Pref' , value:'jss-RoamingWillingPref'});
  fields.push({label:'Judge Season: Previous Experience' , value:'jss-PreviousExperience'});
  fields.push({label:'Judge Season: Special Award Judge' , value:'jss-SpecialAwardJudgeOrg'});

  return fields;
}

export enum dataType {string, date, bool, number};

export const getSearchableFieldName = (value:string) => {
  
  switch(value) {

  case 'm-CategoryId' : return "Category";
  case 'm-JudgingReady' : return "Judging Ready";
  case 'm-PaperworkReady' : return "Paperwork Ready";
  case 'm-Adult' : return "Adult";
  case 'm-Affiliate' : return "Affiliate";
  case 'm-Category' : return "Category";
  case 'm-CheckIn' : return "CheckIn";
  case 'm-Email' : return "Email";
  case 'm-FirstName' : return "First Name";
  case 'm-Gender' : return "Gender";
  case 'm-Grade' : return "Grade";
  case 'm-Division' : return "Division";
  case 'm-FlexFlow' : return "Flex Flow";
  case 'm-InPerson' : return "In Person / Virtual";
  case 'm-HasInvoiceWithRegistrationFee' : return "Has Invoice With Registration Fee";
  case 'm-Id' : return "Id";
  case 'm-IsefRegionalApprovalAt' : return "Isef Regional Approval At";
  case 'm-IsefStateApprovalAt' : return "Isef State Approval At";
  case 'm-IsExternalJudge' : return "Is External Judge";
  case 'm-IsInterpreter' : return "Is Interpreter";
  case 'm-IsModerator' : return "Is Moderator";
  case 'm-IsSpecialAwardJudge' : return "Is Special Award Judge";
  case 'm-IsTeacher' : return "Is Teacher";
  case 'm-LastLoginAt' : return "Last Login At";
  case 'm-LastName' : return "Last Name";
  case 'm-LastUpdate' : return "Last Update";
  case 'm-MaxRole' : return "Max Role";
  case 'm-MediaReleaseAllowed' : return "Media Release Allowed";
  case 'm-MediaReleaseAt' : return "Media Release At";
  case 'm-Paid' : return "Paid";
  case 'm-ParentsEmail' : return "Parents Email";
  case 'm-PersonId' : return "Person Id";
  case 'm-PersonTags' : return "Person Tags";
  case 'm-Phone' : return "Phone";
  case 'm-ProjectId' : return "Project Id";
  case 'm-ProjectKey' : return "Project Key";
  case 'm-ProjectTags' : return "Project Tags";
  case 'm-PromotedAt' : return "Promoted At";
  case 'm-School' : return "School";
  case 'm-TeacherEmail' : return "Teacher Email";
  case 'm-TeacherIsAttending' : return "Teacher Is Attending";
  case 'm-TeacherName' : return "Teacher Name";
  case 'm-TeacherPaid' : return "Teacher Paid";
  case 'm-Team' : return "Team";
  case 'm-TeamLeader' : return "Team Leader";
  case 'm-TempPerson' : return "Temp Person";
  case 'm-Title' : return "Title";
  case 'm-Username' : return "Username";
  case 'm-NumberOnTeam' : return "Number On Team";
  case 'm-EntryTypeId' : return "Entry Type Id";
  case 'm-PrimaryLanguage' : return "Primary Language";
  case 'm-LastCheckedInAt' : return "Last Checked In At";
  case 'm-AccountCreatedAtUtc' : return "Account Created At";
  case 'jp-category1' : return "Judge Pref: category 1";
  case 'jp-category2' : return "Judge Pref: category 2";
  case 'jp-category3' : return "Judge Pref: category 3";
  case 'jp-personId' : return "Judge Pref: person Id";
  case 'jp-availableAllDay' : return "Judge Pref: available All Day";
  case 'jp-availableStartHour' : return "Judge Pref: available Start Hour";
  case 'jp-availableUntilHour' : return "Judge Pref: available Until Hour";
  case 'jp-checkedInAtFair' : return "Judge Pref: checked In At Fair";
  case 'jp-roamingJudgeWilling' : return "Judge Pref: roaming Judge Willing";
  case 'jp-judgeCaptainWilling' : return "Judge Pref: judge Captain Willing";
  case 'jp-previousJudgingExperience' : return "Judge Pref: previous Judging Experience";
  case 'jp-noConflictOfInterest' : return "Judge Pref: no Conflict Of Interest";
  case 'jp-VeteranJudge' : return "Judge Pref: Veteran Judge";
  case 'jp-CategoricalJudge' : return "Judge Pref: Categorical Judge";
  case 'jp-ProfessionalSociety' : return "Judge Pref: Professional Society";
  case 'jp-ProfessionalSocietyJudge' : return "Judge Pref: Professional Society Judge";
  case 'jp-GrandAwardJudge' : return "Judge Pref: Grand Award Judge";
  case 'jp-GrandAwardJudgeType1' : return "Judge Pref: Grand Award Judge Type 1";
  case 'jp-GrandAwardJudgeType2' : return "Judge Pref: Grand Award Judge Type 2";
  case 'jss-Season' : return "Season: Season";
  case 'jss-FirstName' : return "Season: First Name";
  case 'jss-LastName' : return "Season: Last Name";
  case 'jss-JobTitle' : return "Season: Job Title";
  case 'jss-Company' : return "Season: Company";
  case 'jss-Email' : return "Season: Email";
  case 'jss-Phone' : return "Season: Phone";
  case 'jss-Captain' : return "Season: Captain";
  case 'jss-AssessmentsCompleted' : return "Season: Assessments Completed";
  case 'jss-AverageScore' : return "Season: Average Score";
  case 'jss-Team' : return "Season: Team";
  case 'jss-Comments' : return "Season: Comments";
  case 'jss-Card' : return "Season: Card";
  case 'jss-NoShowed' : return "Season: No Showed";
  case 'jss-YearsOfService' : return "Season: Years Of Service";
  case 'jss-Ban' : return "Season: Ban";
  case 'jss-CategoryPref1Id' : return "Season: Category Pref";
  case 'jss-CategoryPref1Name' : return "Season: Category Pref";
  case 'jss-CategoryPref2Id' : return "Season: CategoryPref2Id";
  case 'jss-CategoryPref2Name' : return "Season: Category Pref 2";
  case 'jss-CategoryPref3Id' : return "Season: CategoryPref3Id";
  case 'jss-CategoryPref3Name' : return "Season: Category Pref 3";
  case 'jss-CategoriesJudgedIdsCsv' : return "Season: CategoriesJudgedIdsCsv";
  case 'jss-CategoriesJudgedNamesCsv' : return "Season: CategoriesJudgedNamesCsv";
  case 'jss-SpecialAwardJudgePref' : return "Season: Special Award Judge Pref";
  case 'jss-SpecialAwardJudge' : return "Season: Special Award Judge";
  case 'jss-CaptainPref' : return "Season: Captain Pref";
  case 'jss-CategoryJudgePref' : return "Season: Category Judge Pref";
  case 'jss-CategoryJudge' : return "Season: Category Judge";
  case 'jss-GrandAwardPref' : return "Season: Grand Award Pref";
  case 'jss-RoamingWillingPref' : return "Season: Roaming Willing Pref";
  case 'jss-PreviousExperience' : return "Season: Previous Experience";
  case 'jss-SpecialAwardJudgeOrg' : return "Season: Special Award Judge Org";
}

  return value;
}

export const getSearchableFieldDataType = (value:string) => {
  
  switch(value) {

  case 'm-CategoryId' : return dataType.number;
  case 'm-JudgingReady' : return dataType.bool;
  case 'm-PaperworkReady' : return dataType.bool;
  case 'm-Adult' : return dataType.bool;
  case 'm-Affiliate' : return dataType.string;
  case 'm-Category' : return dataType.string;
  case 'm-CheckIn' : return dataType.string;
  case 'm-Email' : return dataType.string;
  case 'm-FirstName' : return dataType.string;
  case 'm-Gender' : return dataType.string;
  case 'm-Grade' : return dataType.string;
  case 'm-Division' : return dataType.number;
  case 'm-FlexFlow' : return dataType.number;
  case 'm-InPerson' : return dataType.bool;
  case 'm-HasInvoiceWithRegistrationFee' : return dataType.bool;
  case 'm-Id' : return dataType.number;
  case 'm-IsefRegionalApprovalAt' : return dataType.date;
  case 'm-IsefStateApprovalAt' : return dataType.date;
  case 'm-IsExternalJudge' : return dataType.bool;
  case 'm-IsInterpreter' : return dataType.bool;
  case 'm-IsModerator' : return dataType.bool;
  case 'm-IsSpecialAwardJudge' : return dataType.bool;
  case 'm-IsTeacher' : return dataType.bool;
  case 'm-LastLoginAt' : return dataType.date;
  case 'm-LastName' : return dataType.string;
  case 'm-LastUpdate' : return dataType.date;
  case 'm-MaxRole' : return dataType.string;
  case 'm-MediaReleaseAllowed' : return dataType.bool;
  case 'm-MediaReleaseAt' : return dataType.date;
  case 'm-Paid' : return dataType.bool;
  case 'm-ParentsEmail' : return dataType.string;
  case 'm-PersonId' : return dataType.string;
  case 'm-PersonTags' : return dataType.string;
  case 'm-Phone' : return dataType.string;
  case 'm-ProjectId' : return dataType.string;
  case 'm-ProjectKey' : return dataType.string;
  case 'm-ProjectTags' : return dataType.string;
  case 'm-PromotedAt' : return dataType.date;
  case 'm-School' : return dataType.string;
  case 'm-TeacherEmail' : return dataType.string;
  case 'm-TeacherIsAttending' : return dataType.bool;
  case 'm-TeacherName' : return dataType.string;
  case 'm-TeacherPaid' : return dataType.bool;
  case 'm-Team' : return dataType.string;
  case 'm-TeamLeader' : return dataType.bool;
  case 'm-TempPerson' : return dataType.bool;
  case 'm-Title' : return dataType.string;
  case 'm-Username' : return dataType.string;
  case 'm-NumberOnTeam' : return dataType.number;
  case 'm-EntryTypeId' : return dataType.number;
  case 'm-PrimaryLanguage' : return dataType.string;
  case 'm-LastCheckedInAt' : return dataType.date;
  case 'm-AccountCreatedAtUtc' : return dataType.date;
  case 'jp-category1' : return dataType.number;
  case 'jp-category2' : return dataType.number;
  case 'jp-category3' : return dataType.number;
  case 'jp-personId' : return dataType.number;
  case 'jp-availableAllDay' : return dataType.bool;
  case 'jp-availableStartHour' : return dataType.number;
  case 'jp-availableUntilHour' : return dataType.number;
  case 'jp-checkedInAtFair' : return dataType.date;
  case 'jp-roamingJudgeWilling' : return dataType.bool;
  case 'jp-judgeCaptainWilling' : return dataType.bool;
  case 'jp-previousJudgingExperience' : return dataType.bool;
  case 'jp-noConflictOfInterest' : return dataType.bool;
  case 'jp-VeteranJudge' : return dataType.bool;
  case 'jp-CategoricalJudge' : return dataType.bool;
  case 'jp-ProfessionalSociety' : return dataType.number;
  case 'jp-ProfessionalSocietyJudge' : return dataType.bool;
  case 'jp-GrandAwardJudge' : return dataType.bool;
  case 'jp-GrandAwardJudgeType1' : return dataType.number;
  case 'jp-GrandAwardJudgeType2' : return dataType.number;
  case 'jss-Season' : return dataType.string;
  case 'jss-FirstName' : return dataType.string;
  case 'jss-LastName' : return dataType.string;
  case 'jss-JobTitle' : return dataType.string;
  case 'jss-Company' : return dataType.string;
  case 'jss-Email' : return dataType.string;
  case 'jss-Phone' : return dataType.string;
  case 'jss-Captain' : return dataType.bool;
  case 'jss-AssessmentsCompleted' : return dataType.number;
  case 'jss-AverageScore' : return dataType.number;
  case 'jss-Team' : return dataType.string;
  case 'jss-Comments' : return dataType.string;
  case 'jss-Card' : return dataType.string;
  case 'jss-NoShowed' : return dataType.bool;
  case 'jss-YearsOfService' : return dataType.number;
  case 'jss-Ban' : return dataType.bool;
  case 'jss-CategoryPref1Id' : return dataType.number;
  case 'jss-CategoryPref1Name' : return dataType.string;
  case 'jss-CategoryPref2Id' : return dataType.number;
  case 'jss-CategoryPref2Name' : return dataType.string;
  case 'jss-CategoryPref3Id' : return dataType.number;
  case 'jss-CategoryPref3Name' : return dataType.string;
  case 'jss-CategoriesJudgedIdsCsv' : return dataType.string;
  case 'jss-CategoriesJudgedNamesCsv' : return dataType.string;
  case 'jss-SpecialAwardJudgePref' : return dataType.bool;
  case 'jss-SpecialAwardJudge' : return dataType.string;
  case 'jss-CaptainPref' : return dataType.bool;
  case 'jss-CategoryJudgePref' : return dataType.bool;
  case 'jss-CategoryJudge' : return dataType.bool;
  case 'jss-GrandAwardPref' : return dataType.bool;
  case 'jss-RoamingWillingPref' : return dataType.bool;
  case 'jss-PreviousExperience' : return dataType.bool;
  case 'jss-SpecialAwardJudgeOrg' : return dataType.bool;
}

  return dataType.string;
}

export const getSearchableFieldDataOptions = (value:string, data:FullGetPeopleValue) => {
  
  let options:Selectable[] = [];

  let type = getSearchableFieldDataType(value);
  if(type === dataType.bool){
    
    options.push({ value:`true`, label:'True'});
    options.push({ value:`false`, label:'False'});
    return options;
  }

  switch(value) {
  case 'm-Division'  :
    data.Divisions?.forEach(x=>options.push({value:x.Id, label:x.Name}));
    return options;
    break;
    case 'm-FlexFlow'  :
      data.FlexFlows?.forEach(x=>options.push({value:x.Id, label:x.Name}));
      return options;
      break;

    case 'm-Grade'  :
      data.Grades?.forEach(x=>options.push({value:x.Id, label:x.Name}));
      return options;
      break;
  case 'm-InPerson':
    options.push({value:1, label:'In Person'});
    options.push({value:0, label:'Virtual'});
    return options;
    break;
  case 'm-CategoryId' : 
  case 'm-Category' : 
  case 'jp-category1' : 
  case 'jp-category2' : 
  case 'jp-category3' :  
  case 'jss-CategoryPref1Id' : 
  case 'jss-CategoryPref2Id' : 
  case 'jss-CategoryPref3Id' : 
      //set options here....
      data.Categories?.forEach(x=>options.push({value:x.Id, label:x.Name}));
      return options; 
    break;   
    case 'm-EntryTypeId' : 
    //set options here....
    data.EntryTypes?.forEach(x=>options.push({value:x.Id, label:x.Name ?? 'Entry Type'}));
    return options; 
  break;   
  case 'm-PersonTags' : 
      //set options here....
    data.PersonTags?.forEach(x=>options.push({value:x.Id, label:x.Value}));
      return options; 
    break;
  case 'm-ProjectTags' : 
      //set options here....
    data.ProjectTags?.forEach(x=>options.push({value:x.Id, label:x.Value}));
      return options; 
    break;   
  case 'm-MaxRole' : 
      //set options here....
      // options.push({ value:`Student`, label:'Participant'});
      // options.push({ value:`Temp`, label:'Temp'});
      options.push({ value:`Adult`, label:'Adult'});
      options.push({ value:`Teacher`, label:'Teach'});
      options.push({ value:`Judge`, label:'Judge'});
      options.push({ value:`Staff`, label:'Staff'});
      options.push({ value:`Volunteer`, label:'Volunteer'});
      return options; 
    break;
  
    
  case 'm-Gender' : 
  //set options here....
  options.push({ value:`F`, label:'Female'});
  options.push({ value:`M`, label:'Male'});
  options.push({ value:`N`, label:'Non-binary'});
  options.push({ value:`O`, label:'Other'});
  options.push({ value:`Z`, label:'Prefer not to answer'});
  return options; 
break;


case 'jp-ProfessionalSociety' :
  //update teh call to retrun the spcial awards so we can map thiss
    data.SpeicalAwards?.forEach(x=>options.push({value:x.Id, label:x.Name}));
    return options; 
  break;

  
  case 'm-Grade' : 
  case 'jp-ProfessionalSociety' :
  case 'jss-CategoryPref1Name' : 
  case 'jss-CategoryPref2Name' : 
  case 'jss-CategoryPref3Name' : 
  case 'jss-SpecialAwardJudgeOrg' : 
  return undefined;
  break;
}

  return undefined;
}

interface iIndexGroup {
  index:number;
  indexes:number[];
}

export const searchPerson = (filterGroup:IFilterGroup, persons:GetPeoplePerson[])=>{
  
  let indexes:iIndexGroup[] = [];

  let myIndex = -1;
  for(let i = 0; i < filterGroup.Filters.length; i++)
  {
    let x = filterGroup.Filters[i];
    if(x.ParentComparisonType === 'or' || i === 0){
      myIndex++;
      indexes[myIndex] = {index:i, indexes:[]};
    }

    indexes[myIndex].indexes.push(i);
  }

  let results:GetPeoplePerson[] = [];

  persons.forEach((person, pi)=>{
    for(let gi = 0; gi < indexes.length; gi++){
      let pass = true;
      let toCheck = indexes[gi];

      toCheck.indexes.forEach(i=>{
        let x = filterGroup.Filters[i];
        let value = getValueFromPerson(x.Properties, person).map(x=>(x+'').toUpperCase());
        let isArray = value?.length > 1;
        let hash = {};
        if(isArray) value.forEach(v=>{hash[v.toUpperCase()] = true;});
        
        switch(x.ComparisonType){
          case FilterComparison.and: 
            break;
          case FilterComparison.or: 
            break;
          case FilterComparison.greaterThan: 
            if(!value.find(f=> +f > +(x.Value ?? 0))) pass = false; 
            break;
          case FilterComparison.lessThan: 
          if(!value.find(f=> +f < +(x.Value ?? 0))) pass = false; 
            break;
          case FilterComparison.equal: 
            if(isArray && !x.ValueOption){
              if(!hash[x.Value+'']) pass = false;
            }
            else if(x.ValueOption){
              if(!value.find(f=> f === (x.ValueOption?.value+'').toUpperCase())) pass = false;
            }
            else if(!value.find(f=> f === (x.Value+'').toUpperCase())) pass = false; 
            break;
            
          case FilterComparison.notEqual: 
            if(x.ValueOption){
              if(value.find(f=> f+'' === (x.ValueOption?.value+'').toUpperCase())) pass = false;
            }
            else if(value.find(f=> f+'' === (x.Value+'').toUpperCase())) pass = false; 
            console.log(pass)
            break;
          case FilterComparison.contains: 
              if(x.ValueOption){
                if(!value.find(f=> (f).indexOf((x.ValueOption?.value+'').toUpperCase()) > -1)) pass = false;
              }
              else if(!value.find(f=> (f).indexOf((x.Value+'').toUpperCase()) > -1)) pass = false; 
              break;
          case FilterComparison.notContains: 
              if(x.ValueOption){
                if(value.find(f=> (f).indexOf((x.ValueOption?.value+'').toUpperCase()) > -1)) pass = false;
              }
              else if(value.find(f=> (f).indexOf((x.Value+'').toUpperCase()) > -1)) pass = false; 
            break;
          case FilterComparison.before: 
              if(x.Value && value[0] !== 'NULL'){
                let d = ToDate(x.Value ?? '');          
                if(d && !value.find(f=> f && ToDate(f) < d )) pass = false; 
              } else {pass = false;}
            break;
          case FilterComparison.after: 
              if(x.Value && value[0] !== 'NULL'){
                let da = ToDate(x.Value ?? '');          
                let userValue = ToDate(value[0]);
                if(userValue > da){
                  
                } else {
                  pass = false;
                }
                
              } else {pass = false;}
            break;
        }
      });

      if(pass) {
        //it passed
        results.push(person);
        break;
      }
    }
  });
  return results;
}
const getValueFromPerson = (properties: IFilterProperty[], person: GetPeoplePerson) =>{
  let values:string[] = [];

  properties.forEach(x=>{
    getPropertyValueFromPerson(person, x, values);
  })

  return values;
}

const getPropertyValueFromPerson = (person: GetPeoplePerson, property: IFilterProperty, values:string[]) =>{
  let value:any = undefined;
  let judgePrefs = person.JudgePrefs ?? {};
  let summaries = person.JudgePrefs?.SeasonsSummaries ?? [];
  let isArray = false;

  switch(property.Key){
  case 'm-CategoryId': value = person['CategoryId']; break;
  case 'm-JudgingReady': value = person['JudgingReady']; break;
  case 'm-PaperworkReady': value = person['PaperworkReady']; break;
  case 'm-Adult': value = person['Adult']; break;
  case 'm-Affiliate': value = person['Affiliate']; break;
  case 'm-Category': value = person['Category']; break;
  case 'm-CheckIn': value = person['CheckIn']; break;
  case 'm-Email': value = person['Email']; break;
  case 'm-FirstName': value = person['FirstName']; break;
  case 'm-Gender': value = person['Gender']; break;
  case 'm-Grade': value = person['Grade']; break;
  case 'm-InPerson': value = (person.MaxRole === 'Student' && person['InPerson']) ? 1 : 0; break;
  case 'm-Division': value = person['Division']; break;
  case 'm-FlexFlow': value = person['FlowId']; break;
  case 'm-HasInvoiceWithRegistrationFee': value = person['HasInvoiceWithRegistrationFee']; break;
  case 'm-Id': value = person['Id']; break;
  case 'm-IsefRegionalApprovalAt': value = person['IsefRegionalApprovalAt']; break;
  case 'm-IsefStateApprovalAt': value = person['IsefStateApprovalAt']; break;
  case 'm-IsExternalJudge': value = person['IsExternalJudge']; break;
  case 'm-IsInterpreter': value = person['IsInterpreter']; break;
  case 'm-IsModerator': value = person['IsModerator']; break;
  case 'm-IsSpecialAwardJudge': value = person['IsSpecialAwardJudge']; break;
  case 'm-IsTeacher': value = person['IsTeacher']; break;
  case 'm-LastLoginAt': value = person['LastLoginAt']; break;
  case 'm-LastName': value = person['LastName']; break;
  case 'm-LastUpdate': value = person['LastUpdate']; break;
  case 'm-MaxRole': value = person['MaxRole']; break;
  case 'm-MediaReleaseAllowed': value = person['MediaReleaseAllowed']; break;
  case 'm-MediaReleaseAt': value = person['MediaReleaseAt']; break;
  case 'm-Paid': value = person['Paid']; break;
  case 'm-ParentsEmail': value = person['ParentsEmail']; break;
  case 'm-PersonId': value = person['PersonId']; break;
  case 'm-PersonTags': value = person['PersonTags']; isArray = true; break;
  case 'm-Phone': value = person['Phone']; break;
  case 'm-ProjectId': value = person['ProjectId']; break;
  case 'm-ProjectKey': value = person['ProjectKey']; break;
  case 'm-ProjectTags': value = person['ProjectTags']; isArray=true;break;
  case 'm-PromotedAt': value = person['PromotedAt']; break;
  case 'm-School': value = person['School']; break;
  case 'm-TeacherEmail': value = person['TeacherEmail']; break;
  case 'm-TeacherIsAttending': value = person['TeacherIsAttending']; break;
  case 'm-TeacherName': value = person['TeacherName']; break;
  case 'm-TeacherPaid': value = person['TeacherPaid']; break;
  case 'm-Team': value = person['Team']; break;
  case 'm-TeamLeader': value = person['TeamLeader']; break;
  case 'm-TempPerson': value = person['TempPerson']; break;
  case 'm-Title': value = person['Title']; break;
  case 'm-Username': value = person['Username']; break;
  case 'm-NumberOnTeam': value = person['NumberOnTeam']; break;
  case 'm-EntryTypeId': value = person['EntryTypeId']; break;
  case 'm-PrimaryLanguage': value = person['PrimaryLanguage']; break;
  case 'm-LastCheckedInAt': value = person['LastCheckedInAt']; break;
  case 'm-AccountCreatedAtUtc': value = person['AccountCreatedAtUtc']; break;
  case 'jp-category1': value = judgePrefs['category1']; break;
  case 'jp-category2': value = judgePrefs['category2']; break;
  case 'jp-category3': value = judgePrefs['category3']; break;
  case 'jp-personId': value = judgePrefs['personId']; break;
  case 'jp-availableAllDay': value = judgePrefs['availableAllDay']; break;
  case 'jp-availableStartHour': value = judgePrefs['availableStartHour']; break;
  case 'jp-availableUntilHour': value = judgePrefs['availableUntilHour']; break;
  case 'jp-checkedInAtFair': value = judgePrefs['checkedInAtFair']; break;
  case 'jp-roamingJudgeWilling': value = judgePrefs['roamingJudgeWilling']; break;
  case 'jp-judgeCaptainWilling': value = judgePrefs['judgeCaptainWilling']; break;
  case 'jp-previousJudgingExperience': value = judgePrefs['previousJudgingExperience']; break;
  case 'jp-noConflictOfInterest': value = judgePrefs['noConflictOfInterest']; break;
  case 'jp-VeteranJudge': value = judgePrefs['VeteranJudge']; break;
  case 'jp-CategoricalJudge': value = judgePrefs['CategoricalJudge']; break;
  case 'jp-ProfessionalSociety': value = judgePrefs['ProfessionalSociety']; break;
  case 'jp-ProfessionalSocietyJudge': value = judgePrefs['ProfessionalSocietyJudge']; break;
  case 'jp-GrandAwardJudge': value = judgePrefs['GrandAwardJudge']; break;
  case 'jp-GrandAwardJudgeType1': value = judgePrefs['GrandAwardJudgeType1']; break;
  case 'jp-GrandAwardJudgeType2': value = judgePrefs['GrandAwardJudgeType2']; break;
  case 'jss-Season': value = summaries.map(x=> x['Season']); isArray = true; break;
  case 'jss-FirstName': value = summaries.map(x=> x['FirstName']); isArray = true; break;
  case 'jss-LastName': value = summaries.map(x=> x['LastName']); isArray = true; break;
  case 'jss-JobTitle': value = summaries.map(x=> x['JobTitle']); isArray = true; break;
  case 'jss-Company': value = summaries.map(x=> x['Company']); isArray = true; break;
  case 'jss-Email': value = summaries.map(x=> x['Email']); isArray = true; break;
  case 'jss-Phone': value = summaries.map(x=> x['Phone']); isArray = true; break;
  case 'jss-Captain': value = summaries.map(x=> x['Captain']); isArray = true; break;
  case 'jss-AssessmentsCompleted': value = summaries.map(x=> x['AssessmentsCompleted']); isArray = true; break;
  case 'jss-AverageScore': value = summaries.map(x=> x['AverageScore']); isArray = true; break;
  case 'jss-Team': value = summaries.map(x=> x['Team']); isArray = true; break;
  case 'jss-Comments': value = summaries.map(x=> x['Comments']); isArray = true; break;
  case 'jss-Card': value = summaries.map(x=> x['Card']); isArray = true; break;
  case 'jss-NoShowed': value = summaries.map(x=> x['NoShowed']); isArray = true; break;
  case 'jss-YearsOfService': value = summaries.map(x=> x['YearsOfService']); isArray = true; break;
  case 'jss-Ban': value = summaries.map(x=> x['Ban']); isArray = true; break;
  case 'jss-CategoryPref1Id': value = summaries.map(x=> x['CategoryPref1Id']); isArray = true; break;
  case 'jss-CategoryPref1Name': value = summaries.map(x=> x['CategoryPref1Name']); isArray = true; break;
  case 'jss-CategoryPref2Id': value = summaries.map(x=> x['CategoryPref2Id']); isArray = true; break;
  case 'jss-CategoryPref2Name': value = summaries.map(x=> x['CategoryPref2Name']); isArray = true; break;
  case 'jss-CategoryPref3Id': value = summaries.map(x=> x['CategoryPref3Id']); isArray = true; break;
  case 'jss-CategoryPref3Name': value = summaries.map(x=> x['CategoryPref3Name']); isArray = true; break;
  case 'jss-CategoriesJudgedIdsCsv': value = summaries.map(x=> x['CategoriesJudgedIdsCsv']); isArray = true; break;
  case 'jss-CategoriesJudgedNamesCsv': value = summaries.map(x=> x['CategoriesJudgedNamesCsv']); isArray = true; break;
  case 'jss-SpecialAwardJudgePref': value = summaries.map(x=> x['SpecialAwardJudgePref']); isArray = true; break;
  case 'jss-SpecialAwardJudge': value = summaries.map(x=> x['SpecialAwardJudge']); isArray = true; break;
  case 'jss-CaptainPref': value = summaries.map(x=> x['CaptainPref']); isArray = true; break;
  case 'jss-CategoryJudgePref': value = summaries.map(x=> x['CategoryJudgePref']); isArray = true; break;
  case 'jss-CategoryJudge': value = summaries.map(x=> x['CategoryJudge']); isArray = true; break;
  case 'jss-GrandAwardPref': value = summaries.map(x=> x['GrandAwardPref']); isArray = true; break;
  case 'jss-RoamingWillingPref': value = summaries.map(x=> x['RoamingWillingPref']); isArray = true; break;
  case 'jss-PreviousExperience': value = summaries.map(x=> x['PreviousExperience']); isArray = true; break;
  case 'jss-SpecialAwardJudgeOrg': value = summaries.map(x=> x['SpecialAwardJudgeOrg']); isArray = true; break;
  }

  if(isArray)  {
    value?.filter(x=>x).forEach(x=>{
      values.push(x);
    })
  } else {
    values.push(value);
  }

};
