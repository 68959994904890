import React, { useState, useEffect } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { toast } from "react-toastify";
import { UiFormField, UiFormSection } from "../../utils/CommonTypes/UserTypes";
import Icon, { IconType } from "../Icon/Icon";
import Modal from "../Modal/Modal";
import ConfigFormLanding from "../Setup/ConfigForm/ConfigFormLanding";
import { IConfigItemChildType, IUiFormResponse } from "../Setup/ConfigForm/ConfigFormTypes";
import { isFieldRegexSatisfied, isFieldSatisfied } from "./FormFromApiData";
import FormSection from "./FormSection";
import Button from "../_Core/Button";
import StoreX from "../../redux/oldStore";

interface IFormModalProps {
  save: Function;
  remove?:Function;
  onUpdate?:Function;
  canSave: boolean;
  canRemove?:boolean;
  noManageLink?:boolean;
  sections: UiFormSection[];
  form?:IUiFormResponse;
  show: boolean;
  setShow: Function;
  title: string;
  values: Record<string, string[]>;
  projectFileChanged?: Function;
  targetId: string;
  modalSize: "m" | "l" | "xl" | "xxl" | "full";
  modalNoClose?: boolean;
  noEdit?:boolean;
  itemChildren?:IConfigItemChildType[];
}

export interface IFileUpload {
  file:File,
  Key:string,
}

const FormModal = (props: IFormModalProps) => {
  const [formErrors, setFormErrors] = useState<Record<string, UiFormField>>({});
  const [values, setValues] = useState<Record<string, string[]>>({});
  const [sections, setSections] = useState<UiFormSection[]>([]);
  const [noEdit, setNoEdit] = useState<boolean>(false);
  const [files, setFiles] = useState<IFileUpload[]>([]);

  useEffect(()=>{
    if(props.canSave && (props.targetId && +props.targetId > 0) && props.noEdit)
    setNoEdit(true);
    else setNoEdit(false);
  },[]);

    useEffect(()=>{
        let xSections = props.sections;

        xSections.forEach(x=>{
            x.Fields.forEach(f=>{
                let value = props.values[f.Key];
                if(value){
                    f.Value = value;
                }
                //  else 
                // {
                //     f.Value = [];
                // }                
            })
        });

        setFormErrors({});
        setValues(props.values);
        setFiles([]);
        setSections(xSections);
    },[props.sections, props.values]);

  const projectFileChanged = (x, y, z) => {
    if (props.projectFileChanged) props.projectFileChanged(x, y, z);
  };

  const handleFieldChange = (key: string, value: string[]) => {
    let xValues = { ...values };
    xValues[key] = value;
    setValues(xValues);

    let xSections = [...sections];

    xSections.forEach(x=>{
        x.Fields.forEach(f=>{
            if  (f.Key === key){
                f.Value = value;
            }
        })
    });

    setSections(xSections);
  };
  const handleFileChange = (key:string, file?:File) =>{
    let xValues = { ...values };
    xValues[key] = [file?.name ?? ''];
    setValues(xValues);

    let xSections = [...sections];

    xSections.forEach(x=>{
        x.Fields.forEach(f=>{
            if  (f.Key === key){
                f.Value = [file?.name ?? ''];
            }
        })
    });

    setSections(xSections);
    let fs = files.filter(x=>x.Key !== key);
    if(file) fs.push({file:file, Key:key});
    setFiles(fs);
    
  };

  const handleSelectChangeWithCall = (
    field: UiFormField,
    key: string,
    value: string[]
  ) => {
    console.error("HandleSelectedChangeWithCall -> Not fully implemented.");
    handleFieldChange(key, value);
  };

  const canSave = () => {
    let sections = props.sections;

    let requiredMissing = false;
    let missingFields: string[] = [];
    let invalid = false;
    let invalidFields: string[] = [];
    sections.forEach((sect) => {
      sect.Fields.forEach((field) => {

        if (!isFieldSatisfied(field, values)) {
          formErrors[field.Key] = field;
          requiredMissing = true;
          missingFields.push(field.Label);
        }
        if (!isFieldRegexSatisfied(field, values)) {
          formErrors[field.Key] = field;
          invalid = true;
          invalidFields.push(field.Label);
        }
      });
    });

    if (requiredMissing) {
      toast.warning(
        <>
          Oops, Looks like some fields are missing.
          <ul>
            {missingFields.map((x, i) => {
              return (
                <li
                  key={`missing-field-${i}`}
                  dangerouslySetInnerHTML={{ __html: x }}
                ></li>
              );
            })}
          </ul>
        </>
      );
    }
    if (invalid) {
      toast.warning(
        <>
          Oops, Some fields contain invalid data.
          <ul>
            {invalidFields.map((x, i) => {
              return (
                <li
                  key={`missing-field-${i}`}
                  dangerouslySetInnerHTML={{ __html: x }}
                ></li>
              );
            })}
          </ul>
        </>
      );
    }

    return !requiredMissing && !invalid;
  };

  const save = () => {
    console.log('saving form modal!');
    //form field requirements meet?
    let pass = canSave();
    if (!pass) {
      return;
    }
    if (props.canSave) props.save(values, files);
  };

  const remove = ()=>{
    console.log('removing from form modal!');

    if(props.canRemove && props.remove){
      props.remove(values);
    }
  }

  const mapForms = () =>{
    if(props.targetId !== "-1" && props.itemChildren?.length){
      return <Tabs>
        <TabList>
          <Tab>Main</Tab>
          {props.itemChildren.map((x,i)=>{
            return <Tab key={`tsfms-${i}`}>{x.Name}</Tab>
          })}
        </TabList>
        <TabPanel>
          {mapMainForm()}
        </TabPanel>
        {props.itemChildren.map((x,i)=>{
            return <TabPanel key={`tsfmsp-${i}`}>
                {mapForm(x)}              
            </TabPanel>
          })}
      </Tabs>
    } else {
      return mapMainForm();
    }
  };

  const mapForm = (child:IConfigItemChildType) => {
    return <ConfigFormLanding type={`${child.Type}:${props.targetId}`} onUpdate={props.onUpdate} isChild={true} />
  };

  const mapMainForm = () => {
    return           <div className="form-horizontal">
      
    {props.sections.map((x, i) => {
      if(noEdit) x.Fields?.forEach(f=>f.ReadOnly = true);
      return (
        <FormSection
          key={`x-sec-${i}`}
          section={x}
          groupIds={{}}
          formValues={values}
          handleFieldChange={handleFieldChange}
          handleFileChange={handleFileChange}
          handleSelectChangeWithCall={handleSelectChangeWithCall}
          idCalledInApi={props.targetId ?? "really"}
          targetId={props.targetId ?? "HI"} 
          formErrors={formErrors}
          canSave={props.canSave}
          projectFileChanged={projectFileChanged}
        />
      );
    })}
<div className="flex-between">
    <div className="form-group">
      {props.canSave && !noEdit && (
        <button
          type="button"
          className="btn btn-secondary"
          onClick={save}
        >
          <Icon type={IconType.save} /> {props.form?.SubmitBtnText ?? 'Save'}
        </button>
      )}
        {props.canRemove && props.remove && !props.form?.HideRemove && <button
          type="button"
          className="btn btn-default"
          onClick={remove}
        >
          <Icon type={IconType.trashO} addClass={'text-danger'}  /> Remove
        </button>}
    </div>
          <div>
            {props.form?.LinkToManager && props.noManageLink ? false:true && <Button type="open" text={props.form?.LinkToManagerLabelText ?? 'Manager'} onClick={()=>{
              window.location.href = StoreX.BuildUrl(props.form?.LinkToManager ?? '');              
            }} />}
          </div>
    </div>
  </div>
  };

  return (
    <>
      {props.show && (
        <Modal
          setModalOpen={props.setShow}
          title={props.title}
          size={props.modalSize}
          noClose={props.modalNoClose}
        >
          {mapForms()}
        </Modal>
      )}
    </>
  );
};

export default FormModal;
