import { useEffect, useState } from 'react';
import { IState } from '../../../redux/redux';
import Icon, { IconType } from '../../Icon/Icon';
import { ICircleInfo } from '../../_Core/CoreTypes';
import { store } from '../../../redux/oldStore';
import { ServerResponse } from '../../../utils/Server';
import { toast } from 'react-toastify';
import { ICirlceInfoApiCall } from './JudgingCirlcesTypes';

interface IProps {
  state: IState;
  circle?: ICircleInfo;
  info: ICirlceInfoApiCall;
  onUpdate: (circle: ICircleInfo) => void;
  onRemove: (circle: ICircleInfo) => void;
}

const JudgingCircleManager = (props: IProps) => {
  const [circle, setCircle] = useState<ICircleInfo>({});

  useEffect(() => {
    setCircle(props.circle ?? {});
  }, [props.circle]);

  const onSave = () => {
    toast.dismiss();
    let errors:string[] = [];
    if(!circle.name) errors.push(`You must set the name of this new circle.`);
    if((circle.roundId ?? 0) <= 0) errors.push(`You must select the round of this circle.`);

    if(errors.length > 0){
        toast.warning(<>Oops! Can't save yet.
        <ul>
            {errors.map((e,i)=>{
                return <li key={`jcm-e-s-${i}`}>{e}</li>
            })}
        </ul>
        </>)
        return;
    }

    store.server.postApi<ServerResponse<ICircleInfo>>(`../JudgingCircles/CircleUpdate`,circle)
    .then(x=>{
        if(x.Success){
            toast.success(`Circle "${circle.name}" was saved!`);            
            setCircle(x.Value);
            props.onUpdate(x.Value);
        } else {
            toast.error(x.Message, {autoClose:false});
        }
    });
  };

  const onRemove = () => {
    if(!window.confirm(`${props.state.User?.firstName} are you sure you want to remove cirlce "${circle.name}"?`)){
        return;
    }

    toast.dismiss();
    store.server.postApi<ServerResponse<boolean>>(`../JudgingCircles/CircleRemove`,{circleId:circle.id})
    .then((x)=>{
        if(x.Success){
            toast.info(`Circle "${circle.name}" was removed.`);
            props.onRemove(circle);
        } else {
            toast.error(x.Message, {autoClose:false});
        }
    });
  };

  return (
    <>
      <div className="form-horizontal">
        <div className="form-group">
          <label className="col-sm-4 control-label" htmlFor='jcm-name'>Circle Name</label>
          <div className="col-sm-8">
            <input
                type={'text'}
                id={'jcm-name'}
                className='form-control'
                maxLength={250}
                value={circle.name ?? ''}
                onChange={(x)=>{setCircle({...circle,name:x.target.value});}}
            />
          </div>
        </div>
        <div className="form-group">
          <label className="col-sm-4 control-label" htmlFor='jcm-round'>Round</label>
          <div className="col-sm-8">
            <select
                id={'jcm-round'}
                className='form-control'
                value={circle.roundId ?? 0}
                onChange={(x)=>{setCircle({...circle,roundId:+x.target.value});}}
            >
                <option value={0}>Select Round</option>
                {
                    props.info.Rounds.map((x,i)=>{
                        return <option key={`kjcm-e-r-${i}`} value={x.id ?? 0}>{x.name}</option>
                    })
                }
            </select>
          </div>
        </div>
        <div className="form-group">
          <label className="col-sm-4 control-label" htmlFor='jcm-category'>Category</label>
          <div className="col-sm-8">
            <select
                id={'jcm-category'}
                className='form-control'
                value={circle.categoryId ?? 0}
                onChange={(x)=>{setCircle({...circle,categoryId:+x.target.value});}}
            >
                <option value={0}>Select category</option>
                {
                    props.info.Categories.map((x,i)=>{
                        return <option key={`kjcm-e-c-${i}`} value={x.id ?? 0}>{x.name}</option>
                    })
                }
            </select>
          </div>
        </div>        <div className="form-group">
          <label className="col-sm-4 control-label" htmlFor='jcm-division'>Division</label>
          <div className="col-sm-8">
            <select
                id={'jcm-division'}
                className='form-control'
                value={circle.divisionId ?? 0}
                onChange={(x)=>{setCircle({...circle,divisionId:+x.target.value});}}
            >
                <option value={0}>Select division</option>
                {
                    props.info.Divisions.map((x,i)=>{
                        return <option key={`kjcm-e-di-${i}`} value={x.id ?? 0}>{x.name}</option>
                    })
                }
            </select>
          </div>
        </div>        <div className="form-group">
          <label className="col-sm-4 control-label" htmlFor='jcm-judgeGroup'>Judge Group</label>
          <div className="col-sm-8">
            <select
                id={'jcm-judgeGroup'}
                className='form-control'
                value={circle.judgeGroup ?? 0}
                onChange={(x)=>{setCircle({...circle,judgeGroup:+x.target.value});}}
            >
                <option value={0}>Select judge group</option>
                {
                    props.info.JudgeGroups.map((x,i)=>{
                        return <option key={`kjcm-e-jg-${i}`} value={x.id ?? 0}>{x.name}</option>
                    })
                }
            </select>
          </div>
        </div>
        <div className="form-group">
          <div className="col-sm-4"></div>
          <div className="col-sm-8">
            <button type={'button'} className="btn btn-secondary" onClick={onSave}>
              <Icon type={IconType.save} />
              Save
            </button>
            {(circle.id ?? 0) > 0 && (
              <button type={'button'} className="btn btn-default" onClick={onRemove}>
                <Icon type={IconType.trashO} />
                Remove
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default JudgingCircleManager;
