import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import StoreX, { store } from '../../redux/oldStore';
import { Server, ServerResponse } from '../../utils/Server';
import { FormatDate } from '../../utils/Tools';
import Icon, { IconType } from '../Icon/Icon';
import Modal from '../Modal/Modal';
import { IInvoice, IInvoiceInfo, IInvoicesInfo } from './OrganizationTypes';
import { useEffect, useState } from 'react';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe, Stripe, StripeElements } from '@stripe/stripe-js';
import Payment from '../Store/Payment';
import StripPayment, { istripForm } from './stripePayment';
import InvoiceEditor from './InvoiceEditor';

interface IInvoiceProps {}


interface IPayInvoice {
  Invoice: IInvoice;
  Message: string;
}

interface iform {
  invoice?: string;
  paidOn?: string;
  notes?: string;
}

const Invoice = (props: IInvoiceProps) => {
  const {invoiceId} = useParams();
  const [info, setInfo] = useState<IInvoiceInfo>();
  const [showPayNow, setShowPayNow] = useState<boolean>();
  const [showPayNowManual, setShowPayNowManual] = useState<boolean>();
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);
  const [showEditModal, setShowEditModal] = useState<boolean>();
  const [form, setForm] = useState<iform>({});

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    store.server.getApi<ServerResponse<IInvoiceInfo>>(`../OrganizationData/Invoice/${invoiceId}`).then((x) => {
      if (x.Success) {
        setInfo(x.Value);
        initStripe(x.Value.StripeKey);
      } else {
        toast.error(x.Message, { autoClose: false });
      }
    });
  };

  const initStripe = (key: string) => {
    setStripePromise(loadStripe(key));
  };

  const buildInvoiceBlip = (x: IInvoice, i: number) => {
    let link = StoreX.BuildUrl(`../app/organization/invoice/${x.PublicId}`);
    return (
      <div className={`flex-between invoice ${x.PaidAtUtc ? 'paid' : ''}`} key={`i-k-${i}`}>
        <div>
          <div>
            <div><img width="250" src={StoreX.BuildUrl(`../file/Client/c3d161b7-ca58-436c-80fc-5f989eaf0ea7`)}/></div>
            
            <div className="soft">send payment to:</div>
            <div className='strong'>Illuminating Software llc</div>
            <div className='flex-between'>
              <div>
                <div>3688 S Rowan Cove</div>
                <div>West Valley City, UT 84128</div>
              </div>
              <div>
                
              </div>
            </div>
          </div>
        </div>

        <div className="text-right">
          <div className="no-print">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                window.print();
              }}>
              <Icon type={IconType.print} /> Print
            </button>
          </div>
          <div className="large">
            {x.Type} # {x.Id}
          </div>
          <div>
            {x.Type} Date: {FormatDate(x.CreatedAtUtc, 'date')}
          </div>
          {x.DueAtUtc && <div>Due: {FormatDate(x.DueAtUtc, 'date')}</div>}
        </div>
      </div>
    );
  };

  const handlePayNow = async (stripe: Stripe, elements: StripeElements, form: istripForm) => {
    //do I have the info I want?
    let errors: string[] = [];
    if (!form.firstName || form.firstName?.length === 0) errors.push(`First name required`);
    if (!form.lastName || form.lastName?.length === 0) errors.push(`Last name required`);

    if (errors.length > 0) {
      alert(errors.join(', '));
      return;
    }

    if (!stripe || !elements) {
      return;
    }
    let thisElement = elements.getElement(CardElement);
    if (thisElement === null) return;

    stripe.createToken(thisElement).then((x) => {
      if (x.error) {
        console.log(x.error);
        alert(x.error.message);
      } else {
        let process = {
          ...form,
          year: x?.token?.card?.exp_year,
          month: x.token.card?.exp_month,
          last4: x.token.card?.last4,
          zip: x.token.card?.address_zip,
          token: x.token.id,
          orderId: info?.Invoice.PublicId,
        };
        ProcessPayment(process);
      }
    });
  };

  const ProcessPayment = (payload) => {
    let url = `../OrganizationData/PayInvoice`;

    store.server.postApi<ServerResponse<IPayInvoice>>(url, payload).then((x) => {
      if (x.Success) {
        if (info) setInfo({ ...info, Invoice: x.Value.Invoice });
        if (x.Value.Invoice.PaidAtUtc) {
          setShowPayNow(false);
          toast.success(`Payment Recieved!`);
        } else {
          toast.warning(`Payment Failed: ${x.Value.Message}`, { autoClose: false });
        }
      } else {
        toast.error(`${x.Value?.Message ?? ''} ${x.Message}`, { autoClose: false });
      }
    });
  };

  const recordManualPayment = () => {
    let request = { ...form, Invoice: info?.Invoice.PublicId };

    if (!window.confirm(`Are you sure you want to record this manual payment?`)) {
      return;
    }

    let url = `../OrganizationData/MarkInvoicePaid`;

    store.server.postApi<ServerResponse<IPayInvoice>>(url, request).then((x) => {
      if (x.Success) {
        if (info) setInfo({ ...info, Invoice: x.Value.Invoice });
        if (x.Value.Invoice.PaidAtUtc) {
          setShowPayNow(false);
          setShowPayNowManual(false);
          toast.success(`Payment Recorded!`);
        } else {
          toast.warning(`Payment Failed: ${x.Value.Message}`, { autoClose: false });
        }
      } else {
        toast.error(`${x.Value?.Message ?? ''} ${x.Message}`, { autoClose: false });
      }
    });
  };

  return (
    <>
      <div className="org-invoice">
        {info ? (
          <>
            {buildInvoiceBlip(info.Invoice, -1)}
            <hr />

            {(info?.Invoice?.Items?.length ?? 0) > 0 && (
              <div>
                {info.Invoice.Items?.filter(x => x.Quantity ?? 0 > 0).map((x, i) => {
                  return (
                    <div className="flex-between invoice-item" key={`iik-${i}`}>
                      <div>
                        <strong>{x.Name} </strong>
                        <small>{x.ItemName}</small>
                        {x.Notes && <div className="invoice-notes" dangerouslySetInnerHTML={{ __html: x.Notes }}></div>}
                      </div>
                      <div className="text-right">
                        {(x.Quantity ?? 0) > 1 && <div>Quantity: {x.Quantity}</div>}
                        {(x.Cost ?? 0) != 0 && <div>$ {x.Cost?.toFixed(2)}</div>}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {info?.Invoice.Notes && <div className="headroom main invoice-notes" dangerouslySetInnerHTML={{ __html: info.Invoice.Notes }}></div>}
            <hr />
            <div className="flex-between">
              <div>
                {info && info.Invoice.PaidAtUtc && (
                  <div className="alert alert-success">
                    <h4>Paid via {info.Invoice.Method}</h4>
                    <div>
                      {info.Invoice.Type} Payment at {FormatDate(info.Invoice.PaidAtUtc)}
                      {info.Invoice.PaymentId && <div>Payment Auth: {info.Invoice.PaymentId}</div>}
                    </div>
                  </div>
                )}

                {info && info.Invoice.RemovedAtUtc && (
                  <div className="alert alert-danger">
                    <h4>Removed at {FormatDate(info.Invoice.RemovedAtUtc)}</h4>
                  </div>
                )}
              </div>
              <div>
                <div className="large">Amount: $ {info.Invoice.Amount?.toFixed(2)}</div>
                {info.Invoice.PurchaseOrderName && <div className="alert alert-info strong">Purchase Order: {info.Invoice.PurchaseOrderName}</div>}
              </div>
            </div>
            {!info.Invoice.PaidAtUtc && (
              <div className="flex-between text-right no-print">
                <div></div>
                <div className="btn-group headroom">
                  {(info.Invoice.Amount ?? 0) > 0 && (info.Invoice.Amount ?? 0) < 3501 && (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        setShowPayNow(true);
                      }}>
                      <Icon type={IconType.creditCard} /> Pay Now
                    </button>
                  )}
                  {info.IsOwner && (
                    <>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => {
                          setShowPayNowManual(true);
                        }}>
                        <Icon type={IconType.creditCard} /> Record Payment
                      </button>
                      <button
                        type="button"
                        className="btn btn-default"
                        onClick={() => {
                          setShowEditModal(true);
                        }}>
                        <Icon type={IconType.edit} />
                      </button>
                    </>
                  )}
                </div>
              </div>
            )}
            <div className="text-muted headroom-xl text-center">If you require a W9 please email support@zfairs.com and reference this invoice. # {info.Invoice.Id}</div>
            <div className="text-muted headroom tos">
              <h4>Service Basics</h4>
              Unless you have a different Agreement with zFairs or Illuminating Software the following applies: <br />
              <br />
              <a href={'https://www.zfairs.com/Legal/zFairs Service Agreement.pdf'} target={'_blank'}>
                zFairs Service Agreement
              </a>
              <ul>
                <li>
                  Data Security
                  <ul>
                    <li>Data is encrypted via transit and at rest.</li>
                    <li>You maintain full ownership of your data, zFairs does not sell or share information.</li>
                    <li>zFairs is actively working to ensure your data is safe, secure, and available</li>
                    <li>All data and services are in the USA, in secure SOC II && PCI compliant data centers.</li>
                    <li>
                      <a href={'https://www.zfairs.com/Legal/HECVATFull.zip'} target={'_blank'}>
                        Full HECVAT
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Hosting
                  <ul>
                    <li>zFairs is accessible via any modern browser</li>
                    <li>zFairs works to resolve any service interruptions or defects as quickly as possible.</li>
                    <li>zFairs works to ensure its services are always available when you need them.</li>
                    <li>
                      <a href={'https://www.zfairs.com/Legal/Illuminating%20Software%20Terms%20of%20Use.pdf'} target={'_blank'}>
                        Terms of Service
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Privacy
                  <ul>
                    <li>We do not sell or share information</li>
                    <li>We comply with US FERPA and COPPA laws, and are committed to continue working to always stay in compliance.</li>
                    <li>Upon request we will completely remove data from all active systems and databases. </li>
                    <li>
                      <a href={'https://www.zfairs.com/Legal/Privacy%20Policy.pdf'} target={'_blank'}>
                        Privacy Policy
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Support
                  <ul>
                    <li>zFairs is committed to ensuring you have everything you need to run your event smoothly, zFairs support can be reached at support@zfairs.com </li>
                  </ul>
                </li>
                <li>
                  Features
                  <ul>
                    <li>Standard zFairs license include the following</li>
                  </ul>
                  <div className="row">
                    <div className="col-sm-6">
                      <ul>
                        <li>All Base Features</li>
                        <li>In Person & Virtual Tools</li>
                        <li>Online Judging for In person & Virtual</li>
                        <li>Video Conferencing</li>
                        <li>Judging Assignment Tools</li>
                        <li>Awards Management</li>
                        <li>Certificates, & Reports</li>
                      </ul>
                    </div>
                    <div className="col-sm-6">
                      <ul>
                        <li>Showcase</li>
                        <li>Paperwork & Forms Management</li>
                        <li>Forms Wizard & ISEF Forms Wizard</li>
                        <li>Payment & Credit Card Processing</li>
                        <li>Invoicing & Purchase Orders</li>
                        <li>Project / Entry Advancement</li>
                        <li>Participant & Entry Images</li>
                        <li>Support</li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </>
        ) : (
          <>Loading...</>
        )}
      </div>
      {showPayNow && info && (
        <Modal setModalOpen={setShowPayNow} title={'Pay Now'} size="l">
          <Elements stripe={stripePromise}>
            <StripPayment Amount={info.Invoice.Amount ?? 0} handlePayNow={handlePayNow} />
          </Elements>
        </Modal>
      )}
      {showPayNowManual && info && (
        <Modal setModalOpen={setShowPayNowManual} title={'Record Payment'} size="l">
          <div className="form-horizontal">
            <div className="form-group">
              <label className="control-label col-sm-4" htmlFor="org-paidOn">
                Paid On
              </label>
              <div className="col-sm-8">
                <input
                  type="date"
                  id="org-paidOn"
                  onChange={(e) => {
                    setForm({ ...form, paidOn: e.target.value });
                  }}
                  value={form.paidOn ?? ''}
                  className="form-control"
                />
              </div>
            </div>
            <div className="form-group">
              <label className="control-label col-sm-4" htmlFor="org-notes">
                Public Notes
              </label>
              <div className="col-sm-8">
                <textarea
                  rows={5}
                  id="org-notes"
                  onChange={(e) => {
                    setForm({ ...form, notes: e.target.value });
                  }}
                  value={form.notes ?? ''}
                  className="form-control"
                />
              </div>
            </div>
            <div className="form-group">
              <div className="col-sm-8 col-sm-offset-4">
                <button type="button" className="btn btn-secondary" onClick={recordManualPayment}>
                  <Icon type={IconType.payNow} /> Record Payment
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}

      {showEditModal && info && (
        <Modal setModalOpen={setShowEditModal} title={'Update Invoice'} size="l">
          <InvoiceEditor
            invoice={info.Invoice}
            isOwner={info.IsOwner}
            onUpdated={(invoice: IInvoice) => {
              setInfo({ ...info, Invoice: invoice });
              if (invoice.RemovedAtUtc) setShowEditModal(false);
            }}
            products={info.Products ?? []}
          />
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = (reduxState) => {
  return reduxState.main;
};

export default connect(mapStateToProps, {})(Invoice);
