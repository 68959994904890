import React from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { store } from "../../redux/oldStore";
import {
  AdvancedTypes,
  Product,
  ProductsResponse,
} from "../../utils/CommonTypes/StoreTypes";
import { ServerResponse } from "../../utils/Server";
import { formatMoney } from "../../utils/Tools";
import Icon, { IconType } from "../Icon/Icon";

interface ProductInModalProps {
  product: Product;
  handleProductsResponse: (stuff: ProductsResponse) => void;
  handleProductClose: (open?: boolean) => void;
  userId: string | null;
  orderId?: string;
}

const ProductInModal = (props: ProductInModalProps) => {
  const [formData, setFormData] = useState<Record<string, string>>({});
  const [anyFormData, setAnyFormData] = useState<boolean>(false);
  const [size, setSize] = useState("");
  const [quantity, setQuantity] = useState(1);

  const handleAddToCartConfirm = () => {
    let submitObj = {
      productId: props.product.Id,
      quantity,
      size,
      userId: props.userId,
      custom: "",
      orderId: props.orderId,
    };
    if (anyFormData) {
      submitObj.custom = JSON.stringify(formData);
    }
    let errors: string[] = [];
    if (
      props.product.AdvancedType === AdvancedTypes.Normal &&
      props.product.Sizes &&
      props.product.Sizes.length > 0 &&
      props.product.Sizes[0] &&
      !size
    ) {
      toast.warning(`You must select a size.`);
      return;
    } else if (props.product.AdvancedType === AdvancedTypes.HandlingLabel) {
      if (
        !formData["NumberOfPackages"] ||
        Number(formData["NumberOfPackages"]) <= 0
      )
        errors.push(`Please set the number of packages you'll be sending.`);
      if (!formData["SendersName"]) errors.push(`Please set the senders name.`);
      if (!formData["AddressLine1"])
        errors.push(`Address line 1 is required.`);
      if (!formData["AddressLine2"])
        errors.push(`Address line 2 is required.`);
      //if(!formData['AddressLine3']) errors.push(``);
      //if(!formData['AddressLine4']) errors.push(``);
      submitObj.quantity = Number(formData["NumberOfPackages"]);
    } else if (props.product.AdvancedType === AdvancedTypes.HousingPackage) {
      if (!formData[`PackageFor`] || formData[`PackageFor`] === ".")
        errors.push(`Package is for is required.`);
      if (!formData[`OccupantsFirstName`])
        errors.push(`Occupants first name is required. `);
      if (!formData[`OccupantsLastName`])
        errors.push(`Occupants last name is required.`);
      if (!formData[`OccupantsAge`] || formData[`OccupantsAge`] === ".")
        errors.push(`Age is required`);
      if (!formData[`OccupantsGender`] || formData[`OccupantsGender`] === ".")
        errors.push(`Gender is required`);
      //if (!formData[`ChaperonesName`]) errors.push(`Chaperones name is required`);
      //if (!formData[`RoommatesName`]) errors.push(``);
      //if (!formData[`Needs`]) errors.push(``);
      submitObj.quantity = 1;
    } else if (
      props.product.AdvancedType === AdvancedTypes.AdditionalAttendee
    ) {
      submitObj.quantity = 1;
      if (!formData["Relationship"] || formData["Relationship"] === ".")
        errors.push(`Relationship is required`);
      if (!formData["AgeRange"] || formData["AgeRange"] === ".")
        errors.push(`Age range is required`);
      if (!formData["FirstName"] || false)
        errors.push(`First name is required`);
      if (!formData["LastName"] || false) errors.push(`Last name is required`);
    }

    if (errors && errors.length > 0) {
      toast.warning(errors.join(", "));
      return;
    }

    store.server
      .postApi<ServerResponse<ProductsResponse>>(
        "../Store/AddToCart",
        submitObj
      )
      .then((res) => {
        console.log(res);
        // set cart in parent component.
        toast.success("Added to cart!");
        props.handleProductsResponse(res.Value);
        props.handleProductClose();
      });
  };

  const handleFormChange = (key: string, value: string) => {
    setFormData({ ...formData, [key]: value });
    setAnyFormData(true);
  };

  const inputsByAdvancedType = (type: AdvancedTypes) => {

    switch (type){
      
      
    
    case AdvancedTypes.HousingPackage: return (
      <>
        <div className="form-group">
          <label className="control-label col-sm-6" htmlFor="PackageFor">
            Package is for
          </label>
          <div className="col-sm-6">
            <select
              className="form-control"
              name="PackageFor"
              id="PackageFor"
              value={formData?.PackageFor ?? "."}
              onChange={(e) => handleFormChange("PackageFor", e.target.value)}
            >
              <option value="."> </option>
              <option value="Myself">Myself</option>
              <option value="ParentGuardian">Parent / Guardian</option>
              <option value="FamilyMember">Family Member</option>
              <option value="Chaperone">Chaperone</option>
              <option value="Teacher">Teacher</option>
              <option value="Student">Student</option>
            </select>
          </div>
        </div>
        <div className="form-group">
          <label
            className="control-label col-sm-6"
            htmlFor="OccupantsFirstName"
          >
            Occupant's First Name
          </label>
          <div className="col-sm-6">
            <input
              className="form-control"
              type="text"
              name="OccupantsFirstName"
              id="OccupantsFirstName"
              value={formData?.OccupantsFirstName ?? ""}
              onChange={(e) =>
                handleFormChange("OccupantsFirstName", e.target.value)
              }
            />
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-6" htmlFor="OccupantsLastName">
            Occupant's Last Name
          </label>
          <div className="col-sm-6">
            <input
              className="form-control"
              type="text"
              name="OccupantsLastName"
              id="OccupantsLastName"
              value={formData?.OccupantsLastName ?? ""}
              onChange={(e) =>
                handleFormChange("OccupantsLastName", e.target.value)
              }
            />
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-6" htmlFor="OccupantsAge">
            Occupant's Age
          </label>
          <div className="col-sm-6">
            <select
              className="form-control"
              name="OccupantsAge"
              id="OccupantsAge"
              value={formData?.OccupantsAge ?? "."}
              onChange={(e) => handleFormChange("OccupantsAge", e.target.value)}
            >
              <option value="."> </option>
              <option value="0-5">0 - 5 Years Old</option>
              <option value="6-10">6 - 10 Years Old</option>
              <option value="11-13">11 - 13 Years Old</option>
              <option value="14-17">14 - 17 Years Old</option>
              <option value="18+"> 18 and Older</option>
            </select>
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-6" htmlFor="OccupantsGender">
            Occupant's Gender
          </label>
          <div className="col-sm-6">
            <select
              className="form-control"
              name="OccupantsGender"
              id="OccupantsGender"
              value={formData?.OccupantsGender ?? "."}
              onChange={(e) =>
                handleFormChange("OccupantsGender", e.target.value)
              }
            >
              <option value="."> </option>
              <option value="female">Female</option>
              <option value="male">Male</option>
              <option value="female-id-as-male">Identify as Male</option>
              <option value="male-id-as-female">Identify as Female</option>
              <option value="non-binary">Non Binary</option>
              <option value="Other">Other</option>

            </select>
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-6" htmlFor="ChaperonesName">
            Chaperone's Name
          </label>
          <div className="col-sm-6">
            <input
              className="form-control"
              type="text"
              name="ChaperonesName"
              id="ChaperonesName"
              value={formData?.ChaperonesName ?? ""}
              onChange={(e) =>
                handleFormChange("ChaperonesName", e.target.value)
              }
            />
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-6" htmlFor="RoommatesName">
            Roommate's Name
          </label>
          <div className="col-sm-6">
            <input
              className="form-control"
              type="text"
              name="RoommatesName"
              id="RoommatesName"
              value={formData?.RoommatesName ?? ""}
              onChange={(e) =>
                handleFormChange("RoommatesName", e.target.value)
              }
            />
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-6" htmlFor="Needs">
            ADA Needs & Notes
          </label>
          <div className="col-sm-6">
            <textarea
              className="form-control"
              name="Needs"
              id="Needs"
              value={formData?.Needs ?? ""}
              onChange={(e) => handleFormChange("Needs", e.target.value)}
            ></textarea>
          </div>
        </div>
      </>
    );
    case AdvancedTypes.AdditionalAttendee: return (
      <>
        <div className="form-group">
          <label className="control-label col-sm-6" htmlFor="Relationship">
            Relationship to Attendee
          </label>
          <div className="col-sm-6">
            <select
              className="form-control"
              name="Relationship"
              id="Relationship"
              value={formData?.Relationship ?? "."}
              onChange={(e) => handleFormChange("Relationship", e.target.value)}
            >
              <option value="."> </option>
              <option value="ParentGuardian">Parent / Guardian</option>
              <option value="Sibling">Sibling</option>
              <option value="FamilyMember">Family Member</option>
              <option value="Friend">Friend</option>
              <option value="Chaperone">Chaperone</option>
              <option value="Other">Other</option>
            </select>
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-6" htmlFor="FirstName">
            First Name
          </label>
          <div className="col-sm-6">
            <input
              className="form-control"
              type="text"
              name="FirstName"
              id="FirstName"
              value={formData?.FirstName ?? ""}
              onChange={(e) => handleFormChange("FirstName", e.target.value)}
            />
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-6" htmlFor="LastName">
            Last Name
          </label>
          <div className="col-sm-6">
            <input
              className="form-control"
              type="text"
              name="LastName"
              id="LastName"
              value={formData?.LastName ?? ""}
              onChange={(e) => handleFormChange("LastName", e.target.value)}
            />
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-6" htmlFor="AgeRange">
            Age Range
          </label>
          <div className="col-sm-6">
            <select
              className="form-control"
              name="AgeRange"
              id="AgeRange"
              value={formData?.AgeRange ?? "."}
              onChange={(e) => handleFormChange("AgeRange", e.target.value)}
            >
              <option value="."> </option>
              <option value="4-12">4 - 12 Years Old</option>
              <option value="13+">13 and Older</option>
            </select>
          </div>
        </div>
      </>
    );
    case AdvancedTypes.HandlingLabel: return (
      <>
        <div className="form-group">
          <label className="control-label col-sm-6" htmlFor="NumberOfPackages">
            Number of Packages
          </label>
          <div className="col-sm-6">
            <input
              className="form-control"
              type="number"
              name="NumberOfPackages"
              id="NumberOfPackages"
              value={formData?.NumberOfPackages ?? ""}
              onChange={(e) =>
                handleFormChange("NumberOfPackages", e.target.value)
              }
            />
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-6" htmlFor="SendersName">
            Sender's Name
          </label>
          <div className="col-sm-6">
            <input
              className="form-control"
              type="text"
              name="SendersName"
              id="SendersName"
              value={formData?.SendersName ?? ""}
              onChange={(e) => handleFormChange("SendersName", e.target.value)}
            />
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-6" htmlFor="AddressLine1">
            Return Address
          </label>
          <div className="col-sm-6">
            <input
              className="form-control"
              type="text"
              name="AddressLine1"
              id="AddressLine1"
              value={formData?.AddressLine1 ?? ""}
              onChange={(e) => handleFormChange("AddressLine1", e.target.value)}
            />
            <input
              className="form-control"
              type="text"
              name="AddressLine2"
              id="AddressLine2"
              placeholder="address"
              value={formData?.AddressLine2 ?? ""}
              onChange={(e) => handleFormChange("AddressLine2", e.target.value)}
            />
            <input
              className="form-control"
              type="text"
              name="AddressLine3"
              id="AddressLine3"
              value={formData?.AddressLine3 ?? ""}
              onChange={(e) => handleFormChange("AddressLine3", e.target.value)}
            />
            <input
              className="form-control"
              type="text"
              name="AddressLine4"
              id="AddressLine4"
              value={formData?.AddressLine4 ?? ""}
              onChange={(e) => handleFormChange("AddressLine4", e.target.value)}
            />
          </div>
        </div>
      </>
    );
    // case AdvancedTypes.TeacherRegistrationFee: <></>return ,
    // case AdvancedTypes.Normal: 
    default:
    return (
      <>
        <div className="form-group">
          <label className="control-label col-sm-6" htmlFor="quantity">
            Quantity
          </label>
          <div className="col-sm-6">
            <input
              className="form-control"
              type="number"
              name="quantity"
              id="quantity"
              min={0}
              value={quantity ?? 1}
              onChange={(e) => setQuantity(+e.target.value)}
            />
          </div>
        </div>
      </>
    );
  }};

  return (
    <div>
      {props.product.ImageUrl && (
        <div>
          <img src={props.product.ImageUrl} alt={props.product.Name} />
        </div>
      )}
      <h2>
        {props.product.Name} - {formatMoney.format(props.product.Cost)}
      </h2>
      <div dangerouslySetInnerHTML={{__html:props.product.Description ?? ''}}></div>
      <div className="form-horizontal">
        <div className="form-group">
          <label className="control-label col-sm-6" htmlFor="Cost">
            Cost
          </label>
          <div className="col-sm-6">
            <input
              className="form-control"
              readOnly={true}
              type="text"
              name="Cost"
              id="Cost"
              value={formatMoney.format(props.product.Cost)}
              // onChange={(e) => handleFormChange('', e.target.value)}
            />
          </div>
        </div>
        {props.product.Sizes?.length > 1 && (
          <div className="form-group">
            <label className="control-label col-sm-6" htmlFor="size">
              Size
            </label>
            <div className="col-sm-6">
              <select
                className="form-control"
                name="size"
                id="size"
                value={size}
                onChange={(e) => setSize(e.target.value)}
              >
                <option value="."> </option>
                {props.product.Sizes.map((x) => {
                  return (
                    <option key={x} value={x}>
                      {x}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        )}
        {inputsByAdvancedType(props.product.AdvancedType)}
      </div>
      <div className="form-group">
        <div className="col-sm-6 col-sm-offset-6">
          <button className="btn btn-primary" onClick={handleAddToCartConfirm}>
            <Icon type={IconType.plus} /> Add to Cart
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductInModal;
