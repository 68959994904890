import { useEffect, useState } from 'react';
import redux, { IState } from '../../redux/redux';
import { IFlexFlowInfo, IFlexInvoiceInfoPlus, IInvoiceInfo } from './FlexFlowTypes';
import { ServerResponse } from '../../utils/Server';
import StoreX, { store } from '../../redux/oldStore';
import { toast } from 'react-toastify';
import FormFromApiData from '../FormFromApiData/FormFromApiData';
import Icon, { IconType } from '../Icon/Icon';
import Modal from '../Modal/Modal';
import Payment from '../Store/Payment';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { FormatDate } from '../../utils/Tools';

interface IProps {
  state: IState;
  flowId: string;
  recordId: string;
}

interface IFeeSection {
  Header: string;
  Body: string;
  Class: string;
  PersonInfo: boolean;
  OrderHistory: boolean;
  SpecialAwardNominations: boolean;
}

const FlexFlowFee = (props: IProps) => {
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);
  const [info, setInfo] = useState<IFlexInvoiceInfoPlus>();
  const [showAddCoupon, setShowAddCoupon] = useState<boolean>(false);
  const [showPayNow, setShowPayNow] = useState<boolean>(false);
  const [coupon, setCoupon] = useState<string>();

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (props.state.Settings) setStripePromise(loadStripe(props.state.Settings.stripePublicKey ?? ''));
  }, [props.state.Settings]);

  const init = () => {
    store.server
      .postApi<ServerResponse<IFlexInvoiceInfoPlus>>(`../FlexData/FeeInfo`, { flowId: props.flowId, recordId: props.recordId })
      .then((x) => {
        if (x.Success) {
          setInfo(x.Value);
        } else {
          toast.error(x.Message);
        }
      })
      .catch((x) => {
        toast.error(`Erroring finishing your record. ${x}`);
        console.log(x);
      });
  };

  const saveCoupon = () => {
    if (!coupon) {
      toast.warning('Please provide a coupon code.');
      return;
    }

    toast.dismiss();
    store.server
      .postApi<ServerResponse<IFlexInvoiceInfoPlus>>(`../FlexData/AddCoupon`, { flowId: props.flowId, recordId: props.recordId, code: coupon, invoiceId: info?.Info.Detail.PublicId })
      .then((x) => {
        if (x.Success) {
          if (x.Value.Message) {
            toast.warning(x.Value.Message);
            return;
          }
          setInfo(x.Value);
          setShowAddCoupon(false);
          setCoupon(undefined);
        } else {
          toast.error(x.Message);
        }
      })
      .catch((x) => {
        toast.error(`Erroring finishing your record. ${x}`);
        console.log(x);
      });
  };

  const displayInvoice = (invoice: IInvoiceInfo) => {
    return (
      <div className="flex-data-invoice">
        <div className='invoice'>
        <hr />
        <div>
          <h4>Items</h4>
          <table className="table table-striped">
            <tr>
              <th>Item</th>
              <th>Quantity</th>
              <th>Cost</th>
              <th>Total</th>
            </tr>
            {invoice.Items.map((x, i) => {
              return (
                <tr key={`iife-${i}`}>
                  <td>{x.Name}</td>
                  <td>{x.Quantity}</td>
                  <td>$ {x.Cost.toFixed(2)}</td>
                  <td>$ {(x.Cost * x.Quantity).toFixed(2)}</td>
                </tr>
              );
            })}
          </table>
        </div>
        <hr />
        <div className="flex-between">
          <h4>Coupons</h4>

          <div>
            <div className="text-center">
              {invoice.Coupons &&
                invoice.Coupons.length > 0 &&
                invoice.Coupons.map((x, i) => {
                  return (
                    <span className="coupon" key={`icicl-${i}`}>
                      {x.Name}
                    </span>
                  );
                })}
            </div>
            {!invoice.Detail.Paid && info && !info.SoldOut && <div>
              <button
                className="btn btn-default"
                onClick={() => {
                  setShowAddCoupon(true);
                }}>
                <Icon type={IconType.plus} /> Add Coupon
              </button>
            </div>}
          </div>
        </div>
        <hr />
        <div className="flex-between">
          <h4>Total</h4>
            {invoice.Detail.Paid && 
            <div className='alert alert-success text-center'>
                <div className=''><strong>
                    <Icon type={IconType.checkSquareO}/>
                    Invoice Paid!
                    </strong></div>
                    <div>
                {FormatDate(invoice.Detail.PaidOnUtc, 'date-time')}</div>
            </div>}
          <div className="text-center">
            <h4>$ {invoice.Detail.Total.toFixed(2)}</h4>
            {!invoice.Detail.Paid && info && !info.SoldOut && <button
              className="btn btn-secondary"
              onClick={() => {
                setShowPayNow(true);
              }}>
              <Icon type={IconType.payNow} /> Pay Now              
            </button>            
            }
          </div>
        </div>
        <hr />
        </div>
        {info && info.SoldOut && <>
        <div className='alert alert-info'>
          <h3>Sold Out</h3>
          Sorry, all tickets have been sold.
        </div>
        </>}
      </div>
    );
  };

  return (
    <>
      {info && (
        <div>
          {info.Header && <div dangerouslySetInnerHTML={{ __html: info.Header }} />}

          {displayInvoice(info.Info)}

          {info.Footer && <div dangerouslySetInnerHTML={{ __html: info.Footer }} />}

        {info.Info.Detail.Paid && <div className='text-center'>
            <button
                className="btn btn-secondary"
                onClick={() => {
                  window.location.href = StoreX.BuildUrl(`../app/flex/done/${props.flowId}/${props.recordId}`);
                }}>
                <Icon type={IconType.flag} /> Finish
              </button>
            </div>}

        </div>
      )}
      {showAddCoupon && (
        <Modal setModalOpen={setShowAddCoupon} title="Add Coupon" size="m">
          <div className="form-horizontal">
            <div className="form-group">
              <label className="col-sm-4 control-label" htmlFor='fff-coupon-code'>Coupon Code</label>
              <div className="col-sm-8">
                <input
                id={'fff-coupon-code'}
                  type={'text'}
                  maxLength={250}
                  value={coupon}
                  onChange={(x) => {
                    setCoupon(x.target.value);
                  }}
                  className="form-control"
                  placeholder="coupon code"
                  autoFocus={true}
                />
              </div>
            </div>
            <div className="form-group">
              <div className="col-sm-4"></div>
              <div className="col-sm-8">
                <button className="btn btn-default" onClick={saveCoupon}>
                  <Icon type={IconType.save} /> Add Coupon
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}

      {showPayNow && info && (
        <Modal title="Pay Now" size="l" setModalOpen={setShowPayNow}>
          <Elements stripe={stripePromise}>
            <Payment
              paymentAmount={info.Info.Detail.Total}
              setModalOpen={setShowPayNow}
              // fullProductResponse={props.fullProductResponse}
              orderId={info.Info.Detail.PublicId}
              nextPath={`/app/flex/done/${props.flowId}/${props.recordId}`}
              ifPaymentAmountIsZeroAutoProcess={true} 
              fullProductResponse={undefined}              
            />
          </Elements>
        </Modal>
      )}
    </>
  );
};

export default FlexFlowFee;
