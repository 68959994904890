import React, { useState, useEffect } from "react";
import { store } from "../../redux/oldStore";
import { toast } from "react-toastify";
import { setJudges } from "../../redux/judgingSetup/actions";
import { GuidToProjectDict, IntIdToProjectDict, IProject, ISettings, IUserSummary } from "./Dashboard";
import Icon, { IconType } from "../Icon/Icon";

interface ProjectListProps {
  projects: IProject[];
  settings: ISettings | undefined;
  projectsByIntId: IntIdToProjectDict;
  projectsByGuid: GuidToProjectDict;
  projectsActive: string[];
  handleProjectClick: Function;
  videoRoomJudges: any;
  judges: {};
  categories:ICategory[]|undefined;
}


interface ICategory{
  name:string;
  id:number;
}


const ProjectList = (props: ProjectListProps) => {
  const { projects, settings, videoRoomJudges, judges } = props;
  const params = new URLSearchParams(window.location.search);
  const fairId = params.get("f");
  const fairIdPara = fairId ? `f=${fairId}` : null
  const [projectSearchVal, setProjectSearchVal] = useState('')
  const [filteredProjects, setFilteredProjects] = useState<IProject[]>([])
  const [activeProjects, setActiveProjects] = useState<IProject[]>([])
  const [filteredActiveProjects, setFilteredActiveProjects] = useState<IProject[]>([])
  const [projectsActive, setProjectsActive] = useState<any>({});
  const [selectedCategory, setSelectedCategory] = useState<number>(-99);



  useEffect(() => {
    const dic = {};
    props.projectsActive.forEach((x: string) => {
      dic[x] = true;
    });
    setProjectsActive(dic);
  }, [props.projectsActive])

  useEffect(() => {
    if (!projectSearchVal && selectedCategory === -1) {
      return setFilteredProjects(projects)
    } else if (!projectSearchVal){
      return setFilteredProjects(projects.filter(x=>x && (x.CategoryId === selectedCategory || selectedCategory === -1)))
    } else {
      
      var search = projectSearchVal.toLowerCase();
      // project id or title, may need the projectIdDisplay on the Project for searching purposes
      let filteredProjects = projects.filter(project =>
        project         
        && (project.CategoryId === selectedCategory || selectedCategory === -1)
        && (
        project.Title.toLowerCase().includes(search)        
        || project.ProjectId.toLowerCase().includes(search))
      )
      setFilteredProjects(filteredProjects)
    }
  }, [projects, projectSearchVal, selectedCategory]);

  useEffect(() => {
    const theseActiveProjects = props.projectsActive.reduce((newArr, x) => {
      let project = props.projectsByGuid[x];
      let project2 = props.projectsByIntId[x];
      let realOne = project || project2;
      return newArr = [...newArr, realOne];
    }, Array<IProject>())
    setActiveProjects(theseActiveProjects)
  }, [projectsActive])

  useEffect(() => {
    if (!projectSearchVal && selectedCategory === -1) {
      return setFilteredActiveProjects(activeProjects)
    } else if (!projectSearchVal){
      return setFilteredActiveProjects(activeProjects.filter(x=>x && (x.CategoryId === selectedCategory || selectedCategory === -1)))
    }
    else {
      var search = projectSearchVal.toLowerCase();
      // project id or title, may need the projectIdDisplay on the Project for searching purposes
      let filteredProjects = activeProjects.filter(project => 
        project  
        && (project.CategoryId === selectedCategory || selectedCategory === -1)
        && (
        project.Title.toLowerCase().includes(search)
        || project.ProjectId.toLowerCase.toString().includes(search))
      )
      setFilteredActiveProjects(filteredProjects)
    }
  }, [activeProjects, projectSearchVal, selectedCategory]);

  

  const userIcon = (user?:IUserSummary) =>{
    if(!user) return <Icon type={IconType.user} />
    if(user.IsModerator) return <Icon type={IconType.balanceScaleRight} />
    if(user.IsInterpreter) return <Icon type={IconType.language} />
    if(user.IsJudge) return <Icon type={IconType.gavel} />
    if(user.IsSpecialAwardJudge) return <Icon type={IconType.gavelDuo} />
    if(user.IsStaff) return <Icon type={IconType.userCrown} />
    return <Icon type={IconType.user} />
};

  const buildProjectDiv = (project: IProject, i: number, isActive?: boolean) => {
    return (
      <div
        className="project"
        key={`${project.Id}-project-${i}`}
      >
        <div className="id click"
        onClick={e => props.handleProjectClick(e, project)}>
          {project.NumberOfTeam > 1 && <i className="fa fa-users" title="team project"></i>}
          {project.ProjectId} <i className="fal fa-ellipsis-v"></i>
        </div>
        <div className="category">{project.Category}</div>
        {settings?.virtualMeetings && <a href={`${window.location.origin}/app/video/${project.MeetingId}?${fairIdPara}`} className="link" target="_blank" title="Join Interview"><i className="fal fa-tv-retro"></i></a>}
        {settings?.virtualMeetings && <span className={`click fal fa-copy text-muted`} onClick={()=>{window.navigator.clipboard.writeText(`${window.location.origin}/app/video/${project.MeetingId}?${fairIdPara}`);}} title="copy meeting link"></span>}
        {isActive && <i className="far fa-check-circle " title={`user is active, and has a connect to their page`}></i>}
        {videoRoomJudges && judges && videoRoomJudges[project.Id] &&
          <div className="judges">
          {videoRoomJudges[project.Id].map((x: any) => {
            let judge = judges[x];
            if (judge)
              return (<div>{userIcon(judge)} {judge.FirstName} {judge.LastName}</div>);
            else return <></>
            //else return <div>Unknown: {x}</div>
          })}
          </div>}

          
        {project.ProjectLanguage && <div className="language">{project.ProjectLanguage}</div>}
        {!project.ProjectLanguage && project.StudentLanguages && <div className="language">{project.StudentLanguages}</div>}
      </div>
    )
  }

  const mapProjects = filteredProjects.map((x, i: number) => {
    if (!x) return null;
    return buildProjectDiv(x, i, projectsActive[x.Id]);
  });

  const mappedActiveProjects = filteredProjects.filter(x=>projectsActive[x.Id]).map((x, i: number) => {
    if (!x) return null;
    return buildProjectDiv(x, i, projectsActive[x.Id]);
  })



  return (
    <>
    { selectedCategory === -99 && props.categories ? <div> Click on a category tile to see projects within that category.
                <div className="category-tile-list">
                    {props.categories.map((x:ICategory, i:number)=>{
                        return <div className="category-tile" key={`category-tile-${i}`} onClick={()=>{setSelectedCategory(x.id);}}>{x.name}</div>
                    })}
                    <div className="category-tile" key={`category-tile-all`} onClick={()=>{setSelectedCategory(-1);}}>All Categories</div>
                </div>
              </div> 
      :<>
      <div className="flex-between">
        {props.categories && <h3>{selectedCategory === -1 ? <span>All Categories</span> : <span>{props.categories?.filter(x=>x.id === selectedCategory)[0].name}</span>}
        <i className="fa fa-trash-can text-danger click" title="click to remove this category filter." onClick={()=>{setSelectedCategory(-99)}}></i>
        </h3>}
        <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
        <div className="input-group grow">
          <input
            onChange={(e) => setProjectSearchVal(e.target.value)}
            value={projectSearchVal}
            className="form-control "
            placeholder="search"
          />
          <span className="input-group-addon">
            <i className="fa fa-search"></i>
          </span>
        </div>
      </div>
      <h4>Active Projects: {props.projectsActive.length}</h4>
      {props.projectsActive.length > 0
        ? <div className="online-judging-project-list">
            {mappedActiveProjects}
          </div>
        : <div className="online-judging-project-list">
          <h5>No Active Projects</h5>
        </div>


      }
      <h4>All Projects</h4>
      <div className="online-judging-project-list">
        {mapProjects}
      </div></>}
    </>);
};

export default ProjectList;
