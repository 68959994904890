import StoreX from "../../redux/oldStore";
import { Server } from "../../utils/Server";
import Icon, { IconType } from "../Icon/Icon";

const LicenseExpired = () =>{
    return (<div className="col-sm-12 flex-centered">

        <div>
        <h1><Icon type={IconType.warning} />Oops License Expired!</h1>
        <hr/>
        Looks like your license has expired. Please contact support to get this resloved. 
         <ul>
             <li><a href={StoreX.BuildUrl(`../App/Organization/Invoices`)}>See Invoice</a></li>
             <li>Email: support@zfairs.com</li>
             <li>Phone: 800-252-0903</li>
             <li>Ticket: <a href={`https://Tickets.zFairs.com`}>Tickets.zFairs.com</a></li>
         </ul>
         </div>
    </div>);
};

export default LicenseExpired;