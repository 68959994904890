import { fabric } from "fabric";
import { InputType } from "./DocumentTypes";
import { PdfSelfSignState } from "./PdfSelfSign";

declare module "fabric/fabric-impl" {

    export interface Canvas {
        getItemByAttr(attribute: keyof Object, name: string): fabric.Object | null;
        pageNumber: number;
    }

    export interface IEvent {
        pdfSelfSignState: PdfSelfSignState
    }

    export interface IObjectOptions {
        inputType?: InputType,
        _id?: string,
        isPreFill?: boolean;
    }

    export interface Object {
        _key: string;
        _username: string;
        _userId: string;
        uniqueId: string;
        inputType: InputType;
        _signerLabel: string;
        exportPNG(): Promise<[Blob | null, string]>;
        _isAnimating: boolean;
        wiggleObject(): void;
        _isRequired: boolean;
        href: string;
        _objects: Object[];
        isPreFill: boolean;
    }

    export interface Rect {
        fromObject(object: any, callback?: Function): Rect
    }
}

fabric.Canvas.prototype.getItemByAttr = function (attribute: any, name: any) {
    let object: fabric.Object | null = null;
    var objects = this.getObjects();
    for (var i = 0, len = this.size(); i < len; i++) {
        if (objects[i].get(attribute) && objects[i].get(attribute) === name) {
            object = objects[i];
            break;
        }
    }
    return object;
}

fabric.Rect.prototype.fromObject = function (object: any, callback?: Function): fabric.Rect {
    return fabric.Rect.fromObject(object)
}

fabric.Object.prototype.wiggleObject = function () {

    if (this._isAnimating) {
        return;
    }
    this._isAnimating = true;
    this.animate('left', '-=25', {
        onChange: this.canvas?.renderAll.bind(this.canvas),
        duration: 166,
        easing: fabric.util.ease.easeInOutSine,
        onComplete: () => {
            this.animate('left', '+=50', {
                onChange: this.canvas?.renderAll.bind(this.canvas),
                duration: 166,
                easing: fabric.util.ease.easeInOutSine,
                onComplete: () => {
                    this.animate('left', '-=25', {
                        duration: 166,
                        easing: fabric.util.ease.easeInOutSine,
                        onChange: this.canvas?.renderAll.bind(this.canvas),
                        onComplete: () => {
                            this._isAnimating = false;
                        }
                    });
                }
            });
        }
    });
}

fabric.Object.prototype.exportPNG = async function (): Promise<[Blob | null, string]> {
    function trimCanvas(canvas: HTMLCanvasElement) {
        let ctx = canvas.getContext('2d') || new CanvasRenderingContext2D(),
            w = canvas.width,
            h = canvas.height,
            pix = { x: [] as number[], y: [] as number[] }, n,
            imageData = ctx.getImageData(0, 0, w, h),
            fn = function (a: number, b: number) { return a - b };

        for (let y = 0; y < h; y++) {
            for (let x = 0; x < w; x++) {
                if (imageData.data[((y * w + x) * 4) + 3] > 0) {
                    pix.x.push(x);
                    pix.y.push(y);
                }
            }
        }
        pix.x.sort(fn);
        pix.y.sort(fn);
        n = pix.x.length - 1;

        w = pix.x[n] - pix.x[0];
        h = pix.y[n] - pix.y[0];
        let cut = ctx.getImageData(pix.x[0], pix.y[0], w, h);

        canvas.width = w;
        canvas.height = h;
        ctx.putImageData(cut, 0, 0);
    };


    return new Promise<[Blob | null, string]>((resolve, reject) => {
        try {
            let bound = this.getBoundingRect(),
                json = JSON.stringify(this),
                canvas = fabric.util.createCanvasElement();
            canvas.width = bound.width;
            canvas.height = bound.height;
            let fcanvas = new fabric.Canvas(canvas, { enableRetinaScaling: false });

            fabric.util.enlivenObjects([JSON.parse(json)], function (objects: fabric.Object[]) {
                objects.forEach(function (o: fabric.Object) {
                    if (o && o.top && o.left) {
                        o.top -= bound.top;
                        o.left -= bound.left;
                        fcanvas.add(o);
                    }
                });
                fcanvas.renderAll();

                let canvas = fcanvas.getElement();
                trimCanvas(canvas);

                /*
                var url = canvas.toDataURL('image/png'),
                      img = new Image();
                img.width = canvas.width;
                img.height = canvas.height;
                img.src = url;
                document.body.appendChild(img);
                */

                // This requires FileSaver.js!


                canvas.toBlob(function (blob) {
                    //save the file to disk.
                    // saveAs(blob as Blob, 'element.png');
                    let dataUrl = canvas.toDataURL();
                    resolve([blob, dataUrl]);
                }, 'image/png');

            }, "fabric");
        } catch (error) {
            reject(error);
        }
    });
};

export const loadSVGFromStringAsync = async (svg: string) => {
    const promise = new Promise<fabric.Object[]>((resolve, reject)=>{
        fabric.loadSVGFromString(svg, (result)=>{
            resolve(result);
        });
    });
    return promise;
}