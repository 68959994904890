import { useEffect, useState } from 'react';
import { IState } from '../../redux/redux';
import { IFlexFlowInfo } from './FlexFlowTypes';
import { ServerResponse } from '../../utils/Server';
import { store } from '../../redux/oldStore';
import { toast } from 'react-toastify';
import FormFromApiData from '../FormFromApiData/FormFromApiData';

interface IProps {
  state: IState;
  flowId: string;
  recordId?:string;
}

const FlexFlow = (props: IProps) => {
    const [info, setInfo] = useState<IFlexFlowInfo>();

  useEffect(() => {
    //init();
  }, []);

  const init = () => {
    store.server.postApi<ServerResponse<IFlexFlowInfo>>(`../FlexData/Info`, { flowId: props.flowId }).then((x) => {
      if (x.Success) {
        setInfo(x.Value);       
      } else {
        toast.error(x.Message);
      }
    });
  };

  const FileChanged = (targetId:string, key:string, file?:File)=>{
    if(file){
    store.server.postApi<ServerResponse<IFlexFlowInfo>>(`../FlexData/UploadImage`, { 
      flowId: props.flowId, recordId:targetId, fieldId:key }, [file]).then((x) => {
      if (x.Success) {
        toast.success(`File "${file.name}" uplaoded.`);
      } else {
        toast.error(x.Message);
      }
    });
  }
  }

  return <>
  {info && <h3>{info.flow.Name}</h3>}
  {<FormFromApiData 
  getInfoRoute={`../FlexData/Form/${props.flowId}`} 
  saveRoute={`../FlexData/SaveForm/${props.flowId}`}   
  idCalledInApi={'recordId'} 
  isNew={false}
  FileChanged={FileChanged}
  isFlex={true}
   />}
  </>;
};

export default FlexFlow;
