import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { store } from '../../redux/oldStore'
import SupportChats from './SupportChats';


interface IUserInfo{
    user:IUser;
    fairId:string;
    clientId:string;
    projectKey:string|null;
    projectId:string|null;
}

interface IUser{
    id:string;
    firstName:string;
    lastName:string;
    email:string;
    username:string;
    isStaff:boolean;
}

const ChatPage = () => {
    const [isReady, setIsReady] = useState<boolean>(false);
    const [info, setInfo] = useState<IUserInfo>();


  useEffect(()=>{
    store.server.getApi('../SupportChat/info').then((x: any)=>{
        setInfo(x.Value);
        setIsReady(true);
    });
  }, []);


  return(
    <><div className="col-sm-12">
        
      {isReady && info && <SupportChats
        clientId={info?.clientId}
        fairId={info?.fairId}
        name={`${info.user.firstName} ${info.user.lastName}`}
        personId={info.user.id}
        projectId={info.projectKey}
        socket={null}
        isSupportAgent={info.user.isStaff}        
      ></SupportChats>}
    </div>
    </>
  )
}

export default ChatPage