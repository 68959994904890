import React from 'react'

interface LabelHtmlRenderProps {
  customText: string;
}

const LabelHtmlRender = (props: LabelHtmlRenderProps) => {
  return(
    <span dangerouslySetInnerHTML={{__html: props.customText}}>
      
    </span>
  )
}

export default LabelHtmlRender