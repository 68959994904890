import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  setProjects,
  setCategories,
  setRounds,
  setJudges,
  setPage,
  setSelectedRoundIndex,
  setSelectedRound,
  setSelectedProject,
  setSelectedJudge,
  setJudgesByKey,
  setProjectsByKey,
  setJudgingRoundEditModalShow,
  setAssignmentModalShow,
  setAssignments,
  setProjectsByCategory,
} from "../../redux/judgingSetup/actions";
import "./JudgingSetupContainer.css";

import JudgingRound from "./JudgingRound";
import JudgingRoundEdit from "./JudgingRoundEdit";
import Modal from "../Modal/Modal";
import Rubric from "./RubricSetup/Rubric";
import queryString from 'query-string';
import { ServerResponse } from "../../utils/Server";
import { store } from "../../redux/oldStore";
import { IJudgingManagementInfo, IJudgingRounds, IRubricInfo } from "./JudgingInfoDataTypes";
import JudgingRoundDetails from "./judgingRoundDetails";


const JudgingSetupContainer = (props) => {
  const [fullRubricInfo, setFullRubricInfo] = useState<IRubricInfo>();
  const [selectedRubric, setSelectedRubric] = useState({});
  const [selectedTab, setSelectedTab] = useState<"info"|"rounds"|"rubic"|"rubric" >("info");
  const [info, setInfo] = useState<IJudgingManagementInfo>();
  const [timesToJudge, setTimesToJudge] = useState<number>();
  const [messageToJudges, setMessageToJudges] = useState<string>();
  const [defaultRound, setDefaultRound] = useState<number>();
  const [rounds, setRounds] = useState([]);
  const [filteredRounds, setFilteredRounds] = useState([])
  const [judgingOn, setJudgingOn] = useState(false);
  const [divisions, setDivisions] = useState();
  const [roundSearchVal, setRoundSearchVal] = useState<string>('')
  const [settings, setSettings] = useState();
  const [rubrics, setRubrics] = useState();
  const [dic, setDic] = useState<any>({});
  const [roundCategories, setRoundCategories] = useState([])  
  const [roundDivisions, setRoundDivisions] = useState([])  
  const [roundTags, setRoundTags] = useState([])
  const [judgeTags, setJudgeTags] = useState([])
  const init = () => {
    store.server.getApi<ServerResponse<IJudgingManagementInfo>>("../JudgingManagement/info").then((x) => {
      if(x.Success){
      setInfo(x.Value);
      let i = x.Value;

      setTimesToJudge(i.timeToJudge);
      setDefaultRound(i.currentRound);
      setMessageToJudges(i.messageToJudges);
      setJudgingOn(i.judgingOn);
      setSettings(i.settings);
      } else {
        toast.error(`Error getting judging information: ${x.Message}`);
      }
    });

    store.server
      .postApi<ServerResponse<any>>("../JudgingManagement/JudgingRounds", {})
      .then((res) => {

        if(res.Success){
        if(res.Value.Rubrics){
          dic.rubrics = {};
          res.Value.Rubrics.forEach(x=>{
            dic.rubrics[x.id] = x.name;
          })
        };
        
        setDic(dic);
        props.setRounds(res.Value.Rounds);
        setRounds(res.Value.Rounds);
        setRubrics(res.Value.Rubrics);
        setRoundCategories(res.Value.Categories);
        setRoundDivisions(res.Value.Divisions);
        setRoundTags(res.Value.Tags);
        setJudgeTags(res.Value.JudgeTags)

      }
      else{
        toast.error(`Error getting judging information: ${res.Message}`);
      }
      })
      .catch((err) => console.error(err));

    store.server.postApi<ServerResponse<any>>("../JudgingManagement/Projects", {}).then((res) => {
      
      if(res.Success){
      props.setProjects(res.Value.Projects);
      props.setCategories(res.Value.Categories);
      props.setJudges(res.Value.Judges);
      setDivisions(res.Value.Divisions);
      }else{
      toast.error(`Error getting judging information: ${res.Message}`);
      }
    });

    //api call here. get Rubric info
    store.server
      .postApi<ServerResponse<IRubricInfo>>("../Rubric/Info", {})
      .then((res) => {
        if(res.Success){
        setFullRubricInfo(res.Value);
      }else{
        toast.error(`Error getting judging information: ${res.Message}`);
        }
      })
      .catch((err) => {
        console.error(err);
        toast.warning("did not get rubric info");
      });
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    let querystuff = getQueryStuff();
    if (querystuff.tab) {
      if (querystuff.tab && ["info", "rounds", "rubic"].includes(querystuff.tab+'')) {
        setSelectedTab(querystuff.tab as "info" | "rounds" | "rubic");
      }
    }
  }, [window.location]);

  const getQueryStuff = () => {
    const queryValues = queryString.parse(window.location.search);
    return queryValues;
  }

  useEffect(() => {
    let judgesByKey = {};
    props.judges.forEach((judge) => (judgesByKey[judge.Id] = judge));
    props.setJudgesByKey(judgesByKey);
  }, [props.judges]);

  useEffect(() => {
    let projectsByKey = {};
    props.projects.forEach(
      (project) => (projectsByKey[project.ProjectIntId] = project)
    );
    props.setProjectsByKey(projectsByKey);
  }, [props.projects]);

  useEffect(() => {
    let projectsByCategory = {};
    props.categories.forEach((category) => {
      projectsByCategory[category.Id] = props.projects.filter(
        (project) => project.Category?.Id === category.Id
      );
    });
    props.setProjectsByCategory(projectsByCategory);
  }, [props.categories]);

  useEffect(() => {
    if (!roundSearchVal) {
      return setFilteredRounds(props.rounds)
    } else {
      // off round name
      let filteredRoundsTemp = props.rounds.filter(round => round.Name.toLowerCase().includes(roundSearchVal.toLowerCase()))
      setFilteredRounds(filteredRoundsTemp)
    }
  }, [props.rounds, roundSearchVal])

  const closeAllModals = () => {
    props.setJudgingRoundEditModalShow(false);
    props.setAssignmentModalShow(false);
  };

  const roundOpenEdit = (rd, index) => {
    console.log("open edit this round", rd, index);
    props.setSelectedRound(rd);
    props.setSelectedRoundIndex(index);
    props.setJudgingRoundEditModalShow(true);
  };

  const roundSave = (rd, index) => {
    let request = {
      RoundName: rd.Name,
      RoundValue: rd.RoundValue,
      IsSubround: rd.IsSubround,
      UseRubic: rd.useRubic,
      Id: rd.Id || 0,
      ExtraJson: JSON.stringify(rd.Values),
    };
    let msg:string[] = [];
    if (!rd.Name) msg.push("Round name is required.");
    if (msg.length > 0) {
      toast.warning(msg.join("<br/>"));
      return;
    }
    let callback = (data) => {
      console.log('full list of rounds, the res.Value from /roundSave', props.rounds, data);
      toast.success(`Round "${rd.Name}" saved.`);
      let updatedRounds
      if (index == -1) {
        updatedRounds = [...props.rounds, data]
        props.setRounds(updatedRounds);
      } else {
        updatedRounds = props.rounds.map((round, i) => {
          return round.Id === data.Id ? data : round;
        })
        props.setRounds(updatedRounds);
      }
      console.log('list of rounds after adding saved one', updatedRounds)
      props.setJudgingRoundEditModalShow(false);
    };

    store.server
      .postApi<ServerResponse<any>>("../JudgingManagement/roundSave", request)
      .then((res) => {
        if(res.Success){
          callback(res.Value)
      }else{
        toast.error(`Error getting judging information: ${res.Message}`);
        }
      })
      .catch((err) => console.error(err));
  };

  const roundRemove = (rd, index) => {
    if (
      !window.confirm(`Are you sure you want to remove "${rd.Name}" round?`)
    ) {
      return;
    }
    let callback = (data) => {
      props.setRounds(props.rounds.filter((round) => round.Id !== rd.Id));
      toast.info(`Round "${rd.Name}" has been removed.`);
    };
    store.server
      .postApi("../JudgingManagement/roundRemove", { roundId: rd.Id })
      .then((res) => callback(res))
      .catch((err) => console.error(err));
  };

  const roundOpenDetails = (rd, index) => {
    closeAllModals();
    props.setSelectedRound(
      JSON.parse(JSON.stringify(rd)) || {
        Values: {},
      }
    );
    props.setSelectedRoundIndex(index);
    props.setPage("round-details");
  };

  const roundDetailsExit = () => {
    closeAllModals();
    setSelectedRubric({});
    props.setPage("round");
  };

  const rubricOpenDetails = (rubric) => {
    closeAllModals();
    setSelectedRubric(rubric);
    props.setPage("rubric-details");
  };

  const saveInfo = () => {
    store.server.postApi<any>("../JudgingManagement/SaveInfo", {
      message: messageToJudges,
      active: judgingOn,
      round: defaultRound,
      times: timesToJudge
    }).then((x) => {

      if (x.Success) {
        toast.success("Online judging settings saved");
      }

    });
  };

  const mappedJudgingRounds = filteredRounds.map((r:any, index) => {
    return (
      <JudgingRound
        key={`${r.Id}${index}`}
        index={index}
        round={r}
        openEdit={roundOpenEdit}
        openRoundDetails={roundOpenDetails}
        rubrics={dic.rubrics}
      />
    );
  });

  const mappedRubrics = fullRubricInfo?.rubrics?.map((rubric, i) => {
    return (
      <Rubric
        rubric={rubric}
        key={`${rubric.Id}${i}`}
        openEdit={rubricOpenDetails}
      />
    );
  });

  return (
    <div className="bumper-sides-l">
      {props.page === "round" && (
        <>
          <ul className="nav nav-tabs " role="tablist">
            <li
              role="presentation"
              className={selectedTab === "info" ? "active" : ""}
            >
              <a onClick={() => setSelectedTab("info")}>
                <i className="fad fa-info-circle"></i>
                Info
              </a>
            </li>
            <li
              role="presentation"
              className={selectedTab === "rounds" ? "active" : ""}
            >
              <a onClick={() => setSelectedTab("rounds")}>
                <i className="fal fa-project-diagram"></i>
                Rounds
              </a>
            </li>
            <li
              role="presentation"
              className={selectedTab === "rubric" ? "active" : ""}
            >
              <a onClick={() => setSelectedTab("rubric")}>
                <i className="fad fa-abacus"></i>
                Rubric
              </a>
            </li>
          </ul>

          <div className="headroom">
            {selectedTab === "rounds" && (
              <>
                <div>
                  <div className='flex-between'>
                    <div>
                      <h3>Rounds</h3>
                      You must have at least one round to use our judging features.
                      <ul>
                        <li>Edit a rounds settings by clicking on the <i className="fa fa-edit"></i> icon. </li>
                        <li>Make Assignments by clicking the <i className="fa fa-eye"></i> icon.</li>
                      </ul>
                    </div>
                    <div>
                      <div className="input-group">
                        <input
                          // v-model="projectSearchValue"
                          onChange={(e) => setRoundSearchVal(e.target.value)}
                          className="form-control"
                          id="z-index-control1"
                          placeholder="search"
                          value={roundSearchVal}
                        />
                        <span className="input-group-addon">
                          <i className="fa fa-search"></i>
                        </span>
                      </div>
                    </div>


                  </div>
                  <div className="round-container head-room">
                    {mappedJudgingRounds}
                    <JudgingRound
                      template={true}
                      round={null}
                      index={-1}
                      openEdit={roundOpenEdit}
                      rubrics={dic.rubrics}
                    />
                  </div>
                </div>
              </>
            )}
            {selectedTab === "rubric" && (
              <>
                <div>
                  <div className="flex wrap">
                    {mappedRubrics}
                    <Rubric rubric={null} openEdit={rubricOpenDetails} />
                  </div>
                </div>
              </>
            )}
            {selectedTab === "info" && info && (
              <>
                <div className="headroom-l">
                  <div className="form-horizontal">

                    <div>
                      <h3>Online Judging Setup</h3>
                    Online judging must be first enabled before it can be used, you can turn judging on or off by adjusting this form. You also need to define at least one judging <strong>round</strong> and a default <strong>rubric</strong>.

                    <div className="alert alert-warning head-room">
                        <i className="fad fa-exclamation-triangle"></i>
                    It may take up to 15 minutes before changes are shown in Online Judging
                    </div>
                    </div>

                    <div className="head-room"></div>
                    <h4>Rounds</h4>

                  Define the different rounds that your entries will be judged/scored. Under rounds is where you can assign judges.
                  If you are going to do any time of online judging you must define at least one round.

                  <div className="head-room"></div>
                    <h4>Rubric</h4>

                  Click on the Rubric tab to define your judging criteria. You can create multiple rubrics based on your needs, you can set a rubric to a given category or you can even set a rubric to a specific round. You must have a rubric if you are going to use online judging.

                  <div className="head-room"></div>
                    <h4>Basic Setup</h4>

                  Make sure to turn on judging by checking the checkbox next to Judging on.


                    <div className="from-group head-room">
                      <label
                        className="col-sm-3 control-label"
                        htmlFor="judeging-setup-message"
                      >
                        Message to judges
                      </label>
                      <div className="col-sm-7">
                        <textarea
                          id="judeging-setup-message"
                          className="form-control"
                          onChange={(e) => {
                            setMessageToJudges(e.target.value);
                          }}
                          value={messageToJudges}
                          rows={7}
                        ></textarea>
                      </div>
                    </div>
                    <div className="from-group">
                      <label
                        className="col-sm-3 control-label"
                        htmlFor="judeging-setup-times"
                      >
                        Times to judge
                      </label>
                      <div className="col-sm-2">
                        <input
                          type="number"
                          min="1"
                          max="100"
                          step="1"
                          value={timesToJudge}
                          onChange={(e) => {
                            setTimesToJudge(+e.target.value);
                          }}
                          id="judeging-setup-times"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="clearfix"></div>
                    <div className="from-group">
                      <label
                        className="col-sm-3 control-label"
                        htmlFor="judeging-setup-round"
                      >
                        Default round
                      </label>
                      <div className="col-sm-6">
                        <select
                          onChange={(e) => {
                            setDefaultRound(+e.target.value);
                          }}
                          id="judeging-setup-round"
                          className="form-control"
                          value={defaultRound}
                        >
                          <option value={-1}>select</option>
                          {rounds.map((x:any,i) => {
                            return (
                              <option key={i}
                                value={x.Id}                              
                              >
                                {x.Name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                    <div className="from-group">
                      <div className="col-sm-6 col-sm-offset-3">
                        <input
                          type="checkbox"
                          min="1"
                          max="100"
                          step="1"
                          checked={judgingOn}
                          onChange={(e) => {
                            setJudgingOn(e.target.checked);
                          }}
                          id="judeging-setup-on"
                        />

                        <label
                          className="control-label"
                          htmlFor="judeging-setup-on"
                        >
                          Judging on
                        </label>
                      </div>
                    </div>
                    <div className="from-group">
                      <div className="col-sm-6 col-sm-offset-3">
                        <br />
                        <button type="button" className="btn btn-primary" onClick={saveInfo}><i className="fad fa-save"></i> Save</button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
      {props.page === "round-details" && (
        <div>
          <JudgingRoundDetails
            roundIndex={props.selectedRoundIndex}
            round={props.selectedRound}
            projects={props.projects}
            categories={props.categories}
            divisions={divisions}
            judges={props.judges}
            exit={roundDetailsExit}
            judgesByKey={props.judgesByKey}
            projectsByKey={props.projectsByKey}
          /> 
        </div>
      )}
      {props.judgingRoundEditModalShow && (
        <Modal
          setModalOpen={props.setJudgingRoundEditModalShow}
          title={
            props.selectedRound
              ? `Manage Round: ${props.selectedRound.Name}`
              : "New Round"
          }
          size="l"
        >
          <JudgingRoundEdit
            round={props.selectedRound}
            index={props.selectedRoundIndex}
            setModalOpen={props.setJudgingRoundEditModalShow}
            roundEditSave={roundSave}
            roundEditRemove={roundRemove}
            fullRubricInfo={fullRubricInfo}
            roundCategories={roundCategories}
            roundDivisions={roundDivisions}
            roundTags={roundTags}
            judgeTags={judgeTags}
            allRounds={rounds}
            useMarkReadyToJudge={info?.UseMarkReadyToJudge}
          />
        </Modal>
      )}
      
    </div>
  );
};

const mapStateToProps = (reduxState) => {
  return reduxState.judgingSetup;
};

const actions = {
  setProjects,
  setCategories,
  setRounds,
  setJudges,
  setJudgesByKey,
  setPage,
  setSelectedRoundIndex,
  setSelectedRound,
  setSelectedProject,
  setSelectedJudge,
  setProjectsByKey,
  setJudgingRoundEditModalShow,
  setAssignmentModalShow,
  setAssignments,
  setProjectsByCategory,
};

export default connect(mapStateToProps, actions)(JudgingSetupContainer);

