import React, { FunctionComponent, useState } from "react"
import { toast } from "react-toastify";
import { convertImagesToPdf } from "../../utils/PdfConverter"
import LoadingCog from "../LoadingCog/LoadingCog";
import "./PdfConverter.css";


export type PdfConverterProps = {
    uploadFileHook: (blobUrl: string) => void
}

const PdfConverter: FunctionComponent<PdfConverterProps> = ({uploadFileHook}) => {

    const [pdfUrl, setPdfUrl] = useState("");
    const [combiningImages, setCombiningImages] = useState(false);

    const fileChosen = async (evt: React.ChangeEvent<HTMLInputElement>) => {
        handleFiles(evt.target.files)
    }

    const handleFiles = async (files?: FileList | null) => {
        try {
            setPdfUrl("");
            setCombiningImages(true);
            if (!files) {
                return;
            }
            // const pdfDataUrl = await ConvertImagesToPdf(evt.target.files, "newCombinedFile.pdf");
            // const url = `${await convertImagesToPdf(evt.target.files, "newCombinedFile.pdf")}#toolbar=1`;
            const url = `${await convertImagesToPdf(Array.from(files), "newCombinedFile.pdf")}`;
            setPdfUrl(url);

        } catch (error) {
            console.error(error);
            toast.error("There was a problem combining the files you chose.");
        } finally {
            setCombiningImages(false);
        }
    }

    const handleDragEnter = (event: React.DragEvent) => {
        preventDefaults(event);
        highlight(event);
    }

    const handleDragOver = (event: React.DragEvent) => {
        preventDefaults(event);
        highlight(event);
    }

    const handleDragLeave = (event: React.DragEvent) => {
        preventDefaults(event);
        unHighlight(event);
    }

    const handleDrop = (event: React.DragEvent) => {
        preventDefaults(event);
        unHighlight(event);
        const dataTransfer = event.dataTransfer;
        const files = dataTransfer.files;
        handleFiles(files);
    }

    const preventDefaults = (event: React.DragEvent | Event) => {
        event.preventDefault();
        event.stopPropagation();
    }

    const highlight = (event: React.DragEvent) => {
        event.currentTarget.classList.add('highlight');
    }

    const unHighlight = (event: React.DragEvent) => {
        event.currentTarget.classList.remove('highlight');
    }


    return (
        <>
            <div id="drop-area" onDragEnter={handleDragEnter} onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop}>
                <input id="imageChooser" type="file" accept=".jpg,.jpeg,.png" onChange={fileChosen} multiple onClick={(evt) => (evt.target as HTMLInputElement).value = ""} />
                <label className="button" htmlFor="imageChooser">Choose images to combine</label>
            </div>
            {/* <input type="file" accept="image/*"onChange={fileChosen} multiple onClick={(evt) => (evt.target as HTMLInputElement).value = ""} /> */}
            {/* {pdfUrl && <embed src={pdfUrl} style={{height:"100%", width:"50%"}} />} */}
            {combiningImages ?
                <LoadingCog loadingMessage="Combining Images" /> :
                pdfUrl &&
                <>
                    <embed src={pdfUrl} width="500" height="500" />
                    <button className="button" onClick={()=>uploadFileHook(pdfUrl)}>Upload</button>
                </>
            }
        </>
    )
}

export default PdfConverter