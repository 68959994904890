import { useEffect, useState } from "react";
import StoreX from "../../../redux/oldStore";
import Icon, { IconType } from "../../Icon/Icon";
import BranchPortal from "./BranchPortal";

interface IOutsideBranchProps {}

const OutsideBranch = (props: IOutsideBranchProps) => {
  const [branch, setBranch] = useState<string>();
  const [form, setForm] = useState<any>({ branchType: "[sales-site]" });

  useEffect(() => {
    let branchType = StoreX.GetQueryStringValue("type");
    if (branchType) {
      setBranch(branchType);
      setForm({ ...form, branchType: branchType });
    } else {
        setBranch('[sales-site]');
    }
  }, []);

  return (
    <>
        {branch && <div className="branch-container-outside"><BranchPortal type={branch} /></div>}
    </>
  );
};

export default OutsideBranch;
