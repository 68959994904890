import {
  IRound,
  SET_ROUNDS
} from './types'


let initialState = {
  rounds: ([] as IRound[]),

}

export default function reducer(state = initialState, action) {
  const { type, payload } = action
  switch(type) {
    case SET_ROUNDS:
      return Object.assign({}, state, {
        rounds: payload
      })
    default:
      return state
  }
}