import React, { useState, FunctionComponent, useEffect } from 'react'
import "tui-image-editor/dist/tui-image-editor.css";
import ImageEditor from "@toast-ui/react-image-editor";
import icona from "tui-image-editor/dist/svg/icon-a.svg";
import iconb from "tui-image-editor/dist/svg/icon-b.svg";
import iconc from "tui-image-editor/dist/svg/icon-c.svg";
import icond from "tui-image-editor/dist/svg/icon-d.svg";
import { getObjectUrl, getResizedImageObjectUrl } from './ImageEditorExtensions';
import Icon, { IconType } from '../Icon/Icon';


interface ImageCroppingProps {
  imageUrl: string,
  imageName: string,
  saveImage: (objectUrl: string) => void,
  imageOtherInfo: any;
}

const myTheme = {
  "header.display": "none",
  "menu.backgroundColor": "white",
  "common.backgroundColor": "",
  "downloadButton.backgroundColor": "white",
  "downloadButton.borderColor": "white",
  "downloadButton.color": "black",
  "menu.normalIcon.path": icond,
  "menu.activeIcon.path": iconb,
  "menu.disabledIcon.path": icona,
  "menu.hoverIcon.path": iconc,
}


const EventAppImageEditor: FunctionComponent<ImageCroppingProps> = (props: ImageCroppingProps) => {
  // const [imageSrc, setImageSrc] = useState("");
  const imageEditor = React.createRef<typeof ImageEditor>();

  const handleSaveAndClose = async (e) => {
    e.preventDefault();
    const imageEditorInst: tuiImageEditor.ImageEditor = imageEditor.current.imageEditorInst;
    // const data = imageEditorInst.toDataURL();
    const fileExtension = props.imageName.split('.').pop();
    const conversionOptions: tuiImageEditor.IToDataURLOptions = fileExtension === "jpg" || fileExtension === "jpeg" ? {
      format: "jpeg",
      quality: 1
    } : { format: "png" };
    // const url = await getObjectUrl(imageEditorInst);
    const url = imageEditorInst.toDataURL(conversionOptions);
    const objectUrl = await getObjectUrl(imageEditorInst, conversionOptions.format, conversionOptions.quality)
    const response = await fetch(objectUrl);
    // const blob = await response.blob();
    // FileDownload(blob, props.imageName); //maybe we need to download it later?
    // props.saveImage(await blobToDataUrlAsync(blob));
    props.saveImage(url);
  };

  const [resizedImageUrl, setResizedImageUrl] = useState("");

  useEffect(() => {
    getResizedImageObjectUrl(props.imageUrl).then(x => {
      setResizedImageUrl(x);
    });
  }, [props.imageUrl]);

  let styleText = '.tui-image-editor-container { overflow: auto; }'



  return (
    <>
      {resizedImageUrl &&
        <div className="image-editor">
          <div className="grow">
          <ImageEditor
            includeUI={{
              loadImage: {
                path: resizedImageUrl,
                name: props.imageName,
              },
              theme: myTheme,
              menu: ["crop", "flip", "rotate"],//, "shape", "draw", "text"],
              initMenu: "",
              uiSize: {
                 height: `calc(100% - 40px)`,
               },
              menuBarPosition: "bottom",
            }}
            cssMaxHeight={window.innerHeight}
            cssMaxWidth={window.innerWidth}
            selectionStyle={{
              cornerSize: 20,
              rotatingPointOffset: 70,
            }}
            strokeWidth={5}
            usageStatistics={true}
            ref={imageEditor}
          />
          </div>
          <div className=''>
            <button
              className='btn btn-success'
              onClick={handleSaveAndClose}
            >
              <Icon type={IconType.save} /> Save & Close
            </button>
          </div>
        </div>
      }
      <style id="editor-style">
        {styleText}
      </style>
    </>
  )
}

export default EventAppImageEditor