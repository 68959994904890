import { useParams } from 'react-router-dom';
import { IState, ReduxMap } from '../../redux/redux';
import FlexFlowDone from '../../Components/FlexFlow/FlexFlowDone';

interface IProps extends IState {}


const FlexFlowPageDone = (props: IProps) => {
  const {flowId, recordId} = useParams();

  return (
    <>
      {props.IsReady && flowId && recordId &&
        <FlexFlowDone state={props} flowId={flowId} recordId={recordId} />}
    </>
  );
};

export default ReduxMap(FlexFlowPageDone);
