import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { IState } from '../../../redux/redux';
import { useEffect, useState } from 'react';
import { IFlexContent, IFlexDataItem, IFlexSetupFlowInfo, IFlexPage, IFlexSection } from '../FlexFlowTypes';
import { ServerResponse } from '../../../utils/Server';
import { store } from '../../../redux/oldStore';
import { toast } from 'react-toastify';
import Button from '../../_Core/Button';
import Modal from '../../Modal/Modal';
import ConfigFormEditor from '../../Setup/ConfigForm/ConfigFormEditor';
import { IConfigItemSummary } from '../../Setup/ConfigForm/ConfigFormTypes';
import Icon, { IconType } from '../../Icon/Icon';

interface IProps {
  state: IState;
  flowId: string;
}

const FlexFlowManager = (props: IProps) => {
  const [info, setInfo] = useState<IFlexSetupFlowInfo>();
  const [showAddPage, setShowAddPage] = useState<boolean>(false);
  const [showAddSection, setShowAddSection] = useState<boolean>(false);
  const [showAddContent, setShowContent] = useState<boolean>(false);

  const [showEditPage, setShowEditPage] = useState<boolean>(false);
  const [showEditSection, setShowEditSection] = useState<boolean>(false);
  const [showEditContent, setShowEditContent] = useState<boolean>(false);
  const [showEditFlow, setShowEditFlow] = useState<boolean>(false);
  const [editIdPage, setEditIdPage] = useState<IConfigItemSummary>();
  const [editIdSection, setEditIdSection] = useState<IConfigItemSummary>();
  const [editIdContent, setEditIdContent] = useState<IConfigItemSummary>();
  const [editFlow, setEditFlow] = useState<IConfigItemSummary>();

  const [selectedChild, setSelectedChild] = useState<IFlexDataItem>({});
  const [pagesDic, setPagesDic] = useState<Record<string, IFlexPage>>({});
  const [sectionsDic, setSectionsDic] = useState<Record<string, IFlexSection>>({});
  const [contentsDic, setContentsDic] = useState<Record<string, IFlexContent>>({});

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    store.server.postApi<ServerResponse<IFlexSetupFlowInfo>>(`../FlexManager/Info`, { flowId: props.flowId }).then((x) => {
      if (x.Success) {
        setInfo(x.Value);
        let pdic = {};
        x.Value.pages.forEach((i) => {
          pdic[i.PublicId ?? ''] = i;
        });
        setPagesDic(pdic);
        let sdic = {};
        x.Value.sections.forEach((i) => {
          sdic[i.PublicId ?? ''] = i;
        });
        setSectionsDic(sdic);
        console.log(sdic);
        let cdic = {};
        x.Value.content.forEach((i) => {
          cdic[i.PublicId ?? ''] = i;
        });
        setContentsDic(cdic);
      } else {
        toast.error(x.Message);
      }
    });
  };

  const saveFlowChild = () => {
    toast.dismiss();
    const errors: string[] = [];
    if (!selectedChild.ChildPublicId) errors.push(`You must select the page to add.`);
    if (errors.length > 0) {
      toast.warning(
        <>
          <ul>
            {errors.map((x, i) => {
              return <li key={`ffm-sfc-${i}`}>{x}</li>;
            })}
          </ul>
        </>
      );
      return;
    }

    //call server
    store.server.postApi<ServerResponse<IFlexSetupFlowInfo>>(`../FlexManager/SaveFlowsPage`, { ...selectedChild, FlowId: props.flowId }).then((x) => {
      if (x.Success) {
        setInfo(x.Value);
        setShowAddPage(false);
      } else {
        toast.error(x.Message);
      }
    });
  };
  const removeFlowChild = () => {
    toast.dismiss();
    if (!selectedChild.ChildId) {
      setShowAddPage(false);
      return;
    }

    let child = pagesDic[selectedChild.ChildPublicId ?? '']?.Name ?? 'Unknown';

    if (!window.confirm(`Are you sure you want to remove page "${child}" from this flow?`)) {
      return;
    }

    //call server
    store.server.postApi<ServerResponse<IFlexSetupFlowInfo>>(`../FlexManager/removeFlowsPage`, { ...selectedChild, FlowId: props.flowId }).then((x) => {
      if (x.Success) {
        setInfo(x.Value);
        setShowAddPage(false);
      } else {
        toast.error(x.Message);
      }
    });
  };

  const savePageChild = () => {
    toast.dismiss();
    const errors: string[] = [];
    if (!selectedChild.ChildPublicId) errors.push(`You must select the section to add.`);
    if (errors.length > 0) {
      toast.warning(
        <>
          <ul>
            {errors.map((x, i) => {
              return <li key={`ffm-sfc-${i}`}>{x}</li>;
            })}
          </ul>
        </>
      );
      return;
    }

    //call server
    store.server.postApi<ServerResponse<IFlexSetupFlowInfo>>(`../FlexManager/SavePagesSection`, { ...selectedChild, FlowId: props.flowId }).then((x) => {
      if (x.Success) {
        setInfo(x.Value);
        setShowAddSection(false);
      } else {
        toast.error(x.Message);
      }
    });
  };
  const removePageChild = () => {
    toast.dismiss();
    if (!selectedChild.ChildId) {
      setShowAddSection(false);
      return;
    }
    if (!window.confirm(`Are you sure you want to remove this section?`)) {
      return;
    }

    //call server
    store.server.postApi<ServerResponse<IFlexSetupFlowInfo>>(`../FlexManager/removePagesSection`, { ...selectedChild, FlowId: props.flowId }).then((x) => {
      if (x.Success) {
        setInfo(x.Value);
        setShowAddSection(false);
      } else {
        toast.error(x.Message);
      }
    });
  };
  
  const removePageChildHard = (child:IFlexDataItem)=>{
    store.server.postApi<ServerResponse<IFlexSetupFlowInfo>>(`../FlexManager/removePagesSection`, { ...child, FlowId: props.flowId }).then((x) => {
      if (x.Success) {
        setInfo(x.Value);
      } else {
        toast.error(x.Message);
      }
    });
  };

  const saveSectionChild = () => {
    toast.dismiss();
    const errors: string[] = [];
    if (!selectedChild.ChildPublicId) errors.push(`You must select the content to add.`);
    if (errors.length > 0) {
      toast.warning(
        <>
          <ul>
            {errors.map((x, i) => {
              return <li key={`ffm-sfc-${i}`}>{x}</li>;
            })}
          </ul>
        </>
      );
      return;
    }

    //call server
    store.server.postApi<ServerResponse<IFlexSetupFlowInfo>>(`../FlexManager/saveSectionsContent`, { ...selectedChild, FlowId: props.flowId }).then((x) => {
      if (x.Success) {
        setInfo(x.Value);
        setShowContent(false);
      } else {
        toast.error(x.Message);
      }
    });
  };
  const removeSectionChild = () => {
    toast.dismiss();
    if (!selectedChild.ChildId) {
      setShowContent(false);
      return;
    }
    if (!window.confirm(`Are you sure you want to remove this content?`)) {
      return;
    }

    //call server
    store.server.postApi<ServerResponse<IFlexSetupFlowInfo>>(`../FlexManager/removeSectionsContent`, { ...selectedChild, FlowId: props.flowId }).then((x) => {
      if (x.Success) {
        setInfo(x.Value);
        setShowContent(false);
      } else {
        toast.error(x.Message);
      }
    });
  };

  const deleteContentChild =(child:IFlexDataItem) =>{
    store.server.postApi<ServerResponse<IFlexSetupFlowInfo>>(`../FlexManager/removeSectionsContent`, { ...child, FlowId: props.flowId }).then((x) => {
      if (x.Success) {
        setInfo(x.Value);
        setShowContent(false);
      } else {
        toast.error(x.Message);
      }
    });
  };

  return (
    <>
      {info && (
        <>
          <div className="flex-between">
            <div>
              <h3>{info.flow.Name}</h3>
              <div className="small">Flex Flow</div>
            </div>
            <div className="text-right">
              <div className='btn-group'>
                <Button type="refresh" onClick={init} text='Refresh' />
                <Button
                                        type="edit"
                                        text="Edit Flow"
                                        onClick={() => {
                                          setEditFlow({Id:info.flow.PublicId+'', Name:info.flow.Name, Type:'flex-flow'});
                                          setShowEditFlow(true);
                                        }}
                                      />
                <Button
                                        type="save"
                                        text="New Content"
                                        onClick={() => {
                                          setShowEditContent(true);
                                          setEditIdContent({ Id: '0', Name: '', Type: 'flex-content' });
                                        }}
                                      />
                
                <Button
                            type="save"
                            text="New Section"
                            onClick={() => {
                              setShowEditSection(true);
                              setEditIdSection({ Id: '0', Name: '', Type: 'flex-section' });
                            }}
                          />
                <Button
                  type="save"
                  text="New Page"
                  onClick={() => {
                    setShowEditPage(true);
                    setEditIdPage({ Id: '0', Name: '', Type: 'flex-page' });
                  }}
                />
                <Button
                  type="new"
                  text="Add Page"
                  onClick={() => {
                    setShowAddPage(true);
                    setSelectedChild({ ParentPublicId: props.flowId });
                  }}
                />
              </div>
              <div></div>
            </div>
          </div>
          <div className="flex-flow-item-list">
            {info.flow.PageFlow.map((x, i) => {
              let child = pagesDic[x.ChildPublicId ?? ''];
              if (child) {
                let sections = info.pagesSections.filter((s) => s.ParentPublicId === child.PublicId);
                return (
                  <div className="list-item" key={`ffm-pil-${i}`}>
                    <div className="flex-between">
                      <div className="flex">
                        <Button
                          type="edit"
                          iconOnly={true}
                          onClick={() => {
                            setEditIdPage({ Id: child.PublicId, Name: child.Name, Type: 'flex-page' });
                            setShowEditPage(true);
                          }}
                        />
                        <div className="title">{child.Name}</div>
                      </div>
                      <div> Vissible: 
                        {child.ShowDuringRecordCreation && <Icon type={IconType.new} title='Shown during record creation'/> }
                        {child.ShowDuringRecordManagement && <Icon type={IconType.configure} title='Shown during record management'/> }
                        {child.ShowOnlyDuringFirstTimeLogin && <Icon type={IconType.firstTime} title='Shown during first time login'/> }
                        {child.ShowOnlyDuringRecordMissingRequiredInfo && <Icon type={IconType.missing} title='Shown during missing information'/> }
                      </div>
                      <div className="text-right">
                        <div className="flex">
                          <div className="order">{x.SortOrder}</div>
                          <Button
                            type="edit"
                            iconOnly={true}
                            onClick={() => {
                              setShowAddPage(true);
                              setSelectedChild(x);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="item-body">
                      <div className="flex-between">
                        <div>
                          <h4>Sections</h4>
                        </div>
                        <div>
                          <Button
                            type="new"
                            extraSmall={true}
                            text="Add Section"
                            onClick={() => {
                              setShowAddSection(true);
                              setSelectedChild({ ParentPublicId: child.PublicId });
                            }}
                          />
                        </div>
                      </div>
                      <div className="flex-flow-section-list">
                        {sections.map((s, si) => {
                          let section = sectionsDic[s.ChildPublicId ?? ''];
                          if (section) {
                            let contents = info.sectionsContents.filter((s) => s.ParentPublicId === section.PublicId);
                            return (
                              <div className="list-item" key={`ffm-pil-${i}-${si}`}>
                                <div className="flex-between">
                                  <div className="flex">
                                    <Button
                                      type="edit"
                                      iconOnly={true}
                                      onClick={() => {
                                        setEditIdSection({ Id: section.PublicId, Name: section.Name, Type: 'flex-section' });
                                        setShowEditSection(true);
                                      }}
                                    />
                                    <div className="name">{section.Name}</div>
                                  </div>
                                  <div className="text-right">
                                    <div className="flex">
                                      <div className="order">{s.SortOrder}</div>

                                      <Button
                                        type="edit"
                                        iconOnly={true}
                                        onClick={() => {
                                          setShowAddSection(true);
                                          setSelectedChild(s);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="item-body">
                                  <div className="flex-between">
                                    <div>
                                      <h5>Content & Inputs</h5>
                                    </div>
                                    <div>
                                      <Button
                                        type="new"
                                        extraSmall={true}
                                        text="Add Content"
                                        onClick={() => {
                                          setShowContent(true);
                                          setSelectedChild({ ParentPublicId: section.PublicId });
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="flex-flow-section-list">
                                    {contents.map((c, ci) => {
                                      let content = contentsDic[c.ChildPublicId ?? ''];
                                      if (content) {
                                        return (
                                          <div className="list-item" key={`ffm-pil-${i}-${si}-${ci}`}>
                                            <div className="flex-between">
                                              <div className="flex">
                                                <Button
                                                  type="edit"
                                                  iconOnly={true}
                                                  onClick={() => {
                                                    setEditIdContent({ Id: content.PublicId, Name: content.Name, Type: 'flex-content' });
                                                    setShowEditContent(true);
                                                  }}
                                                />
                                                <div className="name">{content.Name}</div>
                                              </div>
                                              <div className="text-right">
                                                <div className="flex">
                                                  <div className="order">{c.SortOrder}</div>
                                                  <Button
                                                    type="edit"
                                                    iconOnly={true}
                                                    onClick={() => {
                                                      setShowContent(true);
                                                      setSelectedChild(c);
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                      return <div>Content not found!
                                                                       <Button
                                                    type="delete"
                                                    iconOnly={true}
                                                    onClick={() => {
                                                      deleteContentChild(c);
                                                    }}
                                                  />

                                      </div>;
                                    })}
                                  </div>
                                </div>
                              </div>
                            );
                          }
                          return <div>Section not found!
                             <Button
                                                    type="delete"
                                                    iconOnly={true}
                                                    onClick={() => {
                                                      removePageChildHard(s);
                                                    }}
                                                  />
                          </div>;
                        })}
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </>
      )}

      {info && showAddPage && (
        <Modal setModalOpen={setShowAddPage} size="m" title={`Manage Page In Flow`}>
          <div className="form-horizontal">
            <h3>Info</h3>
            <div className="form-group">
              <label className="col-sm-4 control-label" htmlFor="ffm-fp-page">
                Page
              </label>
              <div className="col-sm-8">
                <select
                  id="ffm-fp-page"
                  className="form-control"
                  value={selectedChild?.ChildPublicId ?? ''}
                  onChange={(x) => {
                    setSelectedChild({ ...selectedChild, ChildPublicId: x.target.value });
                  }}>
                  <option value={''}>Select...</option>
                  {info.pages.map((x, i) => {
                    return (
                      <option value={x.PublicId} key={`ffm-fp-page-${i}`}>
                        {x.Name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-4 control-label" htmlFor="ffm-fp-order">
                Order
              </label>
              <div className="col-sm-4">
                <input
                  id="ffm-fp-order"
                  type="number"
                  className="form-control"
                  value={selectedChild?.SortOrder ?? ''}
                  onChange={(x) => {
                    setSelectedChild({ ...selectedChild, SortOrder: x.target.valueAsNumber });
                  }}
                />
              </div>
            </div>

                        <div className="form-group">
              <div className="col-sm-4"></div>
              <div className="col-sm-8">
                <Button type="save" text="Save" onClick={saveFlowChild} />
                <Button type="delete" text="Remove Page" onClick={removeFlowChild} />
              </div>
            </div>
          </div>
        </Modal>
      )}

      {info && showAddSection && (
        <Modal setModalOpen={setShowAddSection} size="m" title={`Manage Section In Page`}>
          <div className="form-horizontal">
            <div className="form-group">
              <label className="col-sm-3 control-label" htmlFor="ffm-fp-section">
                Section
              </label>
              <div className="col-sm-9">
                <select
                  id="ffm-fp-section"
                  className="form-control"
                  value={selectedChild?.ChildPublicId ?? ''}
                  onChange={(x) => {
                    setSelectedChild({ ...selectedChild, ChildPublicId: x.target.value });
                  }}>
                  <option value={''}>Select...</option>
                  {info.sections.map((x, i) => {
                    return (
                      <option value={x.PublicId} key={`ffm-fp-section-${i}`}>
                        {x.Name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-3 control-label" htmlFor="ffm-fs-order">
                Order
              </label>
              <div className="col-sm-3">
                <input
                  id="ffm-fs-order"
                  type="number"
                  className="form-control"
                  value={selectedChild?.SortOrder ?? ''}
                  onChange={(x) => {
                    setSelectedChild({ ...selectedChild, SortOrder: x.target.valueAsNumber });
                  }}
                />
              </div>
            </div>
            <div className="form-group">
              <div className="col-sm-3"></div>
              <div className="col-sm-9">
                <Button type="save" text="Save" onClick={savePageChild} />
                <Button type="delete" text="Remove Section" onClick={removePageChild} />
              </div>
            </div>
          </div>
        </Modal>
      )}

      {info && showAddContent && (
        <Modal setModalOpen={setShowContent} size="m" title={`Manage Content In Section`}>
          <div className="form-horizontal">
            <div className="form-group">
              <label className="col-sm-3 control-label" htmlFor="ffm-fs-content">
                Content
              </label>
              <div className="col-sm-8">
                <select
                  id="ffm-fs-content"
                  className="form-control"
                  value={selectedChild?.ChildPublicId ?? ''}
                  onChange={(x) => {
                    setSelectedChild({ ...selectedChild, ChildPublicId: x.target.value });
                  }}>
                  <option value={''}>Select...</option>
                  {info.content.map((x, i) => {
                    return (
                      <option value={x.PublicId} key={`ffm-fs-content-${i}`}>
                        {x.Name ?? x.Label} ({x.ContentType}) {x.SyncWithDataSource ? `(${x.SyncWithDataSource})` : ''}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-3 control-label" htmlFor="ffm-fc-order">
                Order
              </label>
              <div className="col-sm-3">
                <input
                  id="ffm-fc-order"
                  type="number"
                  className="form-control"
                  value={selectedChild?.SortOrder ?? ''}
                  onChange={(x) => {
                    setSelectedChild({ ...selectedChild, SortOrder: x.target.valueAsNumber });
                  }}
                />
              </div>
            </div>
            <div className="form-group">
              <div className="col-sm-3"></div>
              <div className="col-sm-9">
                <Button type="save" text="Save" onClick={saveSectionChild} />
                <Button type="delete" text="Remove Content" onClick={removeSectionChild} />
              </div>
            </div>
          </div>
        </Modal>
      )}

      {showEditPage && editIdPage && (
        <ConfigFormEditor
          type={'flex-page'}
          item={editIdPage}
          canSave={props.state.User?.Admin ?? false}
          noEdit={false}
          onClose={() => {
            setShowEditPage(false);
          }}
          onSave={(x) => {
            init();
          }}
          onRemove={(x) => {
            init();
          }}
          onUpdate={(x) => {
            init();
          }}
          // dontRefreshOnSaveOrRemove={true}
        />
      )}

      {showEditSection && editIdSection && (
        <ConfigFormEditor
          type={'flex-section'}
          item={editIdSection}
          canSave={props.state.User?.Admin ?? false}
          noEdit={false}
          onClose={() => {
            setShowEditSection(false);
          }}
          onSave={(x) => {
            init();
          }}
          onRemove={(x) => {
            init();
          }}
          onUpdate={(x) => {
            init();
          }}
          // dontRefreshOnSaveOrRemove={true}
        />
      )}

      {showEditContent && editIdContent && (
        <ConfigFormEditor
          type={'flex-content'}
          item={editIdContent}
          canSave={props.state.User?.Admin ?? false}
          noEdit={false}
          onClose={() => {
            setShowEditContent(false);
          }}
          onSave={(x) => {
            init();
          }}
          onRemove={(x) => {
            init();
          }}
          onUpdate={(x) => {
            init();
          }}
          // dontRefreshOnSaveOrRemove={true}
        />
      )}

      {showEditFlow && editFlow && (
        <ConfigFormEditor
          type={'flex-flow'}
          item={editFlow}
          canSave={props.state.User?.Admin ?? false}
          noEdit={false}
          onClose={() => {
            setShowEditFlow(false);
          }}
          onSave={(x) => {
            init();
          }}
          onRemove={(x) => {
            init();
          }}
          onUpdate={(x) => {
            init();
          }}
          // dontRefreshOnSaveOrRemove={true}
        />
      )}
    </>
  );
};

export default FlexFlowManager;
