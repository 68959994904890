import React from "react";

const Credits = ()=>{
    return (
        <>
            <h3>Credits</h3>
            Some of the content provided at zFairs is created by other talented individuals. 
            <hr/>

            <h5>Images</h5>
            <hr/>
            <ul>
                <li>"Blue sky with clouds 003" by SoraZG is licensed under CC BY 2.0</li>
                <li><a href="https://www.canstockphoto.com">(c) Can Stock Photo / bluering</a></li>
            </ul>
        </>
    );
}

export default Credits();