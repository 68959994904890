import { CanvasEventFunction, CanvasThingie, InputPlacementMode, doesPlacementModeApply, PlaceCursor } from "./PdfSelfSignEventMethods";
import { fabric } from "fabric";
import { PdfSelfSignExtendedInputs } from "./PdfSelfSignExtendedInputs";
import { v4 as uuidv4 } from "uuid";
import { loadSVGFromStringAsync } from "./fabricExtensions";

type CreateCheckBoxFieldOptions = {
    backgroundColor?: string | fabric.Pattern | fabric.Gradient;
    signerLabel?: string;
    scale?: number;
};

export const handlePlaceCheckBoxField: CanvasEventFunction = (pdfSelfSign: PdfSelfSignExtendedInputs, canvasThingie: CanvasThingie) => {
    handlePlaceCheckBoxFieldAsync(pdfSelfSign, canvasThingie);
}

const handlePlaceCheckBoxFieldAsync = async (pdfSelfSign: PdfSelfSignExtendedInputs, canvasThingie: CanvasThingie) => {
    const options: CreateCheckBoxFieldOptions = {
        backgroundColor: pdfSelfSign.state.activeSigner.color,
        signerLabel: pdfSelfSign.state.activeSigner.label,
    };
    const cursor = await buildCheckboxCursor(options);
    const placementMode: InputPlacementMode = InputPlacementMode.checkBoxPlacement;
    canvasThingie.fabricCanvas.on("mouse:move", (o) => {
        if (doesPlacementModeApply(o.pdfSelfSignState, placementMode)) {
            PlaceCursor(canvasThingie, o, cursor);
        }
    });

    canvasThingie.fabricCanvas.on("mouse:out", (o) => {
        if (doesPlacementModeApply(o.pdfSelfSignState, placementMode)) {
            canvasThingie.fabricCanvas.remove(cursor);
        }
    });

    canvasThingie.fabricCanvas.on("mouse:up", async (o) => {
        if (o.button === 1) {
            if (!o.target && !o.pdfSelfSignState.isPlacementMode) {
                if (o.pdfSelfSignState.inputPlacementMode === placementMode) {
                    PlaceCursor(canvasThingie, o, cursor);
                }
                return;
            }
            if (doesPlacementModeApply(o.pdfSelfSignState, placementMode)) {
                const block = await buildCheckboxCursor(options);
                block.uniqueId = uuidv4();
                block.set({
                    left: cursor.left,
                    top: cursor.top
                });
                console.log("Added Checkbox to canvas");
                canvasThingie.fabricCanvas.add(block);
                canvasThingie.fabricCanvas.renderAll();
                pdfSelfSign.saveInput(block);
            }
        }
    });
}

export const buildUncheckedCursor = async (options?: CreateCheckBoxFieldOptions) => {

    //got this svg from https://donate.wikimedia.org/wiki/File:Ooui-checkbox.svg  https://upload.wikimedia.org/wikipedia/donate/6/6b/Ooui-checkbox.svg
    let fab = await loadSVGFromStringAsync(`<svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>ooui-checkbox</title><rect stroke="#72777D" fill="#FFF" x="3.5" y="3.5" width="13" height="13" rx="2" fill-rule="evenodd"/></svg>`);
    const group = new fabric.Group(fab);
    group.inputType = "CheckBox";
    group._signerLabel = options?.signerLabel || "signer";

    return scaleGroup(group, options?.scale);
    
}

const scaleGroup = (group: fabric.Group, scale?: number) => {
    const caluclatedScale = scale || 3;
    
    console.log("I'm scaling here", caluclatedScale)
    group.scaleX = caluclatedScale;
    group.scaleY = caluclatedScale;

    return group;
}

export const buildCheckboxCursor = async (options?: CreateCheckBoxFieldOptions) => {
    let fab = await loadSVGFromStringAsync(`<svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>ooui-checkbox-selected</title><g fill="none" fill-rule="evenodd"><rect stroke="#36C" fill="#36C" x="3.5" y="3.5" width="13" height="13" rx="2"/><path stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" d="M6 10.157L8.572 13 14 7"/></g></svg>`);
    const group = new fabric.Group(fab);
    group.inputType = "CheckBox";
    group._signerLabel = options?.signerLabel || "signer";

    return scaleGroup(group, options?.scale);
}