import React, { useState, useEffect } from 'react';
import StoreX, { store } from '../../redux/oldStore';
import { toast } from 'react-toastify';
import FileDownload from 'js-file-download';
import { csvLine, splitCSV } from '../../utils/Tools';
import { ColumnData } from '../../utils/CommonTypes/CommonTypes';
import Modal from '../Modal/Modal';
import Icon, { IconType } from '../Icon/Icon';

interface IColumnMap {
  index: number;
  data: ColumnData;
  key:number;
}

export type UploadType = 'comma' | 'tab';

export type OtherUserType = 'Judge' | 'SpecialAwardJudges' | 'Interpreters' | 'Moderators';

const ParticipantsUpload = (props: any) => {
  const [callParticipant, setCallParticipant] = useState('');
  const [fileText, setFileText] = useState<string[][]>([]);
  const [headersIncluded, setHeadersIncluded] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [parsingErrors, setParsingErrors] = useState<any[] | null>([]);
  const [sendWelcomeEmail, setSendWelcomeEmail] = useState(true);
  const [sendSignInCredentials, setSendSignInCredentials] = useState(true);
  const [additionalColumns, setAdditionalColumns] = useState<ColumnData[]>([]);
  const [selectedColumn, setSelectedColumn] = useState<number | string>(-1);
  const [columnToAdd, setColumnToAdd] = useState<ColumnData>();
  const [customQuestionsProject, setCustomQuestionsProject] = useState<ColumnData[]>([]);
  const [customQuestionsPerson, setCustomQuestionsPerson] = useState<ColumnData[]>([]);
  const [uploadType, setUploadType] = useState<UploadType>('comma');

  const [showMapColumns, setShowMapColumns] = useState<boolean>(false);
  const [columnMapping, setColumnMapping] = useState<Record<number, IColumnMap | undefined>>({});
  const [baseColumnMapping, setBaseColumnMapping] = useState<ColumnData[]>([]);
  const [allColumnMapping, setAllColumnMapping] = useState<ColumnData[]>([]);

  useEffect(() => {
    store.server
      .getApi(`../import/info`)
      .then((res: any) => {
        console.log(res);
        setCustomQuestionsProject(
          res.Value.CustomQuestionsProject.map((q) => {
            return { value: q.text, id: q.value };
          })
        );
        setCustomQuestionsPerson(
          res.Value.CustomQuestionsParticipant.map((q) => {
            return { value: q.text, id: q.value };
          })
        );
      })
      .catch((err) => {
        console.error(err);
      });
    setCallParticipant(StoreX.Settings?.text.ParticipantLabel ?? 'Participant');
  }, []);

  useEffect(() => {
    let baseColumns: ColumnData[] = [];
    baseColumns.push({ index: 0, id: `index:0`, value: `Entry ID (Each entry/project should be unique, team projects should all use the same ID)` });
    baseColumns.push({ index: 1, id: `index:1`, value: `${callParticipant} First Name` });
    baseColumns.push({ index: 2, id: `index:2`, value: `${callParticipant} Last Name` });
    baseColumns.push({ index: 3, id: `index:3`, value: `${callParticipant} Email Address` });
    baseColumns.push({ index: 4, id: `index:4`, value: `Grade` });
    baseColumns.push({ index: 5, id: `index:5`, value: `School Name` });
    baseColumns.push({ index: 6, id: `index:6`, value: `Teacher First Name` });
    baseColumns.push({ index: 7, id: `index:7`, value: `Teacher Last Name` });
    baseColumns.push({ index: 8, id: `index:8`, value: `Teacher Email Address` });
    baseColumns.push({ index: 9, id: `index:9`, value: `Category Name` });
    baseColumns.push({ index: 10, id: `index:10`, value: `Entry Title` });
    baseColumns.push({ index: 11, id: `index:11`, value: `Team Project (true/false)` });

    setBaseColumnMapping(baseColumns);
    let all = [...baseColumnMapping];

    all.push({id:"password", value: 'Password'});
    all.push({id:"username", value: 'Username'});
    all.push({id:"MiddleName", value: 'Middle Name'});
    all.push({id:"Phone", value: 'Phone'});
    all.push({id:"Race", value: 'Race'});
    all.push({id:"Ethnicity", value: 'Ethnicity'});
    all.push({id:"Gender", value: 'Gender*'});
    all.push({id:"AddressLine1", value: 'Address Line 1'});
    all.push({id:"AddressLine2", value: 'Address Line 2'});
    all.push({id:"City", value: 'City'});
    all.push({id:"State", value: 'State'});
    all.push({id:"Zip", value: 'Zip'});
    all.push({id:"TeacherPhone", value: 'Teacher Phone'});
    all.push({id:"ParentFirstName", value: 'Parent First Name'});
    all.push({id:"ParentLastName", value: 'Parent Last Name'});
    all.push({id:"ParentEmail", value: 'Parent Email'});
    all.push({id:"ParentPhone", value: 'Parent Phone'});
    all.push({id:"ProjectDescription", value: 'Description'});
    all.push({id:"ProjectAbstract", value: 'Abstract'});
    all.push({id:"ProjectPlan", value: 'Plan'});
    all.push({id:"ProjectURL", value: 'URL'});
    all.push({id:"ProjectVideoLink", value: 'Video Link'});
    all.push({id:"fixed-project-id", value: 'Fixed Project Id'});
      customQuestionsProject?.forEach((q) => {
        all.push({id:q.id, value:q.value.substring(0,50)});
      });
      customQuestionsPerson?.forEach((q) => {
        all.push({id:q.id, value:q.value.substring(0,50)});
      });

    setAllColumnMapping(all);
  }, [customQuestionsPerson, customQuestionsProject, callParticipant]);

  const processMappedFile = ()=>{
    let additional:ColumnData[] = [];
    let data:string[][]=[];

    let indexList:number[] = [];
    let indexListPost:number[] = [];

    let maps:IColumnMap[] = [];
    let keys = Object.keys(columnMapping);

    keys.forEach(x=>{
      let mapping = columnMapping[x];
      if(mapping){
        if(mapping.data){
          maps.push(mapping);
          if(mapping.data.index === undefined){
            additional.push(mapping.data);
            indexListPost.push(mapping.key);
          }
        }
      }
    });

        
    //map base columns
    baseColumnMapping.forEach(x=>{
      let map = maps.find(m=>m.data?.id === x.id);
      indexList.push(map?.key ?? -1);
    });
    
    //combine base and additional inputs
    indexList = [...indexList, ...indexListPost];


    fileText.forEach(x=>{
      let line:string[] = [];

      indexList.forEach(i=>{
        line.push(x[i] ?? '');
      });     

      data.push(line);
    });

    setAdditionalColumns(additional);
    setFileText(data);
    setShowMapColumns(false);
    setParsingErrors(null);
  }

  const ParticipantFileSelected = (e: any) => {
    let file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = async (e: any) => {
      const text = e.target.result;
      processTextFile(text);
    };
    reader.readAsText(file, 'utf-8-sig');
  };

  const processTextFile = (text: string) => {
    let lines: any[] = [];
    const perrors: string[] = [];
    let formattedText = text.replaceAll('\r\n', '\n').replaceAll('\r', '\n');
    const rawLines = formattedText.split('\n');

    let aCol = [...additionalColumns];
    let updateCols = false;

    let headerValues = splitCSV(rawLines[0], uploadType);
    if (headerValues.length > 12) {
      let possibleColumns = [
        { id: 'password', value: 'Password' },
        { id: 'username', value: 'Username' },
        { id: 'Gender', value: 'Gender*' },
        { id: 'AddressLine1', value: 'Address Line 1' },
        { id: 'AddressLine2', value: 'Address Line 2' },
        { id: 'City', value: 'City' },
        { id: 'State', value: 'State' },
        { id: 'Zip', value: 'Zip' },
        { id: 'ParentFirstName', value: 'Parent First Name' },
        { id: 'ParentLastName', value: 'Parent Last Name' },
        { id: 'ParentEmail', value: 'Parent Email' },
        { id: 'ParentPhone', value: 'Parent Phone' },
        { id: 'ProjectDescription', value: 'Description' },
        { id: 'ProjectAbstract', value: 'Abstract' },
        { id: 'ProjectPlan', value: 'Plan' },
        { id: 'ProjectURL', value: 'URL' },
        { id: 'ProjectVideoLink', value: 'Video Link' },
        { id: 'fixed-project-id', value: 'Fixed Project Id' },
      ];
      for (let i = 12; i < headerValues.length; i++) {
        let value = headerValues[i].toLowerCase();
        let col = possibleColumns.find((x) => x.value.toLowerCase() === value || x.value.toLowerCase().replace(/[\s\*]/g, '') === value.replace(/[\s\*]/g, ''));

        if (!col) {
          col = customQuestionsProject?.find((x) => x.value.toLowerCase() === value);
        }
        if (!col) {
          col = customQuestionsPerson?.find((x) => x.value.toLowerCase() === value);
        }

        if (col && (aCol.find((x) => x.id === col?.id) ? false : true)) {
          aCol.push(col);
          updateCols = true;
        }
      }
    }

    if (updateCols) {
      setAdditionalColumns(aCol);
    }

    for (let i = 0; i < rawLines.length; i++) {
      let l = rawLines[i];
      if (l.length > 0) {
        let lineIndexes = csvLine(rawLines, i);
        let line: string = '';
        lineIndexes.forEach((x, xi) => {
          i = x;
          if (xi == 0) line = rawLines[x];
          else line += '<br/>' + rawLines[x];
        });

        //console.log(line);
        let lineValues = splitCSV(line, uploadType);
        for (let i = 0; i < lineValues.length; i++) {
          lineValues[i] = lineValues[i].replace(/(\<br\/\>)/g, '\r\n');
        }

        const columnsPerLine = 12 + aCol.length;
        if (lineValues.length === columnsPerLine) {
          //The line looks good
          lines.push(lineValues);
        } else {
          const errorMessage = `Line ${lineIndexes[0] + 1} has ${lineValues.length} columns, but should have ${columnsPerLine}`;
          if (lineIndexes.length > 1) console.log('this is the line', lineValues);
          //console.log(errorMessage);
          perrors.push(errorMessage);
          lines.push(lineValues);
        }
      }
    }
    if (perrors.length) {
      setParsingErrors(perrors);
      setFileText(lines);
      setShowMapColumns(true);
    } else {
      setParsingErrors(null);
      setFileText(lines);
    }
  };

  const load = (e) => {
    //  console.log('Sending to server...', fileText);
    // bring in the schoolText somewhere
    let realText = headersIncluded ? fileText.slice(1) : fileText;
    // console.log(realText, additionalColumns.map(x => x.id))
    store.server
      .postApi('../Import/LoadParticipants', {
        ParticipantData: realText,
        SendWelcomeEmail: sendWelcomeEmail,
        SendSignInCredentials: sendSignInCredentials,
        additionalColumns: additionalColumns.map((x) => x.id),
      })
      .then((x: any) => {
        if (x.Success) {
          if (!x.Value.allImported) {
            toast.warning(
              <>
                We were not able to import everyone.
                <ul>
                  {x.Value.failedToImport.map((f, i) => {
                    return <li key={`fti-${i}`}>{f}</li>;
                  })}
                </ul>
                Click to close this window
              </>,
              { autoClose: false }
            );
            setParsingErrors(x.Value.failedToImport);
            setLoaded(true);
          } else {
            toast.success('Your Participants have been loaded.');
            setLoaded(true);
          }
        } else {
          toast.error(x.Message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err);
      });
  };

  const downloadTemplateClick = () => {
    let moreColumns = additionalColumns.reduce((acc, col) => {
      let { id, value } = col;
      return { ...acc, [value]: '' };
    }, {});
    const items = [
      {
        ...baseColumnMapping.map((x) => x.value),
        ...moreColumns,
      },
    ];

    const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
    const header = Object.keys(items[0]);
    const csv = [
      header.join(','), // header row first
      ...items.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(',')),
    ].join('\r\n');

    console.log(csv);
    // downloady stuff here
    FileDownload(csv, `${callParticipant}_upload_template.csv`);
  };

  const handleColumnToAddChange = (e: any) => {
    let index = e.nativeEvent.target.selectedIndex;
    let formattedColumn = {
      id: e.target.value,
      value: e.nativeEvent.target[index].text,
    };
    // console.log(e, formattedColumn);
    setSelectedColumn(e.value);
    setColumnToAdd(formattedColumn);
  };

  const handleAddColumn = () => {
    if (columnToAdd) setAdditionalColumns([...additionalColumns, columnToAdd]);
    setColumnToAdd(undefined);
    setSelectedColumn(-1);
  };

  const handleRemoveCol = (id: string) => {
    setAdditionalColumns(additionalColumns.filter((x) => x.id !== id));
  };

  return (
    <>
      <div className="loading-container">
        <h1>Loading {callParticipant} Information</h1>
        <div>
          You can load information for your contest using a CSV file. You can save Excel and Google Doc files as CSV files
          <ul>
            <li>
              <strong>Google Sheets</strong> Select File, then Download then as Comma-separated values (.csv)
            </li>
            <li>
              <strong>Excel</strong> Select File, Save as, then change type to CSV UTF-8 (*.csv)
            </li>
          </ul>
        </div>
        <div className="">
          <h3>Load {callParticipant}s</h3>
          <div className="flex-between">
            <h4>File Format</h4>
            <button className="btn btn-secondary btn-xs" onClick={downloadTemplateClick}>
              Download Template
            </button>
          </div>
          <div>
            The {callParticipant} CSV file should include the following columns in the following order.
            <ul>
              {baseColumnMapping.map((x, i) => {
                return <li key={i}>{x.value}</li>;
              })}
              {additionalColumns.map((col, i) => {
                return (
                  <li key={`${col.id}-${i}`}>
                    {col.value}{' '}
                    <span onClick={() => handleRemoveCol(col.id)}>
                      <i className="far fa-trash-alt"></i>
                    </span>
                  </li>
                );
              })}
            </ul>
            <div className="form-group soft-border">
              {/* Style this stuff here */}
              <p>Use this drop down and button to add additional columns that you want for uploading data.</p>
              <div className="flex-between">
                <select className="form-control" onChange={(e) => handleColumnToAddChange(e)} value={selectedColumn}>
                  <option value={-1}> </option>
                  <option value="password">Password</option>
                  <option value="username">Username</option>
                  <option value="Gender">Gender*</option>
                  <optgroup label="Address">
                    <option value="AddressLine1">Address Line 1</option>
                    <option value="AddressLine2">Address Line 2</option>
                    <option value="City">City</option>
                    <option value="State">State</option>
                    <option value="Zip">Zip</option>
                  </optgroup>
                  <optgroup label="Parent Info">
                    <option value="ParentFirstName">Parent First Name</option>
                    <option value="ParentLastName">Parent Last Name</option>
                    <option value="ParentEmail">Parent Email</option>
                    <option value="ParentPhone">Parent Phone</option>
                  </optgroup>

                  <optgroup label="Project">
                    <option value="ProjectDescription">Description</option>
                    <option value="ProjectAbstract">Abstract</option>
                    <option value="ProjectPlan">Plan</option>
                    <option value="ProjectURL">URL</option>
                    <option value="ProjectVideoLink">Video Link</option>
                    <option value="fixed-project-id">Fixed Project Id</option>
                  </optgroup>
                  <optgroup label="Project Custom Questions">
                    {customQuestionsProject?.map((q) => {
                      return (
                        <option key={q.id} value={q.id}>
                          {q.value}
                        </option>
                      );
                    })}
                  </optgroup>
                  <optgroup label={`${callParticipant} Custom Questions`}>
                    {customQuestionsPerson?.map((q) => {
                      return (
                        <option key={q.id} value={q.id}>
                          {q.value}
                        </option>
                      );
                    })}
                  </optgroup>
                </select>
                <button onClick={handleAddColumn} className="btn btn-primary">
                  add column
                </button>
              </div>
              <div className="small">Gender*: When uploading gender values use: F for Female, M for Male, N for Non-binary, O for Other, and Z for Prefer not to answer.</div>
            </div>
          </div>
          {!loaded && (
            <div className="">
              <div className="flex bumper-l"></div>
              <div className="bumper-l">
                <label>Delimiter: </label>
                <select className="form-control" onChange={(e) => setUploadType(e.target.value as UploadType)} value={uploadType}>
                  <option value={'comma'}>Comma</option>
                  <option value={'tab'}>Tab</option>
                </select>
              </div>
              <div className="bumper-l">
                <label className="">{callParticipant} File:</label>
                <input
                  type="file"
                  accept=".csv"
                  onChange={ParticipantFileSelected}
                  onClick={(evt: any) => (evt.target.value = null)}
                  // Set target to null so the onchange event will fire again even if the user picks the same file.
                />
              </div>
              <div className="flex bumper-l">
                <label htmlFor="headers">Does the file include headers?</label>
                <input type="checkbox" name="headers" id="headers" className="form-input bumper-sides-out-l" onChange={(e) => setHeadersIncluded(e.target.checked)} defaultChecked={headersIncluded} />
              </div>

              {fileText && fileText.length > 0 && (
                <div className="csv-file-preview">
                  <table>
                    {!headersIncluded && (
                      <thead>
                        <tr>
                          {baseColumnMapping.map((x, i) => {
                            return <th key={i}>{x.value}</th>;
                          })}
                        </tr>
                      </thead>
                    )}
                    <tbody>
                      {fileText.map((r, ri) => {
                        return (
                          <tr key={'r' + ri}>
                            {r.map((v, vi) => {
                              if (headersIncluded && ri === 0) {
                                return <th key={ri + '-' + vi}>{v}</th>;
                              }
                              return <td key={ri + '-' + vi}>{v}</td>;
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
              {parsingErrors && parsingErrors.length > 0 && (
                <table>
                  {parsingErrors.map((error, index) => (
                    <tr key={index}>
                      <td>{error}</td>
                    </tr>
                  ))}
                </table>
              )}
            </div>
          )}

          {!loaded && fileText && fileText.length > 0 && (
            <div className="ready-to-load-area">
              <h4>If the {callParticipant} file contents look good, press Load</h4>
              <div>
                Send welcome email: <input type="checkbox" defaultChecked={sendWelcomeEmail} onChange={(e) => setSendWelcomeEmail(e.target.checked)} />
                Send sign-in credentials: <input type="checkbox" defaultChecked={sendSignInCredentials} onChange={(e) => setSendSignInCredentials(e.target.checked)} />
              </div>
              <button type="button" className="btn btn-success" onClick={load}>
                <i className="fad fa-cloud-upload"></i>
                Load
              </button>
            </div>
          )}
          {loaded && (
            <div>
              <h1>{callParticipant}s loaded!</h1>
              If you need to load more {callParticipant}s refresh your page.
            </div>
          )}
        </div>
      </div>

      {showMapColumns && (
        <Modal setModalOpen={setShowMapColumns} title="Map Columns to Load Data" size="l" noClose={true}>
          <div className="form-horizontal">
            <h4>
              <Icon type={IconType.mapO} />
              Map Columns to Data Points
            </h4>
            Map each column from your file to a data point.
            <div className="form-group">
              <div className="col-sm-offset-4 col-sm-8">
                <input type="checkbox" id="headers-2" className="" onChange={(e) => setHeadersIncluded(e.target.checked)} defaultChecked={headersIncluded} />
                <label htmlFor="headers-2" className="control-label">
                  First row are data headers
                </label>
              </div>
            </div>
            {fileText &&
              fileText.length > (headersIncluded ? 1 : 0) &&
              fileText[0].map((x, i) => {
                return (
                  <div className="form-group" key={i}>
                    <label className="col-sm-4 control-label" htmlFor={`map-column-${i}-svjj`}>
                      {headersIncluded ? (
                        <>
                          Column {i + 1}: <br />
                          {x}
                        </>
                      ) : (
                        <>
                          Column {i + 1}: <br />({x.substring(0, 25)})
                        </>
                      )}
                    </label>
                    <div className="col-sm-8">
                      <select
                        id={`map-column-${i}-svjj`}
                        className="form-control"
                        onChange={(x) => {
                          let mapping = columnMapping;
                          let index = +x.target.value;
                          if (index > -1) {
                            mapping[i] = { index: index, data: allColumnMapping[index], key:i };
                          } else {
                            mapping[i] = undefined;
                          }
                          console.log(mapping);
                          setColumnMapping(mapping);
                        }}
                        // value={(columnMapping[i]?.index ?? '') + ''}
                        >
                        <option value="-1">Select data point...</option>
                        {allColumnMapping.map((c, ci) => {
                          return (
                            <option key={`svjj-${i}-${ci}`} value={ci}>
                              {c.value}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                );
              })}
            <div className="form-group">
              <div className="col-sm-4"></div>
              <div className="col-sm-8"></div>
            </div>
            <div className="form-group">
              <div className="col-sm-4"></div>
              <div className="col-sm-8">
                <button type={'button'} className="btn btn-secondary" onClick={processMappedFile}>
                  <Icon type={IconType.upload} /> Process File
                </button>
                <button
                  type={'button'}
                  className="btn btn-default"
                  onClick={() => {
                    setShowMapColumns(false);
                  }}>
                  <Icon type={IconType.close} /> Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ParticipantsUpload;
