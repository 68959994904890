import React, { useEffect, useState } from "react"
import { store } from "../../redux/oldStore";
import './QuestionCreation.css'
import { Confirm } from 'react-st-modal';
import queryString from 'query-string'
import { toast } from "react-toastify";


export interface QueryStuff {
    personType?: CustomQuestionPersonType,
    rubricId?: number
}

function withRubricRouter<P>(WrappedComponent: React.ComponentType<P>) {
    const rubricRouter = (props: P & QueryStuff) => {
        const getQueryStuff = () => {
            const queryValues: QueryStuff = queryString.parse(window.location.search);
            return queryValues;
        }

        const getPersonType = () => {

            const defaultPersonType = getQueryStuff().rubricId ? CustomQuestionPersonType.OnlineJudging : CustomQuestionPersonType.Judge;
            return getQueryStuff().personType ?? defaultPersonType;
        }
        return <WrappedComponent {...props}
            personType={getPersonType()}
            rubricId={getQueryStuff().rubricId}
        />
    };

    return rubricRouter
}

const QuestionCreator = (props: QueryStuff ) => {
    const [customQuestions, setCustomQuestions] = useState<CustomQuestion[]>();
    const [selectedQuestion, setSelectedQuestion] = useState<CustomQuestion>();
    const [questionText, setQuestionText] = useState('');
    const [responseDataType, setResponseDataType] = useState(CustomQuestionType.bool);
    const [multipleChoiceOptions, setMultipleChoiceOptions] = useState('');
    const [questionColumnName, setQuestionColumnName] = useState('');
    const [questionSortOrder, setQuestionSortOrder] = useState<number>();
    const [questionRequired, setQuestionRequired] = useState(false);
    const [questionIgnored, setQuestionIgnored] = useState(false);

    useEffect(() => {
        getCustomQuestionsToState();
    }, []);

    const getCustomQuestionsToState = async () => {
        const request: GetCustomQuestionsRequest = { PersonType: props.personType ?? CustomQuestionPersonType.OnlineJudging, RubricId: props.rubricId }
        console.log("Send Custom Questions Request", request)

        const questions = await getCustomQuestions(request);
        setCustomQuestions(questions);
    }

    const updateFormWithCurrentQuestion = (question: CustomQuestion) => {
        setSelectedQuestion(question);
        setQuestionText(question.question);
        setResponseDataType(question.type);
        setMultipleChoiceOptions(question?.possibleValuesCsv?.replace('^', '\n') ?? '');
        setQuestionColumnName(question.ColumnName);
        setQuestionSortOrder(question.Sort);
        setQuestionRequired(!!question.Required);
        setQuestionIgnored(!!question.Ignore);
    }

    const customQuestionList = customQuestions?.map((cq, i) => {
        const selected = selectedQuestion?.id && cq.id === selectedQuestion?.id
        return (
            <li key={i}><button className={selected ? "selected" : ""} onClick={() => updateFormWithCurrentQuestion(cq)}>{`${cq.Sort} - ${cq.question}`}</button></li>
        )
    });

    const handleSaveCustomQuestion = async (evt: React.MouseEvent) => {
        evt.preventDefault();
        const request: SaveCustomQuestionRequest = {
            CustomQuestion: {
                ColumnName: questionColumnName,
                Ignore: questionIgnored,
                Required: questionRequired,
                Sort: questionSortOrder,
                for: props.personType,
                possibleValuesCsv: multipleChoiceOptions.replace('\n', '^'),
                question: questionText,
                type: responseDataType,
                id: selectedQuestion?.id,
                defaultValue: selectedQuestion?.defaultValue,
                fairId: selectedQuestion?.fairId,
                RubricId: props.rubricId
            }
        };
        await saveCustomQuestion(request);
        getCustomQuestionsToState();
    }

    const addNewCustomQuestion = (evt: React.MouseEvent) => {
        evt.stopPropagation();
        updateFormWithCurrentQuestion(defaultCustomQuestion);
    }

    const handleDeleteCustomQuestion = async (evt: React.MouseEvent) => {
        evt.stopPropagation();
        if (!selectedQuestion?.id) {
            return;
        }
        if (await Confirm('Are you sure you want do delete this custom question?  This action cannot be undone.', 'Delete?', 'Cancel', 'Delete')) {
            return;
        }
        await deleteCustomQuestion(selectedQuestion?.id);
        getCustomQuestionsToState();
    }


    return (
        <div id="custom-questions">
            <nav id="custom-questions-menu">
                <header>
                    <h3>Custom Questions:</h3>
                </header>
                <button onClick={addNewCustomQuestion}>Add New +</button>
                <ul>
                    {customQuestionList}
                </ul>

            </nav>
            <form id='custom-question-editor'>
                <header>
                    <h3>Custom Question Editor Panel:</h3>
                </header>
                <label htmlFor='question-text'>Question:</label>
                <textarea id='question-text' value={questionText} maxLength={2000} onChange={(e) => setQuestionText(e.target.value)}></textarea>
                <label htmlFor='response-data-type'>Response Data Type:</label>
                <select id='response-data-type' value={responseDataType} onChange={(e) => setResponseDataType(e.target.value as CustomQuestionType)}>
                    <option value={CustomQuestionType.bool}>true/false (check-box)</option>
                    <option value={CustomQuestionType.text}>text</option>
                    <option value={CustomQuestionType.textarea}>text multile line</option>
                    <option value={CustomQuestionType.drop}>multiple choice</option>
                    <option value={CustomQuestionType.header}>section header</option>
                </select>
                {responseDataType === "drop" && <>
                    <label htmlFor='multiple-choice-options'>Multiple Choice Options:</label>
                    <textarea id='multiple-choice-options' placeholder="Put each possible answer on its own line." value={multipleChoiceOptions} onChange={(e) => setMultipleChoiceOptions(e.target.value)}></textarea>
                </>}
                <label htmlFor='question-column-name'>Question Column Name:</label>
                <input type='text' id='question-column-name'  maxLength={50} value={questionColumnName} onChange={(e) => setQuestionColumnName(e.target.value)}></input>
                <label htmlFor='question-sort-order'>Sort Order</label>
                <input type='number' id='question-sort-order'  maxLength={3}  pattern={'[0-9]+'} defaultValue='0' value={questionSortOrder} onChange={(e) => setQuestionSortOrder(parseInt(e.target.value))}></input>
                <label htmlFor='question-reqired'>Required:</label>
                <div>
                    <input type='checkbox' id='question-required' onChange={(e) => setQuestionRequired(e.target.checked)} checked={questionRequired}></input>
                    <label htmlFor='question-required'>This question is required.  Does not applly to the check-box.</label>
                </div>
                <label htmlFor='question-ignored'>Ignore:</label>
                <div>
                    <input type='checkbox' id='question-ignored' onChange={(e) => setQuestionIgnored(e.target.checked)} checked={questionIgnored}></input>
                    <label htmlFor='question-ignored'>This question is ignored and is not shown to users.</label>
                </div>
                <footer>
                    <button onClick={handleSaveCustomQuestion} type='submit'>Save Custom Question</button>
                    <button type='reset'>Clear</button>
                    {!!selectedQuestion?.id && <button type='button' onClick={handleDeleteCustomQuestion}>Remove</button>}
                </footer>
            </form>
        </div>
    )
}

export default withRubricRouter(QuestionCreator)



  const getCustomQuestions = async (request: GetCustomQuestionsRequest) => {
    const response = await store.server?.postApiWithServerResponse<
      CustomQuestion[]
    >("../CustomQuestions/RetrieveCustomQuestions", { request });
    if (!response.Success) {
      toast.error("Unable to retreive custom questions.");
      console.error(response.Message);
      return [];
    }
    return response.Value;
  };

  const deleteCustomQuestion = async (questionId: number) => {
    const request: DeleteCustomQuestionRequest = { QuestionId: questionId };
    const response = await store.server?.postApiWithServerResponse<boolean>(
      "../CustomQuestions/DeleteCustomQuestion",
      { request }
    );
    if (!response.Success) {
      toast.error("Unable to delete the custom question.");
      console.error(response.Message);
    }
    return response.Success;
  };

  const saveCustomQuestion = async (request: SaveCustomQuestionRequest) => {
    const response = await store.server?.postApiWithServerResponse<
      CustomQuestion[]
    >("../CustomQuestions/SaveCustomQuestion", { request });
    if (!response.Success) {
      toast.error("Unable to retreive custom questions.");
      console.error(response.Message);
    }
  };

  
  export interface GetCustomQuestionsRequest {
    PersonType: CustomQuestionPersonType;
    RubricId?: number;
  }
  
  export interface SaveCustomQuestionRequest {
    CustomQuestion: Partial<CustomQuestion>;
  }
  
  export interface DeleteCustomQuestionRequest {
    QuestionId: number;
  }
  
  export enum CustomQuestionPersonType {
    Unknown = -1,
    Judge = 1,
    Student = 2,
    Volunteer = 3,
    Teacher = 4,
    Project = 5,
    OnlineJudging = 6,
    Attendee = 7,
  }
  
  export enum CustomQuestionType {
    bool = "bool",
    text = "text",
    textarea = "textarea",
    drop = "drop",
    header = "header",
  }

  
const defaultCustomQuestion: CustomQuestion = {
    id: 0,
    fairId: 0,
    for: CustomQuestionPersonType.Judge,
    question: "",
    ColumnName: "",
    Ignore: false,
    Required: false,
    Sort: 0,
    defaultValue: "",
    possibleValuesCsv: "",
    type: CustomQuestionType.bool,
  };
  
  export interface CustomQuestion {
    id: number;
    fairId: number;
    for: number;
    question: string;
    type: CustomQuestionType;
    defaultValue: string;
    Sort: number;
    possibleValuesCsv: string;
    Required: boolean | undefined;
    Ignore: boolean | undefined;
    ColumnName: string;
    RubricId?: number;
  }
  
  export interface CustomQuestionWithAnswer extends CustomQuestion {
    Answer?: string;
  }
  