import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { closeModalHookDef, fileType, fileUploadRequest, project, uploadedFile, uploadFileHookDef } from "./paperworkTypes";
import { store } from '../../redux/oldStore'
import { convertSingleImageToPdfAsFile } from "../../utils/PdfConverter";
import { toast } from "react-toastify";


type FileUploadComponentProps = {
    fileTypes: fileType[];
    selectedFileTypeId: number;
    project: project;
    fileId: Number;
    closeModalHook: closeModalHookDef;
    fileUploadedHook: uploadFileHookDef;
}

enum fileUploadStatus {
    statusInitial = 0,
    statusSaving = 1,
    statusSuccess = 2,
    statusFailed = 3
}

type FileUploadComponentState = {
    showModal: boolean;
    uploadedFile?: userFileUpload;
    uploadError?: string;
    currentStatus: fileUploadStatus;
    selectedFileTypeId?: number;
}

type userFileUpload = {
    url: string;
    fileName: string;
}

const FileUploadComponent: FunctionComponent<FileUploadComponentProps> = (props) => {
    const initialState: FileUploadComponentState = {
        currentStatus: fileUploadStatus.statusInitial,
        showModal: false,
        // selectedFileTypeId: props.selectedFileTypeId
    }


    const [myState, setMyState] = useState(initialState);

    useEffect(() => {
        setMyState(previousState => ({
            ...previousState,
            selectedFileTypeId: props.selectedFileTypeId
        }))
    }, [props.selectedFileTypeId]);

    const onSelectFileType = (fileType: fileType) => {
        setMyState({ ...myState, selectedFileTypeId: fileType.id });
    }

    const reset = () => {
        //reset form to initial state
        setMyState({
            ...myState,
            currentStatus: fileUploadStatus.statusInitial,
            uploadedFile: undefined,
            uploadError: undefined
        });
    }

    const save = async (files: FileList) => {
        //upload data to the server
        setMyState(
            {
                ...myState,
                currentStatus: fileUploadStatus.statusSaving,
            });

        const success = (data: uploadedFile) => {
            console.debug(data);
            setMyState({
                ...myState,
                uploadedFile: data,
                currentStatus: fileUploadStatus.statusSuccess,

            });
            //emit file-uploaded,
            props.fileUploadedHook();
            //close the modal
            props.closeModalHook();
        }

        const fail = (response: any) => {
            console.error(response);
            setMyState({
                ...myState,
                uploadError: response?.Message || "There was a problem while uploading your file.",
                currentStatus: fileUploadStatus.statusFailed
            });
        }
        const fileMetaData: fileUploadRequest = {
            projectId: props.project.ProjectIntId,
            fileTypeId: myState.selectedFileTypeId || props.selectedFileTypeId
        }

        const filesArray = Object.values(files);
        const processedFilesArray: File[] = []
        for (let i = 0; i < filesArray.length; i++) {
            const file = filesArray[i];
            if (file.type !== "application/pdf") {
                try {
                    processedFilesArray.push(await convertSingleImageToPdfAsFile(file));
                } catch {
                    toast.error("We are unable to convert your image to a pdf.")
                }
            } else {
                processedFilesArray.push(file);
            }
        }
        try {
            const result = await store.server?.postApiWithServerResponse<uploadedFile>('../PaperworkReview/FileUpload', fileMetaData, processedFilesArray);
            if (result?.Value) {
                success(result?.Value);
            } else {
                fail(result)
            }

        } catch (error) {
            fail(error);
        }
    }

    const filesChange = (fileList: FileList | null) => {
        if (!fileList?.length) return;
        save(fileList);
    }

    //#region Vue Computed
    const requiredFileTypes = useMemo(() => {
        return props.fileTypes.filter(ft => { return ft.required });
    }, [props.fileTypes]);

    const nonRequiredFileTypes = useMemo(() => {
        return props.fileTypes.filter(ft => { return !ft.required });
    }, [props.fileTypes]);

    const selectedFileType = useMemo(() => {
        const selectedFileTypeId = myState.selectedFileTypeId || props.selectedFileTypeId
        let fileType = props.fileTypes.filter(ft => { return ft.id === selectedFileTypeId })[0];
        if (!fileType) {
            fileType = {
                description: "Unknown file type",
                id: selectedFileTypeId || 0,
                name: `Unknown${selectedFileTypeId}`,
                required: false,
                fileTypeId: (selectedFileTypeId || 0).toString(), //TODO:  what is fileTypeId?  We may be able to get rid of that.
                FreezeDate:undefined,
                FreezeDateString:undefined,
            }
        }
        return fileType;
    }, [props.fileTypes, myState.selectedFileTypeId, props.selectedFileTypeId]);

    const isInitial = () => {
        return myState.currentStatus === fileUploadStatus.statusInitial;
    }
    const isSaving = () => {
        return myState.currentStatus === fileUploadStatus.statusSaving;
    }
    const isSuccess = () => {
        return myState.currentStatus === fileUploadStatus.statusSuccess;
    }
    const isFailed = () => {
        return myState.currentStatus === fileUploadStatus.statusFailed;
    }


    //#endregion

    useEffect(() => { reset() }, []);


    return (
        <>
            <div>
                {/* single button dropdown */}
                <span>File Type:</span>
                <span className="btn-group">
                    <button type="button" className="btn btn-primary btn-xs dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {selectedFileType.name} <span className="caret"></span>
                    </button>
                    <ul className="dropdown-menu">
                        {requiredFileTypes.map((fileType) => (
                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                            <li key={fileType.id}><a href="#" onClick={() => onSelectFileType(fileType)}>{fileType.name}</a></li>
                        ))}
                        <li role="separator" className="divider"></li>
                        {nonRequiredFileTypes.map((fileType) => (
                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                            <li key={fileType.id}><a href="#" onClick={() => onSelectFileType(fileType)}>{fileType.name}</a></li>
                        ))}
                    </ul>
                </span>
                {/* end single button dropdown */}
                <div className="head-room">
                    {/* UPLOAD */}
                    {(isInitial() || isSaving()) &&
                        <form encType="multipart/form-data" noValidate>
                            <div className="dropbox">
                                <input type="file" name="pdfs" disabled={isSaving()} onChange={(evt) => filesChange(evt.target.files)} accept=".pdf,.jpg,.jpeg,.png" className="input-file"></input>
                                {isInitial() &&
                                    <p>
                                        Drag your file here<br /> or click to browse
                                    </p>
                                }
                                {isSaving() &&
                                    <p>
                                        Uploading file...
                                    </p>
                                }
                            </div>
                        </form>
                    }
                    <br />
                    {isSuccess() &&
                        <div>
                            <h2>Uploaded Successfully</h2>
                            <p>
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                                <a href="#" onClick={reset}>Upload Again</a>
                            </p>
                            <ul className="list-unstyled">
                                <img src={myState.uploadedFile?.url || ""} alt={myState.uploadedFile?.fileName}></img>
                            </ul>
                        </div>
                    }
                    {isFailed() &&
                        <div>
                            <h2>Upload Failed</h2>
                            <p>
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                                <a href="#" onClick={reset}>Try Again</a>
                            </p>
                        </div>
                    }
                    <button type="button" className="btn btn-danger" onClick={props.closeModalHook}>Cancel</button>
                </div>
            </div>
        </>);
}

export default FileUploadComponent;