// import pdfjsLib, { PDFDocumentProxy } from "pdfjs-dist/webpack"
import EventEmitter from "wolfy87-eventemitter";
import { IApiService } from "./IApiService";
import  /*webpackPrefecth: true*/pdfThing, { PDFDocumentProxy } from "pdfjs-dist";
// import pdfjsLib, { PDFDocumentProxy } from "pdfjs-dist/webpack"



export default abstract class pdfBase extends EventEmitter {
    protected source: string = "";
    protected pdfBytes: Uint8Array | null = null;
    protected numberOfPages: number = 0;
    protected doc: PDFDocumentProxy | null = null;
    protected isInitialized: boolean = false

    /**Download an initialize the pdf.  Only happens once.  If initialize has already been called
     * this metheod will return false.
     */
    protected async initialize(source: string, apiService?: IApiService): Promise<boolean> {
        if (this.isInitialized) {
            return false; 
        }

        const pdfjsLib:typeof pdfThing = await import('pdfjs-dist/build/pdf');
        // const pdfjsWorker = await import('pdfjs-dist/build/pdf.worker.entry');
        // pdfjsLib.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/js/pdf.worker.js`;
        // pdfjsLib.GlobalWorkerOptions.workerSrc = '../../node_modules/pdfjs-dist/build/pdf.worker.js';
        // pdfjsLib.GlobalWorkerOptions.workerSrc = '../../';
        // const pdfJsLib: typeof = await import('pdfjs-dist');
        // pdfjsLib.
        // pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;  //TODO:  change this to rely on some local copy of pdf.worker.js.  The one in the node moudles folder matches the one from the CDN, but I haven't had luck getting it to load correctly from a local folder.
        pdfjsLib.GlobalWorkerOptions.workerSrc = "https://cdn.bootcss.com/pdf.js/2.4.456/pdf.worker.js";  //TODO:  change this to rely on some local copy of pdf.worker.js.  The one in the node moudles folder matches the one from the CDN, but I haven't had luck getting it to load correctly from a local folder.
        // pdfjsLib.GlobalWorkerOptions.workerSrc = './pdf.worker.js';
        let pdfData: ArrayBuffer | null = null;
        if (apiService && source && source.length > 0) {
            try {
                let response = ((await apiService.getApiBlob(source)) as any);
                if (response.data && response.data.arrayBuffer) {
                    pdfData = await (response.data.arrayBuffer());
                } else {
                    console.log('Dan did it...');
                }
            }
            catch (error) {
                console.log(error);
            }
        }
        this.source = source;

        console.log("What is our source??", source);
        console.log("What is our pdfData??", pdfData);
        if (source) {
            this.doc = await (pdfData == null ? pdfjsLib.getDocument(source).promise : pdfjsLib.getDocument(pdfData).promise);
            this.pdfBytes = await this.doc.getData();
            this.numberOfPages = this.doc.numPages
        } else {
            this.numberOfPages = 1
        }
        this.isInitialized = true;
        return true;
    }
}