import React from 'react';
import { GetPeoplePerson, IPeopleSettings, Tag } from '../../utils/CommonTypes/PeoplePageTypes';
import { FormatDate } from '../../utils/Tools';
import Icon, { IconType } from '../Icon/Icon';
import TagComponent from '../Tags/TagComponent';
import { IState } from '../../redux/redux';

interface PersonRowProps {
  state:IState;
  settings: IPeopleSettings;
  person: GetPeoplePerson;
  personTagDict: Record<string, Tag>;
  projectTagDict: Record<string, Tag>;
  autoTagDict: Record<string, Tag>;
  setClickedIconModalOpen: (open: boolean) => void;
  setChosenPerson: (p: GetPeoplePerson) => void;
  showSelect: boolean;
  selected: boolean;
  setSelected: (checked: boolean) => void;
}

const PersonRow = (props: PersonRowProps) => {
  const { person } = props;
  let p = person;

  const handlePersonIconClick = () => {
    props.setChosenPerson(p);
    props.setClickedIconModalOpen(true);
  };

  const entryStatus = (p: GetPeoplePerson) => {
    switch (p.EntryStatus) {
      case 'Approved':
        return <div className="entry-status">{props.state.Settings?.text.EntryStatusApproved}</div>;

      case 'Dropped':
        return <div className="entry-status">{props.state.Settings?.text.EntryStatusDropped}</div>;
      case 'DoNotAdvance':
        return (
          <div className="entry-status">
            {props.state.Settings?.text.EntryStatusDNA}
          </div>
        );
      case 'Pending':
      default:
        return <></>;
    }
  };
  
  const entryStatusClass = (p: GetPeoplePerson) => {
    switch (p.EntryStatus) {
      case 'Approved':
        return 'entry-status-approved';
      case 'Dropped':
        return 'entry-status-dropped';
      case 'DoNotAdvance':
        return 'entry-status-dna';
      case 'Pending':
      default:
        return '';
    }
  };

  const whatToShow = () => {
    let icons = <></>;
    let icon = <Icon type={IconType.user} addClass="fa-2x bumper-vert-out-l" />;
    let jsx = <></>;

    let fees = props.settings.HasRegistrationFee;
    let affiliate = true;
    let medical = true;

    if (!p.Adult) {
      icons = (
        <>
          {fees ? (
            <>
              &nbsp;
              <span className={`${p.Paid ? 'text-success' : 'hide'}`} title="Fee Has Been Paid">
                <Icon type={IconType.money} />
              </span>
              <span className={`${p.Paid ? 'hide' : 'text-danger'}`} title="Fee Has Not Been Paid">
                <Icon type={IconType.money} />
              </span>
            </>
          ) : (
            <></>
          )}
          {affiliate ? (
            <>
              &nbsp;
              <span className={`text-info ${p.Affiliate && p.Affiliate.length > 0 ? '' : ' hide'}`} title={`Affiliate: ${p.Affiliate}`}>
                <Icon type={IconType.mapO} />
              </span>
              {p.PromotedAt && (
                <>
                  &nbsp;
                  <span className="text-info" title="Promoted">
                    <Icon type={IconType.arrowCircleOUp} />
                  </span>
                </>
              )}
              {p.ShownPermissionsAt && (
                <>
                  &nbsp;
                  <span className="text-info" title="Agreed to terms and conditions">
                    <Icon type={IconType.agree} />
                  </span>
                </>
              )}
              {p.HasInvoiceWithRegistrationFee && (
                <>
                  &nbsp;
                  <span className="text-info" title="Registration fee on an invoice">
                    <Icon type={IconType.invoice} />
                  </span>
                </>
              )}
            </>
          ) : (
            <></>
          )}
          {medical ? (
            <>
              {p.Medical && (
                <>
                  &nbsp;
                  <span className="text-success" title="Has medical forms">
                    <Icon type={IconType.heartbeat} />
                  </span>
                </>
              )}
            </>
          ) : (
            <></>
          )}
        </>
      );
    }

    if (p.MaxRole === 'Teacher') {
      icons = (
        <>
          {props.settings.TeacherRegistrationFee && (
            <>
              {p.TeacherPaid ? (
                <span className="text-success" title="teacher has paid">
                  <Icon type={IconType.money} />
                </span>
              ) : (
                <span className="text-danger" title="teacher has NOT paid">
                  <Icon type={IconType.money} />
                </span>
              )}
            </>
          )}
          {props.settings.AskIfTeacherIsAttending && (
            <>
              {p.TeacherIsAttending ? (
                <span className="text-success" title="teacher is planning to attend">
                  <Icon type={IconType.checkSquareO} />
                </span>
              ) : (
                <span className="text-danger" title="teacher is NOT attending">
                  <Icon type={IconType.squareO} />
                </span>
              )}
            </>
          )}
        </>
      );
    }

    let iconSet = false;
    if (!p.Adult) {
      iconSet = true;
      icon = <Icon type={IconType.child} addClass={`fa-2x bumper-vert-out-l ${p.TempPerson ? 'text-warning' : ''}`} />;
    }
    if (p.MaxRole === 'Teacher' || p.IsTeacher) {
      iconSet = true;
      icon = <Icon type={IconType.chalkboardTeacher} addClass={`fa-2x bumper-vert-out-l ${p.TempPerson ? 'text-warning' : ''}`} />;
    }
    if (p.MaxRole === 'Judge') {
      iconSet = true;
      icon = <Icon type={IconType.gavel} addClass={`fa-2x bumper-vert-out-l ${p.TempPerson ? 'text-warning' : ''}`} />;
    }

    if (p.MaxRole === 'Staff' || p.MaxRole === 'Admin' || p.MaxRole === 'Yoda') {
      iconSet = true;
      icon = (
        <>
          <Icon type={IconType.userCrown} addClass={`fa-2x bumper-vert-out-l ${p.TempPerson ? 'text-warning' : ''}`} />
          {p.IsTeacher && <Icon type={IconType.chalkboardTeacherLite} addClass="also-teacher" title="Also a Teacher" />}
        </>
      );
    }
    if (p.TempPerson && !iconSet) {
      icon = <Icon type={IconType.userClockSolid} addClass="text-warning fa-2x bumper-vert-out-l" />;
    }

    let specialIcons = (
      <>
        {p.IsTeacher && p.MaxRole !== 'Teacher' && (
          <span title="Teacher">
            <Icon type={IconType.chalkboardTeacherLite} addClass="also-icon" />
          </span>
        )}
        {p.IsModerator && (
          <span title="Proctor">
            <Icon type={IconType.balanceScaleRight} addClass="also-icon" />
          </span>
        )}
        {p.IsInterpreter && (
          <span title="Interpreter">
            <Icon type={IconType.language} addClass="also-icon" />
          </span>
        )}
        {p.IsSpecialAwardJudge && (
          <span title="Special Award Judge">
            <Icon type={IconType.award} addClass="also-icon" />
          </span>
        )}
        {p.IsExternalJudge && (
          <span title="External Judge">
            <Icon type={IconType.globeAmericas} addClass="also-icon" />
          </span>
        )}
        {p.IsInterpreter && p.PrimaryLanguage && <div>{p.PrimaryLanguage}</div>}
      </>
    );

    const mappedProjectTags = p.ProjectTags.map((ptag, j) => {
      let tag = props.projectTagDict?.[ptag];
      if (tag) {
        return <TagComponent key={`${ptag}-${j}-project`} tag={tag} />;
      }
    });
    const mappedPersonTags = p.PersonTags.map((ptag, j) => {
      let tag = props.personTagDict?.[ptag];
      if (tag) {
        return <TagComponent key={`${ptag}-${j}-person`} tag={tag} />;
      }
    });
    const mappedAutoTags = p.AutoTags.map((ptag, j) => {
      let tag = props.autoTagDict?.[ptag];
      if (tag) {
        return <TagComponent key={`${ptag}-${j}-auto`} tag={tag} />;
      }
    });

    if (p.TempPerson) {
      jsx = (
        <tr key={p.PersonId}>
          {' '}
          {props.showSelect && (
            <td>
              <div className="person-row-select">
                <input type="checkbox" disabled={true} />
              </div>
            </td>
          )}
          <td className="text-center people-col-1">
            <span className="text-button" onClick={handlePersonIconClick}>
              {icon}
            </span>
          </td>
          <td className="people-col-2">
            <strong>
              {p.LastName ?? ''}, {p.FirstName ?? ''}
            </strong>
            {p.FlowName && (
              <>
                <br />
                <span className="label label-default">{p.FlowName}</span>
              </>
            )}
            <br />
            {!p.IsPlaceHolder ? <>{p.Email ?? ''}</> : <span className="label label-warning">Placeholder</span>}
            <br />
            {p.Phone ?? ''}
            <br />
            {p.PromotedAt ? <>Promoted: {FormatDate(p.PromotedAt)} </> : <>Created: {FormatDate(p.AccountCreatedAtUtc)}  </>}
          </td>
          <td className="people-col-3"></td>
          <td className="people-col-4">
            {p.PrecentComplete > 0 && (
              <div className="progress">
                <div
                  className="progress-bar progress-bar-striped progress-bar-success"
                  role="progressbar"
                  style={{ width: `${p.PrecentComplete}%` }}
                  aria-valuenow={p.PrecentComplete}
                  aria-valuemin={0}
                  aria-valuemax={100}></div>
              </div>
            )}
            {p.CurrentTab && <>Current Tab: {p.CurrentTab}</>}
            {p.PrecentComplete > 0 && <> {p.PrecentComplete.toFixed(0)}%</>}
            <br />
            <a href={p.CreateContinueLink ?? ''} target="_blank">
              Continue{' '}
            </a>
            <br />
          </td>
          <td className="people-col-5"></td>
          <td className="people-col-6"></td>
        </tr>
      );
    } else if (p.Adult) {
      jsx = (
        <tr key={p.PersonId}>
          {' '}
          {props.showSelect && (
            <td>
              <div className="person-row-select">
                <input
                  type="checkbox"
                  checked={props.selected}
                  onChange={(e) => {
                    props.setSelected(e.target.checked);
                  }}
                />
              </div>
            </td>
          )}
          <td className="head-room text-center people-col-1">
            <span className="text-button person-icon" onClick={handlePersonIconClick}>
              {icon}
            </span>
          </td>
          <td className="people-col-2" >
            <div onClick={handlePersonIconClick} className='click'>
            <strong>
              {p.LastName ?? ''}, {p.FirstName ?? ''}
            </strong>
            {p.FlowName && (
              <>
                <br />
                <span className="label label-default">{p.FlowName}</span>
              </>
            )}
            <br />
            {!p.IsPlaceHolder ? <>{p.Email ?? ''}</> : <span className="label label-warning">Placeholder</span>}
            <br />
            {p.Phone ?? ''}
            {p.LastCheckedInAt && (
              <>
                <br />
                Check-In: {p.LastCheckedInAt}
              </>
            )}
            <br />
            {p.PromotedAt ? <>Promoted: {FormatDate(p.PromotedAt)} </> : <>Created: {FormatDate(p.AccountCreatedAtUtc)} </>}
            </div>
          </td>
          <td className="people-col-3">{specialIcons}</td>
          <td className="people-col-4 tag-bar">
            {mappedPersonTags ?? ''}
            {mappedProjectTags ?? ''}
            {mappedAutoTags ?? ''}
          </td>
          <td className="people-col-5"></td>
          <td className="people-col-6">
            <strong>{icons ?? ''}</strong>
          </td>
        </tr>
      );
    } else {
      jsx = (
        <tr key={`${p.Id}-${p.LastName}`} className={`${entryStatusClass(p)}`}>
          {props.showSelect && (
            <td>
              <div className="person-row-select">
                <input
                  type="checkbox"
                  checked={props.selected}
                  onChange={(e) => {
                    props.setSelected(e.target.checked);
                  }}
                />
              </div>
            </td>
          )}
          <td className="head-room text-center people-col-1">
            <span className="text-button person-icon" onClick={handlePersonIconClick}>
              {icon}
            </span>
            {entryStatus(p)}
          </td>
          <td className="people-col-2">
            <div onClick={handlePersonIconClick} className='click'>
            <strong>
              {p.LastName}, {p.FirstName}
            </strong>
            {p.FlowName && (
              <>
                <br />
                <span className="label label-default">{p.FlowName}</span>
              </>
            )}
            <br />
            {!p.IsPlaceHolder ? <>{p.Email ?? ''}</> : <span className="label label-warning">Placeholder</span>}
            <br />
            {p.Phone}
            {p.Team && p.NumberOnTeam <= 1 && (
              <div className="text-danger">
                <Icon type={IconType.team} /> No Team Members
              </div>
            )}

            <br />
            {p.PromotedAt ? <>Promoted: {FormatDate(p.PromotedAt)} </> : <>Created: {FormatDate(p.AccountCreatedAtUtc)} </>}
            </div>
          </td>
          <td className="people-col-3">
            {p.ProjectId}
            <br />
            {p.Category}
            <br />
            {p.Grade}
          </td>
          <td className="people-col-4">
            {(p.IsefRegionalApprovalAt || p.IsefStateApprovalAt) && (
              <div className="isef-summary-approvals">
                {p.IsefRegionalApprovalAt && (
                  <span className="">
                    <Icon type={IconType.checkSquareO} /> Regional
                  </span>
                )}
                {p.IsefStateApprovalAt && (
                  <span className="">
                    <Icon type={IconType.checkSquareO} /> State
                  </span>
                )}
              </div>
            )}
            {p.Title ?? ''}
            {props.settings.ShowTags && (
              <div className="tag-bar">
                {mappedPersonTags ?? ''}
                {mappedProjectTags ?? ''}
                {mappedAutoTags ?? ''}
              </div>
            )}
          </td>
          <td className="people-col-5">
            {p.School ?? ''}
            <br />
            {p.TeacherName ?? ''}
            <br />
            {p.TeacherEmail && p.TeacherEmail !== '===' ? p.TeacherEmail : ''}
          </td>
          <td className="people-col-6">
            <strong>{icons}</strong>
          </td>
        </tr>
      );
    }
    return jsx;
  };

  return <>{whatToShow()}</>;
};

export default PersonRow;
