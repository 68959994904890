import React, { useState, useEffect } from 'react'
import { store } from "../../redux/oldStore";
import { toast } from 'react-toastify'
import { ServerResponse } from '../../utils/Server';


const JudgingJudgeAssignmentModal = (props) => {
  const {
    projects
    , projectsByKey
    , assignments
    , judge
    , round
    , setAssignments
  } = props;
  const [selectedProjectId, setSelectedProjectId] = useState('0');
  const [projectsToAssign, setProjectsToAssign] = useState([]);
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');

  // not sure where projects to assign comes from
  useEffect(() => {
    let j = [];
    let a = {};
    assignments.forEach(x => {
      a[x.ProjectId] = true;
    });
    projects.forEach((x, i) => {
      if (!a[x.ProjectIntId]) {
        j.push(x);
      }
    });
    setProjectsToAssign(j);
  }, [assignments, projects])

  const mappedOptions = projectsToAssign.map(project => {
    return (
      <option
        key={project.ProjectIntId}
        value={project.ProjectIntId}
      >
        {project.ProjectId} {project.Title}
      </option>
    )
  })


  const handleSave = () => {
    let request = {
      projectId: selectedProjectId,
      projectKey: projectsByKey[selectedProjectId].ProjectKey,
      judgeId: judge.Id,
      roundId: round.Id,
      date,
      time
    };
    let callback = (data) => {
      toast.success(`Assignment made.`);
      setAssignments([...props.fullAssignments, data.assignment])
      props.setModalOpen(false)

    };
    store.server.postApi<ServerResponse<any>>('../JudgingManagement/Assign', request)
      .then( res => {
        console.log(res)
        callback(res.Value)
      })
      .catch(err => console.error(err));
  }

  return (
    <>
      {props.judge
        &&
        <div>
          <div className="">
            <div className="form-horizontal">
              <strong></strong>
              <div className="form-group">
                <label className="control-label col-sm-2" htmlFor="round-edit-name">Project</label>
                <div className="col-sm-6">
                  <select
                    className="form-control select-project-dropdown"
                    onChange={(e) => setSelectedProjectId(e.target.value)}

                  >
                    <option value="0">select...</option>
                    {mappedOptions}
                  </select>
                </div>
              </div>
              {round.Values?.SchedulesInterviews
                &&
                <div className="form-group">
                  <label className="control-label col-sm-2" htmlFor="round-edit-name">At</label>
                  <div className="col-sm-5">
                    <input
                      v-model="date"
                      type="date"
                      placeholder='mm/dd/yyyy'
                      className="form-control wider-input-d"
                      onChange={e => setDate(e.target.value)}
                    />
                  </div>
                  <div className="col-sm-5">
                    <input
                      type="time"
                      placeholder='--:-- --'
                      className="form-control wider-input-t"
                      onChange={e => setTime(e.target.value)}
                    />
                  </div>
                </div>

              }
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary assign-project-btn"
              onClick={handleSave}>
              <i className="fad fa-box-full"></i>Assign Project
              </button>
          </div>
        </div>
      }
    </>
  )
}

export default JudgingJudgeAssignmentModal