import React, { useState, useEffect } from "react";
import Icon, { IconType } from "../../Icon/Icon";
import Modal from "../../Modal/Modal";
import { IEventSession } from "./EventSessionsType";
import { toast } from "react-toastify";
import { store } from "../../../redux/oldStore";
import { ServerResponse } from "../../../utils/Server";

interface IEventSessionManagerProps {}

const EventSessionManager = (props: IEventSessionManagerProps) => {
  const [sessions, setSessions] = useState<IEventSession[]>([]);
  const [selectedSession, setSelectedSession] = useState<IEventSession>({});
  const [showEventSessionManager, setShowEventSessionManager] =
    useState<boolean>(false);

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    store.server
      .getApi<ServerResponse<IEventSession[]>>("../EventSession/Info")
      .then((x) => {
        if (x.Success) {
          setSessions(x.Value);
        } else {
          toast.error(x.Message, { autoClose: false });
        }
      });
  };

  const saveSession = () => {
    let request = selectedSession;
    request.EndTime = request.EndTimeString;
    request.StartTime = request.StartTimeString;

    let errors: string[] = [];

    if (!request.Name) errors.push("Name");

    if (errors.length > 0) {
      toast.warning(
        <>
          You are missing some required information:
          <ul>
            {errors.map((e) => {
              return <li>{e}</li>;
            })}
          </ul>
        </>
      );
      return;
    }
    store.server
      .postApi<ServerResponse<IEventSession>>("../EventSession/Save", request)
      .then((x) => {
        if (x.Success) {
          let sList = [...sessions];
          let found = false;
          for (let i = 0; i < sList.length; i++) {
            let s = sList[i];
            if (s.Id === x.Value.Id) {
              sList[i] = x.Value;
              found = true;
              break;
            }
          }
          if (!found) sList.push(x.Value);
          setSessions(sList);
          toast.info("Event session saved!");
          setShowEventSessionManager(false);
          setSelectedSession({});
        } else {
          toast.error(x.Message, { autoClose: false });
        }
      });
  };

  const removeSession = () => {
    if (
      window.confirm(
        `Are you sure you want to remove event session "${selectedSession.Name}"?`
      )
    ) {
      store.server
        .postApi<ServerResponse<boolean>>(
          `../EventSession/Remove`,
          selectedSession
        )
        .then((x) => {
          if (x.Success) {
            setSessions(sessions.filter((s) => s.Id !== selectedSession.Id));
            toast.info(`Event session "${selectedSession.Name}" removed.`);
            setShowEventSessionManager(false);
            setSelectedSession({});
          } else {
            toast.error(x.Message, { autoClose: false });
          }
        });
    }
  };

  return (
    <>
      <div className="col-sm-12">
        <div className="flex-between">
          <h3>Event Sessions</h3>
          <div>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                setSelectedSession({});
                setShowEventSessionManager(true);
              }}
            >
              <Icon type={IconType.plus} /> Add Event Session
            </button>
          </div>
        </div>
        Event sessions allow you to define different sessions of your event;
        such as a morning session or night session.
        <hr />
        <div className="event-session-list-container">
          {sessions?.map((x, i) => {
            return (
              <div className="event-session" key={`es-${i}`}>
                <div className="flex-between">
                  <h4>
                    {x.Name}
                    {x.DoPopupCheckIn && <Icon type={IconType.users} />}
                  </h4>

                  <button
                    type="button"
                    className="btn btn-default btn-xs"
                    onClick={() => {
                      setSelectedSession(x);
                      setShowEventSessionManager(true);
                    }}
                  >
                    <Icon type={IconType.edit} /> Edit
                  </button>
                </div>
                <div>
                  {x.SpecialAwardsInterviewSession && <span className="judging-session"><Icon type={IconType.gavel} /> {x.InterviewBlockTimeMinutes ?? "?"} Minutes</span>}
                  {x.StartTimeString && (
                    <>
                      {x.StartTimeDisplay} to {x.EndTimeDisplay}
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {showEventSessionManager && (
        <Modal
          setModalOpen={setShowEventSessionManager}
          title={"Manage Event Session"}
          size="l"
        >
          <div className="form-horizontal">
            <div className="form-group">
              <label className="control-label col-sm-4" htmlFor="es-name">
                Name
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  id="es-name"
                  className="form-control"
                  value={selectedSession.Name}
                  onChange={(e) => {
                    setSelectedSession({
                      ...selectedSession,
                      Name: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="control-label col-sm-4" htmlFor="es-start">
                Start
              </label>
              <div className="col-sm-8">
                <input
                  type="datetime-local"
                  id="es-start"
                  className="form-control"
                  value={selectedSession.StartTimeString}
                  onChange={(e) => {
                    setSelectedSession({
                      ...selectedSession,
                      StartTimeString: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="control-label col-sm-4" htmlFor="es-end">
                End
              </label>
              <div className="col-sm-8">
                <input
                  type="datetime-local"
                  id="es-end"
                  className="form-control"
                  min={selectedSession.StartTimeString}
                  value={selectedSession.EndTimeString}
                  onChange={(e) => {
                    setSelectedSession({
                      ...selectedSession,
                      EndTimeString: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="form-group">
              <div className="col-sm-offset-4 col-sm-8">
                <input
                  type="checkbox"
                  id="es-check-in"
                  className=""
                  checked={selectedSession.DoPopupCheckIn}
                  onChange={(e) => {
                    setSelectedSession({
                      ...selectedSession,
                      DoPopupCheckIn: e.target.checked,
                    });
                  }}
                />
                <label className="control-label" htmlFor="es-check-in">
                  Do check-in
                </label>
              </div>
            </div>
            <div className="form-group">
              <div className="col-sm-offset-4 col-sm-8">
                <input
                  type="checkbox"
                  id="es-saj-interivew"
                  className=""
                  checked={selectedSession.SpecialAwardsInterviewSession}
                  onChange={(e) => {
                    setSelectedSession({
                      ...selectedSession,
                      SpecialAwardsInterviewSession: e.target.checked,
                    });
                  }}
                />
                <label className="control-label" htmlFor="es-saj-interivew">
                  Do Special Awards Judge Interview Time
                </label>
              </div>
            </div>
            <div className="form-group">
              <label
                className="control-label col-sm-4"
                htmlFor="es-interview-minutes"
              >
                Interview (Minutes)
              </label>
              <div className="col-sm-8">
                <input
                  type="number"
                  id="es-interview-minutes"
                  className="form-control"
                  value={selectedSession.InterviewBlockTimeMinutes}
                  onChange={(e) => {
                    setSelectedSession({
                      ...selectedSession,
                      InterviewBlockTimeMinutes: +e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="form-group">
              <div className="col-sm-offset-4 col-sm-8">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    saveSession();
                  }}
                >
                  <Icon type={IconType.save} /> Save
                </button>
                {selectedSession.Id && (
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={() => {
                      removeSession();
                    }}
                  >
                    <Icon type={IconType.delete} /> Remove
                  </button>
                )}
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default EventSessionManager;
