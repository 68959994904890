import React, { useState, useEffect } from "react";
import { store } from "../../redux/oldStore";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  setProjectsAssignments,
  setSelectedProject,
} from "../../redux/judgingSetup/actions";
import Icon, { IconType } from "../Icon/Icon";
import { ServerResponse } from "../../utils/Server";

interface IInfo {
  isModerator: boolean;
  isInterpreter: boolean;
  isSpecialAwardJudge: boolean;
  hasInterviewRoom: boolean;
  SpecialAwardJudgesRequestInterview: boolean;
  interviewRoom: IVirtualRoom;
  rounds: IRound[];
  sessions: IRound[];
  user: IUser;
  projects: IProject[];
  person: IPerson;
  categories: ICategory[];
  text: ITextStuff;
}

interface ITextStuff {
  requestInterviewHeader: string;
  scheduleHeader: string;
}

interface IVirtualRoom {
  ownerId?: string;
  Name?: string;
  Id?: number;
  RoomKey?: string;
  RoomPin?: string;
}

interface ICategory {
  name: string;
  id: number;
}

export interface ISlot {
  session: number;
  at: string;
}

interface IPerson {
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  publicId: string;
  id: number;
}
interface IProject {
  projectId: string;
  id: number;
  projectKey: string;
  title: string;
  category: string;
  categoryId: number;
  InPerson:boolean;
}

export interface IRound {
  name: string;
  slots: ISlot[];
}

interface IUser {
  publicId: string;
  personId: number;
  isStaff: boolean;
}

export interface IAssignments {
  at: string;
  atList: string[] | undefined;
  recored: boolean;
  type: string;
  projectId: string;
  projectKey: string;
  judgeId: number;
  id: number;
  meetingId: string;
  UseJudgesVirtualRoom?: boolean;
  RoomKey?:string;
  RoomPin?:string;
}

const AdvancedJudgingLanding = (props: any) => {
  const allCategories: ICategory = { id: 0, name: "All Categories" };
  const { projects, settings, videoRoomJudges, judges } = props;
  const params = new URLSearchParams(window.location.search);
  const fairId = params.get("f");
  const fairIdPara = fairId ? `f=${fairId}` : null;
  const [ready, setReady] = useState<boolean>(false);
  const [info, setInfo] = useState<IInfo | null>();
  const [isStaff, setIsStaff] = useState<boolean>(false);
  const { userId } = useParams<any>();
  const [assignments, setAssignments] = useState<IAssignments[]>();
  const [assignments2, setAssignments2] = useState<IAssignments[]>();
  const [projectDic, setProjectsDic] = useState({});
  const [allProjectAssignmentDic, setAllProjectsAssignmentDic] = useState({});
  const [filteredProjects, setFilteredProjects] = useState<IProject[]>();
  const [projectSearch, setProjectSearch] = useState<string | null>();
  const [projectsSchedule, setProjectsSchedule] = useState<
    IAssignments[] | null
  >();
  const [selectedProject, setSelectedProject] = useState<IProject | null>(null);
  const [projectAssignmentDic, setProjectsAssignmentDic] = useState({});
  const [projectSessionDic, setProjectsSessionDic] = useState({});
  const [selectedProjectsAssignments, setSelectedProjectsAssignments] =
    useState<IAssignments[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<ICategory | null>(
    null
  );
  const [selectedVirtualForm, setSelectedVirtualForm] = useState<IVirtualRoom>(
    {}
  );

  useEffect(() => {
    store.server
      .postApi<ServerResponse<any>>(
        "../JudgingManagement/AdvancedJudgeScheduleInfo",
        { personId: userId }
      )
      .then((x) => {
        if (x.Success) {
          setInfo(x.Value);
          setIsStaff(x.Value.user.isStaff);

          const dic = {};
          x.Value.projects.forEach((x: IProject) => {
            dic[x.projectKey] = x;
          });
          setProjectsDic(dic);
          setFilteredProjects(x.Value.projects);
        } else {
          toast.error(x.Message, { autoClose: false });
        }
      })
      .catch((x) => {
        console.log(x);
        toast.error(`Error setting up page.`, { autoClose: false });
      });

    getAssignments();
  }, []);

  const getAssignments = () => {
    store.server
      .postApi("../JudgingManagement/SpecialJudgesSchedule", {
        judgeId: userId,
      })
      .then((x: any) => {
        setAssignments(x.Value.assignments);
        setAssignments2(x.Value.assignments2);
        const dic = {};
        x.Value.assignments2.forEach((x: IAssignments) => {
          dic[x.projectId] = x;
        });
        let proSched = [...selectedProjectsAssignments];
        let assignment = x.Value.assignments.first;
        //setSelectedProjectsAssignments(x.Value.assignments?.filter(r=>r.projectKey));
        //setAllProjectsAssignmentDic(dic);
        setReady(true);
      })
      .catch((x) => {
        console.log(x);
        toast.error(`Error getting schedule.`, { autoClose: false });
      });
  };

  useEffect(() => {
    let search: string | undefined = projectSearch?.toLowerCase();
    if (
      (search === null || search?.length === 0) &&
      (!selectedCategory || selectedCategory.id === allCategories.id)
    ) {
      setFilteredProjects(info?.projects);
      return;
    }

    if (search === null || search?.length === 0) {
      setFilteredProjects(
        info?.projects.filter(
          (x) =>
            x &&
            (x.categoryId === selectedCategory?.id ||
              selectedCategory?.id === allCategories.id)
        )
      );
      return;
    }

    let projects = info?.projects.filter(
      (x: IProject) =>
        x &&
        (!selectedCategory ||
          x.categoryId === selectedCategory.id ||
          selectedCategory.id === allCategories.id) &&
        (x.projectId.toLowerCase().includes(search ?? "") ||
          x.title.toLowerCase().includes(search ?? ""))
    );

    setFilteredProjects(projects);
  }, [projectSearch, selectedCategory]);

  useEffect(() => {
    if (!selectedProject) {
      setProjectsSchedule(null);
      setProjectsAssignmentDic({});
      return;
    }

    getSelectedProjectsAssignments();
  }, [selectedProject]);

  const getSelectedProjectsAssignments = () => {
    store.server
      .postApi("../JudgingManagement/ProjectsSchedule", {
        projectKey: selectedProject?.projectKey,
      })
      .then((x: any) => {
        setProjectsSchedule(x.Value.assignments);
        const dic = {};
        x.Value.assignments.forEach((x: IAssignments) => {
          if (x.at) {
            dic[x.at] = x;
          }
        });
        const dicS = {};
        x.Value.sessions.forEach((x: number) => {
          dicS[x] = true;
        });
        setSelectedProjectsAssignments(x.Value.assignments ?? []);
        setProjectsSessionDic(dicS);
        setProjectsAssignmentDic(dic);
      })
      .catch((x) => {
        console.log(`error project ${x}`);
        toast.error(`Error getting projects schedule.`, { autoClose: false });
      });
  };

  const reserveSlot = (slot: string, useVirtualRoom?: boolean) => {
    if (!slot) {
      if (
        !window.confirm(
          `Are you sure you want to schedule an interview with entry: ${selectedProject?.projectId}?`
        )
      ) {
        return;
      }
    }

    store.server
      .postApi("../JudgingManagement/UpdateAdvanceJudgeSchedule", {
        id: null,
        at: slot,
        judgeId: info?.person.publicId,
        projectKey: selectedProject?.projectKey,
        remove: false,
        useVirtualRoom,
      })
      .then((x: any) => {
        setProjectsSchedule(x.Value.assignments);
        setSelectedProjectsAssignments(x.Value.assignments);
        const dic = {};
        x.Value.assignments.forEach((x: IAssignments) => {
          dic[x.at] = x;
        });
        setProjectsAssignmentDic(dic);

        getAssignments();
        toast.info("Request sent.");
      })
      .catch((x) => {
        console.log(x);
        toast.error(`Error scheduling interview.`, { autoClose: false });
      });
  };

  const removeSlot = (a: IAssignments) => {
    if (
      !window.confirm(
        `Are you sure you want to remove this scheduled interview at ${a.at} with project ${selectedProject?.projectId}`
      )
    ) {
      return;
    }

    store.server
      .postApi("../JudgingManagement/UpdateAdvanceJudgeSchedule", {
        id: a.id,
        at: a.at,
        judgeId: info?.person.publicId,
        projectKey: a.projectKey,
        remove: true,
      })
      .then((x: any) => {
        setProjectsSchedule(x.Value.assignments);
        setSelectedProjectsAssignments(x.Value.assignments);
        const dic = {};
        x.Value.assignments.forEach((x: IAssignments) => {
          dic[x.at] = x;
        });
        setProjectsAssignmentDic(dic);

        getAssignments();
        toast.info("Remove request sent.");
      })
      .catch((x) => {
        console.log(x);
        toast.error(`Error removing scheduled interview.`, {
          autoClose: false,
        });
      });
  };

  const assignProject = (project: IProject) => {
    const dic = Object.assign({}, allProjectAssignmentDic);
    const assigned = dic[project.id];
    console.log("here", assigned, project);
    let isAssigned: boolean = false;

    if (assigned) {
      isAssigned = true;
    }

    store.server
      .postApi("../JudgingManagement/UpdateAdvanceJudgeAssignment", {
        id: assigned?.id,
        judgeId: info?.person.publicId,
        projectKey: project.projectKey,
        remove: isAssigned,
      })
      .then((x: any) => {
        getAssignments();
      })
      .catch((x) => {
        console.log(x);
        toast.error(`Error removing scheduled interview.`, {
          autoClose: false,
        });
      });
  };
  const removeAssignment = (project: IAssignments) => {
    if (!window.confirm(`Are you sure you want to remove this assignment?`)) {
      return;
    }
    const dic = Object.assign({}, allProjectAssignmentDic);

    store.server
      .postApi("../JudgingManagement/UpdateAdvanceJudgeAssignment", {
        id: project.id,
        judgeId: info?.person.publicId,
        projectKey: project.projectKey,
        remove: true,
      })
      .then((x: any) => {
        getAssignments();
      })
      .catch((x) => {
        console.log(x);
        toast.error(`Error removing scheduled interview.`, {
          autoClose: false,
        });
      });
  };

  const reserveVirtualRoom = () => {
    let request = selectedVirtualForm;
    let errors: string[] = [];

    if (!request.Name) errors.push("Room Name");
    if (!request.RoomPin || request.RoomPin.length < 4) errors.push("Pin");

    if (errors.length) {
      toast.warning(
        <>
          You are missing some required fields.
          <ul>
            {errors.map((e, i) => {
              return <li key={`ei-${i}`}>{e}</li>;
            })}
          </ul>
        </>
      );
      return;
    }

    request.ownerId = info?.person.publicId;

    store.server
      .postApi<ServerResponse<IVirtualRoom>>(
        "../JudgingManagement/SaveVirtualRoom",
        request
      )
      .then((x: ServerResponse<IVirtualRoom>) => {
        if (info) setInfo({ ...info, interviewRoom: x.Value });
        toast.success(`Virtual room set: ${x.Value.Name}`);
      });
  };

  const showInPerson = (project:IProject) =>{
    if(project.InPerson)
    return <span className="text-info"><Icon type={IconType.userDuo}/> In Person </span>
    else <span></span>
  };

  return (
    <>
      <div className="advanced-judging-landing">
        {ready && (
          <div>
            <Tabs>
              <TabList>
                {info?.hasInterviewRoom && <Tab>Virtual Room</Tab>}
                <Tab>
                  <i className="fal fa-calendar"></i> {info?.person.firstName}{" "}
                  {info?.person.lastName}'s Schedule
                </Tab>
                {info?.isSpecialAwardJudge && (
                  <Tab>
                    <i className="fal fa-user-plus"></i> Schedule Interview
                  </Tab>
                )}
                {info?.user.isStaff &&
                  (info?.isModerator || info?.isInterpreter) && (
                    <Tab>
                      <i className="fal fa-flag"></i> Proctor/Interpreter Assign
                      to Project
                    </Tab>
                  )}
              </TabList>
              {info?.hasInterviewRoom && (
                <TabPanel>
                  {info.interviewRoom ? (
                    <>
                      <div className="">
                        <div className="">
                          <h3>Virtual Room</h3>
                          <hr />
                          <span title="go to room">
                            <a
                              href={`${window.location.origin}/app/video/${info.interviewRoom.RoomKey}?rp=${info.interviewRoom.RoomPin}&${fairIdPara}`}
                              target="_blank"
                              className="btn btn-secondary"
                            >
                              <Icon type={IconType.entry} /> Open Room
                            </a>
                          </span>
                          <span
                            className={`click fal fa-copy text-muted`}
                            onClick={() => {
                              window.navigator.clipboard.writeText(
                                `${window.location.origin}/app/video/${info.interviewRoom.RoomKey}?rp=${info.interviewRoom.RoomPin}&${fairIdPara}`
                              );
                            }}
                            title="copy meeting link"
                          >
                            {" "}
                            Copy Link
                          </span>
                        </div>

                        <div>
                          <h4>Requested Interviews</h4>
                          <hr />
                          <div className="requested-interview-container">
                            {assignments
                              ?.filter((x) => x.UseJudgesVirtualRoom)
                              .map((x, i) => {
                                let project = projectDic[x.projectKey];
                                return (
                                  <div
                                    key={`rici-${i}`}
                                    className="requested-interview-container-item"
                                  >
                                    {project?.projectId}{" "}
                                    {x.at && <span>at: {x.at}</span>}
                                    <div className="text-muted">
                                      {project?.title}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <h3>Virtual Room</h3>
                      In order to have participants schedule an interview with
                      you and your team you will need a virtual room where you
                      can meet. Provide a name and a pin for your virtual room
                      to get started.
                      <div className="form-horizontal">
                        <div className="form-group">
                          <label className="control-label col-sm-4">
                            Room Name
                          </label>
                          <div className="col-sm-5">
                            <input
                              type={"text"}
                              className="form-control"
                              maxLength={100}
                              value={selectedVirtualForm.Name}
                              onChange={(e) => {
                                setSelectedVirtualForm({
                                  ...selectedVirtualForm,
                                  Name: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-sm-4">Pin</label>
                          <div className="col-sm-5">
                            <input
                              type={"text"}
                              className="form-control"
                              maxLength={8}
                              placeholder="random 4 - 8 digit number"
                              value={selectedVirtualForm.RoomPin}
                              onChange={(e) => {
                                let x = e.target.value.replace(/[^0-9]/g, "");
                                setSelectedVirtualForm({
                                  ...selectedVirtualForm,
                                  RoomPin: x,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="col-sm-5 col-sm-offset-4">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={reserveVirtualRoom}
                            >
                              <Icon type={IconType.flag} /> Reserve Virtual Room
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </TabPanel>
              )}
              <TabPanel>
                {(assignments && assignments.length > 0) ||
                (assignments2 && assignments2.length > 0) ? (
                  <div>
                    <div className="alert alert-info">
                      Click <i className="fa fa-trash-can text-danger"></i> to
                      remove a time you have scheduled. Click{" "}
                      <i className="fal fa-tv-retro text-info"></i> to go to the
                      interview. Click{" "}
                      <i className="fal fa-copy text-muted"></i> to copy the
                      interview link.
                    </div>

                    {assignments && assignments.length > 0 && (
                      <>
                        {assignments.map((x: IAssignments, i: number) => {
                          const project = projectDic[
                            x.projectKey
                          ] as IProject | null;
                          return (
                            <div className="assignment" key={`assignment-${i}`}>
                              <div>
                                <div>{x.at}</div>
                                <div className="buttons">
                                  <span
                                    title="remove interview"
                                    className={`click fa fa-trash-can text-danger`}
                                    onClick={() => {
                                      removeSlot(x);
                                    }}
                                  ></span>
                                  {!x.UseJudgesVirtualRoom ? (
                                    <>
                                      <span title="go to interview">
                                        <a
                                          href={`${window.location.origin}/app/video/${x.meetingId}?${fairIdPara}`}
                                          target="_blank"
                                          className="fal fa-tv-retro"
                                        ></a>
                                      </span>
                                      <span
                                        className={`click fal fa-copy text-muted`}
                                        onClick={() => {
                                          window.navigator.clipboard.writeText(
                                            `${window.location.origin}/app/video/${x.meetingId}?${fairIdPara}`
                                          );
                                        }}
                                        title="copy meeting link"
                                      ></span>
                                    </>
                                  ) : (
                                    <>
                                      {info?.interviewRoom?.RoomPin ? (
                                        <>
                                          <span title="go to interview">
                                            <a
                                              href={`${window.location.origin}/app/video/${info.interviewRoom.RoomKey}?rp=${info.interviewRoom.RoomPin}&${fairIdPara}`}
                                              target="_blank"
                                              className="fal fa-tv-retro"
                                            ></a>
                                          </span>
                                          <span
                                            className={`click fal fa-copy text-muted`}
                                            onClick={() => {
                                              window.navigator.clipboard.writeText(
                                                `${window.location.origin}/app/video/${info.interviewRoom.RoomKey}?rp=${info.interviewRoom.RoomPin}&${fairIdPara}`
                                              );
                                            }}
                                            title="copy meeting link"
                                          ></span>
                                        </>
                                      ) : (
                                        <>
                                          <span className="label label-warning">
                                            Your virtual room
                                          </span>
                                        </>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                              <div>
                                <div className="strong">
                                  {project?.projectId}
                                </div>
                                <div>{project?.category}</div>
                              </div>
                              <div className="grow bummper-left">
                                <div>{project?.title}</div>
                                {info?.interviewRoom?.RoomPin &&
                                  x.UseJudgesVirtualRoom && (
                                    <span className="label label-warning">
                                      Your virtual room
                                    </span>
                                  )}
                              </div>
                            </div>
                          );
                        })}
                      </>
                    )}

                    {assignments2 && assignments2.length > 0 && (
                      <>
                        {assignments2.map((x: IAssignments, i: number) => {
                          const project = projectDic[
                            x.projectKey
                          ] as IProject | null;
                          return (
                            <div
                              className="assignment assignment2"
                              key={`assignment-${i}`}
                            >
                              <div>
                                <div>{x.at}</div>
                                <div className="buttons">
                                  <span
                                    title="remove interview"
                                    className={`click fa fa-trash-can text-danger`}
                                    onClick={() => {
                                      removeAssignment(x);
                                    }}
                                  ></span>
                                  <span title="go to interview">
                                    {x.UseJudgesVirtualRoom ? 
                                    <a
                                    href={`${window.location.origin}/app/video/${x.RoomKey}?rp=${x.RoomPin}&${fairIdPara}`}
                                    target="_blank"
                                    className="fal fa-tv-retro"
                                  ></a>
                                    :
                                    <a
                                      href={`${window.location.origin}/app/video/${x.meetingId}?${fairIdPara}`}
                                      target="_blank"
                                      className="fal fa-tv-retro"
                                    ></a>}
                                  </span>
                                  <span
                                    className={`click fal fa-copy text-muted`}
                                    onClick={() => {
                                      if(x.UseJudgesVirtualRoom){
                                        //${info.interviewRoom.RoomKey}?rp=${info.interviewRoom.RoomPin}
                                        window.navigator.clipboard.writeText(
                                          `${window.location.origin}/app/video/${x.RoomKey}?rp=${x.RoomPin}&${fairIdPara}`
                                        );
                                      } else {
                                      window.navigator.clipboard.writeText(
                                        `${window.location.origin}/app/video/${x.meetingId}?${fairIdPara}`
                                      );
                                      }
                                    }}
                                    title="copy meeting link"
                                  ></span>
                                  
                                </div>
                              </div>
                              <div>
                                <div className="strong">
                                  {project?.projectId}
                                  {x.RoomPin &&
                                  x.UseJudgesVirtualRoom && (
                                    <><br/>
                                    <span className="label label-warning">
                                      virtual room
                                    </span>
                                    </>
                                  )}
                                </div>
                                <div>{project?.category}</div>
                              </div>
                              <div className="grow bummper-left">
                                <div>{project?.title}</div>
                                <div className="times">
                                  {x.atList &&
                                    x.atList.length > 0 &&
                                    x.atList.map(
                                      (timeAt: string, timeAtIndex: number) => {
                                        return (
                                          <span key={`xx-k-${timeAtIndex}`}>
                                            <i className="fa fa-calendar-o"></i>{" "}
                                            {timeAt}
                                          </span>
                                        );
                                      }
                                    )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                ) : (
                  <div>
                    <div className="alert alert-info">
                      <i className="fad fa-exclamation-triangle"></i> No
                      assignments found at this time.
                    </div>
                  </div>
                )}
              </TabPanel>
              {info?.isSpecialAwardJudge && (
                <TabPanel>
                  {info?.categories &&
                  info?.categories.length > 0 &&
                  info?.projects &&
                  info.projects.length > 10 &&
                  !selectedCategory ? (
                    <div>
                      Click on a category tile to see projects within that
                      category.
                      <div className="category-tile-list">
                        {info.categories.map((x: ICategory, i: number) => {
                          return (
                            <div
                              className="category-tile"
                              key={`category-tile-${i}`}
                              onClick={() => {
                                setSelectedCategory(x);
                              }}
                            >
                              {x.name}
                            </div>
                          );
                        })}
                        <div
                          className="category-tile"
                          key={`category-tile-all`}
                          onClick={() => {
                            setSelectedCategory(allCategories);
                          }}
                        >
                          {allCategories.name}
                        </div>
                      </div>
                    </div>
                  ) : info?.projects && info.projects.length > 0 ? (
                    <div>
                      <div className="search-bar">
                        {selectedCategory && (
                          <div className="category">
                            {selectedCategory.name}
                            <i
                              className="click fa fa-trash-can text-danger"
                              onClick={() => {
                                setSelectedCategory(null);
                              }}
                              title="remove category"
                            ></i>
                          </div>
                        )}
                        <div className="flex">
                          <label htmlFor="search-project">Search</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) => {
                              setProjectSearch(e.target.value);
                            }}
                            id="search-project"
                            placeholder="search by project id or title"
                          ></input>
                        </div>
                      </div>
                      <div>
                        Click the <i className="fal fa-calendar"></i> icon to
                        view a projects schedule.
                      </div>

                      <div className="project-search-list">
                        {filteredProjects?.map((x: IProject, i: number) => {
                          return (
                            <div
                              className="project"
                              key={`project-list-item-${i}`}
                            >
                              <div className="main">
                                <div className="flex-between">
                                  <div className="button-panel">
                                    <span
                                      title="view projects schedule"
                                      className="click fa-2x fal fa-calendar"
                                      onClick={() => {
                                        if (selectedProject === x) {
                                          setSelectedProject(null);
                                          return;
                                        }
                                        setSelectedProject(x);
                                      }}
                                    ></span>
                                  </div>
                                  <div>
                                    <div className="strong">{x.projectId} {showInPerson(x)}</div>
                                    <div>{x.category}</div>
                                  </div>
                                </div>
                                <div>{x.title}</div>
                              </div>
                              {selectedProject?.projectKey === x.projectKey && (
                                <div className="">

                                  <div className="schedule-slots">
                                    {info.SpecialAwardJudgesRequestInterview && (
                                      <div className="panel panel-default">
                                        <div className="panel-heading">
                                          Requested Interview
                                        </div>
                                        <div className="panel-body">
                                            <div dangerouslySetInnerHTML={{__html:info?.text.requestInterviewHeader}}></div>
                                          {selectedProjectsAssignments &&
                                            selectedProjectsAssignments
                                              ?.filter(
                                                (x) =>
                                                  x.UseJudgesVirtualRoom &&
                                                  x.judgeId === info?.person.id
                                              )
                                              ?.map((x, i) => {
                                                let project =
                                                  projectDic[x.projectKey];
                                                return (
                                                  <div key={`ja-${i}`}>
                                                    <div className="flex-between">
                                                      <span>
                                                        {project.projectId}
                                                      </span>{" "}
                                                      {x.at && (
                                                        <span>at: {x.at}</span>
                                                      )}{" "}
                                                      <span
                                                        title="remove interview time"
                                                        className={`click fa fa-trash-can text-danger`}
                                                        onClick={() => {
                                                          removeSlot(x);
                                                        }}
                                                      ></span>
                                                    </div>
                                                  </div>
                                                );
                                              })}
                                          <div className="headroom-xl"></div>
                                          <button
                                            type="button"
                                            className="btn btn-secondary"
                                            onClick={() => {
                                              reserveSlot("", true);
                                            }}
                                          >
                                            <Icon type={IconType.calendar} />{" "}
                                            Request Interview
                                          </button>
                                        </div>
                                      </div>
                                    )}

                                    {!x.InPerson && info.rounds.map(
                                      (r: IRound, ri: number) => {
                                        if (!r.slots || r.slots.length === 0)
                                          return null;
                                        var slots = r.slots.filter(
                                          (x) => projectSessionDic[x.session]
                                        );
                                        if (!slots || slots.length === 0)
                                          return null;

                                        return (
                                          <div
                                            className="panel panel-default"
                                            key={`schedule-slot-${ri}`}
                                          >
                                            <div className="panel-heading">
                                              {r.name}
                                            </div>
                                            <div className="panel-body">
                                                
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                    __html: info?.text.scheduleHeader,
                                                    }}
                                                ></div>
                                              <div className="slot-list">
                                                {r.slots
                                                  .filter(
                                                    (x) =>
                                                      projectSessionDic[
                                                        x.session
                                                      ]
                                                  )
                                                  .map(
                                                    (s: ISlot, si: number) => {
                                                      const aa =
                                                        projectAssignmentDic[
                                                          s.at
                                                        ];
                                                      const a = aa?.at;
                                                      const self =
                                                        aa?.judgeId ===
                                                        info.person.id;

                                                      return (
                                                        <div
                                                          className={`timeslot ${
                                                            self ? "self" : ""
                                                          }`}
                                                          key={`slot-time-${si}`}
                                                        >
                                                          <div>
                                                            <span
                                                              className={`click ${
                                                                a ? "fa" : "fal"
                                                              } fa-calendar`}
                                                              onClick={() => {
                                                                if (a) {
                                                                  toast.info(
                                                                    "This slot is already reserved."
                                                                  );
                                                                  return;
                                                                }
                                                                reserveSlot(
                                                                  s.at
                                                                );
                                                              }}
                                                            ></span>
                                                            {s.at}
                                                          </div>
                                                          {aa &&
                                                            aa.judgeId ===
                                                              info.person
                                                                .id && (
                                                              <span
                                                                title="remove interview time"
                                                                className={`click fa fa-trash-can text-danger`}
                                                                onClick={() => {
                                                                  removeSlot(a);
                                                                }}
                                                              ></span>
                                                            )}
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="alert alert-info">
                        <i className="fad fa-exclamation-triangle"></i> No
                        projects found at this time.
                      </div>
                    </div>
                  )}
                </TabPanel>
              )}
              {info?.user.isStaff &&
                (info?.isModerator || info?.isInterpreter) && (
                  <TabPanel>
                    {info?.categories &&
                    info?.categories.length > 0 &&
                    info?.projects &&
                    info.projects.length > 10 &&
                    !selectedCategory ? (
                      <div>
                        Click on a category tile to see projects within that
                        category.
                        <div className="category-tile-list">
                          {info.categories.map((x: ICategory, i: number) => {
                            return (
                              <div
                                className="category-tile"
                                key={`category-tile-${i}`}
                                onClick={() => {
                                  setSelectedCategory(x);
                                }}
                              >
                                {x.name}
                              </div>
                            );
                          })}
                          <div
                            className="category-tile"
                            key={`category-tile-all`}
                            onClick={() => {
                              setSelectedCategory(allCategories);
                            }}
                          >
                            {allCategories.name}
                          </div>
                        </div>
                      </div>
                    ) : info?.projects && info.projects.length > 0 ? (
                      <div>
                        <div className="search-bar">
                          {selectedCategory && (
                            <div className="category">
                              {selectedCategory.name}
                              <i
                                className="click fa fa-trash-can text-danger"
                                onClick={() => {
                                  setSelectedCategory(null);
                                }}
                                title="remove category"
                              ></i>
                            </div>
                          )}
                          <div className="flex">
                            <label htmlFor="search-project">Search</label>
                            <input
                              type="text"
                              className="form-control"
                              onChange={(e) => {
                                setProjectSearch(e.target.value);
                              }}
                              id="search-project"
                              placeholder="search by project id or title"
                            ></input>
                          </div>
                        </div>
                        <div>
                          Click the <i className="fal fa-flag"></i> icon to
                          assign this entry to {info.person.firstName}{" "}
                          {info.person.lastName}.
                        </div>

                        <div className="project-search-list">
                          {filteredProjects?.map((x: IProject, i: number) => {
                            return (
                              <div
                                className="project"
                                key={`project-list-item-${i}`}
                              >
                                <div className="main">
                                  <div className="flex-between">
                                    <div className="button-panel">
                                      <span
                                        title="click to assign entry"
                                        className={`click fa-2x fal fa-flag ${
                                          allProjectAssignmentDic[x.id]
                                            ? "assigned"
                                            : ""
                                        }`}
                                        onClick={() => {
                                          assignProject(x);
                                        }}
                                      ></span>
                                    </div>
                                    <div>
                                      <div className="strong">
                                        {x.projectId} {showInPerson(x)}
                                      </div>
                                      <div>{x.category}</div>
                                    </div>
                                  </div>
                                  <div>{x.title}</div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="alert alert-info">
                          <i className="fad fa-exclamation-triangle"></i> No
                          projects found at this time.
                        </div>
                      </div>
                    )}
                  </TabPanel>
                )}
            </Tabs>

            {/* <div className="flex-between">
            <div className="strong">
                {info?.person.firstName} {info?.person.lastName}
            </div>
            <div>
                <button type="button" className="btn btn-xs btn-success"><i className="fa fa-user-plus"></i>Add Assignment</button>
            </div>
          </div> */}
            <div className="headroom-xl"></div>
          </div>
        )}
      </div>
    </>
  );
};

export default AdvancedJudgingLanding;
