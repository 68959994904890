import { useParams } from "react-router-dom";
import {IState, ReduxMap } from "../../redux/redux";
import FlexFlow from "../../Components/FlexFlow/FlexFlow";

interface IProps extends IState{

}

const FlexFlowPage = (props:IProps) =>{
const {flowId, recordId} = useParams();

    return <>
        {props.IsReady && flowId && <FlexFlow state={props} flowId={flowId} recordId={recordId} />}
    </>;
};

export default ReduxMap(FlexFlowPage);