import React, { useState, useEffect, useLayoutEffect } from "react";
import { toast } from "react-toastify";
import MyTimer from "./MyTimer";


const TimerControls = (props: any) => {
  const [timerInfo, setTimer] = useState({

    showInterviewTimer: false,
  });
  const [panelMode, setPanelMode] = useState('interview')
  const [socket, setSocket] = useState();

  useEffect(() => {
    const s = props.socket
    setSocket(s);
  }, [props.socket]);


  const interviewTimerCallbackArray = [
    {
      time: 10,
      callback: () => {
        toast.info("Timer starts now");
        // TIMER_START_SOUND();
      },
    },
  ];

  const openInterviewTimer = () => {

    setTimer({showInterviewTimer: true})
    props.socket.emit("room-message", {
      projectId: props.projectId,
      type: "interview-start",
    });
  };

  const closeAllTimers = () => {
    setTimer({showInterviewTimer: false})

    props.socket.emit("room-message", {
      projectId: props.projectId,
      type: "close-all-timers",
    });
  };


  const setPanelNone = () => {
    if (panelMode === 'interview') {
      setPanelMode('none');
      closeAllTimers();
    };
  }

  const setPanelInterview = () => setPanelMode('interview');

  return (
    <div className="control-panels">
      {true && 
        <div className='flex-center column-when-small'>
          <span className="btn-group">
          <button
            className='btn btn-info'
            onClick={()=>{ setPanelInterview(); openInterviewTimer();}}
          >
            <i className="fad fa-stopwatch"></i> Start
            </button>
            <button
                className='btn btn-danger'
                onClick={closeAllTimers}
              >
                Stop
              </button>
            </span>

            {timerInfo.showInterviewTimer && (
                  <>
                    <MyTimer
                      initialTime={0}
                      direction="forward"
                      checkpoints={interviewTimerCallbackArray}
                    />
                  </>
                )}
        </div>
      }              

    </div>
  );
};

export default TimerControls;
