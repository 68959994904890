import { fabric } from "fabric";
import { v4 as uuidv4 } from "uuid";
import { CanvasEventFunction, doesPlacementModeApply, PlaceCursor, CanvasThingie, InputPlacementMode } from "./PdfSelfSignEventMethods";
import { PdfSelfSignExtendedInputs } from "./PdfSelfSignExtendedInputs";
import { CreateFreeformFieldOptions } from "./CreateFieldOptions";

export const handlePlaceFreeFormTextField: CanvasEventFunction = (pdfSelfSign: PdfSelfSignExtendedInputs, canvasThingie: CanvasThingie, options?: CreateFreeformFieldOptions) => {
    
    const tempOptions: CreateFreeformFieldOptions = {
        signerLabel: pdfSelfSign.state.activeSigner.label,
        // fill: pdfSelfSign.state.activeSigner.color,
        fontFamily: "Helvetica",
        backgroundColor: pdfSelfSign.state.activeSigner.color,
        fontSize: pdfSelfSign.state.fontSize * pdfSelfSign.scale,
        fontColor: pdfSelfSign.state.fontColor,
        text: "Free Text",
        ...options
    }
    const freeformTextCursor = buildFreeFormCursor(tempOptions);
    const placementMode: InputPlacementMode = InputPlacementMode.freeformTextPlacement
    canvasThingie.fabricCanvas.on("mouse:move", (o) => {
        if (doesPlacementModeApply(o.pdfSelfSignState, placementMode)) {
            PlaceCursor(canvasThingie, o, freeformTextCursor);
        }
    });

    canvasThingie.fabricCanvas.on("mouse:out", (o) => {
        if (doesPlacementModeApply(o.pdfSelfSignState, placementMode)) {
            canvasThingie.fabricCanvas.remove(freeformTextCursor);
        }
    });

    canvasThingie.fabricCanvas.on("mouse:up", (o) => {
        if (o.button === 1) {
            if (!o.target && !o.pdfSelfSignState.isPlacementMode) {
                if (o.pdfSelfSignState.inputPlacementMode === placementMode) {
                    PlaceCursor(canvasThingie, o, freeformTextCursor);
                }
                return;
            }
            let pass = doesPlacementModeApply(o.pdfSelfSignState, placementMode);            
            if (pass) {
                tempOptions.text = getText();
                let textBlock = buildFreeFormCursor(tempOptions);
                //todo:  get double click editing of the text field to work.
                // textBlock.on("mousedblclick", ()=>{
                //     console.log("here is the text bolck", textBlock);
                //     (textBlock._objects[1] as fabric.IText).set({text: getText()});
                // });

                textBlock.uniqueId = uuidv4();
                textBlock.set({
                    left: freeformTextCursor.left,
                    top: freeformTextCursor.top
                });
                console.log("Added freeform field to canvas");

                canvasThingie.fabricCanvas.add(textBlock);
                canvasThingie.fabricCanvas.renderAll();
                pdfSelfSign.saveInput(textBlock);
            }
        }
    });
}

const getText = () => {
    return prompt("Enter Text") || "";
}

//TODO:  probably should not pass in the pdfSelfSign object but instead pass in the text options.
const buildFreeFormCursor = (options?: CreateFreeformFieldOptions) => {
    // let color = pdfSelfSign.state.signers[signerName].color;
    let text = new fabric.IText(options?.text || "Here it is", {
        fontFamily: options?.fontFamily || "Brush Script MT",
        fontSize: options?.fontSize,
        fill: options?.fontColor,
        originX: 'center',
        originY: 'center',
        left: 0,
        lockRotation: true,
        hasRotatingPoint: false,
        editable: false
    });

    let rect = new fabric.Rect({
        originX: 'center',
        originY: 'center',
        fill: "white", //todo:  fill color
        height: text.height,
        width: text.width
    });

    let group = new fabric.Group([rect, text]);
    group.inputType = "Text";
    group._signerLabel = options?.signerLabel || "signer";


    return group;
}