import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { IState } from '../../redux/redux';
import JudgeGroupManager from '../../Components/JudgingSetup/JudgeGroup/JudgeGroupManager';
import JudgingCirclesAssignmentManager from '../../Components/JudgingSetup/JudgingCircles/JudgingCirclesAssignmentManager';
import JudgingCirclesManager from '../../Components/JudgingSetup/JudgingCircles/JudgingCirclesManager';
import Icon, { IconType } from '../../Components/Icon/Icon';
import JudgingRoundsManager from '../../Components/JudgingSetup/JudgingRounds/JudgingRoundsManager';

interface IProps extends IState {}

const JudgingCirclesMain = (props: IProps) => {
  return (
    <>
      <h3>Judging Circle Management</h3>
      <Tabs>
        <TabList>
          <Tab>Judging Rounds</Tab>
          <Tab><Icon type={IconType.circle}/> Circles</Tab>
          <Tab>Judging Assignments</Tab>
          <Tab>Judge Groups</Tab>
        </TabList>
        <TabPanel>
            <JudgingRoundsManager state={props} />
        </TabPanel>
        <TabPanel>
            <JudgingCirclesManager state={props}/>
        </TabPanel>
        <TabPanel>
          <JudgingCirclesAssignmentManager state={props} />
        </TabPanel>
        <TabPanel>
          <JudgeGroupManager state={props} />
        </TabPanel>
      </Tabs>
    </>
  );
};

export default JudgingCirclesMain;
