import { v4 as uuidv4 } from "uuid";
import { fabric } from "fabric";
import { CanvasEventFunction, CanvasThingie, InputPlacementMode, doesPlacementModeApply, PlaceCursor } from "./PdfSelfSignEventMethods";
import { PdfSelfSignExtendedInputs } from "./PdfSelfSignExtendedInputs";
import { CreateNameFieldOptions } from "./CreateFieldOptions";



export const handlePlaceNameField: CanvasEventFunction = (pdfSelfSign: PdfSelfSignExtendedInputs, canvasThingie: CanvasThingie) => {
    //todo:  make it so options can be passed in withoug having to modify external code.
    const options: CreateNameFieldOptions = {
        backgroundColor: pdfSelfSign.state.activeSigner.color,
        fontFamily: "Helvetica", //todo:  add config for name font family separate from signature.
        fontSize: pdfSelfSign.state.fontSize * pdfSelfSign.scale,
        fontColor: pdfSelfSign.state.fontColor,
        signerLabel: pdfSelfSign.state.activeSigner.label,
        signerValue: pdfSelfSign.state.signatureValue,
    };
    const nameCursor = buildNameCursor(options);
    const placementMode: InputPlacementMode = InputPlacementMode.namePlacement;
    
    canvasThingie.fabricCanvas.on("mouse:move", (o) => {
        if (doesPlacementModeApply(o.pdfSelfSignState, placementMode)) {
            PlaceCursor(canvasThingie, o, nameCursor);
        }
    });

    canvasThingie.fabricCanvas.on("mouse:out", (o) => {
        if (doesPlacementModeApply(o.pdfSelfSignState, placementMode)) {
            canvasThingie.fabricCanvas.remove(nameCursor);
        }
    });

    canvasThingie.fabricCanvas.on("mouse:up", (o) => {
        if (o.button === 1) {
            if (!o.target && !o.pdfSelfSignState.isPlacementMode) {
                if (o.pdfSelfSignState.inputPlacementMode === placementMode) {
                    PlaceCursor(canvasThingie, o, nameCursor);
                }
                return;
            }
            if (doesPlacementModeApply(o.pdfSelfSignState, placementMode)) {
                const nameBlock = buildNameCursor(options);
                nameBlock.uniqueId = uuidv4();
                nameBlock.set({
                    left: nameCursor.left,
                    top: nameCursor.top
                });
                canvasThingie.fabricCanvas.add(nameBlock);
                canvasThingie.fabricCanvas.renderAll();
                pdfSelfSign.saveInput(nameBlock);
            }
        }
    });
}

const buildNameCursor = (options?: CreateNameFieldOptions,) => {
    // const color = options?.backgroundColor;
    const text = new fabric.IText(options?.signerValue || "John Doe", {
        fontFamily: options?.fontFamily || "Helvetica",
        fontSize: options?.fontSize,
        fill: options?.fontColor,
        originY: 'center',
        originX: 'center',
        left: 0,
        lockRotation: true,
        hasRotatingPoint: false,
        editable: false,
    });

    const rect = new fabric.Rect({
        originX: 'center',
        originY: 'center',
        fill: "white",//todo:what should fill color be?
        height: text.height,
        width: text.width,
    });

    const group = new fabric.Group([rect, text]);
    group.inputType = "Name";
    group._signerLabel = options?.signerLabel || "signer";

    return group;

}