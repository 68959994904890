import { useEffect, useState } from 'react';
import { ITemplateRoom } from './NhdTypes';
import Icon, { IconType } from '../../Icon/Icon';
import { toast } from 'react-toastify';

interface IProps {
  onUpdate: (room: ITemplateRoom, index: number) => void;
  onRemove: (room: ITemplateRoom, index: number) => void;
  room: ITemplateRoom;
  index: number;
}
const NhdScheduleRoomManager = (props: IProps) => {
  const [room, setRoom] = useState<ITemplateRoom>(props.room);

  useEffect(() => {
    setRoom(props.room);
  }, [props.room]);

  const onSave = () => {
    //everything that is required is there....
    if (room.maxNumberOfEntries && room.maxNumberOfEntries > 0) {
      props.onUpdate(room, props.index);
    } else {
      toast.warning(`Plase set the number of entries`);
    }
  };

  const onRemove = () => {
    if (props.index === -1 || window.confirm(`Are you sure you want to remove this room?`)) {
      props.onRemove(room, props.index);
    }
  };

  return (
    <>
      <div className="form-horizontal">
        <div className="form-group">
          <label className="control-label col-sm-4">Building</label>
          <div className="col-sm-6">
            <input
              type="text"
              maxLength={50}
              className="form-control col-sm-3"
              value={room.building}
              onChange={(x) => {
                setRoom({ ...room, building: x.target.value });
              }}
              placeholder="Building"
            />
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-4">Room</label>
          <div className="col-sm-6">
            <input
              type="text"
              maxLength={50}
              className="form-control col-sm-3"
              value={room.name}
              onChange={(x) => {
                setRoom({ ...room, name: x.target.value });
              }}
              placeholder="Room Name"
            />
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-4">Max Number of Entires</label>
          <div className="col-sm-6">
            <input
              type="number"
              max={100}
              className="form-control col-sm-3"
              min={0}
              value={room.maxNumberOfEntries}
              onChange={(x) => {
                setRoom({ ...room, maxNumberOfEntries: +x.target.value });
              }}
              placeholder="Entries"
            />
          </div>
        </div>
        <div className="form-group">
          <div className="col-sm-8 col-sm-offset-4">
            <button type={'button'} className="btn btn-secondary" onClick={onSave}>
              <Icon type={IconType.save} /> Save
            </button>
            <button type={'button'} className="btn btn-danger" onClick={onRemove}>
              <Icon type={IconType.delete} /> Remove
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default NhdScheduleRoomManager;
