import { useEffect, useState } from 'react';
import { IState } from '../../redux/redux';
import Navigation from '../Navigation/navigation';
import { EasyImage } from '../Tools/ExpandableDiv';
import StoreX from '../../redux/oldStore';

interface IProps {
  state: IState;
  useOld: boolean;
}

const Header = (props: IProps) => {
  const [ready, setReady] = useState<boolean>(false);
  const [headingStyle, setHeadingStyle] = useState<'std' | 'nhd'>('std');

  useEffect(() => {
    if (props.state.SettingsResponse) {
      setReady(true);

      if (props.state.SettingsResponse.Settings.isHistoryContest) {
        setHeadingStyle('nhd');
      } else {
        setHeadingStyle('std');
      }
    }
  }, [props.state]);

  return (
    <>
      {props.state.SettingsResponse && (
        <>
          {props.useOld ? (
            <>
              {ready && headingStyle === 'std' && (
                <>
                  <div className="title-bar primary-bg no-print">
                    {props.state.SettingsResponse && props.state.SettingsResponse.Logo?.length > 0 && (
                      <div className="logo">
                        <EasyImage src={props.state.SettingsResponse.Logo} alt="logo" link={StoreX.BuildUrl(``)} />
                      </div>
                    )}
                    <div>{props.state.SettingsResponse && <h1>{props.state.SettingsResponse.Name}</h1>}</div>
                    <div className="logo"></div>
                  </div>
                  <Navigation useOld={props.useOld} />
                </>
              )}

              {ready && headingStyle === 'nhd' && (
                <>
                  <div className="title-bar primary-bg no-print">
                    <div className="logo nhd-logo">
                      <EasyImage src={StoreX.BuildUrl('../api/FileApi/FileFetcherClient/e99bb006-500d-40fb-82df-6946fe3e383a')} alt="nhd logo" />
                    </div>
                    <div>{props.state.SettingsResponse && <h1>{props.state.SettingsResponse.Name}</h1>}</div>
                    {props.state.SettingsResponse && props.state.SettingsResponse.Logo?.length > 0 && (
                      <div className="logo">
                        <EasyImage src={props.state.SettingsResponse.Logo} alt="logo" />
                      </div>
                    )}
                  </div>
                  <Navigation  useOld={props.useOld} />
                </>
              )}
            </>
          ) : (
            <>
              <div className=''>
                <div className='x-logo-'>
                  {/* <a href={StoreX.BuildUrl('../')}>
                    <EasyImage src={props.state.SettingsResponse.Logo} alt="logo" />
                  </a> */}
                </div>
                <div className='x-menu'>
                  <Navigation useOld={props.useOld} />
                </div>
              </div>
              <div className="x-title">
              <div className='x-logo'>
                  <a href={StoreX.BuildUrl('../')}>
                    <EasyImage src={props.state.SettingsResponse.Logo} alt="logo" />
                  </a>
                </div>
                <h1>{props.state.SettingsResponse.Name}</h1>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Header;
