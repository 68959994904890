import { PdfCanvas } from "./PdfCanvas";
import { InputType, InputTemplate } from "./DocumentTypes";
import { fabric } from "fabric";
import { v4 as uuidv4 } from "uuid";
import { InputPlacementMode, PlaceCursor, CanvasThingie } from "./PdfSelfSignEventMethods";
// import '../fonts/BRUSHSCI.TTF';
// import '../fonts/bedtime stories.ttf';
import './pdfCanvas.css';


export type PdfSelfSignState = {
    signatureValue: string,
    activeSigner: Signer,
    isPlacementMode: boolean,
    signers: Record<string, Signer>,
    objectSelected?: fabric.Object,
    pageNum: number,
    xPos: number,
    yPos: number,
    newX: number,
    newY: number,
    menu: boolean,
    inputsToSaveArr: Input[],
    fontSize: number,
    signatureFontFamily: string,
    fontColor: string | fabric.Pattern | fabric.Gradient,
    inputPlacementMode: InputPlacementMode,
}

export type DatePlacementMode = ""

export interface Input extends InputTemplate {
    dataUrl: string
}

export type Signer = {
    label: string,
    color: string,
}

export type SelfSignOptions = {
    signatureValue?: string,
    signatureColor?: string | fabric.Pattern | fabric.Gradient,
    inputPlacementMode: InputPlacementMode;
    fontSize: number;
}

 
export class PdfSelfSign extends PdfCanvas {
    private areCanvasEventsInitialized: boolean = false;
    state: PdfSelfSignState;

    constructor(options?: SelfSignOptions) {
        super();
        // var font = new FontFace('bedtime stories', '')
        this.state = {
            signatureValue: "Jane Doe",
            activeSigner: {
                color: "white",
                label: "signer",
            },
            isPlacementMode: true,
            signers: { signer: { color: "white", label: "signer" } },
            pageNum: 0,
            xPos: 0,
            yPos: 0,
            newX: 0,
            newY: 0,
            menu: false,
            inputsToSaveArr: [],
            fontSize: options?.fontSize || 12,
            signatureFontFamily: "Brush Script MT",
            //signatureFontFamily: "test-family",
            fontColor: "#444444",
            inputPlacementMode: InputPlacementMode.signaturePlacement
        };
        if (options?.signatureValue) {
            this.state.signatureValue = options.signatureValue;
        }
        if (options?.signatureColor) {
            this.state.fontColor = options.signatureColor
        }
    }
    /**
     * initCanvasEvents
     */
    /**
     * initialze
     */
    public async init(source: string, element: HTMLElement | null) {
        await super.init(source, element);
        this.initCanvasEvents();
    }

    exitPlacementMode() {
        this.state.isPlacementMode = false;
        for (let i = 0; i < this.canvasObjects.length; i++) {
            const canvas = this.canvasObjects[i].fabricCanvas;
            const specialCursor = this.canvasObjects[i].specialCursor;
            if (specialCursor) {
                canvas.remove(specialCursor);
            }
        }
    }

    deleteAciveItems() {
        // super.deleteAciveItems();
        this.canvasObjects.forEach(x => {
            let activeObjects = x.fabricCanvas.getActiveObjects();
            activeObjects.forEach(fabricObject => {
                x.fabricCanvas.remove(fabricObject);
                let inputsToKeep = this.state.inputsToSaveArr.filter(input => {
                    return input.id !== fabricObject.uniqueId
                });
                this.state.inputsToSaveArr = inputsToKeep;
            });
        });
    }

    protected initCanvasEvents() {
        if (this.areCanvasEventsInitialized) {
            return;
        }
        this.areCanvasEventsInitialized = true;
        document.addEventListener('keydown', (e) => {
            if (e.code === "Escape") {
                this.exitPlacementMode()
            }
        });
        for (let i = 0; i < this.canvasObjects.length; i++) {
            let signatureCursor = this.buildOtherFabricGroup("Signature", this.state.activeSigner.label, this.state.signatureValue);

            const canvas = this.canvasObjects[i].fabricCanvas;
            canvas.on("mouse:move", (o) => {
                o.pdfSelfSignState = { ...this.state };
                if (this.state.isPlacementMode && this.state.inputPlacementMode === InputPlacementMode.signaturePlacement) {
                    this.PlaceSignatureCursor(this.canvasObjects[i], o, signatureCursor);
                }
            });

            canvas.on("mouse:out", (o) => {
                o.pdfSelfSignState = { ...this.state };
                if (this.state.isPlacementMode && this.state.inputPlacementMode === InputPlacementMode.signaturePlacement) {
                    canvas.remove(signatureCursor)
                }
            });

            canvas.on("mouse:up", (o) => {
                o.pdfSelfSignState = { ...this.state };
                if (o.button === 1) {
                    if (!o.target && !this.state.isPlacementMode) {
                        this.state.isPlacementMode = true;
                        if (this.state.inputPlacementMode === InputPlacementMode.signaturePlacement) {
                            this.PlaceSignatureCursor(this.canvasObjects[i], o, signatureCursor);
                            this.state.menu = false;
                        }
                        return;
                    }
                    //console.log('left click action', o)
                    if (this.state.isPlacementMode) {
                        let special = this.canvasObjects[i]?.specialCursor
                        if (special) {
                            canvas.remove(special);
                            console.log("remove signature");
                        }
                        if (this.state.isPlacementMode && this.state.inputPlacementMode === InputPlacementMode.signaturePlacement) {
                            //add the signature block to the document
                            let signatureBlock: fabric.Group = this.buildOtherFabricGroup("Signature", this.state.activeSigner.label, this.state.signatureValue);

                            signatureBlock.uniqueId = uuidv4();
                            signatureBlock.set({
                                left: signatureCursor.left,
                                top: signatureCursor.top
                            });
                            console.log("added signature to canvas");
                            canvas.add(signatureBlock);
                            canvas.renderAll();
                            //send the signature template to the api
                            this.saveSignatureInput(signatureBlock);
                        }
                        //exit signature placement mode
                        this.state.isPlacementMode = false;
                    }
                }
            });

            canvas.on("object:modified", (o) => {
                // this.handleSubmit(o);
                this.handleModified(o);

            });
        }
    }

    private PlaceSignatureCursor(canvas: CanvasThingie, o: fabric.IEvent, signatureCursor: fabric.Group) {
        PlaceCursor(canvas, o, signatureCursor, this.state.signatureValue);
    }



    buildOtherFabricGroup(type: InputType, name: string, signatureValue?: string) {
        let rightColor = this.state.signers[name].color;

        let text = new fabric.IText(`${signatureValue ?? type}`, {
            fontFamily: this.state.signatureFontFamily,
            fontSize: this.state.fontSize * this.scale,
            fill: this.state.fontColor,
            originX: 'center',
            originY: 'center',
            left: 0,
            // lockScalingX: true,
            // lockScalingY: true,
            // lockUniScaling: true,
            lockRotation: true,
            hasRotatingPoint: false,
            editable: false,
        });

        let rect = new fabric.Rect({
            originX: 'center',
            originY: 'center',
            fill: rightColor,
            height: text.height,
            width: text.width
        });

        let group = new fabric.Group([rect, text]); //, ...sigArr]);
        group.inputType = type
        group._signerLabel = name

        return group;

    }

    handleModified = (o: fabric.IEvent) => {
        // let thing =  this._pdfViewer.getActiveObject() as fabric.Group;
        let thing = o.target as fabric.Group
        if (thing) {
            // (thing.getObjects()[3] as fabric.IText).set({ text: `${this.state.sigType}${this.state.signer.slice(-1)}` });

            let correctCanvas = this.canvasObjects[0].fabricCanvas // What? this needs to be the canvas page that was picked
            if (thing.canvas) {
                correctCanvas = this.canvasObjects[this.convertPageNumberToIndex(thing.canvas?.pageNumber)].fabricCanvas
            }

            this.saveModifiedInput(thing);
            correctCanvas.renderAll();
            // this.setMenu(false)
        }
    }

    saveModifiedInput = async (signatureBlock: fabric.Object) => {
        let signatureTemplate: Input = {
            id: signatureBlock.uniqueId,
            signerLabel: signatureBlock._signerLabel,
            top: this.calculateTopPercent(signatureBlock),
            left: this.calculateLeftPercent(signatureBlock),
            height: this.calculateHeightPercent(signatureBlock),
            width: this.calculateWidthPercent(signatureBlock),
            pageNumber: signatureBlock.canvas?.pageNumber || 0,
            inputType: signatureBlock.inputType,
            dataUrl: signatureBlock.toDataURL({}),
            isRequired: signatureBlock._isRequired,
        }

        let filteredResult = this.state.inputsToSaveArr.filter(input => {
            return input.id === signatureTemplate.id
        })
        if (filteredResult[0]) {
            this.state.inputsToSaveArr = this.state.inputsToSaveArr.map(input =>
                input.id === signatureTemplate.id ? signatureTemplate : input);
        } else {
            this.state.inputsToSaveArr = [...this.state.inputsToSaveArr, signatureTemplate];
        }
    }

    saveInput(signatureBlock: fabric.Group, inputType?: InputType) {
        if (!signatureBlock.top || !signatureBlock.height || !signatureBlock.left || !signatureBlock.scaleY
            || !signatureBlock.width || !signatureBlock.scaleX || !signatureBlock.canvas) {
            throw new Error("Object is not propertly defined.");
        }

        let signatureTemplate: Input = {
            id: signatureBlock.uniqueId,
            signerLabel: (signatureBlock as any).text || this.state.activeSigner.label, //fixme:  I think .text will always be undefined.
            top: this.calculateTopPercent(signatureBlock),
            left: this.calculateLeftPercent(signatureBlock),
            height: this.calculateHeightPercent(signatureBlock),
            width: this.calculateWidthPercent(signatureBlock),
            pageNumber: signatureBlock.canvas?.pageNumber || 0,
            inputType: inputType ?? signatureBlock.inputType,
            dataUrl: signatureBlock.toDataURL({}),
            isRequired: signatureBlock._isRequired,
        }

        let filteredResult = this.state.inputsToSaveArr.filter(input => {
            return input.id === signatureTemplate.id
        });
        if (filteredResult[0]) {
            this.state.inputsToSaveArr = this.state.inputsToSaveArr.map(input =>
                input.id === signatureTemplate.id ? signatureTemplate : input);

        } else {
            this.state.inputsToSaveArr = [...this.state.inputsToSaveArr, signatureTemplate]
        }
    }

    saveSignatureInput(signatureBlock: fabric.Group) {
        this.saveInput(signatureBlock, "Signature");
    }


}