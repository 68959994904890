import { useEffect, useState } from 'react';
import { IState } from '../../../redux/redux';
import Icon, { IconType } from '../../Icon/Icon';
import { IAssignmentInfo, IProjectAssignment } from './JudgingCirlcesTypes';
import { IProjectInfo } from '../../_Core/CoreTypes';
import Modal from '../../Modal/Modal';
import { IListItem } from '../JudgingInfoDataTypes';
import { store } from '../../../redux/oldStore';
import { ServerResponse } from '../../../utils/Server';
import { toast } from 'react-toastify';

interface IProps {
  state: IState;
  info: IAssignmentInfo;
  categoriesDic: Record<string, string>;
  divisionsDic: Record<string, string>;
  roundDic: Record<string, string>;
  onRemove: (assignment: IProjectAssignment) => void;
  onUpdate: (assignment: IProjectAssignment) => void;
}

const JudgingCirclesProjectAssignmentList = (props: IProps) => {
  const [assignmentDic, setAssignmentDic] = useState<Record<string, IProjectAssignment | undefined>>({});
  const [selectedRound, setSelectedRound] = useState<number>();
  const [selectedProject, setSelectedProject] = useState<IProjectInfo>();
  const [showMakeAssignment, setShowMakeAssignment] = useState<boolean>(false);
  const [roundAssignment, setRoundAssingment] = useState<number>(0);

  useEffect(() => {
    if (props.info.ProjectAssignments) {
      let dic = {};
      props.info.ProjectAssignments.forEach((x, i) => {
        dic[dicKeyFromAssignment(x)] = x;
      });

      setAssignmentDic(dic);
    }
  }, [props.info, props.info.ProjectAssignments, props.info.JudgeAssignments]);

  const openMakeAssignment = (project: IProjectInfo, round: IListItem) => {
    setSelectedProject(project);
    setSelectedRound(+round.id);
    setShowMakeAssignment(true);
    setRoundAssingment(assignmentDic[dicKey(round.id, project.id)]?.CircleId ?? 0);
  };

  const dicKeyFromAssignment = (assignment: IProjectAssignment) => {
    return `${assignment.RoundId}:${assignment.ProjectId}`;
  };

  const dicKey = (roundId: number | string, projectId: number | string) => {
    return `${roundId}:${projectId}`;
  };

  const Save = () => {
    if (+roundAssignment === 0) {
      Remove();
      return;
    }

    store.server.postApi<ServerResponse<IAssignmentInfo>>(`../JudgingCircles/ProjectAssignment`, {roundId:selectedRound, projectId:selectedProject?.id, circleId:roundAssignment})
    .then((x) => {
      if (x.Success) {
        let assignments = { ...assignmentDic };
        let assignment = assignments[dicKey(selectedRound ?? 0, selectedProject?.id ?? 0)];
        
        if(selectedProject && selectedRound) {
        assignments[dicKey(selectedRound ?? 0, selectedProject?.id ?? 0)] = {
            ProjectId:selectedProject.id,
            RoundId: +selectedRound,
            CircleId: +roundAssignment,
            Name: props.info.Circles.find(y=>y.id === roundAssignment)?.name ?? 'Unknown',
        };}

        setAssignmentDic(assignments);

        if (assignment) {
          props.onUpdate(assignment);          
        }

        toast.success(`Assignment for ${selectedProject?.projectId} was saved.`);

        setShowMakeAssignment(false);
      } else {
        toast.error(x.Message);
      }
    });

  };

  const Remove = () => {
    if (!window.confirm(`Are you sure you want to remove this assignment for ${selectedProject?.projectId}?`)) {
      return;
    }

    store.server.postApi<ServerResponse<IAssignmentInfo>>(`../JudgingCircles/ClearProjectAssignment`, {roundId:selectedRound, projectId:selectedProject?.id})
    .then((x) => {
      if (x.Success) {
        let assignments = { ...assignmentDic };
        let assignment = assignments[dicKey(selectedRound ?? 0, selectedProject?.id ?? 0)];
        
        assignments[dicKey(selectedRound ?? 0, selectedProject?.id ?? 0)] = undefined;
        setAssignmentDic(assignments);

        if (assignment) {
          props.onRemove(assignment);          
        }

        toast.info(`Assignment for ${selectedProject?.projectId} was cleared.`);

        setShowMakeAssignment(false);
      } else {
        toast.error(x.Message);
      }
    });
  };

  const mapProjects = () => {
    if (props.info.Projects.length === 0) {
      return (
        <div className="alert alert-info">
          <h4>
            <Icon type={IconType.warning} /> No Entries Found
          </h4>
          It seems you don't have any entries at this time. You will not be able to make assignments until you have entries to assign.
        </div>
      );
    } else {
      return (
        <>
          <table>
            <tr>
              <th>Entry Info</th>
              {props.info.Rounds.map((r, ri) => {
                return <th key={`jcpal-li-head-${ri}`}>{r.name}</th>;
              })}
            </tr>
            {props.info.Projects.map((x, i) => {
              return (
                <tr className="list-item" key={`jcpal-li-${i}`}>
                  <td>
                    <div className="title">{x.projectId}</div>
                    <div>{x.title}</div>
                    <div className="detail">{x.categoryName}</div>
                  </td>
                  {props.info.Rounds.map((r, ri) => {
                    let assignment = assignmentDic[dicKey(r.id, x.id)];

                    return (
                      <td key={`jcpal-li-x-${i}-${ri}`}>
                        {assignment && <span>{assignment.Name}</span>}
                        <span
                          className="click big"
                          title="Click to make circle assignment"
                          onClick={() => {
                            openMakeAssignment(x, r);
                          }}>
                          <Icon type={IconType.idBadge} />
                        </span>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </table>
        </>
      );
    }
  };

  return (
    <>
      <div className="judging-cirlces-assignment-list">{mapProjects()}</div>

      {showMakeAssignment && (
        <Modal setModalOpen={setShowMakeAssignment} size="l" title={`Circle Assignment for ${selectedProject?.projectId}`}>
          <div className="form-horizontal">
            <div className="form-group">
              <label className="control-label col-sm-4" htmlFor="jcpal-ca">
                Circle
              </label>
              <div className="col-sm-4">
                <select
                  id="jcpal-ca"
                  className="form-control"
                  value={roundAssignment}
                  onChange={(x) => {
                    setRoundAssingment(+x.target.value);
                  }}>
                  <option value={0}>Select Circle</option>
                  {props.info.Circles.filter((x) => x.roundId === selectedRound).map((x, i) => {
                    return <option value={x.id}>{x.name}</option>;
                  })}
                </select>
              </div>
            </div>
            <div className="form-group">
              <div className="col-sm-4"></div>
              <div className="col-sm-8">
                <button type={'button'} className="btn btn-secondary" onClick={Save}>
                  <Icon type={IconType.save} /> Save
                </button>
                <button type={'button'} className="btn btn-default" onClick={Remove}>
                  <Icon type={IconType.trashO} /> Remove Assignment
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default JudgingCirclesProjectAssignmentList;
