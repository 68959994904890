import { toast } from 'react-toastify';
import { store } from '../../../redux/oldStore';
import { IState, ReduxMap } from '../../../redux/redux';
import { Server, ServerResponse } from '../../../utils/Server';
import { IScheduleRequestInfo, IScheduleRound, IScheduleRoundDetails, ITemplate } from '../../../Components/Client/NHD/NhdTypes';
import { useEffect, useState } from 'react';
import './styles.css';
import Icon, { IconType } from '../../../Components/Icon/Icon';
import Modal from '../../../Components/Modal/Modal';
import NhdScheduleManager from '../../../Components/Client/NHD/NhdScheduleManager';
import NhdScheduleBuildRoundManager from '../../../Components/Client/NHD/NhdScheduleBuildRoundManager';
import NhdScheduleRoundDetailsManager from '../../../Components/Client/NHD/NhdScheduleRoundDetailsManager';


interface IProps extends IState {}

const NhdSchedule = (props: IProps) => {
  const [info, setInfo] = useState<IScheduleRequestInfo>();
  const [showBuildRoundModal, setShowBuildRoundModal] = useState<boolean>(false);
  const [showTemplateModal, setShowTemplateModal] = useState<boolean>(false);
  const [activeTemplate, setActiveTemplate] = useState<ITemplate>();
  const [roundDetails, setRoundDetails] = useState<IScheduleRoundDetails>();
  const [showRoundDetailsManager, setShowRoundDetailsManager] = useState<boolean>(false);

  useEffect(() => {
    if (props.User && props.IsReady) {
      init();
    }
  }, [props.User]);

  const init = () => {
    store.server.postApi<ServerResponse<IScheduleRequestInfo>>(`../ClientNhdJudging/scheduleInfo`, {}).then((x) => {
      if (x.Success) {
        setInfo(x.Value);
        if(activeTemplate){
            let at = x.Value.templates.find(x=>x.id === activeTemplate.id);
            setActiveTemplate(at);
        }
      } else {
        toast.error(x.Message, { autoClose: false });
      }
    });
  };

  const onAddScheduleTemplateClick = (id:number|string) => {
    setActiveTemplate({
        category:+id,        
    });
    setShowTemplateModal(true);
  }

  const update = () =>{
    init();
  };

  const removeTemplate = (t:ITemplate)=>{
    if(info){
        let templates = info.templates.filter(x=>x.id !== t.id);
            setActiveTemplate(undefined);
            setShowTemplateModal(false);
            setInfo({...info,templates:templates});
        }
  };

  const updateTemplate = (t:ITemplate) =>{
    if(info){
    let templates = [...info.templates];
        let found = false;
        for(let i = 0; i < templates.length; i++){
            if(t.id === templates[i].id){
                found = true;
                templates[i] = t;
                break;
            }
        }

        if(!found) {
            templates.push(t);
        }
        setActiveTemplate(t);
        setInfo({...info,templates:templates});
    }
  };

  const onAssignIds = ()=>{
    store.server.postApi<ServerResponse<boolean>>(`../ClientNhdJudging/SetEntryIds`, {roundId:roundDetails?.roundId})
    .then(x=>{
        if(x.Success){
            loadRound({id:roundDetails?.roundId});
        } else {
            toast.error(x.Message, {autoClose:false});
        }
    });
  };

  const onAssignId = (entryId:number, id:string)=>{
    store.server.postApi<ServerResponse<boolean>>(`../ClientNhdJudging/SetEntryId`
    , {entryId:entryId, projectId:id})
    .then(x=>{
        if(x.Success){
            //loadRound({id:roundDetails?.roundId});
        } else {
            toast.error(x.Message, {autoClose:false});
        }
    });
  };

  const onAssignRank = (roundId:number, entryId:number, rank?:number)=>{
    toast.dismiss();
    store.server.postApi<ServerResponse<boolean>>(`../ClientNhdJudging/RecordRank`
    , {roundId:roundId, projectId:entryId, rank:rank??0})
    .then(x=>{
        if(x.Success){
            //loadRound({id:roundDetails?.roundId});
            toast.info('Rank updated.');
        } else {
            toast.error(x.Message, {autoClose:false});
        }
    });
  };

  const onBuildRound = (roundInfo: IScheduleRound) =>{
    store.server.postApi<ServerResponse<IScheduleRoundDetails>>(`../ClientNhdJudging/BuildRound`, roundInfo)
    .then(x=>{
        if(x.Success){
            setShowBuildRoundModal(false);
            setRoundDetails(x.Value);
            setShowRoundDetailsManager(true);            
            console.log(x.Value);
        } else {
            toast.error(x.Message, {autoClose:false});
        }
    });
  };

  const loadRound = (round:IScheduleRound)=>{
    store.server.postApi<ServerResponse<IScheduleRoundDetails>>(`../ClientNhdJudging/LoadRound`,{roundId:round.id})
    .then(x=>{
        if(x.Success){
            setRoundDetails(x.Value);
            setShowRoundDetailsManager(true);
        } else {
            toast.error(x.Message, {autoClose:false});
        }
    });
  }

  const saveRound = (round:IScheduleRoundDetails)=>{
    store.server.postApi<ServerResponse<IScheduleRoundDetails>>(`../ClientNhdJudging/SaveRound`,round)
    .then(x=>{
        if(x.Success){
            toast.info(`Round "${round.name}" was saved!`);
            setRoundDetails(x.Value);

            if(info){
                let rounds = [...info.rounds];
                let found = false;

                for(let i = 0; i < rounds.length;i++){
                    if(x.Value.roundId === rounds[i].id){
                        found = true;
                        rounds[i].name = x.Value.name;
                        rounds[i].isFinalRound = x.Value.isFinals;
                        rounds[i].categoryId = x.Value.categoryId;
                        rounds[i].templateId = x.Value.templateId;
                        rounds[i].poolFilterGrade = x.Value.grade+'';
                        rounds[i].poolFilterLowestRankPermitted = x.Value.lowestRank;
                        rounds[i].poolFilterPreviousRound = x.Value.previousRoundId;

                        break;
                    }
                }

                if(!found){
                    let r = x.Value;
                    rounds.push({
                        id:r.roundId,
                        categoryId:r.categoryId,
                        isFinalRound: r.isFinals,
                        poolFilterGrade: r.grade+'',
                        poolFilterLowestRankPermitted: r.lowestRank,
                        poolFilterPreviousRound: r.previousRoundId,
                        name:r.name,
                        templateId:r.templateId,
                    });
                }
                setInfo({...info, rounds:rounds});

            }
        } else {
            toast.error(x.Message, {autoClose:false});
        }
    });
  }
  const removeRound = (round:IScheduleRoundDetails)=>{
    store.server.postApi<ServerResponse<IScheduleRoundDetails>>(`../ClientNhdJudging/RemoveRound`,{roundId:round.roundId})
    .then(x=>{
        if(x.Success){
            toast.info(`Round "${round.name}" was removed!`);
            setRoundDetails(undefined);
            setShowRoundDetailsManager(false);
            if(info)
            setInfo({...info, rounds: info.rounds.filter(r=>r.id !== round.roundId)});
        } else {
            toast.error(x.Message, {autoClose:false});
        }
    });
  }

  const mapCategoryRounds = () => {
    if (info) {
      return info.categories.map((c, ci) => {
        let templates = info.templates.filter((x) => x.category === c.id);

        return (
          <div className="nhd-schedule-round" key={`nhdsr-${ci}`}>
            <div className='flex-between'><div>
            <h2>{c.name}</h2>
            <div>Entries: {c.quantity ?? 0}</div>
            </div>
                <div>
                    <button type={'button'} className='btn btn-default' onClick={()=>{onAddScheduleTemplateClick(c.id)}}><Icon type={IconType.plus} /> Add Schedule Template</button>
                </div>
            </div>
            <div className="scheduled-round-list">
              {templates.length > 0 ? (
                <>
                  {templates.map((t, ti) => {
                    let rounds = info.rounds.filter((x) => x.categoryId === c.id && x.templateId === t.id);
                    let category = info.categories.find(x=>x.id === t.category);
                    let division = info.divisions.find(x=>x.id === t.division);
                    return (
                      <div className="schedule-template" key={`nhdsrt-${ti}`}>
                        <div className='flex-between'>
                        <div>
                        <h4>{t.name}</h4>
                        <div>Category: {category?.name ?? ''}</div>
                        {division && <div>Division: {division?.name ?? ''}</div>}
                        <hr/>
                        </div>
                        <div>
                        <button type={'button'} className='btn btn-secondary btn-xs' onClick={()=>{setActiveTemplate(t);setShowBuildRoundModal(true);}}><Icon type={IconType.build} /> Generate Round</button>
                        <button type={'button'} className='btn btn-default btn-xs' onClick={()=>{setActiveTemplate(t);setShowTemplateModal(true);}}><Icon type={IconType.edit} /> Edit Template</button>
                        </div>
                        </div>
                        <div className='flex scheduled-template-round-list'>
                        {rounds.length > 0 ? (
                          <>
                            {rounds.map((r, ri) => {
                              return (
                                <div className={`scheduled-round ${r.isFinalRound ? 'nhd-finals':''}`} key={`nhdsrlsr-${ti}-${ri}`}>
                                  <h4>{r.name}</h4>
                                  {r.isFinalRound && <><span><Icon type={IconType.flag}/> Finals Round!</span></>}
                                  <br/>
                                  <div>

                                  </div>
                                  <div>
                                    <button type='button' className='btn btn-default btn-xs' onClick={()=>{loadRound(r)}}>
                                        <Icon type={IconType.build}/> View Round
                                    </button>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <>No Rounds Scheduled</>
                        )}
                      </div>
                      </div>
                    );
                  })}
                </>
              ):
              <div>
                    <div className='alert alert-warning'>
                        <h4><Icon type={IconType.warning}/> No Scheduled Templates</h4>
                        There are no templates defined for this category. You must add a template before you can schedule a judging round.
                    </div>
                </div>}
            </div>
          </div>
        );
      });
    }
    return undefined;
  };

  return (
    <>
      <h3>Build and Edit Judging Schedule</h3>
      <hr/>

      {info && <div>{mapCategoryRounds()}</div>}



      {activeTemplate && showTemplateModal && 
      <Modal setModalOpen={setShowTemplateModal} 
        title={`Template Manager: ${activeTemplate.name ?? ''}`}
        size='l'
        >
            {info && <NhdScheduleManager 
                categories={info.categories} 
                divisions={info.divisions} 
                template={activeTemplate}
                onRemove={removeTemplate}
                onUpdate={updateTemplate}
            />}
        </Modal>}

{activeTemplate && showBuildRoundModal && 
<Modal setModalOpen={setShowBuildRoundModal} 
  title={`Round Manager: ${activeTemplate.name ?? ''}`}
  size='l'
  >
      {info && <>
        <NhdScheduleBuildRoundManager 
        template={activeTemplate} 
        grades={info.grades} 
        rounds={info.rounds ?? []}
        onBuildRound={onBuildRound}  />
      </>}
  </Modal>}

  {roundDetails && info && showRoundDetailsManager && <Modal 
    setModalOpen={setShowRoundDetailsManager} 
    title={`Round: ${roundDetails.name ?? ''}`}
    size='full'
  >
        <NhdScheduleRoundDetailsManager 
            onAssignIds={onAssignIds}
            onAssignId={onAssignId}
            details={roundDetails}
            onSave={saveRound}        
            onRemove={removeRound}
            onAssignRank={onAssignRank}
            onClose={()=>{setShowRoundDetailsManager(false);setRoundDetails(undefined)}}
        />
  </Modal>}
    </>
  );
};

export default ReduxMap(NhdSchedule);
