import React, { useState } from "react";
import EventAppImageEditor from "./EventAppImageEditor";
import { getUrlFromUserFile, objectUrlToDataUrl } from "../../utils/UrlHelpers";
import { getResizedImageObjectUrl } from "./ImageEditorExtensions";
import Icon, { IconType } from "../Icon/Icon";
import { useEffect } from "react";
import Modal from "../Modal/Modal";
import { UiFormField } from "../../utils/CommonTypes/UserTypes";

export interface IImageInfo {
  name?: string | null | undefined;
  text?: string | null | undefined;
  id?: string | undefined;
  inputType?: string | null | undefined;
  dataUrl?: string | null | undefined;
  fieldKey: string;
}

interface ImageLoadingProps {
  imageInfo?: IImageInfo;
  setImageInfo?: (imageInfo: any) => void;
  imageValue?: string | null;
  clearInfo?: boolean;
  handleRemoveImage?: (e, fieldKey: string) => void;
  handleLoadClick?: () => void;
  fieldKey: string;
  field: UiFormField;
}

const ImageLoading = (props: ImageLoadingProps) => {
  const defaultUploadState = {
    isImageUploadVisible: false,
    imageUploadSave: (url: string) => {},
    imageFileUrl: "",
    imageFileName: "",
    imageOtherInfo: {},
    fieldKey: props.fieldKey,
  };
  const [imageUploadstate, setInputUploadState] = useState(defaultUploadState);
  const [field, setField] = useState<IImageInfo>();
  const [profileImageFailedToLoad, setProfileImageFailedToLoad] =useState<Boolean>();

  useEffect(() => {
    if (props.clearInfo) {
      clearInputs();
    }
  }, [props.clearInfo]);

  const handleUploadClick = async (e, id: string) => {
    e.preventDefault();
    let result = await getUrlFromUserFile({ urlType: "DataUrl" });
    let sizedUrl = await getResizedImageObjectUrl(result.url);
    saveUploadFile(id, result.filename, sizedUrl);
  };

  const clearInputs = () => {
    setInputUploadState(defaultUploadState);
    setField(undefined);
  };

  const handleRemoveClick = (e) => {
    if(true || window.confirm(`Are you sure you want to remove this image?`)){
    clearInputs();
    props.handleRemoveImage?.(e, props.fieldKey);}
  };

  const setImageBoolean = () => {
    setInputUploadState({
      ...imageUploadstate,
      isImageUploadVisible: !imageUploadstate.isImageUploadVisible,
    });
  };

  const handleThumbnailClick = () => {
    // let fieldThatMatches = props.fullFile?.preFillFields?.filter(x => x.id === field.id)?.[0]
    setInputUploadState({
      imageFileUrl: field?.dataUrl ?? "",
      imageFileName: field?.text ?? "new",
      imageUploadSave: (url: string) =>
        saveUploadFile(field?.id ?? "", field?.text ?? "", url),
      imageOtherInfo: {},
      isImageUploadVisible: true,
      fieldKey: props.fieldKey,
    });
  };

  const saveUploadFile = async (
    id: string,
    filename: string,
    objectUrl: string
  ) => {
    const dataUrl = await objectUrlToDataUrl(objectUrl);
    let updatedFormData: IImageInfo = {
      ...field,
      dataUrl: dataUrl,
      text: filename,
      fieldKey: props.fieldKey,
    };
    setField(updatedFormData);
    setInputUploadState({
      ...imageUploadstate,
      isImageUploadVisible: false,
    });
    props?.setImageInfo?.(updatedFormData);
  };

  return (
    <div className="profile profile-image-container">
        <div className="profile profile-image">
      {field?.dataUrl || props.imageValue ? 
        <>
          {!profileImageFailedToLoad ? 
          <>
            <img
            src={ field?.dataUrl ?? props?.imageValue ?? ""}
            alt={"profile image"} onError={(x)=>{
              setProfileImageFailedToLoad(true);
            }} />
            </>
            :<>
            <Icon type={IconType.userProfileImage} />
            </>}
            </>
            
           
       : (
        <div className="click huge" onClick={(e) => handleUploadClick(e, field?.id ?? "")} ><Icon type={IconType.user}></Icon></div>
      )}
        </div>
      <div>
        <span className="click btn" title="select image to upload" onClick={(e) => handleUploadClick(e, field?.id ?? "")}><Icon type={IconType.upload} /></span>
        {(props.imageInfo ||
          props.imageValue ||
          imageUploadstate.imageFileUrl) && (
            <><br/><span className="click btn" title="remove image" onClick={handleRemoveClick}><Icon type={IconType.trashO} /></span></>
        )}
        { field?.dataUrl &&
          <><br/><span className="click btn" title="edit image" onClick={handleThumbnailClick}><Icon type={IconType.edit} /></span></>
        }
      </div>
      {props.field.AdditionalInformation && <div dangerouslySetInnerHTML={{__html:props.field.AdditionalInformation}}></div>}
      {imageUploadstate.isImageUploadVisible && (
        <Modal
          setModalOpen={setImageBoolean}
          title=" "
          className="image-modal-sizer"
          // isOpen={imageUploadstate.isImageUploadVisible}
          // toggle={setImageBoolean}
        >
            <EventAppImageEditor
              imageName={imageUploadstate.imageFileName}
              imageUrl={imageUploadstate.imageFileUrl}
              saveImage={imageUploadstate.imageUploadSave}
              imageOtherInfo={imageUploadstate.imageOtherInfo}
            />
        </Modal>
      )}
    </div>
  );
};

export default ImageLoading;
