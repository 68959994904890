import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { store } from '../../redux/oldStore';
import { GetPeoplePerson, SecurityRole } from '../../utils/CommonTypes/PeoplePageTypes';
import { ServerResponse } from '../../utils/Server';



interface ChangeRoleFormProps {
  person: GetPeoplePerson;
  setModalOpen: (open: boolean) => void;
  refreshAll: () => void;
  settings?: any;
  whoAmI?: any;
}

interface IRole {
  roles:string;
  value:number;
}

const ChangeRoleForm = (props: ChangeRoleFormProps) => {
  const [personRole, setPersonRole] = useState<any>(props.person.MaxRole);
  const [roleOptions, setRoleOptions] = useState<{value: SecurityRole, label: string}[]>([]);
  const [roles, setRoles] = useState<IRole[]>([]);


  useEffect(() => {
    store.server.getApi<ServerResponse<IRole[]>>('../Info/Roles')
    .then(x=>{
      if(x.Success){
        setRoles(x.Value);
      }
    });
    handleRoleOptionsGeneration();
  }, []);

  const handleRoleOptionsGeneration = () => {
    let someArr = Object.entries(SecurityRole);
    let options: any[] = [] 
    for (let i = 0;  i < someArr.length; i++) {
      if( typeof someArr[i][1] !== 'string') {
        if ((props.whoAmI?.SecurityLevel ?? -1000)>= someArr[i][1]) {
          options.push({label: someArr[i][0], value: someArr[i][1]})
        }
      } 
    }
    let sorted = _.sortBy(options, 'value')
    setRoleOptions(sorted)
  }

  const roleChangeSave = () => {
    let submitObj = {
      userId: props.person.Id,
      newRole: personRole
    }
    store.server.postApi('../Person/ChangeRole', submitObj)
      .then((res: any) => {
      console.log(res);
      toast.success('role changed!')
      props.refreshAll();
      props.setModalOpen(false);

    })
  }


  const mappedRoleOptions = roles.map( (role, i) => {
    return (<option value={role.value}>{role.roles}</option>)
  })


  return (
    <>
      <div className='form-group'>
        <label className='control-label col-md-4'>Role: </label>
        <div className='col-md-8'>
          <select
            onChange={(e) => setPersonRole(SecurityRole[e.target.value])}
            value={SecurityRole[personRole]}
            className="form-control"
            id="AdminDirectorySecurityDropdown"
          >
            {mappedRoleOptions}
          </select>
        </div>
      </div>
      <div className='form-group'>
        <div className='col-md-8 col-md-offset-4'>
          <button
            type='button'
            className='btn btn-primary btn-block'
            onClick={roleChangeSave}
          >
            Change Role
          </button>
        </div>
      </div>
    </>
  )
}


export default ChangeRoleForm