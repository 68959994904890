import React, {useState, useEffect} from 'react';
import { toast } from 'react-toastify';
import StoreX, { store } from '../../redux/oldStore';
import { ServerResponse } from '../../utils/Server';
import Icon, { IconType } from '../Icon/Icon';
import VideoContainer from '../JustVideo/VideoContainer';
import { IWaitingRoomInfo, IWaitRoomRequest } from './AdvancedJudgingTypes';
import RequestModeratorInterpreter from './RequestModeratorInterpreter';


interface IWaitingRoomProps {

}

const WaitingRoom = (props:IWaitingRoomProps)=>{
    const [info, setInfo] = useState<IWaitingRoomInfo>();
    const [requests, setRequests] = useState<IWaitRoomRequest[]>([]);
    const isDev = window.origin.indexOf('localhost') > -1;

    useEffect(()=>{
        init();
        lookForHelpRequests();
    },[]);

    const init = () =>{
        store.server.getApi<ServerResponse<IWaitingRoomInfo>>(`../WaitingRoom/Info`)
        .then(x=>{
            if(x.Success){
                setInfo(x.Value);
            } else {
                toast.error(x.Message, {autoClose:false});
            }
        });
    };

    

    const lookForHelpRequests = () =>{
        store.server.getApiQuiet<ServerResponse<IWaitRoomRequest[]>>(`../WaitingRoom/ListRequests`)
        .then(x=>{
            if(x.Success){
                setRequests(x.Value ?? []);
                setTimeout(lookForHelpRequests, 7500);
            } else {
                toast.error(x.Message, {autoClose:false});
            }
        });
    };

    const goHelp=(request:IWaitRoomRequest)=>{
        store.server.getApi<ServerResponse<boolean>>(`../WaitingRoom/FillRequest/${request.Id}`)
        .then(x=>{
            if(x.Success){
                setRequests([...requests.filter(r=>r.Id !== request.Id)]);
                if(x.Value === true){
                    let url = StoreX.BuildUrlWithFair(`../app/Video/${request.MeetingId}`);
                    window.location.href = url;
                } else {
                    toast.info(`Thanks, for being willing to help. However someone else already went.`);
                }
            } else {
                toast.error(x.Message, {autoClose:false});
            }
        });
    }


    return (
        <>
        <div className='bumper'>
            <div dangerouslySetInnerHTML={{__html:info?.html ?? ''}}></div>
            <hr/>

            <div className='waitroom-request-container'>
                {!requests.length && <div>
                        <h4>No request at this time, but we'll keep checking every few seconds...</h4>
                    </div>}
            {requests.length > 0 && requests.map((x,i)=>{

                return (<div className='waitroom-request' key={`wait-request-${i}`}>
                    <span className='type'>{x.Type === 'Moderator' ? 'Proctor':x.Type}</span>
                    <div className='details'>
                        <div>
                            <button type='button' className='btn btn-secondary' onClick={()=>{goHelp(x)}}>
                                <Icon type={IconType.handsHelping}/> Go Help
                            </button>
                        </div>
                        By: {x.RequestBy}

                    </div>
                    {x.Language && x.Type === 'Interpreter' && <div className='language'>{x.Language}</div>}
                </div>);
            })}
            </div>
            {info?.videoRoom && <div className='headroom-xl'>
                    <VideoContainer roomId={`${info.fairId}-pro-inter-room-day-2`} autoStart={true}  />
                </div>}
        </div>
        {isDev && <RequestModeratorInterpreter settings={StoreX.Settings} languages={info?.languages ?? []} meetingId={StoreX.NewGuid()} />}
        </>
    );
};

export default WaitingRoom;