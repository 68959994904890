import React, { useState, useEffect } from "react";
import { store } from "../../redux/oldStore";
import { toast } from "react-toastify";
import { setJudges } from "../../redux/judgingSetup/actions";
import {
  GuidToProjectDict,
  IntIdToProjectDict,
  IProject,
  ISettings,
} from "./Dashboard";

interface ToolsProps {
  settings: ISettings | undefined;
  socket: any;
}

const Tools = (props: ToolsProps) => {
  const { socket, settings } = props;
  const params = new URLSearchParams(window.location.search);
  const fairId = params.get("f");
  const fairIdPara = fairId ? `f=${fairId}` : null;
  const [projectSearchVal, setProjectSearchVal] = useState("");
  const [filteredProjects, setFilteredProjects] = useState<IProject[]>([]);
  const [projectsActive, setProjectsActive] = useState<any>({});
  const [bellMessage, setBellMessage] = useState<string | null>(
    "Ring, Ring, Ring..."
  );
  const [message, setMessage] = useState<string | null>("");

  const ringBell = () => {
    let msg = bellMessage;
    if (msg === "") {
      msg = null;
    }
    socket.emit("dashboard-announcement", { type: "bell", message: msg });
  };
  const sendMessage = () => {
    let msg = message;
    if (!msg || msg.length === 0) {
      toast.error("You must set a message to send.");
      return;
    }
    socket.emit("dashboard-announcement", { type: "message", message: msg });
  };

  const reloadAll = () => {
    if (
      !window.confirm(
        `Are you sure you want to force all Judges & Students pages to be reloaded?`
      )
    ) {
      return;
    }
    socket.emit("dashboard-announcement", { type: "reload" });
  };
  const forceLogout = () => {
    if (
      !window.confirm(
        `Are you sure you want to force all Judges & Students to be logged out?`
      )
    ) {
      return;
    }
    socket.emit("dashboard-announcement", { type: "force-logout" });
  };

  return (
    <div className="dashboard-tools">
      <div className="tool">
        <div>
          <div className="alert alert-info">
            Rings a bell for all judges in online judging and students on the
            virtual judging page.
          </div>
          <div className="form-group">
            <label>Message</label>

            <input
              type="text"
              maxLength={150}
              value={bellMessage || ""}
              onChange={(e) => {
                setBellMessage(e.target.value);
              }}
              className="form-control"
              placeholder="Ring, Ring, Ring..."
            />
          </div>
        </div>
        <button
          type="button"
          className="btn btn-primary bell-button"
          onClick={ringBell}
        >
          <i className="fad fa-bell-on"></i>Ring Bell
        </button>
      </div>
      <div className="tool">
        <div>
          <div className="alert alert-info">
            Send Message to all judges in online judging and students on the
            virtual judging page.
          </div>
          <div className="form-group">
            <label>Message</label>

            <textarea
              maxLength={1500}
              value={message || ""}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
              className="form-control"
              placeholder="message"
            ></textarea>
          </div>
        </div>
        <button
          type="button"
          className="btn btn-primary bell-button"
          onClick={sendMessage}
        >
          <i className="fad fa-comment-alt-dots"></i>Send Message
        </button>
      </div>
      <div className="tool">
        <h3>Misc Tools</h3>
        <div>
          <div className="flex-between">
            <button
              type="button"
              className="btn btn-primary"
              onClick={reloadAll}
            >
              <i className="fad fa-sync"></i>Force Reload
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={forceLogout}
            >
              <i className="fad fa-sync"></i>Force Logout
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tools;
