import React, { useState } from 'react';
// import { useTimer } from 'react-timer-hook';
import Timer from 'react-compound-timer'
import { toast } from "react-toastify";


const ParticipantTimer = (props) => {
  // const [showTime, setShowTime] = useState(true)

  // const closeTime = () => setShowTime(false)

  return (
    <Timer
      initialTime={props.initialTime}
      direction={props.direction}
      startImmediately={true}
      // onStart={() => setShowTime(true)}
      // onResume={() => console.log('onResume hook')}
      // onPause={() => console.log('onPause hook')}
      // onStop={() => console.log('onStop hook')}
      // onReset={() => console.log('onReset hook')}
      timeToUpdate={100}
      checkpoints={props.checkpoints}
    >
      {({ start, resume, pause, stop, reset, timerState }) => (
        <div>
          {props.showTime
            &&
            <div
              className='time-display'
            >
              <div>
                <Timer.Minutes /> : <Timer.Seconds formatValue={value => value < 10 ? `0${value}` : value} />
              </div>
              {/* <span 
                  className='normal-sized'
                  onClick={closeTime}
                >
                  <i className="far fa-times-circle"></i>
                </span> */}
            </div>
          }
          {/* <div>{timerState}</div> */}
        </div>
      )}
    </Timer>
  )

}

export default ParticipantTimer