import React, { useEffect, useState } from 'react';
import { store } from '../../redux/oldStore';
import { Selectable } from '../../utils/CommonTypes/CommonTypes';
import { GetPeoplePerson, Tag } from '../../utils/CommonTypes/PeoplePageTypes';
import Icon, { IconType } from '../Icon/Icon';
import Modal from '../Modal/Modal';
import Select from 'react-select';
import { ServerResponse } from '../../utils/Server';
import { toast } from 'react-toastify';

interface TagModalProps {
  setModalOpen: (open: boolean) => void;
  projectTags: Tag[];
  personTags: Tag[];
  person?: GetPeoplePerson;
  persons?: GetPeoplePerson[];
  projectTagDict: Record<number, Tag>;
  personTagDict: Record<number, Tag>;
  setChosenPerson: (p: GetPeoplePerson) => void;
  updateTag: (person, ids, type, remove) => void;
}

const TagModal = (props: TagModalProps) => {
  const [selectTag, setSelectTag] = useState<string[]>(['-1', '-1']);
  const [tagOptions, setTagOptions] = useState<Selectable[]>([]);
  const [tagsToAdd, setTagsToAdd] = useState<Selectable[]>([]);
  const [idString, setIdString] = useState<string>('');

  useEffect(()=>{
    let ids:string[] = [];

    if(props.person) ids.push(`${props.person?.ProjectKey},${props.person?.Id}`);

    if(props.persons) props.persons.forEach(x=>{
      if(x.ProjectKey)
      ids.push(`${x.ProjectKey},${x.Id}`)
      else 
      ids.push(`${x.Id}`)
    });

    setIdString(ids.join(','));
  },[props.person, props.persons]);

  useEffect(()=>{
    let options:Selectable[] = [];

    
    props.personTags?.forEach(t=>{
      if(!props.person || !props.person.PersonTags.find(x=>x === t.Id))
      options.push({value:t.Id, label:`Person: ${t.Value}`});
    });

    props.projectTags?.forEach(t=>{
      if(!props.person || !props.person.ProjectTags.find(x=>x === t.Id))
      options.push({value:t.Id, label:`Project: ${t.Value}`});
    });

    setTagOptions(options);

  },[props.personTags, props.projectTags, props.person]);

  const handleTagRemoval = (tagId: number, project: boolean) => {
    if (true || window.confirm('Are you sure you want to remove this tag?')){
      let submitObj = {
        tags: [tagId],
        remove: true,
        ids: idString,
      }
      store.server.postApi<ServerResponse<boolean>>('../Tag/BulkTag2', submitObj)
        .then(x => {
          if(x.Success){
            if(props.person){
            let updatedPerson = {...props.person}
            if (project) {
              updatedPerson.ProjectTags = props.person.ProjectTags.filter(t => t !== tagId)
            } else {
              updatedPerson.PersonTags = props.person.PersonTags.filter(t => t !== tagId)
            }
            props.setChosenPerson(updatedPerson);
            props.updateTag(props.person, [{id:tagId, type:project ? 'project':'person'}], project ? 'project':'person', true);
          }
          else {
             props.persons?.forEach(p=>{
               props.updateTag(p, submitObj.tags, selectTag[0], false);
             });
           }
            setTagsToAdd([]);
            toast.info('Tags removed.');
          } else {
            toast.error(x.Message);
          }
        });
    }
  }

  const handleTagAdd = () => {

    let tags:any[] = [];

    tagsToAdd.forEach(x=>{tags.push({id:x.value, type:props.personTagDict[x.value] ? 'person':'project'})});

    let submitObj: any = {
      remove: false,
      tags: tags.map(x=>x.id),
      ids: idString,
    }

    store.server.postApi<ServerResponse<boolean>>('../Tag/BulkTag2', submitObj).then(x => {
      if(x.Success){
       if(props.person) { 
          let updatedPerson = {...props.person}
          if (selectTag[0] === 'person'){
            updatedPerson.PersonTags = [...props.person?.PersonTags, +selectTag[1]]
          }
          if (selectTag[0] === 'project'){
            updatedPerson.ProjectTags = [...props.person?.ProjectTags, +selectTag[1]]
          }
          props.setChosenPerson(updatedPerson);
          props.updateTag(props.person, tags, selectTag[0], false);
       }
       else {
          props.persons?.forEach(p=>{
            props.updateTag(p, tags, selectTag[0], false);
          });
        }
        
        setTagsToAdd([]);
        toast.success('Tags add.');
      } else {
        toast.error(x.Message);
      }
    })


  }

  const mappedProjectTags = props.person?.ProjectTags.map((id, i) => {
    let proj = props.projectTagDict[id]

    if (!proj) return <></>
    return (
      <span className='tag tag-project' key={`${proj?.Value}-${i}`}>
        <Icon type={IconType.tag} />
        {proj?.Value}
        <span onClick={() => handleTagRemoval(id, true)}><Icon type={IconType.delete} /></span>
      </span>
    )
  });

  const mappedPersonTags = props.person?.PersonTags.map((id, i) => {
    let peep = props.personTagDict[id]
    if (!peep) return <></>
    return (
      <span className='tag tag-person' key={`${peep?.Value}-${i}`}>
        <Icon type={IconType.tag} />
        {peep?.Value}
        <span onClick={() => handleTagRemoval(id, false)}><Icon type={IconType.delete} /></span>
      </span>
    )
  });


  const handleSelectChange = (val: string) => {
    let split = val.split(',')
    setSelectTag(split);
  }

  return (
    <Modal
      title='Tags'
      setModalOpen={props.setModalOpen}
      size='m'
    >
      <div className='panel-body form-horizontal'>
        <div className='col-sm-12'>
          {props.person && <div id='tag-modal-my-tags'>
            {(props.person.PersonTags.length === 0 && props.person.ProjectTags.length === 0)
              && <h4>No tags added</h4>
            }
            {mappedProjectTags}
            {mappedPersonTags}
          </div>}
          <div className='form-group'>
            <label className='col-sm-2 control-label'>Tags: </label>
            <div className='col-sm-10'>
              {/* <select
                className='form-control'
                id='tag-modal-add-tag'
                value={selectTag}
                onChange={(e) => handleSelectChange(e.target?.value)}
              >
                <option value={-1}>choose tag...</option>
                {props.person.ProjectId &&
                  <optgroup label='Project Tags' className='tags-project'>
                    {mappedProjectOptions}
                  </optgroup>
                }
                <optgroup label='Person Tags' className='tags-person'>
                  {mappedPersonOptions}
                </optgroup>
              </select> */}
              <Select
                    onChange={(x: Selectable[]) => {
                      setTagsToAdd(x);
                    }}
                    value={tagsToAdd}
                    options={tagOptions}
                    placeholder={'What tags do you want to add?'}
                    className="multi-select"
                    classNamePrefix="cool"
                    menuPlacement={'auto'}
                    isMulti={true}
                    isClearable={true}
                  />
            </div>
          </div>
        </div>
        <div className='form-group col-sm-12'>
          <button className='btn btn-success' onClick={handleTagAdd}><Icon type={IconType.tag} /> Add Tag</button>
          <button className='btn btn-default' onClick={() => props.setModalOpen(false)}><Icon type={IconType.ban} /> Cancel</button>
        </div>
      </div>
    </Modal>
  )
}


export default TagModal