import { PdfSelfSign } from "./PdfSelfSign";
import { CanvasEventFunction } from "./PdfSelfSignEventMethods";
import { handlePlaceDateField } from "./HandlePlaceDate";
import { handlePlaceNameField } from "./HandlePlaceName";
import { handlePlaceCheckBoxField } from "./HandlePlaceCheckBox";
import { handlePlaceFreeFormTextField } from "./HandlePlaceFreeformText";


export class PdfSelfSignExtendedInputs extends PdfSelfSign {

    public async init(source: string, element: HTMLElement | null, eventFunctions?: CanvasEventFunction[]) {
        await super.init(source, element);

        let functions = eventFunctions ?? [handlePlaceCheckBoxField, handlePlaceNameField, handlePlaceDateField, handlePlaceFreeFormTextField]

        this.initCanvasEvents(functions);
    }

    public initCanvasEvents(events: CanvasEventFunction[] = []) {
        super.initCanvasEvents();
        for (let i = 0; i < this.canvasObjects.length; i++) {
            events?.forEach(event => {
                event(this, this.canvasObjects[i]);
            });
        }
    }
}