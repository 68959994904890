import { initial } from 'lodash';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import StoreX, { store } from '../../../redux/oldStore';
import { ServerResponse } from '../../../utils/Server';
import Icon, { IconType } from '../../Icon/Icon';
import Modal from '../../Modal/Modal';
import BranchPortal from './BranchPortal';
import { IBranchSearch, IBranchSearchInfo } from './BranchTypes';
import { IState } from '../../../redux/redux';

interface IBranchSearchProps {
  state: IState;
}

interface Iform {
  text?: string;
}

const BranchSearch = (props: IBranchSearchProps) => {
  const [info, setInfo] = useState<IBranchSearchInfo>();
  const [branches, setBranches] = useState<IBranchSearch[]>([]);
  const [form, setForm] = useState<Iform>({});
  const [showBranch, setShowBranch] = useState<boolean>();
  const [showSearch, setShowSearch] = useState<boolean>();
  const [branch, setBranch] = useState<IBranchSearch>();

  useEffect(() => {
    if (info?.branches) {
      setBranches(canView2(info.branches, form));
    }
  }, [form, info]);

  useEffect(() => {
    if (showSearch && !info) {
      init();
    }
  }, [showSearch]);

  const init = () => {
    store.server.getApi<ServerResponse<IBranchSearchInfo>>(`../setupwiz/SearchableBranches`).then((x) => {
      if (x.Success) {
        setInfo(x.Value);
      } else {
        toast.error(x.Message);
      }
    });
  };

  const canView = (branch: IBranchSearch) => {
    if (form.text) {
      if (branch.SearchText?.toLowerCase().indexOf(form.text.toLowerCase()) > -1) {
        //found
        return true;
      }
      return false;
    }

    //todo add more filter options stuff here...

    return true;
  };

  const canView2 = (branchesToSearch: IBranchSearch[], search: Iform) => {
    if (search.text) {
      let fullTerm = search.text.toLowerCase();
      let terms = fullTerm.split(/[,\s]/g);
      let fakeTerms: Record<string, boolean> = {};
      fakeTerms['i'] = true;
      fakeTerms['a'] = true;
      fakeTerms['the'] = true;
      fakeTerms['is'] = true;
      fakeTerms['it'] = true;
      fakeTerms['of'] = true;
      fakeTerms['as'] = true;
      fakeTerms['for'] = true;
      fakeTerms['your'] = true;
      fakeTerms['to'] = true;
      fakeTerms['or'] = true;

      terms = terms.filter((x) => !fakeTerms[x]);

      console.log(terms);
      let termsDic: Record<string, boolean> = {};
      terms.forEach((x) => {
        termsDic[x] = true;
      });

      let branchDic: Record<string, number> = {};
      let branchDic2: Record<string, boolean> = {};

      branchesToSearch.forEach((b) => {
        let score = 0;

        let sText = b.SearchText?.toLowerCase() ?? '';
        let sTitle = b.SearchTitle?.toLowerCase() ?? '';
        let sPreview = b.SearchTextPreview?.toLowerCase() ?? '';
        let tags = b.Tags?.map((x) => x.toLowerCase()) ?? [];

        if (b.PublicId.toLowerCase() === fullTerm) score += 10000;
        if (sTitle === fullTerm) {
          score += 500;
          branchDic2[b.PublicId] = true;
        }

        if (sPreview.indexOf(fullTerm) > -1) {
          score += 30;
          branchDic2[b.PublicId] = true;
        }
        if (sText.indexOf(fullTerm) > -1) {
          score += 30;
          branchDic2[b.PublicId] = true;
        }
        if (sTitle.indexOf(fullTerm) > -1) {
          score += 30;
          branchDic2[b.PublicId] = true;
        }

        tags.forEach((t) => {
          if (t.indexOf(fullTerm) > -1) {
            score += 10;
            branchDic2[b.PublicId] = true;
          }
        });

        terms.forEach((term) => {
          if (sPreview.indexOf(term) > -1) score += 3;
          if (sText.indexOf(term) > -1) score += 3;
          if (sTitle.indexOf(term) > -1) score += 3;

          tags.forEach((t) => {
            if (t.indexOf(term) > -1) score += 2;
            if (t === term) score += 10;
          });
        });

        branchDic[b.PublicId] = score;
      });

      console.log(branchDic);
      let b2 = branchesToSearch.filter((x) => branchDic2[x.PublicId]);
      if (b2.length > 0) return b2;

      return branchesToSearch
        .filter((x) => branchDic[x.PublicId] > 0)
        .sort((a, b) => {
          return branchDic[a.PublicId] > branchDic[b.PublicId] ? -1 : 1;
        });
    }

    return branchesToSearch;
  };

  const branchSelected = (selectedBranch: IBranchSearch) => {
    setBranch(selectedBranch);
    setShowBranch(true);
  };

  return (
    <div className='body-container-footer'>
      <div className="system-tag-bubble-container no-print ">
        {props.state.branchInfo?.HasUnpaidInvoices && props.state.User?.Admin && (
          <a className="unpaid-org-invoices" href={StoreX.BuildUrl(`../app/organization/invoices`)} title={`Unpaid Invoices - View Unpaid Invoice Pending`}>
            <Icon type={IconType.tag} rotate="270" />
          </a>
        )}
        {true && (
          <>
            <div
              className="branch-search-help"
              onClick={() => {
                setShowSearch(true);
              }}
              title="Click for help">
              <Icon type={IconType.info} />
            </div>
          </>
        )}
      </div>

      {showSearch && !showBranch && (
        <>
          <Modal setModalOpen={setShowSearch} title={'How can I help?'} size="l">
            <div className="branch-search-container">
              <div className="search-bar form-horizontal">
                <div className="form-group">
                  <label className="col-sm-4 control-label" htmlFor="search-branch">
                    Search
                  </label>
                  <div className="col-sm-6">
                    <input
                      id="search-branch"
                      autoFocus={true}
                      value={form.text ?? ''}
                      onChange={(e) => {
                        setForm({ ...form, text: e.target.value });
                      }}
                      type="text"
                      max={500}
                      className="form-control"
                      onKeyPress={(key)=>{
                        if(key.key === 'Enter'){
                          let branch = branches[0];
                          if(branch)
                          {
                            branchSelected(branch);
                          }
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="search-results">
                {branches.length > 0 ? (
                  <>
                    {branches.map((x, i) => {
                      return (
                        <div className="search-item" key={`si-${i}`}>
                          <div className="flex-between">
                            <h4>{x.SearchTitle}</h4>
                            <button
                              className="btn btn-default"
                              onClick={() => {
                                branchSelected(x);
                              }}>
                              Open
                            </button>
                          </div>
                          {x.SearchTextPreview && <div dangerouslySetInnerHTML={{ __html: x.SearchTextPreview }}></div>}
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <>
                    <div className="alert alert-info">
                      <h4>
                        <Icon type={IconType.warning} /> No results found.
                      </h4>
                      Please adjust your search paramters or sign-in to see more options.
                    </div>
                  </>
                )}
              </div>
            </div>
          </Modal>
        </>
      )}
      <>
        {showBranch && branch && (
          <Modal setModalOpen={setShowBranch} title={branch?.SearchTitle ?? 'Stickman Stu is here to help'} size="l">
            <BranchPortal publicId={branch.PublicId} />
          </Modal>
        )}
      </>
    </div>
  );
};

export default BranchSearch;
