import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { IState, ReduxMap } from '../../redux/redux'
import './loading.css'

const Loading = (props:IState) => {
  const [progress, setProgress] = useState<number>();
  const [showProgress, setShowProgress] = useState<boolean>(false);

  useEffect(()=>{

    if(props.UploadProgressTotal && props.UploadProgress){
      setProgress(props.UploadProgress / props.UploadProgressTotal * 100.0);
      setShowProgress(true);
    }
    else (setShowProgress(false))
  },[props.UploadProgress, props.UploadProgressTotal]);
  
  return(
    <div className="loading-wrapper">
    <div className="wrapper">
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="shadow"></div>
        <div className="shadow"></div>
        <div className="shadow"></div>
        {false && <div className='loading-text'>{props.LoadingText ?? 'Loading'}</div>}
        {showProgress && <div className='loading-progress flex-between'>
          <span>Uploading... {progress?.toFixed(2)}%</span> 
          {/* <span>{props.UploadProgress} of {props.UploadProgressTotal}</span>  */}
          
          </div>}
    </div>
    </div>
  )
}

const mapStateToProps = (reduxState) => {
  return reduxState.main
}

export default ReduxMap(Loading)