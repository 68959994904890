import { useEffect, useState } from 'react';
import redux, { IState } from '../../redux/redux';
import { IFlexFlowInfo } from './FlexFlowTypes';
import { ServerResponse } from '../../utils/Server';
import { store } from '../../redux/oldStore';
import { toast } from 'react-toastify';
import FormFromApiData from '../FormFromApiData/FormFromApiData';

interface IProps {
  state: IState;
  flowId: string;
  recordId:string;
}

interface IDoneSection {
  Header: string;
  Body: string;
  Class: string;
  PersonInfo: boolean;
  OrderHistory: boolean;
  SpecialAwardNominations:boolean;
}

const FlexFlowDone = (props: IProps) => {
    const [sections, setSections] = useState<IDoneSection[]>();

  useEffect(() => {
    init();
  }, []);

  useEffect(()=>{
    if(sections && sections.length > 0){
      redux.LoadSettings(true);
    }
  },[sections])

  const init = () => {
    store.server.postApi<ServerResponse<IDoneSection[]>>(`../FlexData/Done`, { flowId: props.flowId, recordId: props.recordId }).then((x) => {
      if (x.Success) {
        setSections(x.Value);       
      } else {
        toast.error(x.Message);
      }
    }).catch(x=>{
      toast.error(`Erroring finishing your record. ${x}`);
      console.log(x);
    });
  };

  return <>
  {sections && sections.map((x,i)=>{
    return <div key={`done-section-${i}`}>
<div dangerouslySetInnerHTML={{__html:x.Body}} className={x.Class}/>
    </div>
  })}
  
  </>;
};

export default FlexFlowDone;
