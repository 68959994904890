import React, { useState, useEffect } from 'react';
// import { useTimer } from 'react-timer-hook';
import Timer from 'react-compound-timer'
import { toast } from "react-toastify";


const MyTimer = (props) => {
  const [showTime, setShowTime] = useState(true)


  return (
    <div >
      <Timer
        initialTime={props.initialTime}
        direction={props.direction}
        startImmediately={true}
        timeToUpdate={100}
        checkpoints={props.checkpoints}
      >
        {() => {
          return (
            <React.Fragment>
              {showTime
                &&
                <div
                  className='time-display'
                >
                  <div>
                    <Timer.Minutes /> : <Timer.Seconds formatValue={value => value < 10 ? `0${value}` : value} />
                  </div>
                </div>
              }
            </React.Fragment>
          )
        }
        }
      </Timer>
    </div>
  )

}

export default MyTimer