import React, { useState, useEffect } from "react";
import { store } from "../../redux/oldStore.ts";
import ProjectList from "../Project/ProjectList";

export default function DisplayAndSafety(props) {
  const [started, setStarted] = useState(false);
  const [projects, setProjects] = useState([]);
  const [schools, setSchools] = useState([]);
  const [search, setSearch] = useState(true);
  const [project, setProject] = useState(null);
  const [projectDetails, setProjectDetails] = useState(null);

  useEffect(() => {
    store.server.getApi("../DisplayAndSafety/Details").then((x) => {
      setProjects(x.Value.projects);
      console.log(x.Value);
      let s = [];
      x.Value.schools.forEach((x) => {
        if (x.id > 0) {
          s.push(x);
        }
      });
      setSchools(s);
      setStarted(true);
    });
  }, []);

  const projectSelected = (project) => {
    store.server
      .postApi("../DisplayAndSafety/Project", { projectId: project.Id })
      .then((x) => {
        setProjectDetails(x.Value);
        setProject(project);
        console.log("======================================", project, x.Value);
      });
    setSearch(false);
  };

  const exitDisplayAndSafety = ()=>{
    setProjectDetails(null);
    setProject(null);
    setSearch(true);
  };

  return (
    <div className="displayandsafety-container">
      {started && search && (
        <div>
          <ProjectList
            projects={projects}
            schools={schools}
            onSelect={projectSelected}
          ></ProjectList>
        </div>
      )}
      {!search && project && (
        <div>
          <div className="flex-between">
            <h3>{project.ProjectId}</h3>
            <div><h4>Display & Safety</h4>
              <div className="text-right"><span className="button" onClick={exitDisplayAndSafety}>Exit</span></div>
            </div>
          </div>
          <div className="title">{project.Title}</div>
          <div className="team">
            {project.StudentNames.map((x) => {
              return <span className="person">{x}</span>;
            })}
          </div>

          {projectDetails && projectDetails.projectPlan && projectDetails.projectPlan.length > 0 && (
            <div className="input-review">
              <h4>Plan</h4>
              <div className="content">{projectDetails.projectPlan}</div>
            </div>
          )}

          {projectDetails && projectDetails.projectAbstract && projectDetails.projectAbstract.length > 0 && (
            <div className="input-review">
              <h4>Abstract</h4>
              <div className="content">{projectDetails.projectAbstract}</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
