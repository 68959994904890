import {
  SET_PROJECTS
  ,SET_CATEGORIES
  ,SET_ROUNDS
  ,SET_SELECTED_ROUND
  ,SET_SELECTED_ROUND_INDEX
  ,SET_PAGE
  ,SET_SELECTED_PROJECT
  ,SET_SELECTED_JUDGE
  ,SET_JUDGES
  ,SET_JUDGES_BY_KEY
  ,SET_PROJECTS_BY_KEY
  ,SET_ASSIGNMENTS
  ,SET_PROJECTS_ASSIGNMENTS
  ,SET_JUDGES_ASSIGNMENTS
  ,SET_JUDGING_ROUND_EDIT_MODAL_SHOW
  ,SET_ASSIGNMENT_MODAL_SHOW,
  SET_PROJECTS_BY_CATEGORY
} from './types'



export const setProjects = (array) => {
  return {
    type: SET_PROJECTS,
    payload: array
  }
};

export const setProjectsByKey = (object) => {
  return {
    type: SET_PROJECTS_BY_KEY,
    payload: object
  }
};

export const setProjectsByCategory = (object) => {
  return {
    type: SET_PROJECTS_BY_CATEGORY,
    payload: object
  }
};

export const setCategories = (array) => {
  return {
    type: SET_CATEGORIES,
    payload: array
  }
};

export const setRounds = (array) => {
  return {
    type: SET_ROUNDS,
    payload: array
  }
};

export const setJudges = (array) => {
  return {
    type: SET_JUDGES,
    payload: array
  }
};
export const setJudgesByKey = (object) => {
  return {
    type: SET_JUDGES_BY_KEY,
    payload: object
  }
};

export const setPage = (string) => {
  return {
    type: SET_PAGE,
    payload: string
  }
};

export const setSelectedRoundIndex = (number) => {
  return {
    type: SET_SELECTED_ROUND_INDEX,
    payload: number
  }
};

export const setSelectedRound = (object) => {
  return {
    type: SET_SELECTED_ROUND,
    payload: object
  }
};

export const setSelectedProject = (object) => {
  return {
    type: SET_SELECTED_PROJECT,
    payload: object
  }
};

export const setSelectedJudge = (object) => {
  return {
    type: SET_SELECTED_JUDGE,
    payload: object
  }
};

export const setAssignments = (array) => {
  return {
    type: SET_ASSIGNMENTS,
    payload: array
  }
}
export const setProjectsAssignments = (object) => {
  return {
    type: SET_PROJECTS_ASSIGNMENTS,
    payload: object
  }
}
export const setJudgesAssignments = (object) => {
  return {
    type: SET_JUDGES_ASSIGNMENTS,
    payload: object
  }
}
export const setJudgingRoundEditModalShow = (bool) => {
  return {
    type: SET_JUDGING_ROUND_EDIT_MODAL_SHOW,
    payload: bool
  }
}
export const setAssignmentModalShow = (bool) => {
  return {
    type: SET_ASSIGNMENT_MODAL_SHOW,
    payload: bool
  }
}

