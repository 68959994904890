import {useState, useEffect} from 'react';
import { toast } from 'react-toastify';
import StoreX from '../../redux/oldStore';
import Icon, { IconType } from '../Icon/Icon';
import Modal from '../Modal/Modal';
import { IFormDetail, IFormGroup, IFormSupportingDocument } from './formTypes';
import Select from "react-select";
import Editor from './Editor';

interface IFormsSupportingDocumentManager {
    packet:IFormGroup,
    form:IFormDetail,
    updateAndSave:Function,
}

const FormsSupportingDocumentManager = (props:IFormsSupportingDocumentManager)=>{
    const [docs, setDocs] = useState<IFormSupportingDocument[]>(props.form.SupportingDocuments ?? []);
    const [doc, setDoc] = useState<IFormSupportingDocument>();
    const [showEditModal, setShowEditModal] = useState<boolean>(false);


    useEffect(()=>{
    },[]);


    const EditDoc = (doc?:IFormSupportingDocument) =>{
        let max = 1;
        docs.forEach(x=>{
            max = Math.max(max, x.Order + 1);
        })
        let q:IFormSupportingDocument = doc ?? {Order:max,PublicId:StoreX.NewGuid(),Instructions:'', Name:'', FileKeys:[]};

        setDoc(q);
        setShowEditModal(true);
    };

    const RemoveDoc = () =>{
        if(window.confirm(`Are you sure you want to remove this supporting document, this cannot be undone?`)){
            let q = docs.filter(q=>q.PublicId !== doc?.PublicId);
            props.updateAndSave(q);
            setDocs([...q]);
            setDoc(undefined);
            setShowEditModal(false);
            toast.success(`Supporting document removed!`);
        }
    };
    const SaveDoc = () =>{

        let errors = [];
        if(doc?.Name?.length === 0 ?? true) {

            toast.warn(`So we don't get confused, what would you like to name this file?`);
            return;
        }

            let qs = docs;
            let found = false;

            for(let i =0; i < qs.length; i++){
                if(qs[i].PublicId == doc?.PublicId){
                    found = true;
                    qs[i] = {...doc};
                    break;
                }
            }

            if(!found) qs.push(doc);

            qs = qs.sort((a, b)=> a.Order - b.Order);

            props.updateAndSave([...qs]);
            setDocs([...qs]);
            toast.success(`Supporting document saved!`);
    };

    return (<>
        <div>
            <h4>Supporting Documents</h4>
            Do you need additional files to be uploaded to complete this form? Well then Supporting Documents is your answer.  
            <hr/>

            <div>
                <div className="flex-between">
                    <h3>Documents</h3>
                    <button type="button" onClick={()=>{EditDoc()}} className="btn btn-secondary">
                        <Icon type={IconType.plus}/> New Document
                    </button>
                </div>
                <div className="wizard-question-container">
                    {docs.map((x,i)=>{
                        return (<div key={`wiz-question-${i}`} className='qizard-question-tile'>
                            <div>
                                <span className='text-muted'>{x.Order} - </span>
                                {x.Name}
                                </div>
                                <span className='click' onClick={()=>{EditDoc(x);}} title='Click to edit question'>
                                    <Icon type={IconType.edit}  />
                                </span>
                            </div>);
                    })}
                </div>
            </div>

        </div>
        
        {showEditModal && <Modal setModalOpen={setShowEditModal} title={'Edit Supporting Document'} size='l'>
            <div className='form-horizontal'>
                <div className='form-group'>
                    <label htmlFor='wiz-doc-order' className='col-sm-4 control-label'>Order</label>
                    <div className='col-sm-2'>
                        <input type='number' max={10000} min={0} step={1} className='form-control'
                         id='wiz-doc-order'
                         value={doc?.Order}
                         onChange={(e)=>{if(doc)setDoc({...doc, Order:+e.target.value});}}
                         />
                    </div>
                </div>
                <div className='form-group'>
                    <label htmlFor='wiz-doc-name' className='col-sm-4 control-label'>Name</label>
                    <div className='col-sm-8'>                        
                        <input type="text" className='form-control' 
                        id='wiz-doc-name'
                        maxLength={250}
                        value={doc?.Name}
                        onChange={(e)=>{if(doc)setDoc({...doc, Name:e.target.value});}}
                        />
                    </div>
                </div>
                <div className='form-group'>
                    <label htmlFor='wiz-doc-in' className='col-sm-4 control-label'>Instructions</label>
                    <div className='col-sm-8'>                        
                    <Editor
                            options={"simple"}
                            value={doc?.Instructions ?? ''}
                            changed={(v) => {
                                if (doc)
                                    setDoc({ ...doc, Instructions: v });
                            } } height={'short'}            />
                    </div>
                </div>
                <div className='form-group'>
                    <div className='col-sm-8 col-sm-offset-4'>
                        <button type='button' className='btn btn-secondary' title='Click to save' onClick={SaveDoc}>
                            <Icon type={IconType.edit} /> Save
                        </button>
                        <button type='button' className='btn btn-default' title='Click to remove question' onClick={RemoveDoc}>
                            <Icon type={IconType.trashO} /> Remove
                        </button>
                    </div>
                </div>
            </div>
        </Modal>}
    </>);
};

export default FormsSupportingDocumentManager;