export interface UrlFileResult {
  url: string,
  filename: string,
  file: File | null
}

export enum DataType {
  jpeg = "image/jpeg",
  png = "image/png"
}

export const base64StringToDataUrl = (base64: string, type: DataType) => {
  return `data:${type};base64,${base64}`
}


export const objectUrlToDataUrl = async (objectUrl: string) => {
  const fileBlob = await fetch(objectUrl).then(r => r.blob());
  const dataUrl = await blobToDataUrlAsync(fileBlob);
  return dataUrl;
}


export const blobToDataUrlAsync = (blob: Blob) => {
  const promise = new Promise<string>((resolve) => {
    blobToDataURL(blob, (result: string) => {
      resolve(result ?? "");
    })
  });
  return promise;
}

export const blobToDataURL = (blob: Blob, callback: Function) => {
  var a = new FileReader();
  a.onload = (e) => { callback(e.target?.result); }
  a.readAsDataURL(blob);
}

export type getUrlFromUserFileOptions = { urlType?: "DataUrl" | "ObjectUrl", accept?: string };

export const getUrlFromUserFile = (options?: getUrlFromUserFileOptions) => {
  //set the default options and overwrite them with the ones the user passes in.
  const actualOptions: Required<getUrlFromUserFileOptions> = {
    accept: "image/*",
    urlType: "ObjectUrl",
    ...options
  };

  return new Promise<UrlFileResult>((resolve) => {
    //let input:HTMLInputElement|null = null;//(document.getElementById('image-input-magic') as HTMLInputElement);
    //if(!input){      
    let input = document.createElement('input');
    input.id = 'image-input-magic';
    input.type = "file";
    input.accept = actualOptions.accept
    input.style.display = 'none';
    document.body.appendChild(input);
    //}

    const resolveFunction = (resolveMethod: (value?: UrlFileResult | PromiseLike<UrlFileResult> | undefined) => void) => {
      // document..removeChild(input); //cleanup the input.
      resolveMethod();
    }

    const itChanged = async (e: any) => {
      if (e.target.files) {
        const file = e.target.files[0];
        switch (actualOptions.urlType ?? "ObjectUrl") {
          case "ObjectUrl":
            const url = URL.createObjectURL(file);
            resolveFunction(() => resolve({ url: url, filename: file.name, file }));
            return;
          case "DataUrl":
          default:
            const dataUrl = await blobToDataUrlAsync(file);
            resolveFunction(() => resolve({ url: dataUrl, filename: file.name, file }));
            return;
        }
      } else {
        resolve({ url: "", filename: "", file: null });
      }
    };

    //input.onchange = itChanged;
    //input.removeEventListener('change', itChanged);
    input.addEventListener('change', itChanged);

    input.click();
  });
}


export function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  //Old Code
  //write the ArrayBuffer to a blob, and you're done
  //var bb = new BlobBuilder();
  //bb.append(ab);
  //return bb.getBlob(mimeString);

  //New Code
  return new Blob([ab], { type: mimeString });


}