import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import StoreX, { store } from '../../redux/oldStore';
import { FieldTypes, FileUploadData, UiFormField, UiFormSection } from '../../utils/CommonTypes/UserTypes';
import { ServerResponse } from '../../utils/Server';
import Icon, { IconType } from '../Icon/Icon';
import FormModal from './FormModal';

interface IUserPartyManagerProps {
  isNew: boolean;
  canSave: boolean;
  userId: string;
  firstTimeLogin: boolean;
}

interface IUserPartyManagerInfo {
  MainText: string;
  Party: IPartyMember[];
  MemberSections: UiFormSection[];
}

interface IPartyMember {
  Name?: string;
  FirstName?: string;
  LastName?: string;
  Id?: string;
  IsPaid?: boolean;
  PaidOnOrderId?: number;
  Values?: Record<string, string[]>;
}

const UserPartyManager = (props: IUserPartyManagerProps) => {
  const [info, setInfo] = useState<IUserPartyManagerInfo>();
  const [partyMember, setParytMember] = useState<IPartyMember>({});
  const [showEditModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (partyMember && partyMember.Name && !partyMember.FirstName) {
      let pm = { ...partyMember };
      let names = partyMember.Name.split(' ');
      pm.FirstName = names[0];
      pm.LastName = names[1] ?? pm.Name;
      if (pm.Values) {
        pm.Values['FirstName'] = [pm.FirstName];
        pm.Values['LastName'] = [pm.LastName];
      }
      setParytMember(pm);
      console.log(pm);
    }
  }, [partyMember]);

  const init = () => {
    //call server
    store.server.postApi<ServerResponse<IUserPartyManagerInfo>>(`../UserProfile/PartyManagerInfo`, { userId: props.userId }).then((x) => {
      if (x.Success) {
        setInfo(x.Value);
      } else {
        toast.error(x.Message, { autoClose: false });
      }
    });
  };

  const editPartyMember = (member: IPartyMember) => {
    setShowModal(true);
    setParytMember(member);
    if (!member.Values && info) {
      let sections = info.MemberSections;
      sections.forEach((s) => {
        s.Fields?.forEach((f) => {
          f.Value = [];
        });
      });

      setInfo({ ...info, MemberSections: sections });
    }
  };

  const modalSave = (values: Record<string, string[]>) => {
    //call server
    let request = { ...partyMember };
    request.Values = values;
    let wrapper = {
      userId: props.userId,
      member: request,
    };

    let data = JSON.stringify(wrapper);

    store.server.postApi<ServerResponse<IPartyMember>>(`../UserProfile/SavePartyInfo`, { data: data }).then((x) => {
      if (x.Success) {
        if (info) {
          let xInfo = { ...info };
          let m = xInfo.Party?.find((y) => y.Id === x.Value.Id);
          if (m) {
            m.Name = x.Value.Name;
            m.Values = x.Value.Values;
          } else {
            if (!xInfo.Party) xInfo.Party = [];
            m = x.Value;
            xInfo.Party.push(x.Value);
          }
          setInfo(xInfo);
          setParytMember(m);
          setShowModal(false);
        }
      } else {
        toast.error(x.Message, { autoClose: false });
      }
    });
  };

  const modalRemove = (values: Record<string, string[]>) => {
    if (!window.confirm('Are you sure you want to remove this member?')) {
      return;
    }
    //call server
    let request = { ...partyMember };
    request.Values = values;
    let wrapper = {
      userId: props.userId,
      member: request,
    };

    let data = JSON.stringify(wrapper);

    store.server.postApi<ServerResponse<IPartyMember>>(`../UserProfile/RemovePartyInfo`, { data: data }).then((x) => {
      if (x.Success) {
        if (info) {
          let xInfo = { ...info };
          xInfo.Party = xInfo.Party.filter((y) => y.Id !== partyMember.Id);
          setInfo(xInfo);
          setParytMember({});
          setShowModal(false);
        }
      } else {
        toast.error(x.Message, { autoClose: false });
      }
    });
  };

  return (
    <>
      {info && (
        <>
          <div dangerouslySetInnerHTML={{ __html: info.MainText }} />
          <hr />
          <div className="users-party-container">
            {info.Party &&
              info.Party.length > 0 &&
              info.Party.map((x, i) => {
                return (
                  <div
                    key={`up-${i}`}
                    className="party-member"
                    onClick={() => {
                      editPartyMember(x);
                    }}>
                    <Icon type={IconType.user} addClass={'fa-3x'} />
                    {x.FirstName && x.LastName ? (
                      <span>
                        {x.FirstName} {x.LastName}
                      </span>
                    ) : (
                      <span>{x.Name}</span>
                    )}

                    {x.IsPaid && <div className="paid">Paid</div>}
                  </div>
                );
              })}
          </div>
          <div className="flex-betweenx headroom-xl">
            <button
              type="button"
              className="btn btn-secondary add-member-btn"
              onClick={() => {
                editPartyMember({});
              }}>
              <Icon type={IconType.userPlus} /> Add Member
            </button>

            {!props.isNew && !props.firstTimeLogin && info?.Party?.find((x) => !x.IsPaid) && (
              <a className="btn btn-default" href={StoreX.BuildUrlWithFair(`../App/Store/${props.userId}`)} title="Go to store to pay">
                <Icon type={IconType.shoppingCart} /> Checkout
              </a>
            )}
          </div>
          <div className="headroom-xl"></div>
        </>
      )}

      {partyMember && info?.MemberSections && (
        <FormModal
          save={modalSave}
          remove={modalRemove}
          canSave={props.canSave}
          canRemove={partyMember.Id ? true : false}
          sections={info?.MemberSections ?? []}
          show={showEditModal}
          setShow={setShowModal}
          title={'Manage Member'}
          values={partyMember.Values ?? {}}
          modalSize={'m'}
          targetId={props.userId}
        />
      )}
    </>
  );
};

export default UserPartyManager;
