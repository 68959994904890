import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { store } from "../../redux/oldStore";
import { ServerResponse } from "../../utils/Server";
import Icon, { IconType } from "../Icon/Icon";
import Modal from "../Modal/Modal";
import { IVirtualRoom, ILogMeInRequest } from "./JustVideoTypes";

interface IGatedEntryControlerProps {
  Meeting: IVirtualRoom;
  CanApprove: boolean;
  Join: Function;
}

const GatedEntryControler = (props: IGatedEntryControlerProps) => {
  // const [findWaiters, setFindWaiters] = useState<boolean>();
  const ref = useRef(props.Join);
  const [waiters, setWaiters] = useState<ILogMeInRequest[]>([]);
  const [canJoin, setCanJoin] = useState<boolean>(false);
  const [showWaitingForEntry, setShowWiatingForEntry] = useState<boolean>(false);
  const refCanJoin = useRef(setCanJoin);
  const [showSmallWaiters, setShowSmallWaiters] = useState<boolean>(false);

  useEffect(() => {
    if (props.CanApprove && props.Meeting.RoomPin) {
      //setFindWaiters(true);
      LookForWaiters();
    } else {
      CanIJoin();
      setShowWiatingForEntry(true);
    }
  }, []);

  useEffect(()=>{
      if(canJoin) props.Join();
  }, [canJoin])

  const CanIJoin = () => {
    store.server
      .getApi<ServerResponse<ILogMeInRequest>>(
        `../Hinge/LetMeIn/${props.Meeting.RoomKey}`
      )
      .then((x) => {
        if (x.Success) {
          if (!canJoin && !x.Value.Approved)
            setTimeout(refCanIJoin.current, 5 * 1000);
          if (x.Value.Approved) {
            //CallJoinMeeting();
            refCanJoin.current(true);            
            setShowWiatingForEntry(false);
          }
        }
      });
  };
  const refCanIJoin = useRef(CanIJoin);

  const LookForWaiters = () => {
    store.server
      .getApiQuiet<ServerResponse<ILogMeInRequest[]>>(
        `../Hinge/LetMeInRequests/${props.Meeting.RoomKey}`
      )
      .then((x) => {
        if (x.Success) {
          setWaiters(x.Value);
          //if(findWaiters)
          setTimeout(LookForWaiters, 5 * 1000);
        }
      });
  };

  const allowEntry = (request: ILogMeInRequest) => {
    store.server
      .postApi<ServerResponse<ILogMeInRequest>>(`../Hinge/ApproveLetMeIn`, {
        id: props.Meeting.RoomKey,
        userId: request.Id,
      })
      .then((x) => {
        if (x.Success) {
          toast.info(
            `Admitted ${x.Value.Name}, it may take 15 - 20 seconds before they join.`
          );
          setWaiters([...waiters.filter((y) => y.Id !== x.Value.Id)]);
        }
      });
  };

  const CallJoinMeeting = () => {
      ref.current();
  };

  return (
    <>
      {waiters.length > 0 && (
        <div className="gated-entery-video">
        <div className="flex-between">
          <h4>{props.Meeting.Name ?? "Virtual Meeting"}</h4>
         { !showSmallWaiters ? <span className="click" onClick={()=>{setShowSmallWaiters(true)}}>
              <Icon type={IconType.up} />
          </span>
          :
          <span className="click" onClick={()=>{setShowSmallWaiters(false)}}>
              <Icon type={IconType.down} />
          </span>}
          </div>
          <div className={`gated-entery-video-wait-list ${showSmallWaiters ? 'hide':''}`}>
            {waiters.map((x, i) => {
              return (
                <div
                  className="gated-entry-waiter flex-between"
                  key={`waiter-${i}`}
                >
                  <strong>{x.Name}</strong>
                  <div>
                    <button
                      type="button"
                      className="btn btn-default btn-xs"
                      onClick={() => {
                        allowEntry(x);
                      }}
                    >
                      <Icon type={IconType.unlock} /> Admit
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {!canJoin && showWaitingForEntry && (
        <Modal
          setModalOpen={setShowWiatingForEntry}
          title={"Waiting for Entry"}
          noClose={true}
          size="m"
        >
          <h3>{props.Meeting.Name ?? "Virtual Meeting Room"}</h3>
          <hr />
          We are waiting for someone to allow you entry into the meeting.
        </Modal>
      )}
    </>
  );
};

export default GatedEntryControler;
