import { Server, ServerResponse } from "../utils/Server";
import lscache from "lscache";
import { v4 as guid } from "uuid";
import { IUser, ISettings, ISettingsResponse, IFileInfo } from "./types";


export default class StoreX {
  static FileInfo(filePath: string | undefined) {
    if (!filePath) return null;
    let ext = filePath.substring(filePath.lastIndexOf(".")).toLowerCase();
    let isPdf = ext === ".pdf";
    let isImage =
      ext === ".jpg" ||
      ext === ".jpeg" ||
      ext === ".png" ||
      ext === ".tif" ||
      ext === ".tiff" ||
      ext === ".gif";
    let isVideo = [".mov", ".mp4"].find((x) => x === ext);
    let isText = [".csv", ".xml", ".txt"].find((x) => x === ext);
    let type:any = isPdf
      ? "pdf"
      : isImage
      ? "image"
      : isVideo
      ? "video"
      : isText
      ? "text"
      : "other";

    let result: IFileInfo = { type: type, path: filePath, ext: ext };
    return result;
  }
  static BuildUrl(path: string) {
    path = path?.toLowerCase() ?? '';
    if (path.indexOf("http:") === 0 || path.indexOf("https:") === 0)
      return path;
    let isDev = window.location.host.indexOf("localhost:") > -1;
    let host = isDev ? "http://localhost:8081" : window.location.origin;
    path = path.replace("../", "/");
    return `${host}${path}`;
  }

  public static BuildUrlWithFair(path: string) {
    path = path?.toLowerCase() ?? '';
    if (path.indexOf("http:") === 0 || path.indexOf("https:") === 0)
      return path;

    let isDev = window.location.host.indexOf("localhost:") > -1;
    let host = isDev ? Server.GetDevHost() : window.location.origin;
    path = path.replace("../", "/");
    let hasQueryString = path.indexOf('?') > -1;
    
    return `${host}${path}${hasQueryString?'&':'?'}f=${this.GetQueryStringValue("f")}`;
  }
  server: Server;
  public user?: IUser;
  public static settingsResponse?: ISettingsResponse;
  public static Settings?: ISettings;
  public static Text: any = {};
  public static instance: StoreX;
  private constructor() {
    this.server = new Server(true);
  }

  public static IsDev() {
    return window.location.host.indexOf("localhost:") > -1;
  }

  public static NewGuid() {
    return guid();
  }

  public static GetQueryStringValue(key: string) {
    let params = new URLSearchParams(window.location.search);
    const newParams = new URLSearchParams();
    for (const [name, value] of params) {
      newParams.append(name.toLowerCase(), value);
    }
    return newParams.get(key.toLowerCase());
  }

  public static getInstance() {
    if (!StoreX.instance) {
      StoreX.instance = new StoreX();
    }
    return StoreX.instance;
  }

  public setUseLoadingScreen(value: any) {
    this.server.loadingScreen = value;
  }

  // private async getStyleText(bustCache: boolean) {
  //   const key = "styleText";
  //   if (bustCache) lscache.remove(key);
  //   let data = lscache.get(key);
  //   if (!data) {
  //     try {
  //       data = (
  //         await this.server.getApi<ServerResponse<any>>("../app2/styleText")
  //       ).Value;
  //       lscache.set(key, data, 60 * 24 * 365); // last argument is expiration length, in minutes
  //     } catch (err) {
  //       console.error("could not get styleText", err);
  //     }
  //   }
  //   return data;
  // }

  private async getSettings(bustCache: boolean) {
    
    const key = `fmsSettings`;
    if (bustCache) lscache.remove(key);
    let data = lscache.get(key);
    if (!data) {
      try {
        data = (
          await this.server.getApi<ServerResponse<ISettingsResponse>>(
            "../app2/Settings"
          )
        ).Value;
        lscache.set(key, data, 60 * 24 * 365); // last argument is expiration length, in minutes
      } catch (err) {
        console.error("could not get settings", err);
      }
    }
    StoreX.settingsResponse = data;
    StoreX.Settings = data.Settings;
    StoreX.Text = StoreX.Settings?.text ?? {};
    this.user = data.user;
    return StoreX.settingsResponse;
  }

  private async getUser() {
    if (!this.user) {
      this.user = (await this.getSettings(false))?.user;
    }
    return this.user;
  }
}
const store = StoreX.getInstance();
export { store };
