import { useEffect, useState } from "react";
import { IListItem, IScheduleRound, ITemplate } from "./NhdTypes";
import Icon, { IconType } from '../../Icon/Icon';
import { toast } from "react-toastify";

interface IProps {
    template:ITemplate,
    grades: IListItem[];
    rounds: IScheduleRound[];
    onBuildRound:(roundInfo:IScheduleRound)=>void;    
}

const NhdScheduleBuildRoundManager = (props:IProps) =>{
    const [round, setRound] = useState<IScheduleRound>({});

    useEffect(()=>{
        if(props.template){
            setRound({...round,
                name:`${props.template.name}`, 
                templateId:props.template.id,
                categoryId:props.template.category,
                poolFilterPreviousRound: -1,
                poolFilterGrade: '-1',
                isFinalRound:false,
                fairId: 0,
                id:0,
        });
        }
    }, [props.template]);


    const onBuild = ()=>{

        let errors:string[] = [];

            if(!round.name || round.name.length === 0) errors.push('Please name this round!');
            
            if(errors.length > 0){
            toast.warning(<>{errors.map(x=>{return <div>{x}</div>})}</>);
            return;
        }

        props.onBuildRound(round);

    };

    return <>
        <div className="form-horizontal">
            <div className="form-group">
                <label className="col-sm-4 control-label" htmlFor="nhdsbrm-name">Round Name</label>
                <div className="col-sm-6">
                    <input
                    id={'nhdsbrm-name'}
                    type={'text'}
                    maxLength={50}
                    value={round.name ?? ''}
                    onChange={(x)=>{
                        setRound({...round,name:x.target.value});
                    }}
                    placeholder="What is the name of this round?"
                    className="form-control"
                    />
                </div>
            </div>
            <div className="form-group">
                
                <div className="col-sm-8 col-sm-offset-4">
                    <input
                    id={'nhdsbrm-finals'}
                    type={'checkbox'}
                    checked={round.isFinalRound}
                    onChange={(x)=>{
                        setRound({...round,isFinalRound:x.target.checked});
                    }}                    
                    /><label className="control-label" htmlFor="nhdsbrm-finals">Is Finals Round</label>
                </div>
            </div>
            <h3>Entry Filters</h3>
            <div className="form-group">
                <label className="col-sm-4 control-label" htmlFor="nhdsbrm-grade">Grade</label>
                <div className="col-sm-6">
                    <select
                    id={'nhdsbrm-grade'}
                    value={round.poolFilterGrade ?? '-1'}
                    onChange={(x)=>{
                        setRound({...round,poolFilterGrade:x.target.value});}}
                    //placeholder="Grade Entry Filter"
                    className="form-control"
                    >
                        <option value={'-1'}>All Grades</option>
                        {props.grades.map((x,i)=>{
                            return <option value={x.id}>{x.name}</option>
                        })}
                    </select>
                </div>
            </div>
            <div className="form-group">
                <label className="col-sm-4 control-label" htmlFor="nhdsbrm-Round">Previous Round</label>
                <div className="col-sm-6">
                    <select
                    id={'nhdsbrm-Round'}
                    value={round.poolFilterPreviousRound ?? '-1'}
                    onChange={(x)=>{
                        setRound({...round,poolFilterPreviousRound:+x.target.value});}}
                    //placeholder="Revious Round Entry Filter"
                    className="form-control"
                    >
                    <option value={'-1'}>No Round Filter</option>
                    {props.rounds.map((x,i)=>{
                        return <option value={x.id}>{x.name}</option>
                    })}
                </select>
                </div>
            </div>
            <div className="form-group">
                <label className="col-sm-4 control-label" htmlFor="nhdsbrm-lowest-rank">Lowest Rank Permitted</label>
                <div className="col-sm-6">
                    <input
                    id={'nhdsbrm-lowest-rank'}
                    type={'number'}
                    min={0}
                    max={100}
                    value={round.poolFilterLowestRankPermitted ?? ''}
                    onChange={(x)=>{                        
                        setRound({...round,poolFilterLowestRankPermitted:+x.target.value});
                    }}
                    placeholder="lowest-rank"
                    className="form-control"
                    />
                </div>
            </div>
            <div className="form-group">
                <div className="col-sm-8 col-sm-offset-4">
                <button type={'button'} className='btn btn-secondary ' onClick={onBuild}>
                    <Icon type={IconType.build} /> Generate Round</button>
                </div>
            </div>
        </div>
    </>
};

export default NhdScheduleBuildRoundManager;