import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { store } from "../../redux/oldStore";
import { ServerResponse } from "../../utils/Server";
import { FormatDate } from "../../utils/Tools";

interface IReportProps {

}

interface IData {
    Data:IDataRecord[];
}

interface IDataRecord {
    FirstName:string;
	LastName:string;
	Email:string;
	Phone:string;
	Cell:string;
	Username:string;
    InterviewAt:string;
	Title:string;
	Category:string;
	SessionName:string;
	RoundName:string;
	CirlceName:string;
	ProjectId:string;
	ProjectIdInt:number;
	JudgeId:number;
}

export const JudgingAssignmentsReportStickers = (props:IReportProps) =>{
    const [info, setInfo] = useState<IData>();
    const [judges, setJudges] = useState<number[]>([]);
    const [judgeAssignments, setJudgeAssignments] = useState<Record<number, IDataRecord[]>>({});

    useEffect(()=>{
        store.server.getApi<ServerResponse<IData>>(`../Report/JudgingAssignments`)
        .then(x=>{
            if(x.Success){
                setInfo(x.Value);
                let judgeId = 0;
                let tJudges:number[] = [];
                let tAssignments:Record<number, IDataRecord[]> = {};
                for(let i = 0; i < x.Value.Data.length; i++){
                    if(judgeId !== x.Value.Data[i].JudgeId){
                        judgeId = x.Value.Data[i].JudgeId;
                        tJudges.push(judgeId);
                        tAssignments[judgeId] = [];
                    }
                    tAssignments[judgeId].push(x.Value.Data[i]);
                }

                setJudgeAssignments(tAssignments);
                setJudges(tJudges);

            } else {
                toast.error(x.Message, {autoClose:false});
            }
        });
    },[]);
    

    return (<>
        <div className="judging-assignments-report-sickers-2pp">
            {info && judges.map((judgeId, i)=>{
                let records = judgeAssignments[judgeId] ?? [];

                return (<div key={`judge-record-${judgeId}`}>
                    <div className="jr-assignments">
                        <h3>{records[0].FirstName} {records[0].LastName}</h3>
                        {records.map((x,i)=>{
                            return (<div>
                                {FormatDate(x.InterviewAt)} 
                                {x.ProjectId} 
                                {x.Title}
                            </div>);
                        })}
                    </div>
                </div>);
            })}
        </div>
    </>)

};