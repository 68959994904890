import React, { useEffect, useState } from 'react'
import { store } from "../../redux/oldStore.ts";
import ExternalJudge from './ExternalJudge';
import { toast } from "react-toastify";
import booImg from "../../../public/boo.jpg";


const ExternalJudgesContainer = (props) => {
  const [judgesArr, setJudgesArr] = useState([])
  const [filteredJudgesArr, setFilteredJudgesArr] = useState([])
  const [searchVal, setSearchVal] = useState('')
const [boo, setBoo] = useState(false);
const booSound = new Audio('/sounds/boo.mp3')
  const init = () => {
    store.server.getApi('../ExternalJudges/Judges')
      .then(res => {
        console.log(res)
        if (res.LoginRequired) return toast.info('you need to log in')
        // handle not logged in
        setJudgesArr(res.Value.judges)
        setFilteredJudgesArr(res.Value.judges)
      })
      .catch(err => toast.error('something went wrong getting the judges'));
  };

  useEffect(() => {
    init()
  }, []);

  useEffect(() => {
    setFilteredJudgesArr(
      judgesArr.filter(judge => judge.firstName.toLowerCase().includes(searchVal.toLowerCase()) || judge.lastName.toLowerCase().includes(searchVal.toLowerCase())
      )
    );

  }, [searchVal])

  const approveJudge = (portalId) => {
    // do something to format the body correctly
    store.server.postApi('../ExternalJudges/Approve', { portalId })
      .then(res => {
        console.log('post approve api', res);
        toast.success('judge approved');
        //update the judgesArr with the approval
        let [personToUpdate] = judgesArr.filter(judge => judge.portalId === portalId);
        personToUpdate.approved = true;
        setJudgesArr(judgesArr.map(judge => judge.portalId === personToUpdate.portalId ? personToUpdate : judge));
        setFilteredJudgesArr(filteredJudgesArr.map(judge => judge.portalId === personToUpdate.portalId ? personToUpdate : judge));
      })
      .catch(err => toast.error('something went wrong with the approval'))
  }

  const scare = ()=> {
    window.scroll(0,0);

      setBoo("true");
      booSound.play();

      
      setTimeout(()=>{
        setBoo(false);
      }, 3000);
  }

  const denyJudge = (portalId) => {
    // do something to format the body correctly
  

    store.server.postApi('../ExternalJudges/Deny', { portalId })
      .then(res => {
        console.log('post deny api', res);
        toast.warning('judge denied');
        //update the judgesArr with the denial
        let [personToUpdate] = judgesArr.filter(judge => judge.portalId === portalId);
        personToUpdate.denied = true;
        setJudgesArr(judgesArr.map(judge => judge.portalId === personToUpdate.portalId ? personToUpdate : judge));
        setFilteredJudgesArr(filteredJudgesArr.map(judge => judge.portalId === personToUpdate.portalId ? personToUpdate : judge));
      })
      .catch(err => toast.error('something went wrong with the denial'))


  }

  const removeJudge = (portalId) => {
    
    // do something to format the body correctly
    store.server.postApi('../ExternalJudges/Remove', { portalId })
      .then(res => {
        console.log('post remove api', res);
        toast.error('judge removed');
        //update the judgesArr with the removal
        let [personToUpdate] = judgesArr.filter(judge => judge.portalId === portalId);
        personToUpdate.removed = true;
        setJudgesArr(judgesArr.map(judge => judge.portalId === personToUpdate.portalId ? personToUpdate : judge));
        setFilteredJudgesArr(filteredJudgesArr.map(judge => judge.portalId === personToUpdate.portalId ? personToUpdate : judge));
      })
      .catch(err => toast.error('something went wrong with the removal'))
  }
  const clearAll = () => {
    
    // do something to format the body correctly
    if(window.confirm(`Are you sure you want to remove all external judges?`)){
    store.server.postApi('../ExternalJudges/ClearAll', { })
      .then(res => {
        init();
      })
      .catch(err => toast.error('something went wrong with the removal'));
    }
  }

  const mappedJudges = filteredJudgesArr.map((judge, i) => {
    return (
      <div key={`${judge.portalId}${i}`}>
        <ExternalJudge
          judge={judge}
          approveJudge={approveJudge}
          denyJudge={denyJudge}
          removeJudge={removeJudge}

        />
      </div>
    )
  })


  return (
    <div
      className='external-judges-container'
    >
      <div className="row">

        <div className='col-sm-6 col-sm-offset-3'>

          <input
            onChange={e => setSearchVal(e.target.value)}
            className="form-control"
            placeholder='search by name'
          />

        </div>
        <div className='col-sm-3 text-right'>
          <button type="button" className='btn btn-default' onClick={clearAll}>Clear all external judges</button>
        </div>
      </div>
      <div className='clear-fix'></div>
      <div>

        {mappedJudges}
      </div>
      <div className={boo ? 'boo':'boo hide'}>
        <img src={booImg}></img>
      </div>

      <span className="scare hide" onClick={scare}><i className="fad fa-jack-o-lantern"></i></span>
    </div>
  )
}


export default ExternalJudgesContainer