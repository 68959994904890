import { useEffect, useState } from 'react';
import {
  FullGetPeopleValue,
  IFilter,
  IFilterGroup,
  FilterComparison,
  getSearchableFields,
  getComparisonFields,
  IFilterProperty,
  getAndOrFields,
  getSearchableFieldName,
  getSearchableFieldDataOptions,
  getSearchableFieldDataType,
  dataType,
} from '../../utils/CommonTypes/PeoplePageTypes';
import Icon, { IconType } from '../Icon/Icon';
import Modal from '../Modal/Modal';
import Select from 'react-select';
import { Selectable } from '../../utils/CommonTypes/CommonTypes';
import { toast } from 'react-toastify';
import React from 'react';

interface IAdvancedFilterProps {
  data: FullGetPeopleValue;
  newFilter: (filter: IFilterGroup) => void;
}

interface IForm {
  fields?: Selectable[];
  comparison?: Selectable;
  andOr?: Selectable;
  parentComparison?: Selectable;
  value?: string;
  valueOption?: Selectable;
  type?:dataType;
}

export const AdvancedFilter = (props: IAdvancedFilterProps) => {
  const [filterGroup, setFilterGroup] = useState<IFilterGroup>({ Filters: [], ComparisonType: FilterComparison.and });
  const [filter, setFilter] = useState<IFilter>();
  const [showAddFilter, setShowAddFilter] = useState<boolean>(false);
  const [fields, setFields] = useState<Selectable[]>([]);
  const [comparisonTypes, setComparisonTypes] = useState<Selectable[]>([]);
  const [form, setForm] = useState<IForm>({});
  const [valueOptions, setValueOptions] = useState<Selectable[]>();

  useEffect(() => {
    setFields(getSearchableFields());
    setComparisonTypes(getComparisonFields());
  }, []);

  useEffect(() => {
    if (form.fields && form.fields.length === 1) {
      let type = getSearchableFieldDataType(form.fields[0].value + '');

      let options = getSearchableFieldDataOptions(form.fields[0].value + '', props.data);
      setValueOptions(options);
      let types=getComparisonFields(getSearchableFieldDataType(form.fields[0].value+''), props.data, form.fields[0].value+'');
      setComparisonTypes(types);

      setForm({...form, comparison:types[0], valueOption:options ? options[0]:undefined, type:type});
    } else {
      setValueOptions(undefined);
      setComparisonTypes(getComparisonFields());
    }
  }, [form.fields]);

  useEffect(()=>{
    props.newFilter(filterGroup);
  },[filterGroup]);

  const getSign = (type: FilterComparison) => {
    switch (type) {
      case FilterComparison.and:
        return (
          <span>
            <Icon type={IconType.and} /> And
          </span>
        );
        break;
      case FilterComparison.or:
        return (
          <span>
            <Icon type={IconType.or} /> Or
          </span>
        );
        break;
      case FilterComparison.greaterThan:
        return (
          <span>
            <Icon type={IconType.greaterThan} /> Greater Than
          </span>
        );
        break;
      case FilterComparison.lessThan:
        return (
          <span>
            <Icon type={IconType.lessThan} /> Less Than
          </span>
        );
        break;
        case FilterComparison.equal:
          return (
            <span>
              <Icon type={IconType.equal} /> Equal
            </span>
          );
          break;
          case FilterComparison.notEqual:
            return (
              <span>
                <Icon type={IconType.notEqual} /> Not Equal
              </span>
            );
            break;
      case FilterComparison.contains:
        return (
          <span>
            <Icon type={IconType.contains} /> Contains
          </span>
        );
        break;
      case FilterComparison.notContains:
        return (
          <span>
            <Icon type={IconType.notContains} /> Not Contains
          </span>
        );
        break;
      case FilterComparison.before:
        return (
          <span>
            <Icon type={IconType.before} />
            Before
          </span>
        );
        break;
      case FilterComparison.after:
        return (
          <span>
            <Icon type={IconType.after} />
            After
          </span>
        );
        break;
    }
  };

  const getProperties = (items: Selectable[]) => {
    let props: IFilterProperty[] = [];

    items.forEach((x) => {
      props.push({
        Key: x.value + '',
      });
    });

    return props;
  };
  const getComparisonType = (item: Selectable) => {
    switch (item.value) {
      case 'contains':
        return FilterComparison.contains;
      case 'notContains':
        return FilterComparison.notContains;
        case 'equal':
          return FilterComparison.equal;
          case 'notEqual':
            return FilterComparison.notEqual;
      case 'greaterThan':
        return FilterComparison.greaterThan;
      case 'lessThan':
        return FilterComparison.lessThan;
      case 'before':
        return FilterComparison.before;
      case 'after':
        return FilterComparison.after;
    }

    return FilterComparison.contains;
  };

  const removeFilterAt = (index: number) => {
    let f = filterGroup.Filters.filter((x, i) => i !== index);
    setFilterGroup({ ...filterGroup, Filters: f });
  };

  const getDataTypeName = (properties: IFilterProperty[]) => {
    let name: string[] = [];
    properties.forEach((x) => {
      name.push(getSearchableFieldName(x.Key));
    });

    return name.join(', ');
  };

  const buildFilter = (i: number, f: IFilter) => {
    return (
      <div className="filter-item" key={`afbf-k-${i}`}>
        {getDataTypeName(f.Properties)} {getSign(f?.ComparisonType)} "{f.Value}"{' '}
        <span
          className="click "
          onClick={() => {
            removeFilterAt(i);
          }}>
          <Icon type={IconType.trashO} />
        </span>
      </div>
    );
  };

  const addFilter = () => {
    let value = valueOptions ? form.valueOption?.label : form.value;
    if (form.comparison && value && (form.fields?.length ?? 0) > 0) {
      setFilterGroup({
        ...filterGroup,
        Filters: [
          ...(filterGroup.Filters ?? []),
          {
            Properties: getProperties(form.fields ?? []),
            ComparisonType: getComparisonType(form.comparison),
            Value: value,
            ValueOption: form.valueOption,
            ParentComparisonType: (form.parentComparison?.value ?? 'or') === 'or' ? 'or' : 'and',
          },
        ],
      });
      setForm({});
      setShowAddFilter(false);
    } else {
      toast.warn(`You are missing required fields`);
    }
  };

  return (
    <>
      <div className="advanced-filter form-horizontal">
        {true && (
          <div className="filter-list">
            <span>
              <Icon type={IconType.filter} addClass="" />
            </span>
            {filterGroup.Filters.map((x, i) => {
              if (i === 0) {
                return buildFilter(i, x);
              } else {
                return (
                    <React.Fragment key={`filter-compare-key-${i}`}>
                    {x.ParentComparisonType === 'and' ? <span className="filter-group-comp-type">AND</span> : <span className="filter-group-comp-type">OR</span>}
                    {buildFilter(i, x)}
                  </React.Fragment>
                );
              }
            })}

            <div>
                <button
                type="button"
                className="btn btn-xs btn-default"
                onClick={() => {
                    setShowAddFilter(true);
                }}>
                <Icon type={IconType.plus} /> Add Filter
                </button>
            </div>
          </div>
        )}
        {false && !showAddFilter && (
          <div>
            <button
              type="button"
              className="btn btn-xs btn-default"
              onClick={() => {
                setShowAddFilter(true);
              }}>
              <Icon type={IconType.plus} /> Add Filter
            </button>
          </div>
        )}

        {showAddFilter && (
          <Modal setModalOpen={setShowAddFilter} size="m" title="Add filter">
            <div className="form-horizontal">
              {filterGroup.Filters.length > 0 && (
                <div className="form-group">
                  <label className="control-label col-sm-4"></label>
                  <div className="col-sm-8">
                    <Select
                      onChange={(x: Selectable) => {
                        setForm({ ...form, parentComparison: x });
                      }}
                      value={form?.parentComparison}
                      options={getAndOrFields()}
                      placeholder={'compare rule'}
                      className="multi-select"
                      classNamePrefix="cool"
                      menuPlacement={'auto'}
                      isMulti={false}
                      isClearable={true}
                    />
                  </div>
                </div>
              )}
              <div className="form-group">
                <label className="control-label col-sm-4">Data to Compare</label>
                <div className="col-sm-8">
                  <Select
                    onChange={(x: Selectable[]) => {
                      setForm({ ...form, fields: x });
                    }}
                    value={form?.fields ?? []}
                    options={fields}
                    placeholder={'Fields to compare'}
                    className="multi-select"
                    classNamePrefix="cool"
                    menuPlacement={'auto'}
                    isMulti
                    isClearable={true}
                  />
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-sm-4">Comparison Type</label>
                <div className="col-sm-8">
                  <Select
                    onChange={(x: Selectable) => {
                      setForm({ ...form, comparison: x });
                    }}
                    value={form?.comparison}
                    options={comparisonTypes}
                    placeholder={'compare rule'}
                    className="multi-select"
                    classNamePrefix="cool"
                    menuPlacement={'auto'}
                    isMulti={false}
                    isClearable={true}
                  />
                </div>
              </div>
              {valueOptions && (
                <div className="form-group">
                  <label className="control-label col-sm-4">Value</label>
                  <div className="col-sm-8">
                    <Select
                      onChange={(x: Selectable) => {
                        setForm({ ...form, valueOption: x });
                      }}
                      value={form?.valueOption}
                      options={valueOptions}
                      placeholder={'value'}
                      className="multi-select"
                      classNamePrefix="cool"
                      menuPlacement={'auto'}
                      isMulti={false}
                      isClearable={true}
                    />
                  </div>
                </div>
              )}

              {!valueOptions && (
                <div className="form-group">
                  <label className="control-label col-sm-4">Value</label>
                  <div className="col-sm-8">
                    <input
                      type={form.type === dataType.date ? "date":"text"}
                      maxLength={250}
                      onChange={(x) => {
                        setForm({ ...form, value: x.target.value });
                      }}
                      value={form?.value ?? ''}
                      placeholder={'compare rule'}
                      className="form-control"
                    />
                  </div>
                </div>
              )}
              <div className="form-group">
                <div className="col-sm-8 col-sm-offset-4">
                  <button type="button" className="btn btn-secondary" onClick={addFilter}>
                    <Icon type={IconType.plus} /> Add Filter
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};
