import React, { useState, useEffect } from "react";
import { store } from "../../redux/oldStore";
import { toast } from "react-toastify";
import { setJudges } from "../../redux/judgingSetup/actions";
import {
  GuidToProjectDict,
  IntIdToProjectDict,
  IProject,
  ISettings,
  IUserSummary,
} from "./Dashboard";
import Icon, { IconType } from "../Icon/Icon";

interface ActiveList2Props {
  users: IUserSummary[];
  settings: ISettings | undefined;
  usersActive: string[];
  categories: ICategory[] | undefined;
  userConfrenceDic: any;
  projectDicByGuid: any;  
  handUserClick:Function;
}

interface ICategory {
  name: string;
  id: number;
}

const ActiveList2 = (props: ActiveList2Props) => {
  const { settings, users } = props;
  const params = new URLSearchParams(window.location.search);
  const fairId = params.get("f");
  const fairIdPara = fairId ? `f=${fairId}` : null;
  const [searchVal, setSearchVal] = useState("");
  const [activeUsers, setActiveUsers] = useState({});
  const [filteredUsers, setFilteredUsers] = useState<IUserSummary[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<number>(-99);
  const [conferenceDic, setConferenceDic] = useState<any>({});

  useEffect(() => {
    const dic = {};
    props.usersActive.forEach((x: string) => {
      dic[x] = true;
    });
    setActiveUsers(dic);
  }, [props.usersActive]);

  useEffect(() => {
    setConferenceDic(props.userConfrenceDic ?? {});
  }, [props.userConfrenceDic]);

  useEffect(() => {
    if (!searchVal && selectedCategory === -1) {
      return setFilteredUsers(
        users.filter((x) => x && activeUsers[x.PublicId])
      );
    } else if (!searchVal) {
      return setFilteredUsers(
        users.filter(
          (x) =>
            x && activeUsers[x.PublicId] && (true || selectedCategory === -1)
        )
      );
    } else {
      var search = searchVal.toLowerCase();
      // project id or title, may need the projectIdDisplay on the Project for searching purposes
      let filtered = users.filter(
        (x) =>
          x &&
          activeUsers[x.PublicId] &&
          //&& (x.CategoryId === selectedCategory || selectedCategory === -1)
          (x.FirstName.toLowerCase().includes(search) ||
            x.LastName.toLowerCase().includes(search) || 
            entryId(x).includes(search))

      );
      setFilteredUsers(filtered);
    }
  }, [users, searchVal, selectedCategory, activeUsers]);

  useEffect(() => {}, [activeUsers]);

  const entryId = (x:IUserSummary)=>{
    let ids = x.ProjectKeys?.toLocaleLowerCase().split(',') ?? [];
    let entryIds:string[] = [];
    ids.forEach(i=>{
      console.log(i)
      let id = props.projectDicByGuid[i]?.ProjectId;
      if(id)entryIds.push(id);
    });
    return entryIds.join('').toLowerCase();
  }

  const UserIcon = (x: IUserSummary) => {
    if (x.IsStudent) return <i title="Student" className="fal fa-child"></i>;
    if (x.IsJudge) return <i title="Judge" className="fal fa-gavel"></i>;
    if (x.IsStaff)
      return <i title="Staff Member" className="fal fa-user-crown"></i>;
    if (x.IsModerator)
      return <i title="Moderator" className="fal fa-balance-scale-right"></i>;
    if (x.IsInterpreter)
      return <i title="Interpreter" className="fal fa-language"></i>;
    if (x.IsSpecialAwardJudge)
      return <i title="Special Awards Judge" className="fa fa-award"></i>;

    return <></>;
  };

  const handelClick = (user:IUserSummary)=>{
    if(user.IsStudent){
      props.handUserClick(true, user.ProjectKeys?.split(',')[0]?.toLowerCase() ?? user.PublicId.toLowerCase());
    } else {
      props.handUserClick(false, user.PublicId.toLowerCase());
    }
  };

  const mapActiveUsers = filteredUsers.map((x: IUserSummary, i: number) => {
    let meeting = conferenceDic[x.PublicId];
    let ids = x.ProjectKeys?.toLocaleLowerCase().split(',') ?? [];
    let entryIds:string[] = [];
    ids.forEach(i=>{
      let id = props.projectDicByGuid[i]?.ProjectId;
      if(id)entryIds.push(id);
    })
    return (
      <tr key={`user-sumary-${i}`} className="user">
        <td className="al2-icon">{UserIcon(x)}
              {<span className="click" onClick={()=>{handelClick(x)}}>
                <Icon type={IconType.info}/>
                </span>}</td>
        <td className="al2-name">
          {x.FirstName} {x.LastName}
        </td>
        <td className="al2-username">{x.Username}</td>
        <td className="al2-entry">{entryIds.join(', ')}</td>
        <td className="al2-links">
          {meeting && (
            <>
              {
                <a
                  href={`${window.location.origin}/app/video/${meeting}?${fairIdPara}`}
                  className="link"
                  target="_blank"
                  title="Join Interview"
                >
                  <i className="fal fa-tv-retro"></i>
                </a>
              }
              {
                <span
                  className={`click fal fa-copy text-muted`}
                  onClick={() => {
                    window.navigator.clipboard.writeText(
                      `${window.location.origin}/app/video/${meeting}?${fairIdPara}`
                    );
                  }}
                  title="copy meeting link"
                ></span>
              }
            </>
          )}
        </td>
      </tr>
    );
  });

  return (
    <>
      <div>
        <div className="alert alert-info">
          Here is a list of all of the active users. An active user is a user
          with their browser open to one of the following pages.
          <ul>
            <li>
              <strong>Judges</strong> - Online Judging
            </li>
            <li>
              <strong>Students</strong> - Virtual Room
            </li>
            <li>
              <strong>All Others</strong> - Interview Room
            </li>
          </ul>
        </div>
      </div>
      <div className="flex-between">
        <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
        <div className="input-group grow">
          <input
            onChange={(e) => setSearchVal(e.target.value)}
            value={searchVal}
            className="form-control "
            placeholder="search by name"
          />
          <span className="input-group-addon">
            <i className="fa fa-search"></i>
          </span>
        </div>
      </div>
      <div className="user-list">
        <table className="table table-striped">
          <thead>
            <tr>
              <th className="al2-icon"></th>
              <th className="al2-name">Name</th>
              <th className="al2-username">Username</th>
              <th className="al2-entry">Entry Id</th>
              <th className="al2-links">Link to meeting</th>
            </tr>
          </thead>
          <tbody>{mapActiveUsers}</tbody>
        </table>
      </div>
    </>
  );
};

export default ActiveList2;
