import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { store } from "../../redux/oldStore";
import Project from "./project";

import { ProjectDetails2 } from "./projectDetails2";
import FileDownload from "js-file-download";
import Modal from "../Modal/Modal";
import UserLogin from "../UserLogin/UserLogin";
import { Selectable } from "../../utils/CommonTypes/CommonTypes";
import Creatable from "react-select/creatable";
import {
  ICustomQuestions,
  IProject,
  IShowcaseSettings,
  IAward,
  ITag,
} from "./ShowcaseTypes";
import Icon, { IconType } from "../Icon/Icon";

export default function Showcase() {
  const [ready, setReady] = useState<boolean>(false);
  const [wholeResponse, setWholeResponse] = useState<any>({});
  const [projects, setProjects] = useState<IProject[]>([]);
  const [projectsCustomQuestions, setProjectsCustomQuestions] = useState<
    ICustomQuestions[]
  >([]);
  const [openGate, setOpenGate] = useState<boolean>(false);
  const [lockedRequiresTag, setLockedRequiresTag] = useState<boolean>(false);
  const [gateErrorMessage, setGateErrorMessage] = useState<string | null>(null);
  const [codeInputValue, setCodeInputValue] = useState<string>("");
  const [categories, setCategories] = useState<{ id: number; name: string }[]>(
    []
  );
  const [settings, setSettings] = useState<IShowcaseSettings>();
  const [visibleProjects, setVisibleProjects] = useState<IProject[]>([]);
  const [selectedProject, setSelectedProject] = useState<IProject | null>(null);
  const [personTags, setPersonTags] = useState<ITag[]>([]);
  const [projectTags, setProjectTags] = useState<ITag[]>([]);
  const [myTags, setMyTags] = useState<number[]>([]); //straight data from the ShowcaseInfo call
  const [filterByTagChoices, setFilterByTagChoices] = useState<ITag[]>([]); //These are the tags that overlap between projectTags and myTags. Used to create the map for selectedTag
  const [filters, setFilters] = useState<Selectable[]>([]);
  const [search, setSearch] = useState<any>({ filters: [], text: "", tag: "" });
  const [allMyTaggedProjects, setAllMyTaggedProjects] = useState<IProject[]>(
    []
  );
  const [myTaggedProjectsModalOpen, setMyTaggedProjectsModalOpen] =
    useState<boolean>(false);
  const [projectLikesDic, setProjectLikesDic] = useState<any>({});
  const [myLikesDic, setMyLikesDic] = useState<any>({});
  const [categoryAwardsArr, setCategoryAwardsArr] = useState<IAward[]>([]);
  const [categoryAwardsDict, setCategoryAwardsDict] = useState<{
    [key: number]: IAward;
  }>([]);
  const [specialAwardsArr, setSpecialAwardsArr] = useState<IAward[]>([]);
  const [specialAwardsDict, setSpecialAwardsDict] = useState<{
    [key: number]: IAward;
  }>([]);
  const [showProject, setShowProject] = useState<boolean>(false);

  let gateCode: any = null;

  useEffect(() => {
    LoadProjects();
  }, []);

  useEffect(() => {
    if (myTags.length > 0 && projectTags.length > 0) {
      let tagsIveUsed = [...new Set(myTags)];
      setFilterByTagChoices(
        projectTags?.filter((tag) => true)
      );
    }
  }, [myTags, projectTags]);

  useEffect(() => {
    let newDict = categoryAwardsArr.reduce((dict, cat) => {
      return { ...dict, [cat.Id]: cat };
    }, {});
    setCategoryAwardsDict(newDict);
  }, [categoryAwardsArr]);

  useEffect(() => {
    let newDict = specialAwardsArr.reduce((dict, cat) => {
      return { ...dict, [cat.Id]: cat };
    }, {});
    setSpecialAwardsDict(newDict);
  }, [specialAwardsArr]);

  const LoadProjects = () => {
    store.server
      .postApi("../showcase/ShowcaseInfo", { code: gateCode })
      .then((x: any) => {
        setReady(true);
        if (x.Success) {
          if (x.Value.needsAccessCode) {
            setOpenGate(true);
          } else if (x.Value.needsToLogin) {
            if (window.location.host === "localhost:3001") {
              console.log("You need to redirect...", x.Value.url);
            } else {
              window.location = x.Value.url;
            }
            return;
          } else if (x.Value.ShowcaseOnlyAllowUsesTagged) {
            setLockedRequiresTag(true);
            return;
          } else {
            setWholeResponse(x.Value);
            setProjectLikesDic(x.Value.likes);
            let myLikes = {};
            if (x.Value.myLikes) {
              x.Value.myLikes.forEach((x) => {
                myLikes[x] = "true";
              });
            }
            setMyLikesDic(myLikes);

            setCategories(x.Value.categories);
            x.Value.projects.forEach((p: IProject) => {
              let owners: any[] = [];
              p.owners.forEach((x: any) => {
                owners.push(`${x.firstName} ${x.lastName}, ${x.firstName}`);
              });
              p.searchString = `${p.title}|${owners.join("|")}|${
                p.projectId
              }`.toLowerCase();
            });
            setProjectsCustomQuestions(x.Value.projectCustomQuestions);
            setProjects(x.Value.projects);
            setVisibleProjects(x.Value.projects);
            setSettings(x.Value.settings);
            setMyTags(x.Value.myTags);
            if (x.Value.showTags) {
              LoadTags();
            }
            if (x.Value.awardDetails) {
              setCategoryAwardsArr(x.Value.awardDetails.categoryAwards);
              setSpecialAwardsArr(x.Value.awardDetails.specialAwards);
            }

            //build filters
            let filts: Selectable[] = [];
            if (x.Value.divisions && x.Value.divisions.length > 0) {
              let d: Selectable = {
                label: "Divisions",
                value: "-1",
                options: [],
              };
              x.Value.divisions.forEach((y) => {
                d.options?.push({ label: y.Name, value: `Div|${y.Id}` });
              });
              filts.push(d);
            }
            if (x.Value.entryTypes && x.Value.entryTypes.length > 0) {
              let d: Selectable = {
                label: "Entry Type",
                value: "-1",
                options: [],
              };
              x.Value.entryTypes.forEach((y) => {
                d.options?.push({ label: y.Name, value: `Ent|${y.Id}` });
              });
              filts.push(d);
            }
            if (x.Value.categories && x.Value.categories.length > 0) {
              let d: Selectable = {
                label: "Categories",
                value: "-1",
                options: [],
              };
              x.Value.categories.forEach((y) => {
                d.options?.push({ label: y.name, value: `Cat|${y.id}` });
              });
              filts.push(d);
            }
            setFilters(filts);
          }
        }
      });
  };

  const LoadTags = () => {
    store.server
      .getApi("../Tag/Info")
      .then((res: any) => {
        setPersonTags(res.Value.personTags);
        setProjectTags(res.Value.projectTags);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err);
      });
  };

  useEffect(()=>{
    

    let filts = filters;
    if (projectTags && projectTags.length > 0) {
      let d: Selectable = {
        label: "Tags",
        value: "-1",
        options: [],
      };
      projectTags.forEach((y) => {
        d.options?.push({ label: y.Value, value: `Tag|${y.Id}` });
      });
      filts.push(d);
    }
    setFilters([...filts]);

  }, [projectTags]);

  const UnlockGate = () => {
    let code = codeInputValue;

    if (code.length === 0) {
      setGateErrorMessage("You must enter the key to unlock.");
      console.log("key missing...");
      return;
    }

    store.server.postApi("../showcase/EnterCode", { code }).then((x: any) => {
      if (x.Success) {
        if (x.Value) {
          gateCode = code;
          setOpenGate(false);
          LoadProjects();
        } else {
          setGateErrorMessage("That key did not work. Please try again.");
          setCodeInputValue("");
        }
      }
    });
  };

  useEffect(() => {
    setAllMyTaggedProjects(projects.filter((proj) => !!proj.myTags));
  }, [projects]);

  useEffect(() => {
    let projCopy = [...projects];
    let has: any = {
      text: [],
      textSearch:false,
      category: false,
      categoryHash: {},
      division: false,
      divisionHash: {},
      entryType: false,
      entryTypeHash: {},
      tagSearch: false,
      tagHash: {},
      tag: +search.tag,
    };

    search.filters?.forEach((x) => {
      let v = x.value + "";
      if (v.indexOf("Cat|") === 0) {
        has.category = true;
        has.categoryHash[v.substring(4)] = true;
      } else if (v.indexOf("Ent|") === 0) {
        has.entryType = true;
        has.entryTypeHash[v.substring(4)] = true;
      } else if (v.indexOf("Div|") === 0) {
        has.division = true;
        has.divisionHash[v.substring(4)] = true;
      } else if (v.indexOf("Tag|") === 0) {
        has.tagSearch = true;
        has.tagHash[v.substring(4)] = true;
      } else {
        if(v && v.length > 0){
          has.textSearch = true;
          has.text.push(v.toLowerCase());
        }
      }
    });

    projCopy = projCopy.filter(
      (x) =>
        (!has.category || has.categoryHash[x.CategoryId]) &&
        (!has.division || has.divisionHash[x.DivisionId]) &&
        (!has.entryType || has.entryTypeHash[x.EntryTypeId]) &&
        (!has.tagSearch || x.myTags?.find(t=>has.tagHash[t+''])) &&
        (!has.tag || x.myTags?.find(t=>t === has.tag)) &&
        (!has.textSearch || has.text.find(t=>x.searchString.includes(t)))
    );
    // if (searchText) projCopy = projCopy.filter(proj => proj.searchString.includes(searchText.trim().toLowerCase()));
    // if (searchCategory) projCopy = projCopy.filter(proj => proj.category === searchCategory);
    // if (selectedTag) projCopy = projCopy.filter(proj => proj.myTags?.includes(+selectedTag));

    setVisibleProjects(projCopy);
    console.log("search updated");
  }, [projects, search]);

  const openProjectDetails = (project) => {
    setSelectedProject(project);
    setShowProject(true);
  };

  const handleTagChange = (project, selectedTag, removed) => {
    // I thought i was being fancy using this for both add and remove, but maybe it would be better in two separate functions.
    // selectedTag can have the {label, value} type or a much bigger, from the api type {Id, FairId, Value, etc.}
    // label = Value and value = Id, if that makes sense.
    let newProj;
    let formattedTag = { ...selectedTag };
    if (!formattedTag.Id) formattedTag.Id = formattedTag.value;
    if (!removed) {
      if (project.myTags) {
        newProj = { ...project, myTags: [...project.myTags, formattedTag.Id] };
      } else {
        newProj = { ...project, myTags: [formattedTag.Id] };
      }
    } else {
      newProj = {
        ...project,
        myTags: project.myTags.filter((num) => num !== formattedTag.Id),
      };
    }
    let updatedProjects = projects.map((proj) =>
      proj.publicId === newProj.publicId ? newProj : proj
    );
    let updatedVisibleProjects = visibleProjects.map((proj) =>
      proj.publicId === newProj.publicId ? newProj : proj
    );

    setSelectedProject(newProj);
    setProjects(updatedProjects);
    setVisibleProjects(updatedVisibleProjects);
  };

  const mappedFilterTags = filterByTagChoices.map((tag, i) => {
    return (
      <option key={`${tag.Id}-${i}`} value={tag.Id}>
        {tag.Value}
      </option>
    );
  });

  const likeProject = (projectId: number, like: boolean) => {
    const request = { projectId, like };
    store.server.postApi("../showcase/LikeProject", request).then((x: any) => {
      if (x.Success) {
        let dic = projectLikesDic;
        let cv = dic[projectId] || 0;
        if (like) cv++;
        else cv--;

        dic[projectId] = cv;
        setProjectLikesDic(dic);

        let myLikes = myLikesDic;
        myLikes[projectId] = like;
        setMyLikesDic(myLikes);
      }
    });
  };

  const handleExportCsvClick = () => {
    const items = projects
      .filter((proj) => !!proj.myTags)
      .map((p) => {
        return {
          ProjectId: p.projectId,
          Title: p.title,
          Category: p.category,
          Tags: projectTags
            .filter((tag) => p.myTags.includes(tag.Id))
            .map((t) => t.Value)
            .join(", "),
        };
      });

    console.log(items);
    const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
    const header = Object.keys(items[0]);
    const csv = [
      header.join(","), // header row first
      ...items.map((row) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(",")
      ),
    ].join("\r\n");

    // console.log(csv)
    // downloady stuff here
    FileDownload(csv, "my_tagged_projects.csv");
  };

  const filterChanged = (values: Selectable[]) => {
    setSearch({ ...search, filters: values });
  };

  return (
    <>
    <div className="page-header flex-between">
      <div>Showcase</div>
      <div>
      <button
                      className="btn btn-default btn-xs"
                      title="list all tagged projects"
                      onClick={() => setMyTaggedProjectsModalOpen(true)}
                    >
                      <i className="fas fa-list"></i>
                    </button>
      </div>
    </div>
      <div className="showcase">
        <div className="">
          {projects && projects.length > 0 && (
            <div className="sticky">
              <div className="flex-between middle">
                <div className="form-input">
                  <label>Search</label>
                  <div >
                  {filters && filters.length > 0 && (
                    <>
                      <Creatable
                        onChange={(x)=>{
                          //filterChanged(x)
                          alert("filter changed")
                          console.log(x) ;
                        
                        }}
                        value={search.filters ?? []}
                        options={filters}
                        placeholder={"search filters"}
                        className="input-selectable"
                        isMulti
                        isClearable={true}
                        menuPlacement={'auto'}
                      />
                    </>
                  )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {!openGate && !lockedRequiresTag && visibleProjects.length > 0 && (
          <div>
            <div className="showcase-project-container">
              {settings &&
                visibleProjects.map((x, i) => {
                  return (
                    <Project
                      key={i}
                      project={x}
                      openProject={openProjectDetails}
                      settings={settings}
                      tags={projectTags}
                      categoryAwardsDict={categoryAwardsDict}
                      specialAwardsDict={specialAwardsDict}
                      likes={projectLikesDic[x.projectIntId] || 0}
                      myLikes={myLikesDic}
                      likeProject={likeProject}
                    />
                  );
                })}
            </div>
          </div>
        )}
        {!openGate &&
          !lockedRequiresTag &&
          visibleProjects.length === 0 &&
          ready && (
            <div>
              <div className="showcase-gate">
                <div className="gate">
                  <h2>No Projects Found</h2>
                  Sorry at this time there are not projects to share
                </div>
              </div>
            </div>
          )}
      </div>
      {openGate && (
        <div className="showcase-gate">
          <div className="gate">
            <UserLogin inModal={false}></UserLogin>
          </div>
          <div className="gate">
            <h2>Enter Key</h2>
            {gateErrorMessage && (
              <div className="error">{gateErrorMessage}</div>
            )}
            <div className="form-input">
              <label>Key</label>
              <input
                type="password"
                maxLength={50}
                onChange={(e) => setCodeInputValue(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    UnlockGate();
                  }
                }}
                value={codeInputValue}
                placeholder="showcase key"
              />
            </div>
            <div className="form-input">
              <div className="grow  text-right">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={UnlockGate}
                >
                  <i className="fal fa-unlock-alt"></i>&nbsp; Unlock
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {lockedRequiresTag && (
        <div className="showcase-gate">
          <div>
            <h3><Icon type={IconType.lock }/> Showcase Locked</h3>
            <hr />
            Sorry this showcase has been locked, only authorized users can
            access it.
          </div>
        </div>
      )}
      {showProject && settings && selectedProject && (
        <Modal
          setModalOpen={setShowProject}
          title={`${selectedProject.projectId} ${selectedProject.title}`}
          size="xl"
        >
          <ProjectDetails2
            project={selectedProject}
            // close={closeProjectDetails}
            settings={settings}
            availableProjectTags={projectTags}
            handleTagChange={handleTagChange}
            categoryAwardsDict={categoryAwardsDict}
            specialAwardsDict={specialAwardsDict}
            projectQuestions={projectsCustomQuestions} 
            likes={projectLikesDic[selectedProject.projectIntId] || 0}
            myLikes={myLikesDic}
            likeProject={likeProject}        
            />
        </Modal>
      )}
      {myTaggedProjectsModalOpen && (
        <Modal
          setModalOpen={setMyTaggedProjectsModalOpen}
          title="My Tagged Projects"
        >
          <div>
            <button className="btn btn-primary" onClick={handleExportCsvClick}>
              <i className="fal fa-download" /> Export to CSV
            </button>
            <table className="judge-table">
              <thead>
                <tr>
                  <th>Project Id</th>
                  <th>Title</th>
                  <th>Category</th>
                  <th>Tags</th>
                </tr>
              </thead>
              <tbody>
                {allMyTaggedProjects.map((project) => {
                  return (
                    <tr key={project.publicId}>
                      <td>{project.projectId}</td>
                      <td>{project.title}</td>
                      <td>{project.category}</td>
                      <td>
                        {project.myTags.map((num, i) => {
                          return (
                            <div key={`${num}-${i}`} className="bumper-out-l">
                              {projectTags
                                .filter((tag) => tag.Id === num)
                                .map((tag) => (
                                  <span
                                    key={tag.Value}
                                    className="nice-tag bumper-sides-l"
                                  >
                                    {tag.Value}
                                  </span>
                                ))}
                            </div>
                          );
                        })}
                        <div></div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Modal>
      )}
    </>
  );
}
