import React, { useState, useEffect } from "react";
import { store } from "../../redux/oldStore";
import { toast } from "react-toastify";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import ProjectList from "./ProjectList";
import JudgeList from "./JudgeList";
import RoundList from "./RoundList";
import io from "socket.io-client";
import SelectedRound from "./SelectedRound";
import Tools from "./Tools";
import audioPlayer from "../../utils/AudioPlayer";
import Modal from "../Modal/Modal";
import AutoPlopPanel from "./AutoPlopPanel";
import { VideoRoomInfo } from "../../utils/DocTogetherVideo";
import SupportChat from "../SupportChat/SupportChats";
import ActiveList from "./ActiveList";
import ActiveList2 from "./ActiveList2";
import Icon, { IconType } from "../Icon/Icon";
import { setAssignmentModalShow } from "../../redux/judgingSetup/actions";
import { ServerResponse } from "../../utils/Server";

export interface IProject {
  ProjectIntId: number;
  Id: string; //"706b7d34-73a6-47ab-b198-000a3557fc75"
  Title: string; //"The Boston Tea Party"
  Category: string; //"Websites"
  CategoryId: number;
  ProjectId: string; //"BO-292"
  NumberOfTeam: number; //1
  StudentNames: string | null; //null
  MeetingId: string; //
  ProjectLanguage: string;
  StudentLanguages: string;
}

export interface IJudge {
  PublicId: string; //"baf68d73-006f-427e-b1c1-7ee15c16b3da"
  FirstName: string; //"Judge"
  LastName: string; //"7"
  Email: string; //"trent@illuminatingsoftware.com"
  LeadJudge: boolean; //
  PersonId: number;
  Category1: number;
  Category2: number;
  Category3: number;
}

export interface IRound {
  RoundName: string; //"1 - A - Online Judging"
  Id: number; //1
  IsSubround: boolean; //
}

export interface ISettings {
  virtualMeetings: boolean;
  autoPlop: boolean;
  autoPlopUrl: string;
  acaDeca: boolean;
  onlineVideoShowCallInNumber: boolean;
  enableSupportChat: boolean;
  showTools: boolean;
}

export interface IIndividualPlop {
  Minutes: number; // = span.Minutes;
  Seconds: number; // = span.Seconds;
  PersonId: string; // = info.PersonId;
  ProjectId: string; // = info.ProjectId;
  Category: number; // = info.CategoryId;
  Category2: number; // = info.CategoryId;
  Category3: number; // = info.CategoryId;
  TimesJudged: number;
}

export interface IPlopInfo {
  judges: IIndividualPlop[];
  projects: IIndividualPlop[];
}

export interface IntIdToProjectDict {
  [ProjectIntId: number]: IProject;
}

export interface PersonIdToJudgeDict {
  [PersonId: number]: IJudge;
}

export interface GuidToProjectDict {
  [Id: string]: IProject;
}

export interface GuidToJudgeDict {
  [PublicId: string]: IJudge;
}
export interface GuidToUserDic {
  [PublicId: string]: IUserSummary;
}

interface Everything {
  projects: IProject[];
  judges: IJudge[];
  rounds: IRound[];
  settings: ISettings;
  fairId: string; //"9df61f13-474b-442f-ac1f-edca7348ef71"
  clientId: string; //guid
  categories: ICategory[];
  allUsers: IUserSummary[];
  user: IUser;
}

export interface IUserSummary {
  FirstName: string;
  LastName: string;
  Username: string;
  PersonId: number;
  PublicId: string;
  Phone: string | null;
  Email: string;
  IsDistrictCoordinator: boolean;
  IsInterpreter: boolean;
  IsJudge: boolean;
  IsSpecialAwardJudge: boolean;
  IsModerator: boolean;
  IsStaff: boolean;
  IsStudent: boolean;
  IsTeacher: boolean;
  IsVolunteer: boolean;
  PrimaryLanguage: string;
  ProjectKeys:string;
}

interface IUser {
  firstName: string;
  lastName: string;
  personId: string;
}

export interface ICategory {
  id: number;
  name: string;
}

const Dashboard = () => {
  const [ready, setReady] = useState(false);
  const [socket, setSocket] = useState<any>({});
  const [fairId, setFairId] = useState<string>();
  const [clientId, setClientId] = useState<string>();
  const [everything, setEverything] = useState<Everything>();
  const [projects, setProjects] = useState<IProject[]>([]);
  const [user, setUser] = useState<IUser>();
  const [projectsByIntId, setProjectsByIntId] = useState<IntIdToProjectDict>(
    {}
  );
  const [projectsByGuid, setProjectsByGuid] = useState<GuidToProjectDict>({});
  const [judges, setJudges] = useState<IJudge[]>([]);
  const [judgesByPersonId, setJudgesByPersonId] = useState<PersonIdToJudgeDict>(
    {}
  );
  const [judgesByGuid, setJudgesByGuid] = useState<GuidToJudgeDict>({});
  const [allUsersByGuid, setAllUsersByGuid] = useState<GuidToUserDic>({});
  const [rounds, setRounds] = useState<IRound[]>([]);
  const [settings, setSettings] = useState<ISettings>();
  const [socketProjectUpdate, setSocketProjectUpdate] = useState<string[]>([]);
  const [socketActiveUsers, setSocketActiveUsers] = useState<any>([]);
  const [socketJudgeInRoomList, setSocketJudgeInRoomList] = useState<any>({});
  const [videoRoomJudges, setvideoRoomJudges] = useState<any>({}); // roomId -- judgeId
  const [socketProjectStudentsUpdate, setSocketProjectStudentsUpdate] =
    useState<any>();
  const [personConferenceDic, setPersonConferenceDic] = useState<any>();
  const [socketJudgeUpdate, setSocketJudgeUpdate] = useState<string[]>([]);
  const [chosenRound, setChosenRound] = useState<IRound>();
  const [chosenProject, setChosenProject] = useState<IProject>();
  const [chosenProjectModalOpen, setChosenProjectModalOpen] = useState(false);
  const [chosenJudge, setChosenJudge] = useState<IJudge>();
  const [chosenJudgeModalOpen, setChosenJudgeModalOpen] = useState(false);
  // const [tabIndexOverride, setTabIndexOverride] = useState();

  const [plopInfo, setPlopInfo] = useState<IPlopInfo>();
  const [queueHandel, setQueueHandel] = useState<number>(0);

  const [projCategories, setProjCategories] = useState<ICategory[]>([]);
  const [categoriesById, setCategoriesById] = useState<{
    [id: number]: ICategory;
  }>({});
  const [callInNumber, setCallInNumber] = useState<string[]>([]);
  const [callInPin, setCallInPin] = useState("");

  const [showAssignInterpreter, setShowAssignInterpreter] = useState<boolean>();
  const [form, setForm] = useState<any>({});

  useEffect(() => {
    if (!fairId || !clientId) return;
    let url =
      window.location.host.indexOf("localhost") > -1
        ? "http://localhost:9191/online-judging"
        : "https://socket.zfairs.com/online-judging";
    const s = io(url, {
      query: {
        fairId: fairId,
        clientId: clientId,
      },
      transports: ["websocket", "polling"],
    });
    setSocket(s);

    s.emit("dashboard", { fairId });
    s.emit("dashboard-info-request");

    s.on(`dashboard-info`, (x) => {
      console.log("Socket Stuff dashboard-info", x);
      setSocketJudgeUpdate(x.data.judges);
      setSocketActiveUsers(x.data.users);

      const data = {};
      const projectList: any[] = [];
      x.data.entries.forEach((x: any, i: number) => {
        let values = x.split("|");

        if (!data[values[0]]) {
          projectList.push(values[0]);
          data[values[0]] = [];
        }
        data[values[0]].push(values[1]);
      });
      setSocketProjectUpdate(projectList);
      setSocketProjectStudentsUpdate(data);

      const personsConference = {};
      const usersIn = {};
      x.data.usersInConfs.forEach((x: any, i: number) => {
        let values = x.split("|");
        let who = values[0];
        if (who !== "undefined") {
          let key = values[1].replace(/\s/g, "");
          personsConference[values[0]] = key;

          key = key.substring(key.indexOf("-") + 1);
          console.log(key);
          usersIn[key] = [...(usersIn[key] ?? []), values[0]];
        }
      });

      setvideoRoomJudges(usersIn);

      setPersonConferenceDic(personsConference);
      //console.log(personsConference)

      window.setTimeout(() => {
        s.emit("dashboard-info-request");
      }, 6 * 1000);
    });

    s.on("dashboard-announcement", (data) => {
      switch (data.type) {
        case "bell":
          toast.info(data.message || "Ring, Ring, Ring...", {
            autoClose: 7000,
          });
          audioPlayer.sound.bell();
          break;
        case "message":
          toast.info(data.message, { autoClose: false });
          audioPlayer.sound.attention();
          break;
        case "reload":
          if (!data.id) {
            toast.info("Force Reload page called for judges and students");
          } else if (data.id === "projects") {
            toast.info("Force Reload page called for students");
          } else {
            const project = projectsByGuid[data.id];
            if (project) {
              toast.info(
                `Force Reload page called for entry: ${project.ProjectId}`
              );
            }
            const judge = judgesByGuid[data.id];
            if (judge) {
              toast.info(
                `Force Reload page called for judge: ${judge.FirstName} ${judge.LastName}`
              );
            }
          }
          break;
        case "force-logout":
          if (!data.id) {
            toast.info("Force logout was called for judges and students");
          } else if (data.id === "projects") {
            toast.info("Force logout was called for judges and students");
          } else {
            const project = projectsByGuid[data.id];
            if (project) {
              toast.info(
                `Force logout was called for entry: ${project.ProjectId}`
              );
            }
            const judge = judgesByGuid[data.id];
            if (judge) {
              toast.info(
                `Force logout was called for judge: ${judge.FirstName} ${judge.LastName}`
              );
            }
          }
          break;
        default:
          break;
      }
    });

    return function cleanup() {
      s.close();
      setSocket({});
    };
  }, [fairId, clientId]);

  useEffect(() => {
    //load info
    store.server
      .getApi<ServerResponse<Everything>>("../JudgingDashboard/Info")
      .then((x) => {
        if (x.Success) {
          setEverything(x.Value);
          setRounds(x.Value.rounds);
          setProjects(x.Value.projects);
          setJudges(x.Value.judges);
          setSettings(x.Value.settings);
          setFairId(x.Value.fairId);
          setClientId(x.Value.clientId);
          setProjCategories(x.Value.categories);
          setUser(x.Value.user);
          setReady(true);

          let dic: GuidToUserDic = {};
          x.Value.allUsers.forEach((x) => {
            dic[x.PublicId] = x;
          });
          setAllUsersByGuid(dic);
        } else {
          toast.error("Something went wrong, very wrong. " + x.Message);
        }
      });
  }, []);

  useEffect(() => {
    let projDict = projects?.reduce((dict: IntIdToProjectDict, project) => {
      dict[project.ProjectIntId] = project;
      return dict;
    }, {});

    let projGuidDict = projects?.reduce((dict: GuidToProjectDict, project) => {
      dict[project.Id] = project;
      return dict;
    }, {});

    setProjectsByIntId(projDict);
    setProjectsByGuid(projGuidDict);
  }, [projects]);

  useEffect(() => {
    let judgeDict = judges?.reduce((dict: PersonIdToJudgeDict, judge) => {
      dict[judge.PersonId] = judge;
      return dict;
    }, {});
    let judgeGuidDict = judges?.reduce((dict: GuidToJudgeDict, judge) => {
      dict[judge.PublicId] = judge;
      return dict;
    }, {});
    setJudgesByPersonId(judgeDict);
    setJudgesByGuid(judgeGuidDict);
  }, [judges]);

  useEffect(() => {
    let catDict = projCategories?.reduce(
      (dict: { [id: number]: ICategory }, x) => {
        dict[x.id] = x;
        return dict;
      },
      {}
    );
    setCategoriesById(catDict);
  }, [projCategories]);

  const sendAnnouncement = (data: any) => {
    if (socket) {
      socket.emit("dashboard-announcement", data);
    }
  };

  const handleUserClick = (isParticipant:boolean, id:string)=>{
    console.log(id, isParticipant)
    if(isParticipant){
      let project = projectsByGuid[id];
      handleProjectClick(null, project);
    } else {
      let judge = judgesByGuid[id];
      handleJudgeClick(null, judge);
    }

  }

  const handleProjectClick = (e, project: IProject) => {
    e?.stopPropagation();
    setChosenProject(project);
    setChosenProjectModalOpen(true);
  };

  const handleJudgeClick = (e, judge: IJudge) => {
    e?.stopPropagation();
    setChosenJudge(judge);
    setChosenJudgeModalOpen(true);
  };

  const handleModalClose = (bool: boolean) => {
    //need the param for the way the Modal component works
    setChosenProject(undefined);
    setChosenProjectModalOpen(false);
    setChosenJudge(undefined);
    setChosenJudgeModalOpen(false);
  };

  const loadAutoPlopQueue = () => {
    if (queueHandel > 0) {
      window.clearInterval(queueHandel);
      setQueueHandel(0);
    } else {
      let url = `${everything?.settings.autoPlopUrl}/Plop/Info?id=asldkfji23jklsanvjasnfsajdfklj3i2jijcnm&fairId=${everything?.fairId}`;

      const check = () => {
        store.server.getApiQuiet(url).then((x: any) => {
          if (x.Success) {
            setPlopInfo(x.Value.plopInfo);
          } else {
            toast.error("Error getting Auto Plop Queue: " + x.Message, {
              autoClose: false,
            });

            window.clearInterval(queueHandel);
            setQueueHandel(0);
          }
        });
      };

      let handel = window.setInterval(check, 1000);
      setQueueHandel(handel);
    }
  };
  const resetAutoPlopQueue = () => {
    if (!window.confirm(`Are you sure you want to reset the queue?`)) {
      return true;
    }
    let url = `${everything?.settings.autoPlopUrl}/Plop/Reset?id=asldkfji23jklsanvjasnfsajdfklj3i2jijcnm&fairId=${everything?.fairId}`;

    store.server.getApiQuiet(url).then((x: any) => {
      if (x.Success) {
        toast.warn("Auto Plop Queue Reset!");
      } else {
        toast.error("Error resetting Auto Plop Queue: " + x.Message, {
          autoClose: false,
        });

        window.clearInterval(queueHandel);
        setQueueHandel(0);
      }
    });
  };

  const handelForceLogout = (id: string) => {
    if (!window.confirm(`Are you sure you want to sign them out?`)) {
      return;
    }
    socket.emit("dashboard-announcement", { type: "force-logout", id: id });
  };
  const handelForceRefresh = (id: string) => {
    if (!window.confirm(`Are you sure you want to force a page reload?`)) {
      return;
    }

    socket.emit("dashboard-announcement", { type: "reload", id: id });
  };

  useEffect(() => {
    if (settings?.onlineVideoShowCallInNumber) {
      if (chosenProjectModalOpen) {
        if (chosenProject) {
          store.server
            ?.postApiWithServerResponse<VideoRoomInfo>(
              "../Hinge/GetVideoRoomInfo",
              { id: chosenProject.MeetingId }
            )
            .then((res) => {
              console.log(res.Value);
              setCallInNumber(res.Value.phoneNumber);
              setCallInPin(res.Value.conferencePin);
            });
        }
      }
    }
  }, [chosenProject, chosenProjectModalOpen]);

  const requestInterpreter = () => {
    //call server
    let projectNeedingIt = chosenProject;
    store.server
      .getApi<ServerResponse<boolean>>(
        `../WaitingRoom/RequestHelp?type=Interpreter&language=${
          projectNeedingIt?.ProjectLanguage ??
          projectNeedingIt?.StudentLanguages
        }&meetingId=${projectNeedingIt?.MeetingId}`
      )
      .then((x) => {
        if (x.Success) {
          toast.info(
            `An interpreter has been requesed for ${projectNeedingIt?.ProjectId}.`
          );
        } else {
          toast.error(x.Message, { autoClose: false });
        }
      });
  };

  const assignInterpreter = () => {
    //call server

    if (!form.interpreterId || form.interpreterId === "0") {
      toast.info("Select an interpreter to assign.");
      return;
    }

    store.server
      .postApi<ServerResponse<any>>(
        "../JudgingManagement/UpdateAdvanceJudgeAssignment",
        {
          judgeId: form.interpreterId,
          projectKey: chosenProject?.Id,
          remove: false,
        }
      )
      .then((x: any) => {
        if (x.Success) {
          setShowAssignInterpreter(false);
          setForm({});
          toast.info(`Interpreter assigned to ${chosenProject?.Id}`);
        } else {
          toast.error(x.Message, { autoClose: false });
        }
      })
      .catch((x) => {
        console.log(x);
        toast.error(`Error removing scheduled interview.`, {
          autoClose: false,
        });
      });
  };

  return (
    <>
      <div className="online-judging-dashboard">
        {ready && (
          <Tabs>
            <TabList>
              <Tab>Active Users</Tab>
              <Tab>Projects</Tab>
              <Tab>Judges</Tab>
              {settings?.autoPlop && <Tab>Auto Plop</Tab>}
              {settings?.showTools && <Tab>Tools</Tab>}
              {settings && settings.enableSupportChat && (
                <Tab>
                  <i className="fad fa-user-headset"></i> Support Chat
                </Tab>
              )}
              <Tab>Rounds</Tab>
              {chosenRound && <Tab>{chosenRound.RoundName}</Tab>}
            </TabList>
            <TabPanel>
              <ActiveList2
                categories={everything?.categories}
                settings={settings}
                users={everything?.allUsers || []}
                usersActive={socketActiveUsers}
                userConfrenceDic={personConferenceDic}
                projectDicByGuid={projectsByGuid}
                handUserClick={handleUserClick}
              />
            </TabPanel>
            <TabPanel>
              <ProjectList
                projects={projects}
                settings={settings}
                projectsActive={socketProjectUpdate}
                projectsByIntId={projectsByIntId}
                projectsByGuid={projectsByGuid}
                handleProjectClick={handleProjectClick}
                videoRoomJudges={videoRoomJudges}
                judges={allUsersByGuid}
                categories={projCategories}
              />
            </TabPanel>
            <TabPanel>
              <JudgeList
                judges={judges}
                settings={settings}
                judgesActive={socketJudgeUpdate}
                judgesByPersonId={judgesByPersonId}
                judgesByGuid={judgesByGuid}
                handleJudgeClick={handleJudgeClick}
                judgesInRoom={socketJudgeInRoomList}
                projects={projectsByGuid}
              />
            </TabPanel>
            {/* <TabPanel>
            <ActiveList categories={everything?.categories} settings={settings} users={everything?.allUsers || []} usersActive={socketActiveUsers}/>
          </TabPanel> */}
            {settings?.autoPlop && (
              <TabPanel>
                <AutoPlopPanel
                  loadAutoPlopQueue={loadAutoPlopQueue}
                  resetAutoPlopQueue={resetAutoPlopQueue}
                  queueHandel={queueHandel}
                  plopInfo={plopInfo}
                  settings={settings}
                  judgesByGuid={judgesByGuid}
                  projectsByGuid={projectsByGuid}
                  categoriesById={categoriesById}
                />
              </TabPanel>
            )}
            {settings?.showTools && (
              <TabPanel>
                <Tools settings={settings} socket={socket}></Tools>
              </TabPanel>
            )}
            {settings && settings.enableSupportChat && (
              <TabPanel>
                <SupportChat
                  clientId={clientId}
                  fairId={fairId}
                  projectId={null}
                  socket={socket}
                  personId={user?.personId || null}
                  name={`${user?.firstName} ${user?.lastName}`}
                  isSupportAgent={true}
                ></SupportChat>
              </TabPanel>
            )}
            <TabPanel>
              <RoundList
                rounds={rounds}
                settings={settings}
                setChosenRound={setChosenRound}
              />
            </TabPanel>
            {chosenRound && (
              <TabPanel>
                <SelectedRound
                  chosenRound={chosenRound}
                  settings={settings}
                  judgesByPersonId={judgesByPersonId}
                  projectsByIntId={projectsByIntId}
                  judgesActive={socketJudgeUpdate}
                  judgesByGuid={judgesByGuid}
                  projectsActive={socketProjectUpdate}
                  projectsByGuid={projectsByGuid}
                />
              </TabPanel>
            )}
          </Tabs>
        )}
        {chosenProject && chosenProjectModalOpen && (
          <Modal
            title={chosenProject.ProjectId}
            setModalOpen={handleModalClose}
            size="l"
          >
            <div>
              <div>
                Title: <strong>{chosenProject.Title}</strong>
              </div>
              <div>
                Category: <strong>{chosenProject.Category}</strong>
              </div>
              <div>
                Team Size: <strong>{chosenProject.NumberOfTeam}</strong>
              </div>
              {chosenProject.ProjectLanguage && (
                <div>
                  Primary Language:{" "}
                  <strong>{chosenProject.ProjectLanguage}</strong>
                </div>
              )}
              {!chosenProject.ProjectLanguage &&
                chosenProject.StudentLanguages && (
                  <div>
                    Primary Language:{" "}
                    <strong>{chosenProject.StudentLanguages}</strong>
                  </div>
                )}
              <div>
                Participants:
                <ul>
                  <strong>
                    {chosenProject.StudentNames?.split(",").map((x, i) => {
                      return (
                        <li className="" key={`stu-name-${i}`}>
                          {x}
                        </li>
                      );
                    })}
                  </strong>
                </ul>
              </div>
              {settings?.onlineVideoShowCallInNumber && (
                <>
                  <div>
                    Call-in Number: <strong>{callInNumber}</strong>
                  </div>
                  <div>
                    PIN: <strong>{callInPin}</strong>
                  </div>
                </>
              )}
              <div className="flex-between head-room-l">
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={() => {
                    handelForceLogout(chosenProject.Id);
                  }}
                >
                  <i className="fad fa-sync"></i>Force Logout
                </button>
                {(chosenProject.ProjectLanguage ||
                  chosenProject.StudentLanguages) && (
                  <div>
                    <button
                      type="button"
                      className="btn btn-default"
                      onClick={() => {
                        requestInterpreter();
                      }}
                    >
                      <Icon type={IconType.language} /> Request Interpreter
                    </button>
                    <button
                      type="button"
                      className="btn btn-default"
                      onClick={() => {
                        setShowAssignInterpreter(true);
                      }}
                    >
                      <Icon type={IconType.calendar} /> Assign Interpreter
                    </button>
                  </div>
                )}
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={() => {
                    handelForceRefresh(chosenProject.Id);
                  }}
                >
                  <i className="fad fa-sync"></i>Force Reload
                </button>
              </div>
            </div>
          </Modal>
        )}
        {chosenJudge && chosenJudgeModalOpen && (
          <Modal
            title={`${chosenJudge.FirstName} ${chosenJudge.LastName}`}
            setModalOpen={handleModalClose}
          >
            <div>Email: {chosenJudge.Email}</div>
            <div className="flex-between head-room-l">
              <button
                type="button"
                className="btn btn-default"
                onClick={() => {
                  handelForceLogout(chosenJudge.PublicId);
                }}
              >
                <i className="fad fa-sync"></i>Force Logout
              </button>
              <button
                type="button"
                className="btn btn-default"
                onClick={() => {
                  handelForceRefresh(chosenJudge.PublicId);
                }}
              >
                <i className="fad fa-sync"></i>Force Reload
              </button>
            </div>
          </Modal>
        )}

        {showAssignInterpreter && (
          <Modal
            setModalOpen={setAssignmentModalShow}
            title={"Assign Interpreter"}
            size="m"
          >
            <div className="form-horizontal">
              <h4>Assign Interpreter for {chosenProject?.ProjectId}</h4>
              <div>
                Language:{" "}
                <strong>
                  {chosenProject?.StudentLanguages ??
                    chosenProject?.ProjectLanguage}
                </strong>
              </div>
              <div>{chosenProject?.Title}</div>
              <hr />
              <div className="form-group">
                <label
                  htmlFor="assign-interpreter-interpreter"
                  className="col-sm-4 control-label"
                >
                  Interpreter
                </label>
                <div className="col-sm-8">
                  <select
                    className="form-control"
                    id="assign-interpreter-interpreter"
                    value={form.interpreterId ?? "0"}
                    onChange={(e) => {
                      setForm({ ...form, interpreterId: e.target.value });
                    }}
                  >
                    <option value={"0"}>select</option>
                    {everything?.allUsers
                      .filter((x) => x.IsInterpreter)
                      .map((x, i) => {
                        return (
                          <option key={`int-${i}`} value={x.PublicId}>
                            {x.LastName}, {x.FirstName}{" "}
                            {x.PrimaryLanguage && <>({x.PrimaryLanguage})</>}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div className="form-group">
                <div className="col-sm-offset-4 col-sm-8">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {
                      assignInterpreter();
                    }}
                  >
                    <Icon type={IconType.save} /> Assign
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};

export default Dashboard;
