import { fabric } from "fabric"
import { v4 as uuidv4 } from "uuid";
import EventEmitter from "wolfy87-eventemitter";
import { InputType } from ".";
import { calculateHeightFromPercent, calculateLeftFromPercent, calculateTopFromPercent, calculateWidthFromPercent } from "./PositionCalculation";


export type makeMethod = (x: number, //Horizontal starting point
    y: number, //Vertical starting point
    options: fabric.IObjectOptions,
    x2?: number, //Horizontal ending point
    y2?: number) //Vertical ending point
    => Promise<fabric.Object>

export interface IObjectDrawer {
    drawingMode: DrawingMode;
    //Makes the current object
    readonly make: makeMethod;
    //Resizes the object (used during the mouseOver event below)
    readonly resize: (object: fabric.Object, x: number, y: number)
        => Promise<fabric.Object>;
}



export enum DrawingMode {
    Line,
    Rectangle,
    Oval,
    // Text,
    // Polyline,
    // Path
}

export enum CursorMode {
    Draw,
    Select
}

const ObjectUpdated = "objectUpdated";
const ObjectCreated = "objectCreated";
const ObjectRemoved = "objectRemoved";
export class DrawingEditor extends EventEmitter {
    canvas: fabric.Canvas;

    private drawer: IObjectDrawer; //Current drawer
    private readonly drawers: Record<DrawingMode, IObjectDrawer>; //All possible drawers
    private object?: fabric.Object; //The object currently being drawn
    private isDown: boolean;
    /**
     * Input type of drawing editor.  All fabric objects created here will have this input type when they are created.
     */
    public inputType: InputType = "Text"; // on clicking "add image" or "add text", switch the drawingEditor.inputType to that type
    /**
     * Determines whether preFill mode is enabled.  If preFill mode is enabled, the items that are drawn will be marked as
     * preFill fields which will be required to be filled in before the sign request is sent out.
     */
    public isPreFillMode: boolean = true;
    cursorMode: CursorMode;
    drawingMode: DrawingMode;
    private enabled: boolean = false;
    /**
     * There are the objects created by the drawing editor.
     */
    private objects: fabric.Object[] = [];

    public name: string = '';

    constructor(canvas: fabric.Canvas, drawingMode: DrawingMode) {
        super();

        this.drawingMode = drawingMode

        //Create the Fabric canvas
        this.canvas = canvas;

        this.cursorMode = CursorMode.Draw;

        //Create a collection of all possible "drawer" classes
        this.drawers = [
            new LineDrawer(),
            new RectangleDrawer(),
            new OvalDrawer(),

        ];


        //Set the current "drawer" class
        this.drawer = this.drawers[this.drawingMode];

        //Set the default options for the "drawer" class, including 
        //stroke color, width, and style

        this.isDown = false;
        this.initializeCanvasEvents();

    }

    private getDrawerOptions = (): fabric.IObjectOptions => {
        return this.getDrawerOptionsFromInput({ inputType: this.inputType })
    }

    private getDrawerOptionsFromInput = (options: { inputType: InputType }): fabric.IObjectOptions => {
        return {
            stroke: 'black',
            strokeWidth: 1,
            selectable: true,
            strokeUniform: true,
            originX: "left",
            originY: "top",
            fill: options.inputType === "Text" ? "azure" : '#dddddd'
        }
    }

    public setOptions(options: { enabled: boolean, type?: InputType }) {
        this.enabled = options.enabled
        if (options.type) this.inputType = options.type
    }

    public setEnabled(enabled: boolean) {
        // if (enabled && this.enabled) {
        //     this.initializeCanvasEvents();
        // } else {
        //     // this.clearCanvasEvents()
        // }
        this.enabled = enabled;
    }

    public getEnabled() {
        return this.enabled;
    }

    private mouseDownHandle = (o: fabric.IEvent) => {
        const e = o.e as MouseEvent;

        const pointer = this.canvas.getPointer(e);
        this.mouseDown(pointer.x, pointer.y);
    }

    private mouseMoveHandle = (o: fabric.IEvent) => {
        const pointer = this.canvas.getPointer(o.e);
        this.mouseMove(pointer.x, pointer.y);
    }

    private mouseUpHandle = (o: fabric.IEvent) => {
        this.isDown = false;
        if (!this.object?.height || this.objects.find(x => x._id === o.target?._id)) {
            // let myIndex = -1
            // const matchingObject = this.objects.find((x, index) => {
            //     myIndex = index;
            //     return x._id === o.target?._id
            // });
            // this.objects.splice(myIndex, 1);
            //todo:  removing the object was removing it even on mouse up for existing objects.
            // if (this.object){
            //     this.canvas.remove(this.object) //if this was not a valid object that was created, remove it.  This happens when you just click and create a thing that has no dimensions.
            // }
        } else {
            this.objects.push(this.object);
            console.log("object created", this.objects);
            this.emit(ObjectCreated, this.object);
        }
        // if (o.target) {
        //     if (!o.target.height) {
        //         const index = -1
        //         const matchingObject = this.objects.find((x, index) => {

        //             return x._id === o.target?._id
        //         })
        //     }
        //     //emit the create
        // }
    }

    private objectSelectedHandle = (o: fabric.IEvent) => {
        this.cursorMode = CursorMode.Select;
        //sets currently selected object
        this.object = o.target;
    }

    private selectionClearedHandle = (o: fabric.IEvent) => {
        this.cursorMode = CursorMode.Draw;
    }

    private objectModifiedHandle = (e: fabric.IEvent) => {
        const matchingObject = this.objects.find(x => x._id === e.target?._id)
        if (matchingObject) {
            this.emit(ObjectUpdated, e.target)
        }
    }

    private objectRemovedHandle = (e: fabric.IEvent) => {
        let foundIndex = -1;
        const matchingObject = this.objects.find((x, index) => {
            const found = x?._id === e.target?._id;
            foundIndex = index;
            return found;
        })
        if (matchingObject) {
            // console.log("Removing object", foundIndex);
            this.objects.splice(foundIndex, 1);
            this.emit(ObjectRemoved, e.target);
        }

        //we need to go through all the objects and remove the deleted ones.
    }

    private onKeyDownListener = (e: KeyboardEvent) => {
        // e.preventDefault();
        // e.stopPropagation();
        switch (e.key) {
            case "Delete":
            case "Backspace":
                const activeObjects = this.canvas.getActiveObjects();
                const selectedObjectIds = activeObjects.filter(x => x._id).map(x => x?._id);
                console.log("Delete thing selectedObjectIds: myObjects:", selectedObjectIds, this.objects)
                const objectsTodelete = this.objects.filter(x => selectedObjectIds.indexOf(x._id) > -1);
                this.deleteObjects(objectsTodelete)
        }
    }

    public deleteObjects = (objectsToDelete: fabric.Object[]) => {
        objectsToDelete.forEach(x => {
            if (!(x as fabric.IText).isEditing) { //don't delete a text object if we are editing the text so we don't delete the object when the user only meant to characters.

                this.canvas.remove(x);
            }
        });
    }

    private initializeCanvasEvents() {
        this.canvas.on('mouse:down', this.mouseDownHandle);
        this.canvas.on('mouse:move', this.mouseMoveHandle);
        this.canvas.on('mouse:up', this.mouseUpHandle);
        this.canvas.on('object:selected', this.objectSelectedHandle);
        this.canvas.on('selection:cleared', this.selectionClearedHandle);
        this.canvas.on("object:modified", this.objectModifiedHandle);
        this.canvas.on("object:removed", this.objectRemovedHandle);
        document.body.addEventListener("keydown", this.onKeyDownListener);

    }

    private clearCanvasEvents() {
        this.canvas.off('mouse:move', this.mouseMoveHandle);
        this.canvas.off('mouse:up', this.mouseUpHandle);
        this.canvas.off('mouse:down', this.mouseDownHandle);
        this.canvas.off('object:selected', this.objectSelectedHandle);
        this.canvas.off('selection:cleared', this.selectionClearedHandle);
        this.canvas.off("object:modified", this.objectModifiedHandle);
        this.canvas.off("object:removed", this.objectRemovedHandle);
        document.body.removeEventListener("keydown", this.onKeyDownListener);
    }


    private async mouseDown(x: number, y: number): Promise<any> {
        this.isDown = true; //The mouse is being clicked

        if (!this.enabled) {
            return;
        }

        if (this.cursorMode !== CursorMode.Draw) {
            return;
        }

        //Create an object at the point (x,y)
        this.object = await this.make(x, y);

        //Add the object to the canvas
        this.canvas.add(this.object);

        //Renders all objects to the canvas
        this.canvas.renderAll();
    }

    public addObjectCreatedListener(callback: (object: fabric.Object) => void) {
        this.addListener(ObjectCreated, callback);
    }

    public removeObjectCreatedListener(callback: (object: fabric.Object) => void) {
        this.removeListener(ObjectCreated, callback);
    }

    public removeAllObjectCreatedListeners() {
        this.removeEvent(ObjectCreated);
    }

    public addObjectUpdatedListener(callback: (object: fabric.Object) => void) {
        console.log("Added object updated listener.")
        this.addListener(ObjectUpdated, callback);
    }

    public removeObjectUpdatedListener(callback: (object: fabric.Object) => void) {
        this.removeListener(ObjectUpdated, callback);
    }

    public removeAllObjectUpdatedListeners() {
        this.removeEvent(ObjectUpdated);
    }

    public addObjectRemovedListener(callback: (object: fabric.Object) => void) {
        this.addListener(ObjectRemoved, callback);
    }

    public removeObjectRemovedListener(callback: (object: fabric.Object) => void) {
        this.removeListener(ObjectRemoved, callback);
    }

    public removeAllObjectRemvedListeners() {
        this.removeEvent(ObjectRemoved);
    }

    private mouseMove(x: number, y: number): any {
        if (!this.enabled) {
            return;
        }
        if (!(this.cursorMode.valueOf() === CursorMode.Draw.valueOf()
            && this.isDown)) {
            return;
        }

        //Use the Resize method from the IObjectDrawer interface
        if (!this.object) {
            return;
        }
        this.drawer.resize(this.object, x, y);
        this.canvas.renderAll();
    }

    private async make(x: number, y: number): Promise<fabric.Object> {
        const object = await this.drawer.make(x, y, this.getDrawerOptions());
        object._id = uuidv4();
        object.inputType = this.inputType;
        object.isPreFill = this.isPreFillMode;
        object.on("mousedown", (e) => {
            if (e.button === 3) {
                object.name = prompt("Enter field Name", object.inputType) ?? undefined
            }
        })
        return object;
    }

    public drawRectangleFromPercentages = async (
        id: string,
        inputType: InputType,
        name: string,
        isPreFill: boolean,
        leftPercent: number,
        topPercent: number,
        heightPercent: number,
        widthPercent: number,
        text: string | undefined,
        immovable: boolean,
        dataUrl: string | undefined,
        fontSize: number) => {
        const x = calculateLeftFromPercent(leftPercent, this.canvas);
        const y = calculateTopFromPercent(topPercent, this.canvas);
        const width = calculateWidthFromPercent(widthPercent, this.canvas);
        const height = calculateHeightFromPercent(heightPercent, this.canvas);
        const x2 = x + width;
        const y2 = y + height;

        return await this.drawRectangle(
            id,
            inputType,
            name,
            isPreFill,
            x,
            y,
            width,
            height,
            text,
            immovable,
            dataUrl,
            fontSize
        );
    }

    public async drawRectangle(
        id: string,
        inputType: InputType,
        name: string,
        isPreFill: boolean,
        x: number,
        y: number,
        width: number,
        height: number,
        text: string | undefined,
        immovable: boolean,
        dataUrl: string | undefined, 
        fontSize: number
    ) {
        const group = new fabric.Group([], {
            _id: id,
            inputType: inputType,
            name: name,
            left: x,
            top: y,
            width: width,
            height: height,
            originX: "left",
            originY: "top",
            evented: !immovable,
            selectable: !immovable
        });
        group.isPreFill = isPreFill


        const object = new fabric.Rect({
            left: -(width ?? 0) / 2,
            top: -(height ?? 0) / 2,
            width: width,
            height: height,
            ...this.getDrawerOptionsFromInput({ inputType: inputType })
        })
        group.add(object);


        if (text && inputType === "Text") {
            const fText = new fabric.Textbox(text, {
                left: -(width ?? 0) / 2,
                top: -(height ?? 0) / 2,
                width: width,
                height: height,
                editable: false,
                evented: false,
                fontSize: fontSize
            });
            group.add(fText);
            console.log("Created Texbox", fText.fontSize)
        }


        if (dataUrl && inputType === "Image") {
            const fImage = await this.generateImageFromUrl(dataUrl, {
                left: -(width ?? 0) / 2,
                top: -(height ?? 0) / 2,
            });

            let scale = Math.min((width / (fImage.width || 1)), (height / (fImage.height || 1)));
            fImage.set('scaleX', scale);
            fImage.set('scaleY', scale);

            group.add(fImage);
        }
        // console.log("FabricDarwer created group", group)
        // console.log(`x: ${x} y: ${y}`);
        // console.log("drawerOptions:", this.getDrawerOptions())

        this.canvas.add(group);
        this.canvas.renderAll();
        this.objects.push(group);

        this.emit(ObjectCreated, group);
    }

    generateImageFromUrl = async (url: string, options: fabric.IImageOptions) => {
        return new Promise<fabric.Image>((resolve) => {
            fabric.Image.fromURL(url, (image) => {
                resolve(image);
            }, options)
        });
    }
}

export class LineDrawer implements IObjectDrawer {
    drawingMode: DrawingMode = DrawingMode.Line;
    make(x: number, y: number, options: fabric.IObjectOptions,
        x2?: number, y2?: number)
        : Promise<fabric.Object> {
        //Return a Promise that will draw a line
        return new Promise<fabric.Object>(resolve => {
            //Inside the Promise, draw the actual line from (x,y) to (x2,y2)
            resolve(new fabric.Line([x, y, x2 ?? x, y2 ?? y], options));
        });
    }

    resize(object: fabric.Object, x: number, y: number)
        : Promise<fabric.Object> {


        //Change the secondary point (x2, y2) of the object 
        //This resizes the object between starting point (x,y)
        //and secondary point (x2,y2), where x2 and y2 have new values.
        (object as fabric.Line).set({
            x2: x,
            y2: y
        }).setCoords();

        //Wrap the resized object in a Promise
        return new Promise<fabric.Object>(resolve => {
            resolve(object);
        });
    }
}

class RectangleDrawer implements IObjectDrawer {
    private origX: number = 0;
    private origY: number = 0;

    drawingMode: DrawingMode = DrawingMode.Rectangle;

    make(x: number, y: number,
        options: fabric.IObjectOptions,
        width?: number, height?: number)
        : Promise<fabric.Object> {
        this.origX = x;
        this.origY = y;

        return new Promise<fabric.Object>(resolve => {
            resolve(new fabric.Rect({
                left: x,
                top: y,
                width: width,
                height: height,
                ...options
            }));
        });
    }

    resize(object: fabric.Object, x: number, y: number): Promise<fabric.Object> {
        //Calculate size and orientation of resized rectangle
        object.set({
            originX: this.origX > x ? 'right' : 'left',
            originY: this.origY > y ? 'bottom' : 'top',
            width: Math.abs(this.origX - x),
            height: Math.abs(this.origY - y),
        }).setCoords();

        return new Promise<fabric.Object>(resolve => {
            resolve(object);
        });
    }
}

class OvalDrawer implements IObjectDrawer {
    private origX: number = 0;
    private origY: number = 0;

    drawingMode: DrawingMode = DrawingMode.Oval;

    make(x: number, y: number, options: fabric.IObjectOptions, rx?: number, ry?: number): Promise<fabric.Object> {
        this.origX = x;
        this.origY = y;

        return new Promise<fabric.Object>(resolve => {
            resolve(new fabric.Ellipse({
                left: x,
                top: y,
                rx: rx,
                ry: ry,
                fill: 'transparent',
                ...options
            }));
        });
    }

    resize(object: fabric.Object, x: number, y: number): Promise<fabric.Object> {
        if (!object.left) {
            object.left = 0;
        }
        if (!object.top) {
            object.top = 0;
        }
        const ellipse = object as fabric.Ellipse;
        ellipse.set({
            originX: this.origX > x ? 'right' : 'left',
            originY: this.origY > y ? 'bottom' : 'top',
            rx: Math.abs(x - object.left) / 2,
            ry: Math.abs(y - object.top) / 2
        }).setCoords();

        return new Promise<fabric.Object>(resolve => {
            resolve(object);
        });
    }
}