import React, { useRef } from 'react';
import io from "socket.io-client";
import { NoGateVideo, Participant } from "../../utils/DocTogetherVideo";
import StoreX, { store } from "../../redux/oldStore";
import { toast } from "react-toastify";
import Modal from '../Modal/Modal';
import JustVideoAdminPanel from './JustVideoAdminPanel';
import { ScreenRecorder } from '../ScreenRecording/ScreenRecorder';
import { ServerResponse } from '../../utils/Server';
import SupportChat from "../SupportChat/SupportChats";

import audioPlayer from "../../utils/AudioPlayer";
import ProjectInfo from './ProjectInfo';
import {formatPin} from '../../utils/Tools';
import { IVirtualRoom, JustVideoContainerState, RoomInfo } from './JustVideoTypes';
import GatedEntryControler from './GatedEntryControler';
import RequestModeratorInterpreter from '../AdvancedJudging/RequestModeratorInterpreter';
import { useParams } from 'react-router-dom';

class JustVideoContainer extends React.Component<JustVideoContainerState> {
  docVideo?: NoGateVideo;
  state:any;
  roomId:string;
  socketMgr;
  constructor(props) {
    super(props)
    this.state = {
      joined: false,
      settings: {},
      videoCallInModalOpen: false,
      videoCallInPhoneNumber: [],
      videoCallInPin: '',
      roomInfoDump: {},
      hingeInfoDump: undefined,
      participants: [],
      projectId: '',
      user:undefined
    };

    this.socketMgr = {
      init: () => {
        console.log('!!!!!!!!!!!!! init in the socketMgr')
        let url =
          window.location.host.indexOf("localhost") > -1
            ? "http://localhost:9191/online-judging"
            : "https://socket.zfairs.com/online-judging";
        let socket = (this.socketMgr.socket = io(url, {
          query: {
            fairId: this.state.hingeInfoDump?.fairId,
            clientId: this.state.hingeInfoDump?.clientId,
            personId: this.state.hingeInfoDump?.user.id,
            projectId: this.state.projectId,
            // judge: false,
          },
          transports: ['websocket', 'polling'],
        }));

        socket?.on('room-message', (x: any) => {
          console.log('!!!!!!!!!! room-message', x)
          this.handleRoomMessage(x);
        });
        socket.on("video-gate-override", (x) => {
          // this.setState({ gateOverridden: x.on });
          console.log("Socket---> video-gate-override: ", x);
        });

        
        socket.on("dashboard-announcement", (data) => {
          switch (data.type) {
            case "bell":
              if(!data.id || data.id === this.state.projectId || data.id === 'jduges'){
                toast.info(data.message || "Ring, Ring, Ring...", {autoClose:7000});
                audioPlayer.sound.bell();
              }
              break;
            case "message":
              if(!data.id || data.id === this.state.projectId || data.id === 'judges'){
                toast.info(data.message, {autoClose:false});
                audioPlayer.sound.attention();
              }
              break;
              
        case "reload":
          if(!data.id || data.id === this.state.projectId || data.id === 'projects'|| this.state.personId === data.id)
          {
            toast.error('Refreshing the page.');
            window.setTimeout(()=>{
              window.location.reload();
            }, 3000);
          } 
          break;
        case "force-logout":
          if(!data.id || data.id === this.state.projectId || data.id === 'projects'|| this.state.personId === data.id){
            toast.error(`You are being signed out.`);
            store.server.postApi('../User/SignOut',{})
            window.setTimeout(()=>{
              window.location.href = window.location.origin;
            }, 3000);
          }
          break;
            default:
              break;
          }
        });
      },

      hangUp: () => {        
        
        
        let meetingId = this.roomId;
        this.socketMgr.socket.emit('person-out-conference', {meetingId:meetingId?? this.state.roomInfoDump?.id ?? 'Unknown'});
      },
      connected: () => {
        let meetingId = this.roomId;
        this.socketMgr.socket.emit('person-in-conference', {meetingId:meetingId?? this.state.roomInfoDump?.id ?? 'Unknown'});
        
      },
      closeGate: () => {
        this.socketMgr.socket.emit("video-gate-override", {on:false, projectId:this.state.projectId});
      }
    };

    this.roomId = window.location.pathname.split('/').pop() ?? '';

  }


  componentDidMount() {

    let origString = this.roomId;
    let arrayStuff = origString.split(' - ');
    let stringed = arrayStuff[1]
    this.setState({ projectId: stringed })
    store.server
      .postApi<any>("../Hinge/Info", {})
      .then((res) => {
        // console.log("info response", res);
        if (!res.Success) {
          toast.error(res.Message, { autoClose: false });
          return;
        }

        this.setState({
          settings: res.Value.settings,
          hingeInfoDump: res.Value,
          user: res.Value.user,
        });

        this.init(res.Value.settings);

        const self = this;
        window.document.title = `Video Room`;
        window.setTimeout(() => {
          self.handleStartVideoClick();
        }, 300);
      })
      .catch((err) => console.log(err));


  }

  connectToMeeting(room:RoomInfo) {
    this.docVideo?.connect(room, this.state.settings.enableChat);
    this.setState({ joined: true }, this.JoinedChanged);
  }

  connectToMeetingApproved(){
    if(this.state.roomInfoDump) this.connectToMeeting(this.state.roomInfoDump);
  }

  getRoomInfo = async () => {

    await store.server
      .postApi<ServerResponse<RoomInfo>>(`../Hinge/GetVideoRoomInfo`, {id: this.roomId, roomPin:StoreX.GetQueryStringValue('rp')})
      .then((res) => {
        if (res.Success) {
          if(res.Value.PinPass) this.connectToMeeting(res.Value);
          if(res.Value.PinPass && res.Value.DefinedVirtualRoom && res.Value.DefinedVirtualRoom.RoomPin) {            
            this.setState({lookForThoseWantingToEnter:true});
          }

          this.setState({ roomInfoDump: res.Value });


          if (this.state.settings.onlineVideoShowCallInNumber) {
            this.setState({ 
              videoCallInPhoneNumber: res.Value.phoneNumber || [], 
              videoCallInPin: res.Value.conferencePin ?? "" 
            });
          }


        } else {
          toast.error(res.Message)
        }
      })
      .catch((err) => console.log(err));
  };

  connected = () => {
    this.socketMgr.connected();

    this.setState({ joined: true }, this.JoinedChanged);
    this.JoinedChanged();
  };

  hungUp = () => {
    this.setState({ joined: false }, this.JoinedChanged);
  };

  private JoinedChanged = () => {
    const joined = this.state.joined;
    const appEl = document.querySelector("body");
    if (joined) appEl?.classList.add("participant-video");
    else appEl?.classList.remove("participant-video");
  };

  handleHangup = () => {
    this.socketMgr.hangUp();
    this.docVideo?.hangUp();
    if(this.stopRecording) this.stopRecording();
  };

  stopRecording:Function = ()=>{};
  startRecording:Function = ()=>{};
  recordingCallbacks= (stopRecording:Function, startRecording:Function)=>{
    this.stopRecording = stopRecording;
    this.startRecording = startRecording;
  };

  init = async (settings) => {
    this.socketMgr.init();
    this.docVideo = new NoGateVideo("#docVideo", {
      dev: false,
      hangUp: this.hungUp,
      connectCallback: this.connected,
      setParticipants: this.setParticipants,
      jitsi: settings.useJitsi,
    });
    this.docVideo.init();


  };

  handleStartVideoClick = async () => {
    await this.docVideo?.init();
    await this.getRoomInfo();
  };

  setVideoCallInModalOpen = (modalOpen: boolean) => {
    this.setState({ videoCallInModalOpen: modalOpen })
  }

  sendRoomMessage = (body: any) => {
    body.projectId = this.state.projectId;
    this.socketMgr.socket?.emit('room-message', body);
  };

  handleRoomMessage = (body: any) => {
    console.log('!!!!!!!!!', body);
    if (body.message) {
      toast.info(body.message);
    }

    const myId = this.docVideo?.jitsiApi?._myUserID;

    if (body.mute === myId) {
      this.docVideo?.muteAudio();
      toast.dark(`Your microphone has been turned off.`);
    }
    if (body.muteVideo === myId) {
      this.docVideo?.muteVideo();
      toast.dark(`Your camera has been turned off.`);
    }
    if (body.kick === myId) {
      this.docVideo?.hangUp();
      toast.dark(`You've been kicked from this meeting.`);
    }
  };

  setParticipants = (participants: Array<Participant>) => {
    this.setState({ participants: participants }, () => { });
  };

  render() {

    return (
      <div>
        <div>
          {!this.state.joined ? (
            <div className="flex-center">
              <button
                className="btn btn-primary"
                onClick={this.handleStartVideoClick}
              >
                <i className="fad fa-person-booth"></i>
                   Join Virtual Meeting Room
                </button>
            </div>
          ) : (
              <>
              <div className="flex-center">
              <span className="btn-group">
                <button className="btn btn-danger" onClick={this.handleHangup}>
                  <i className="fas fa-phone hangup-button"></i>
                  Hang up
                </button>
                {this.state.settings.onlineVideoShowCallInNumber
                  && <button type="button" className="btn btn-secondary" onClick={() => this.setVideoCallInModalOpen(true)} title="dial into meeting"><i className="fas fa-phone-rotary text-secondary"  /></button>
                }
                { (this.state.hingeInfoDump?.moderator || (this.state.roomInfoDump?.DefinedVirtualRoom?.RoomPin && this.state.roomInfoDump?.PinPass))
                && this.state.settings && this.state.settings.allowRecording && this.state.joined &&
                <ScreenRecorder autoSaveEveryXSeconds={15} uploadUrl="https://site.nhd.org/recording/SaveThis" 
                  fileName={this.state.roomInfoDump?.id || "ScreenRecording"} 
                  callback={this.recordingCallbacks} />}
                </span>
                </div>
              </>
            )}
        </div>
                
        <div
          id="docVideo"
          className={`${!this.state.joined ? "hide" : ""}`}
        ></div>
        <div>

          {this.state.joined
            &&
            <JustVideoAdminPanel
              participants={this.state.participants}
              sendRoomMessage={this.sendRoomMessage}
              isModerator={((this.state.hingeInfoDump?.moderator ?? false) || this.state.lookForThoseWantingToEnter)}
              socket={this.socketMgr.socket}
              projectId={this.state.projectId ?? this.roomId}              
              settings={this.state.settings}
            />
          }
        </div>
        <div>
          {this.state.roomInfoDump && this.state.roomInfoDump.additional && <>
            <ProjectInfo settings={this.state.settings} project={this.state.roomInfoDump.additional}></ProjectInfo>
            
          </>}
        </div>

        {(this.state.videoCallInModalOpen)
          && (
            <Modal
              title='Conference call in number'
              setModalOpen={this.setVideoCallInModalOpen}
            >
              <div>
                <div className="call-in-info-line">
                  
                <span className="emph">Phone number: </span>
                
                <select>
                {this.state.videoCallInPhoneNumber.map(x => <option key={x}>{x}</option>)}

                </select>
                </div>
                <div className="call-in-info-line">
                  <span className="emph">PIN:</span>
                   {this.state.videoCallInPin && formatPin(this.state.videoCallInPin)}
                  </div>
              </div>
            </Modal>
          )

        }
                  {this.state.settings && this.state.settings.enableSupportChat && 
          <SupportChat 
          socket={null}
          isSupportAgent={false} 
          fairId={this.state.fairId}
          clientId={this.state.clientId}
          personId={this.state.personId}
          projectId={this.state.personId}
          name={`${this.state.user?.firstName} ${this.state.user?.lastName}`}
          ></SupportChat>}

          {this.state.settings?.enableWaitRoom && this.state.roomInfoDump?.id && <RequestModeratorInterpreter settings={StoreX.Settings} languages={this.state.settings.languages ?? ['English']} meetingId={this.state.roomInfoDump.id} />}
              
          {this.state.roomInfoDump?.DefinedVirtualRoom && <>
            <GatedEntryControler Meeting={this.state.roomInfoDump.DefinedVirtualRoom} CanApprove={this.state.roomInfoDump.PinPass ?? false} Join={this.connectToMeetingApproved.bind(this)} />
          </>}

      </div>
    )
  }
}

export default JustVideoContainer;