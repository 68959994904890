import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { store } from "../../redux/oldStore";
import { ServerResponse } from "../../utils/Server";
import Icon, { IconType } from "../Icon/Icon";
import { IInvoice, IInvoiceItem, IInvoiceProduct } from "./OrganizationTypes";

interface IInvoiceNewEditorProps {
    onUpdated:(invoice:IInvoice)=>void;
    invoice:IInvoice;
    isOwner:boolean;
    products:IInvoiceProduct[];
}

const InvoiceNewEditor = (props:IInvoiceNewEditorProps)=>{
    const [form, setForm] = useState<IInvoice>(props.invoice)

    useEffect(()=>{
        setForm(props.invoice);
    },[props.invoice]);

    const save = ()=>{
        let url = `../OrganizationData/NewInvoice`;    
            store.server
              .postApi<ServerResponse<IInvoice>>(url, form)
              .then((x) => {
                if (x.Success) {
                    props.onUpdated(x.Value);
                    setForm(x.Value);                    
                    toast.success(`Invoice Created!`);
                } else {
                    toast.error(`${x.Message}`, { autoClose: false });
                }
              });    
    };

    const itemUpdated = (item:IInvoiceItem)=>{
        let invoice = {...form};
        if(!invoice.Items) invoice.Items = [];
        let found = false;

        console.log(item);

        for(let i = 0; i < (invoice.Items?.length ?? 0); i++){
            let x = invoice.Items[i];
            if(x.PublicId === item.PublicId){
                invoice.Items[i] = item;
                
        console.log(invoice.Items);
                found = true;
                break;
            }
        }

        if(!found){
            invoice.Items.push(item);
        }

        setForm(invoice);
    };

    const addItem=()=>{
        let item = form.NewItem;
        let p = props.products.find(x=>x.PublicId === item);
        if(item && p){
            let f = {...form};
            let items = f.Items ?? [];
            items.push({
                CartLineId: 0,
                Id: p.Id,
                Name: p.Name,
                Notes: "",
                ItemName: p.Name,
                PublicId: p.PublicId,
                Quantity: 1,
                Cost: p.Cost,
            });
            setForm({...f, Items:items, NewItem:undefined});
        } else {
            toast.warning(`Select an item to add.`);
        }
    };
    

    return <>
        <div className="form-horizontal">
            <h3>Order </h3>
            <hr/>
            <div className="form-group">
                <label className="control-label col-sm-4" htmlFor="eifg-Type">Type*</label>
                <div className="col-sm-6">
                    <select
                        id={'eifg-Type'}
                        value={form.Type}
                        className={`form-control`}
                        onChange={(e)=>{setForm({...form,Type:e.target.value})}}
                    >
                        <option>Invoice</option>
                        <option>Quote</option>
                        <option>Purchase Order</option>
                    </select>
                    </div>                
            </div>
            <div className="form-group">
                <label className="control-label col-sm-4" htmlFor="eifg-PurchaseOrderName">Purchase Order</label>
                <div className="col-sm-6">
                    <input 
                        id={'eifg-PurchaseOrderName'}
                        type={'text'}
                        maxLength={250}
                        value={form.PurchaseOrderName}
                        className={`form-control`}
                        onChange={(e)=>{setForm({...form,PurchaseOrderName:e.target.value})}}

                        />
                    </div>                
            </div>
{ props.isOwner && <div className="form-group">
                <label className="control-label col-sm-4" htmlFor="eifg-Amount">Amount</label>
                <div className="col-sm-6">
                    <input 
                        id={'eifg-Amount'}
                        type={'number'}
                        min={0}
                        max={100000}
                        step={1}
                        value={form.Amount}
                        className={`form-control`}
                        onChange={(e)=>{setForm({...form,Amount:+e.target.value})}}

                        />
                    </div>                
            </div>}
            <div className="form-group">
                <label className="control-label col-sm-4" htmlFor="eifg-Notes">Notes</label>
                <div className="col-sm-6">
                    <textarea 
                        id={'eifg-Notes'}
                        rows={10}
                        maxLength={10000}
                        value={form.Notes}
                        className={`form-control`}
                        onChange={(e)=>{setForm({...form,Notes:e.target.value})}}

                        />
                    </div>                
            </div>

            {form.Items && form.Items.length > 0 && form.Items.map((x,i)=>{
                return <InvoiceItemEditor invoice={x} isOwner={props.isOwner} onUpdated={itemUpdated} key={`iie-k-${i}`} id={x.PublicId}/>
            })}
            <div className="form-group">
                <label className="control-label col-sm-4" htmlFor="eifg-NewItem">New Item</label>
                <div className="col-sm-4">
                    <select 
                        id={'eifg-NewItem'}
                        value={form.NewItem ?? "none"}
                        className={`form-control`}
                        onChange={(e)=>{setForm({...form,NewItem:e.target.value})}}

                        >
                            <option value={"none"}>select</option>
                            {props.products.map((x,i)=>{
                                return <option key={`iie-anp-${i}`} value={x.PublicId}>{x.Name}</option>
                            })}

                        </select>
                    </div>      
                    <div className="col-sm-4">
                            <button type="button" className="btn btn-success" onClick={addItem}><Icon type={IconType.plus}/> Add</button>
                        </div>          
            </div>
            <div className="form-group">
                <div className="col-sm-8 col-sm-offset-4">
                    <button type="button" className="btn btn-secondary" onClick={()=>{save()}}><Icon type={IconType.save} /> Save</button>
                </div>                
            </div>
        </div>
    </>    
}

interface IInvoiceItemEditorProps {
    onUpdated:(item:IInvoiceItem)=>void;
    invoice:IInvoiceItem;
    isOwner:boolean;
    id:string;
}

const InvoiceItemEditor = (props:IInvoiceItemEditorProps)=>{
    const [form, setForm] = useState<IInvoiceItem>(props.invoice)

    useEffect(()=>{
        setForm(props.invoice);
    },[props.invoice]);

    const save = (item:IInvoiceItem)=>{        
        props.onUpdated(item);
    };

    

    return <>
        <div className="form-horizontal invoice-item">
            <h3>Item {form.ItemName}</h3>
            <hr/>
            <div className="form-group">
                <label className="control-label col-sm-4" htmlFor={props.id+'eifg-Name'}>Name</label>
                <div className="col-sm-6">
                    <input 
                        id={props.id+'eifg-Name'}
                        type={'text'}
                        maxLength={500}
                        value={form.Name}
                        className={`form-control`}
                        onChange={(e)=>{save({...form,Name:e.target.value});}}

                        />
                    </div>                
            </div>
{ props.isOwner && <div className="form-group">
                <label className="control-label col-sm-4" htmlFor={props.id+'eifg-Cost'}>Cost</label>
                <div className="col-sm-6">
                    <input 
                        id={props.id+'eifg-Cost'}
                        type={'number'}
                        maxLength={250}
                        min={-1000}
                        max={10000}
                        step={1}
                        value={form.Cost}
                        className={`form-control`}
                        onChange={(e)=>{save({...form,Cost:+e.target.value});}}

                        />
                    </div>                
            </div>}
            <div className="form-group">
                <label className="control-label col-sm-4" htmlFor={props.id+'eifg-Quantity'}>Quantity</label>
                <div className="col-sm-6">
                    <input 
                        id={props.id+'eifg-Quantity'}
                        type={'number'}
                        maxLength={250}
                        step={1}
                        value={form.Quantity}
                        className={`form-control`}
                        onChange={(e)=>{save({...form,Quantity:+e.target.value});}}

                        />
                    </div>                
            </div>
            <div className="form-group">
                <label className="control-label col-sm-4" htmlFor={props.id+'eifg-Notes'}>Notes</label>
                <div className="col-sm-6">
                    <textarea 
                        id={'eifg-Notes'}
                        rows={3}
                        maxLength={10000}
                        value={form.Notes}
                        className={`form-control`}
                        onChange={(e)=>{save({...form,Notes:e.target.value});}}

                        />
                    </div>                
            </div>
        </div>
    </>    
}

export default InvoiceNewEditor;