export const calculateTopPercent = (fabricObject: fabric.Object, correctCanvas: fabric.Canvas) => {
    if (
      !fabricObject.top ||
      !fabricObject.height ||
      !fabricObject.left ||
      !fabricObject.scaleY ||
      !fabricObject.width ||
      !fabricObject.scaleX ||
      !fabricObject.canvas
    ) {
      throw new Error("Object is not propertly defined.");
    }
    // let correctCanvas = this._pdfViewer.canvasObjects[
    //   this._pdfViewer.convertPageNumberToIndex(
    //     fabricObject.canvas?.pageNumber ?? 0
    //   )
    // ].fabricCanvas;
    return (fabricObject.top / correctCanvas.getHeight()) * 100 || 0;
  }

export const calculateLeftPercent = (fabricObject: fabric.Object, correctCanvas: fabric.Canvas) => {
    if (
      !fabricObject.top ||
      !fabricObject.height ||
      !fabricObject.left ||
      !fabricObject.scaleY ||
      !fabricObject.width ||
      !fabricObject.scaleX ||
      !fabricObject.canvas
    ) {
      throw new Error("Object is not propertly defined.");
    }
    return (fabricObject.left / correctCanvas.getWidth()) * 100 || 0;
  }

export const calculateHeightPercent = (fabricObject: fabric.Object, correctCanvas: fabric.Canvas) => {
    if (
      !fabricObject.top ||
      !fabricObject.height ||
      !fabricObject.left ||
      !fabricObject.scaleY ||
      !fabricObject.width ||
      !fabricObject.scaleX ||
      !fabricObject.canvas
    ) {
      throw new Error("Object is not propertly defined.");
    }
    return ((fabricObject.height * fabricObject.scaleY) /
      correctCanvas.getHeight()) *
      100 || 0;
  }

export const calculateWidthPercent = (fabricObject: fabric.Object, correctCanvas: fabric.Canvas) => {
    if (
      !fabricObject.top ||
      !fabricObject.height ||
      !fabricObject.left ||
      !fabricObject.scaleY ||
      !fabricObject.width ||
      !fabricObject.scaleX ||
      !fabricObject.canvas
    ) {
      throw new Error("Object is not propertly defined.");
    }
    return ((fabricObject.width * fabricObject.scaleX) /
      correctCanvas.getWidth()) *
      100 || 0
  }

export const calculateTopFromPercent = (topAsPercent: number, correctCanvas: fabric.Canvas) => {
    return (topAsPercent / 100) * correctCanvas.getHeight();
}

export const calculateHeightFromPercent = (height: number, correctCanvas: fabric.Canvas) => {
    return (height / 100) * correctCanvas.getHeight();
}

export const calculateLeftFromPercent = (leftAsPercent: number, correctCanvas: fabric.Canvas) => {
    return (leftAsPercent / 100) * correctCanvas.getWidth();
}

export const calculateWidthFromPercent = (widthAsPercent: number, correctCanvas: fabric.Canvas) => {
    return (widthAsPercent / 100) * correctCanvas.getWidth();
}