import { useEffect, useState } from "react";
import { IState, ReduxMap } from "../../redux/redux";
import { toast } from "react-toastify";
import StoreX, { store } from "../../redux/oldStore";
import { ServerResponse } from "../../utils/Server";


interface IProps extends IState {
    
}


const NewUserLanding = (props:IProps) =>{
const [data, setData] = useState<IAccountTypeInfo>();

useEffect(()=>{
    init();
},[]);

const init=()=>{
    toast.dismiss();
    store.server.getApi<ServerResponse<IAccountTypeInfo>>(`../Account/AccountTypes`)
    .then(x=>{
        if(x.Success){
            setData(x.Value);
        } else {
            toast.error(x.Message, {autoClose:false});
        }
    });
};


    return <>
        {data && <>
            <div dangerouslySetInnerHTML={{__html:data.HeaderContent ?? ""}}/>

            <div className="account-type-container">
                {data.Accounts.map((x,i)=>{
                    return <div className="list-item click" key={i} onClick={()=>{window.location.href = StoreX.BuildUrl(x.EntryPoint);}}>
                        {x.Icon && <div className="icon-image" >
                                <div dangerouslySetInnerHTML={{__html:x.Icon}}/>
                            </div>}
                        <div className="tile-label">{x.Label}</div>
                        {x.Description && <div className="description" dangerouslySetInnerHTML={{__html:x.Description}}></div>}
                    </div>
                })}
            </div>
        
            <div dangerouslySetInnerHTML={{__html:data.FooterContent ?? ""}}/>
        </>}
    </>
};


export default ReduxMap(NewUserLanding);

interface IAccountTypeInfo{
    Accounts:IAccountType[];
    HeaderContent:string;
    FooterContent:string;
}

interface IAccountType
{
    Label:string;
    Icon:string;
    EntryPoint:string;
    Opens:string;
    Closes:string;
    Description:string;
    Locked:boolean;
}