import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';



const AddOrEditCategory = (props) => {
  const {
    setModalOpen,
    selectedCategory,
    handleDelete,
  } = props

  //const categoryWeights = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100]
  const categoryWeights = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100 ]

  const Id = selectedCategory.Id || uuidv4()
  const [Name, setName] = useState(selectedCategory.Name || '')
  const [Sort, setSort] = useState(selectedCategory.Sort)
  const [nameError, setNameError] = useState(false)
  const [Value, setValue] = useState((selectedCategory.Value === 0 || !!selectedCategory.Value) ? selectedCategory.Value :  20)
  const [TeamOnly, setTeamOnly] = useState(selectedCategory.TeamOnly || false)

  const modifiedHandleDelete = (e) => {
    if (!window.confirm(`are you sure you want to delete this?`)) return;
    handleDelete(selectedCategory.Id);
  };


  const handleSave = () => {
    if (!Name) {
      toast.warning('Category needs a name')
      setNameError(true);
      return; 
    }
    let categoryObj = {
      Id,
      Name, // needed 
      Value, // weight/value
      TeamOnly,
      Sort
    };
    // TODO form validation
    props.handleSaveCategory(categoryObj);
  }
  const handleFocus = (e) => {
    e.target.select();
  }

  return (
    <div>
      <div className='inputs-area'>
        <div className={`form-input ${nameError ? 'error' : '' }`}>
          <label htmlFor='Name'>Judging Category: </label>
          <input
            className='dark-text'
            type='text'
            name='Name'
            id='Name'
            onChange={(e) => setName(e.target.value)}
            value={Name}
            autoFocus={true}
            onFocus={handleFocus}
          />
        </div>
        <div className='form-input'>
          <label htmlFor='Value'>Category Weight: </label>
          <select
            name='Value'
            id='Value'
            onChange={(e) => setValue(e.target.value)}
            value={Value}
          // value={selectedCategory.Value}

          >
            <option value={0}>0% - This category has no outcome on the score</option>
            {categoryWeights.map(weight => <option key={weight} value={weight}>{weight}%</option>)}
          </select>
        </div>
        <div className={`form-input`}>
        <label htmlFor='Sort'>Sort: </label>
        <input
          className='dark-text'
          type='number'
          name='Sort'
          id='Sort'
          min={0}
          max={100}
          step={1}
          onChange={(e) => setSort(e.target.value)}
          value={Sort}
        />
      </div>
        <div className='form-input'>
          <label htmlFor='TeamOnly'>This judging category only applies to team entries.</label>
          <input
            type='checkbox'
            name='TeamOnly'
            id='TeamOnly'
            onChange={(e) => setTeamOnly(e.target.checked)}
            defaultChecked={TeamOnly}
          />
        </div>

      </div>
      <div className='buttons-area'>
        <button
          className='btn btn-success save-judging-category-btn'
          onClick={handleSave}
        >
          <i className="far fa-save"></i>
          save
        </button>
        <button
          className='btn btn-secondary'
          onClick={() => setModalOpen(false)}
        >
          <i className="far fa-times-circle"></i>
          cancel
        </button>
        {selectedCategory.Id
          &&
          <button
            className='btn btn-danger delete-category-btn'
            onClick={modifiedHandleDelete}
          >
            <i className="far fa-trash-alt"></i>
            delete
          </button>

        }
      </div>
    </div>

  )
}

export default AddOrEditCategory