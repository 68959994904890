import React, { useState, useEffect, useLayoutEffect } from "react";
import { toast } from "react-toastify";

interface ICategory{
  id:number, name:string,
}
interface IQuestion{
  question:string,
  response:string,
}

interface IProject {
  category:ICategory|null,
  projectId:string,
  title:string,
  students:string[],
  team:boolean,
  projectQuestions:IQuestion[]|null,
}

interface IProjectInfoProps{
  project:IProject,
  settings:any,
}

const ProjectInfo = (props: any) => {
  const [project, setProjectInfo] = useState<IProject>(props.project);
  const [settings, setSettings] = useState<any>(props.settings);

  return (
    <div className="project-info-panel">
    {project && <>    
              <div className="heading">
                <h3>{project.projectId}</h3>
                <div className="title">{project.title}</div>
              </div>

              <div className="question-container">
                {project.projectQuestions && project.projectQuestions.length && project.projectQuestions.map((q:IQuestion, i:number)=>{
                  
                  if(q.response && q.response.toLowerCase().indexOf('http')===0){
                    return (<div className="question"> 
                      <span className='project-question'>{q.question}</span>
                      <span className='project-answer'>
                      <span className={`click fal fa-copy text-muted`} onClick={()=>{window.navigator.clipboard.writeText(`${q.response}`);}} title="copy link"></span>
                      &nbsp;&nbsp;&nbsp;
                      <a href={q.response} target="_blank">{q.response}</a> 
                      </span>
                    </div>);
                  }
                  else if(q.response && q.response.length > 100){
                    return (<div className="question"> 
                        <span className='project-question'>{q.question}</span>
                        <div className='project-answer'>{q.response}</div>
                      </div>);
                  }
                  else{
                    return (<div className="question">
                        <span className='project-question'>{q.question}</span>
                        <span className='project-answer'>{q.response}</span>
                     </div>);
                  }
                 })}
              </div>
    
    </>}
    </div>
  );
};

export default ProjectInfo;
