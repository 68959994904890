import React, { useEffect, useState } from 'react'

import Icon, { IconType } from "../Icon/Icon";
import { store } from '../../redux/oldStore'
import { GuidToJudgeDict, GuidToProjectDict, IntIdToProjectDict, IRound, ISettings, PersonIdToJudgeDict } from './Dashboard'

interface SelectedRoundProps {
  chosenRound: IRound;
  settings: ISettings | undefined;
  judgesByPersonId: PersonIdToJudgeDict;
  projectsByIntId: IntIdToProjectDict;
  projectsByGuid: GuidToProjectDict;
  projectsActive: string[];
  judgesByGuid: GuidToJudgeDict;
  judgesActive: string[];


}

interface IAssignment {
  Id: number; //14819
  RoundId: number; //2
  JudgeId: number; //72
  Status: string; //"New"
  ProjectId: number; //72
  FaildToSetInterview: boolean | null; //null
  InterviewAt: string | null; //"Wed : string | null; // AM"
  Date: string | null; //"2020-11-11"
  Time: string | null; //": string | null; //"
  Score: number | null; //0
  AssessmentId: number | null; //9133
  NoShow: boolean; //
  InterviewFeedback: IInterviewFeedback;
}

interface IInterviewFeedback{
  AtUtc:string,
  AtString:string,
  Interview:string,
}

const SelectedRound = (props: SelectedRoundProps) => {
  const params = new URLSearchParams(window.location.search);
  const fairId = params.get("f");
  const fairIdPara = fairId ? `f=${fairId}` : null;
  const [roundAssignments, setRoundAssignments] = useState<IAssignment[]>([]);
  const [filteredRoundAssignments, setFilteredRoundAssignments] = useState<IAssignment[]>([]);
  const [assignmentSearchVal, setAssignmentSearchVal] = useState('');
  const [showCompletedAssignments, setShowCompletedAssignments] = useState(true);
  const [showToDoAssignments, setShowToDoAssignments] = useState(true);
  const [judgesActive, setJudgesActive] = useState<any>({});
  const [projectsActive, setProjectsActive] = useState<any>({});
  const [showLegend, setShowLegend] = useState(false);


  useEffect(()=>{
    const dic = {};
    props.judgesActive.forEach((x:string)=>{
      dic[x]=true;
    });
    setJudgesActive(dic);
}, [props.judgesActive]);

useEffect(() => {
  const dic = {};
  props.projectsActive.forEach((x: string) => {
    dic[x] = true;
  });
  setProjectsActive(dic);
}, [props.projectsActive]);

  useEffect(() => {
    store.server.postApi('../JudgingManagement/RoundAssignments', { roundId: props.chosenRound.Id })
      .then((res: any) => {
        console.log(res)
        setRoundAssignments(res.Value.Assignments)
      })
  }, [props.chosenRound])

  useEffect(() => {
    if (!assignmentSearchVal) {
      return setFilteredRoundAssignments(roundAssignments)
    } else {
      // project id or title, may need the projectIdDisplay on the Project for searching purposes
      let filteredStuff = roundAssignments.filter(assignment =>{
        let project = props.projectsByIntId[assignment.ProjectId]
        let judge = props.judgesByPersonId[assignment.JudgeId]
        return (
          project.Title.toLowerCase().includes(assignmentSearchVal.toLowerCase())
          || project.ProjectIntId.toString().includes(assignmentSearchVal)
          || project.ProjectId.toString().includes(assignmentSearchVal)
          || judge.FirstName.toLowerCase().includes(assignmentSearchVal.toLowerCase())
          || judge.LastName.toLowerCase().includes(assignmentSearchVal.toLowerCase())
        )
        }
      )
      setFilteredRoundAssignments(filteredStuff);
    }
  }, [roundAssignments, assignmentSearchVal]);

  const buildAssignmentDiv = (assignment: IAssignment, i: number, isDone: boolean) => {
    let project = props.projectsByIntId[assignment.ProjectId];
    let judge = props.judgesByPersonId[assignment.JudgeId];
    if(!project) return undefined;
    return (
      <tr key={`${assignment.Id}-project-${i}`}>
        <td className='bumper-l'>{isDone && <i className="far fa-check-circle text-success"></i>}
        {assignment.InterviewFeedback?.Interview === 'Not Interviewed' && <span className="text-danger" title="Judge did not show">
        <Icon type={IconType.warning} /></span>}
        </td>
        <td className='bumper-l'>{projectsActive[project.Id] && <i className="fal fa-user"></i>} {project.ProjectId}</td>
        <td className='bumper-l'>{project.Title}</td>
        <td className='bumper-l'>{judgesActive[judge.PublicId] && <i className="fal fa-user"></i>} {judge.FirstName} {judge.LastName}</td>
        <td className='bumper-l'>{assignment.Date} {assignment.InterviewAt}</td>
        <td className='bumper-l'>
          {assignment.NoShow 
            ? <i 
              title='project was a no-show' 
              className="fas fa-exclamation-triangle text-danger"
            /> 
            : assignment.Score?.toFixed(2)
          }
          </td>
        <td className='bumper-l'>
          {props.settings?.virtualMeetings 
            && <a 
                href={`${window.location.origin}/app/video/${project.MeetingId}?${fairIdPara}`} 
                className="link" 
                target="_blank" 
                title="Join Interview"
              >
                <i className="fal fa-tv-retro"></i>
              </a>
          }
        </td>
      </tr>
    )
  }

  const mappedCompletedAssignments = filteredRoundAssignments.filter(x => x.AssessmentId).map((y, i) => buildAssignmentDiv(y, i, true))
  const mappedToDoAssignments = filteredRoundAssignments.filter(x => !x.AssessmentId).map((y, i) => buildAssignmentDiv(y, i, false))


  return (
    <div>
      <div className="input-group">
        <input
          onChange={(e) => setAssignmentSearchVal(e.target.value)}
          value={assignmentSearchVal}
          className="form-control "
          placeholder="search by project id, project title, or judge name"
        />
        <span className="input-group-addon">
          <i className="fa fa-search"></i>
        </span>
      </div>
      <div>
        <label>show completed: </label>
        <input 
          type='checkbox' 
          checked={showCompletedAssignments} 
          onChange={e => setShowCompletedAssignments(e.target.checked)} 
        />
        <label>show to do: </label>
        <input 
          type='checkbox' 
          checked={showToDoAssignments} 
          onChange={e => setShowToDoAssignments(e.target.checked)} 
        />
      </div>
      <div className='bumper-l bumper-out-l'>
        Legend: 
        <span
          onClick={() => setShowLegend(!showLegend)}
          className="hover-info"
        >
          <i className="fad fa-eye " ></i>
        </span>
        { showLegend 
          &&<div>
            <div><i className="far fa-check-circle text-success" /> - Assignment is done</div>
            <div><i className="fal fa-user"/> - person is active</div>
            <div><i className="fas fa-exclamation-triangle text-danger"/> - Project was a no show</div>
          </div>
        }
      </div>
      <div className='row'>
        <div className='col-sm-12'>
          <h4>Assignments:</h4>
          <table className='judge-table'>
            <thead>
              <tr>
                <th className='bumper-l'>Done</th>
                <th className='bumper-l'>Project Id</th>
                <th className='bumper-l'>Project Title</th>
                <th className='bumper-l'>Judge Name</th>
                <th className='bumper-l'>Interview At</th>
                <th className='bumper-l'>Score</th>
                <th className='bumper-l'>Interview Room</th>
              </tr>
            </thead>
            <tbody>
              {showToDoAssignments && mappedToDoAssignments}
              {showCompletedAssignments && mappedCompletedAssignments}
            </tbody>
          </table>
          </div>
      </div>
    </div>
  )
}

export default SelectedRound