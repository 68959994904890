import React from 'react';
import Select from 'react-select';
import { IAward, IProject, ITag } from '../../redux/judgingResults/types'
import FileDownload from 'js-file-download'
import { Selectable } from '../../utils/CommonTypes/CommonTypes';
import Icon, { IconType } from '../Icon/Icon';

type ProjectSort = 'default' | 'averageScore' | 'zScore' | 'adjustedScore';

export interface IFilter {text?:string; selected?:Selectable[]};

interface ProjectsTabProps {
  allRoundProjects: IProject[];
  handleProjectClick: Function;
  projectTags: ITag[];
  categoryAwards: IAward[];
  setAllRoundProjects: Function;
  categoryAwardDict: { [key: number]: IAward };
  allRoundCategoryAwards: {ProjectKey: string, AwardId: number}[];
  projectTagDict: { [key: number]: ITag }
  projectsWithAward: {[ProjectKey: string]: IAward}
  projectSearchVal: IFilter;
  filteredRoundProjects: IProject[];
  filterOptions: Selectable[];
  projectsSortedBy: ProjectSort;
  setProjectSearchVal: (f:IFilter)=>void;
  showAutoAssignAwards: ()=>void;
  setProjectsSortedBy: Function;
}

const ProjectsTab = (props: ProjectsTabProps) => {
  const { 
    allRoundProjects, 
    handleProjectClick, 
    projectTags, 
    categoryAwardDict, 
    allRoundCategoryAwards, 
    projectTagDict, 
    projectsWithAward,
    projectSearchVal,
    filteredRoundProjects,
    projectsSortedBy,
    setProjectSearchVal,
    setProjectsSortedBy
  } = props


  const handleExportCsvClick = () => {
    // format allRoundProjects to be the right thing values (csv vs json)
    //ProjectId, Title, Category, Grade, AverageScore, AdjustedScore, AdjustedZScore
    const items = filteredRoundProjects.map(p => {
      return {
        ProjectId: p.ProjectId,
        Title: p.Title,
        Category: p.Category,
        SubCategory: p.SubCategory,
        Grade: p.Grade,
        AverageScore: p.AverageScore,
        AdjustedScore: p.AdjustedScore,
        ZScore: p.AdjustedZScore,
        Students: p.Students?.join(', '),
        CircleName: p.CircleName,
        Schools:p.Schools?.join(', '),
      }
    })
    const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
    const header = Object.keys(items[0])
    const csv = [
      header.join(','), // header row first
      ...items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
    ].join('\r\n')
    
    console.log(csv)
    // downloady stuff here
    FileDownload(csv, 'project_info.csv');

  }




  const mappedAllRoundProjects = filteredRoundProjects.map((project, i) => {
    let zScoreIsOkayArray = project.Scores.filter(score => score.zScoreIsValid)
    let isGoodZScoreFlag = zScoreIsOkayArray.length > 0;
    return (
      <div
        key={`${project.ProjectId} + ${i}`}
        className='bumper-l bumper-out-l soft-border click'
        onClick={() => handleProjectClick(project)}
      >
        <div>
        <div className="flex-between">
          <div>
          {project.ProjectId} - <span className='emph'>{project.Title}</span>
          </div>
          <div className="student-name-list">{project.Students && project.Students.length > 0 && project.Students.map((student:string, sIndex:number)=>{
            return <span key={`student-${sIndex}`}>{student}</span>
          })}</div>
        </div>
        <div className='flex'>

          <div className='bumper-l bumper-out-l'>Average Score: <span className='emph'>{project.AverageScore?.toFixed(2)}</span></div>
          <span style={{ whiteSpace: 'pre' }}>    </span>
          <div className='bumper-l bumper-out-l'>Adjusted Score: <span className='emph'>{project.AdjustedScore?.toFixed(2)}</span></div>
          <span style={{ whiteSpace: 'pre' }}>    </span>
          <div
            className='bumper-l bumper-out-l'
            title={`${!(isGoodZScoreFlag) ? `Not sufficient data to calculate the z-score.` : ''}`}
          >
            Average Z-Score:
                  
            {!(isGoodZScoreFlag)
              ? <span className='emph '><i className="far fa-exclamation-triangle text-warning"></i> </span>
              : <span className={`emph`}>
              {project.AdjustedZScore?.toFixed(2)}
            </span>
            }
          </div>
        </div>
        </div>
        <div className='bumper-out-l flex wrap logo'>
          {projectsWithAward[project.ProjectKey]?.Name 
            &&
              <div className='nice-tag alert-success text-active bumper-sides-l'>
                <i className="fas fa-award"></i>
                {projectsWithAward[project.ProjectKey].Name}
              </div>
          }

          { (project.Tags && project.Tags.length > 0)
            &&  project.Tags
                  ?.filter(tag => projectTagDict[tag])
                  .map((tag, i) => {
                    return(
                      <div 
                        key={`tag-${tag}-${i}`} 
                        className='nice-tag alert-secondary bumper-sides-l'
                      >
                        {projectTagDict[tag].Value}
                      </div>
                    )
                })
          }
        </div>

      </div>
    )
  });





  return (
    <>
    <div>
      <div className='flex-between bumper-l'>
 
      <div className="input-group bumper-sides-out" style={{minWidth:'200px'}}>
            <Select
            autoFocus={true}
            isClearable={true}
            isMulti={true}
            options={props.filterOptions}
              onChange={(e) => setProjectSearchVal({...projectSearchVal, selected: e ? Array.from(e) : []})}

              // className="form-control"
              id="z-index-control2"
              placeholder="Filter"
              value={projectSearchVal.selected ?? []}              
            />
            <span className="input-group-addon">
              <Icon type={IconType.search}/>
            </span>
          </div>

        <div className='flex'>
          <div className="input-group bumper-sides-out">
            <input
              onChange={(e) => setProjectSearchVal({...projectSearchVal, text:e.target.value})}
              className="form-control"
              id="z-index-control1"
              placeholder="search projects"
              value={projectSearchVal.text ?? ''}
            />
            <span className="input-group-addon">
              <Icon type={IconType.search}/>
            </span>
          </div>

          <div className='input-group'>
            <select
              className='form-control'
              value={projectsSortedBy}
              onChange={e => setProjectsSortedBy((e.target.value as ProjectSort))}
            >
              <option value='default'>Project Id</option>
              <option value='averageScore'>Average Score</option>
              <option value='adjustedScore'>Adjusted Score</option>
              <option value='zScore'>Average Z-Score</option>
            </select>
            <span className="input-group-addon">
              <i className="fas fa-sort"></i>
            </span>
          </div>
        </div>
        <div>
          <button
            className='btn btn-default'
            onClick={handleExportCsvClick}
          >
            <Icon type={IconType.download}/> Export to CSV
          </button>
          
          <button
            className='btn btn-primary'
            onClick={props.showAutoAssignAwards}
          >
            <Icon type={IconType.magic}/> Assign Awards
          </button>
        </div>
      </div>
      <div className=''>
          {allRoundProjects.length > 0 ? 'Click a project to get more info' : 'no projects have a score in this round'}
        </div>
      <div
        className='bumper-l'
      >
        {mappedAllRoundProjects}
      </div>
    </div>
    </>
  )
}

export default ProjectsTab