import React, { useEffect, useState } from 'react'


const JudgingRound = (props) => {


  const [isFiltered, setIsFiltered] = useState(false);
  const [val, setVal] = useState<any>()

  useEffect(() => {
    setVal(props.round?.Values);
  }, [props.round?.Values])

  useEffect(() => {
    if (val) {
      if (
        val.FilterCategoryIds?.length > 0 ||
        val.FilterDivisionIds?.length > 0 ||
        val.FilterRoundIds?.length > 0 ||
        val.FilterTagIds?.length > 0 ||
        val.FilterMinAvgScore ||
        val.FilterMinAvgZScore
      ) {
        setIsFiltered(true);
      } else {
        setIsFiltered(false)
      }
    }
  }, [val])

  return (
    <>
      {props.round
        ?
        <div className="judging-round soft-border hover-shadow">
          <div className="title">
            <h3>{props.round.Name}</h3>
            {props.round.IsSubround
              &&
              <i className="fas fa-sort-size-down text-info" title='This is a subround'></i>
            }
            {isFiltered
              &&
              <i className="fas fa-filter text-info" title='This round has a filter'></i>
            }


          </div>
          <p>Round Value: {props.round.RoundValue}</p>
          {(props.round.Values.SchedulesInterviews === 'true' || props.round.Values.SchedulesInterviews === true)
            &&
            <>
              <div><i className='fad fa-calendar-day'></i> Interviews Scheduled</div>
              {props.round.Values.StartDate
                &&
                <div >Judging Starts: {props.round.Values.StartDate} at {props.round.Values.StartTime}</div>
              }
              {props.round.Values.BreakStart1
                &&
                <div >Break: {props.round.Values.BreakStart1} to {props.round.Values.BreakEnd1}</div>
              }
              {props.round.Values.EndDate
                &&
                <div>Judging Ends: {props.round.Values.EndDate} at {props.round.Values.EndTime}</div>

              }
            </>
          }

          {
            props.round.Values.UseRubric && props.rubrics && <div>Rubric: {props.rubrics[props.round.Values.UseRubric]}</div>
          }
          {isFiltered
            && <div>
              Filtered by:
                {val?.FilterCategoryIds?.length > 0 && <span>Category, </span>}
              {val?.FilterDivisionIds?.length > 0 && <span>Division, </span>}
              {val?.FilterRoundIds?.length > 0 && <span>Previous Round, </span>}
              {val?.FilterTagIds?.length > 0 && <span>Tag, </span>}
              {val?.FilterMinAvgScore && <span>Minimum Average Score</span>}
              {val?.FilterMinAvgZScore && <span>Minimum Average Z-Score</span>}
            </div>

          }

          <div className="off-bottom-right">
            <span
              onClick={() => props.openRoundDetails(props.round, props.index)}
              className={`hover-info open-${props.round.Name}`}
            >
              <i className="fad fa-eye fa-3x"></i>
            </span>
            <span
              onClick={() => props.openEdit(props.round, props.index)}
              className={`hover-success edit-${props.round.Name}`}
              >
              <i className="fad fa-edit fa-3x"></i>
            </span>
          </div >
        </div >
        :
        <div className="judging-round soft-border hover-shadow">
          <div className="title">
            <h3>Create a New Round</h3>
          </div>
          <p>Round Value: 0-100</p>
          <div className="off-bottom-right create-round-btn">
            <span
              onClick={() => props.openEdit(null, props.index)}
              className="hover-success"
            >
              <i className="fad fa-plus-circle fa-3x"></i>
            </span>
          </div>
        </div >

      }
    </>
  )
}

export default JudgingRound