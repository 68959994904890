import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { IScore } from '../../redux/judgingResults/types';
import { store } from '../../redux/oldStore';
import Icon, { IconType } from '../Icon/Icon';
import { JudgingQuickComment } from '../JudgingSetup/RubricSetup/QuickComments/QuickComments';
import './JudgingResults.css'

interface AssessmentInfoProps {
  assessment: IScore;
  setModalOpen: Function;

}

interface AssessmentQuickComment extends JudgingQuickComment {
  Type: string;
}

interface AssessmentInfo {
  assessment: any
  rubric: any
  customQuestions: any[]
  quickComments: AssessmentQuickComment[]
}


const AssessmentInfo = (props: AssessmentInfoProps) => {
  const [everything, setEverything] = useState<AssessmentInfo>();

  const rubric = everything?.rubric;
  const thisAssessment = everything?.assessment;
  const customQuestions = everything?.customQuestions;
  const quickComments = everything?.quickComments;

  useEffect(() => {
    store.server.postApiWithServerResponse<AssessmentInfo>('../JudgingResults/AssessmentInfo', { id: props.assessment.scoreId })
      .then((res) => {
        setEverything(res.Value);
      })
      .catch(err => {
        toast.error('something went wrong');
        console.error(err);
      })
  }, [props.assessment.scoreId])

  const customQuestionsDisplay = () => {
    const mappedQuestions = customQuestions?.map((x, i) => {
      return (
        <React.Fragment key={`${x.question}-${i}`}>
          <dt>{x.question}</dt>
          <dd>{x.Answer ?? "Unanswered"}</dd>
        </React.Fragment>
      )
    });
    return (
      <>
        <h4>Custom Questions</h4>
        <dl>
          {mappedQuestions}
        </dl>
      </>
    )
  };

  const quickCommentsDisplay = () => {
    let sortedArr = _.orderBy(quickComments?.filter(x => Boolean(x.Type) && Boolean(x.Active)), ['ParentGroup', 'ParentGroupSub', 'Comment'], ['asc', 'desc', 'asc'])

    let groupedStuff = _.groupBy(sortedArr, 'ParentGroup');

    // let quickCommentObject = sortedArr.reduce((fullCommentObj, comment) => {
    //   return {
    //     ...fullCommentObj,
    //     [comment.ParentGroup]: {
    //       ...fullCommentObj?.[comment.ParentGroup],
    //       [comment.ParentGroupSub]: [
    //         [...fullCommentObj?.[comment.ParentGroup]?.[comment.ParentGroupSub], comment],
    //       ]
    //     }
    //   }
    // }, {})

    // console.log(quickCommentObject)

    // const mappedQuickComments = sortedArr?.map((x, i)=> {
    //   return (
    //     <React.Fragment key={`${x}-${i}`}>
    //       <dt>{x.ParentGroup} - {x.ParentGroupSub} - {x.Comment}</dt>
    //       <dd>{x.Type}</dd>
    //     </React.Fragment>
    //   )
    // });

    return (
      <>
        <h3>Quick Comments</h3>
        <dl>
          {Object.entries(groupedStuff).map((commentGroup, i) => {
            return (
              <div className='bummper-left'>
                <h4>{commentGroup[0]}</h4>
                {Object.entries(_.groupBy(commentGroup[1], 'ParentGroupSub')).map((subCommentGroup, i) => {
                  return(
                    <div className='bummper-left'>
                    <h5><strong>{(subCommentGroup[0] === 'null' ? null : subCommentGroup[0])}</strong></h5>
                    {subCommentGroup[1].map((comment, i) => {
                      return (<div className='bummper-left'>
                        <p>{comment.Comment} - {<>
                          {(comment.Type === 'Strong') && <Icon type={IconType.thumbsUp} />}
                          {(comment.Type === 'NeedsFocus') && <Icon type={IconType.thumbsDown} />}
                          </>
                        }</p>
                      </div>)
                    })}
                  </div>)
                })}
              </div>
            )
          })
          }
          {/* {mappedQuickComments} */}
        </dl>
      </>
    )
  }

  return (
    <>
        {rubric?.Categories
          &&
          <div>
            {rubric.Categories.map((category, i: number) => {
              return (
                <div className='bumper-l' key={`${category.Id}-${i}`}>
                  {thisAssessment.noShow && <div> <i className="fa fa-siren-on text-warning"></i> No Show</div>}
                  <div className='flex-between'>
                    <h4 className='bumper-sides-out'>{category.Value}% {category.Name}: </h4>
                    <div className='bumper-sides-out text-muted'>{(thisAssessment.categoryTotals[category.Id])?.toFixed(2)}%</div>
                  </div>
                  {category.Assessments
                    && (
                      category.Assessments.map((question, i) => {
                        return (
                          <div key={i} className='flex-between bumper-l'>
                            <h5 className='bumper-sides-out'> - {question.Question}: </h5>
                            <div className='bumper-sides-out'>
                              {(thisAssessment.assessmentScores[question.Id] < -999)
                                ? <>N/A</>
                                : (question.MinValue === 0 && question.MaxValue === 1)
                                  ? (thisAssessment.assessmentScores[question.Id] === 1 ? <>Yes</> : <>No</>)
                                  : (thisAssessment.assessmentScores[question.Id] + 1)
                              }
                            </div>
                          </div>
                        )
                      })
                    )
                  }
                </div>
              )
            })}
            {thisAssessment.JudgesNotes && true &&
              <div className='flex-between'>
                <div>Notes: </div>
                <div>{thisAssessment.JudgesNotes}</div>
              </div>
            }

          </div>
        }
        {customQuestionsDisplay()}
        {quickCommentsDisplay()}
        <hr/>
        <div className='flex-between'>
          <div className="text-muted">Assessment Id: {props.assessment.scoreId}</div>
          {thisAssessment && <div>{thisAssessment.At}</div>}
        </div>
        
    </>
  )
}

export default AssessmentInfo