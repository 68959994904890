import React, {useState, useEffect} from 'react';
import { toast } from 'react-toastify';
import { store } from '../../redux/oldStore';
import { ServerResponse } from '../../utils/Server';
import Icon, { IconType } from '../Icon/Icon';
import Modal from '../Modal/Modal';

interface IRequestModeratorInterpreterProps{
    languages:string[],
    meetingId:string,
    settings:any,
}

const RequestModeratorInterpreter = (props:IRequestModeratorInterpreterProps) => {
    const [showForm, setShowForm] = useState<boolean>(false);
    const [type, setType] = useState<string>();
    const [language, setLanguage] = useState<string>();
    
    const requestHelp = () =>{

        if(!type) {
            toast.warning('You need to select a help type.');            
            return;
        }

        if(type === 'Interpreter' && !language){
            toast.warning('You need to select a language.');  
            return;
        }

        store.server.getApi<ServerResponse<boolean>>(`../WaitingRoom/RequestHelp?type=${type}&language=${language}&meetingId=${props.meetingId}`)
        .then(x=>{
            if(x.Success){
                toast.info(`A "${type}" has been requested.`);
                setShowForm(false);
            } else {
                toast.error(x.Message, {autoClose:false});
            }
        });
    };


    return (
        <>
            <div className='request-mod-or-interpreter' onClick={()=>{setShowForm(true);}}>
            <Icon type={IconType.language} /> Interpreter 
            <br/>
             <Icon type={IconType.balanceScaleRight} /> Proctor
                
            </div>
            {showForm && <Modal title='Request Proctor or Interpreter' size='m' setModalOpen={setShowForm}>
                <div className='form-horizontal'>
                <div>
                            {props.settings?.text.ProctorInterpreterRequest && 
                            <div dangerouslySetInnerHTML={{__html:props.settings.text.ProctorInterpreterRequest}}></div>}
                        </div>
                    <div className='form-group'>
                        <label className='col-sm-4 control-label' htmlFor='request-m-i-type'>Type</label>
                        <div className='col-sm-8'>
                            <select className='form-control' id='request-m-i-type'
                                value={type ?? ''}
                                onChange={(e)=>{setType(e.target.value)}}
                             >
                                 <option value={''}>select</option>
                                 <option value={'Moderator'}>Proctor</option>
                                 <option value={'Interpreter'}>Interpreter</option>
                             </select>
                        </div>
                    </div>
                    {type == 'Interpreter' && <div className='form-group'>
                        <label className='col-sm-4 control-label' htmlFor='request-m-i-language'>Language</label>
                        <div className='col-sm-8'>
                            <select className='form-control' id='request-m-i-language'
                                value={language ?? ''}
                                onChange={(e)=>{setLanguage(e.target.value)}}
                             >
                                 <option value={''}>select</option>
                                {props.languages.map((x,i)=>{
                                    return <option key={`rmi-lang-${i}`}>{x}</option>
                                })}
                             </select>
                        </div>
                    </div>}
                    <div className='form-group'>
                        <div className='col-sm-offset-4 col-sm-8'>
                            <button type='button' className='btn btn-secondary' onClick={requestHelp} >
                                <Icon type={IconType.handsHelping} /> Request
                            </button>
                            <button type='button' className='btn btn-default' onClick={()=>{setShowForm(false);}} >
                                <Icon type={IconType.close} /> Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>}
        </>
    );
}

export default RequestModeratorInterpreter;