import { useEffect, useState } from 'react';
import { IState } from '../../../redux/redux';
import { ServerResponse } from '../../../utils/Server';
import StoreX, { store } from '../../../redux/oldStore';
import { IJudgeGroup, IJudgeGroupInfo } from './JudgeGroupTypes';
import { toast } from 'react-toastify';
import Icon, { IconType } from '../../Icon/Icon';
import Modal from '../../Modal/Modal';
import JudgeGroupEditor from './JudgeGroupEditor';

interface IProps {
  state: IState;
}

const JudgeGroupManager = (props: IProps) => {
  const [info, setInfo] = useState<IJudgeGroupInfo>();
  const [showEditJudgeGroup, setShowEditJudgeGroup] = useState<boolean>(false);
  const [selectedJudgeGroup, setSelectedJudgeGroup] = useState<IJudgeGroup>();
  const [categoryDic, setCategoryDic] = useState<Record<string, string>>({});
  const [divisionDic, setDivisionDic] = useState<Record<string, string>>({});

  useEffect(() => {
    Init();
  }, [props.state.IsReady]);

  const Init = () => {
    toast.dismiss();
    store.server.getApi<ServerResponse<IJudgeGroupInfo>>(`../JudgeGroup/Info`).then((x) => {
      if (x.Success) {
        setInfo(x.Value);
        let cDic = {};
        let dDic = {};
        x.Value.Categories.forEach((c) => {
          cDic[c.id] = c.name;
        });
        x.Value.Divisions.forEach((c) => {
          dDic[c.id] = c.name;
        });
        setCategoryDic(cDic);
        setDivisionDic(dDic);
        console.log(x.Value);
      } else {
        toast.error(x.Message, { autoClose: false });
      }
    });
  };

  const mapJudgeGroup = () => {
    if (!info || info.JudgeGroups.length === 0) {
      return (
        <div>
          <h4>
            <Icon type={IconType.warning} /> No Judge Groups
          </h4>
          No judge groups have been created, press add some so I'm not so lonely.
        </div>
      );
    } else {
      return (
        <>
          {info.JudgeGroups.map((x, i) => {
            let category = categoryDic[x.Category + ''];
            let division = divisionDic[x.Division + ''];
            return (
              <div className="judge-group-list-item" key={`jgli-oj-${i}`}>
                <div className="flex-between">
                  <div>
                    <div className="name">
                      <span
                        className="click"
                        onClick={() => {
                          setShowEditJudgeGroup(true);
                          setSelectedJudgeGroup(x);
                        }}>
                        <Icon type={IconType.edit} />
                      </span>
                      {x.Name}
                    </div>
                    <div>
                      {props.state.Settings?.isHistoryContest && (
                        <>
                          <a href={StoreX.BuildUrlWithFair(`../JudgePortal/JudgeGroup/${x.Id}`)} target="_blank">
                            View Assignments
                          </a>
                        </>
                      )}
                    </div>
                  </div>
                  <div>
                    {category && (
                      <div className="text-right text-info" title="Category">
                        {category}
                      </div>
                    )}
                    {division && (
                      <div className="text-right text-info" title="Division">
                        {division}
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  {x.Judges && x.Judges.length > 0 && (
                    <div className="flex">
                      {x.Judges.map((j, ji) => {
                        let judge = info.JudgesDic[j.PersonId];
                        return (
                          <div className="judge" key={`jgli-oj-${i}-${ji}`}>
                            <Icon type={j.Captain ? IconType.userCrown : IconType.user} /> {judge?.FirstName ?? 'Unknown'} {judge?.LastName}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </>
      );
    }
  };

  return (
    <>
      {info && (
        <div>
          <div className="flex-between">
            <h4>Judge Groups</h4>
            <div>
              <button
                type={'button'}
                className="btn btn-default"
                onClick={() => {
                  setShowEditJudgeGroup(true);
                  setSelectedJudgeGroup({ Id: 0, Judges: [] });
                }}>
                <Icon type={IconType.plus} /> Add Judge Group
              </button>
            </div>
          </div>
          <div className="judge-group-list">{mapJudgeGroup()}</div>
        </div>
      )}

      {showEditJudgeGroup && selectedJudgeGroup && (
        <Modal setModalOpen={setShowEditJudgeGroup} size="l" title={`Judge Group Manager`}>
          <JudgeGroupEditor
            state={props.state}
            judgeGroupId={selectedJudgeGroup.Id ?? 0}
            onClose={() => {
              setShowEditJudgeGroup(false);
            }}
            onRemove={() => {
              if (info) {
                setInfo({ ...info, JudgeGroups: info.JudgeGroups.filter((x) => x.Id !== selectedJudgeGroup.Id) });
              }
              setShowEditJudgeGroup(false);
              setSelectedJudgeGroup(undefined);
            }}
            onUpdate={(judgeGroup) => {
              if (info) {
                let groups = info.JudgeGroups ?? [];
                let found = false;
                for (let i = 0; i < groups.length; i++) {
                  if (judgeGroup.Id === groups[i].Id) {
                    found = true;
                    groups[i] = judgeGroup;
                    break;
                  }
                }
                if (!found) {
                  groups.push(judgeGroup);
                }

                setInfo({ ...info, JudgeGroups: [...groups] });
              }
              setSelectedJudgeGroup(judgeGroup);
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default JudgeGroupManager;
