import { useEffect, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Id, toast } from "react-toastify";
import { store } from "../../../redux/oldStore";
import { Selectable } from "../../../utils/CommonTypes/CommonTypes";
import { ServerResponse } from "../../../utils/Server";
import Icon, { IconType } from "../../Icon/Icon";
import Modal from "../../Modal/Modal";
import ConfigFormLanding from "../../Setup/ConfigForm/ConfigFormLanding";
import { IAwardInfo, IAwardsEntryBasic, ISpecialAward } from "./AwardTypes";
import Select from "react-select";
import { entries } from "lodash";


interface IAssignSpecialAwardsProps {

}

interface ISearch{
    awardText?:string;
    division?:number;
}

interface IAssign{
    award?:ISpecialAward;
    subType?:string;
    entries?:Selectable[];
}

export const AssignSpecialAwards = (props:IAssignSpecialAwardsProps) => {
    const [tabIndex, setTabIndex] = useState<number>(0);
    const [info, setInfo] = useState<IAwardInfo>();
    const [filteredAwards, setFilteredAwards] = useState<ISpecialAward[]>([]);
    const [search, setSearch] = useState<ISearch>({});
    const [entryDic, setEntryDic] = useState<Record<string,IAwardsEntryBasic>>({});
    const [showAssign, setShowAssign] = useState<boolean>(false);
    const [assign, setAssign] = useState<IAssign>({});    
    const [entriesSelectable, setEntriesSelecatable] = useState<Selectable[]>([]);

    useEffect(()=>{
        init();
    },[]);

    

    useEffect(()=>{
        if(info){
            let awards = info.SpecialAwards.filter(x=>searchAwards(x, search));
            setFilteredAwards(awards);
        }
    },[info?.SpecialAwards, search]);

    const searchAwards = (award:ISpecialAward, filter:ISearch) =>{

        if(filter.awardText && award.Name.toLowerCase().indexOf(filter.awardText.toLowerCase()) === -1) return false;
        if(filter.division && !award.Divisions.find(x=>x === filter.division)) return false;

        return true;
    };

    const init = () =>{
        store.server.getApi<ServerResponse<IAwardInfo>>(`../Awards/Info`)
        .then(x=>{
            if(x.Success){
                setInfo(x.Value);
                let dic:Record<string,IAwardsEntryBasic> ={};
                let es:Selectable[] = [];

                x.Value?.Entries.forEach(e=>{
                    dic[e.Id] = e;
                    es.push({label:`${e.ProjectId} ${e.Title.substring(0, 50)}`, value:e.Id})
                });

                setEntryDic(dic);
                setEntriesSelecatable(es);
            } else {
                toast.error(x.Message, {autoClose:false});
            }
        });
    };

    const save = () =>{
        
        if(!assign.award) {
            toast.warning('Please select the award you wish to assign.');
            return;
        }

        if(!assign.entries || assign.entries.length == 0){
            
            toast.warning('Please select this award should be assigned too.');
            return;
        }

        store.server.postApi<ServerResponse<boolean>>(`../Awards/UpdateSpecialAward`, {id:0, delete:false, awardId:assign.award.Id, subType:assign.subType, ids:assign.entries.map(x=>x.value)})
        .then(x=>{
            if(x.Success){

                if(info){
                let i = info;
                    let awards = i.AssignedSpeicalAwards ?? [];
                    assign.entries?.forEach(e=>{
                        awards.push({AwardId:assign.award?.Id, EntryId:+e.value, SubType:assign.subType});
                    });
                    setInfo({...i, AssignedSpeicalAwards:awards});
                    setAssign({});
                    setShowAssign(false);
                }

                toast.info(`award assigned.`);
            } else {
                toast.error(x.Message, {autoClose:false});
            }
        });
    
    
    };

    const remove = (entry:IAwardsEntryBasic, award:ISpecialAward, subType:string|undefined)=>{
        if(!window.confirm(`Are you sure you want to remove "${award.Name}" from ${entry.ProjectId}?`)) return;

        store.server.postApi<ServerResponse<boolean>>(`../Awards/UpdateSpecialAward`, {id:entry.Id, delete:true, awardId:award.Id, subType:subType})
        .then(x=>{
            if(x.Success){

                if(info){
                let i = info;

                    setInfo({...i, AssignedSpeicalAwards:i?.AssignedSpeicalAwards.filter(x=>!(x.AwardId === award.Id && x.EntryId === entry.Id && x.SubType === subType))});
                }

                toast.info(`Award "${award.Name}" was removed from ${entry.ProjectId}.`);
            } else {
                toast.error(x.Message, {autoClose:false});
            }
        });
    };

    const specialAwardComponent = (award:ISpecialAward, i:number)=>{

        return <div className="special-award-component" key={`asa-swc-${i}`}>
            <div className="flex-between header">
                <h4>{award.Name}</h4>
                <div>
                    <button type="button" className="btn btn-default btn-xs" onClick={()=>{setShowAssign(true); setAssign({award:award})}}><Icon type={IconType.plus}/> Assign Awards</button>
                </div>
            </div>
            <div className="assigned-entires">
                {info?.AssignedSpeicalAwards.filter(x=>x.AwardId === award.Id).map((x,ai)=>{
                    let entry = entryDic[x.EntryId ??''];
                    if(entry)
                    return <div key={`asa-swc-${i}-${ai}`} className="assigned-entry flex-between">
                        <div>
                            <span className="click text-danger" title="remove assigned award" onClick={()=>{remove(entry, award, x.SubType)}}><Icon type={IconType.trashO} /></span>
                            <strong>{entry.ProjectId}</strong> <span>{entry.Title} </span></div>
                        <span>{entry.Category}</span>
                    </div>
                })}
            </div>
        </div>

    };


    return (<>

        <h3>Speical Awards</h3>

        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}  >
            <TabList>
                <Tab><Icon type={IconType.award} /> Assign</Tab>
                <Tab><Icon type={IconType.configure} /> Configure</Tab>
            </TabList>
            <TabPanel>
                <div className="form-horizontal sticky">
                    <div className="form-group">
                        <label className="control-label col-sm-4" htmlFor="asa-s-text">Search</label>
                        <div className="col-sm-6">
                            <input
                                id="asa-s-text" 
                                type="text"
                                className="form-control"
                                autoFocus={true}
                                maxLength={250}
                                onChange={(e)=>{setSearch({...search, awardText:e.target.value});}}
                                value={search.awardText ?? ''}

                                />
                        </div>
                        <div className="col-sm-2">
                        <button type="button" className="btn btn-secondary" onClick={()=>{setShowAssign(true)}}><Icon type={IconType.plus}/> Assign Awards</button>
                        </div>
                    </div>
                </div>
                    

                <div></div>
                <div className="special-award-container">
                    {filteredAwards.map((x,i)=>{
                        return specialAwardComponent(x,i)
                    })}
                </div>
            </TabPanel>
            <TabPanel>
                <ConfigFormLanding type="awards-special" />
            </TabPanel>
        </Tabs>


{info&& showAssign && <Modal setModalOpen={setShowAssign} title={"Assign Special Award"} size={"l"}>
                <div className="form-horizontal">
                    <div className="form-group">
                        <label className="control-label col-sm-4" htmlFor="form-sa-award">Award</label>
                        <div className="col-sm-6">
                            <select
                                id="form-sa-award" 
                                className="form-control"
                                autoFocus={true}
                                onChange={(e)=>{
                                    let a = info.SpecialAwards.find(x=>x.Id === +e.target.value);
                                    setAssign({...assign, award:a});}
                                }
                                value={assign.award?.Id ?? "0"}

                                >
                                    <option value={"0"}>select</option>
                                    {info.SpecialAwards.sort((a,b)=>{return a.Name > b.Name ? 1:-1;}).map((x,i)=>{
                                        return <option key={`form-sa-award-option-${i}`} value={`${x.Id}`}>{x.Name}</option>
                                    })}
                                </select>
                        </div>
                    </div>
                    
                    <div className="form-group">
                        <label className="control-label col-sm-4" htmlFor="form-sa-sub-award">Sub Type</label>
                        <div className="col-sm-6">
                            <select
                                id="form-sa-sub-award" 
                                className="form-control"
                                
                                onChange={(e)=>{
                                    let a = e.target.value;

                                    setAssign({...assign, subType: a == "-0" ? undefined : a});}
                                }
                                value={assign.subType ?? "-0"}

                                >
                                    <option value={"-0"}>select</option>
                                    {assign.award?.SubAwards?.map((x,i)=>{
                                        return <option key={`form-sa-award-sub-option-${i}`} value={`${x}`}>{x}</option>
                                    })}
                                </select>
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="control-label col-sm-4" htmlFor="form-sa-entries">Entries</label>
                        <div className="col-sm-8">
                                        
                        <Select
                            inputId={"form-sa-entries"}
                            onChange={(values: Selectable[]) => {
                                setAssign({...assign, entries:values})
                            }}
                            value={assign.entries ?? []}
                            options={entriesSelectable}
                            className={`col-sm-12 row config-multi multi-select`}
                            isMulti={true}
                            isClearable={true}
                            menuPlacement={'auto'}
                            // menuIsOpen={true}
                            classNamePrefix='cool'  />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="col-sm-8 col-sm-offset-4">
                            <button type="button" className="btn btn-secondary" onClick={save}><Icon type={IconType.save} /> Assign Award(s)</button>
                        </div>
                    </div>
                </div>

</Modal>}

    </>);
}