import { useEffect, useState } from 'react';
import { IListItem, ITemplate, ITemplateRoom, ItemplateTime } from './NhdTypes';
import Icon, { IconType } from '../../Icon/Icon';
import NhdScheduleRoomManager from './NhdScheduleRoomManager';
import Modal from '../../Modal/Modal';
import { toast } from 'react-toastify';
import NhdScheduleTimeManager from './NhdScheduleTimeManager';
import { FormatDate } from '../../../utils/Tools';
import { store } from '../../../redux/oldStore';
import { ServerResponse } from '../../../utils/Server';

interface IProps {
  template: ITemplate;
  categories: IListItem[];
  divisions: IListItem[];
  onRemove: (t: ITemplate) => void;
  onUpdate: (t: ITemplate) => void;
}

const NhdScheduleManager = (props: IProps) => {
  const [template, setTemplate] = useState<ITemplate>({});
  const [activeRoom, setActiveRoom] = useState<ITemplateRoom>({});
  const [activeRoomIndex, setActiveRoomIndex] = useState<number>(-1);
  const [showRoom, setShowRoom] = useState<boolean>(false);
  const [activeTime, setActiveTime] = useState<ItemplateTime>({});
  const [activeTimeIndex, setActiveTimeIndex] = useState<number>(-1);
  const [showTime, setShowTime] = useState<boolean>(false);

  useEffect(() => {
    if (props.template) {
      setTemplate(props.template);
    }
  }, [props.template]);

  const onSave = ()=>{
    //check
    let errors:string[] = [];

    if(!template.sessionTimeInMinutes || template.sessionTimeInMinutes <= 0) errors.push('Session time must be set. This is the number of minutes for each entry to be judged.');
    if(!template.name || template.name.length === 0) errors.push('Please name this template.');

    if(errors.length > 0){
        toast.warning(<>{errors.map(x=>{return <div>{x}</div>})}</>);
        return;
    }

    template?.times?.forEach(x=>{
      x.day = FormatDate(x.day, 'input', true);
    });
    store.server.postApi<ServerResponse<ITemplate>>('../ClientNhdJudging/UpdateTemplate', template).then(x=>{
        if(x.Success){
            toast.success(`Template "${template.name}" saved!`);
            setTemplate(x.Value);
            props.onUpdate(x.Value);
        } else {
            toast.error(x.Message, {autoClose:false});
        }
    });
  };

  const onRemove = () =>{
    if(window.confirm(`Are you sure you want to remove this schedule template?`)){

        store.server.postApi<ServerResponse<ITemplate>>('../ClientNhdJudging/RemoveTemplate', template).then(x=>{
            if(x.Success){
                toast.success(`Template "${template.name}" removed!`);
                setTemplate(x.Value);
                props.onRemove(template);
            } else {
                toast.error(x.Message, {autoClose:false});
            }
        });
        props.onRemove(template);
    }
  };

  const updateRoom = (room: ITemplateRoom, index: number) => {
    let rooms = [...(template.rooms ?? [])];

    if (index === -1) {
      setActiveRoom(room);
      rooms.push(room);
    } else {
      rooms[index] = room;
    }
    setTemplate({ ...template, rooms: rooms });
    setShowRoom(false);
    setActiveRoom({});
  };

  const removeRoom = (room: ITemplateRoom, index: number) => {
    let rooms: ITemplateRoom[] = [];

    template.rooms?.forEach((x, i) => {
      if (i !== index) rooms.push(x);
    });

    setTemplate({ ...template, rooms: rooms });
    setShowRoom(false);
    setActiveRoom({});
  };

  const updateTime = (time: ItemplateTime, index: number) => {
    let times = [...(template.times ?? [])];

    if (index === -1) {
      setActiveTime(time);
      times.push(time);
    } else {
      times[index] = time;
    }
    setTemplate({ ...template, times: times });
    setShowTime(false);
    setActiveTime({});
  };

  const removeTime = (time: ItemplateTime, index: number) => {
    let times: ItemplateTime[] = [];

    template.times?.forEach((x, i) => {
      if (i !== index) times.push(x);
    });

    setTemplate({ ...template, times: times });
    setShowTime(false);
    setActiveTime({});
  };

  return (
    <>
      <div className="form-horizontal">
        <div className="form-group">
          <label className="control-label col-sm-4" htmlFor="nhd-template-name">
            Template Name
          </label>
          <div className="col-sm-6">
            <input
              id="nhd-template-name"
              className="form-control"
              value={template.name ?? ''}
              onChange={(x) => {
                setTemplate({ ...template, name: x.target.value });
              }}
            />
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-4" htmlFor="nhd-template-cateogry">
            Category
          </label>
          <div className="col-sm-6">
            <select
              id="nhd-template-cateogry"
              className="form-control"
              value={template.category ?? 0}
              onChange={(x) => {
                setTemplate({ ...template, category: +x.target.value });
              }}>
              <option value={0}></option>
              {props.categories.map((x, i) => {
                return <option value={x.id}>{x.name}</option>;
              })}
            </select>
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-4" htmlFor="nhd-template-division">
            Division
          </label>
          <div className="col-sm-6">
            <select
              id="nhd-template-division"
              className="form-control"
              value={template.division ?? 0}
              onChange={(x) => {
                setTemplate({ ...template, division: +x.target.value });
              }}>
              <option value={0}></option>
              {props.divisions.map((x, i) => {
                return <option value={x.id}>{x.name}</option>;
              })}
            </select>
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-4" htmlFor="nhd-template-session-time">
            Session Time
          </label>
          <div className="col-sm-6">
            <input
              id="nhd-template-session-time"
              className="form-control"
              type={'number'}
              min={0}
              max={120}
              placeholder="session time in minutes"
              value={template.sessionTimeInMinutes}
              onChange={(x) => {
                setTemplate({ ...template, sessionTimeInMinutes: +x.target.value });
              }}
            />
          </div>
        </div>

        <h3>Location Information</h3>
        <div className="form-group">
          <label className="control-label col-sm-4">Rooms</label>
          <div className="col-sm-8">
            {template.rooms && template.rooms.length > 0 ? (
              <>
                <table className="table table-striped">
                  <tr>
                    <th>Building</th>
                    <th>Room</th>
                    <th>Max Entries</th>
                    <th>
                        <button type={'button'} className='btn btn-success btn-xs' onClick={()=>{
                            setShowRoom(true);
                            setActiveRoom({maxNumberOfEntries:10});
                            setActiveRoomIndex(-1);
                        }}>
                            <Icon type={IconType.plus}/> Add Room
                        </button>
                    </th>
                  </tr>
                  {template.rooms.map((x, i) => {
                    return (
                      <tr key={`ndhtrr-${i}`}>
                        <td>{x.building ?? ''}</td>
                        <td>{x.name ?? ''}</td>
                        <td>{x.maxNumberOfEntries ?? 0}</td>
                        <td>
                          <span
                            className="click"
                            onClick={() => {
                              setActiveRoom(x);
                              setActiveRoomIndex(i);
                              setShowRoom(true);
                            }}>
                            <Icon type={IconType.edit} />
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </>
            ) : (
              <>
                <div className="alert alert-warning">
                  <h4>
                    <Icon type={IconType.warning} /> No Rooms
                  </h4>
                  Please add at least one room.
                </div>
                <button type={'button'} className='btn btn-success' onClick={()=>{
                            setShowRoom(true);
                            setActiveRoom({maxNumberOfEntries:10});
                            setActiveRoomIndex(-1);
                        }}>
                            <Icon type={IconType.plus}/> Add Room
                        </button>
              </>
            )}
          </div>          
        </div>
        
        <h3>Schedule Information</h3>
        <div className="form-groups">
          <label className="control-label col-sm-4">Times</label>
          <div className="col-sm-8">

          {template.times && template.times.length > 0 ? (
              <>
                <table className="table table-striped">
                  <tr>
                    <th></th>
                    <th>Date</th>
                    <th>Start</th>
                    <th>End</th>
                    <th>
                        <button type={'button'} className='btn btn-success btn-xs' onClick={()=>{
                            setShowTime(true);
                            setActiveTime({});
                            setActiveTimeIndex(-1);
                        }}>
                            <Icon type={IconType.plus}/> Time Slot
                        </button>
                    </th>
                  </tr>
                  {template.times.map((x, i) => {
                    return (
                      <tr key={`ndhtrr-${i}`}>
                        <td>{x.isBreak ? 'Break': 'Judging'}</td>
                        <td>{FormatDate(x.day, 'date', true)}</td>
                        <td>{x.startTime}</td>
                        <td>{x.endTime}</td>
                        <td>
                          <span
                            className="click"
                            onClick={() => {
                              setActiveTime(x);
                              setActiveTimeIndex(i);
                              setShowTime(true);
                            }}>
                            <Icon type={IconType.edit} />
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </>
            ) : (
              <>
                <div className="alert alert-warning">
                  <h4>
                    <Icon type={IconType.warning} /> No Time Slots
                  </h4>
                  Please add at least one time slot.
                </div>
                <button type={'button'} className='btn btn-success' onClick={()=>{
                            setShowTime(true);
                            setActiveTime({});
                            setActiveTimeIndex(-1);
                        }}>
                            <Icon type={IconType.plus}/> Add Time Slot
                        </button>
              </>
            )}

          </div>
        </div>

        <div className="form-group">
          <div className="col-sm-8 col-sm-offset-4">
          <button type={'button'} className="btn btn-secondary" onClick={onSave}>
              <Icon type={IconType.save} /> Save
            </button>
            <button type={'button'} className="btn btn-danger" onClick={onRemove}>
              <Icon type={IconType.delete} /> Remove
            </button>
          </div>
        </div>
      </div>

      {showRoom && activeRoom && (
        <Modal setModalOpen={setShowRoom} title="Room Manager" size="m">
          <NhdScheduleRoomManager room={activeRoom} index={activeRoomIndex} onUpdate={updateRoom} onRemove={removeRoom} />
        </Modal>
      )}
      {showTime && activeTime && (
        <Modal setModalOpen={setShowTime} title="Time Manager" size="m">
          <NhdScheduleTimeManager time={activeTime} index={activeTimeIndex} onUpdate={updateTime} onRemove={removeTime} />
        </Modal>
      )}
    </>
  );
};

export default NhdScheduleManager;
