import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import StoreX,{store} from '../../redux/oldStore';
import { ServerResponse } from '../../utils/Server';
import Icon, { IconType } from '../Icon/Icon';

interface iConfirmEmailResponse{
    CanSkip:boolean;
    Confirmed:boolean;
    GoTo:string;
    FirstName:string;
    Email:string;
}

interface iconfirmEmailProps {

}

const ConfirmEmail = (props:iconfirmEmailProps)=>{
    const {targetId} = useParams();
    const [response, setResponse] = useState<iConfirmEmailResponse>();
    const [showConfirmed, setshowConfirmed] = useState<boolean>();

    useEffect(()=>{
        let request = {
            send:true
        };

        sendServerRequest(request, ()=>{});

    }, []);

    useEffect(()=>{
        if(response){
            if(response.Confirmed && response.GoTo){
                window.location.href = response.GoTo;
            }
            else if (response.Confirmed){
                setshowConfirmed(true);
            }
        }

    }, [response]);

    const sendServerRequest = (request, callback:Function) =>{
        request.userId = targetId;
        request.email = StoreX.GetQueryStringValue("email");
        request.confirm = (StoreX.GetQueryStringValue("confirm") && true);      
        console.log(request);

        store.server.postApiWithServerResponse<iConfirmEmailResponse>('../userProfile/EmailIsVerified', request)
        .then(x=>{
           if(x.Success) {
               setResponse(x.Value);
           }
           callback(x);
        });
    };

    const resendClick = () =>{
        let request = {
            send:true,forceSend:true
        };
        sendServerRequest(request, (x:ServerResponse<iConfirmEmailResponse>)=>{
            if(x.Success){
                toast.success(`Confirmation email was resent.`);
            } else {
                toast.error(`There was an error sending your confirmation eamil.`);
            }
        });
    };
    const bypassClick = () =>{
        let request = {
            bypass:true
        };
        sendServerRequest(request, ()=>{});
    };
    const continueClick = () =>{
        let request = {
        };
        sendServerRequest(request, (x:ServerResponse<iConfirmEmailResponse>)=>{
            if(x.Success && x.Value.Confirmed){
                toast.success(`Looks like you are good to go. Should advance to the next step shortly.`);
            } else {
                toast.error(`Hmm, looks like we are still trying to verify your email.`);
            }
        });
    };

    

    return (<>
    {!showConfirmed && <div className="col-sm-12">
    <h3>Verify Email Address</h3>
    {response && <>
               Hi {response.FirstName},<br/>
               <p>
               We need to verify that you can receive our emails. We have sent an email to {response.Email}.
                   Check your email for a message from zFairs. If you don't see it, please check your spam folder.
               </p>
               <br/>
               <button type="button" className="btn btn-primary" title="resend email" onClick={resendClick} >
                   <Icon type={IconType.envelopeO}/> Resend Email</button>
               <button type="button" className="btn btn-secondary" title="continue" onClick={continueClick} >
                   <Icon type={IconType.paperPlane}/> Continue</button>
               {response.CanSkip && <button type="button" className="btn btn-default" title="Bypass email confirmation" onClick={bypassClick} >
                   <Icon type={IconType.skip}/> Bypass Confirm Email</button>}
    </>}
    </div>}
    {showConfirmed && <div>
            <h3>Email Confirmed, Thank you.</h3>
        </div>}
    </>);
};

export default ConfirmEmail;