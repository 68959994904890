import { useEffect, useState } from 'react';
import redux, { IState } from '../../redux/redux';
import Icon, { IconType } from '../Icon/Icon';
import UserLogin from '../UserLogin/UserLogin';
import Button from '../_Core/Button';
import { IBranchTask } from '../../redux/types';
import Modal from '../Modal/Modal';
import BranchPortal from '../Setup/Branches/BranchPortal';
import StoreX, {store} from '../../redux/oldStore';
import { ServerResponse } from '../../utils/Server';
import { toast } from 'react-toastify';
import { FormatDate } from '../../utils/Tools';

interface IProps {
  state: IState;
  type: string;
}

interface IWidgetData {}

interface IImportantDateResponse{
  data: IImportantDate[];
}

interface IImportantDate{
  PublicId?: string;
  Order?:number;
  Date?:string;
  IncludeInScheduleWidget?:boolean;
  Name?:string;
  SubLabel?:string;
  Group?:string;
}
interface IImportantLinkResponse{
  data: IImportantLink[];
}

interface IImportantLink{
  ExternalLink?:string;
  FlexLink?:string;
  Name?:string;
  OpenInNewTab?:boolean;
  Order?:number;
  PublicId?:string;
  SystemLink?:string;
  Icon?:string;
}

const Widget = (props: IProps) => {
  const [data, setData] = useState<IWidgetData>();
  const [profileImageFailedToLoad, setProfileImageFailedToLoad] = useState<boolean>();
  const [selectedBranch, setSelectedBranch] = useState<IBranchTask>();
  const [showBranch, setShowBranch] = useState<boolean>(false);

  const [dateWidgetData, setDateWidgetData] = useState<IImportantDateResponse>();
  const [linkWidgetData, setLinkWidgetData] = useState<IImportantLinkResponse>();
  const [dateGroups, setDateGroups] = useState<string[]>([]);

  useEffect(() => {
    init();
  }, []);

  const logout = () => {
    redux.NotReady();
    window.location.href = StoreX.BuildUrlWithFair('../Logout.aspx');
  };

  const init = () => {
    switch (props.type.toUpperCase()) {
      case 'LINKS':
        store.server.postApi<ServerResponse<IImportantLinkResponse>>(`../Important/Data`, {type:'link'})
        .then((x) => {
          if (x.Success) {
            setLinkWidgetData(x.Value);
          } else {
            toast.error(`${x.Message}`, { autoClose: false });
          }
        });
      break;
      case 'DATE':
      case 'SCHEDULE':
        store.server.postApi<ServerResponse<IImportantDateResponse>>(`../Important/Data`, {type:'date'})
        .then((x) => {
          if (x.Success) {
            setDateWidgetData(x.Value);
            let groupDic = new Set<string>();
            let groups:string[] = [];
            x.Value.data.forEach((x)=>{
              let g = x.Group || '';
              if(!groupDic.has(g)) {
                groupDic.add(g);
                groups.push(g);
              }
            });
            setDateGroups(groups);
          } else {
            toast.error(`${x.Message}`, { autoClose: false });
          }
        });
      break;
    }
  };

  const buildWidget = () => {
    switch (props.type.toUpperCase()) {
      case 'ACCOUNT':
        return buildAccountWidget();
      case 'BRANCH':
        if (props.state.branchInfo) return buildBranchWidget(props.state.branchInfo);
        return <></>;
      case 'BRANCH-SUB':
        if (props.state.branchInfo?.SubBranch) return buildBranchWidget(props.state.branchInfo.SubBranch);
        return <></>;
      case 'SCHEDULE':
        return buildScheduleWidget();
      break;
      case 'LINKS':
        return buildLinksWidget();
        break;
      default:
        return (
          <div className="widget text-center">
            <h3>Unknown Widget "{props.type}"</h3>
          </div>
        );
    }
  };

  const buildAccountWidget = () => {
    if (props.state.User?.signedIn) {
      let user = props.state.User;
      return (
        <div className="widget widget-account">
          <div className="widget-header">
            <div className="widget-profile-img">
              {user.ProfileLink && !profileImageFailedToLoad ? (
                <>
                  <img
                    src={user.ProfileLink}
                    alt="Profile Image"
                    onError={(x) => {
                      setProfileImageFailedToLoad(true);
                    }}
                  />
                </>
              ) : (
                <>
                  <Icon type={IconType.userProfileImage} />
                </>
              )}
            </div>
            <div className='widget-user-info'>
              <h4>
                {user.firstName} {user.lastName}
              </h4>
              <div className='small'>{user.email}</div>
            </div>
              <div className='bottom-right'>
                <Button type='logout' text='Logout' extraSmall={true} onClick={logout}/>
              </div>
          </div>
        </div>
      );
    } else {
      //Create Account / Sign in
      return (
        <div className="widget">
          <h3>
            <Icon type={IconType.user} addClass="text-muted" /> Account Info
          </h3>
          <Button
            type="login"
            addClass="btn-block"
            text="Login"
            onClick={() => {
              redux.showLogin(true);
            }}
          />
          <Button type="register" addClass="btn-block" text="Register" />
        </div>
      );
    }
  };

  const buildBranchWidget = (info: IBranchTask) => {
    return (
      <div
        className={`widget branch-entry-bar ${info.ClassName ?? ''} ${info.CallToActionText ? '':'click'}`}
        onClick={() => {
          if(!info.CallToActionText) {
            setSelectedBranch(info);
            setShowBranch(true);
          }
        }}>
        <div className="entry-bar-text">
          <div dangerouslySetInnerHTML={{ __html: info.Title }}></div>
        </div>
        <br/>
        {/* <div>{info.EntryBarText ?? 'Click Here'}</div> */}
        
        <div dangerouslySetInnerHTML={{__html:info.EntryBarText ?? 'Click Here'}} />
        {info.CallToActionText && <div>
          <br/>
            <button 
            className='btn btn-info'
            onClick={()=>{

              setSelectedBranch(info);
              setShowBranch(true);

            }}  dangerouslySetInnerHTML={{__html:info.CallToActionText}}>

            </button>
          </div>}
      </div>
    );
  };

  const buildScheduleWidget = () => {
    if(dateWidgetData?.data) {
      return (
        <div className="widget">
          <h3>
            <Icon type={IconType.calendar} />  Schedule
          </h3>
            {dateGroups.map((g) => {
              let groupData = dateWidgetData.data.filter((x) => (x.Group || '' ) === g);
              return (
                <div key={g} className='schedule-widget-list' >
                  {g && <h6>{g}</h6>}
                  <ul>
                    {groupData.map((x) => {
                      return (
                        <li key={x.PublicId}>
                          {!g && <h6>{x.Name}</h6>}
                          <div>
                            {FormatDate(x.Date, 'pretty')}
                            {" "}
                          {x.SubLabel && <span className=''>({x.SubLabel})</span>}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
        </div>
      );
    }
    else return <></>;
  };

  const buildLinksWidget = () => {
    
    if(linkWidgetData?.data) {
      return (
        <div className="widget">
          <h3>
            <Icon type={IconType.link} /> Important Links
          </h3>
          <ul className='important-links'>
            {linkWidgetData.data.map((x) => {
              return (
                <li key={x.PublicId}>
                  <a href={x.ExternalLink ?? x.FlexLink ?? x.SystemLink} target={x.OpenInNewTab ? '_blank' : '_self'}>
                    {x.Icon && <span dangerouslySetInnerHTML={{__html:x.Icon}}/>} {x.Name} 
                  </a>
                </li>
              );
            })}
          </ul>
        </div>);
}
        return <></>;
  };

  return (
    <>
      <div className="widget-container">{buildWidget()}</div>
      {showBranch && selectedBranch && (
            <Modal setModalOpen={setShowBranch} title={selectedBranch.Title} size="xl">
              {/* <SetupWizard wizardKey={data.ContentKey ?? ''} /> */}
              <BranchPortal type={selectedBranch.Type} />
            </Modal>
          )}
    </>
  );
};

export default Widget;
