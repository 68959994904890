import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { store } from "../../../redux/oldStore";
import { ServerResponse } from "../../../utils/Server";
import FormModal, { IFileUpload } from "../../FormFromApiData/FormModal";
import { IConfigFormData, IConfigFormEditorData, IConfigItemChildType, IConfigItemSummary } from "./ConfigFormTypes";
import redux from "../../../redux/redux";


interface IConfigFromEditorProps {
    type:string;
    item:IConfigItemSummary;
    canSave:boolean;
    noEdit?:boolean;
    noManageLink?:boolean;
    noDelete?:boolean;

    onSave?:Function;
    onClose?:Function;
    onRemove?:Function;
    onUpdate?:Function;
    title?:string;
    itemChildren?:IConfigItemChildType[];
    dontRefreshOnSaveOrRemove?:boolean;
}

const ConfigFormEditor = (props:IConfigFromEditorProps)=>{
    const[type, setType] = useState<string>(props.type);
    const [data, setData] = useState<IConfigFormEditorData>();
    const [showEditModal, setShowEditModal] = useState<boolean>(true);


    useEffect(()=>{
        init();
    },[]);

    const init = () =>{
        getForm();
    };

    const getForm = (item?:IConfigItemSummary)=>{
        toast.dismiss();
        store.server.postApi<ServerResponse<IConfigFormEditorData>>(`../ConfigForm/GetForm`, {...(item??props.item)})
        .then(x=>{
            if(x.Success){
                setData(x.Value);
            } else {
                toast.error(`${x.Message}`, {autoClose:false});
            }
        });
    };

    const save = (fields: Record<string, string[]>, files?:IFileUpload[])=>{
        let request = {Id:props.item.Id ?? -1, Type:props.type, Values:fields};
        let data:any = JSON.stringify(request);
        
        let filesToUpload:File[] = [];
        files?.forEach(x=>filesToUpload.push(x.file));

        store.server.postApi<ServerResponse<IConfigItemSummary>>(`../ConfigForm/SaveForm`,{data:data, altPermissionVia:props.item?.AltPermissionVia}, filesToUpload)
        .then(x=>{
            console.log(x);
            if(x.Success && !x.Value.Failed){
                toast.success('Saved.');
                if(props.onSave) props.onSave(x.Value);
                onUpdate(x.Value);
            }  else if (x.Success) {
                toast.warning(x.Value.Message, {autoClose:15000})   ;
            }else {
                toast.error(`${x.Message}`, {autoClose:false});
            }
        });

    };

    const remove = () =>{
        if(window.confirm(`Are you sure you want to delete "${props.item.Name}"?`)){
            store.server.postApi<ServerResponse<IConfigItemSummary>>(`../ConfigForm/Remove`,props.item)
            .then(x=>{
                if(x.Success){
                    toast.success('Removed.');
                    if(props.onRemove) props.onRemove(props.item);
                    onUpdate();

    
                }  else {
                    toast.error(`${x.Message}`, {autoClose:false});
                }
            });
        }
    };

    const close = () =>{
        setShowEditModal(false);

        if(props.onClose) props.onClose();
    };

    const onUpdate = (item?:IConfigItemSummary) =>{
        if(!props.dontRefreshOnSaveOrRemove){
            redux.Reload();
            if(props.item?.AltPermissionVia){
                item.AltPermissionVia = props.item.AltPermissionVia;
            }
            getForm(item);
        }
        if(props.onUpdate) props.onUpdate();
    };


    return (<>
        {showEditModal && data && <FormModal 
            save={save} 
            onUpdate={onUpdate}
            canSave={props.canSave} 
            sections={data?.Form.Sections ?? []} 
            form={data?.Form}
            show={showEditModal} 
            setShow={close} 
            title={props.title ?? "Editor"} 
            values={data?.Values ?? {}} 
            targetId={props.item?.Id ?? ""} 
            modalSize={props.item?.Id && props.itemChildren?.length ? "xl":"l"}            
            itemChildren={props.itemChildren}
            remove={remove}
            canRemove={props.canSave && props.noDelete ? false : true && (props.item?.Id ?? "-1") != "-1"}
            noManageLink={props.noManageLink ?? false}
            noEdit={props.noEdit ?? false}
            />}
    </>);
}

export default ConfigFormEditor;