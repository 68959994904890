import react, {useState, useEffect} from 'react';
import { toast } from 'react-toastify';
import { store } from '../../redux/oldStore';
import { ServerResponse } from '../../utils/Server';
import { IAssignments, IRound, ISlot } from './AdvancedJudgingLanding';

interface IInfo{
    assignments:IAssignments[];
    sessions:IRound[]
}
interface IScheduleRequestedInterviewProps {
    assignmentId:number,
    projectId:string,
    callOnSet:Function,
}

const ScheduleRequestedInterview = (props:IScheduleRequestedInterviewProps) =>{
    const [info, setInfo] = useState<IInfo>();
    const [judgesSchedule, setJudgesSchedule] = useState<IAssignments[]>();
    const [sessions, setSessions] = useState<IRound[]>();
    const [judgeAssignmentDic, setJudgeAssignmentDic] = useState<any>({});

    useEffect(()=>{
        init();
    }, [props.assignmentId, props.projectId]);
    useEffect(()=>{
        init();
    }, []);

    const init = () =>{
        //calll server to get schedule for this judge...
        store.server.postApi<ServerResponse<IInfo>>(`../JudgingManagement/JudgesSchedule`, {assignmentId:props.assignmentId, projectKey:props.projectId})
        .then(x=>{
            if(x.Success){
                setJudgesSchedule(x.Value.assignments);
                setSessions(x.Value.sessions);
                setInfo(x.Value);
                const dic = {};

                x.Value.assignments?.forEach(a=>{
                    const at = a.at;
                    if(at){
                        dic[at] = a;
                    };
                })

                setJudgeAssignmentDic(dic);

            } else {
                toast.error(x.Message, {autoClose:false});
            }
        })
    };

    const reserveSlot = (at:string)=>{
        store.server.postApi<ServerResponse<any>>(`../JudgingManagement/SetTimeOfAdvanceJudgeAssignment`, {assignmentId:props.assignmentId, projectKey:props.projectId, at:at})
        .then(x=>{
            if(x.Success){
                props.callOnSet();
            } else {
                toast.error(x.Message, {autoClose:false});
            }
        })
    };

    return (<>
                            {info?.sessions?.map((x,i)=>{
                                    // if(!r.slots || r.slots.length === 0) return null;
                                    // var slots = r.slots.filter(x=>projectSessionDic[x.session]);
                                    // if(!slots || slots.length === 0) return null;

                                    return (
                                        <div className="panel panel-default" key={`session-schedule-slot-${i}`}>
                                            <div className="panel-heading">{x.name}</div>
                                            <div className="panel-body">                           
                                                <div className="slot-list">
                                                {x.slots.map((s:ISlot,si:number)=>{
                                                    const aa = judgeAssignmentDic[s.at];
                                                    const a = aa?.at;
                                                    const self = aa?.id === props.assignmentId;

                                                    return (<div className={`timeslot ${self ? 'self':''}`} key={`slot-time-${si}`} onClick={()=>{if(a){toast.info('This slot is already reserved.');return;} reserveSlot(s.at);}}>
                                                        <div>
                                                            <span className={`click ${a ? 'fa':'fal'} fa-calendar`} ></span>
                                                            {"  "}{s.at} 
                                                            </div>
                                                         </div>);
                                                })}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
    </>)
};

export default ScheduleRequestedInterview;