
export interface Product {
  AdvancedType: AdvancedTypes;
  Cost: number;
  CustomTextLimit: number;
  Description: string;
  Id: number;
  ImageUrl: string;
  InBag: InBagItem[];
  IsHidden: boolean;
  Name: string;
  Sizes: string[];
  Limit:number;
  Removable:boolean;
}

interface InBagItem {
  Count: number;
  Size: string;
  Custom: string;
  Free:boolean;
}

export enum AdvancedTypes {
  Normal = '1000',
  TeacherRegistrationFee = '3000',
  HousingPackage = '7000',
  AdditionalAttendee = '7500',
  HandlingLabel = '8000'
}

export interface ProductsResponse {
  payContinueBtnText: string;
  continueBtnText: string;
  headerText:string;
  footerText:string;
  cartCount: number;
  coupons: string[];
  msg: string | null;
  products: Product[];
  savings: number;
  total: number;
  NewRecord: boolean;
  NextRecordPath: string;
  registrationFeeOptions:IRegistrationFeeInfo;
}

export interface IRegistrationFeeInfo{
    checkInstructions:string;
    hasRegistrationFee:boolean;
    payByCheck:boolean;
    payByFeeWavier:boolean;
    payBySchool:boolean;
}

export interface InvoicePaymentRequestResponse {
  OrderId:string;
  ConfirmationCode:string;
  Pass:boolean;
  Message:string;
  RegisterPass:string;
  NextRecordPath:string;
  UserId:string;
  ProjectKey:string;
  ProjectId:string;
}

export interface FullShoppingCartItem extends InBagItem {
  Cost: number;
  Name: string;
  Id: number;
  Limit: number;
  Removeable:boolean;
}

export interface InvoicePaymentRequest{
    FirstName: string;   
    LastName: string;   
    Email: string;   
    Phone: string;   
    Last4: string;   
    Year: string;   
    Month: string;   
    Zip: string;   
    OrderId: string;
    Token: string; 
}  