import { useState, useEffect, FunctionComponent } from "react";
import {
  project,
  paperworkProjectsResponse,
  updateFileHookDef,
  uploadFileHookDef,
  requirementUpdateHookDef,
  fileRemovedHookDef,
  paperworkProjectsWrapper,
} from "./paperworkTypes";
import ParticipantPaperworkReivewProjectListItem from "./ParticipantPaperworkReviewProjectListItem";
import "./paperworkStyles.css";
import { Selectable } from "../../utils/CommonTypes/CommonTypes";
import Creatable from 'react-select/creatable';
import Icon,{ IconType } from "../Icon/Icon";
import { IState } from "../../redux/redux";

type projectListProps = paperworkProjectsResponse & {
  fileUploadHook: uploadFileHookDef;
  fileUpdateHook: updateFileHookDef;
  fileRemoveHook: fileRemovedHookDef;
  requirementUpdateHook: requirementUpdateHookDef;
  data: paperworkProjectsWrapper;
  refresh:Function;
  state:IState;
};

type projectListState = {
  searchString: string;
  selectedSchool: string;
};

const BuildTeamMembersString = (project: project) => {
  return project.TeamMembers.map((t) =>
    `${t.firstName} ${t.lastName}, ${t.firstName}`
  ).join('|');
};

const ParticipantPaperworkReivewProjectList: FunctionComponent<projectListProps> = (props: projectListProps) => {

  const {
    projects,
    fileTypes,
    baseUrl,
    canUpload,
    fileUploadHook,
    fileUpdateHook,
    fileRemoveHook,
    requirementUpdateHook,
    data,
  } = props;

  projects.forEach(x=>{
    let team = BuildTeamMembersString(x);
    x.search = `${team}|${x.Category.Name}|${x.ProjectId}|${x.ProjectKey}|${x.School}|${x.Title}`.toLowerCase();

  });

  const [projectList, setProjectList] = useState<project[]>(projects);
  const [filteredProjects, setFilteredProjects] = useState<project[]>(projects);
  const [filters, setFilters] = useState<Selectable[]>([]);
  const [search, setSearch] = useState<any>({filters:[], text:''});

  useEffect(()=>{
    let options:Selectable[] = [];

    let school:Selectable = {
      label:"Schools", value:"-1", options:[]
    }

    
    if(data.markReady){
      options.push({label:"Ready for Review", value:"ready:review"});
    }
    if(data.markReadyToJudge){
      options.push({label:"Ready for Judging", value:"ready:judging"});
    }
    if(data.trackEntryStatus){
      options.push({label:props.state.Settings?.text.EntryStatusPending,value:'status:Pending'});
      options.push({label:props.state.Settings?.text.EntryStatusApproved,value:'status:Approved'});
      options.push({label:props.state.Settings?.text.EntryStatusDNA,value:'status:DoNotAdvance'});
      options.push({label:props.state.Settings?.text.EntryStatusDropped,value:'status:Dropped'});
    }

    if(data.Divisions && data.Divisions.length > 0){
      let d:Selectable = {
        label:"Divisions", value:"-2", options:[]
      }

      data.Divisions.forEach(x=>{d.options?.push({label:x.Name, value:`Div|${x.Id}`})});
      
      options.push(d);
    }
    if(data.UsesEntryTypes && data.EntryTypes && data.EntryTypes.length > 0){
      let d:Selectable = {
        label:"Entry Type", value:"-1", options:[]
      }

      data.EntryTypes.forEach(x=>{d.options?.push({label:x.Name, value:`Ent|${x.Id}`})});
      
      options.push(d);
    }


    data.Value?.schools?.forEach(x=>{
      school.options?.push({label:x, value:x});
    });

    options.push(school);
    setFilters(options);

  }, [data.Divisions, data.EntryTypes, data.Value.schools]);

  useEffect(()=>{
    let has:any = {
      division:false,
      school:false,
      entryType:false,
      ready:false,
      readyJudging:false,
      text:search.text.length > 0,
    }
    let divisionHash:any = {};
    let entryTypeHash:any = {};
    let schoolHash:any = {};
    let statusHash:any ={};
    let pendingStatus:boolean = false;
    let hasStatus: boolean = false;

    search.filters?.forEach(x=>{
      let v = x.value + '';
      if(v.indexOf('Ent|') === 0){
        entryTypeHash[v.substring(4)] = true;
        has.entryType = true;
      } else if (v.indexOf('Div|') === 0){
        divisionHash[v.substring(4)] = true;
        has.division = true;
      } else if (v.indexOf('ready:review') === 0){
        has.ready = true;
      } else if (v.indexOf('ready:judging') === 0){
        has.readyJudging = true;
      } else if (v.indexOf('status:Pending') === 0) {
        pendingStatus = true;        
      } else if (v.indexOf('status:') === 0) {
        statusHash[v.substring(7)] = true
        hasStatus = true;        
      }
      else {
        schoolHash[v] = true;
        has.school = true;
      }
    });

    setFilteredProjects(projectList?.filter(x=> 
            (!has.division || divisionHash[x.DivisionId])
            && (!has.school || schoolHash[x.School])
            && (!has.ready || x.PaperworkReadyForReview)
            && (!has.readyJudging || x.ReadyForJudging)
            && (!has.entryType || entryTypeHash[x.EntryTypeId])
            && (!pendingStatus || x.EntryStatus === 'Pending' || !x.EntryStatus)
            && (!hasStatus || statusHash[x.EntryStatus])
            && ( !has.text || x.search.indexOf(search.text) > -1)

            ) ?? [])
  },[projectList, search, search.filters]);

  useEffect(()=>{
    setProjectList([...projects]);
  }, [projects]);


  const updateSearchString = (e: any) => {
    setSearch({...search, text:e.target.value.toLowerCase()})
  };

  const projectUpdateHook = (project:project)=>{
    let ps = projectList;
    for(let i = 0; i < ps.length; i++){
      let p = ps[i];
      if(p.ProjectIntId === project.ProjectIntId){
        ps[i] = project;
        break;
      }      
    }
    setProjectList(ps);
  };

  const filterChanged= (values:Selectable[]) =>{
    setSearch({...search, filters:values});
};

  return (
    <>
      <div id="project-list">
        <div className="flex-between">
          <div className="col-sm-9 form-horizontal">
          <label htmlFor="fileReviewSearch" className="control-label col-sm-2">
            Search:
          </label>
          <div className="col-sm-5">
            <input
              type="text"
              id="fileReviewSearch"
              className="form-control focus"
              onChange={updateSearchString}
              placeholder="Search for a project by id or name..."
            />
          </div>
          <div className="col-sm-4">
            {filters && filters.length > 0 && (<>
              <Creatable
              onChange={filterChanged}
              value={search.filters ?? []}
              options={filters}
        placeholder={"filters"}
        className=""              
        isMulti        
        isClearable={true}
        menuPlacement={'auto'}
      />    
            </>)}
          </div>
        {props.refresh &&  <div className="col-sm-1">
                        <span className="icon-btn" title="refresh list" onClick={()=>{props.refresh()}}>
                          <Icon type={IconType.refresh}/>                           </span>
          
          </div>}

          </div>
          <div>
            
            <div className="text-muted"><i className="fal fa-file-upload text-muted"></i> file not uploaded</div>
            <div className="text-info"><i className="fal fa-file text-info"></i> new file, needs review.</div>
            <div className="text-success"><i className="fal fa-file-check text-success"></i> file reviewed, passed.</div>
            <div className="text-danger"><i className="fal fa-file-exclamation text-danger"></i> file reviewed, needs work.</div>
            <div className="text-warning"><i className="fal fa-file-minus text-warning"></i> file pending review.</div>
          </div>
        </div>
        <div className="clearfix"></div>
        <div className="head-room"></div>
        <div className="col-sm-12">
          {filteredProjects.map((project, index) => (
            <ParticipantPaperworkReivewProjectListItem
              canManageEntryStatus={data.trackEntryStatus ?? false}
              state={props.state}
              project={project}
              fileTypes={fileTypes}
              baseUrl={baseUrl}
              projectUpdateHook={projectUpdateHook}
              fileUploadHook={fileUploadHook}
              fileUpdateHook={fileUpdateHook}
              requirementUpdateHook={requirementUpdateHook}
              fileRemovedHook={fileRemoveHook}
              canUpload={canUpload}
              key={`pprpli-${index}-${project.ProjectId}`}
              data={data}
              canMarkReady={data.markReady}
              canMarkReadyToJudge={data.markReadyToJudge}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default ParticipantPaperworkReivewProjectList;
