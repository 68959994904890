import { useEffect, useState } from "react";
import StoreX from "../../../redux/oldStore";
import Icon, { IconType } from "../../Icon/Icon";
import BranchPortal from "./BranchPortal";

interface IBranchContainerProps {
  branchId?:string;
  extraProps?:string;
}

const BranchContainer = (props: IBranchContainerProps) => {
  const [branch, setBranch] = useState<string>();
  const [form, setForm] = useState<any>({ branchType: props.branchId ?? "[NEW]" });
  const [showDevTools, setShowDevTools] = useState<boolean>(false);

  useEffect(() => {
    let branchType = StoreX.GetQueryStringValue("type");
    if (branchType) {
      setBranch(branchType);
      setForm({ ...form, branchType: branchType });
    } else {
        setBranch(props.branchId ?? '[NEW]');
    }
    
    let dev = StoreX.GetQueryStringValue("dev");
    if(dev) setShowDevTools(true);
    //if(window.location.origin.indexOf('localhost') > -1) setShowDevTools(true);
  }, []);

  return (
    <>
      <div className="col-sm-12">
        {showDevTools && <>
        <div className="flex-between ">
          <div>
            <input
              type="text"
              value={form.branchType}
              onChange={(e) => {
                setForm({ ...form, branchType: e.target.value });
              }}
              maxLength={200}
            />
            <span
              className="click"
              title="change"
              onClick={() => {
                setBranch(form.branchType);
              }}
            >
              <Icon type={IconType.refresh} />
            </span>
          </div>
        </div>

        <hr />
        </>}
        {branch && <BranchPortal type={branch} extraProps={props.extraProps} />}
      </div>
    </>
  );
};

export default BranchContainer;
