import React from 'react'

const DangerousInnerHtml = (props) => {
  return(
    <div dangerouslySetInnerHTML={{__html: props.customText}}>
      
    </div>
  )
}

export default DangerousInnerHtml