import { useEffect, useState } from 'react';
import { ITemplateRoom, ItemplateTime } from './NhdTypes';
import Icon, { IconType } from '../../Icon/Icon';
import { toast } from 'react-toastify';
import { FormatDate, FormatTimeToInput } from '../../../utils/Tools';

interface IProps {
  onUpdate: (time: ItemplateTime, index: number) => void;
  onRemove: (time: ItemplateTime, index: number) => void;
  time: ItemplateTime;
  index: number;
}
const NhdScheduleTimeManager = (props: IProps) => {
  const [time, setTime] = useState<ItemplateTime>(props.time);

  useEffect(() => {
    setTime(props.time);
  }, [props.time]);

  const onSave = () => {
    //everything that is required is there....
    let errors:string[] = [];

    if(!time.startTime) errors.push(`Please set the start time.`);
    if(!time.endTime) errors.push(`Please set the end time.`);

    if(errors.length > 0){
        toast.warning(<>{errors.map(x=>{return <div>{x}</div>})}</>);
        return;
    }
      props.onUpdate(time, props.index);
  };

  const onRemove = () => {
    if (props.index === -1 || window.confirm(`Are you sure you want to remove this time slot?`)) {
      props.onRemove(time, props.index);
    }
  };

  return (
    <>
      <div className="form-horizontal">
        <div className="form-group">
          <label className="control-label col-sm-4">Date</label>
          <div className="col-sm-6">
            <input
              type="date"
              className="form-control"
              defaultValue={time.day ? FormatDate(time.day,'input', true) : ''}
              onChange={(x) => {
                setTime({ ...time, day: x.target.value });
              }}
              placeholder="Date"
            />
          </div>
        </div>
        <div className="form-group">
          
          <div className="col-sm-6 col-sm-offset-4">
            <input
            id="isbreakcbnhd"
              type="checkbox"
              className=""
              checked={time.isBreak ?? false}
              onChange={(x) => {
                setTime({ ...time, isBreak: x.target.checked });
              }}
              placeholder="Date"
            /><label className="control-label" htmlFor='isbreakcbnhd'>This is a judging break.</label>
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-4">Start Time</label>
          <div className="col-sm-6">
            <input
              type="time"
              step="300"
              className="form-control"
              value={FormatTimeToInput(time.startTime ??  '')}
              onChange={(x) => {
                setTime({ ...time, startTime: x.target.value });
              }}
              placeholder="Start Time"
            />
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-4">End Time</label>
          <div className="col-sm-6">
            <input
              type="time"
              step="300"
              className="form-control"
              value={FormatTimeToInput(time.endTime ??  '')}
              onChange={(x) => {
                setTime({ ...time, endTime: x.target.value });
              }}
              placeholder="End Time"
            />
          </div>
        </div>
        <div className="form-group">
          <div className="col-sm-8 col-sm-offset-4">
            <button type={'button'} className="btn btn-secondary" onClick={onSave}>
              <Icon type={IconType.save} /> Save
            </button>
            <button type={'button'} className="btn btn-danger" onClick={onRemove}>
              <Icon type={IconType.delete} /> Remove
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default NhdScheduleTimeManager;
