import React from 'react'
import { CustomQuestionPersonType } from '../../QuestionCreation/QuestionCreator';
import { useNavigate } from 'react-router-dom';


const Rubric = (props) => {
  const navigate = useNavigate();
  
  const SumValue = (obj) =>{
    let sum = 0;
    obj.forEach((x, i) => {
      sum+= x.Value;
    });
    return sum;
  };

  const navigateToQuestionSetup = (newRubric?: boolean) => {
    if (newRubric) {
      return navigate(`/app/judging/setup/rubric?rubricId=0&personType=${CustomQuestionPersonType.OnlineJudging}`)
    }
    navigate(`/app/judging/setup/rubric?rubricId=${props.rubric.Id}&personType=${CustomQuestionPersonType.OnlineJudging}`)
  }

  const AnyCategoriesWithoutAssessments = (categories)=>{

    for(let i = 0; i < categories.length; i++){
      const assessments = categories[i].Assessments
      if(!assessments || assessments.length === 0){
        return true;
      }
    }
    return false;
  };

  return (
    <>
      {props.rubric
        ?
        <div className="judging-round soft-border hover-shadow">
          <div className="title">
            <h3>{props.rubric.Name}</h3>            
          </div>
          <div>
            {props.rubric.IsDefault && <><i className="fad fa-pennant text-success"></i>Default Rubric</>}<br/>
          </div>
         <div>
          {(!props.rubric.Categories || props.rubric.Categories.length === 0) && <div><i className="fad fa-exclamation-triangle text-danger"></i>Judging categories not set</div>}
          {(props.rubric.Categories && SumValue(props.rubric.Categories) !== 100) && <div><i className="fad fa-exclamation-triangle text-warning"></i>Judging categories total not 100%</div>}
          {(props.rubric.Categories && AnyCategoriesWithoutAssessments(props.rubric.Categories)) && <div><i className="fad fa-exclamation-triangle text-danger"></i>Judging categories are missing assessments</div>}
         </div>

          <div className={`off-bottom-right edit-${props.rubric.Name}`}>
             <span
              // onClick={() => props.openEdit(props.rubric)}
              onClick={() => navigateToQuestionSetup()}
              className="hover-success"
            >
              <i className="fad fa-edit fa-3x"></i>
            </span>
          </div >
        </div >
        :
        <div className="judging-round soft-border hover-shadow">
          <div className="title">
            <h3>Create a New Rubric</h3>
          </div>
          <div className="off-bottom-right create-rubric-btn">
            <span
              onClick={() => navigateToQuestionSetup(true)}
              className="hover-success"
            >
              <i className="fad fa-plus-circle fa-3x"></i>
            </span>
          </div>
        </div >

      }
    </>
  )
}

export default Rubric;