import React, { useEffect, useState } from "react";
import Select from "react-select";
import Constants from "../../utils/Constants";
import { toast } from "react-toastify";
import StoreX, { store } from "../../redux/oldStore";
import Modal from "../Modal/Modal";
import { Selectable } from "../../utils/CommonTypes/CommonTypes";
import { IState, ReduxMap } from "../../redux/redux";
import { ServerResponse } from "../../utils/Server";
import Editor from "../GenericForms/Editor";

type School = {
  id: number;
  name: string;
};

type Tag = {
  Id: number;
  FairId: number;
  Type: "person" | "project";
  Value: string;
};

interface IProps extends IState {}

const EmailToolContainer = (props:IProps) => {
  const [selectedRecipients, setSelectedRecipients] = useState([]);
  const [preEmailEditorState, setPreEmailEditorState] = useState('');
  const [hideSchoolList, setHideSchoolList] = useState(true);
  const [schoolList, setSchoolList] = useState([]);
  const [selectedSchools, setSelectedSchools] = useState<Selectable[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<Selectable[]>([]);
  const [selectedPersonTags, setSelectedPersonTags] = useState<Selectable[]>([]);
  const [selectedProjectTags, setSelectedProjectTags] = useState<Selectable[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [validFiles, setValidFiles] = useState<any>([]);
  const [skipPreview, setSkipPreview] = useState(false);
  const [previewEmailVal, setPreviewEmailVal] = useState("");
  const [subjectVal, setSubjectVal] = useState("");
  const [showSendModal, setShowSendModal] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  const [personTags, setPersonTags] = useState<Tag[]>([]);
  const [projectTags, setProjectTags] = useState<Tag[]>([]);

  useEffect(() => {
    store.server
      .getApi("../school/list")
      .then((res: any) => {
        setSchoolList(res.Value.schools);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err);
      });

    store.server
      .getApi("../Tag/Info")
      .then((res: any) => {
        console.log(res);
        setPersonTags(res.Value.personTags);
        setProjectTags(res.Value.projectTags);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err);
      });
  }, []);

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage);
      setErrorMessage("");
    }
  }, [errorMessage]);

  useEffect(() => {
    let filteredArray = selectedFiles.reduce((file: any, current: any) => {
      if (current.invalid) return file;
      const x: any = file.find((item: any) => item.name === current.name);
      if (!x) {
        return [...file, current];
      } else {
        return file;
      }
    }, []);
    setValidFiles([...filteredArray]);
  }, [selectedFiles]);

  // useEffect(() => {
  //   if(validFiles[0]) {
  //     uploadFile(validFiles[0])
  //       .then( fileId => {
  //         let formatted = formatFileData(fileId, validFiles[0]);
  //         if (props.setFilesArr && props.filesArr) {
  //           props.setFilesArr([...props.filesArr, formatted]);
  //         }
  //         if (props.setOnStep) handleNewTemplate(formatted)
  //         setValidFiles(validFiles.slice(1));
  //       });
  //   }
  // }, [validFiles]);

  const recipientCategories = [
    { value: "students", label: "Students" },
    { value: "parents", label: "Parents" },
    { value: "teachers", label: "Teachers" },
    { value: "studentFees", label: "Missing Student Fees" },
    { value: "outstanding", label: "Outstanding Invoice" },
    { value: "judges", label: "Judges" },
    { value: "volunteers", label: "Volunteers" },
    { value: "staff", label: "Staff" },
    { value: "attendees", label: "Attendees" },
    { value: "firsttimemissing", label: "Student - Missing first time login" },
  ];

  const mappedRecipients = recipientCategories.map((group) => {
    return {
      value: group.value,
      label: group.label,
    };
  });

  const mappedSchoolList = schoolList.map((school: School) => {
    return {
      value: school.id,
      label: school.name,
    };
  });

  const mappedEntryStatus = () =>{
    return [
      {value: `Pending`, label: props.Settings?.text.EntryStatusPending },
      {value: `Approved`, label: props.Settings?.text.EntryStatusApproved },
      {value: `Dropped`, label: props.Settings?.text.EntryStatusDropped },
      {value: `DoNotAdvance`, label: props.Settings?.text.EntryStatusDNA },
    ]
  }

  const onEditorStateChanged = (state) => {
    setPreEmailEditorState(state);
  };

  const toggleHideSchoolList = () => setHideSchoolList(!hideSchoolList);

  const checkEmailRegex = (emailAddress: string) => {
    return Constants.emailRegex.test(emailAddress.toLowerCase());
  };

  const onChange = (e: any) => {
    if (e.target.files[0]) {
      handleFiles(e.target.files);
    }
  };

  const validateFile = (file: any) => {
    const validTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "image/jpeg",
      "image/png",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "text/plain",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];
    if (validTypes.indexOf(file.type) === -1) {
      return false;
    }
    return true;
  };

  const validateFileSize = (file: any) => {
    const maxSize = 10 * 1024 * 1024; //10MB max size
    console.log("this is the file for size validation", file);
    if (file.size > maxSize) {
      console.log("validateFileSize returning false");
      return false;
    }
    return true;
  };

  const handleFiles = async (files: any) => {
    let filesArr: any[] = [];
    for (let i = 0; i < files.length; i++) {
      if (validateFileSize(files[i])) {
        if (validateFile(files[i])) {
          // add to an array so we can display the name of file
          filesArr.push(files[i]);
        } else {
          // add a new property called invalid
          files[i]["invalid"] = true;
          // do some error stuff
          setErrorMessage("Not a valid format");
        }
      } else {
        files[i]["invalid"] = true;
        await setErrorMessage("Files must be < 10MB");
      }
      setSelectedFiles(filesArr);
    }
  };

  const handleSend = (isPreview: boolean) => {
    if (isPreview) {
      if (!checkEmailRegex(previewEmailVal)) {
        toast.error("Need valid email to send preview");
        return;
      }
    }

    if (selectedRecipients.length === 0)
      return toast.error('Need a "To" group');
    if (subjectVal === "") return toast.error("Need a Subject");
    let messageBody = preEmailEditorState;
    let checkBodyArr = messageBody.split(/[\n\r]/g);
    // the toHtml() in an empty body returns a <p></p> and a new line.
    if (checkBodyArr[0] === `<p></p>` && checkBodyArr.length === 2)
      return toast.error("Need Email Body content");

    // public int[] personTags { get; set; }
    // public int[] projectTags { get; set; }

    let formattingReqBody = {
      schoolFilter: selectedSchools.map((school) => school.value),
      entryStatusFilter: selectedStatus?.map(x=>x.value),
      isPreview,
      subject: subjectVal,
      message: messageBody,
    };

    if (selectedProjectTags.length > 0)
      formattingReqBody["projectTags"] = selectedProjectTags.map(
        (x) => x.value
      );
    if (selectedPersonTags.length > 0)
      formattingReqBody["personTags"] = selectedPersonTags.map((x) => x.value);

    if (isPreview) formattingReqBody["previewEmail"] = previewEmailVal;

    selectedRecipients.forEach(
      (recipient: { value: string; label: string }) => {
        formattingReqBody[recipient.value] = true;
      }
    );

    let filesArr = validFiles;

    console.log("sending this object", formattingReqBody);

    store.server
      .postApi<ServerResponse<any>>("../Email/SendBulkEmail", formattingReqBody, filesArr)
      .then((res) => {
        if(res.Success){
        console.log(res);
        if (isPreview) {
          toast.success('preview email sent')
          setShowSendModal(true);
        }
        if (!isPreview) {
          toast.success('emails sent, form reset for another email', { 
            autoClose: false
          });
          setShowSendModal(false);
          handleAnotherClick();
          // setShowBanner(true);
        }
      } else {
        toast.error(res.Message);
      }
      })
      .catch((err) => {
        console.log(err);
        toast.error(`Error trying to send your message. ${err}`);
      });
  };

  const handleAnotherClick = () => {
    setSelectedRecipients([]);
    setPreEmailEditorState('');
    setHideSchoolList(true);
    setSelectedFiles([]);
    setErrorMessage("");
    setValidFiles([]);
    setSkipPreview(false);
    setPreviewEmailVal("");
    setSubjectVal("");
    setShowSendModal(false);
    setShowBanner(false);
    handleClearFilters();
  };

  const handleClearFilters = () => {
    setSelectedSchools([]);
    setSelectedPersonTags([]);
    setSelectedProjectTags([]);
    setSelectedStatus([]);
  };

  const mappedPersonTags = personTags.map((tag) => {
    return {
      label: tag.Value,
      value: tag.Id,
    };
  });

  const mappedProjectTags = projectTags.map((tag) => {
    return {
      label: tag.Value,
      value: tag.Id,
    };
  });

  return (
    <div className="bumper-l">
      <div className="col-sm-12">
        <h3>Bulk Mailer</h3>
        Quickly and easily communicate with your students, teachers, volunteers, and staff members. Fill out the form below and we will send them all an email.
        <br/>
        <br/>
      </div>
      <div className="form-horizontal">
        <div className="form-group row">
          <label className="control-label col-sm-3">To</label>

          <Select
            onChange={(newValue) => setSelectedRecipients(newValue as Selectable[])}
            value={selectedRecipients}
            options={mappedRecipients}
            className="col-sm-6 grow-1"
            isMulti
            isClearable={true}
            menuPlacement={'auto'}
          />
          <div className="col-sm-3">
            <button className="btn btn-default" onClick={toggleHideSchoolList}>
              <i className="fal fa-filter"></i> Toggle Additional Filters
            </button>
          </div>
        </div>
        <div className="form-group">
          {/* <label className='control-label'>Additional Filters</label> */}

          {!hideSchoolList && (
            <>
              <div className="col-sm-8 col-sm-offset-2 soft-border">
                <h3>Additional Filters</h3>
                <p>
                  Only the people selected by the <strong>To</strong> that meet
                  the following additional filters will sent this message.
                </p>
                {(props.Settings?.TrackEntryStatus ?? false) && <div className="row">
                  <label className="control-label col-sm-4">Entry Status</label>
                  <Select
                    onChange={(newValue) => setSelectedStatus(newValue as Selectable[])}
                    value={selectedStatus}
                    options={mappedEntryStatus()}
                    placeholder={"choose none for all entries"}
                    className="col-sm-8 grow-1"
                    isMulti
                    isClearable={true}
                    menuPlacement={'auto'}
                  />
                </div>}
                <div className="row">
                  <label className="control-label col-sm-4">
                    School Filter
                  </label>
                  <Select
                    onChange={(newValue) => setSelectedSchools(newValue as Selectable[])}
                    value={selectedSchools}
                    options={mappedSchoolList}
                    placeholder={"choose none for all schools"}
                    className="col-sm-8 grow-1"
                    isMulti
                    isClearable={true}
                    menuPlacement={'auto'}
                  />
                </div>
                <div className="row">
                  <label className="control-label col-sm-4">
                    Person Tag Filter
                  </label>
                  {/* <input className='col-sm-10 grow-1' placeholder='person tag filter select here' /> */}
                  <Select
                    onChange={(newValue) => setSelectedPersonTags(newValue as Selectable[])}
                    value={selectedPersonTags}
                    options={mappedPersonTags}
                    placeholder={"Person Tags"}
                    className="col-sm-8 grow-1"
                    isMulti
                    isClearable={true}
                    menuPlacement={'auto'}
                  />
                </div>
                <div className="row">
                  <label className="control-label col-sm-4">
                    Project Tag Filter
                  </label>
                  {/* <input className='col-sm-10 grow-1' placeholder='project tag filter select here' /> */}
                  <Select
                    onChange={(newValue) => setSelectedProjectTags(newValue as Selectable[])}
                    value={selectedProjectTags}
                    options={mappedProjectTags}
                    placeholder={"Project Tags"}
                    className="col-sm-8 grow-1"
                    isMulti
                    isClearable={true}
                    menuPlacement={'auto'}
                  />
                </div>
                <div className="text-right">
                  <button
                    className="btn btn-default"
                    onClick={handleClearFilters}
                  >
                    clear filters
                  </button>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="form-group row">
          <label className="control-label col-sm-3">Subject</label>
          <div className="col-sm-8">
            <input
              placeholder={"Email subject"}
              onChange={(e) => setSubjectVal(e.target.value)}
              value={subjectVal}
              className="form-control "
            />
          </div>
        </div>
        <div className="form-group">
          <div className="col-sm-9 col-sm-offset-3">
            <Editor
              value={preEmailEditorState} 
              options={"simple"} 
              changed={onEditorStateChanged} 
              height={"normal"}       
              // placeholder={`Type your message here, below is an example.
            
              //   Dear $NAME$,
                            
              //   Remember the fair is tomorrow.
                
              //   Thank you.`}     
            />
            
        <div className="text-muted">
          <ul>
            <li>$NAME$ will be replaced with recipients name.</li>
            <li>
              $INVOICE$ will be replaced with link to invoice. (outstanding
              invoice only)
            </li>
            {StoreX.Settings?.usesParty && <li>$PARTY$ will be replaced with a list of their party members. </li>}
            {/* <li>$CIRCLE-ASSIGNMENTS$ will be replaced with a list of judging circle assignments. (judges only)</li> */}
          </ul>
        </div>
          </div>
        </div>
        <div className="bumper-l bumper-out-l form-group row">
          <label className="control-label col-sm-3">Attach Files</label>
          <div className="col-sm-6">
            <input type="file" className="form-control" onChange={onChange} />
          </div>
        </div>
      </div>

      <div className="form-horizontal">
        <div className="bumper-l bumper-out-l form-group row">
          <label className="control-label col-sm-3">Preview Email</label>
          <div className="col-sm-6">
            <input
              placeholder={"Email address to send preview to."}
              onChange={(e) => setPreviewEmailVal(e.target.value)}
              value={previewEmailVal}
              className="form-control"
            />
          </div>
        </div>
      </div>

      <div className="col-sm-9 col-sm-offset-3">
        {skipPreview && (
          <button
            onClick={() => handleSend(false)}
            className="bumper-l bumper-out-l btn btn-success"
          >
            <i className="fas fa-paper-plane"></i> Send
          </button>
        )}
        {!skipPreview && (
          <button
            onClick={() => handleSend(true)}
            className="bumper-l bumper-out-l btn btn-primary"
          >
            <i className="fas fa-paper-plane"></i> Send Preview
          </button>
        )}
        <button
          onClick={() => {
            setSkipPreview(!skipPreview);
          }}
          className="bumper-l bumper-out-l btn btn-default"
        >
          <i className="fal fa-file-check"></i> {!skipPreview ? <>Skip Preview</> : <>Preview</>}
        </button>
      </div>
      {showSendModal && (
        <Modal setModalOpen={setShowSendModal} title="Check preview, then send">
          <div>
            Preview Email sent to address {previewEmailVal}.
            <br />
            If everything looks good in that email, press send.
            <div>
              <button
                onClick={() => handleSend(false)}
                className="bumper-l bumper-out-l btn btn-success"
              >
                <i className="fas fa-paper-plane"></i> Send
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ReduxMap(EmailToolContainer);


