import React, { useState } from "react";
import { toast } from "react-toastify";
import StoreX, { store } from "../../redux/oldStore";
import { IState, ReduxMap } from "../../redux/redux";
import { IUser } from "../../redux/types";
import { GetPeoplePerson, IPeopleSettings, Tag } from "../../utils/CommonTypes/PeoplePageTypes";
import Icon, { IconType } from "../Icon/Icon";
import Modal from "../Modal/Modal";
import ChangePasswordForm from "./ChangePasswordForm";
import ChangeRoleForm from "./ChangeRoleForm";
import ContextForm from "./ContextForm";
import EmailAPerson from "./EmailAPerson";
import LinkProject from "./LinkProject";
import TagModal from "./TagModal";
import { IListItem } from "../_Core/CoreTypes";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

interface PersonContextMenuProps extends IState {
  person: GetPeoplePerson;
  settings: IPeopleSettings;
  setModalOpen: (open: boolean) => void;
  projectTags: Tag[];
  personTags: Tag[];
  projectTagDict: Record<number, Tag>;
  personTagDict: Record<number, Tag>;
  setChosenPerson: (p: GetPeoplePerson) => void;
  refreshAll: () => void;
  updateTag: (person, ids, type, remove, userId, projectId) => void;
  whoAmI?: IUser;
  redirectUrl: string;
  ShowEntriesPage:boolean;  
  fileTypes: IListItem[];
}

export enum FormTypes {
  usernameChange,
  passwordChange,
  fileUpload,
}

const PersonContextMenu = (props: PersonContextMenuProps) => {
  const navigate = useNavigate();
  
  const [tagModalOpen, setTagModalOpen] = useState(false);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [linkProjectModalOpen, setLinkProjectModalOpen] = useState(false);
  const [formModalOpen, setFormModalOpen] = useState(false);
  const [formType, setFormType] = useState<FormTypes>(FormTypes.usernameChange);
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [changeRoleModalOpen, setChangeRoleModalOpen] = useState(false);

  const editViewParticipantClick = () => {
    const url = `/app/user/profile/${props.person.Id}/${window.location.search}`;
    window.open(url,'_blank');
  };

  const editViewParticipantEntryClick = () => {
    const url = `/app/projects/${props.person.Id}/${window.location.search}`;
    window.open(url,'_blank');
  };

    const editProjectClick = () => {
    navigate(`/app/project/${props.person.Id}/${props.person.ProjectKey}/${window.location.search}`);
  };
  const tagsClick = () => {
    setTagModalOpen(true);
  };
  const emailPersonClick = () => {
    setEmailModalOpen(true);
  };
  const specialAssignmentsClick = () => {
    const url = `/app/AdvancedJudging/${props.person.Id}/${window.location.search}`;
    window.open(url,'_blank');
  };

  const changePasswordClick = () => {
    setPasswordModalOpen(true);
  };

  const changeUsernameClick = () => {
    setFormType(FormTypes.usernameChange);
    setFormModalOpen(true);
  };

  const loginAsClick = () => {
    let url = StoreX.BuildUrl(`/Person/LoginAs/${props.person.Id}`);
    console.log(url);
    window.location.href = url;
  };

  const judgeAsClick = () =>{
    
    let url = StoreX.BuildUrl(`/Where.aspx?Judge=Y&As=${props.person.Id}`);
    if(window.confirm(`You are going to go to online judging as "${props.person.FirstName}". You can only have use one judge account at a time. The last judge account you access online judging with will be the one that scores.`)){
      window.open(url,'_blank');
    }
  };

  const uploadFileClick = () => {
    // modal
    // drop down menu for which ISEF Form
    // file to upload browse button
    // only pdfs, please
    // upload file | close
    setFormType(FormTypes.fileUpload);
    setFormModalOpen(true);
  };

  
  const continueClick = () => {
    const url = `/app/user/new/${props.person.MaxRole}/${props.person.Id}${window.location.search}`;
    window.open(url,'_blank');
  }

  const deleteClick = () => {
    // window.confirm, this cannot be undone.
    // toast.error('handle delete stuff');
    if (
      window.confirm(
        `Are you sure you want to delete ${props.person.FirstName} ${props.person.LastName}? This cannot be undone.`
      )
    ) {
      store.server
        .postApi("../Person/Delete", { personId: props.person.Id, projectKey: props.person.ProjectKey })
        .then((res: any) => {
          if (!res.Success) {
            return toast.error(res.Message);
          }

          toast.success(
            `${props.person.FirstName} ${props.person.LastName} deleted!`
          );
          props.refreshAll();
          props.setModalOpen(false);
        });
    }
  };


  const roleChangeClick = () => {
    // toast.warning('handle role change stuff. What api to call?');
    setChangeRoleModalOpen(true);
  };

  const linkToProjectClick = () => {
    setLinkProjectModalOpen(true);
  };

  const unlinkFromProjectClick = () => {
    if (
      window.confirm(
        `unlinks ${props.person.FirstName} ${props.person.LastName} from ${props.person.Title}. Are you sure you want to do this?`
      )
    ) {
      let submitObj = {
        personId: props.person.Id,
      };
      store.server.postApi("../Person/UnlinkProject", submitObj).then(() => {
        toast.success("project unlinked!");
        props.refreshAll();
      });
    }
  };

  const reviewProjectInDashboardClick = () => {
    let url = store.server.makeUrl(`../ProjectReview.aspx?projectId=${props.person.ProjectIdInt}&review=true&IsSigner=true`);
    window.open(url,'_blank');

  };

  const handleViewDetails = () => {
    toast.warning("not sure what View Details does. Look in people.ascx");
  };

  const titleStringByFormType = {
    [FormTypes.passwordChange]: `Change ${props.person.FirstName} ${props.person.LastName} password`,
    [FormTypes.usernameChange]: `Change ${props.person.FirstName} ${props.person.LastName} username`,
    [FormTypes.fileUpload]: `Upload file for ${props.person.FirstName} ${props.person.LastName}`,
  };

  const updatedTag = (person, tagIds, type, remove) =>{
    props.updateTag(person, tagIds, type, remove, props.person.Id, props.person.ProjectKey);
  };

  return (
    <div className="panel-body form-horizontal">
      {props.person.ProjectId && !props.Settings?.hideProjectId && (
        <span>Project Id: {props.person.ProjectId}</span>
      )}


      {props.person.Adult && (
        <button
          type="button"
          className="btn btn-default btn-block"
          onClick={roleChangeClick}
        >
          <Icon type={IconType.idBadge} /> Change Role
        </button>
      )}

      {!props.person.TempPerson && (
        <>
          <button
            type="button"
            className="btn btn-block btn-primary edit-view-participant"
            onClick={editViewParticipantClick}
          >
            <Icon type={IconType.edit} /> Edit / View
          </button>
          {props.ShowEntriesPage && !props.person.Adult &&        
           <button
            type="button"
            className="btn btn-block btn-primary edit-view-participant"
            onClick={editViewParticipantEntryClick}
          >
            <Icon type={IconType.entry} /> View Entries Page
          </button>}
          { props.settings.ShowTags && <button
            type="button"
            className="btn btn-block btn-primary"
            onClick={tagsClick}
          >
            <Icon type={IconType.tags} /> Tags
          </button>}
          <button
            type="button"
            className="btn btn-block btn-primary change-pswd"
            onClick={changePasswordClick}
          >
            <Icon type={IconType.unlock} /> Change Password
          </button>
          <button
            type="button"
            className="btn btn-block btn-primary"
            onClick={changeUsernameClick}
          >
            <Icon type={IconType.idBadge} /> Change Username
          </button>
        </>
      )}
      <button
        type="button"
        className="btn btn-block btn-primary"
        onClick={emailPersonClick}
      >
        <Icon type={IconType.envelopeO} /> Email
      </button>

      {!props.person.TempPerson && (
        <>
          {!props.person.Adult && (
            <>
              {props.fileTypes && props.fileTypes.length > 0 && <button
                type="button"
                className="btn btn-block btn-primary"
                onClick={uploadFileClick}
              >
                <Icon type={IconType.upload} /> Upload File
              </button>}
              <button
                type="button"
                className="btn btn-block btn-primary"
                onClick={linkToProjectClick}
              >
                <Icon type={IconType.link} /> Link to a project
              </button>
              <button
                type="button"
                className="btn btn-block btn-primary"
                onClick={unlinkFromProjectClick}
              >
                <Icon type={IconType.chainBroken} /> Unlink from project
              </button>
              {props.Settings?.useISEFDashboard && (
                <button
                  type="button"
                  className="btn btn-block btn-primary"
                  onClick={reviewProjectInDashboardClick}
                >
                  <Icon type={IconType.tachometer} /> Review Project in
                  Dashboard
                </button>
              )}
            </>
          )}
        </>
      )}

      {!props.person.TempPerson && (
        <>
          {props.person.Adult && props.person.MaxRole === "Judge" && !props.Settings?.isHistoryContest &&  (
            <button
              type="button"
              className="btn btn-block btn-secondary"
              onClick={judgeAsClick}
            >
              <Icon type={IconType.gavelDuo} /> Judge As
            </button>
          )}
          {props.settings?.LoginAs && (
            <button
              type="button"
              className="btn btn-block btn-warning"
              onClick={loginAsClick}
            >
              <Icon type={IconType.user} /> Login As
            </button>
          )}

        </>
      )}
      {/* {props.settings?.isOwner
        && <button
          onClick={handleViewDetails}
        >
          View Details (not sure what this does)
        </button>

      } */}
      
      {props.person.TempPerson && (
        <button
          type="button"
          className="btn btn-block btn-primary continue-temp-person-link"
          onClick={continueClick}
        >
          <Icon type={IconType.after} /> Continue
        </button>
      )}

      {props.settings?.CanRemove && (
        <button
          type="button"
          className="btn btn-block btn-danger delete-user"
          onClick={deleteClick}
        >
          <Icon type={IconType.delete} /> Delete
        </button>
      )}

      <button
        type="button"
        className="btn btn-block btn-default"
        onClick={() => props.setModalOpen(false)}
      >
        <Icon type={IconType.ban} /> Cancel
      </button>

      {props.person.IsSpecialAwardJudge && (
        <button
          type="button"
          className="btn btn-block btn-primary"
          onClick={specialAssignmentsClick}
        >
          <Icon type={IconType.calendar} /> Special Award Interview Schedule
        </button>
      )}

      {(props.person.IsModerator || props.person.IsInterpreter) && (
        <button
          type="button"
          className="btn btn-block btn-primary"
          onClick={specialAssignmentsClick}
        >
          <Icon type={IconType.flag} /> Proctor / Interpreter Assignments
        </button>
      )}
Click to Copy
{props.User?.Admin && <div className="can-copy-container">
        
        <span className="can-copy" onClick={()=>{navigator.clipboard.writeText(props.person.PersonId+'');toast.dark('Copied');}}>Person Id</span>       
        <span className="can-copy" onClick={()=>{navigator.clipboard.writeText(props.person.Id+'');toast.dark('Copied');}}>Public Id</span> 

        {props.person.ProjectId&&<span className="can-copy" onClick={()=>{navigator.clipboard.writeText(props.person.ProjectId+'');toast.dark('Copied');}}>Entry Id</span>}
        {props.person.ProjectIdInt&&<span className="can-copy" onClick={()=>{navigator.clipboard.writeText(props.person.ProjectIdInt+'');toast.dark('Copied');}}>Entry Internal Id</span>}
        {props.person.ProjectKey&&<span className="can-copy" onClick={()=>{navigator.clipboard.writeText(props.person.ProjectKey+'');toast.dark('Copied');}}>Entry Key</span>}
     </div>}

      {changeRoleModalOpen && (
        <Modal
          title={`Change ${props.person.FirstName} ${props.person.LastName}'s Role`}
          setModalOpen={setChangeRoleModalOpen}
        >
          <ChangeRoleForm
            person={props.person}
            setModalOpen={setChangeRoleModalOpen}
            refreshAll={props.refreshAll}
            whoAmI={props.whoAmI}
          />
        </Modal>
      )}

      {tagModalOpen && (
        <TagModal
          setModalOpen={setTagModalOpen}
          projectTags={props.projectTags}
          personTags={props.personTags}
          person={props.person}
          setChosenPerson={props.setChosenPerson}
          projectTagDict={props.projectTagDict}
          personTagDict={props.personTagDict}
          updateTag={updatedTag}
        />
      )}

      {emailModalOpen && (
        <Modal
          title={`Email ${props.person.FirstName} ${props.person.LastName}`}
          setModalOpen={setEmailModalOpen}
          size="l"
        >
          <EmailAPerson
            personId={props.person.Id}
            setModalOpen={setEmailModalOpen}
          />
        </Modal>
      )}

      {linkProjectModalOpen && (
        <Modal title="Link to project" setModalOpen={setLinkProjectModalOpen}>
          <LinkProject
            person={props.person}
            setModalOpen={setLinkProjectModalOpen}
            refreshAll={props.refreshAll}
          />
        </Modal>
      )}

      {formModalOpen && (
        <Modal
          title={titleStringByFormType[formType]}
          setModalOpen={setFormModalOpen}
          size="l"
        >
          <ContextForm
            person={props.person}
            setModalOpen={setFormModalOpen}
            formType={formType}
            refreshAll={props.refreshAll}
            fileTypes={props.fileTypes}
          />
        </Modal>
      )}
      {passwordModalOpen && (
        <Modal
          title={titleStringByFormType[formType]}
          setModalOpen={setPasswordModalOpen}
        >
          <ChangePasswordForm
            person={props.person}
            setModalOpen={setPasswordModalOpen}
            isSelfPasswordChange={false}
          />
        </Modal>
      )}
    </div>
  );
};

//export default ReduxMap(PersonContextMenu);

const mapStateToProps = (state) => {
  return { ...state.main };
};

export default connect(mapStateToProps)(PersonContextMenu);

