import React,{useEffect, useState} from 'react';
import Icon, { IconType } from "../../Icon/Icon";
import Modal from "../../Modal/Modal";
import { IEventSession } from "./EventSessionsType";
import { toast } from "react-toastify";
import { store } from "../../../redux/oldStore";
import { ServerResponse } from "../../../utils/Server";
import redux from '../../../redux/redux';

interface IEventSessionCheckInProps {

}

const EventSessionCheckIn = (props: IEventSessionCheckInProps)=>{
    const [sessions, setSessions] = useState<IEventSession[]>([]);
    const [activeSession, setActiveSession] = useState<IEventSession>();
    const [showModal, setShowModal] = useState<boolean>();


    useEffect(() => {
        if(sessions && sessions.length > 0){
            setActiveSession(sessions[0]);
            setShowModal(true);
        }
      }, [sessions]);
    

    useEffect(() => {
        init();

        return ()=>{
          clearInterval();          
        };
      }, []);

      const clearInterval = ()=>{
        if(redux.EventSessionHandel){
          window.clearInterval(redux.EventSessionHandel);
          redux.EventSessionHandel = null;
        }
      };
    
      const init = () => {
        store.server
          .getApiQuiet<ServerResponse<IEventSession[]>>("../EventSession/CheckInNeeded")
          .then((x) => {
            if (x.Success) {
              setSessions(x.Value);
            } else {
              toast.error(x.Message, { autoClose: false });
            }
          })
          .catch((x)=>{
            console.log(`Check in check failed.`, x);
            clearInterval();
          });
        if(!redux.EventSessionHandel){
          let handel = window.setInterval(init,  15 * 60 * 1000); 
          redux.EventSessionHandel = handel;
        }
      };

      const checkIn = ()=>{

        store.server
        .postApi<ServerResponse<IEventSession[]>>("../EventSession/SelfCheckIn", {sessionId:activeSession?.Id, here:true})
        .then((x) => {
          if (x.Success) {
              let s = sessions.filter(y=>y.Id !== activeSession?.Id);
            setShowModal(false);
            setSessions(s);
            toast.info(`You have been checked in for ${activeSession?.Name}!`);
          } else {
            toast.error(x.Message, { autoClose: false });
          }
        });

      };

    return (<>
    {showModal && activeSession && 
    <Modal setModalOpen={setShowModal} title={'Check In'} noClose={true} size='m'>
        <h3>{activeSession.Name}</h3>
        Please check in for this session it runs from: 
        {activeSession.StartTimeString && <>{activeSession.StartTimeDisplay} to {activeSession.EndTimeDisplay}</>}
        <br/>
        <br/>
        Thank you!

        <div className='headroom-xl'>
        <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                checkIn();
              }}
            >
              <Icon type={IconType.user} /> Check In
            </button>
        </div>
        </Modal>}

    </>);
}

export default EventSessionCheckIn;