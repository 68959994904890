import ImageEditor from "tui-image-editor"


export const getObjectUrl = (imageEditorInstance: ImageEditor, mimeType?: string, quality?: number) => {
    const canvas: fabric.Canvas = (imageEditorInstance as any)._graphics._canvas;
    const promise = new Promise<string>(async resolve => {
        const blob = await toBlobAsync(canvas.getElement(), mimeType, quality);
        if (!blob) {
            resolve("");
        } else {
            resolve(URL.createObjectURL(blob));
        }
    });
    return promise;
}


export const toBlobAsync = (canvas: HTMLCanvasElement, mimeType?: string, quality?: number) => {
    return new Promise<Blob | null>(resolve => {
        canvas.toBlob(blob => {
            resolve(blob);
        }, mimeType, quality);
    });
}

export interface ResizeImageOptions {
    imageUrl: string,
    maxSize?: number
}

export const getResizedImageObjectUrl = async (url: string, size?: number) => {
  let optionsObj:ResizeImageOptions = { imageUrl: url };
  if (size) optionsObj.maxSize = size
    try {
        const imageBlob = await resizeImage(optionsObj);
        return URL.createObjectURL(imageBlob);
    } catch (error){
        console.error(error);
        return url;
    }
}


export const resizeImage = async (options: ResizeImageOptions) => {
    const actualOptions: Required<ResizeImageOptions> = {
        maxSize:  500,
        ...options
    }
    const blob = await (await fetch(actualOptions.imageUrl)).blob();
    const mimeType = await getMimeTypeFromBlob(blob);
    const image = new Image();
    
    const resize = async () => {
        let width = image.width;
        let height = image.height;
        if (width > height) {
            if (width > actualOptions.maxSize) {
                height *= actualOptions.maxSize / width;
                width = actualOptions.maxSize;
            }
        } else {
            if (height > actualOptions.maxSize) {
                width *= actualOptions.maxSize / height;
                height = actualOptions.maxSize
            }
        }
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        canvas.getContext('2d')?.drawImage(image, 0, 0, width, height);
        return await toBlobAsync(canvas, mimeType);
    }
    
    return new Promise<Blob | null>((accept, reject) => {
        if (!mimeType.match(/image.*/)) {
            reject(new Error("Not an image"));
            return;
        }
        
        image.onload = async () => { 
            const resizedBlob = await resize();
            accept(resizedBlob); 
            URL.revokeObjectURL(image.src); //clean up the object url to stop clear memory.
        };
        image.src = URL.createObjectURL(blob) ?? "";
    });

}

export const getMimeTypeFromBlob = async (blob: Blob) => {
    return new Promise<string>(resolve => {
        var fileReader = new FileReader();
        fileReader.onloadend = (e) => {
            if (e?.target?.result) {
                var arr = (new Uint8Array(e.target.result as ArrayBuffer)).subarray(0, 4);
                var header = "";
                for (var i = 0; i < arr.length; i++) {
                    header += arr[i].toString(16);
                }
                switch (header) {
                    case "89504e47":
                        resolve("image/png");
                        break;
                    case "47494638":
                        resolve("image/gif");
                        break;
                    case "ffd8ffe0":
                    case "ffd8ffe1":
                    case "ffd8ffe2":
                    case "ffd8ffe3":
                    case "ffd8ffe8":
                        resolve("image/jpeg")
                        break;
                    default:
                        resolve(blob.type);
                        break;
                }
            } else {
                resolve(blob.type)
            }

            // Check the file signature against known types

        };
        fileReader.readAsArrayBuffer(blob);
    });


}