import { useState, useEffect } from "react";
import { IFieldInfo, ISigner } from "../GenericForms/formTypes";
import Icon, { IconType } from "../Icon/Icon";


interface IPdfInput {
  hasFocus: boolean;
  index: number;
  tabToNext: Function;
  position: number[];
  values: any;
  value: string;
  exportValue: string;
  type: string;
  name: string;
  id: string;
  scale: number;
  lineHeight?: number;
  valueUpdated: Function;
  fieldMetaData?: IFieldInfo;
  canEdit: boolean;
  signer?: ISigner;
  requestSign: Function;
  isSetupMode?:boolean;
  fieldSelected?:()=>void;
  missingFields?:Record<string,IFieldInfo>;
}

const PdfInput = (props: IPdfInput) => {
  const [scale, setScale] = useState<number>(props.scale);
  const [isSignature, setIsSignature] = useState<boolean>(
    props.fieldMetaData && props.fieldMetaData.SignatureOwner ? true : false
  );
  const [isSigner, setIsSigner] = useState<boolean>(
    props.signer &&
      props.signer?.SignatureInfoId === props.fieldMetaData?.SignatureOwner
      ? true
      : false
  );
  const left = props.position[0] * scale;
  const width = (props.position[2] - props.position[0]) * scale + 2;
  const height = (props.position[3] - props.position[1]) * scale - 2;
  const bottom = props.position[3] * scale - height;
  const isTextArea = height > 30;

  const [edit, setEdit] = useState<boolean>(false);
  const [value, setValue] = useState<string>(
    props.values[props.id] ?? props.value
  );
  const [startingValue, setStartingValue] = useState<string>(
    props.values[props.id] ?? props.value
  );

  useEffect(()=>{
    setIsSignature(props.fieldMetaData && props.fieldMetaData.SignatureOwner ? true : false);
  }, [props.fieldMetaData, props.fieldMetaData?.SignatureOwner, props.id])

  useEffect(() => {
    if (edit && props.type === "Btn") {
      let newValue = value === props.exportValue ? "" : props.exportValue;
      setValue(newValue);
      editClosed(newValue);
    }
    let newValue = '';
    if (edit && isSignature) {
      switch (props.fieldMetaData?.SignatureType) {
        case "Name":
          if (!value) {
            newValue = `${props.signer?.Name}`;
            setValue(newValue);
          }
          editClosed(newValue);
          break;
        case "Signature":
          if (!value) {
            newValue = `/${props.signer?.Name} ${props.signer?.PublicId}/`;
            setValue(newValue);
          }
          editClosed(newValue);
          break;
        case "DateSigned":
          if (!value) {
            let d = new Date();
            setValue(`${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`);
          }
          break;
        case "Initials":
          if (!value) {
            newValue = `${props.signer?.Initials}`;
            setValue(newValue);
          }
          editClosed(newValue);
          break;
        case "Email":
          if (!value) {
            setValue(`${props.signer?.Email}`);
          }
          break;
        case "Phone":
          if (!value) {
            setValue(`${props.signer?.Phone}`);
          }
          break;
        default:
          break;
      }
    }
  }, [edit]);

  useEffect(() => {
    if (props.hasFocus && props.canEdit && !props.signer) {
      setEdit(true);
    }
  }, [props.hasFocus]);

  const maxLength = (big:boolean):number=>{
    if(props.fieldMetaData?.MaxLength){
      return props.fieldMetaData.MaxLength;      
    }
    return big?20000 : 2000;
  };

  const displayValue = () => {
    switch (props.type) {
      case "Tx":
      case "Sig":
        if(props.isSetupMode){
          return <span>{props.name}</span>
        }
        if (value) return <span>{value}&nbsp;</span>;
        else if (isSignature && !isSigner && props.signer) return <span>&nbsp;</span>;
        else if (isSignature) {
          switch (props.fieldMetaData?.SignatureType) {
            case "Signature":
              return (
                <span>
                  <Icon type={IconType.sign} /> Sign
                </span>
              );
            case "DateSigned":
              return (
                <span>
                  <Icon type={IconType.sign} /> Date Signed
                </span>
              );
            case "Name":
              return (
                <span>
                  <Icon type={IconType.sign} /> Name of Signer
                </span>
              );
            default:
              return <span>{props.fieldMetaData?.SignatureType}</span>;
          }
        } 
        else if (props.signer) {
          return <span>&nbsp;</span>;
        } 
        else {
          return <span>click to enter value</span>;
        }

        break;
      case "Btn":
        return value === props.exportValue ? (
          <span>
            <Icon type={IconType.checkSquareO} />
          </span>
        ) : (
          <span>
            <Icon type={IconType.squareO} />
          </span>
        );
        break;
      default:
        return <span>unknown type: {props.type}</span>;
    }
  };
  const editValue = () => {
    switch (props.type) {
      case "Tx":
      case "Sig":
        if (isTextArea)
          return (
            <textarea
              autoFocus={true}
              value={value}
              onBlur={() => {
                editClosed();
              }}
              maxLength={maxLength(true)}
              onKeyDown={keyPressed}
              onChange={valueChange}
            />
          );
        return (
          <input
            type="text"
            autoFocus={true}
            value={value}
            maxLength={maxLength(false)}
            onBlur={() => {
              editClosed();
            }}
            onKeyDown={keyPressed}
            onChange={valueChange}
          />
        );
        break;
      case "Btn":
        return <></>;
        break;
      default:
        return <span>unknown type: {props.type}</span>;
    }
  };
  const valueChange = (e: any) => {
    switch (props.type) {
      case "Tx":
      case "Sig":
        setValue(e.target.value);
        break;
      default:
        console.log(`can't save type: ${props.type}`);
    }
  };

  const keyPressed = (e: any) => {
    if (e.key === "Enter" && !isTextArea) {
      editClosed();
      e.preventDefault();
    }
    if (e.key === "Tab") {
      console.log("Tab pressed");
      e.preventDefault();
      editClosed();
      props.tabToNext(props.index);
    }
  };

  const editClosed = (newValue?: string) => {
    let v = newValue ?? value;
    if (startingValue !== v) {
      props.valueUpdated(props.id, v);
      setStartingValue(v);
    }

    setEdit(false);
  };

  const inputClicked = () => {
    if(props.fieldSelected) props.fieldSelected();
    if(props.isSetupMode){ 
      //console.log('Input Field Selected', props);
      return;
    }

    if(isSignature && props.fieldMetaData?.AllowOwnerToEdit && !isSigner && props.canEdit && !edit){
      setEdit(true);
    }
    else if (isSignature) {
      if (isSigner) {
        //do stuff
        setEdit(true);
      } else if (!props.signer && !value) {
        props.requestSign(props.fieldMetaData?.SignatureOwner);
      } else if(!props.signer) {
        //todo give option to remove signatures... this would need to remove all signature fields from this signer on this file.
      }
    } else if (!edit && props.canEdit && !props.signer) {
      setEdit(true);
    }
  };

  return (
    <>
      {!props.fieldMetaData?.Hidden && <div
        className={`zpdf-input ${props.isSetupMode ?? false ? 'setup-mode': ''} ${isSignature && (isSigner || !props.signer) ? "z-signature" : ""} ${props.fieldMetaData?.AllowOwnerToEdit ? 'owner-can':''} ${(props.missingFields && props.missingFields[props.id]) ? 'missing':''}`}
        style={{
          left: left,
          bottom: bottom,
          width: width,
          minHeight: height,
        }}
        title={props.name}
        onClick={inputClicked}
      >
        {(!edit || props.isSetupMode) && <>{displayValue()}</>}
        {!isSignature && edit && !props.isSetupMode && <>{editValue()}</>}
        {isSignature && (isSigner || props.fieldMetaData?.AllowOwnerToEdit) && edit && !props.isSetupMode && <>{editValue()}</>}
      </div>}
    </>
  );
};

export default PdfInput;
