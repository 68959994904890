import React, { useState, useEffect } from "react";
import { store } from "../../redux/oldStore.ts";
import { toast } from "react-toastify";

export default function Biliteracy(props) {
  let minutes = 60;
  const [ready, setReady] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [alreadySubmitted, setAlreadySubmitted] = useState(false);
  const [started, setStarted] = useState(false);
  const [finished, setfinished] = useState(false);
  const [timeouts, setTimeouts] = useState({});
  const [intervals, setIntervals] = useState({});
  const [editorState, setEditorState] = useState('');
  const [data, setData] = useState({});
  const [ticks, setTicks] = useState(0);
  const [timesUp, setTimesUp] = useState(false);

  useEffect(() => {
    //call sever to get setup information...
    console.log("called useEffect ===========");
    store.server.getApi("../Biliteracy/EssayStartInfo").then((x) => {
      console.log(x);
      setData(x.Value);
      if (!x.Success) {
        toast.error(x.Message);

        return;
      }
      if (x.Value.canStart) {
        setReady(true);

        minutes = x.Value.secondsRemaining / 60;
        console.log("minutes: ", minutes);

        if (x.Value.started) {
          autoStart(x.Value.startedAt);
          if (x.Value.essay) {
            setEditorState(x.value.essay);
          }
        }

        console.log("Showing start page");
      } else {
        setAlreadySubmitted(true);
        toast.warn("Sorry, you have already submitted your essay.");
      }
    });
  }, []);

  useEffect(() => {
    save();
  }, [ticks]);

  useEffect(() => {
    if (timesUp) {
      finish();
    }
  }, [timesUp]);

  function setupTimeouts() {
    let warning30 = window.setTimeout(() => {
      showWarning(30);
    }, (minutes - 30) * 60 * 1000);
    let warning10 = window.setTimeout(() => {
      showWarning(10);
    }, (minutes - 10) * 60 * 1000);
    let warning5 = window.setTimeout(() => {
      showWarning(5);
    }, (minutes - 5) * 60 * 1000);
    let warning1 = window.setTimeout(() => {
      showWarning(1);
    }, (minutes - 1) * 60 * 1000);
    let warninglast = window.setTimeout(() => {
      showWarning(0.5);
    }, (minutes - 0.5) * 60 * 1000);
    let warningDone = window.setTimeout(() => {
      toast.info(`Time's up, your essay is being submitted.`);
      setTimesUp(t=>true);
      finish();
    }, minutes * 60 * 1000);
    setTimeouts({
      warning10,
      warning30,
      warning5,
      warning1,
      warninglast,
      warningDone,
    });

    let autoSave = window.setInterval(() => {
      setTicks((t) => t + 1);
    }, 30 * 1000);

    setIntervals({ autoSave });
  }

  const save = () => {
    let html = editorState;

    if (html === "<p></p>\n") {
      console.log("Nothing to save...");
      return;
    }

    store.server
      .postApiQuite("../Biliteracy/SubmitEssay", {
        Essay: html,
        Done: false,
      })
      .then((x) => {
        console.log("saved.", html);
      });
  };

  const showWarning = (type) => {
    console.log("showing warning", type);
    switch (type) {
      case 30:
        toast.warn("Less then 30 minutes remaining");
        break;
      case 10:
        toast.warn("Less then 10 minutes remaining");
        break;
      case 5:
        toast.warn("Less then 5 minutes remaining");
        break;
      case 1:
        toast.warn("Less then 1 minute remaining");
        break;
      case 0.5:
        toast.warn("Less then 30 seconds remaining");
        break;
      default:
        break;
    }
  };
  const autoStart = (at) => {
    setStarted(true);
    setupTimeouts();
    setStartTime(at);
  };
  const start = () => {
    store.server
      .postApi("../Biliteracy/SubmitEssay", {
        Essay: "",
        Done: false,
      })
      .then((x) => {
        console.log(x);
        toast.info("Start");
        setStarted(true);
        setupTimeouts();
        setStartTime(new Date());
      });
  };
  const clickSubmit = () => {
    if (!window.confirm("Are you sure you are ready to submit?")) {
      return;
    }
    finish();
  };
  const finish = () => {
    clearTimeout(timeouts.warning10);
    clearTimeout(timeouts.warning30);
    clearTimeout(timeouts.warning5);
    clearTimeout(timeouts.warning1);
    clearTimeout(timeouts.warninglast);
    clearTimeout(timeouts.warningDone);
    clearInterval(intervals.autoSave);
    //submit to server
    store.server
      .postApi("../Biliteracy/SubmitEssay", {
        Essay: editorState,
        Done: true,
      })
      .then((x) => {
        console.log(x);
        setfinished(true);
      });
  };
  const onEditorStateChanged = (state) => {
    setEditorState(state);
  };
  return (
    <>
      <div className="biliteracy-container">
        {alreadySubmitted && (
          <div className="biliteracy-panel">
            <h3>{data.category}</h3>
            <div className="head-room-l">
              <strong>{data.user.firstName}</strong> your essay has already been
              submited.
              <br />
              <br />
              Thank you.
            </div>
          </div>
        )}
        {!started && ready && (
          <div className="biliteracy-panel">
            <h3>{data.category}</h3>
            <div
              className="instructions"
              dangerouslySetInnerHTML={{ __html: data.instructions }}
            ></div>
            <div className="head-room-l">
              <button type="button" className="button" onClick={start}>
                Start
              </button>
            </div>
          </div>
        )}
        {started && !finished && (
          <div className="Biliteracy-panel">
            <div>
              <h2>{data.category}</h2>
              <div>
                This is a timed essay, you may submit your essay early. If you
                do not submit your essay before time runs out it will be
                submitted for you.
              </div>

              <div
                className="instructions head-room"
                dangerouslySetInnerHTML={{ __html: data.postStartInstructions }}
              ></div>

              <div className="head-room">
                
        {/*<Editor value={editorState} options={'simple'} changed={onEditorStateChanged} height={'normal'}/>*/}
              </div>
              <div>
                <button className="button" type="button" onClick={clickSubmit}>
                  Submit
                </button>
                <button className="button" type="button" onClick={save}>
                  Save
                </button>
              </div>
            </div>
          </div>
        )}
        {finished && (
          <div className="biliteracy-panel">
            <h2>Finished</h2>
            <h3>{data.category}</h3>
          </div>
        )}
      </div>
    </>
  );
}
