import React, { useState, useEffect } from "react";
import Icon, { IconType } from "../Icon/Icon";

interface IPdfFooterToolbar {
  pages: number;
  page: number;
  zoom: number;
  changePage: Function;
  changeZoom: Function;
  save?: Function;
  isSigning?:boolean;
  downloadFormUrl?:string;
}

const PdfFooterToolbar = (props: IPdfFooterToolbar) => {
  const { page, pages, zoom, changePage, changeZoom } = props;

  const nextPage = () => {
    page < pages && changePage(page + 1);
  };

  const prevPage = () => {
    page > 1 && changePage(page - 1);
  };

  return (
    <div className="zpdf-tool-bar">
      <>
        <div>
          
        </div>
        {pages > 1 && (
          <>
            <div className="flex-between big">
              <div className="zpdf-page-button">
                {page > 1 && (
                  <span className="click" onClick={prevPage}>
                    <Icon type={IconType.up} />
                  </span>
                )}
              </div>
              <span>
                {page} of {pages}
              </span>
              <div className="zpdf-page-button">
                {page < pages && (
                  <span className="click" onClick={nextPage}>
                    <Icon type={IconType.down} />
                  </span>
                )}
              </div>
            </div>
          </>
        )}
      </>
    </div>
  );
};

export default PdfFooterToolbar;
