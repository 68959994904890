import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { IState } from "../../../redux/redux";
import { store } from "../../../redux/oldStore";
import { ServerResponse } from "../../../utils/Server";
import { IAssignmentInfo, IJudgeAssignment, IProjectAssignment } from "./JudgingCirlcesTypes";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import JudgingCirclesProjectAssignmentList from "./JudgingCirclesProjectAssignmentList";
import JudgingCirclesJudgeAssignmentList from "./JudgingCirclesJudgeAssignmentList";


interface IProps {
    state : IState;
}

const JudgingCirclesAssignmentManager = (props:IProps) =>{
    const [info, setInfo] = useState<IAssignmentInfo>();
    const [cateDic, setCateDic] = useState<Record<string, string>>({});
    const [divDic, setDivDic] = useState<Record<string, string>>({});
    const [roundDic, setRoundDic] = useState<Record<string, string>>({});

    useEffect(()=>{
        init();
    },[]);

    const init = ()=>{
        store.server.getApi<ServerResponse<IAssignmentInfo>>(`../JudgingCircles/AssignmentInfo`)
        .then(x=>{
            if(x.Success){
                let cDic = {};
                let dDic = {};
                let rDic = {};
                x.Value.Categories.forEach(y=>{ cDic[y.id] = y.name});
                x.Value.Rounds.forEach(y=>{ cDic[y.id] = y.name});
                x.Value.Divisions.forEach(y=>{ dDic[y.id] = y.name});
                setInfo(x.Value);
                setCateDic(cDic);
                setDivDic(dDic);
                setRoundDic(rDic);
            } else {
                toast.error(x.Message);
            }
        });
    };

    return <>


    
    <Tabs>
            <TabList>
              <Tab>Entries</Tab>
              <Tab>Judges</Tab>
            </TabList>
            <TabPanel>
              <h3>Entry Circle Assignments</h3>
              {info && <JudgingCirclesProjectAssignmentList
                categoriesDic={cateDic}
                divisionsDic={divDic}
                info={info}
                roundDic={roundDic}
                state={props.state}                
                onRemove={(assignment:IProjectAssignment)=>{
                    let assignments = info.ProjectAssignments.filter(x=>x.ProjectId !== assignment.ProjectId || x.RoundId !== assignment.RoundId);
                    setInfo({...info,ProjectAssignments:assignments});
                }}
                onUpdate={(assignment:IProjectAssignment)=>{
                    let assignments = info.ProjectAssignments.filter(x=>x.ProjectId !== assignment.ProjectId || x.RoundId !== assignment.RoundId);
                    assignments.push(assignment);
                    setInfo({...info, ProjectAssignments:assignments});
                }}
              />}
            </TabPanel>
            <TabPanel>
              <h3>Judge Circle Assignments</h3>
              {info && <JudgingCirclesJudgeAssignmentList
                categoriesDic={cateDic}
                divisionsDic={divDic}
                info={info}
                roundDic={roundDic}
                state={props.state}                
                onRemove={(assignment:IJudgeAssignment)=>{
                    let assignments = info.JudgeAssignments.filter(x=>x.JudgeId !== assignment.JudgeId || x.RoundId !== assignment.RoundId);
                    setInfo({...info,JudgeAssignments:assignments});
                }}
                onUpdate={(assignment:IJudgeAssignment)=>{
                    let assignments = info.JudgeAssignments.filter(x=>x.JudgeId !== assignment.JudgeId || x.RoundId !== assignment.RoundId);
                    assignments.push(assignment);
                    setInfo({...info, JudgeAssignments:assignments});
                }}
              />}
            </TabPanel>
          </Tabs>

    </>;
}

export default JudgingCirclesAssignmentManager