import React, { useEffect, useState } from 'react'

const FirstTimeModal = (props: any) => {
  const [dontShow, setDontShow] = useState(false)

  useEffect(() => {
    if(dontShow) {
      let judgeObj = {
        dontShow: true,
        fairId: props.fairId
      }
      window.localStorage.setItem('eventApp', JSON.stringify(judgeObj));
    }
  }, [dontShow])

  return(
    <div>
      <div>
        <h3>Video & Audio Tips and Tricks</h3>
        <ul>
          <li><strong>Close other applications</strong> - Make sure your device, just like yourself, is focused on giving you and others the best experience possible </li>
          <li><strong>Wait Patiently</strong> - Sometimes connections can take up to a minute to fully connect everyone.</li>
          <li><strong>No Video or Audio</strong> - See what camera, microphone and speaker devices are currently being used for the meeting and adjust them  to the correct / preferred ones</li>
          <li><strong>Still Not Connecting</strong> - Try pressing the red 'Hang up' button then rejoining again by pressing the blue 'Enter Meeting' button</li>
          <li><strong>Use Chrome</strong> - Try using the Google Chrome browser</li>
          <li><strong>Still not Working</strong> - Try a different device</li>
        </ul>
      </div>
      <div className="flex-between">
        <button className='btn btn-primary' onClick={() => props.setModalOpen(false)}>Close</button>
        
      <div>
        <input type='checkbox' id="dont-show-again-cb" onChange={e => setDontShow(e.target.checked)} defaultChecked={dontShow} />
        <label htmlFor="dont-show-again-cb">don't show this again</label>
      </div>
        </div>
    </div>
  )
}

export default FirstTimeModal