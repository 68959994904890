import { useEffect, useState } from 'react';
import { IAward, IListItem, IProject } from '../../redux/judgingResults/types';
import { Selectable } from '../../utils/CommonTypes/CommonTypes';
import Icon, { IconType } from '../Icon/Icon';
import Select from 'react-select';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { SortProjectsByScore } from './JudgingResultsContainer';
import StoreX from '../../redux/oldStore';
import { ServerResponse } from '../../utils/Server';
import { toast } from 'react-toastify';

type ProjectSort = 'default' | 'averageScore' | 'zScore' | 'adjustedScore';

interface IProps {
  categories: IListItem[];
  divisions: IListItem[];
  categoryAwards: IAward[];
  projects: IProject[];
  filterOptions: Selectable[];
  awardsAssigned: () => void;
}

interface IAwardInstruction {
  SplitOnCategory: boolean;
  SplitOnDivision: boolean;
  Awards: ICateAwardInstruction[];
  RankBy: ProjectSort;
}

interface ICateAwardInstruction {
  Id: number;
  Name: string;
  NumberToAward?: number;
  DefaultAward?: boolean;
  TopX?: number;
}

interface IAssignedAward {
  ProjectIntId: number;
  ProjectKey: string;
  AwardId: number;
}

const AutoAssignJudgingResults = (props: IProps) => {
  const [instruction, setInstruction] = useState<IAwardInstruction>({ SplitOnCategory: props.categories.length > 1, SplitOnDivision: props.divisions.length > 0, Awards: [], RankBy: 'zScore' });
  const [awardsToAssign, setAwardsToAssign] = useState<IAssignedAward[]>([]);
  const [awardDic, setAwardDic] = useState<Record<number,IAward>>({});
  const [projectDic, setProjectDic] = useState<Record<number,IProject>>({});

  useEffect(() => {
    let awards: ICateAwardInstruction[] = [];
    let aDic:Record<number,IAward> = {};

    props.categoryAwards?.forEach((x) => {
      awards.push({
        Id: x.Id,
        Name: x.Name,
        NumberToAward: x.Points > 0 ? 1 : 0,
      });
      aDic[x.Id] = x;
    });

    setInstruction({ ...instruction, Awards: awards });

    let pDic:Record<number,IProject> = {};
    props.projects.forEach(x=>{
        pDic[x.ProjectIntId] = x;
    });

    setAwardDic(aDic);
    setProjectDic(pDic);

  }, []);

  useEffect(() => {
    let awards: IAssignedAward[] = [];
    if(instruction.SplitOnCategory && instruction.SplitOnDivision){
        props.divisions.forEach(d=>{
            let divisionProjects = [...props.projects].filter(p=>p.DivisionId === +d.id);
            props.categories.forEach(c=>{
                AssignToThisGroup(instruction, divisionProjects.filter(p=>p.CategoryId === +c.id), awards);
            });
        });

    } else if(instruction.SplitOnCategory) {
        props.categories.forEach(x=>{
            AssignToThisGroup(instruction, [...props.projects].filter(p=>p.CategoryId === +x.id), awards);
        });

    } else if(instruction.SplitOnDivision){
        props.divisions.forEach(x=>{
            AssignToThisGroup(instruction, [...props.projects].filter(p=>p.DivisionId === +x.id), awards);
        });

    } else {
        AssignToThisGroup(instruction, [...props.projects], awards);
    }

    setAwardsToAssign(awards);
    console.log(awards);

  }, [instruction]);

  const AssignToThisGroup = (inst: IAwardInstruction, projects: IProject[], awards: IAssignedAward[]) => {
    let sortedProjects = SortProjectsByScore(inst.RankBy, projects);

    inst.Awards.forEach((x) => {
      for (let i = 0; i < (x.NumberToAward ?? 0); i++) {
        let topProject = sortedProjects[0];
        if(topProject){
        awards.push({ AwardId: x.Id, ProjectIntId: topProject.ProjectIntId, ProjectKey: topProject.ProjectKey });
        sortedProjects = sortedProjects.splice(1);
        } else {break;}
      }
    });
  };

  const AssignAwards = () => {
    if (!window.confirm(`Are you sure you want to assign category awards; this will clear the current awards?`)) {
      return;
    }

    let reqBody = {
        assignAwards: awardsToAssign,
        ClearAllAssignments:true
      };
      StoreX.instance.server
        .postApi<ServerResponse<any>>('../JudgingResults/AssignAwards', reqBody)
        .then((x) => {
          if(x.Success){
            toast.success(`Awards Assigned!`);
            props.awardsAssigned();
          } else {
            toast.error(`Error trying to assign awards... ${x.Message}`, {autoClose:false});
          }
        })
        .catch((err) => {
          console.error(err);
          toast.error(`Error trying to assign awards... ${err}`, {autoClose:false});
        });
    ///Assign Awards...
    ///Send to Server...
  };

  return (
    <>
      <div className="alert alert-info">
        Assigning awards should be fast and easy. Even if we don't assign all the awards the way you want, remeber you can tweak and reassign awards after you auto assign awards.
      </div>

<Tabs>
    <TabList>
        <Tab><Icon type={IconType.magic}/> Wizard </Tab>
        <Tab><Icon type={IconType.award}/> Preview </Tab>        
    </TabList>
    <TabPanel>
              <div className="form-horizontal">
        <h3>How are awards assigned?</h3>
        <div className="form-group">
          <label className="control-label col-sm-4">Rank projects by</label>
          <div className="col-sm-3">
            <select className="form-control" value={instruction.RankBy} onChange={(e) => setInstruction({ ...instruction, RankBy: e.target.value as ProjectSort })}>
              <option value="default">Project Id</option>
              <option value="averageScore">Average Score</option>
              <option value="adjustedScore">Adjusted Score</option>
              <option value="zScore">Average Z-Score</option>
            </select>
          </div>
        </div>
        {props.categories.length > 1 && (
          <div className="form-group">
            <div className="col-sm-11 col-sm-offset-1">
              <input
                id="insoc"
                type="checkbox"
                checked={instruction.SplitOnCategory}
                onChange={(e) => {
                  setInstruction({ ...instruction, SplitOnCategory: e.target.checked });
                }}
              />
              <label className="control-label" htmlFor="insoc">
                Each category has it's own set of awards.
              </label>
              <div>For example each category will have a 1st place, 2nd place, etc.</div>
            </div>
          </div>
        )}
        {props.divisions.length > 0 && (
          <div className="form-group">
            <div className="col-sm-11 col-sm-offset-1">
              <input
                id="insod"
                type="checkbox"
                checked={instruction.SplitOnDivision}
                onChange={(e) => {
                  setInstruction({ ...instruction, SplitOnDivision: e.target.checked });
                }}
              />
              <label className="control-label" htmlFor="insod">
                Awards are broken up by division
              </label>
              {instruction.SplitOnCategory ? (
                <div>For example each division each category will have a 1st palce, 2nd place, etc</div>
              ) : (
                <div>For example each division will have a 1st palce, 2nd place, etc</div>
              )}
            </div>
          </div>
        )}
        <h3>How many of each award do you want assigned?</h3>
        Awards are assigned in order, then moved to the next group.
        {instruction.Awards.map((x, i) => {
          return (
            <div className="form-group" key={`aajr-acs-${i}`}>
              <label className="control-label col-sm-4" htmlFor={`ants-${x.Id}`}>
                {x.Name}
              </label>
              <div className="col-sm-2">
                <input
                  id={`ants-${x.Id}`}
                  type="number"
                  value={x.NumberToAward ?? 0}
                  className="form-control"
                  onChange={(e) => {
                    let awards = instruction.Awards;
                    awards[i].NumberToAward = e.target.valueAsNumber;
                    setInstruction({ ...instruction, Awards: [...awards] });
                  }}
                />
              </div>
            </div>
          );
        })}
        <div className="form-group">
          <div className="col-sm-12">
            <button type="button" className="btn btn-secondary" onClick={AssignAwards}>
              <Icon type={IconType.award} /> Assign Awards
            </button>
          </div>
        </div>
      </div>
    </TabPanel>
    <TabPanel>
        <h3>Award Assignment Preview</h3>
        <div className='project-award-preview-list'>
        {awardsToAssign.map((x,i)=>{
            let award = awardDic[x.AwardId];
            let project = projectDic[x.ProjectIntId];

            return <div key={`atapre-${i}`} className='flex-between project-award-preview'>
                <div>
                    <strong>{project.ProjectId}</strong>
                    {instruction.SplitOnCategory && <div className='label lable-info'>{project.Category}</div>}
                    <br/>
                    {project.Title}
                </div>
                <div>
                    <span className='label label-default'><Icon type={IconType.award}/> {award.Name}</span>
                </div>
            </div>
        })}
        </div>
    </TabPanel>
</Tabs>


    </>
  );
};

export default AutoAssignJudgingResults;
