import { toast } from 'react-toastify';
import StoreX, { store } from '../../../redux/oldStore';
import { IState } from '../../../redux/redux';
import { ServerResponse } from '../../../utils/Server';
import Icon, { IconType } from '../../Icon/Icon';
import { IAdminPanelInfo, IAdminPanelRoomInfo, IAdminPanelRoundDetailsInfo } from './NhdTypes';
import { useEffect, useState } from 'react';
import Modal from '../../Modal/Modal';

interface IProps {
  state: IState;
}

interface ISetJudgeGroupForm{
    roomId?:number;
    roundId?:number;
    groupId?:number;
    assignmentId?:number;
}

const NhdAdminPanel = (props: IProps) => {
  const [info, setInfo] = useState<IAdminPanelInfo>();
  const [categoryDic, setCategoryDic] = useState<Record<number, string>>({});
  const [divisionDic, setDivisionDic] = useState<Record<number, string>>({});
  const [showSelectJudgeGroup, setShowSelectJudgeGroup] = useState<boolean>(false);
  const [judgeGroupForm, setJudgeGroupForm] = useState<ISetJudgeGroupForm>();

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    store.server.postApi<ServerResponse<IAdminPanelInfo>>(`../ClientNhdJudging/AdminPanelInfo`, {}).then((x) => {
      if (x.Success) {

        x.Value.Rounds?.sort((a,b)=>{return a.CategoryName.toLowerCase() > b.CategoryName.toLowerCase() ? 1:-1;});
        let cDic = {};
        x.Value.Categories.forEach((y) => {
          cDic[y.id] = y.name;
        });
        setCategoryDic(cDic);
        let dDic = {};
        x.Value.Divisions.forEach((y) => {
          dDic[y.id] = y.name;
        });
        setDivisionDic(dDic);
        let jgDic = {};
        x.Value.JudgeGroups.forEach((y) => {
          jgDic[y.id] = y.name;
        });
        x.Value.JudgeGroupDic = jgDic;
        setInfo(x.Value);
      } else {
        toast.error(x.Message);
      }
    });
  };

  const onToggleLock = (lock: boolean, roomId: number, roundId: number, assignmentId:number) => {
    toast.dismiss();

    store.server.postApi<ServerResponse<boolean>>(`../ClientNhdJudging/LockJudgingRoom`, {lock, assignmentId})
    .then(x=>{
        if(x.Success){
            init();
            toast.info(lock?'Room locked!':'Room unlocked!')
        } else {
            toast.error(x.Message, {autoClose:false});
        }
    });

  };

  const onOpenSetJudgeGroup = (roomId: number, roundId: number, assignmentId?:number, judgeGroup?:number) => {
    setShowSelectJudgeGroup(true);
    setJudgeGroupForm({roomId:roomId, roundId:roundId, assignmentId:assignmentId, groupId:judgeGroup});
  };

  const saveJudgeGroup = ()=>{
    toast.dismiss();
    store.server.postApi<ServerResponse<boolean>>(`../ClientNhdJudging/SaveAssignedJudgeGroup`, judgeGroupForm)
    .then(x=>{
        if(x.Success){
            init();
            setJudgeGroupForm(undefined);
            setShowSelectJudgeGroup(false);
            toast.info('Judge group assignment saved!');
        } else {
            toast.error(x.Message, {autoClose:false});
        }
    });};
  const removeJudgeGroup = ()=>{
    toast.dismiss();
    if(!window.confirm(`Are you sure you want to remove this judging group assignment?`)){
        return;
    }
    store.server.postApi<ServerResponse<boolean>>(`../ClientNhdJudging/RemoveAssignedJudgeGroup`, {roomId:judgeGroupForm?.roomId, roundId:judgeGroupForm?.roundId})
    .then(x=>{
        if(x.Success){
            init();
            setJudgeGroupForm(undefined);
            setShowSelectJudgeGroup(false);
            toast.info('Judge group assignment removed!');
        } else {
            toast.error(x.Message, {autoClose:false});
        }
    });

  };

  const mapRooms = (parentKey: string, rooms: IAdminPanelRoomInfo[], roundId: number) => {
    if (!rooms || rooms.length === 0) {
      return (
        <div className="alert alert-info">
          <h4>
            <Icon type={IconType.warning} /> No Rooms
          </h4>
          There are no rooms for this round.
        </div>
      );
    } else {
      return (
        <div className="nhd-admin-panel-rooms-list">
          <table className="table table-striped">
            {info && rooms.map((c, i) => {
              return (
                <tr className="nhd-admin-panel-room" key={`${parentKey}-${i}`}>
                  <td className='nhd-tb-25'>
                    {c.AssignmentId && <>
                    {!c.IsLocked ? (
                      <span
                        className="click text-success"
                        title="Room is NOT locked."
                        onClick={() => {
                          onToggleLock(true, c.Id, roundId, c.AssignmentId);
                        }}>
                        <Icon type={IconType.unlock} />
                      </span>
                    ) : (
                      <span
                        className="click text-danger"
                        title="Room is locked."
                        onClick={() => {
                          onToggleLock(false, c.Id, roundId, c.AssignmentId);
                        }}>
                        <Icon type={IconType.lock} />
                      </span>
                    )}</>}
                  </td>
                  <td className='nhd-tb-25'>
                    {c.PrecentDone !== 'N/A' && <>{c.PrecentDone}%</>}
                  </td>
                  <td>
                    {c.Name}
                    <div className="details">
                      {c.Building && <span>Building: {c.Building}</span>}                      
                      </div>
                  </td>
                  <td className='nhd-tb-25'>
                    <span
                      className="click text-info"
                      title="Set Judge Group"
                      onClick={() => {
                        onOpenSetJudgeGroup(c.Id, roundId, c.AssignmentId, c.JudgeGroup);
                      }}>
                      <Icon type={IconType.users} />
                    </span>
                  </td>
                  <td  className='nhd-tb-60'>
                    {(c.JudgeGroup ?? 0) > 0 && <span> {info.JudgeGroupDic[c.JudgeGroup] ?? c.JudgeGroup} </span>}
                    {(c.JudgeGroup ?? 0) > 0 && (
                        <div className='details'>
                      <a href={StoreX.BuildUrl(`../JudgePortal/JudgeGroup/${c.JudgeGroup}`)} target='_blank' rel="noreferrer">
                        <Icon type={IconType.fileInvoice} /> View Assignments
                      </a></div>
                      
                    )}
                    </td>
                </tr>
              );
            })}
          </table>
        </div>
      );
    }
  };

  const mapRounds = (parentIndex: number, rounds: IAdminPanelRoundDetailsInfo[]) => {
    if (!rounds || rounds.length === 0) {
      return (
        <div className="alert alert-info">
          <h4>
            <Icon type={IconType.warning} /> No Rounds
          </h4>
          There are no judging rounds for this category.
        </div>
      );
    } else {
      return (
        <div className="nhd-admin-panel-rounds-list">
          {rounds.map((c, i) => {
            return (
              <div className={`nhd-admin-panel-rounds ${c.IsFinalRound ? 'finals' : ''}`} key={`nhd-apc-pi-${parentIndex}-${i}`}>
                <div className="flex-between">
                  <div>
                    <h5>
                      {c.Name}{' '}
                      {c.IsFinalRound && (
                        <span className="nhd-finals">
                          {' '}
                          <Icon type={IconType.flag} /> Finals{' '}
                        </span>
                      )}
                    </h5>
                  </div>
                  <div className="text-right">
                    <div>
                      <a href={StoreX.BuildUrl(`../JudgePortal/PrintRankingSheet?RoundId=${c.Id}&roomId=-1`)} target='_blank'  rel="noreferrer">
                        <Icon type={IconType.print} /> Ranking Sheets
                      </a>
                    </div>
                    <div>
                      <a href={StoreX.BuildUrl(`../JudgePortal/PrintEval?RoundId=${c.Id}&entryId=-1&roomId=-1`)} target='_blank' rel="noreferrer">
                        <Icon type={IconType.print} /> Eval Sheets
                      </a>
                    </div>
                    <div>Rooms: {c.Rooms?.length ?? 0}</div>
                  </div>
                </div>

                <hr/>

                {mapRooms(`nhd-apc-pi-${parentIndex}-${i}`, c.Rooms, c.Id)}
              </div>
            );
          })}
        </div>
      );
    }
  };

  const mapCategories = () => {
    if (!info || (info.Rounds?.length ?? 0) === 0) {
      return (
        <div className="alert alert-info">
          <h4>
            <Icon type={IconType.warning} /> No Rounds
          </h4>
          There are no judging categories.
        </div>
      );
    } else {
      return (
        <div className="nhd-admin-panel-categories-list">
          {info.Rounds.map((c, i) => {
            return (
              <div className="nhd-admin-panel-category" key={`nhd-apc-${i}`}>
                <div className="flex-between header">
                  <div>
                    <h4>{c.CategoryName}</h4>
                  </div>
                  <div>Rounds: {c.Rounds?.length ?? 0}</div>
                </div>

                {mapRounds(i, c.Rounds)}
              </div>
            );
          })}
        </div>
      );
    }
  };

  return (
    <>
      <div className="flex-between">
        <div>
          <h3>
            <Icon type={IconType.dashboard} /> Admin Panel
          </h3>
        </div>
        <div>
          <a className="btn btn-xs btn-default" href={StoreX.BuildUrl(`../JudgePortal/PrintAllEvals?finals=false`)} target="_blank">
            <Icon type={IconType.print} addClass="text-info" /> Print Evals
          </a>
          <a className="btn btn-xs btn-default" href={StoreX.BuildUrl(`../JudgePortal/PrintAllEvals?finals=true`)} target="_blank">
            <Icon type={IconType.flag} addClass="text-danger" /> Print Finals Evals
          </a>
        </div>
      </div>

      {mapCategories()}

      {showSelectJudgeGroup && info && <Modal
        size='m'
        setModalOpen={setShowSelectJudgeGroup}
        title='Select Judge Group'
      >
        <div className='form-horizontal'>
            <div className='form-group'>
                <div className='col-sm-4'></div>
                <div className='col-sm-8'></div>
            </div>
            <div className='form-group'>
                <label className='col-sm-4 control-label' htmlFor='nhd-ap-jgf'>Judge Group</label>
                <div className='col-sm-8'>
                    <select
                        id={`nhd-ap-jgf`}
                        className='form-control'
                        value={judgeGroupForm?.groupId ?? 0}
                        onChange={(x)=>{setJudgeGroupForm({...judgeGroupForm,groupId:+x.target.value});}}
                    >
                        <option value={0}>Select</option>
                        {info.JudgeGroups.map((x,i)=>{
                            return <option value={x.id} key={`nhd-ap-jgf-${i}`}>{x.name}</option>
                        })}
                    </select>
                </div>
            </div>
            <div className='form-group'>
                <div className='col-sm-4'></div>
                <div className='col-sm-8'>
                    <button type={'button'} className='btn btn-secondary' onClick={saveJudgeGroup}>
                        <Icon type={IconType.save}/> Save
                    </button>
                    {(judgeGroupForm?.assignmentId ?? 0) > 0 && <button type={'button'} className='btn btn-default' onClick={removeJudgeGroup}>
                        <Icon type={IconType.trashO}/> Remove
                    </button>}
                </div>
            </div>
        </div>
        </Modal>}
    </>
  );
};

export default NhdAdminPanel;
