import React, { useState, useEffect } from "react";
import Icon, { IconType } from "../Icon/Icon";
import NewSite from "./Content/NewSite";
import RefreshSite from "./Content/RefreshSite";

const SetupStart = () => {
  const [setupType, setSetupType] = useState<"new" | "refresh" | "unknown">(
    "unknown"
  );


  const GetWizardTitle = ()=>{
      switch(setupType){
        case 'new':
        return 'New Contest Setup Guide';
        break;
        case 'refresh':
        return 'Contest Refresh Guide';
        break;
      }
  };

  const SetupContent = () =>{
    switch(setupType){
        case 'new':
        return <NewSite />;
        break;
        case 'refresh':
        return <RefreshSite />;
        break;
      }
  };

  return (
    <div className="col-sm-12">
      {setupType === "unknown" && (
        <div>
          <div className="start-heading">
            <h3>Let's Get Started...</h3>
          </div>
          <div>
            Let's get started, select an option below to start and we can get
            you pointed in the right direction.
          </div>
          <div className="alert alert-info">
            <h4>
              <Icon type={IconType.warning} /> New Feature Warning!
            </h4>
            <p>
            We are supper excited about this new feature but it is just now
            being developed, so this is just the most basic concept of what we
            are creating. Feel free to let us know how we can make it better.
                Our goal is to make is so that a user could setup or refresh their site so it is ready for registration and judging in 5 minutes.
            </p>
          </div>
          <hr />
          <div className="wiz-setup-types-container flex-centered">
            <div className="wiz-setup-type-tile">
              <div>
                <h3>First Time Setup</h3>
                <hr />
              </div>
              <div className="flex-grow alert alert-danger">
                  Coming soon.
              </div>
              <div>
                <hr />
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    setSetupType("new");
                  }}
                  disabled={true}
                >
                  Start
                </button>
              </div>
            </div>
            <div className="wiz-setup-type-tile">
              <div>
                <h3>Refresh Site</h3>
                <hr />
              </div>
              <div className="flex-grow">
                Select this to refresh your site for your next contest.
              </div>
              <div>
                <hr />
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    setSetupType("refresh");
                  }}
                >
                  Start
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {setupType !== "unknown" && (
        <div>
          <div className="flex-between">
            <h3>{GetWizardTitle()}</h3>
            <div>
                <button type="button" className="btn btn-default"
                onClick={()=>{setSetupType('unknown')}}>
                    <Icon type={IconType.close}/> Exit
                </button>
            </div>
          </div>
          <hr/>
          <div>
            {SetupContent()}
          </div>
        </div>
      )}
    </div>
  );
};

export default SetupStart;
