import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { setAssignments, setProjectsAssignments, setJudgesAssignments } from '../../redux/judgingSetup/actions';
import JudgingRoundJudgeAssignment from './JudgingRoundJudgeAssignment';
import JudgingRoundProjectAssignment from './JudgingRoundProjectAssignment';
import JudgingAssignmentLoad from './JudgingAssignmentLoad';
import JudgingRoundSetup from './JudgingRoundSetup';
import { store } from '../../redux/oldStore';
import { toast } from 'react-toastify';
import Modal from '../Modal/Modal';
import EditAssignment from './EditAssignment';
import JudgingJudgeAssignmentModal from './JudgingJudgeAssignmentModal';
import JudgingProjectAssignmentModal from './JudgingProjectAssignmentModal';
import { ServerResponse } from '../../utils/Server';

interface IProps extends IJudgingActions {
  roundIndex: any;
  round: any;
  projects: any;
  categories: any;
  divisions: any;
  judges: any;
  exit: () => void;
  judgesByKey: any;
  projectsByKey: any;
}

const JudgingRoundDetails = (props:IProps) => {
  const [projectSearchValue, setProjectSearchValue] = useState('');
  const [judgeSearchValue, setJudgeSearchValue] = useState('');
  const [thisProjects, setThisProjects] = useState([]);
  const [thisProjectsByKey, setThisProjectsByKey] = useState({});
  const [thisJudges, setThisJudges] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [filteredJudges, setFilteredJudges] = useState([]);
  const [selectedAssignment, setSelectedAssignment] = useState();
  const [editAssignmentOpen, setEditAssignmentOpen] = useState(false);
  const [judgingSessions, setJudgingSessions] = useState([]);
  const [roundProjectsArr, setRoundProjectsArr] = useState([]);
  const [roundProjectsDict, setRoundProjectsDict] = useState<any>({});
  const [roundJudgesArr, setRoundJudgesArr] = useState([]);
  const [roundJudgesDict, setRoundJudgesDict] = useState<any>({});
  const [assignmentModalShow, setAssignmentModalShow] = useState(false);
  const [selectedProject, setSelectedProject] = useState<any>({});
  const [selectedJudge, setSelectedJudge] = useState<any>({});

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    getAssignments();
  }, []);

  useEffect(() => {
    console.log('updating assignmetns useEffect');
    let pa = {};
    props.assignments.forEach((x) => {
      if (!pa[x.ProjectId]) pa[x.ProjectId] = [];
      pa[x.ProjectId].push(x);
    });
    props.setProjectsAssignments(pa);

    let ja = {};
    props.assignments.forEach((x) => {
      if (!ja[x.JudgeId]) ja[x.JudgeId] = [];
      ja[x.JudgeId].push(x);
    });
    props.setJudgesAssignments(ja);
  }, [props.assignments]);

  useEffect(() => {
    let theseProjects = props.projects.filter((proj) => roundProjectsDict[proj.ProjectKey]);
    setThisProjects(theseProjects);
  }, [props.projects, roundProjectsDict]);

  useEffect(() => {
    let theseJudges = props.judges.filter((judge) => roundJudgesDict[judge.PublicId]);
    setThisJudges(theseJudges);
  }, [props.judges, roundJudgesDict]);

  useEffect(() => {
    let value = projectSearchValue.toLowerCase().trim();
    if (value.length === 0) {
      return setFilteredProjects(thisProjects);
    }
    return setFilteredProjects(thisProjects.filter((project) => project.ProjectId.toLowerCase().includes(value) || project.Title.toLowerCase().includes(value)));
  }, [thisProjects, projectSearchValue]);

  useEffect(() => {
    let value = judgeSearchValue.toLowerCase().trim();
    if (value.length === 0) {
      return setFilteredJudges(thisJudges);
    }
    return setFilteredJudges(thisJudges.filter((judge) => `${judge.FirstName} ${judge.LastName}`.toLowerCase().includes(value)));
  }, [thisJudges, judgeSearchValue]);

  useEffect(() => {
    if (roundProjectsArr.length > 0) {
      let projectDict = roundProjectsArr.reduce((dict, string) => {
        return { ...dict, [string]: true };
      }, {});
      setRoundProjectsDict(projectDict);
    }
  }, [roundProjectsArr]);

  useEffect(() => {
    if (roundJudgesArr.length > 0) {
      let judgeDict = roundJudgesArr.reduce((dict, string) => {
        return { ...dict, [string]: true };
      }, {});
      setRoundJudgesDict(judgeDict);
    }
  }, [roundJudgesArr]);

  const removeAssignment = (assignmentId) => {
    let [assignment] = props.assignments.filter((assignment) => assignment.Id === assignmentId);
    if (!assignment) return;
    let judge = props.judgesByKey[assignment.JudgeId];
    if (!judge) return;
    let project = props.projectsByKey[assignment.ProjectId];
    if (!window.confirm(`Are you sure you want to remove this assignment from ${judge.FirstName} ${judge.LastName} to judge ${project.ProjectId}?`)) {
      return;
    }
    let cb = () => {
      props.setAssignments(props.assignments.filter((assignment) => assignment.Id !== assignmentId));
    };
    store.server
      .postApi('../JudgingManagement/RemoveAssignment', {
        assignmentId: assignmentId,
      })
      .then((res) => {
        console.log(res);
        cb();
      })
      .catch((err) => {
        console.error(err);
        toast.warning('something went wrong');
      });
  };

  const editAssignment = (assignmentId) => {
    let [assignment] = props.assignments.filter((assignment) => assignment.Id === assignmentId);
    if (!assignment) return;

    let judge = props.judgesByKey[assignment.JudgeId];
    if (!judge) return;
    let project = props.projectsByKey[assignment.ProjectId];

    //open
    setSelectedAssignment(assignment);
    setEditAssignmentOpen(true);
    setSelectedJudge(judge);
    setSelectedProject(project);
    //create modal...
  };

  const mappedFilteredProjects = filteredProjects.map((project, index) => {
    return (
      <JudgingRoundProjectAssignment
        key={`${project.ProjectId}${index}`}
        project={project}
        assignments={props.projectsAssignments[project.ProjectIntId] || []}
        judges={props.judgesByKey}
        round={props.round}
        setModalOpen={setAssignmentModalShow}
        setSelectedProject={setSelectedProject}
        setSelectedJudge={setSelectedJudge}
        removeAssignment={removeAssignment}
        editAssignment={editAssignment}
      />
    );
  });

  const mappedFilteredJudges = filteredJudges.map((judge, index) => {
    return (
      <JudgingRoundJudgeAssignment
        key={`${judge.Id}${index}`}
        projects={props.projectsByKey}
        assignments={props.judgesAssignments[judge.Id]}
        judge={judge}
        round={props.round}
        setModalOpen={setAssignmentModalShow}
        setSelectedProject={setSelectedProject}
        setSelectedJudge={setSelectedJudge}
        removeAssignment={removeAssignment}
        editAssignment={editAssignment}
      />
    );
  });

  const getAssignments = () => {
    let request = {
      roundId: props.round.Id,
    };
    store.server
      .postApi<ServerResponse<any>>('../JudgingManagement/RoundAssignments', request)
      .then((res) => {
        props.setAssignments(res.Value.Assignments);
        if (res.Value.JudgingSessions && res.Value.JudgingSessions.length > 0) {
          setJudgingSessions(res.Value.JudgingSessions);
        }
        setRoundProjectsArr(res.Value.projects);
        setRoundJudgesArr(res.Value.judges);
      })
      .catch((err) => console.error(err));
  };

  return (
    <>
      {props.round && (
        <div>
          <div className="flex-between">
            <div className="">
              <h3>{props.round.Name}</h3>
              Round Value: {props.round.RoundValue}
            </div>
            <div className="">
              <span className="hover-danger" onClick={props.exit}>
                <i className="fad fa-2x fa-portal-exit"></i>
                Close
              </span>
            </div>
          </div>
          <hr />
          <div className="head-room"></div>
          <div>
            <ul className="nav nav-tabs" role="tablist">
              <li role="presentation" className="active">
                <a href="#round-details-projects" aria-controls="round-details-projects" role="tab" data-toggle="tab">
                  <i className="fad fa-box-full"></i>
                  Projects
                </a>
              </li>
              <li role="presentation" className="">
                <a href="#round-details-judges" aria-controls="round-details-judges" role="tab" data-toggle="tab">
                  <i className="fad fa-balance-scale-right"></i>
                  Judges
                </a>
              </li>
              <li role="presentation" className="">
                <a href="#round-details-setup" aria-controls="round-details-setup" role="tab" data-toggle="tab">
                  <i className="fad fa-cogs"></i>
                  Auto Assign Judges
                </a>
              </li>
              <li role="presentation" className="">
                <a href="#round-details-load" aria-controls="round-details-load" role="tab" data-toggle="tab">
                  <i className="fad fa-cloud-upload"></i>
                  Load Assignments
                </a>
              </li>
            </ul>
          </div>
          <div className="head-room"></div>
          <div className="tab-content">
            <div role="tabpanel" className="tab-pane active" id="round-details-projects">
              {thisProjects ? (
                <div>
                  <div className="flex-between">
                    <div>
                      <h3>Projects</h3> With their judging assignments for this round. You can add additional judges to a project by clicking on the <i className="fad fa-user-plus"></i> icon.
                    </div>
                    <div>
                      <div className="input-group">
                        <input
                          // v-model="projectSearchValue"
                          onChange={(e) => setProjectSearchValue(e.target.value)}
                          className="form-control"
                          id="z-index-control1"
                          placeholder="search"
                        />
                        <span className="input-group-addon">
                          <i className="fa fa-search"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  {mappedFilteredProjects}
                </div>
              ) : (
                <div>
                  <div className="head-room"></div>
                  <h1 className="text-muted">
                    <i className="fad fa-spinner fa-spin"></i>Loading...
                  </h1>
                  <div className="head-room"></div>
                </div>
              )}
            </div>
            <div role="tabpanel" className="tab-pane" id="round-details-judges">
              {thisJudges ? (
                <div>
                  <div className="flex-between">
                    <div>
                      <h3>Judges</h3> With their judging assignments for this round. You can add additional projects to a judge by clicking on the <i className="fad fa-box-open"></i> icon.
                    </div>
                    <div>
                      <div className="input-group">
                        <input
                          // v-model="judgeSearchValue"
                          onChange={(e) => setJudgeSearchValue(e.target.value)}
                          className="form-control "
                          id="z-index-control2"
                          placeholder="search"
                        />
                        <span className="input-group-addon">
                          <i className="fa fa-search"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  {mappedFilteredJudges}
                </div>
              ) : (
                <div>
                  <div className="head-room"></div>
                  <h1 className="text-muted">
                    <i className="fad fa-spinner fa-spin"></i>Loading...
                  </h1>
                  <div className="head-room"></div>
                </div>
              )}
            </div>
            <div role="tabpanel" className="tab-pane" id="round-details-setup">
              <div className="head-room"></div>
              <JudgingRoundSetup
                judges={thisJudges || []}
                round={props.round}
                categories={props.categories || []}
                divisions={props.divisions || []}
                projectsByCategory={props.projectsByCategory}
                projects={thisProjects || []}
                setAssignments={props.setAssignments}
                judgingSessions={judgingSessions}
              />
              <div className="head-room"></div>
            </div>
            <div role="tabpanel" className="tab-pane" id="round-details-load">
              <div className="head-room"></div>
              <JudgingAssignmentLoad
                judges={thisJudges || []}
                round={props.round}
                categories={props.categories || []}
                divisions={props.divisions || []}
                projectsByCategory={props.projectsByCategory}
                projects={thisProjects || []}
                setAssignments={props.setAssignments}
                judgingSessions={judgingSessions}
              />
              <div className="head-room"></div>
            </div>
          </div>
        </div>
      )}
      {editAssignmentOpen && (
        <Modal setModalOpen={setEditAssignmentOpen} title="Edit Assignment">
          <div>
            <EditAssignment
              projects={thisProjects}
              projectsByKey={props.projectsByKey}
              judgesByKey={props.judgesByKey}
              judges={thisJudges}
              assignments={props.assignments}
              round={props.round}
              selectedAssignment={selectedAssignment}
              setAssignments={props.setAssignments}
              setModalOpen={setEditAssignmentOpen}
              removeAssignment={removeAssignment}></EditAssignment>
          </div>
        </Modal>
      )}
      {assignmentModalShow && (
        <Modal setModalOpen={setAssignmentModalShow} title={selectedJudge.Id ? `${selectedJudge.FirstName} ${selectedJudge.LastName}` : selectedProject.ProjectId && `${selectedProject.Title}`}>
          {selectedProject.ProjectId && (
            <div>
              <JudgingProjectAssignmentModal
                project={selectedProject}
                assignments={selectedProject.assignments || []}
                judges={thisJudges}
                judgesByKey={props.judgesByKey}
                round={props.round}
                fullAssignments={props.assignments}
                setAssignments={props.setAssignments}
                setModalOpen={setAssignmentModalShow}
              />
            </div>
          )}
          {selectedJudge.Id && (
            <div>
              <JudgingJudgeAssignmentModal
                judge={selectedJudge}
                assignments={props.judgesAssignments[selectedJudge.Id] || []}
                projects={thisProjects}
                projectsByKey={props.projectsByKey}
                round={props.round}
                fullAssignments={props.assignments}
                setAssignments={props.setAssignments}
                setModalOpen={setAssignmentModalShow}
              />
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = (reduxState) => {
  return reduxState.judgingSetup;
};

const actions = {
  setAssignments,
  setProjectsAssignments,
  setJudgesAssignments,
};

export default connect(mapStateToProps, actions)(JudgingRoundDetails);



interface IJudgingActions {
  setProjects?:Function;
  setProjectsByKey?:Function;
  setProjectsByCategory?:Function;
  setCategories?:Function;
  setRounds?:Function;
  setJudges?:Function;
  setJudgesByKey?:Function;
  setPage?:Function;
  setSelectedRoundIndex?:Function;
  setSelectedRound?:Function;
  setSelectedProject?:Function;
  setSelectedJudge?:Function;
  setAssignments?:Function;
  setProjectsAssignments?:Function;
  setJudgesAssignments?:Function;
  setJudgingRoundEditModalShow?:Function;
  setAssignmentModalShow?:Function;
  projects?:any;
  categories?:any;
  rounds?:any;
  selectedRound?:any;
  selectedRoundIndex?:any;
  page?:any;
  selectedProject?:any;
  selectedJudge?:any;
  judges?:any;
  judgesByKey?:any;
  projectsByKey?:any;
  projectsByCategory?:any;
  assignments?:any;
  projectsAssignments?:any;
  judgesAssignments?:any;
  judgingRoundEditModalShow?:any;
  assignmentModalShow?:any;
}