import React from 'react'
import { toast } from 'react-toastify'
import { store } from '../../redux/oldStore'
import { IFair, IFeedRequest } from './FairPromotionLinkingContainer'
import Request from './Request'

interface RequestsToApproveProps {
  fairs: IFair[];
  feederRequests: IFeedRequest[];
  setFeederRequests: Function;
}


const RequestsToApprove = (props: RequestsToApproveProps) => {

  // ../FairPromotion/ApproveLink/${id} --linkId
  // ../FairPromotion/JustNoLink/${id} --linkId


  const handleApproval = (request: IFeedRequest) => {
    
      store.server.getApi(`../FairPromotion/ApproveLink/${request.Id}`)
        .then((res: any) => {
          console.log(res);
          let updatedRequests = props.feederRequests.map( x => x.Id === request.Id ? {...x, ...res.Value.link} : x)
          toast.info('request approved');
          props.setFeederRequests(updatedRequests);
        })
        .catch(err => toast.warning(err));
  
  }

  const handleJustNo = (request: IFeedRequest) => {
      store.server.getApi(`../FairPromotion/JustNoLink/${request.Id}`)
        .then((res: any) => {
          console.log(res)
          let updatedRequests = props.feederRequests.map( x => x.Id === request.Id ? {...x, ...res.Value.link} : x)
          toast.info('approval removed');
          props.setFeederRequests(updatedRequests);
        })
        .catch(err => toast.warning(err));
  
  }
  
  const mappedFeederRequests = props.feederRequests.map( request => {
    return(
      <Request 
        key={request.Id} 
        request={request}
        requests={props.feederRequests}
        setRequests={props.setFeederRequests}
        approve={handleApproval}
        justNo={handleJustNo}

      />
    )
  });


  return(
    <div className='bumper-l bumper-out-l'>
      <h4>Approved & Pending Requests</h4>
      <div>Requests listed here are form events that wish to promote their participants to your event.</div>

      <hr/>
      <div>
        {mappedFeederRequests}
      </div>
    </div>
  )
}

export default RequestsToApprove