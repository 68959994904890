import React from 'react'
import { IPlopInfo, ISettings, GuidToJudgeDict, GuidToProjectDict, ICategory } from './Dashboard';

interface AutoPlopPanelProps {
  loadAutoPlopQueue: any;
  resetAutoPlopQueue: any;
  queueHandel: number;
  plopInfo: IPlopInfo | undefined;
  settings: ISettings
  judgesByGuid: GuidToJudgeDict;
  projectsByGuid: GuidToProjectDict;
  categoriesById: {[id: number]: ICategory};
}

const AutoPlopPanel = (props: AutoPlopPanelProps) => {
  const {
    loadAutoPlopQueue,
    queueHandel,
    plopInfo,
    settings,
    judgesByGuid,
    projectsByGuid,
    categoriesById,
    resetAutoPlopQueue
  } = props


  const formatSeconds = (seconds: number) => {
    let formattedNumber = seconds.toLocaleString('en-US', { minimumIntegerDigits: 2})
    return formattedNumber;
  }

  return (
    <div>
      <h3>Auto Plop</h3>
      <div className="flex-between">
      <button type="button" className="btn btn-default" onClick={loadAutoPlopQueue}>
        {queueHandel > 0 ? <span><i className="fa fa-refresh text-success"></i> Checking Queue</span>
          : <span><i className="fa fa-refresh text-danger"></i> Click to check queue</span>}
      </button>
      <button type="button" className="btn btn-default" onClick={resetAutoPlopQueue}>
        <i className="fal fa-broom"></i> Reset Queue
      </button>

      </div>

      <div className="auto-plop-queue">
        <div>
          <h3>Judges</h3>
          <table className='plop-table'>
            <thead>
              <tr>
                <th></th>
                <th>Time </th>
                <th>Name</th>
                <th>Category</th>
              </tr>
            </thead>
            <tbody>
              {plopInfo && plopInfo.judges && plopInfo.judges.map(((x, i: number) => {
                let judge = judgesByGuid[x.PersonId];
                if (judge) {
                  return (
                    <tr className="inqueue" key={`${x.PersonId}-${i}`}>
                    <td>{i+1}</td>
                      <td>{x.Minutes}:{formatSeconds(x.Seconds)}</td>
                      <td>{judge.LeadJudge && <i className="fas fa-crown"></i>} {judge.FirstName} {judge.LastName}</td>
                      <td>
                        {x.Category && categoriesById[x.Category] &&  <span className="judge-category">{categoriesById[x.Category].name} </span>}
                        {x.Category && categoriesById[x.Category2] && <span className="judge-category">{categoriesById[x.Category2].name}</span>}
                        {x.Category && categoriesById[x.Category3] && <span className="judge-category">{categoriesById[x.Category3].name}</span>}
                      </td>
                    </tr>
                  )
                }
                else return (
                  <tr className="inqueue" key={`${x.PersonId}-${i}`}>
                  <td>{i+1}</td>
                    <td>{x.Minutes}:{formatSeconds(x.Seconds)}</td>
                    <td>Unknown Judge</td>
                    <td>{x.Category && categoriesById[x.Category] && <>{categoriesById[x.Category].name}</>}</td>
                  </tr>
                )
              }))}

            </tbody>
          </table>
        </div>
        <div>
          <h3>{!settings?.acaDeca ? 'Projects' : 'Students'}</h3>
          <table className='plop-table'>
            <thead>
              <tr>
              <th></th>
              <th>Judged </th>
              <th>Time </th>
              <th>Category</th>
              <th>{!settings?.acaDeca ? 'Project' : 'Student'}</th>
              </tr>
            </thead>
            <tbody>
              {plopInfo && plopInfo.projects && plopInfo.projects.map(((x, i: number) => {
                let project = projectsByGuid[x.ProjectId];
                if (project) {
                  return (
                    <tr className="inqueue" key={`${x.ProjectId}-${i}`}>
                      <td>{i+1}</td>
                      <td>{x.TimesJudged}</td>
                      <td>{x.Minutes}:{formatSeconds(x.Seconds)}</td>
                      <td>{x.Category && categoriesById[x.Category] && <>{categoriesById[x.Category].name}</>}</td>
                      <td>{!settings?.acaDeca ? `${project.ProjectId} ${project.Title}` : `${project.StudentNames}` }</td>
                    </tr>
                  )
                }
                else return (
                  <tr className="inqueue" key={`${x.ProjectId}-${i}`}>
                  <td>{i+1}</td>
                    <td>{x.Minutes}:{formatSeconds(x.Seconds)}</td>
                    <td>{x.Category && categoriesById[x.Category] && <>{categoriesById[x.Category].name}</>}</td>
                    <td>Unknown {!settings?.acaDeca ? 'project' : 'student'} {x.ProjectId}</td>
                  </tr>)
              }))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}


export default AutoPlopPanel