import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import audioPlayerx from "../../utils/AudioPlayer";
import ParticipantTimer from "./ParticipantTimer";
import Modal from "../Modal/Modal";

const TimerContainer = (props) => {
  const [socketInited, setSocketInited] = useState(false);
  const [timerInfo, setTimerInfo] = useState({
    showImpromptuPrepTimer: false,
    showImpromptuSpeechTimer: false,
    showInterviewTimer: false,
    showPreparedSpeechTimer: false,
  });
  const [showPromptModal, setShowPromptModal] = useState(false);
  const [topics, setTopics] = useState([])


  const TIMER_START_SOUND = props.settings.onlineJudgingeAcaDecaPlaySounds ? audioPlayerx.sound.meetingEnter : () => null;
  const TIMER_30_SECOND_WARNING = props.settings.onlineJudgingeAcaDecaPlaySounds ? audioPlayerx.sound.newMessage : () => null;
  const TIMER_TIMES_UP = props.settings.onlineJudgingeAcaDecaPlaySounds ? audioPlayerx.sound.attention : () => null;



  // if (isVideoAllowed) {
  //   console.log('should play sound here')
  //   audioPlayer.sound.videoEnter();
  // } else {
  //   console.log('should play sound here')
  //   audioPlayer.sound.videoLeave();
  // }

  useEffect(() => {
    if (!socketInited && props.socket) {
      initSocket(props.socket)
    }
    console.log("##########################",props.settings);
  }, [props.socket])

  const initSocket = (s) => {

    const roomMessage = (data) => {
      switch (data.type) {
        case "prepared-speech-start":
          setTimerInfo({
            showImpromptuPrepTimer: false,
            showImpromptuSpeechTimer: false,
            showInterviewTimer: false,
            showPreparedSpeechTimer: true,
          });
          toast.info("Start prepared speech");
          break;
        case "impromptu-prep-start":
          setTimerInfo({
            showPreparedSpeechTimer: false,
            showImpromptuPrepTimer: true,
            showImpromptuSpeechTimer: false,
            showInterviewTimer: false,
          });
          setShowPromptModal(true);
          setTopics(data.topics);
          toast.info("Start impromptu prep");
          break;
        case "impromptu-speech-start":
          setTimerInfo({
            showPreparedSpeechTimer: false,
            showImpromptuPrepTimer: false,
            showImpromptuSpeechTimer: true,
            showInterviewTimer: false,
          });
          toast.info("Start impromptu speech");
          break;
        case "interview-start":
          setTimerInfo({
            showPreparedSpeechTimer: false,
            showImpromptuPrepTimer: false,
            showImpromptuSpeechTimer: false,
            showInterviewTimer: true,
          });
            toast.info('interview-start')
          break;
        case "close-all-timers":
          setTimerInfo({
            showPreparedSpeechTimer: false,
            showImpromptuPrepTimer: false,
            showImpromptuSpeechTimer: false,
            showInterviewTimer: false,
          });
          toast.info('timer stopped')
          break;
        default:
          break;
      }
    };

    s.off("room-message");
    s.on("room-message", roomMessage);
      setSocketInited(true);
  }

  const preparedTimerCallbackArray = [
    {
      time: 1000 * 60 * 4,
      callback: () => {
        toast.error("Time Has Expired", {className:"acadeca-0"});
        TIMER_TIMES_UP();
      },
    },
    {
      time: 1000 * 60 * 3.5,
      callback: () => {
        toast.warning("30 Seconds Remaining", {className:"acadeca-30", autoClose:3000});
        TIMER_30_SECOND_WARNING();
      },
    },
    {
      time: 1000 * 60 * 3,
      callback: () => {
        toast.warning("1 Minute Remaining", {className:"acadeca-60", autoClose:3000});
        TIMER_30_SECOND_WARNING();
      },
    },
    {
      time: 10,
      callback: () => {
        toast.info("Speech time starts now.");
        TIMER_START_SOUND();
      },
    },
  ];

  const impromptuFirstTimerCallbackArray = [
    {
      time: 59900,
      callback: () => {
        toast.info("Here is your prompt. You have 1 minute to prepare");
        TIMER_START_SOUND();
      },
    },
    {
      time: 0,
      callback: () => {
        toast.warning("Preparation time is up!");
        // openImpromptuSpeechTimer();
        TIMER_TIMES_UP();
      },
    },
  ];

  const impromptuSecondTimerCallbackArray = [
    {
      time: 10,
      callback: () => {
        toast.info("Speech time starts now");
        TIMER_START_SOUND();
      },
    },
    {
      time: 90000,
      callback: () => {
        toast.warning("30 Seconds Remaining", {className:"acadeca-30", autoClose:3000});
        TIMER_30_SECOND_WARNING();
      },
    },
    {
      time: 120000,
      callback: () => {
        toast.error("Time Has Expired", {className:"acadeca-0"});
        TIMER_TIMES_UP();
      },
    },
    {
      time: 1000 * 60 * 1,
      callback: () => {
        toast.warning("1 Minute Remaining", {className:"acadeca-60", autoClose:3000});
        TIMER_30_SECOND_WARNING();
      },
    },
  ];

  const interviewTimerCallbackArray = [
    {
      time: 10,
      callback: () => {
        toast.info("Interview time starts now");
        TIMER_TIMES_UP();
      },
    },
  ];

  return (
    <>
    
    {!showPromptModal && topics && topics.length > 0 && 
      <span>
      <button type="Button" className="btn btn-primary" onClick={()=>{setShowPromptModal(true)}}><i className="fad fa-map-signs"></i>Show Prompts</button>
      </span>
    }
      {/* timers and modal here */}
      {timerInfo.showInterviewTimer && (
        <ParticipantTimer
          showTime={!props.settings.onlineJudgingeAcaDecaStudentHideClock}
          initialTime={0}
          direction="forward"
          checkpoints={interviewTimerCallbackArray}
        />
      )}
      {timerInfo.showPreparedSpeechTimer && (
        <ParticipantTimer
          showTime={!props.settings.onlineJudgingeAcaDecaStudentHideClock}
          initialTime={0}
          direction="forward"
          checkpoints={preparedTimerCallbackArray}
        />
      )}
      {timerInfo.showImpromptuPrepTimer && (
        <>
          <ParticipantTimer
            showTime={!props.settings.onlineJudgingeAcaDecaStudentHideClock}
            initialTime={1000 * 60}
            direction="backward"
            checkpoints={impromptuFirstTimerCallbackArray}
          />
          {showPromptModal 
            && <Modal
              setModalOpen={setShowPromptModal}
              title='Prompts'
            // className='modal-l'
            >
              {topics.map((topic, i) => {
                return (
                  <div
                    key={i}
                    className="modal-topics bumper-l"
                  >
                    <span className='topic-number'>{i + 1}. </span>
                    <span className='topic-words'>{topic}</span>
                  </div>
                )
              })}
            </Modal>
          }
        </>
      )}
      {timerInfo.showImpromptuSpeechTimer && (
        <ParticipantTimer
          showTime={!props.settings.onlineJudgingeAcaDecaStudentHideClock}
          initialTime={0}
          direction="forward"
          checkpoints={impromptuSecondTimerCallbackArray}
        />
      )}
    </>
  )
}

export default TimerContainer

