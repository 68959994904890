import { UiFormField } from "../../utils/CommonTypes/UserTypes";
import Recaptcha from "react-recaptcha";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

interface IRecaptchaProps {
  field?: UiFormField;
  fieldChanged: Function;
}



const MyCaptcha = (props: IRecaptchaProps) => {
  const [value, setValue] = useState<string>();
  useEffect(() => {
      pushToParent();
  }, [value]);

  const pushToParent = ()=>{
      if(!props || !props.fieldChanged){
          console.log('function missing.', props);
          toast.error(`ReCaptcha missing key function.`, {autoClose:false});
          return;
      }
    props.fieldChanged(props.field?.Key, [value]);
    console.log(`Pushed Value: ${value}`);
  };
  const changed = (x) => {
    const w:any = window;
    if(x)    w.x_reCaptcha = x;
    console.log("Captcha changed.");
    setValue(x ?? "");
  };
  const verify = (x) => {
    changed(`${x}`);
  };
  const expired = (x) => {
    changed("");
  };

  return (
    <>
      <Recaptcha
        sitekey="6LdIiq8UAAAAAFSXnLUAIcTsPeGH4UGuNIU3bvmk"
        //render="explicit"
        //size="compact"

        verifyCallback={(x)=>{
          let w:any = window;
          w.x_reCaptcha = x;
          console.log('Set Recaptcha',x)
        }}
        expiredCallback={(x)=>{
          let w:any = window;
          w.x_reCaptcha = x;
          console.log('Clear Recaptcha',x)
        }}
      />{" "}
      <div className="show-on-has-error">
      {value && value.length > 0 && <button type="button" className="btn btn-xs btn-default" onClick={pushToParent}>push</button>}
      </div>

    </>
  );
};

export default MyCaptcha;
