import { useEffect, useState } from "react";
import { Tag } from "../../utils/CommonTypes/PeoplePageTypes";


interface IProps {
    tag:Tag,
    onClick?:()=>void,
}

const TagComponent = (props:IProps)=>{
    const [style, setStyle] = useState<any>();

    useEffect(()=>{
        if(props.tag.Color){
            let fontColor = '#000000';
            if(props.tag.Color.indexOf('#') === 0){
            const c = props.tag.Color.substring(1);      // strip #
            const rgb = parseInt(c, 16);   // convert rrggbb to decimal
            const r = (rgb >> 16) & 0xff;  // extract red
            const g = (rgb >>  8) & 0xff;  // extract green
            const b = (rgb >>  0) & 0xff;  // extract blue

            const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
                if(luma < 128){
                    fontColor = '#ffffff';
                }
            }
            setStyle({backgroundColor:props.tag.Color, color:fontColor});
        } else {
            setStyle(undefined);
        }
    },[props.tag]);

    return <>
    <span style={style} className={`tag tag-${props.tag.Type === 'project' ? 'project':props.tag.Type === 'person' ? 'person':'auto'}`} onClick={()=>{
        if(props.onClick) props.onClick();
    }}>{props.tag.Value}</span>
    </>
};

export default TagComponent;