import React, { useRef, useState } from "react";
import Icon, { IconType } from "../Icon/Icon";
import { useEffect } from "react";
import Modal from "../Modal/Modal";
import { IFileDetails, UiFormField } from "../../utils/CommonTypes/UserTypes";
import StoreX, { store } from "../../redux/oldStore";
import { ServerResponse } from "../../utils/Server";
import { toast } from "react-toastify";
import { file } from "@babel/types";
import { IState, ReduxMap } from "../../redux/redux";
import { connect } from "react-redux";

interface INominationsProps extends IState {
  projectId?: string;
}

interface INomLoad {
  awards: INomAward[];
  headerDescription: string;
  canNominate: boolean;
}

interface INomAward {
  id: number;
  name: string;
  sponsor: string;
  description: string;
  nominated: boolean;
  nominations: INom[];
}

interface INom {
  Id: string;
  Reason: string;
  NominationType: string;
  CanRemove: boolean;
}

const Nominations = (props: INominationsProps) => {
  const [canNominate, setCanNominate] = useState<boolean>();
  const [header, setHeader] = useState<string>();
  const [awards, setAwards] = useState<INomAward[]>();
  const [selectedAward, setSelectedAward] = useState<INomAward>();
  const [showNominationWindow, setShowNominationWindow] = useState<boolean>();
  const [formReason, setFormReason] = useState<string>();

  useEffect(() => {
    LoadNominations();
  }, []);

  const LoadNominations = () => {
    store.server
      .postApi<ServerResponse<INomLoad>>(`../Project/SpecialAwardNominations`, {
        id: props.projectId,
      })
      .then((x) => {
        if (x.Success) {
          setAwards(x.Value.awards);
          setHeader(x.Value.headerDescription);
          setCanNominate(x.Value.canNominate);
        } else {
          toast.error(x.Message, { autoClose: false });
        }
      });
  };

  const removeNomination = (x: INom, award: INomAward) => {
    if(!window.confirm(`Are you sure you want to remove this nomination to "${award.name}"?`)){
        return;
    }

    let request = {
      projectId: props.projectId,
      awardId: award.id,
      nominationId: x.Id,
    };

    store.server
      .postApi<ServerResponse<INomLoad>>("../Project/RemoveNomination", request)
      .then((x) => {
        if (x.Success) {
          setAwards(x.Value.awards);
          setHeader(x.Value.headerDescription);
          setCanNominate(x.Value.canNominate);
          setFormReason("");
          setShowNominationWindow(false);
          toast.success(`Nomination for "${award.name}" removed.`);
        } else {
          toast.error(x.Message, { autoClose: false });
        }
      });
  };

  const startNomination = (award: INomAward) => {
    setSelectedAward(award);
    setShowNominationWindow(true);
  };

  const submitNominationClick = () => {
    let request = {
      projectId: props.projectId,
      awardId: selectedAward?.id,
      reason: formReason,
    };

    store.server
      .postApi<ServerResponse<INomLoad>>("../Project/NominateProject", request)
      .then((x) => {
        if (x.Success) {
          setAwards(x.Value.awards);
          setHeader(x.Value.headerDescription);
          setCanNominate(x.Value.canNominate);
          setFormReason("");
          setShowNominationWindow(false);
          toast.success(`Nomination for "${selectedAward?.name}" saved.`);
        } else {
          toast.error(x.Message, { autoClose: false });
        }
      });
  };

  const mapNominations = (noms: INom[], award: INomAward) =>
    noms?.map((x: INom, i: number) => {
      return (
        <div key={`anom-${i}`}>
          <div className="flex-between">
            <h5>{x.NominationType}</h5>
            {x.CanRemove && canNominate && (
              <span
                className="click text-danger"
                title="remove nomination"
                onClick={() => {
                  removeNomination(x, award);
                }}
              >
                <Icon type={IconType.trashO}></Icon>
              </span>
            )}
          </div>
          <div className="small">{x.Reason}</div>
        </div>
      );
    });

  const mapAwards = awards?.map((a: INomAward, i: number) => {
    return (
      <div key={`nom-${i}`} className="panel panel-default headroom-xl">
        <div className="panel-heading">
          <div className="flex-between">
            <h4>{a.name}</h4>
            {canNominate && !a.nominated && (
              <span
                className="click text-info"
                title="click to nominate"
                onClick={() => {
                  startNomination(a);
                }}
              >
                <Icon type={IconType.flag}></Icon> Nominate
              </span>
            )}
          </div>
        </div>
        <div className="panel-body">
          {a.description && (
            <>
              {a.description}
            </>
          )}
          {a.nominations && a.nominations.length > 0 && (
            <>
            <hr />{mapNominations(a.nominations, a)}</>
          )}
        </div>
      </div>
    );
  });

  return (
    <>
      <h4>Nominations</h4>
      {header && <div dangerouslySetInnerHTML={{ __html: header }}></div>}
      <hr />
      {mapAwards}

      {showNominationWindow && (
        <>
          <Modal
            setModalOpen={setShowNominationWindow}
            title="Nomination"
            className="file-input-modal-sizer"
          >
            <div className="form-horizontal">
              <div className="form-group">
                <label
                  className="control-label col-sm-4"
                  htmlFor={`form-nomination`}
                >
                  {props.Settings?.text.LabelNominationReason}
                </label>
                <div className="col-sm-8">
                  <textarea
                    id="form-nomination"
                    className="form-control"
                    maxLength={2000}
                    onChange={(e) => setFormReason(e.target.value)}
                    value={formReason}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="col-sm-8 col-sm-offset-4">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={submitNominationClick}
                  >
                    <Icon type={IconType.flag} /> Submit Nomination
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return { ...state.main };
};

export default connect(mapStateToProps)(Nominations);
