import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { store } from "../../redux/oldStore";
import { ServerResponse } from "../../utils/Server";
import { FilePicker } from "../GenericForms/Editor";
import Modal from "../Modal/Modal";
import { HtmlEditorPro2 } from "../Tools/htmlEditor2";

interface IPageContentEditorProps{
    content?:string;
    contentKey?:string;
    show:boolean;
    close?:()=>void;    
    onSave?:(content:string)=>void;
}

const PageContentEditor = (props:IPageContentEditorProps) =>{

    const [content, setContent] = useState<string>();
    const [contentLoaded, setContentLoaded] = useState<boolean>(false);

    useEffect(()=>{
        if(props.contentKey) init();
        else setContentLoaded(true);
    },[props.contentKey]);

    useEffect(()=>{                
        if(!props.contentKey) setContentLoaded(true);
    },[]);

    const onSave = (value:string)=>{
        if(props.contentKey){
        store.server.postApi<ServerResponse<string>>(`../Setup/SetCustomText`, {id:props.contentKey, value:value})
        .then(x=>{
            if(x.Success){
                if(props.onSave) props.onSave(value);
            } else {
                toast.error(x.Message, {autoClose:false});
            }
        });}
        else if(props.onSave) {
            props.onSave(value);
        }
        
    };

    const onClose = ()=>{
        if(props.close) props.close(); 
    };

    const init = () =>{
        store.server.getApi<ServerResponse<string>>(`../Setup/GetCustomText/${props.contentKey}`)
        .then(x=>{
            if(x.Success){
                setContent(x.Value);
                setContentLoaded(true);
            } else {
                toast.error(x.Message, {autoClose:false});
            }
        });
    };


    return (<>
        {props.show && contentLoaded && <div> 
                {/* <HtmlEditorPro2 changed={onSave} value={(contentLoaded ? props.content : content) ?? ''} autoOpen={true} onClose={onClose} show={props.show}/> */}
                <HtmlEditorPro2 changed={onSave} value={content ?? props.content ?? ''} autoOpen={true} onClose={onClose} show={props.show}/>
            </div>}
    </>);
};

export default PageContentEditor;