import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { store } from "../../redux/oldStore";
import {
  GetPeoplePerson,
  PasswordRequirements,
} from "../../utils/CommonTypes/PeoplePageTypes";
import Icon, { IconType } from "../Icon/Icon";

interface ChangePasswordFormProps{
  person?: GetPeoplePerson;
  setModalOpen?: (open: boolean) => void;
  isSelfPasswordChange: boolean;
}


const ChangePasswordForm = (props: ChangePasswordFormProps) => {
  const [mePerson, setMePerson] = useState<GetPeoplePerson>();
  const [oldPasswordVal, setOldPasswordVal] = useState("");
  const [passwordVal, setPasswordVal] = useState("");
  const [passwordReqs, setPasswordReqs] = useState<PasswordRequirements>();
  const [confirmPasswordVal, setConfirmPasswordVal] = useState("");
  const [passwordError, setPasswordError] = useState(false);

  useEffect(() => {
    store.server.getApi("../Person/PasswordRequirements").then((res: any) => {
      setPasswordReqs(res.Value);
    });
  }, []);

  const handleChangePasswordClick = () => {
    let errors:string[] = [];
    if(props.isSelfPasswordChange && oldPasswordVal.length === 0){
      errors.push(`What is your current password!`);
    }
    else if(passwordVal.length === 0){
      errors.push(`Please provide a new password.`);
    } else if(passwordVal !== confirmPasswordVal){
      errors.push(`New passwords do not match.`);
    }

    if(errors.length > 0){
      toast.error(errors[0], {autoClose:false});
      return;
    }
    //api submission
    if (!passwordError) {
      store.server
        .postApi("../Person/ChangePassword", {
          userId: props.person?.Id,
          newPassword: passwordVal,
          oldPassword: oldPasswordVal,
        })
        .then((res: any) => {
          if (!res.Success) {
            // setPasswordError(true);
            toast.error(res.Message);
            return;
          } else {
            toast.success("password changed");
            props.setModalOpen?.(false);
            setOldPasswordVal('');
            setPasswordVal('');
            setConfirmPasswordVal('');
          }
        });
    } else {
      toast.error("password and confirm password do not match");
    }
  };
  const checkPasswordsTheSame = () => {
    if (passwordVal !== confirmPasswordVal) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
  };

  return (
    <>
      <div className="form-horizontal">
        <h3>Change Password</h3>
      <div className='alert alert-info col-sm-offset-2 col-sm-8'>
            <h4>Password Requirements</h4>
            <ul>
              {passwordReqs?.MinLength && (
                <li>
                  Password must be at least {passwordReqs?.MinLength} characters
                  long.
                </li>
              )}
              {passwordReqs?.RequireAltCase && (
                <li>
                  Password must contain at least one Uppercase and one Lowercase
                  letter
                </li>
              )}
              {passwordReqs?.RequireNumber && (
                <li>Password must contain at least one number</li>
              )}
              {passwordReqs?.RequireSpecialChar && (
                <li>
                  Password must contain a special character ({" "}
                  {passwordReqs?.specialChars} ).
                </li>
              )}
            </ul>
          </div>
        <div className="">
          {props.isSelfPasswordChange && (
            <div className="form-group">
              <label className="control-label col-sm-4">Current Password:</label>
              <div className={`col-sm-6 ${passwordError ? "has-error" : ""}`}>
                <input
                  className="form-control"
                  value={oldPasswordVal}
                  onChange={(e) => setOldPasswordVal(e.target.value)}
                  type="password"
                />
              </div>
            </div>
          )}
          <div className="form-group">
          <label className="control-label col-sm-4">New Password:</label>
          <div className={`col-sm-6 ${passwordError ? "has-error" : ""}`}>
            <input
              className="form-control new-pswd"
              value={passwordVal}
              onChange={(e) => setPasswordVal(e.target.value?.replace(/[\t\s]/g, ''))}
              type="password"
            />
          </div>
          </div>
          <div className="form-group">
          <label className="control-label col-sm-4">Confirm New Password:</label>
          <div className={`col-sm-6 ${passwordError ? "has-error" : ""}`}>
            <input
              className="form-control confirm-new-pswd"
              value={confirmPasswordVal}
              onChange={(e) => setConfirmPasswordVal(e.target.value?.replace(/[\t\s]/g, ''))}
              onBlur={checkPasswordsTheSame}
              type="password"
            />
            {passwordError && (
              <span
                id="ContentPlaceHolder1_passReset_CompareValidator1"
                className="label label-danger"
                style={{ display: "inline" }}
              >
                Your passwords do not match.
              </span>
            )}
          </div></div>
          <div className="form-group">
            <div className="col-sm-offset-4 col-sm-8">
              <button
                className="btn btn-secondary save-change-pswd"
                onClick={handleChangePasswordClick}
              >
                {" "}
                <Icon type={IconType.lock} /> Change Password
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePasswordForm;
