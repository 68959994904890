import React, { useEffect, useState } from 'react';
import FileDownload from 'js-file-download';
import { toast } from 'react-toastify';
import StoreX, { store } from '../../redux/oldStore';
import Modal from '../Modal/Modal';
import Icon, { IconType } from '../Icon/Icon';

interface Props {
  uploadUrl?: string;
  fileName: string;
  audioOptional?: boolean;
  autoSaveEveryXSeconds?: number;
  recordingStopped?: Function;
  autoStart?: boolean;
  hideButtons?: boolean;
  callback?: Function;
}

export const ScreenRecorder: React.FunctionComponent<Props> = (props: Props) => {
  const isFireFox = navigator.userAgent.indexOf('Firefox') > 0;
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder>();
  const [mediaStream, setMediaStream] = useState<MediaStream>();
  const [showMustRecordModal, setShowMustRecordModal] = useState<boolean>(false);

  useEffect(() => {
    if (props.callback) {
      props.callback(handleStopRecordClick, () => {
        if (isFireFox) setShowMustRecordModal(true);
        else handleRecordClick();
      });
    }
    if (props.autoStart) {
      setTimeout(() => {
        handleRecordClick();
      }, 100);
    }
  }, []);

  const handleRecordClick = async () => {
    console.log('Recording - start clicked');
    let constraintObj: any = {
      audio: true,
      video: true,
    };

    let options: MediaRecorderOptions = {
      //audioBitsPerSecond: 16000,
      videoBitsPerSecond: 64000,
    };

    //navigator.mediaDevices.getUserMedia({audio:true}).then(x=>x.)
    //@ts-ignore

    let micAudioStream: MediaStream | null = null;
    if (!isFireFox) {
      micAudioStream = await navigator.mediaDevices
        .getUserMedia({ audio: true, video: false })
        .then((mx) => {
          return mx;
        })
        .catch((x) => {
          console.log('Recording... Error', x);
          return null;
        });
    }

    let videoStream: MediaStream | null = await navigator.mediaDevices
      .getDisplayMedia({
        video: {
          displaySurface: 'browser',
        },
        audio: {
          suppressLocalAudioPlayback: false,
        },
        preferCurrentTab: true,
        selfBrowserSurface: 'include',
        systemAudio: 'exclude',
        surfaceSwitching: 'exclude',
        monitorTypeSurfaces: 'exclude',
      } as any)
      .then((mediaStreamObj: MediaStream) => {
        return mediaStreamObj;
      })
      .catch((x) => {
        console.log('Recording...', x);
        return null;
      });

    if (videoStream == null) {
      if (props.recordingStopped) props.recordingStopped();
      micAudioStream?.getTracks().forEach((t) => {
        t?.stop();
      });
      return false;
    }

    if (videoStream?.getAudioTracks().length === 0 && !props.audioOptional && !isFireFox) {
      toast.error('You failed to add audio, recording stopped!');
      setIsRecording(false);
      if (props.recordingStopped) props.recordingStopped();
      micAudioStream?.getTracks().forEach((t) => {
        t?.stop();
      });
      videoStream?.getTracks().forEach((t) => {
        t?.stop();
      });
      return false;
    }

    if (micAudioStream?.getAudioTracks().length === 0 && !props.audioOptional && !isFireFox) {
      toast.error('You failed to access your microphone, recording stopped!');
      setIsRecording(false);
      if (props.recordingStopped) props.recordingStopped();
      micAudioStream?.getTracks().forEach((t) => {
        t?.stop();
      });
      videoStream?.getTracks().forEach((t) => {
        t?.stop();
      });
      return false;
    }

    //await videoTrack.addTrack(micAudioTrack);
    let tracks: MediaStreamTrack[] = [];
    let audioContext = new AudioContext();

    let micAC = micAudioStream === null ? null : audioContext.createMediaStreamSource(micAudioStream);
    let videoAC = videoStream.getAudioTracks().length === 0 ? null : audioContext.createMediaStreamSource(new MediaStream(videoStream.getAudioTracks()));
    let audioStream = audioContext.createMediaStreamDestination();
    if (micAC) micAC.connect(audioStream);
    if (videoAC) videoAC.connect(audioStream);

    audioStream.stream.getTracks().forEach((t) => {
      tracks.push(t);
    });
    videoStream.getVideoTracks().forEach((t) => {
      tracks.push(t);
    });

    let combinedStream = new MediaStream(tracks);

    let w: any = window;
    let fileId = StoreX.NewGuid();

    let recorder = new MediaRecorder(combinedStream, options);
    recorder.onstart = (ev: Event) => {
      console.log('Recording... started');
      setIsRecording(true);
      if (props.autoSaveEveryXSeconds) {
        w._autoSaveHandle = setInterval(() => {
          if (recorder) recorder.requestData();
        }, props.autoSaveEveryXSeconds * 1000);
      }

      let stopped = () => {
        if (!videoStream?.active) {
          handleStopRecordClick();
          return;
        }
        setTimeout(stopped, 2500);
      };

      stopped();
    };
    recorder.ondataavailable = function (ev) {
      console.log('Recording... OnDataAvailable');

      let chunks: Blob[] = [];
      chunks.push(ev.data);

      let blob = new Blob(chunks, { type: 'video/webm;' });
      chunks = [];
      let formData = new FormData();
      let file = new File([blob], `${props.fileName.length == 36 ? '0-' : ''}${props.fileName}.webm`);
      formData.append('file', file);
      formData.append('FileId', fileId);
      if (props.uploadUrl) {
        if (!props.autoSaveEveryXSeconds) toast.info('Uploading screen recording...');
        fetch(props.uploadUrl, { method: 'POST', body: formData })
          .then((x) => {
            console.log('response from server: ', x);
            if (x.ok) {
              if (!props.autoSaveEveryXSeconds) toast.info('Your recording has been uploaded');
            } else {
              toast.error('Unable to upload your recording.  Please save the file for your records.');
              console.error(x.status, x.statusText);
              FileDownload(blob, `${props.fileName}.webm`);
            }
          })
          .catch((err) => {
            console.error(err);
            toast.error('Unable to upload your recording.  Please save the file for your records.');
            FileDownload(blob, `${props.fileName}.webm`);
          });
      } else {
        FileDownload(blob, props.fileName + '.webm');
      }
    };

    recorder.onstop = (ev) => {
      console.log('Recording Stopped');
      if (w._autoSaveHandle) window.clearInterval(w._autoSaveHandle);
      w._autoSaveHandle = null;

      if (props.recordingStopped) props.recordingStopped();
    };

    w._Recorder = recorder;
    w._RecorderStream = videoStream;
    w._RecorderAuidoStream = micAudioStream;
    setMediaRecorder(recorder);
    setMediaStream(videoStream);
    recorder.start();

    return true;
  };

  const handleStopRecordClick = () => {
    console.log('Recording - stop clicked');
    setIsRecording(false);
    if (mediaRecorder?.state !== 'inactive') mediaRecorder?.stop();
    mediaStream?.getTracks().forEach((x) => {
      x.stop();
    });
    let w: any = window;
    if (w._Recorder && w._Recorder.state != 'inactive') w._Recorder.stop();
    w._Recorder = null;
    if (w._RecorderStream)
      w._RecorderStream.getTracks().forEach((x) => {
        x.stop();
      });

    if (w._RecorderAuidoStream)
      w._RecorderAuidoStream.getTracks().forEach((x) => {
        x.stop();
      });

    setMediaStream(undefined);
    setMediaRecorder(undefined);
    console.log(mediaRecorder?.state);
  };

  return (
    <>
      {!props.hideButtons && (
        <>
          {!isRecording ? (
            <button type="button" className="btn btn-secondary" onClick={handleRecordClick}>
              <i className="fas fa-video"></i> Record
            </button>
          ) : (
            <button type="button" className="btn btn-secondary" onClick={handleStopRecordClick}>
              <i className="fas fa-video-slash"></i> Stop
            </button>
          )}
        </>
      )}
      {props.hideButtons && isRecording && (
        <>
          <span className="text-danger">
            <i className="fas fa-video"></i> Recording
          </span>
        </>
      )}
      {showMustRecordModal && (
        <Modal title="Start Recording" noClose={true} size="l" setModalOpen={setShowMustRecordModal}>
          <h3>Action Required</h3>
          In order to partipate, you must turn on recording.
          <div className="flex-between headroom-xl">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                handleRecordClick();
                setShowMustRecordModal(false);
              }}>
              <Icon type={IconType.video} /> Start Recording
            </button>
            <button
              type="button"
              className="btn btn-default"
              onClick={() => {
                setShowMustRecordModal(false);
                if (props.recordingStopped) props.recordingStopped();
              }}>
              <Icon type={IconType.exit} /> Cancel
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};
