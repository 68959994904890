import React, { useState, useEffect } from "react";
import { store } from "../../redux/oldStore";
import { toast } from "react-toastify";
import { GuidToJudgeDict, IJudge, ISettings, PersonIdToJudgeDict } from "./Dashboard";

interface JudgeListProps {
  judges: IJudge[];
  settings: ISettings | undefined;
  judgesByPersonId: PersonIdToJudgeDict;
  judgesByGuid: GuidToJudgeDict;
  judgesActive: string[];
  handleJudgeClick: Function;
  projects:any;
  judgesInRoom:any;
}

const JudgeList = (props: JudgeListProps) => {
  const params = new URLSearchParams(window.location.search);
  const fairId = params.get("f");
  const fairIdPara = fairId ? `f=${fairId}` : null
  const { judges, settings } = props;
  const [searchVal, setSearchVal] = useState('');
  const [filteredJudges, setFilteredJudges] = useState<IJudge[]>([]);  
  const [activeJudges, setActiveJudges] = useState<IJudge[]>([])
  const [filteredActiveJudges, setFilteredActiveJudges] = useState<IJudge[]>([])
  const [judgesActive, setJudgesActive] = useState<any>({});

  useEffect(()=>{
    const dic = {};
    props.judgesActive.forEach((x:string)=>{
      dic[x]=true;
    });
    setJudgesActive(dic);
}, [props.judgesActive])

  useEffect(() => {
    if (!searchVal) {
      return setFilteredJudges(judges)
    } else {
      // off first name or last name
      let updatedJudges = judges.filter(judge =>
        judge.FirstName.toLowerCase().includes(searchVal.toLowerCase())
        || judge.LastName.toLowerCase().includes(searchVal.toLowerCase())
      )
      setFilteredJudges(updatedJudges)
    }
  }, [judges, searchVal])

  useEffect(() => {
    const theseActiveJudges = props.judgesActive.reduce((newArr, x) => {
      let judge = props.judgesByGuid[x];
      let judge2 = props.judgesByPersonId[x];
      let realOne = judge || judge2;
      return newArr = [...newArr, realOne];
    }, Array<IJudge>());
    setActiveJudges(theseActiveJudges)
  }, [judgesActive])

  useEffect(() => {
    if (!searchVal) {
      return setFilteredActiveJudges(activeJudges)
    } else {
      // project id or title, may need the projectIdDisplay on the Project for searching purposes
      let updatedJudges = activeJudges.filter(judge =>
        judge && ( judge.FirstName.toLowerCase().includes(searchVal.toLowerCase())
        || judge.LastName.toLowerCase().includes(searchVal.toLowerCase()))
      )
      setFilteredActiveJudges(updatedJudges)
    }
  }, [activeJudges, searchVal]);


  const buildJudgeDiv = (judge: IJudge, i: number, isActive?: boolean) => {
    let inRoom = props.judgesInRoom[judge.PublicId];
    let project = props.projects[inRoom];
    return(
      <div 
        className="project" 
        key={`${judge.PublicId}-judge-${i}`}
        onClick={e => props.handleJudgeClick(e, judge)}

      >
        <div className="id">
          {judge.LeadJudge && <i className="fa fa-crown" title="lead judge"></i>}
          {judge.FirstName} {judge.LastName}
        </div>
        { isActive && <i className="far fa-check-circle "></i>}
        {project && <>{project.Tit}</>}
        {settings?.virtualMeetings && project && <div><a href={`${window.location.origin}/app/video/${project.MeetingId}?${fairIdPara}`} className="link" target="_blank" title="Join Interview"><i className="fal fa-tv-retro"></i> {project.ProjectId}</a></div>}

      </div>
    )
  }


  const mapJudges = filteredJudges.map((x, i: number) => {
    if(!x) return null;
    return buildJudgeDiv(x, i, judgesActive[x.PublicId]);
  });

  const mappedActiveJudges = filteredActiveJudges.map((x, i) => {    
    if(!x) return null;
    return buildJudgeDiv(x, i, judgesActive[x.PublicId]);
  })


  return (
    <>
      <div className="">
        <div className="input-group">
          <input
            // v-model="judgeSearchValue"
            onChange={(e) => setSearchVal(e.target.value)}
            value={searchVal}
            className="form-control "
            placeholder="search"
          />
          <span className="input-group-addon">
            <i className="fa fa-search"></i>
          </span>
        </div>
      </div>
      <div>
        <h4>Active Judges: {props.judgesActive.length}</h4>
        {props.judgesActive.length > 0
          ? <div className="online-judging-project-list">
              {mappedActiveJudges}
            </div>
          : <div className="online-judging-project-list">
              <h5>No Active Judges</h5>
            </div>
        }
        <h4>All Judges</h4>
        <div className="online-judging-project-list">
          {mapJudges}
        </div>
      </div>
    </>);
};

export default JudgeList;
