import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { store } from '../../redux/oldStore';
import { IAward, IJRCategoryAward, IJudge, IJudgingStat, IProject, IResultsRoundInfo, IRound, IScore, ITag } from '../../redux/judgingResults/types';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Modal from '../Modal/Modal';
import AssessmentInfo from './AssessmentInfo';
import ProjectsTab, { IFilter } from './ProjectsTab';
import Select from 'react-select';
import { Selectable } from '../../utils/CommonTypes/CommonTypes';
import Icon, { IconType } from '../Icon/Icon';
import { ServerResponse } from '../../utils/Server';
import AutoAssignJudgingResults from './AutoAssignJudingResults';
import { FormatDate } from '../../utils/Tools';

type ProjectSort = 'default' | 'averageScore' | 'zScore' | 'adjustedScore';

const JudgingResultsContainer = (props: any) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [rounds, setRounds] = useState<IRound[]>([]);
  const [roundClicked, setRoundClicked] = useState<IRound>();

  const [info, setInfo] = useState<IResultsRoundInfo>();
  const [allRoundJudges, setAllRoundJudges] = useState<IJudge[]>([]);
  const [allRoundProjects, setAllRoundProjects] = useState<IProject[]>([]);
  const [allRoundCategoryAwards, setAllRoundCategoryAwards] = useState<IJRCategoryAward[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [chosenJudge, setChosenJudge] = useState<IJudge>();
  const [chosenProject, setChosenProject] = useState<IProject>();
  const [roundSearchVal, setRoundSearchVal] = useState<string>('');
  const [judgeSearchVal, setJudgeSearchVal] = useState<string>('');

  const [filteredRounds, setFilteredRounds] = useState<IRound[]>([]);
  const [filteredRoundJudges, setFilteredRoundJudges] = useState<IJudge[]>([]);
  const [projectSearchVal, setProjectSearchVal] = useState<IFilter>({});
  const [filteredRoundProjects, setFilteredRoundProjects] = useState<IProject[]>([]);
  const [projectsSortedBy, setProjectsSortedBy] = useState<ProjectSort>('zScore');

  const [zScoreMin, setZScoreMin] = useState(4);
  const [selectedAssessment, setSelectedAssessment] = useState<IScore>();
  const [assessmentModalOpen, setAssessmentModalOpen] = useState(false);
  // adding all these tags and awards now.
  const [personTags, setPersonTags] = useState<ITag[]>([]);
  const [projectTags, setProjectTags] = useState<ITag[]>([]);
  const [categoryAwards, setCategoryAwards] = useState<IAward[]>([]); //this is the list of awards
  const [categoryAwardDict, setCategoryAwardDict] = useState<{ [key: number]: IAward }>({});

  const [projectTagDict, setProjectTagDict] = useState<{ [key: number]: ITag }>({});
  const [projectsWithAward, setProjectsWithAward] = useState<{ [ProjectKey: string]: IAward }>({});

  const [selectedTag, setSelectedTag] = useState<Selectable>({ label: 'Choose a tag', value: -1 });
  const [selectedAward, setSelectedAward] = useState<Selectable>({ label: 'Choose an award', value: -1 });

  const [editTags, setEditTags] = useState(false);
  const [editAwards, setEditAwards] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [roundStatsId, setRoundStatsId] = useState<number>(0);
  const [filterOptions, setFilterOptions] = useState<Selectable[]>([]);
  const [showAutoAssignAwards, setShowAutoAssignAwards] = useState<boolean>(false);

  // const [roundCategoryAwardDict, setRoundCategoryAwardDict] = useState<{[ProjectKey: string]: {ProjectKey: string, AwardId: number}}>({});

  useEffect(() => {
    LoadRoundInfo();
    LoadTags();
  }, []);

  useEffect(()=>{
    let options:Selectable[] = [];

    if(info && info.Categories){
      //let cate = {label:"Categories", value:'', options:[]};
      info.Categories.forEach(x=>{
        //cate.options.push({label:x.name, value:`c|${x.id}`});
        options.push({label:x.name, value:`c|${x.id}`});
      })
      //options.push(cate);
    }

    if(info && info.Divisions){
      info.Divisions.forEach(x=>{
        //cate.options.push({label:x.name, value:`c|${x.id}`});
        options.push({label:x.name, value:`d|${x.id}`});
      })
    }

    setFilterOptions(options);
  },[info]);

  useEffect(() => {
    getRound();
  }, [roundClicked?.Id]);

  useEffect(() => {
    console.log('useEffect judge');
    if (!judgeSearchVal) {
      return setFilteredRoundJudges(allRoundJudges);
    } else {
      // off first name or last name
      let filteredJudges = allRoundJudges.filter(
        (judge) => judge.FirstName.toLowerCase().includes(judgeSearchVal.toLowerCase()) || judge.LastName.toLowerCase().includes(judgeSearchVal.toLowerCase())
      );
      setFilteredRoundJudges(filteredJudges);
    }
  }, [allRoundJudges, judgeSearchVal]);

  useEffect(() => {
    if (!roundSearchVal) {
      return setFilteredRounds(rounds);
    } else {
      // off round name
      let filteredRoundsTemp = rounds.filter((round) => round.Name.toLowerCase().includes(roundSearchVal.toLowerCase()));
      setFilteredRounds(filteredRoundsTemp);
    }
  }, [rounds, roundSearchVal]);

  useEffect(() => {
    let dict = categoryAwards.reduce((dict, award) => {
      return { ...dict, [award.Id]: award };
    }, {});
    setCategoryAwardDict(dict);
  }, [categoryAwards]);

  useEffect(() => {
    let dict = projectTags.reduce((dict, tag) => {
      return { ...dict, [tag.Id]: tag };
    }, {});
    setProjectTagDict(dict);
  }, [projectTags]);

  useEffect(() => {
    let newDict = allRoundCategoryAwards.reduce((newThing, oldThing) => {
      return { ...newThing, [oldThing.ProjectKey]: categoryAwardDict[oldThing.AwardId] };
    }, {});
    setProjectsWithAward(newDict);
  }, [categoryAwardDict, allRoundCategoryAwards]);

  useEffect(() => {
    console.log('useEffect project');
    if (!projectSearchVal) {
      return setFilteredRoundProjects(allRoundProjects);
    } else {
      const val = projectSearchVal?.text?.toLowerCase() ?? null;
      // project id or title, may need the projectIdDisplay on the Project for searching purposes
      let filteredProjects =
        val === null
          ? allRoundProjects
          : allRoundProjects.filter(
              (project) =>
                project.Title.toLowerCase().includes(val) ||
                (project.StudentsSearch && project.StudentsSearch.includes(val)) ||
                project.ProjectIntId.toString().includes(val) ||
                project.ProjectId.toLowerCase().includes(val)
            );

      if(projectSearchVal.selected && projectSearchVal.selected.length > 0){
        let categoryHash = {};
        let hasCate = false;
        let divisionHash = {};
        let hasDiv = false;
        projectSearchVal.selected.forEach(x=>{
          let values = (x.value+'').split('|');
          let v = +values[1];
          if(values[0] === 'c'){
            categoryHash[v] = true;
            hasCate = true;
          }if(values[0] === 'd'){
            divisionHash[v] = true;
            hasDiv = true;
          }
          
          if(hasCate){
            filteredProjects = filteredProjects.filter(x=>categoryHash[x.CategoryId]);
          }
          if(hasDiv){
            filteredProjects = filteredProjects.filter(x=>divisionHash[x.DivisionId]);
          }

        })
      }

      setFilteredRoundProjects(filteredProjects);
    }
  }, [allRoundProjects, projectSearchVal]);

  useEffect(() => {
    let sortedFull = filteredRoundProjects.map((x) => x);
    sortedFull = SortProjectsByScore(projectsSortedBy, sortedFull);
    setFilteredRoundProjects(sortedFull);
  }, [projectsSortedBy]);

  const LoadRoundInfo = () => {
    store.server
      .getApi('../JudgingResults/rounds')
      .then((res: any) => {
        // console.log(res.Value);
        setLoaded(true);
        setRounds(res.Value.rounds ?? []);
        setZScoreMin(res.Value.zScoreSetting);
        setCategoryAwards(res.Value.categoryAwards);
        setIsAdmin(res.Value.admin);
      })
      .catch((err) => {
        console.error(err);
        toast.error(err);
      });
  };

  const getRound = () => {
    if (roundClicked?.Id) {
      store.server
        .postApi<ServerResponse<IResultsRoundInfo>>('../JudgingResults/RoundInfo', { roundId: roundClicked?.Id })
        .then((x) => {
          setInfo(x.Value);
          setAllRoundJudges(x.Value.Judges);
          setAllRoundProjects(x.Value.Projects);
          setAllRoundCategoryAwards(x.Value.CategoryAwards);
        })
        .catch((err) => {
          console.error(err);
          toast.error(err);
        });
    }
  };

  const handleModalClose = (bool: boolean) => {
    setModalOpen(bool);
    setChosenJudge(undefined);
    setChosenProject(undefined);
    setEditAwards(false);
    setEditTags(false);
  };

  const handleJudgeClick = (judge: IJudge) => {
    setModalOpen(true);
    setChosenJudge(judge);
  };

  const handleProjectClick = (project: IProject) => {
    setModalOpen(true);
    setChosenProject(project);
  };

  const handleProjectIdClick = (projectId: number) => {
    let [projectPicked] = allRoundProjects.filter((project) => project.ProjectIntId === projectId);
    if (projectPicked) {
      setChosenJudge(undefined);
      setChosenProject(projectPicked);
    }
  };

  const handleJudgeIdClick = (judgeId: number) => {
    let [judgePicked] = allRoundJudges.filter((judge) => judge.Id === judgeId);
    if (judgePicked) {
      setChosenProject(undefined);
      setChosenJudge(judgePicked);
    }
  };

  const handleShowStats = (roundId: number, e?) => {
    e?.stopPropagation();
    setRoundStatsId(roundId);
  };

  const handleAssessmentClick = (assessment: IScore, e?) => {
    e?.stopPropagation();
    setSelectedAssessment(assessment);
    setAssessmentModalOpen(true);
  };

  const closeAssessmentModal = (bool: boolean) => {
    setSelectedAssessment(undefined);
    setAssessmentModalOpen(false);
  };

  const removeAssessmentClick = (assessment: IScore, e?) => {
    e?.stopPropagation();
    if (!window.confirm('are you sure you want to remove that score?')) return;
    store.server
      .postApi('../JudgingResults/AssessmentRemove', { id: assessment.scoreId })
      .then((res) => {
        toast.success('score removed, refresh page to see updated stats');
      })
      .catch((err) => {
        toast.error('something went wrong in the request');
      });
  };

  const LoadTags = () => {
    store.server
      .getApi('../Tag/Info')
      .then((res: any) => {
        console.log(res);
        setPersonTags(res.Value.personTags);
        setProjectTags(res.Value.projectTags);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err);
      });
  };

  const mappedRounds = filteredRounds.map((round) => {
    return (
      <div key={round.Id} className="round-list-item">
        <div className="flex-between">
          <div>
            <button type="button" className="btn btn-xs btn-default" onClick={() => setRoundClicked(round)}>
              <Icon type={IconType.fileInvoice} /> View Results
            </button>
            <strong>{round.Name}</strong>
          </div>
          {round.stats && round.stats.PercentComplete && (
            <button
              type="button"
              className="btn btn-xs btn-secondary"
              title="Judging status - click to see more details"
              onClick={(e) => {
                handleShowStats(round.Id, e);
              }}>
              <i className="fal fa-abacus"></i>
              {round.stats?.PercentComplete}
              view stats
            </button>
          )}
        </div>
        {round.stats && roundStatsId > 0 && round.Id == roundStatsId && (
          <div className="stats-info-panel">
            <div className="stats-header">
              <div>
                {round.stats.PercentComplete && (
                  <div className="flex-between">
                    Estimated level of completed judging: <span>{round.stats?.PercentComplete} </span>
                  </div>
                )}
                {round.stats.NumberOfScoresRecorded > 0 && (
                  <div className="flex-between">
                    Scoresheets submited: <span>{round.stats?.NumberOfScoresRecorded} </span>
                  </div>
                )}
                {round.stats.NumberOfAssignments > 0 && (
                  <div className="flex-between">
                    Number of assignments: <span>{round.stats?.NumberOfAssignments} </span>
                  </div>
                )}
              </div>
              <div>
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={(e) => {
                    handleShowStats(0, e);
                  }}
                  title="click to close status/stats">
                  <Icon type={IconType.exit} /> Close
                </button>
              </div>
            </div>
            <div className="stats-body">
              {round.stats.Categories && round.stats.Categories.length > 0 && (
                <div className="stats-sub-stat">
                  <h3>Stats by Category</h3>
                  <table>
                    <tr>
                      <th></th>
                      <th>
                        <i className="fad fa-check-square" title="scoresheets done"></i>
                      </th>
                      <th>
                        <i className="fad fa-mountain" title="scoresheets needed"></i>
                      </th>
                      <th>
                        <i className="fad fa-ballot-check" title="precent complete"></i>
                      </th>
                    </tr>
                    {round.stats.Categories.map((cx: IJudgingStat, ci: number) => {
                      return (
                        <tr key={`sub-stat-c-${ci}`}>
                          <td className="col-1">{cx.Name}</td>
                          <td className="col-2">{cx.Done}</td>
                          <td className="col-3">{cx.Needed}</td>
                          <td className="col-4">{cx.Percent}</td>
                        </tr>
                      );
                    })}
                  </table>
                </div>
              )}
              {round.stats.Divisions && round.stats.Divisions.length > 0 && (
                <div className="stats-sub-stat">
                  <h3>Stats by Division</h3>
                  <table>
                    <tr>
                      <th></th>
                      <th>
                        <i className="fad fa-check-square" title="scoresheets done"></i>
                      </th>
                      <th>
                        <i className="fad fa-mountain" title="scoresheets needed"></i>
                      </th>
                      <th>
                        <i className="fad fa-ballot-check" title="precent complete"></i>
                      </th>
                    </tr>
                    {round.stats.Divisions.map((cx: IJudgingStat, ci: number) => {
                      return (
                        <tr key={`sub-stat-d-${ci}`}>
                          <td className="col-1">{cx.Name}</td>
                          <td className="col-2">{cx.Done}</td>
                          <td className="col-3">{cx.Needed}</td>
                          <td className="col-4">{cx.Percent}</td>
                        </tr>
                      );
                    })}
                  </table>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  });

  const mappedAllRoundJudges = filteredRoundJudges.map((judge, i) => {
    return (
      <div key={`${judge.Id}-${i}`} className="bumper-l bumper-out-l soft-border click" onClick={() => handleJudgeClick(judge)}>
        <div>
          <span className="emph">
            {judge.FirstName} {judge.LastName}
          </span>
        </div>
        <div className="flex bumper-out-l bumper-l">
          <div>
            Average Score: <span className="emph">{judge.AverageScore?.toFixed(2)}</span>
          </div>
          <span style={{ whiteSpace: 'pre' }}> </span>
        </div>
      </div>
    );
  });

  const mappedTags = projectTags.map((tag) => {
    return {
      value: tag.Id,
      label: tag.Value,
    };
  });

  const mappedAwards = categoryAwards.map((award) => {
    return {
      value: award.Id,
      label: award.Name,
    };
  });

  const handleTagChange = (project: IProject, selectedTag: any, removed: boolean) => {
    // I thought i was being fancy using this for both add and remove, but maybe it would be better in two separate functions.
    // selectedTag can have the {label, value} type or a much bigger, from the api type {Id, FairId, Value, etc.}
    // label = Value and value = Id, if that makes sense.
    let newProj;
    let formattedTag = { ...selectedTag };

    if (!formattedTag.Id) formattedTag.Id = formattedTag.value;
    if (!removed) {
      if (project.Tags && project.Tags.length > 0) {
        newProj = { ...project, Tags: [...project.Tags, formattedTag.Id] };
      } else {
        newProj = { ...project, Tags: [formattedTag.Id] };
      }
    } else {
      newProj = { ...project, Tags: project.Tags?.filter((num) => num !== formattedTag.Id) };
    }
    let updatedProjects = allRoundProjects.map((proj) => (proj.ProjectKey === newProj.ProjectKey ? newProj : proj));
    // let updatedVisibleProjects = visibleProjects.map(proj => proj.publicId === newProj.publicId ? newProj : proj)
    console.log(project, formattedTag, removed);
    // console.log(projects, updatedProjects);
    setChosenProject(newProj);
    setAllRoundProjects(updatedProjects);
    // setVisibleProjects(updatedVisibleProjects);
  };

  const handleSaveThis = () => {
    if (!selectedTag?.value) {
      toast.error('need to choose a tag');
      return;
    }
    if (!chosenProject) return;

    let reqBody = {
      id: chosenProject.ProjectKey, //projectGuid
      tagId: selectedTag.value,
      remove: false,
    };
    store.server
      .postApi('../Tag/TagIt', reqBody)
      .then((res) => {
        console.log(res);
        handleTagChange(chosenProject, selectedTag, false);
        setSelectedTag({ label: 'Choose a Tag', value: -1 });
        setEditTags(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleRemoveTag = (tag) => {
    if (!chosenProject) return;
    if (!tag.Id) tag.Id = tag.value;
    let reqBody = {
      id: chosenProject.ProjectKey,
      tagId: tag.Id,
      remove: true,
    };
    store.server
      .postApi('../Tag/TagIt', reqBody)
      .then((res) => {
        console.log(res);
        handleTagChange(chosenProject, tag, true);
        setSelectedTag({ label: 'Choose a Tag', value: -1 });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleAwardChange = (project: IProject, award: any, removed: boolean) => {
    let formattedAward = { ...award };

    if (!formattedAward.Id) formattedAward.Id = formattedAward.value;
    if (!formattedAward.Name) formattedAward.Name = formattedAward.label;
    if (!removed) {
      setAllRoundCategoryAwards([...allRoundCategoryAwards, { ProjectKey: project.ProjectKey, AwardId: formattedAward.Id }]);
      // if (project.Tags && project.Tags.length > 0) {
      //   newProj = { ...project, Tags: [...project.Tags, formattedAward.Id] }
      // } else {
      //   newProj = { ...project, Tags: [formattedAward.Id] }
      // }
    } else {
      // newProj = { ...project, Tags: project.Tags?.filter(num => num !== formattedAward.Id) }
    }
    // let updatedProjects = allRoundProjects.map(proj => proj.ProjectKey === newProj.ProjectKey ? newProj : proj)
    // // let updatedVisibleProjects = visibleProjects.map(proj => proj.publicId === newProj.publicId ? newProj : proj)
    // console.log(project, formattedAward, removed);
    // // console.log(projects, updatedProjects);
    // setChosenProject(newProj);
    // setAllRoundProjects(updatedProjects);
  };

  const handleSaveThisAward = () => {
    if (!selectedAward?.value) {
      toast.error('need to choose an award');
      return;
    }
    if (!chosenProject) return;
    if (!window.confirm('Projects can only have one category award at a time. Do you want to save this one?')) return;
    let reqBody = {
      assignAwards: [{ projectKey: chosenProject.ProjectKey, awardId: selectedAward.value }],
    };
    store.server
      .postApi('../JudgingResults/AssignAwards', reqBody)
      .then((res) => {
        console.log(res);
        handleAwardChange(chosenProject, selectedAward, false);
        setSelectedAward({ label: 'Choose a Tag', value: -1 });
        setEditAwards(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleClearAllAssessments = () => {
    if (!roundClicked) return;

    if (!window.confirm(`Are you sure you want to remove all project scoresheets for round \r\n"${roundClicked?.Name}"? \r\n\r\nNote: This cannot be undone!`)) {
      return;
    }

    store.server
      .getApi(`../JudgingResults/AssessmentRemoveRoundScores/${roundClicked.Id}`)
      .then((x) => {
        toast.info(`Scoresheets cleared for round "${roundClicked?.Name}".`);
        getRound();
      })
      .catch((err) => {
        console.error(err);
        toast.error(err);
      });
  };

  return (
    <>
      <div className="bumper-l bumper-out-l">
        {roundClicked?.Id ? (
          <div>
            <div className="flex-between">
              <h2>{roundClicked.Name}</h2>
              {isAdmin && (
                <div>
                  <button className="btn btn-xs btn-default" onClick={handleClearAllAssessments}>
                    <i className="fad fa-eraser"></i>clear all assessments
                  </button>
                </div>
              )}
              <div className="hover-danger click" onClick={() => setRoundClicked(undefined)}>
                <i className="fad fa-2x fa-portal-exit"></i>
                Close
              </div>
            </div>
            <div>
              <Tabs>
                <TabList>
                  <Tab>Projects</Tab>
                  <Tab>Judges</Tab>
                  {/* <Tab>Awards</Tab> */}
                </TabList>
                <TabPanel>
                  <ProjectsTab
                    allRoundProjects={allRoundProjects}
                    handleProjectClick={handleProjectClick}
                    projectTags={projectTags}
                    categoryAwards={categoryAwards}
                    setAllRoundProjects={setAllRoundProjects}
                    allRoundCategoryAwards={allRoundCategoryAwards}
                    categoryAwardDict={categoryAwardDict}
                    projectTagDict={projectTagDict}
                    projectsWithAward={projectsWithAward}
                    projectSearchVal={projectSearchVal}
                    filteredRoundProjects={filteredRoundProjects}
                    projectsSortedBy={projectsSortedBy}
                    setProjectSearchVal={(f: IFilter) => {
                      setProjectSearchVal(f);
                    }}
                    setProjectsSortedBy={setProjectsSortedBy}
                    filterOptions={filterOptions}
                    showAutoAssignAwards={()=>{setShowAutoAssignAwards(true)}}
                    // so much for trying to make this its own component.
                  />
                </TabPanel>
                <TabPanel>
                  <div>
                    <div className="flex-between bumper-l bumper-out-l">
                      <div>{allRoundJudges.length > 0 ? 'Click a judge to get more info' : 'no judges have scored a project in this round'}</div>
                      <div className="input-group">
                        <input onChange={(e) => setJudgeSearchVal(e.target.value)} className="form-control" id="z-index-control1" placeholder="search judges" value={judgeSearchVal} />
                        <span className="input-group-addon">
                          <i className="fa fa-search"></i>
                        </span>
                      </div>
                    </div>
                    <div className="bumper-l">{mappedAllRoundJudges}</div>
                  </div>
                </TabPanel>
                {/* 
                <TabPanel>
                  <div>
                    <h3>Award stuff here...</h3>
                    <div className='alert alert-info'>Only 1 category award per project. </div>
                  </div>
                </TabPanel> 
              */}
              </Tabs>
            </div>
          </div>
        ) : (
          <div>
            {rounds.length > 0 ? (
              <div className="">
                <div className="flex-between">
                  <div>
                    <h2>Choose a Round</h2>
                    <p>You can use this tool to check the scores for the round by judge or by project. Click a round to continue.</p>
                  </div>
                  <div className="flex">
                    <button
                      type="button"
                      className="btn btn-default"
                      onClick={() => {
                        LoadRoundInfo();
                      }}>
                      <Icon type={IconType.refresh} />
                    </button>
                    <div className="input-group">
                      <input
                        // v-model="projectSearchValue"
                        onChange={(e) => setRoundSearchVal(e.target.value)}
                        className="form-control"
                        id="z-index-control1"
                        placeholder="search"
                        value={roundSearchVal}
                      />
                      <span className="input-group-addon">
                        <i className="fa fa-search"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="results-round-list">{mappedRounds}</div>
              </div>
            ) : loaded ? (
              <h3>No Round records to show</h3>
            ) : (
              <div>
                <h4>loading...</h4>
              </div>
            )}
          </div>
        )}

        {/* Chosen Judge Modal */}
        {modalOpen && chosenJudge?.Id && (
          <Modal title={`${chosenJudge.FirstName} ${chosenJudge.LastName}`} setModalOpen={handleModalClose} size="l">
            <div className="bumper-l bumper-out-l">
              <div>
                Name:{' '}
                <span className="emph">
                  {chosenJudge.FirstName} {chosenJudge.LastName}
                </span>
              </div>
              <div>
                Id: <span className="emph">{chosenJudge.Id}</span>
              </div>
              <div>
                Lead Judge: <span className="emph">{chosenJudge.Veteran ? 'Yes' : 'No'}</span>
              </div>
              <div className="flex-evenly">
                <div className="bumper-out-l">
                  Average Score: <span className="emph">{chosenJudge.AverageScore?.toFixed(2)}</span>
                </div>
              </div>
            </div>
            <div>
              {chosenJudge.Scores?.sort((a, b) => b.score - a.score).map((score) => {
                return (
                  <div
                    key={`${score.judgeId}${score.projectId}`}
                    className="bumper-l bumper-out-l soft-border click"
                    onClick={() => handleProjectIdClick(score.projectId)} //need a way to swap to chosenProject
                  >
                    <div className='flex-between'>
                    <div>
                      {score.ProjectIdDisplay} - {score.Title}
                    </div>
                    <div>{FormatDate(score.at,'date-time')}</div>
                    </div>
                    <div>{score.Category}</div>
                    <div className="flex-between">
                      {score.noShow && (
                        <div>
                          {' '}
                          <i className="fa fa-siren-on text-warning"></i> No Show
                        </div>
                      )}
                      {!score.noShow && (
                        <>
                          <div className="bumper-out-l">
                            Score: <span className="emph">{score.score?.toFixed(2)}</span>
                          </div>
                          <div
                            className="bumper-out-l"
                            title={`${!chosenJudge.MyZScoreIsValid ? `Need at least ${zScoreMin} for this to be a reliable statistic; this only has ${chosenJudge.Scores.length} score(s)` : ''}`}>
                            Z-Score:
                            {!chosenJudge.MyZScoreIsValid || score.zScore < -5 ? (
                              <span className="emph ">
                                <i className="far fa-exclamation-triangle text-warning"></i>{' '}
                              </span>
                            ) : (
                              <span className={`emph`}>{score.zScore?.toFixed(2)}</span>
                            )}
                          </div>
                        </>
                      )}
                      <div>
                        {!score.noShow && <span className="text-success click fal fa-file-contract fa-2x bumper-sides-out" onClick={(e) => handleAssessmentClick(score, e)}></span>}
                        <span className="text-danger click fal fa-trash-alt fa-2x bumper-sides-out" onClick={(e) => removeAssessmentClick(score, e)}></span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Modal>
        )}

        {/* Chosen Project Modal */}
        {modalOpen && chosenProject?.ProjectId && (
          <Modal title={chosenProject.ProjectId} setModalOpen={handleModalClose}>
            <div className="bumper-l bumper-out-l">
              <div>
                Title: <span className="emph">{chosenProject.Title}</span>
              </div>
              <div>
                Category: <span className="emph">{chosenProject.Category}</span>
              </div>
              <div>
                Grade: <span className="emph">{chosenProject.Grade}</span>
              </div>
              <div className="flex-evenly">
                <div className="bumper-out-l">
                  Average Score: <span className="emph">{chosenProject.AverageScore?.toFixed(2)}</span>
                </div>
                <div className="bumper-out-l">
                  Adjusted Score: <span className="emph">{chosenProject.AdjustedScore?.toFixed(2)}</span>
                </div>

                <div
                  className="bumper-out-l"
                  title={`${
                    !(chosenProject.Scores.filter((score) => score.zScoreIsValid).length >= 0)
                      ? `Need at least ${zScoreMin} for this to be a reliable statistic; this only has ${chosenProject.Scores.filter((score) => score.zScoreIsValid).length} valid score(s)`
                      : ''
                  }`}>
                  Average Z-Score:
                  {!(chosenProject.Scores.filter((score) => score.zScoreIsValid).length >= 0) ? (
                    <span className="emph ">
                      <i className="far fa-exclamation-triangle text-warning"></i>{' '}
                    </span>
                  ) : (
                    <span className={`emph`}>{chosenProject.AdjustedZScore?.toFixed(2)}</span>
                  )}
                </div>
              </div>
              <div className="bumper-out-l">
                {projectTags && projectTags.length > 0 && (
                  <div className="flex logo">
                    <h4>Tags: </h4>

                    {chosenProject.Tags &&
                      chosenProject.Tags.length > 0 &&
                      chosenProject.Tags?.filter((tag) => projectTagDict[tag]).map((tag, i) => {
                        return (
                          <div key={`tag-${tag}-${i}`} className="nice-tag bumper-sides-l">
                            {projectTagDict[tag].Value}
                            {editTags && (
                              <span onClick={() => handleRemoveTag(projectTagDict[tag])} className="click">
                                <i className="fal fa-trash-alt"></i>
                              </span>
                            )}
                          </div>
                        );
                      })}
                    <div className="nice-tag alert-secondary bumper-sides-l click" onClick={() => setEditTags(!editTags)}>
                      {editTags ? 'cancel' : 'add/edit'}
                    </div>
                  </div>
                )}

                {editTags && (
                  <div className="row">
                    <Select
                      id="round-edit-tags"
                      onChange={setSelectedTag}
                      value={selectedTag}
                      options={mappedTags.filter((tag) => !chosenProject.Tags?.includes(tag.value))}
                      placeholder={'choose tag'}
                      className="col-sm-8"
                    />
                    <button onClick={handleSaveThis} className="btn btn-success col-sm-3">
                      Save Tag
                    </button>
                  </div>
                )}
              </div>
              <div className="bumper-out-l">
                {true && (
                  <div className="flex logo">
                    <h4>Awards:</h4>
                    {projectsWithAward[chosenProject.ProjectKey]?.Name && (
                      <span className="nice-tag alert-success bumper-sides-l">
                        <i className="fas fa-award"></i>
                        {projectsWithAward[chosenProject.ProjectKey].Name}
                      </span>
                    )}
                    <span className="nice-tag alert-secondary bumper-sides-l click" onClick={() => setEditAwards(!editAwards)}>
                      {editAwards ? 'cancel' : 'add/edit'}
                    </span>
                  </div>
                )}
                {editAwards && (
                  <div className="row">
                    <Select id="round-edit-tags" onChange={setSelectedAward} value={selectedAward} options={mappedAwards} placeholder={'choose tag'} className="col-sm-8" />
                    <button onClick={handleSaveThisAward} className="btn btn-success col-sm-3">
                      Save Award
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div>
              {chosenProject.Scores?.sort((a, b) => b.score - a.score).map((score) => {
                return (
                  <div
                    key={`${score.judgeId}${score.projectId}`}
                    className="bumper-l bumper-out-l soft-border click"
                    onClick={() => handleJudgeIdClick(score.judgeId)} //need a way to swap to chosenProject
                  >
                    <div className='flex-between'>
                      <div>{score.JudgeFirstName} {score.JudgeLastName}</div>
                      <div>{FormatDate(score.at,'date-time')}</div>
                    </div>
                    <div className="flex-between">
                      {score.noShow && (
                        <div>
                          {' '}
                          <i className="fa fa-siren-on text-warning"></i> No Show
                        </div>
                      )}
                      {!score.noShow && (
                        <>
                          <div className="bumper-out-l">
                            Score: <span className="emph">{score.score?.toFixed(2)}</span>
                          </div>
                          <div className="bumper-out-l" title={`${!score.zScoreIsValid ? `Need at least ${zScoreMin} for this to be a reliable statistic` : ''}`}>
                            Z-Score:
                            {!score.zScoreIsValid ? (
                              <span className="emph ">
                                <i className="far fa-exclamation-triangle text-warning"></i>{' '}
                              </span>
                            ) : (
                              <span className={`emph`}>{score.zScore?.toFixed(2)}</span>
                            )}
                          </div>
                        </>
                      )}
                      <div>
                        {!score.noShow && <span className="text-success click fal fa-file-contract fa-2x bumper-sides-out" onClick={(e) => handleAssessmentClick(score, e)}></span>}
                        <span className="text-danger click fal fa-trash-alt fa-2x bumper-sides-out" onClick={(e) => removeAssessmentClick(score, e)}></span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Modal>
        )}

        {selectedAssessment && assessmentModalOpen && (
          <Modal title="Assessment" setModalOpen={closeAssessmentModal} size="l">
            <AssessmentInfo assessment={selectedAssessment} setModalOpen={closeAssessmentModal} />
          </Modal>
        )}
      </div>

      {showAutoAssignAwards && info && <Modal setModalOpen={setShowAutoAssignAwards} title='Auto Assign Category Awards' size={'xl'}>
          <AutoAssignJudgingResults 
            categories={info.Categories}
            divisions={info.Divisions}
            filterOptions={filterOptions}
            projects={allRoundProjects}
            categoryAwards={categoryAwards}
            awardsAssigned={()=>{
              getRound();
            }}
          />
        </Modal>}
    </>
  );
};

export default JudgingResultsContainer;

export function SortProjectsByScore (sort:ProjectSort, projects:IProject[]) {
  switch (sort) {
    case 'default':
      projects = projects.sort((a, b) => {
        let idA = a.ProjectId.toUpperCase();
        let idB = b.ProjectId.toUpperCase();
        if (idA < idB) {
          return -1;
        }
        if (idA > idB) {
          return 1;
        }
        return 0;
      });      
      break;
    case 'averageScore':
      projects = projects.sort((a, b) => b.AverageScore - a.AverageScore);
      break;
    case 'adjustedScore':
      //sortedFull.sort((a, b) => b.AdjustedScore - a.AdjustedScore);
      projects = projects.sort((a, b) => {
        return a.AdjustedScore > b.AdjustedScore ? -1 : 1;
      });
      break;
    case 'zScore':
      //sortedFull.sort((a, b) => b.AdjustedZScore - a.AdjustedZScore);
      projects = projects.sort((a, b) => {
        if (b.AdjustedZScore == a.AdjustedZScore) {
          return a.AdjustedScore > b.AdjustedScore ? -1 : 1;
        } else return a.AdjustedZScore > b.AdjustedZScore ? -1 : 1;
      });
      break;
    default:
      break;
  }

  return projects;
}
