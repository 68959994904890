import { useEffect, useState } from 'react';
import { IState } from '../../../redux/redux';
import { IJudgeGroup, IJudgeGroupInfo, IJudgeInfo } from './JudgeGroupTypes';
import { toast } from 'react-toastify';
import { ServerResponse } from '../../../utils/Server';
import { store } from '../../../redux/oldStore';
import Icon, { IconType } from '../../Icon/Icon';

interface IProps {
  state: IState;
  judgeGroupId: number;
  onUpdate: (group: IJudgeGroup) => void;
  onClose: () => void;
  onRemove: (group: IJudgeGroup) => void;
}

const JudgeGroupEditor = (props: IProps) => {
  const [info, setInfo] = useState<IJudgeGroupInfo>();
  const [judgeGroup, setJudgeGroup] = useState<IJudgeGroup>({ Judges: [] });
  const [selectedJudges, setSelectedJudges] = useState<Record<string, boolean>>({});
  const [judgesList, setJudgesList] = useState<IJudgeInfo[]>([]);

  useEffect(() => {
    Init();
  }, []);

  useEffect(() => {
    if (info) {
      //make judges list
      let list: IJudgeInfo[] = [];
      Object.keys(info.JudgesDic).forEach((x) => {
        list.push(info.JudgesDic[x]);
      });

      list = list.sort((a, b) => {
        if (a.LastName.toUpperCase() < b.LastName.toUpperCase()) return -1;
        else if (a.LastName.toUpperCase() === b.LastName.toUpperCase()) {
          if (a.FirstName.toUpperCase() < b.FirstName.toUpperCase()) return -1;
        }
        return 1;
      });
      setJudgesList(list);
    }
  }, [info]);

  useEffect(() => {
    if (info) {
      let dic = {};
      judgeGroup.Judges.forEach((x) => {
        dic[x.PersonId] = true;
      });

      setSelectedJudges(dic);
      console.log(dic);
    }
  }, [judgeGroup, info]);

  const Init = () => {
    //call server and get information
    toast.dismiss();
    store.server.getApi<ServerResponse<IJudgeGroupInfo>>(`../JudgeGroup/JudgeGroup/${props.judgeGroupId}`).then((x) => {
      if (x.Success) {
        setJudgeGroup(x.Value.JudgeGroup);
        setInfo(x.Value);
      } else {
        toast.error(x.Message, { autoClose: false });
      }
    });
  };

  const Save = () => {
    toast.dismiss();
    let errors: string[] = [];

    if (!judgeGroup) return;
    if (!judgeGroup.Name) errors.push('Please set the name of this judge group.');

    if (errors.length > 0) {
      toast.warning(
        <>
          <ul>
            {errors.map((e, i) => {
              return <li key={`eli-${i}`}>{e}</li>;
            })}
          </ul>
        </>
      );
      return;
    }

    store.server.postApi<ServerResponse<IJudgeGroupInfo>>(`../JudgeGroup/SaveJudgingGroup`, { ...judgeGroup, Judges: judgeGroup.Judges.map((x) => x.PersonId) }).then((x) => {
      if (x.Success) {
        setJudgeGroup(x.Value.JudgeGroup);
        setInfo(x.Value);
        props.onUpdate(x.Value.JudgeGroup);
        toast.success(`Judge group "${x.Value.JudgeGroup.Name}" saved.`);
      } else {
        toast.error(x.Message, { autoClose: false });
      }
    });
  };

  const Remove = () => {
    toast.dismiss();
    if (!judgeGroup) return;
    if (window.confirm(`Are you sure you want to remove "${judgeGroup.Name}" judge group?`)) {
      //call server
      store.server.getApi<ServerResponse<IJudgeGroup>>(`../JudgeGroup/RemoveJudgingGroup/${judgeGroup.Id}`).then((x) => {
        if (x.Success) {
          setJudgeGroup(x.Value);
          props.onRemove(x.Value);
          props.onClose();
          toast.info(`Judge group "${x.Value.Name}" removed.`);
        } else {
          toast.error(x.Message, { autoClose: false });
        }
      });
      toast.dismiss();
    }
  };

  const mapJudges = () => {
    return (
      <>
        <div className="form-group judge-group-editor-list">
          {judgeGroup.Judges && judgeGroup.Judges.length > 0 && <div className='form-group' >
          <div className='col-sm-offset-4 col-sm-8'>Key: <Icon type={IconType.userCrown} addClass='text-info' /> Make 1st Judge  <Icon type={IconType.trashO} addClass='text-danger'/> Remove Judge</div>
          </div>
          }
          <div className="col-sm-offset-4 col-sm-8">
            {judgeGroup.Judges.map((x, i) => {
              let judge = info?.JudgesDic[x.PersonId];
              return (
                <div className="judge" key={`jgel-${i}-jj`}>
                  <span>
                    <Icon type={i === 0 ? IconType.userCrown : IconType.user} />
                    {judge?.FirstName} {judge?.LastName}
                  </span>
                  {i !== 0 && <span
                    className="click text-info"
                    title="Click to promote"
                    onClick={() => {
                      setJudgeGroup({ ...judgeGroup, Judges: [x, ...judgeGroup.Judges.filter((j) => j.PersonId+'' !== judge?.PersonId + '')] });
                    }}>
                    <Icon type={IconType.userCrown} />
                  </span> } 
                  <span
                    className="click text-danger"
                    title="Click to remove"
                    onClick={() => {
                      setJudgeGroup({ ...judgeGroup, Judges: judgeGroup.Judges.filter((j) => j.PersonId+'' !== judge?.PersonId + '') });
                    }}>
                    <Icon type={IconType.trashO} />
                  </span>
                  
                </div>
              );
            })}         
            
            
          </div>
        </div>
        {(info?.MaxNumberOfJudgesPerGroup ?? 4) > judgeGroup.Judges.length && (
          <div className="form-group">
            <label className="control-label col-sm-4" htmlFor="jge-JudgeNew">
              Add Judge
            </label>
            <div className="col-sm-6">
              <select
                id={`jge-JudgeNew`}
                className="form-control"
                value={0}
                onChange={(x) => {
                  let judges = judgeGroup.Judges;
                  let count = judges.length;
                  judges.push({ PersonId: x.target.value, Captain: count === 0, JudgeNumber: count + 1 });
                  setJudgeGroup({ ...judgeGroup, Judges: judges });
                }}>
                <option value={0}>Select to Add Judge</option>
                {info &&
                  judgesList
                    .filter((x) => !selectedJudges[x.PersonId])
                    .map((x, i) => {
                      return (
                        <option key={`jge-nj-${i}`} value={x.PersonId}>
                          {x.LastName}, {x.FirstName}
                        </option>
                      );
                    })}
              </select>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <div className="form-horizontal">
        <div className="form-group">
          <label className="control-label col-sm-4" htmlFor="jge-Name">
            Name
          </label>
          <div className="col-sm-6">
            <input
              type="text"
              id={`jge-Name`}
              className="form-control"
              maxLength={100}
              value={judgeGroup.Name}
              onChange={(x) => {
                setJudgeGroup({ ...judgeGroup, Name: x.target.value });
              }}
            />
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-4" htmlFor="jge-Category">
            Category
          </label>
          <div className="col-sm-6">
            <select
              id={`jge-Category`}
              className="form-control"
              value={judgeGroup.Category}
              onChange={(x) => {
                setJudgeGroup({ ...judgeGroup, Category: +x.target.value });
              }}>
              <option value={0}>Select</option>
              {info &&
                info.Categories.map((x, i) => {
                  return (
                    <option key={`jge-c-${i}`} value={x.id}>
                      {x.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-4" htmlFor="jge-Division">
            Division
          </label>
          <div className="col-sm-6">
            <select
              id={`jge-Division`}
              className="form-control"
              value={judgeGroup.Division}
              onChange={(x) => {
                setJudgeGroup({ ...judgeGroup, Division: +x.target.value });
              }}>
              <option value={0}>Select</option>
              {info &&
                info.Divisions.map((x, i) => {
                  return (
                    <option key={`jge-d-${i}`} value={x.id}>
                      {x.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
        {mapJudges()}

        {/* <div className='form-group'>
            <label className='control-label col-sm-4' htmlFor='jge-xxxx'>
                xxxx
            </label>
            <div className='col-sm-6'>
                <input type="text"
                    id={`jge-xxxx`}
                    className='form-control'
                    value={judgeGroup.xxxx}
                    onChange={(x)=>{
                        setJudgeGroup({...judgeGroup, xxxx:x.target.value});
                    }}
                />
            </div>
        </div> */}
        <div>&nbsp;</div>
        <hr/>
        <div className="form-group">
          <div className="col-sm-offset-4 col-sm-8">
            <button type={'button'} className="btn btn-secondary" onClick={Save}>
              <Icon type={IconType.save} /> Save
            </button>

            {(judgeGroup.Id ?? 0) > 0 && (
              <button type={'button'} className="btn btn-default" onClick={Remove}>
                <Icon type={IconType.trashO} /> Remove
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default JudgeGroupEditor;
