import React from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import StoreX, { store } from "../../redux/oldStore";
import {
  FullShoppingCartItem,
  ProductsResponse,
} from "../../utils/CommonTypes/StoreTypes";
import { ServerResponse } from "../../utils/Server";
import { formatMoney } from "../../utils/Tools";
import Icon, { IconType } from "../Icon/Icon";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import Modal from "../Modal/Modal";
import Payment from "./Payment";
import { useEffect } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { IState, ReduxMap } from "../../redux/redux";
import { connect } from "react-redux";

interface ShoppingCartProps extends IState{
  cartContents: FullShoppingCartItem[];
  cartCount: number;
  couponsUsed: string[];
  fullProductResponse: ProductsResponse;
  handleProductsResponse: (stuff: ProductsResponse) => void;
  userId: string | null;
  orderId?: string;
  newUsersContinue: (reason: string, makeInvoice: boolean) => void;
}

interface IFeeOptions {
  payByCheck: boolean;
  payByCard: boolean;
  payByFeeWavier: boolean;
  payBySchool: boolean;
}

const ShoppingCart = (props: ShoppingCartProps) => {
  const [couponCode, setCouponCode] = useState("");
  const [stripePromise, setStripePromise] =
    useState<Promise<Stripe | null> | null>(null);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [feeOptions, setFeeOptions] = useState<IFeeOptions>();

  useEffect(() => {
    if(props.Settings){
    let options = props.fullProductResponse.registrationFeeOptions;

    var optionFee = {
      payByCheck: options.payByCheck && options.hasRegistrationFee,
      payByCard: props.Settings?.AcceptPayment == true,
      payByFeeWavier: options.payByFeeWavier && options.hasRegistrationFee,
      payBySchool: options.payBySchool && options.hasRegistrationFee,
    };

    
    setStripePromise(loadStripe(props.Settings.stripePublicKey ?? ""));

    setFeeOptions(optionFee);

  }
  }, [props.Settings]);

  const handleCheckoutAndPay = () => {
    setPaymentModalOpen(true);

    let submitObj = {};
    // store.server.postApi('../Store/ProcessPayment')
  };

  const handleClearCart = () => {
    store.server
      .postApi<ServerResponse<ProductsResponse>>("../Store/Clear", {
        userId: props.userId,
        orderId:props.orderId,
      })
      .then((res) => {
        toast.success("cart cleared!");
        props.handleProductsResponse(res.Value);
      });
  };

  const handleRemoveItemClick = (item: FullShoppingCartItem) => {
    if (
      window.confirm(
        `Are you sure you want to remove ${item.Name} from the cart?`
      )
    ) {
      let submitObj = {
        productId: item.Id,
        size: item.Size,
        userId: props.userId,
        orderId: props.orderId,
        custom: item.Custom,
        idToRemove:item.Id
      };
      store.server
        .postApi<ServerResponse<ProductsResponse>>(
          "../Store/RemoveItem",
          submitObj
        )
        .then((res: any) => {
          toast.success("item removed");
          props.handleProductsResponse(res.Value);
        });
    }
  };

  const handleCouponSubmit = () => {
    store.server
      .postApi<ServerResponse<ProductsResponse>>("../Store/AddCoupon", {
        code: couponCode,
        userId: props.userId,
        orderId: props.orderId,
      })
      .then((res) => {
        if (!res.Success) {
          toast.warning(res.Message);
        } else {
          toast.success("coupon applied!");
          props.handleProductsResponse(res.Value);
          setCouponCode("");
        }
      });
  };

  const handlePayBy = (by: string) => {
    props.newUsersContinue(by, true);
  };

  const mappedCartContents = props.cartContents.map((item, i) => {
    let x = item.Custom && item.Custom.indexOf('{') == 0 ? JSON.parse(item.Custom) : item.Custom ? {Name:item.Custom}: {};
    return (
      <tr key={i}>
        <td>{item.Name} {x?.Name && <>- {x.Name}</>}</td>
        <td>{item.Size}</td>
        <td>{formatMoney.format(item.Free ? 0 : item.Cost)}</td>
        <td>{item.Count}</td>
        <td>{formatMoney.format((item.Free ? 0 : item.Cost) * item.Count)}</td>
        <td>
          {/* {item.Removeable && !x.DoNotAllowToBeRemoved && ( */}
          {item.Removeable && (
            <span
              className="text-danger click"
              title="remove"
              onClick={() => handleRemoveItemClick(item)}
            >
              <Icon type={IconType.trashO} />
            </span>
          )}
        </td>
      </tr>
    );
  });

  return (
    <div>
      <div className="flex-between">
        <h2>
          <Icon type={IconType.shoppingCart} /> Shopping Cart
        </h2>

        { StoreX.IsDev() && <button className="btn btn-default" onClick={handleClearCart}>
          <Icon type={IconType.ban} /> Clear Cart
        </button>}
      </div>
      <div>
        <table className="table table-hover table-striped">
          <thead>
            <tr>
              <th>Name</th>
              <th>Size</th>
              <th>Cost</th>
              <th>Count</th>
              <th>Total</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {mappedCartContents}
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td>Savings: </td>
              <td>
                {formatMoney.format(props.fullProductResponse.savings ?? 0)}
              </td>
              <td></td>
            </tr>
            {props.fullProductResponse?.coupons &&
              props.fullProductResponse.coupons.length > 0 && (
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    {props.fullProductResponse.coupons.map((x, i) => {
                      return <span className="cart-coupon">{x}</span>;
                    })}
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              )}
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td>Cart Total: </td>
              <td>
                {formatMoney.format(props.fullProductResponse.total ?? 0)}
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="form-group">
        <div className="col-sm-4">
          <input
            className="form-control-coupon"
            placeholder="coupon code"
            type="text"
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
          />
        </div>
        <button className="btn btn-secondary submit-coupon-btn" onClick={handleCouponSubmit}>
          <strong>Submit Coupon Code</strong>
        </button>
      </div>
      <div className="headroom-xl">
        {props.fullProductResponse.total > 0 && (
          <button className="btn btn-primary" type="button" onClick={handleCheckoutAndPay}>
            <Icon type={IconType.shoppingCart} />{" "}
            {props.fullProductResponse.NewRecord ? (
              <>{props.fullProductResponse.payContinueBtnText ?? "Pay & continue"}</>
            ) : (
              <>Checkout & Pay</>
            )}
          </button>
        )}
        {!props.orderId && props.cartContents && props.cartContents.find((x) => x.Removeable) && (
          <button className="btn btn-default" type="button" onClick={handleClearCart}>
            <Icon type={IconType.ban} /> Clear Cart
          </button>
        )}
      </div>

      {paymentModalOpen && (
        <Modal 
        title="Checkout" 
        setModalOpen={setPaymentModalOpen}
        size="l"
        >
          <h3>How are you going to pay?</h3>
          <div className="headroom-xl"/>
          <Tabs>
            <TabList>
              {feeOptions?.payByCard && (
                <Tab>
                  <Icon type={IconType.creditCard} /> Card
                </Tab>
              )}
              {feeOptions?.payByCheck && (
                <Tab>
                  <Icon type={IconType.checkMoney} /> Check
                </Tab>
              )}
              {feeOptions?.payBySchool && (
                <Tab>
                  <Icon type={IconType.school} /> School
                </Tab>
              )}
              {feeOptions?.payByFeeWavier && (
                <Tab>
                  <Icon type={IconType.stars} /> Fee Wavier
                </Tab>
              )}
            </TabList>
            {feeOptions?.payByCard && (
              <TabPanel>
                <Elements stripe={stripePromise}>
                  <Payment
                    paymentAmount={props.fullProductResponse?.total ?? 0}
                    setModalOpen={setPaymentModalOpen}
                    fullProductResponse={props.fullProductResponse}
                    orderId={props.orderId}                    
                  />
                </Elements>
              </TabPanel>
            )}
            {feeOptions?.payByCheck && (
              <TabPanel>
                <div>
                  <div>
                    I will be paying my registration fee by check I understand
                    that it is my responsibility to pay my registration fee. I
                    understand that if my fee is not paid I may not be able to
                    compete.
                  </div>
                  <div>
                    {
                      props.fullProductResponse.registrationFeeOptions
                        .checkInstructions
                    }
                  </div>
                  <hr />
                  <button
                    className="btn btn-success"
                    type="button"
                    onClick={() => {
                      handlePayBy("Check");
                    }}
                  >
                    <Icon type={IconType.checkSquareO} /> I agree & continue
                  </button>
                </div>
              </TabPanel>
            )}
            {feeOptions?.payBySchool && (
              <TabPanel>
                <div>
                  <div>
                    My school is paying for all of the registration fees. I
                    understand that it is still my responsibility to pay my
                    registration fee if my school does not. I understand that if
                    my fee is not paid I may not be able to compete.{" "}
                  </div>
                  <hr />
                  <button
                    className="btn btn-success"
                    type="button"
                    onClick={() => {
                      handlePayBy("School");
                    }}
                  >
                    <Icon type={IconType.checkSquareO} /> I agree & continue
                  </button>
                </div>
              </TabPanel>
            )}
            {feeOptions?.payByFeeWavier && (
              <TabPanel>
                <div>
                  <div>
                    I qualify for a fee waiver. I understand that this status
                    will be checked and if I do not have or qualify for a fee
                    waiver. I will be required to make payment by the date of
                    the fair.{" "}
                  </div>
                  <hr />
                  <button
                    className="btn btn-success"
                    type="button"
                    onClick={() => {
                      handlePayBy("Wavier");
                    }}
                  >
                    <Icon type={IconType.checkSquareO} /> I agree & continue
                  </button>
                </div>
              </TabPanel>
            )}
          </Tabs>
        </Modal>
      )}
    </div>
  );
};

//export default ReduxMap(ShoppingCart);

const mapStateToProps = (state) => {
  return { ...state.main };
};

export default connect(mapStateToProps)(ShoppingCart);

