import { useEffect, useState } from 'react';
import { IScheduleRoundDetails, IScheduleRoundRoomTimeSlot } from './NhdTypes';
import Icon, { IconType } from '../../Icon/Icon';
import { FormatDate } from '../../../utils/Tools';
import NhdScheduleSlot from './NhdScheduleSlot';
import { round } from 'lodash';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

interface IProps {
  details: IScheduleRoundDetails;
  onSave: (details: IScheduleRoundDetails) => void;
  onRemove: (details: IScheduleRoundDetails) => void;
  onClose: () => void;
  onAssignIds: () => void;
  onAssignId: (entryId: number, projectId: string) => void;
  onAssignRank: (roundId:number, entryId:number, rank?:number)=>void;
}

const NhdScheduleRoundDetailsManager = (props: IProps) => {
  const [details, setDetails] = useState<IScheduleRoundDetails>();
  const [selectedSlot, setSelectedSlot] = useState<number>();
  const [selectedUnassigned, setSelectedUnassigned] = useState<string>();

  useEffect(() => {
    setDetails(props.details);
  }, [props.details]);

  const onSave = () => {
    if (details) {
      props.onSave(details);
    }
  };

  const onRemove = () => {
    if (details && window.confirm(`Are you sure you want to REMOVE this round?`)) {
      props.onRemove(details);
    }
  };

  const onAssignIds = () => {
    if (window.confirm(`Are you sure you want to assign Entry Ids? \r\nThis cannot be undone.`)) {
      props.onAssignIds();
    }
  };

  const onAssignId = (entryId: string, id: string) => {
    if (details?.entries) {
      let entries = { ...details.entries };
      let entry = entries[entryId];
      console.log(entry);
      props.onAssignId(entry.id, id);
      entries[entryId].entryId = id;
      setDetails({...details,entries:entries});
    }
  };

  const onAssignRank = (entryId:string, rank?:number)=>{
    if (details?.entries) {
      let entries = { ...details.entries };
      let entry = entries[entryId];
      props.onAssignRank(details.roundId, entry.id, rank);
      entries[entryId].rank = rank;
      setDetails({...details,entries:entries});
    }
  };

  const onSwap = (id: number) => {
    if (id === selectedSlot) {
      setSelectedSlot(undefined);
    } else if (selectedUnassigned) {
      doSwapFromUnselected(id, selectedUnassigned);
      setSelectedUnassigned(undefined);
    } else if (!selectedSlot) {
      setSelectedSlot(id);
    } else {
      doSwap(id, selectedSlot);
      setSelectedSlot(undefined);
    }
  };
  const onSwapFromUnassigned = (id: string) => {
    if (id === selectedUnassigned) {
      setSelectedSlot(undefined);
    } else if (selectedSlot) {
      doSwapFromUnselected(selectedSlot, id);
      setSelectedUnassigned(undefined);
    } else {
      setSelectedUnassigned(id);
    }
  };

  const doSwapFromUnselected = (id: number, entryId: string) => {
    let rooms = [...(details?.rooms ?? [])];

    let a: IScheduleRoundRoomTimeSlot | null = null;
    let findA = false;

    for (let i = 0; i < rooms.length; i++) {
      for (let si = 0; si < rooms[i].slots.length; si++) {
        if (!findA && id === rooms[i].slots[si].id) {
          findA = true;
          a = rooms[i].slots[si];
          break;
        }
      }
      if (findA) break;
    }

    if (a && details) {
      let entry = details.entries[entryId];
      let tempId = a.entryPublicKey;

      let missing = details.notSetEntries.filter((x) => x !== entryId);

      if (tempId) {
        missing.push(tempId);
      }
      a.entryId = entry.id;
      a.entryPublicKey = entry.publicId;

      if (details) setDetails({ ...details, rooms: rooms, notSetEntries: missing });
    }
  };

  const doSwap = (id1: number, id2: number) => {
    let rooms = [...(details?.rooms ?? [])];

    let a: IScheduleRoundRoomTimeSlot | null = null;
    let b: IScheduleRoundRoomTimeSlot | null = null;
    let findA = false;
    let findB = false;

    for (let i = 0; i < rooms.length; i++) {
      for (let si = 0; si < rooms[i].slots.length; si++) {
        if (!findA && id1 === rooms[i].slots[si].id) {
          findA = true;
          a = rooms[i].slots[si];
        }
        if (!findB && id2 === rooms[i].slots[si].id) {
          findB = true;
          b = rooms[i].slots[si];
        }

        if (findA && findB) break;
      }
      if (findA && findB) break;
    }

    if (a && b) {
      let tempId = a.entryId;
      let tempKey = a.entryPublicKey;

      a.entryId = b.entryId;
      a.entryPublicKey = b.entryPublicKey;

      b.entryId = tempId;
      b.entryPublicKey = tempKey;

      if (details) setDetails({ ...details, rooms: rooms });
    }
  };

  const removeEntryFromSlot = (id: number) => {
    let rooms = [...(details?.rooms ?? [])];

    let a: IScheduleRoundRoomTimeSlot | null = null;
    let findA = false;

    for (let i = 0; i < rooms.length; i++) {
      for (let si = 0; si < rooms[i].slots.length; si++) {
        if (!findA && id === rooms[i].slots[si].id) {
          findA = true;
          a = rooms[i].slots[si];
          break;
        }
      }
      if (findA) break;
    }

    if (a && details) {
      let missing = details.notSetEntries.filter((x) => x !== (a?.entryPublicKey ?? ''));
      if (a.entryPublicKey) {
        missing.push(a.entryPublicKey);
      }
      a.entryId = undefined;
      a.entryPublicKey = undefined;

      if (details) setDetails({ ...details, rooms: rooms, notSetEntries: missing });
    }
  };

  const mapRooms = () => {
    if (details && details.rooms && details.rooms.length > 0) {
      return details.rooms.map((r, ri) => {
        return (
          <div className="nhd-round-room" key={`nhd-rr-${ri}`}>
            <div className="flex-between">
              <div>
                <strong>{r.room ?? 'Room'} </strong>
                {r.building && <span className="soft">({r.building})</span>}
              </div>
              <div></div>
            </div>
            <hr />
            <div className="nhd-round-room-slots-list">
              {r.slots && r.slots.length > 0 ? (
                r.slots.map((s, si) => {
                  return (
                    <NhdScheduleSlot
                      active={s.id === selectedSlot}
                      onSwap={onSwap}
                      onClearEntry={removeEntryFromSlot}
                      key={`nhdrrsls-${si}`}
                      slot={s}
                      entries={props.details.entries}
                      onAssignId={onAssignId}
                      onAssignRank={onAssignRank}
                    />
                  );
                })
              ) : (
                <div>
                  <h3>
                    <Icon type={IconType.warning} />
                    No Time Slots
                  </h3>
                </div>
              )}
            </div>
          </div>
        );
      });
    }
    return (
      <div className="alert alert-warning">
        <h3>
          <Icon type={IconType.warning} />
          No Rooms
        </h3>
      </div>
    );
  };

  const mapNotSet = () => {
    if (details && details.notSetEntries && details.notSetEntries.length > 0) {
      return (
        <div className="alert alert-warning">
          <h4>
            <Icon type={IconType.warning} /> Unassigned Entries
          </h4>
          <div>
            {details.notSetEntries.map((x, i) => {
              let entry = details.entries[x];
              return (
                <NhdScheduleSlot
                  active={x === selectedUnassigned}
                  onSwap={() => {
                    onSwapFromUnassigned(x);
                  }}
                  onClearEntry={removeEntryFromSlot}
                  key={`nhdrrslsua-${i}`}
                  slot={{
                    entryId: entry.id,
                    entryPublicKey: entry.publicId,
                    id: -1,
                  }}
                  entries={props.details.entries}
                  onAssignId={onAssignId}
                  onAssignRank={onAssignRank}
                />
              );
            })}
          </div>
        </div>
      );
    } else {
      return (
        <div className="alert alert-success">
          <h4>
            <Icon type={IconType.UserCheck} />
            All Entries Assigned!
          </h4>
        </div>
      );
    }
  };

  return (
    <>
      {details && (
        <div>
          <Tabs>
            <TabList>
              <Tab>
                <Icon type={IconType.calendar} /> Schedule
              </Tab>
              <Tab>
                <Icon type={IconType.configure} /> Round Details
              </Tab>
            </TabList>
            <TabPanel>
              <div>
                <div className="flex-between nhd-round-details-header">
                  <div>
                    <span>Rooms: {details.rooms.length}</span> <span>Entries: {Object.keys(details.entries).length}</span>
                    {details.isFinals && (
                      <>
                        <div className="">
                          <Icon type={IconType.flag} /> Finals Round!
                        </div>
                      </>
                    )}
                  </div>
                    <div className='btn-group'>
                      <button type={'button'} className="btn btn-secondary" onClick={onSave}>
                        <Icon type={IconType.save} /> Save
                      </button>
                      <button type={'button'} className="btn btn-default" onClick={props.onClose}>
                        <Icon type={IconType.close} /> Close
                      </button>

                      {details && details.roundId > 0 && (
                        <button type={'button'} className="btn btn-danger" onClick={onRemove}>
                          <Icon type={IconType.remove} /> Remove
                        </button>
                      )}
                    </div>
                  <div>
                    <button type="button" className="btn btn-default" onClick={onAssignIds}>
                      <Icon type={IconType.idBadge} /> Assign Entry Ids
                    </button>
                  </div>
                </div>
                <div className="nhd-round-details-body row">
                  <div className="col-sm-9">{mapRooms()}</div>
                  <div className="col-sm-3">{mapNotSet()}</div>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="form-horizontal">
                <div className="form-group">
                  <label className="col-sm-4 control-label" htmlFor="nhdsbrm-name">
                    Round Name
                  </label>
                  <div className="col-sm-6">
                    <input
                      id={'nhdsbrm-name'}
                      type={'text'}
                      maxLength={50}
                      value={details.name ?? ''}
                      onChange={(x) => {
                        setDetails({ ...details, name: x.target.value });
                      }}
                      placeholder="What is the name of this round?"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-sm-8 col-sm-offset-4">
                    <input
                      id={'nhdsbrm-finals'}
                      type={'checkbox'}
                      checked={details.isFinals}
                      onChange={(x) => {
                        setDetails({ ...details, isFinals: x.target.checked });
                      }}
                    />
                    <label className="control-label" htmlFor="nhdsbrm-finals">
                      Is Finals Round
                    </label>
                  </div>
                </div>
              </div>
            </TabPanel>
          </Tabs>
        </div>
      )}

      <div className="nhd-bottom-margin"></div>
      <div className="nhd-round-details-footer">
        <div>
          <button type={'button'} className="btn btn-secondary" onClick={onSave}>
            <Icon type={IconType.save} /> Save
          </button>
          <button type={'button'} className="btn btn-default" onClick={props.onClose}>
            <Icon type={IconType.close} /> Close
          </button>

          {details && details.roundId > 0 && (
            <button type={'button'} className="btn btn-danger" onClick={onRemove}>
              <Icon type={IconType.remove} /> Remove
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default NhdScheduleRoundDetailsManager;
