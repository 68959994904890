import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { store } from "../../../redux/oldStore";
import { ServerResponse } from "../../../utils/Server";
import Icon, { IconType } from "../../Icon/Icon";
import Modal from "../../Modal/Modal";
import {
  ICaptainAssignment,
  ICategoryCaptainInfo,
} from "./CategoryCaptainTypes";

interface ICategoryCaptainManagerProps {}

interface IForm {
  Assignment: ICaptainAssignment;
}

const CategoryCaptainManager = (props: ICategoryCaptainManagerProps) => {
  const [data, setData] = useState<ICategoryCaptainInfo>();
  const [userDic, setUserDic] = useState<any>({});
  const [divDic, setDivDic] = useState<any>({});
  const [selectedAssignment, setSelectedAssignment] =
    useState<ICaptainAssignment>();
  const [form, setForm] = useState<IForm>({ Assignment: {} });
  const [showEditor, setShowEditor] = useState<boolean>(false);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    setForm({ ...form, Assignment: selectedAssignment ?? {} });
  }, [selectedAssignment]);

  const init = () => {
    store.server
      .getApi<ServerResponse<ICategoryCaptainInfo>>("../JudgingCaptain/Info")
      .then((x) => {
        if (x.Success) {
          setData(x.Value);
          let dic = {};
          x.Value.judges.forEach((j) => {
            dic[j.id] = j.name;
          });

          let dd = {};
          x.Value.divisions.forEach((d) => {
            dd[d.id] = d.name;
          });
          setDivDic(dd);
          setUserDic(dic);
        } else {
          toast.error(x.Message, { autoClose: false });
        }
      });
  };

  const addAssignment = () => {
    let request = form.Assignment ?? {};

    let errors: string[] = [];
    if (!request.CategoryId) errors.push("Category");
    if (!request.PersonId) errors.push("Captain");
    if (!request.DivisionId) request.DivisionId = 0;

    if (errors.length > 0) {
      toast.warning(
        <>
          You are missing some required information:
          <ul>
            {errors.map((e) => {
              return <li>{e}</li>;
            })}
          </ul>
        </>
      );
      return;
    }
    store.server
      .postApi<ServerResponse<any>>("../JudgingCaptain/AddCaptain/", request)
      .then((x) => {
        if (x.Success) {
          init();
          setSelectedAssignment(undefined);
          setForm({ ...form, Assignment: selectedAssignment ?? {} });
          toast.info("Captain added!");
        } else {
          toast.error(x.Message, { autoClose: false });
        }
      });
  };
  const removeAssignment = (id: number) => {
    if (window.confirm(`Are you sure you want to remove this captain?`)) {
      store.server
        .getApi<ServerResponse<any>>("../JudgingCaptain/RemoveCaptain/" + id)
        .then((x) => {
          if (x.Success) {
            init();
            setSelectedAssignment(undefined);
            setForm({ ...form, Assignment: selectedAssignment ?? {} });
            toast.info("Captain removed!");
          } else {
            toast.error(x.Message, { autoClose: false });
          }
        });
    }
  };

  return (
    <>
      <div className="col-sm-12">
        <h3>Category Chairs</h3>
        You can assign users that can help manage your categories.
        <hr />
        <div className="catagory-chair-container-list">
          {data &&
            data.categories.map((c, ci) => {
              return (
                <div className="category-chair-container" key={`ccc-${ci}`}>
                  <div className="flex-between">
                    <strong>{c.name}</strong>

                    <button
                      type="button"
                      className="btn btn-xs btn-default"
                      onClick={() => {
                        setShowEditor(true);
                        let f = { ...form };
                        f.Assignment.CategoryId = +c.id;
                        setForm(f);
                      }}
                    >
                      <Icon type={IconType.plus} /> Add Captain
                    </button>
                  </div>
                  <div className="category-chair-captains">
                    {data.captains
                      .filter((x) => x.CategoryId === +c.id)
                      .map((a, ai) => {
                        return (
                          <div key={`cap-${ai}`} className="category-chair-cap">
                            <span
                              className="click text-danger"
                              onClick={() => {
                                removeAssignment(a.Id ?? 0);
                              }}
                            >
                              <Icon type={IconType.trashO} />
                            </span>
                            &nbsp;&nbsp;&nbsp;
                            {userDic[a.PersonId ?? 0] ?? "Unknown"}
                            {divDic[a.DivisionId ?? 0] && (
                              <>
                                <span className="text-muted">
                                  &nbsp;&nbsp;&nbsp;
                                  {divDic[a.DivisionId ?? 0]}
                                </span>
                              </>
                            )}
                          </div>
                        );
                      })}
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      {showEditor && (
        <Modal
          setModalOpen={setShowEditor}
          title={`Assign Judging Captain`}
          size={"m"}
        >
          <div className="form-horizontal">
            <div className="form-group">
              <label className="control-label col-sm-4" htmlFor="cc-category">
                Category
              </label>
              <div className="col-sm-8">
                <select
                  id="cc-category"
                  className="form-control"
                  value={form.Assignment.CategoryId ?? 0}
                  onChange={(e) => {
                    let f = { ...form };
                    f.Assignment.CategoryId = +e.target.value;
                    setForm(f);
                  }}
                >
                  <option value={0}>Select</option>
                  {data?.categories.map((x, i) => {
                    return (
                      <option key={`cc-c-${i}`} value={x.id}>
                        {x.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="form-group">
              <label className="control-label col-sm-4" htmlFor="cc-judge">
                Captain
              </label>
              <div className="col-sm-8">
                <select
                  id="cc-judge"
                  className="form-control"
                  value={form.Assignment.PersonId ?? 0}
                  onChange={(e) => {
                    let f = { ...form };
                    f.Assignment.PersonId = +e.target.value;
                    setForm(f);
                  }}
                >
                  <option value={0}>Select</option>
                  {data?.judges.map((x, i) => {
                    return (
                      <option key={`cc-j-${i}`} value={x.id}>
                        {x.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="form-group">
              <label className="control-label col-sm-4" htmlFor="cc-division">
                Division
              </label>
              <div className="col-sm-8">
                <select
                  id="cc-division"
                  className="form-control"
                  value={form.Assignment.DivisionId ?? 0}
                  onChange={(e) => {
                    let f = { ...form };
                    f.Assignment.DivisionId = +e.target.value;
                    setForm(f);
                  }}
                >
                  <option value={0}>Select</option>
                  {data?.divisions.map((x, i) => {
                    return (
                      <option key={`cc-d-${i}`} value={x.id}>
                        {x.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="form-group">
              <div className="col-sm-8 col-sm-offset-4">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    addAssignment();
                  }}
                >
                  <Icon type={IconType.save} /> Save
                </button>
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={() => {
                    setShowEditor(false);
                  }}
                >
                  <Icon type={IconType.close} /> Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default CategoryCaptainManager;
