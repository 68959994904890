import { useParams } from 'react-router-dom';
import { IState, ReduxMap } from '../../redux/redux';
import FlexFlowManager from '../../Components/FlexFlow/Setup/FlexFlowManager';
import FlexFlow from '../../Components/FlexFlow/FlexFlow';
import FlexFlowFee from '../../Components/FlexFlow/FlexFlowFee';

interface IProps extends IState {}

const FlexFlowPageFee = (props: IProps) => {
  
  const {flowId, recordId} = useParams();

  return (
    <>
      {props.IsReady && flowId && recordId && 
        <FlexFlowFee state={props} flowId={flowId} recordId={recordId} />}
    </>
  );
};

export default ReduxMap(FlexFlowPageFee);
