import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import StoreX, { store } from '../../redux/oldStore';
import { Server, ServerResponse } from '../../utils/Server';
import { FormatDate } from '../../utils/Tools';
import Icon, { IconType } from '../Icon/Icon';
import Modal from '../Modal/Modal';
import InvoiceNewEditor from './InvoiceNewEditor';
import { IInvoice, IInvoicesInfo } from './OrganizationTypes';

interface IInvoicesProps {}

interface iform {
  expiringBefore?: string;
  goodUntil?: string;
  subject?: string;
  bodyTemplate?: string;
  send?: boolean;
}

const Invoices = () => {
  const [info, setInfo] = useState<IInvoicesInfo>();
  const [showBuildInvoices, setShowBuildInvoices] = useState<boolean>();
  const [showSendInvoiceNotifications, setShowInvoiceNotifications] = useState<boolean>();
  const [showNewInvoice, setShowNewInvoice] = useState<boolean>(false);
  const [form, setForm] = useState<iform>({});

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    store.server.getApi<ServerResponse<IInvoicesInfo>>(`../OrganizationData/Invoices`).then((x) => {
      if (x.Success) {
        setInfo(x.Value);
        console.log(x.Value);
        setForm({});
      } else {
        toast.error(x.Message, { autoClose: false });
      }
    });
  };

  const sendNotices = () => {
    if (form.bodyTemplate && form.subject) {
      if (window.confirm(`Are you sure you want to send out invoice notices?`)) {
        store.server
          .postApi<ServerResponse<any[]>>(`../OrganizationData/SendInvoiceNotice`, { ...form, send: form.send ?? false })
          .then((x) => {
            if (x.Success) {
              console.log(x.Value);
              setShowInvoiceNotifications(false);
              toast.dark('Notices Sent');
            } else {
              toast.error(x.Message, { autoClose: false });
            }
          })
          .catch((x) => {
            toast.error('That went bad');
            console.log(x);
          });
      }
    } else {
      toast.dark('Hay add the missing stuff.');
    }
  };

  const buildNewInvoices = () => {
    if (window.confirm(`Are you sure you want to build invoices for everyone needing a new one?`)) {
      store.server
        .postApi<ServerResponse<IInvoice[]>>(`../OrganizationData/BuildInvoicesForEveryone`, form)
        .then((x) => {
          if (x.Success) {
            if (info) setInfo({ ...info, Invoices: x.Value });
            console.log(x.Value);
            setShowBuildInvoices(false);
            toast.dark('Invoices Generated!');
          } else {
            toast.error(x.Message, { autoClose: false });
          }
        })
        .catch((x) => {
          toast.error('That went bad');
          console.log(x);
        });
    }
  };

  const buildInvoiceBlip = (x: IInvoice, i: number) => {
    let link = StoreX.BuildUrl(`../app/organization/invoice/${x.PublicId}`);
    return (
      <div className={`flex-between invoice ${x.PaidAtUtc ? 'paid' : ''}`} key={`i-k-${i}`}>
        <div>
          <h3>{x.Organization.Name}</h3>
          <div>Amount: $ {x.Amount.toFixed(2)}</div>

          <div>
            <a className="btn btn-info" href={link} target={'_blank'}>
              <Icon type={IconType.invoice} />
              View Invoice
            </a>
          </div>
        </div>
        {x.PaidAtUtc && (
          <div className="alert alert-success">
            <h4>Paid</h4>
            <div>
              {x.Type} Payment at {FormatDate(x.PaidAtUtc)}
              {x.PaymentId && <div>Payment Auth: {x.PaymentId}</div>}
            </div>
          </div>
        )}
        <div className="text-right">
          <div className="large">
            {x.Type} # {x.Id}
          </div>
          <div>
            {x.Type} Date: {FormatDate(x.CreatedAtUtc)}
          </div>
          {x.DueAtUtc && <div>Due: {FormatDate(x.DueAtUtc)}</div>}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="org-invoices">
        <div className="flex-between">
          <h3>Invoices</h3>
          <div>
            <button
              type="button"
              className="btn btn-default"
              onClick={() => {
                setShowNewInvoice(true);
              }}>
              <Icon type={IconType.fileInvoice} /> New Invoice
            </button>
            {info?.IsOwner && (
              <>
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={() => {
                    setShowBuildInvoices(true);
                  }}>
                  <Icon type={IconType.stars} /> New Invoices
                </button>
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={() => {
                    setShowInvoiceNotifications(true);
                  }}>
                  <Icon type={IconType.paperPlane} /> Send Invoice Notices
                </button>
              </>
            )}
          </div>
        </div>
        <hr />

        {(info?.Invoices?.length ?? 0) > 0 && (
          <div className="invoices-list">
            {info?.Invoices.map((x, i) => {
              return buildInvoiceBlip(x, i);
            })}
          </div>
        )}
      </div>

      {showBuildInvoices && info && (
        <Modal setModalOpen={setShowBuildInvoices} title={'Build Invoices for everyone'} size="m">
          <div className="form-horizontal">
            <div className="form-group">
              <label className="control-label col-sm-4" htmlFor="org-expiringBefore">
                Expiring Before
              </label>
              <div className="col-sm-8">
                <input
                  type="date"
                  id="org-expiringBefore"
                  onChange={(e) => {
                    setForm({ ...form, expiringBefore: e.target.value });
                  }}
                  value={form.expiringBefore ?? ''}
                  className="form-control"
                />
              </div>
            </div>
            <div className="form-group">
              <label className="control-label col-sm-4" htmlFor="org-goodUntil">
                Good Until
              </label>
              <div className="col-sm-8">
                <input
                  type="date"
                  id="org-goodUntil"
                  onChange={(e) => {
                    setForm({ ...form, goodUntil: e.target.value });
                  }}
                  value={form.goodUntil ?? ''}
                  className="form-control"
                />
              </div>
            </div>
            <div className="form-group">
              <div className="col-sm-8 col-sm-offset-4">
                <button type="button" className="btn btn-secondary" onClick={buildNewInvoices}>
                  <Icon type={IconType.stars} /> Build New Invoices
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}

      {showSendInvoiceNotifications && info && (
        <Modal setModalOpen={setShowInvoiceNotifications} title={'Send outstanding invoice notifications'} size="l">
          <div className="form-horizontal">
            <div className="form-group">
              <div className="col-sm-8 col-sm-offset-4">
                <input
                  type="checkbox"
                  id="org-send"
                  onChange={(e) => {
                    setForm({ ...form, send: e.target.checked });
                  }}
                  checked={form.send ?? false}
                />
                <label className="control-label" htmlFor="org-send">
                  Send notices
                </label>
              </div>
            </div>
            <div className="form-group">
              <label className="control-label col-sm-4" htmlFor="org-subject">
                Subject
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  id="org-subject"
                  onChange={(e) => {
                    setForm({ ...form, subject: e.target.value });
                  }}
                  value={form.subject ?? ''}
                  className="form-control"
                />
              </div>
            </div>
            <div className="form-group">
              <label className="control-label col-sm-4" htmlFor="org-body">
                body
              </label>
              <div className="col-sm-8">
                <textarea
                  rows={10}
                  id="org-body"
                  onChange={(e) => {
                    setForm({ ...form, bodyTemplate: e.target.value });
                  }}
                  value={form.bodyTemplate ?? ''}
                  className="form-control"
                />
                <div className='email-tokens'>Keys: 
<span>[first]</span>
<span>[last]</span>
<span>[email]</span>
<span>[link]</span>
<span>[url]</span>
<span>[title]</span></div>


              </div>
            </div>
            <div className="form-group">
              <div className="col-sm-8 col-sm-offset-4">
                <button type="button" className="btn btn-secondary" onClick={sendNotices}>
                  <Icon type={IconType.paperPlane} /> Send Invoice Notices
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}

      {showNewInvoice && info && (
        <Modal setModalOpen={setShowNewInvoice} title={'New Order'} size="xl">
          <div className="form-horizontal">
            <strong>Event Size: {info.EventSize ?? "Unknown"}</strong>
            <InvoiceNewEditor
              onUpdated={(invoice:IInvoice)=>{
                setInfo({...info, Invoices: [invoice, ...info.Invoices]});
                setShowNewInvoice(false);
              }}
              invoice={{}}
              isOwner={info.IsOwner}
              products={info.Products}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default Invoices;
