import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { IConfigChild, IConfigGroup, IConfigBase } from '../../ObjectTypes/SetupTypes';
import Icon, { IconType } from '../Icon/Icon';
import SetupConfig from './SetupConfig';
import SetupSubGroup from './SetupSubGroup';

interface ISetupGroupProps {
  Save: Function;
  Group: IConfigBase;
  ValueUpdated: Function;
  ValueFileUpdated: Function;
  CloseGroup?: Function;
  IsSubGroup?: boolean;
}

const SetupGroup = (props: ISetupGroupProps) => {
  const buildTabs = (children: IConfigChild[]) => {
    let configs = children.filter((x) => x.Type === 'config');
    let groups = children.filter((x) => x.Type === 'group');

    return (
      <>
        <Tabs>
          <TabList>
            {configs.length > 0 && <Tab>Main</Tab>}
            {groups.map((g, i) => {
              return <Tab key={`sg-tab-${i}`}>{g.Name}</Tab>;
            })}
          </TabList>
          {configs.length > 0 && <TabPanel>{mapTheChildren(configs)}</TabPanel>}
          {groups.map((g, i) => {
            return (
              <TabPanel key={`sg-tab-panel-${i}`}>
                {g.Description && (
                  <>
                    <div dangerouslySetInnerHTML={{ __html: g.Description }}></div>
                  </>
                )}
                <div>
                  {mapTheChildren(g.Children)}
                </div>
              </TabPanel>
            );
          })}
        </Tabs>
      </>
    );
  };

  const mapTheChildren = (configs: IConfigChild[]) => {
    if (configs.find((x) => x.Type === 'group')) {
      return buildTabs(configs);
    }
    return (
      <>
        {configs.map((c, i) => {
          return (
            <div key={`ssg-${i}`}>
              <SetupConfig Config={c} valueUpdated={props.ValueUpdated} valueFileUpdated={props.ValueFileUpdated} />
            </div>
          );
        })}
      </>
    );
  };

  const mapChildren = () => {
    if (props.Group.Children.find((x) => x.Type === 'group')) {
      return buildTabs(props.Group.Children);
    }
    return mapTheChildren(props.Group.Children);
  };
  return (
    <>
      <div className="config-group" id={`config-group-${props.Group.PublicId}`}>
        <div className="config-group-header">
          <div className="flex-between">
            {!props.IsSubGroup && <h3>{props.Group.Name}</h3>}
            {props.CloseGroup && (
              <button
                type="button"
                className="btn btn-default"
                onClick={() => {
                  if (props.CloseGroup) props.CloseGroup();
                }}>
                <Icon type={IconType.close} /> Exit
              </button>
            )}
          </div>
          {props.Group.Description && (
            <>
              <div dangerouslySetInnerHTML={{ __html: props.Group.Description }}></div>
            </>
          )}
          <div>{mapChildren()}</div>
        </div>
      </div>
    </>
  );
};

export default SetupGroup;
