import React from 'react'
import { IFeedRequest } from './FairPromotionLinkingContainer'

interface RequestProps {
  request: IFeedRequest;
  requests: IFeedRequest[];
  setRequests: Function;
  approve?: Function; // approves - RequestsToApprove
  justNo?: Function; //removes approval - RequestsToApprove
  remove?: Function; // remove request - MakeARequest
}

const Request = (props: RequestProps) => {
  const {
    request,
    requests,
    setRequests,
    approve,
    justNo,
    remove,
  } = props

  return (
    <div
      className='external-judge'
    >
      <div className='card-header'>
        <h3>
          {request.Name} {request.State ? `- ${request.State}` : ''} &nbsp;&nbsp;&nbsp;
          {request.ApprovedAtUtc && <span className='text-success'><i className="far fa-check-circle "></i> Approved</span>} 
        </h3> 
        <span>{request.Website}</span>
      </div>
      <div className='card-body'>
        
        <div>
          {(!request.ApprovedAtUtc && approve)
            &&
            <>
              <button
                className='btn btn-success'
                onClick={() => approve(request)}
              >
                <i className="far fa-thumbs-up"></i>
              Approve
            </button>
            </>
          }
          {(request.ApprovedAtUtc && justNo)
            &&
            <>
              <button
                className='btn btn-danger'
                onClick={() => justNo(request)}
              >
                <i className="far fa-thumbs-down"></i>
                Remove Approval
            </button>
            </>}

          {remove
            &&
            <button
              className='btn btn-danger'
              onClick={() => remove(request)}
            >
              <i className="far fa-trash-alt"></i>
              Remove Request
            </button>
          }

        </div>

      </div>
    </div>
  )
}


export default Request