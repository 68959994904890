import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { store } from '../../redux/oldStore';
import { GetPeoplePerson } from '../../utils/CommonTypes/PeoplePageTypes';
import Icon, { IconType } from '../Icon/Icon';
import { fileUploadRequest, uploadedFile } from '../Paperwork/paperworkTypes';
import { FormTypes } from './PersonContextMenu';
import { IListItem } from '../_Core/CoreTypes';

interface ContextFormProps {
  person: GetPeoplePerson;
  setModalOpen: (open: boolean) => void;
  formType: FormTypes;
  refreshAll: () => void;
  fileTypes: IListItem[];
}


const ContextForm = (props: ContextFormProps) => {
  const [usernameVal, setUsernameVal] = useState(props.person.Username);
  const [fileForm, setFileForm] = useState('-1');
  const [fileOptions, setFileOptions] = useState<IListItem[]>([]);
  const [shouldAppend, setShouldAppend] = useState(false);


  useEffect(()=>{
    setFileOptions(props.fileTypes ?? []);
  },[props.fileTypes]);

  const fileRef = useRef<HTMLInputElement>(null);


  
  const handleChangeUsernameClick = () => {
    store.server.postApi('../Person/ChangeUsername', {userId: props.person.Id, newUsername: usernameVal}).then((res: any) => {
      if (!res.Success) {
        toast.error(res.Message)
        return;
      }
      toast.success('Username changed!');
      props.refreshAll();
      props.setModalOpen(false);
    })
  }


  const uploadFile = async (e: any) => {
    if (!fileRef?.current?.files?.[0]) {
      toast.error("You need to choose a file to upload.");
      return;
    }

    const success = (data: uploadedFile) => {
      //what do we do on success?
      // closeModalHook();
      toast.success('File uploaded!');
      props.refreshAll();
      props.setModalOpen(false);
    }
    const fail = (response: any) => {
      console.error(response);
      toast.error(response);
    }
    const fileMetaData: fileUploadRequest = {
      fileTypeId: +fileForm,
      shouldAppend,
      projectId: +(props?.person?.ProjectIdInt ?? 0),
    }
    try {
      console.log('file metadata', fileMetaData)
      console.log('fileRef?.current?.files?.[0]', [fileRef?.current?.files?.[0]])
      // let formData = new FormData()
      if(!fileMetaData.fileTypeId || fileMetaData.fileTypeId <= 0){
        toast.warning('Please select a paperwork file type to upload.');
        return;
      }
      const result = await store.server.postApiWithServerResponse<uploadedFile>("../ParticipantFiles/FileUpload", { ...fileMetaData }, [fileRef?.current?.files?.[0]])
      if (result?.Value) {
        success(result?.Value);
      } else {
        fail(result);
      }

    } catch (error) {
      fail(error);
    }

  }


  const renderBasedOnFormType = {
    [FormTypes.usernameChange]: (<>
      <div className='form-horizontal'>
        <div className='form-group'>
          <label className='control-label col-sm-4'>Username:</label>
          <div className='col-sm-8'>
            <input
              className='form-control'
              value={usernameVal}
              onChange={(e) => setUsernameVal(e.target.value?.replace(/[\t\s]/g, ''))}
              type='text'
            />
          </div>
        </div>
        <button className='btn btn-primary' onClick={handleChangeUsernameClick}>Change Username</button>
      </div>
    </>),
    [FormTypes.fileUpload]: (<>
      <div className=''>
        <div className='form-group'>
          <label>File:</label>
          <select
            className='form-control'
            value={fileForm}
            onChange={(e) => setFileForm(e.target.value)}
          >
            <option value='-1'>choose a file...</option>
            {fileOptions && fileOptions.map((opt, i) => {
              console.log('Trent', opt);
            return (
                <option value={opt.id} key={i}>{opt.name}</option>
              )})}  
          </select>

        </div>
        <div className='form-group flex-center'>
          <label className=''>File To Upload:</label>
          <div className='col-md-8'>
            <input
              type='file'
              className=''
              ref={fileRef}
              accept='application/pdf'
            />
          </div>
        </div>
        (please only upload files as PDFs)
        <div className=''>

        <div className='form-group'>
          <label htmlFor='append-checkbox' className='col-md-4'>Should append to files:</label>
          <div className='col-md-8'>
            <input
              name='append-checkbox'
              id='append-checkbox'
              type='checkbox'
              className='bumper-vert-out-l'
              checked={shouldAppend}
              onChange={(e) => setShouldAppend(e.target.checked)}
              />
          </div>
        </div>
              </div>
        <div className='headroom'>
          <button 
            className='btn btn-primary' 
            type='submit' 
            onClick={uploadFile}
          >
            <Icon type={IconType.upload} /> Upload File
          </button>
        </div>
      </div>
    </>)
  }


  return (
    <div>
      <div>
        {renderBasedOnFormType[props.formType]}
      </div>
    </div>
  )

}


export default ContextForm