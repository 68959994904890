import React, { useEffect, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { toast } from "react-toastify";
import { IConfigGroup } from "../../ObjectTypes/SetupTypes";
import StoreX from "../../redux/oldStore";
import { ServerResponse } from "../../utils/Server";
import { IFileUpload } from "../FormFromApiData/FormModal";
import Icon, { IconType } from "../Icon/Icon";
import SetupGroup from "./SetupGroup";
import { ValueChange } from "./SetupTypes";

interface ISetupWizardProps {
  wizardKey: string;
  closeWizard?: Function;
  updateValues?: Function;
  autoSave?: boolean;
  onSave?: Function;
}

const SetupWizard = (props: ISetupWizardProps) => {
  const [wizard, setWizard] = useState<IConfigGroup>();
  const [useTabs, setUseTabs] = useState<boolean>(false);
  const [changedValues, setChangedValues] = useState<ValueChange[]>([]);
  const [files, setFiles] = useState<IFileUpload[]>([]);

  // useEffect(()=>{
  //     init();
  // }, []);
  useEffect(() => {
    console.log(`wizard key changed... ${props.wizardKey}`);
    init();
  }, [props.wizardKey]);

  const init = () => {
    StoreX.instance.server
      .getApi<ServerResponse<any>>(`../SetupWiz/Wizard/${props.wizardKey}`)
      .then((x) => {
        if (x.Success) {
          let w: IConfigGroup = x.Value.group;
          if (!w) {
            w = {
              Tags: [],
              Key: props.wizardKey,
              PublicId: "not found public id",
              Name: "Oops, Not Found",
              Description: `The configurations you are looking for are not found. <br/><strong>Key: ${props.wizardKey}</strong>`,
              Children: [],
              ConfigType: "group",
              Value: "",
            };
            console.log(`Wizard Not Found: ${props.wizardKey}`);
          }
          setWizard(w);
          let tabs = w.Children?.filter((r) => r.Type === 'group').length ?? 0;
          setUseTabs(tabs > 1);
        } else {
          toast.error(x.Message, { autoClose: false });
        }
      });
  };

  const save = () => {
    let values = changedValues;
    if (values.length === 0) {
      toast.info("No changeds need to be saved.");
      return;
    }

    StoreX.instance.server
      .postApi<ServerResponse<any>>(
        `../SetupWiz/Save`,
        { values: values, key: props.wizardKey },
        files.map((x) => x.file)
      )
      .then((x) => {
        if (x.Success) {
          if (props.onSave) props.onSave();
          toast.success("Changes saved.");
          let w = x.Value.group;
          setWizard(w);
          let tabs = w.Children?.filter((r) => r.Type == "group").length ?? 0;
          setUseTabs(tabs > 1);
          setFiles([]);
        } else {
          toast.error(x.Message, { autoClose: false });
        }
      });
  };

  const autoSave = (values: ValueChange[], newFiles: IFileUpload[]) => {
    if (!props.autoSave) return;
    if (values.length === 0) {
      return;
    }

    let myFiles = (newFiles ?? files).map((x) => x.file);

    StoreX.instance.server
      .postApiQuite<ServerResponse<any>>(
        `../SetupWiz/Save`,
        { values: values, key: props.wizardKey },
        myFiles
      )
      .then((x) => {
        if (x.Success) {
          if (props.onSave) props.onSave();
          setFiles([]);
          setWizard(x.Value.group);
          // let w = x.Value.group;
          // setWizard(w);
          // let tabs = w.Children?.filter(r=>r.Type == 'group').length;
          // setUseTabs(tabs > 1);
        } else {
          toast.error(x.Message, { autoClose: false });
        }
      });
  };

  const valueUpdated = (publicId: string, value: any) => {
    let values = changedValues.filter((x) => x.PublicId !== publicId);
    values.push({ PublicId: publicId, Value: value });
    autoSave(values, files);
    setChangedValues(values);
  };

  const valueFileUpdated = (publicId: string, value: any, file?: File) => {
    let values = changedValues.filter((x) => x.PublicId !== publicId);
    values.push({ PublicId: publicId, Value: file?.name ?? value ?? "" });

    let fs = files.filter((x) => x.Key !== publicId);
    if (file) fs.push({ file: file, Key: publicId });
    setFiles(fs);
    setChangedValues(values);
    autoSave(values, fs);
  };

  const buildTabs = () => {
    let tabs = wizard?.Children?.filter((x) => x.Type == "group") ?? [];
    let mainConfigs = wizard?.Children?.filter((x)=> x.Type === "config") ?? [];
    if (tabs.length === 0) return <>No Tabs to return</>;
    return (
      <>
        <Tabs>
          <TabList>
            
          {mainConfigs.length > 0 && <Tab>Main</Tab>}
            {tabs?.map((t, i) => {
              return <Tab key={`tab-${i}`}>{t.Name}</Tab>;
            })}
          </TabList>
          
          {mainConfigs.length > 0 && <TabPanel>Main</TabPanel>}
          {tabs?.map((t, i) => {
            return (
              <TabPanel key={`tab-panel-${i}`}>
                <SetupGroup
                  Save={save}
                  Group={t}
                  IsSubGroup={true}
                  ValueUpdated={valueUpdated}
                  ValueFileUpdated={valueFileUpdated}
                />
              </TabPanel>
            );
          })}
        </Tabs>
      </>
    );
  };

  const buildPrimaryGroup = () => {
    if (wizard) {
      return (
        <>
          <SetupGroup
            Save={save}
            Group={wizard}
            CloseGroup={props.closeWizard}
            ValueUpdated={valueUpdated}
            ValueFileUpdated={valueFileUpdated}
          />
        </>
      );
    } else {
      <span className="label label-warning">
        <Icon type={IconType.warning} /> Wizard is not defined.{" "}
      </span>;
    }
  };

  return (
    <>
      {wizard && (
        <>
          {/* {useTabs && <>{buildTabs()}</>} */}
          {/* {!useTabs && <>{buildPrimaryGroup()}</>} */}

          <SetupGroup
                  Save={save}
                  Group={wizard}
                  IsSubGroup={true}
                  ValueUpdated={valueUpdated}
                  ValueFileUpdated={valueFileUpdated}
                />

          {!props.autoSave && (
            <div className="form-group">
              <button
                type="button"
                className="btn btn-secondary"
                title="save changes"
                onClick={save}
              >
                <Icon type={IconType.save} />
                Save
              </button>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default SetupWizard;
