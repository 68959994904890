import React, { useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import { toast } from 'react-toastify';
import { store } from '../../redux/oldStore';
import MakeARequest from './MakeARequest';
import RequestsToApprove from './RequestsToApprove';

export interface IFair {
  Name: string;
  State: string | null;
  Website: string;
  Id: number;
}

export interface IFeedRequest extends IFair {
  ApprovedAtUtc: string | null; //null
  ApprovedBy: string | null; //null
  CreatedAtUtc: string | null; //"12/31/2020"
  DestinationFairId: number; //19
  SourceFairId: number; //1
}

const FairPromotionLinkingContainer = (props) => {
  const [everythinkg, setEverythinkg] = useState();
  const [fairs, setFairs] = useState<IFair[]>([]);
  const [feederRequests, setFeederRequests] = useState<IFeedRequest[]>([]);
  const [feedRequests, setFeedRequests] = useState<IFeedRequest[]>([]);

  useEffect(() => {
    store.server.getApi('../FairPromotion/Info')
      .then((res: any) => {
        console.log(res);
        let { fairs, feederRequests, feedRequests } = res.Value
        setEverythinkg(res.Value);
        setFairs(fairs);
        setFeederRequests(feederRequests);
        setFeedRequests(feedRequests);

      })
      .catch( err => {
        console.error(err);
        toast.error(err);
      })

  }, [])
  return(
    <div className="bumper-l bumper-out-l">
      <div className='flex-between'>
      <h3>Fair Promotion Linking</h3>
        
      </div>
      <Tabs>
        <TabList>
          <Tab>Make a request (From this contest)</Tab>
          <Tab>Requests to approve (To this contest)</Tab>
        </TabList>

        <TabPanel>
          <MakeARequest 
            fairs={fairs}
            feedRequests={feedRequests}
            setFeedRequests={setFeedRequests}
          />
        </TabPanel>
        <TabPanel>
          <RequestsToApprove 
            fairs={fairs}
            feederRequests={feederRequests}
            setFeederRequests={setFeederRequests}
          />
        </TabPanel>
      </Tabs>
    </div>
  )
}

export default FairPromotionLinkingContainer