import { Icons } from "react-toastify";
import Icon, { IconType } from "../../Icon/Icon";


const RefreshSite = ()=>{

    return (
        <div>
            <ol>
                <li>
<h3>Archive Users</h3>

<ul>
    <li>NOTE: If you are planning on doing any testing or training in the system it is often helpful to have data avaialble. We recommend waiting to Archive Users till you are done with everything else and a week or so prior to registration opening up again
        <ol>
            <li>Go to Staff <Icon type={IconType.next}/> Archive Fair</li>
            <li>Select the User Types you would like to clear out - Participants/Students are selected by default and cannot be deselected</li>
            <li>Enter your username - The fair will not be cleared if you don't do this</li>
<li>Click Clear Fair</li>
<li>Review your people page to make sure everything looks as expected</li>
        </ol>

    </li>
</ul>

</li><li>
<h3>Update Scheduled Dates</h3>
<ol>
<li>Go to Staff <Icon type={IconType.next}/> Fair Setup <Icon type={IconType.next}/> Schedule</li>
<li>Revise the dates there as needed
<ul>
    <li>If you don't have a late registration period set this date to be the same as Registration Ends</li>
</ul>

</li>

</ol>
</li><li>
<h3>Update Home Page Content</h3>
See <a href="https://wiki.zfairs.com/index.php?title=Anchor_Points" target={'_blank'} title="view info on anchor points">Anchor Points</a> for tips on using a helpful feature
<h4>Method 1</h4>
<ol>
<li>Go to the page you would like to edit</li>
<li>Click the Edit button next to your username</li>
<li>Double click somewhere in the page you are wanting to edit</li>
<li>Make the needed changes</li>
</ol>
<h4>Method 2</h4>
<ol>
<li>Go to Staff <Icon type={IconType.next}/> Fair Setup <Icon type={IconType.next}/> Customize Text</li>
<li>Select the page you would like to edit</li>
<li>Update the page as desired</li>
</ol>
</li><li>
<h3>Review / Update General information</h3>
<ol>
<li>Go to Staff <Icon type={IconType.next}/> Fair Setup <Icon type={IconType.next}/> General Information</li>
<li>Review and revise the information there as needed</li>
</ol>
</li><li>
<h3>Review / Update Rubrics & Rounds</h3>
<ol>
<li>Go to Judging <Icon type={IconType.next}/> Setup <Icon type={IconType.next}/> Rubrics/Rounds</li>
<li>Review and Revise the content in each Rubric as needed</li>
<li>Review the Dates set for Scheduled Interviews on each round</li>
</ol>
</li>
</ol>
        </div>
    );

};

export default RefreshSite;