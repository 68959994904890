import {useState, useEffect} from 'react';
import { toast } from 'react-toastify';
import StoreX, { store } from '../../redux/oldStore';
import Icon, { IconType } from "../Icon/Icon";
import Modal from '../Modal/Modal';
import { IFormGroup, IWizardQuestion } from "./formTypes";

interface IFormsWizardBuilder {
    packet:IFormGroup;
    updateAndSave:Function;
};

const FormsWizardBuilder = (props:IFormsWizardBuilder) =>{
    const [questions, setQuestions] = useState<IWizardQuestion[]>(props.packet.WizardQuestions ?? []);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [question, setQuestion] = useState<IWizardQuestion>();

    const EditQuestion = (question?:IWizardQuestion) =>{
        let max = 1;
        questions.forEach(x=>{
            max = Math.max(max, x.Order + 1);
        })
        let q = question ?? {Order:max,PublicId:StoreX.NewGuid(),Question:''};

        setQuestion(q);
        setShowEditModal(true);
    };

    const RemoveQuestion = () =>{
        if(window.confirm(`Are you sure you want to remove this question, this cannot be undone?`)){
            let q = questions.filter(q=>q.PublicId !== question?.PublicId);
            props.updateAndSave(q);
            setQuestions([...q]);
            setQuestion(undefined);
            setShowEditModal(false);
            toast.success(`Question removed!`);
        }
    };
    const SaveQuestion = () =>{

        if(!question?.Question){
            toast.warn(`So, what's the question?`);
            return;
        }

            let qs = questions;
            let found = false;

            for(let i =0; i < qs.length; i++){
                if(qs[i].PublicId == question?.PublicId){
                    found = true;
                    qs[i] = {...question};
                    break;
                }
            }

            if(!found) qs.push(question);

            qs = qs.sort((a, b)=> a.Order - b.Order);

            props.updateAndSave([...qs]);
            setQuestions([...qs]);
            toast.success(`Question saved!`);
    };
    
    return (
        <>
        <div>
            <h4>Form Wizard</h4>
            Here you can build the logic of which forms are required or not required for your packet.
            <hr/>

            <div>
                <div className="flex-between">
                    <h3>Questions</h3>
                    <button type="button" onClick={()=>{EditQuestion()}} className="btn btn-secondary">
                        <Icon type={IconType.plus}/> New Question
                    </button>
                </div>
                <div className="wizard-question-container">
                    {questions.map((x,i)=>{
                        return (<div key={`wiz-question-${i}`} className='qizard-question-tile'>
                            <div>
                                <span className='text-muted'>{x.Order} - </span>{x.Question}
                                </div>
                                <span className='click' onClick={()=>{EditQuestion(x);}} title='Click to edit question'>
                                    <Icon type={IconType.edit}  />
                                </span>
                            </div>);
                    })}
                </div>
            </div>

        </div>


        {showEditModal && <Modal setModalOpen={setShowEditModal} title={'Edit Question'} size='l'>
            <div className='form-horizontal'>
                <div className='form-group'>
                    <label htmlFor='wiz-que-order' className='col-sm-4 control-label'>Order</label>
                    <div className='col-sm-2'>
                        <input type='number' max={10000} min={0} step={1} className='form-control'
                         id='wiz-que-order'
                         value={question?.Order}
                         onChange={(e)=>{if(question)setQuestion({...question, Order:+e.target.value});}}
                         />
                    </div>
                </div>
                <div className='form-group'>
                    <label htmlFor='wiz-que' className='col-sm-4 control-label'>Question</label>
                    <div className='col-sm-8'>
                        <input type='text' maxLength={4000} className='form-control' autoFocus
                         id='wiz-que'
                         value={question?.Question}
                         onChange={(e)=>{if(question)setQuestion({...question, Question:e.target.value});}}
                         />
                    </div>
                </div>
                <div className='form-group'>
                    <div className='col-sm-8 col-sm-offset-4'>
                        <button type='button' className='btn btn-secondary' title='Click to save' onClick={SaveQuestion}>
                            <Icon type={IconType.edit} /> Save
                        </button>
                        <button type='button' className='btn btn-default' title='Click to remove question' onClick={RemoveQuestion}>
                            <Icon type={IconType.trashO} /> Remove
                        </button>
                    </div>
                </div>
            </div>
        </Modal>}

        </>
    );
};


export default FormsWizardBuilder;