export class AudioPlayer {
  constructor() {
    let self = this;
      this.sound = {
        players: {
          meetingEnter:new Audio('/sounds/EnterMeeting.mp3'),
          meetingLeave:new Audio('/sounds/LeaveMeeting.mp3'),
          videoEnter:new Audio('/sounds/EnterVideo.mp3'),
          videoLeave:new Audio('/sounds/LeaveVideo.mp3'),
          newMessage:new Audio('/sounds/NewMessage.mp3'),
          attention:new Audio('/sounds/attentionPing.mp3'),
          attentionB:new Audio('/sounds/attentionPingB.mp3'),
          bell:new Audio('/sounds/boxingBell.mp3'),
        },
        meetingEnter : ()=>{self.sound.players.meetingEnter.play(); console.log('start sound play');},
        meetingLeave : ()=>{self.sound.players.meetingLeave.play();},
        //videoEnter : ()=>{self.sound.players.videoEnter.play();},
        //videoLeave : ()=>{self.sound.players.videoLeave.play();},
        newMessage : ()=>{self.sound.players.newMessage.play();},
        attention : ()=>{self.sound.players.attention.play();},
        //attentionB : ()=>{self.sound.players.attentionB.play();},
        bell : ()=>{self.sound.players.bell.play();},
      };

      
      this.sound.players.meetingLeave.volume = .1;
      this.sound.players.meetingEnter.volume = .1;
      this.sound.players.attention.volume = .1;
      this.sound.players.bell.volume = .1;
  }
}

let audioPlayerx = new AudioPlayer();

export default audioPlayerx