import { useEffect, useState } from 'react';
import { IState } from '../../../redux/redux';
import Icon, { IconType } from '../../Icon/Icon';
import { IAssignmentInfo, IJudgeAssignment } from './JudgingCirlcesTypes';
import { IJudgeInfo } from '../../_Core/CoreTypes';
import Modal from '../../Modal/Modal';
import { IListItem } from '../JudgingInfoDataTypes';
import { store } from '../../../redux/oldStore';
import { ServerResponse } from '../../../utils/Server';
import { toast } from 'react-toastify';

interface IProps {
  state: IState;
  info: IAssignmentInfo;
  categoriesDic: Record<string, string>;
  divisionsDic: Record<string, string>;
  roundDic: Record<string, string>;
  onRemove: (assignment: IJudgeAssignment) => void;
  onUpdate: (assignment: IJudgeAssignment) => void;
}

const JudgingCirclesJudgeAssignmentList = (props: IProps) => {
  const [assignmentDic, setAssignmentDic] = useState<Record<string, IJudgeAssignment | undefined>>({});
  const [selectedRound, setSelectedRound] = useState<number>();
  const [selectedJudge, setSelectedJudge] = useState<IJudgeInfo>();
  const [showMakeAssignment, setShowMakeAssignment] = useState<boolean>(false);
  const [roundAssignment, setRoundAssingment] = useState<number>(0);

  useEffect(() => {
    if (props.info.JudgeAssignments) {
      let dic = {};
      props.info.JudgeAssignments.forEach((x, i) => {
        dic[dicKeyFromAssignment(x)] = x;
      });

      setAssignmentDic(dic);
    }
  }, [props.info, props.info.ProjectAssignments, props.info.JudgeAssignments]);

  const openMakeAssignment = (judge: IJudgeInfo, round: IListItem) => {
    setSelectedJudge(judge);
    setSelectedRound(+round.id);
    setShowMakeAssignment(true);
    setRoundAssingment(assignmentDic[dicKey(round.id, judge.id)]?.CircleId ?? 0);
  };

  const dicKeyFromAssignment = (assignment: IJudgeAssignment) => {
    return `${assignment.RoundId}:${assignment.JudgeId}`;
  };

  const dicKey = (roundId: number | string, judgeId: number | string) => {
    return `${roundId}:${judgeId}`;
  };

  const Save = () => {
    if (+roundAssignment === 0) {
      Remove();
      return;
    }

    store.server.postApi<ServerResponse<IAssignmentInfo>>(`../JudgingCircles/JudgeAssignment`, {roundId:selectedRound, judgeId:selectedJudge?.id, circleId:roundAssignment})
    .then((x) => {
      if (x.Success) {
        let assignments = { ...assignmentDic };
        let assignment = assignments[dicKey(selectedRound ?? 0, selectedJudge?.id ?? 0)];
        
        if(selectedJudge && selectedRound) {
        assignments[dicKey(selectedRound ?? 0, selectedJudge?.id ?? 0)] = {
            JudgeId:selectedJudge.id,
            RoundId: +selectedRound,
            CircleId: +roundAssignment,
            Name: props.info.Circles.find(y=>y.id === roundAssignment)?.name ?? 'Unknown',
        };}

        setAssignmentDic(assignments);

        if (assignment) {
          props.onUpdate(assignment);          
        }

        toast.success(`Assignment for ${selectedJudge?.firstName} ${selectedJudge?.lastName} was saved.`);

        setShowMakeAssignment(false);
      } else {
        toast.error(x.Message);
      }
    });

  };

  const Remove = () => {
    if (!window.confirm(`Are you sure you want to remove this assignment for ${selectedJudge?.firstName} ${selectedJudge?.lastName}?`)) {
      return;
    }

    store.server.postApi<ServerResponse<IAssignmentInfo>>(`../JudgingCircles/ClearJudgeAssignment`, {roundId:selectedRound, judgeId:selectedJudge?.id})
    .then((x) => {
      if (x.Success) {
        let assignments = { ...assignmentDic };
        let assignment = assignments[dicKey(selectedRound ?? 0, selectedJudge?.id ?? 0)];
        
        assignments[dicKey(selectedRound ?? 0, selectedJudge?.id ?? 0)] = undefined;
        setAssignmentDic(assignments);

        if (assignment) {
          props.onRemove(assignment);          
        }

        toast.info(`Assignment for ${selectedJudge?.firstName} ${selectedJudge?.lastName} was cleared.`);

        setShowMakeAssignment(false);
      } else {
        toast.error(x.Message);
      }
    });
  };

  const mapJudges = () => {
    if (props.info.Judges.length === 0) {
      return (
        <div className="alert alert-info">
          <h4>
            <Icon type={IconType.warning} /> No Entries Found
          </h4>
          It seems you don't have any entries at this time. You will not be able to make assignments until you have entries to assign.
        </div>
      );
    } else {
      return (
        <>
          <table>
            <tr>
              <th>Entry Info</th>
              {props.info.Rounds.map((r, ri) => {
                return <th key={`jcpal-li-head-${ri}`}>{r.name}</th>;
              })}
            </tr>
            {props.info.Judges.map((x, i) => {
              return (
                <tr className="list-item" key={`jcpal-li-${i}`}>
                  <td>
                    <div className="title">{x.lastName}, {x.firstName}</div>
                    {(x.assignedCategory??0) > 0 && <div className="detail">{props.categoriesDic[x.assignedCategory??'']}</div>}
                  </td>
                  {props.info.Rounds.map((r, ri) => {
                    let assignment = assignmentDic[dicKey(r.id, x.id)];

                    return (
                      <td key={`jcpal-li-x-${i}-${ri}`}>
                        {assignment && <span>{assignment.Name}</span>}
                        <span
                          className="click big"
                          title="Click to make circle assignment"
                          onClick={() => {
                            openMakeAssignment(x, r);
                          }}>
                          <Icon type={IconType.idBadge} />
                        </span>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </table>
        </>
      );
    }
  };

  return (
    <>
      <div className="judging-cirlces-assignment-list">{mapJudges()}</div>

      {showMakeAssignment && (
        <Modal setModalOpen={setShowMakeAssignment} size="l" title={`Circle Assignment for ${selectedJudge?.firstName} ${selectedJudge?.lastName}`}>
          <div className="form-horizontal">            
            <div className="form-group">
              <label className="control-label col-sm-4" htmlFor="jcpal-ca">
                Circle
              </label>
              <div className="col-sm-4">
                <select
                  id="jcpal-ca"
                  className="form-control"
                  value={roundAssignment}
                  onChange={(x) => {
                    setRoundAssingment(+x.target.value);
                  }}>
                  <option value={0}>Select Circle</option>
                  {props.info.Circles.filter((x) => x.roundId === selectedRound).map((x, i) => {
                    return <option value={x.id}>{x.name}</option>;
                  })}
                </select>
              </div>
            </div>
            <div className="form-group">
              <div className="col-sm-4"></div>
              <div className="col-sm-8">
                <button type={'button'} className="btn btn-secondary" onClick={Save}>
                  <Icon type={IconType.save} /> Save
                </button>
                <button type={'button'} className="btn btn-default" onClick={Remove}>
                  <Icon type={IconType.trashO} /> Remove Assignment
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default JudgingCirclesJudgeAssignmentList;
