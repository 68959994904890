import React, { useState, useEffect } from "react";
import { store } from "../../redux/oldStore";
import { toast } from "react-toastify";
import FileDownload from 'js-file-download';
import { splitCSV } from '../../utils/Tools';
import { ColumnData } from "../../utils/CommonTypes/CommonTypes";
import { UploadType } from "./ParticipantsUpload";


// interface ColumnData {
//   value: string;
//   id: string;
// }

// export type UploadType = 'comma' | 'tab';

// export type OtherUserType = 'Judge'|'SpecialAwardJudges' | 'Interpreters' | 'Moderators';


const SchoolUpload = (props: any) => {
  // =========Lifted straight from AcaDeca========= //

  const [fileText, setFileText] = useState<any>(null);
  const [headersIncluded, setHeadersIncluded] = useState(false)
  const [loaded, setLoaded] = useState(false);
  const [parsingErrors, setParsingErrors] = useState<any[] | null>([]);
  //  const [sendWelcomeEmail, setSendWelcomeEmail] = useState(true);
  //  const [sendSignInCredentials, setSendSignInCredentials] = useState(true);
  const [additionalColumns, setAdditionalColumns] = useState<ColumnData[]>([])
  const [selectedColumn, setSelectedColumn] = useState<number | string>(-1)
  const [columnToAdd, setColumnToAdd] = useState<ColumnData>();
  //  const [customQuestionsProject, setCustomQuestionsProject] = useState<ColumnData[]>([]);
  //  const [customQuestionsPerson, setCustomQuestionsPerson] = useState<ColumnData[]>([]);
  const [uploadType, setUploadType] = useState<UploadType>('comma');




  useEffect(() => {
    // store.server.getApi(`../import/info`)
    //   .then((res: any) => {
    //     console.log(res);
    //     setCustomQuestionsProject(res.Value.CustomQuestionsProject.map(q => { return { value: q.text, id: q.value}}));
    //     setCustomQuestionsPerson(res.Value.CustomQuestionsParticipant.map(q => { return { value: q.text, id: q.value}}));
    //   })
    //   .catch(err => {
    //     console.error(err);
    //   })
  }, [])

  const schoolFileSelected = (e: any) => {
    let file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = async (e: any) => {
      const text = e.target.result;
      let lines: any[] = [];
      const perrors: string[] = [];
      let formattedText = text.replaceAll('\r\n', '\n').replaceAll('\r', '\n');
      const rawLines = formattedText.split("\n");
      rawLines.forEach((l: string, index: number) => {
        if (l.length > 0) {
          const line = splitCSV(l, uploadType);
          const columnsPerLine = 10 + additionalColumns.length;
          if (line.length === columnsPerLine) {
            //The line looks good
            lines.push(line);
          } else {
            const errorMessage = `Line ${index + 1} has ${line.length} columns, but should have ${columnsPerLine}`
            console.log('this is the line', line)
            console.error(errorMessage);
            perrors.push(errorMessage);
          }
        }
      });
      if (perrors.length) {
        setParsingErrors(perrors);
      } else {
        setParsingErrors(null);
        setFileText(lines);
      }
    };
    reader.readAsText(file, 'utf-8-sig');
  };



  const load = (e) => {
    let realText = headersIncluded ? fileText.slice(1) : fileText
    console.log(realText, additionalColumns.map(x => x.id))
    store.server.postApi("../Import/LoadSchools", { 
      Data: realText, 
      additionalColumns: additionalColumns.map(x => x.id)
    }).then((x: any) => {
      if (x.Success) {
        if(x.Value.FailedToLoad.length > 0) {
          toast.warning('something went wrong in the upload, refresh and try these schools again');
          setParsingErrors(x.Value.FailedToLoad);
          setLoaded(true);
        } else {
          toast.success("Your schools have been loaded.");
          setLoaded(true);
        }
      } else {
        toast.error(x.Message);
      }
    }).catch(err => {
      console.log(err);
      toast.error(err);
    });
  };

  const downloadTemplateClick = () => {
    let moreColumns = additionalColumns.reduce((acc, col) => {
      let { id, value } = col
      return { ...acc, [value]: '' }
    }, {})
    const items = [{
      'Name': ''
      ,'Address': ''
      ,'Address 2': ''
      ,'City': ''
      ,'State': ''
      ,'Zip': ''
      ,'County': ''
      ,'Phone': ''
      ,'Grade Low (number)': ''
      ,'Grade High (number)': ''
      , ...moreColumns
    }]

    const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
    const header = Object.keys(items[0])
    const csv = [
      header.join(','), // header row first
      ...items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
    ].join('\r\n')

    console.log(csv)
    // downloady stuff here
    FileDownload(csv, 'school_upload_template.csv');
  }

  const handleColumnToAddChange = (e: any) => {
    let index = e.nativeEvent.target.selectedIndex;
    let formattedColumn = {
      id: e.target.value,
      value: e.nativeEvent.target[index].text
    };
    // console.log(e, formattedColumn);
    setSelectedColumn(e.value);
    setColumnToAdd(formattedColumn);
  }


  const handleAddColumn = () => {
    if (columnToAdd) setAdditionalColumns([...additionalColumns, columnToAdd])
    setColumnToAdd(undefined);
    setSelectedColumn(-1);
  }

  const handleRemoveCol = (id: string) => {
    setAdditionalColumns(additionalColumns.filter(x => x.id !== id));
  }

  return (
    <>
      <div className="loading-container">
        <h1>Loading School Information</h1>
        <div>
          You can load information for your contest using a CSV file.
          You can save Excel and Google Doc files as CSV files.
          <ul>
            <li>
              <strong>Google Sheets</strong> Select File, then Download then
              as Comma-separated values (.csv)
            </li>
            <li>
              <strong>Excel</strong> Select File, Save as, then change type to
              (*.csv)
            </li>
          </ul>
        </div>
        <div className=''>

          <h3>Load Schools</h3>
          <div className="flex-between">
            <h4>File Format</h4>
            <button className='btn btn-secondary btn-xs' onClick={downloadTemplateClick}>Download Template</button>
          </div>
          <div>
            The School CSV file should include the following columns in the following
            order.
            <ul>
              <li>Name</li>
              <li>Address</li>
              <li>Address 2</li>
              <li>City</li>
              <li>State</li>
              <li>Zip</li>
              <li>County</li>
              <li>Phone</li>
              <li>Grade Low (number)</li>
              <li>Grade High (number)</li>
              {additionalColumns.map((col, i) => {
                return (
                  <li key={`${col.id}-${i}`}>{col.value} <span onClick={() => handleRemoveCol(col.id)}><i className="far fa-trash-alt"></i></span></li>
                )
              })
              }
            </ul>
            <div className='form-group soft-border'>
              {/* Style this stuff here */}
              <p>Use this drop down and button to add additional columns that you want for uploading data.</p>
              <div className="flex-between">
                <select className='form-control' onChange={(e) => handleColumnToAddChange(e)} value={selectedColumn}>
                  <option value={-1}> </option>
                  <option value="schoolPayingStudentRegFee">School Paying Student Registration Fee (true/false)</option>
                  <option value="PrincipalName">Principal Name</option>
                  <option value="PrincipalEmail">Principal Email</option>
                  <option value="PrincipalPhone">Principal Phone</option>
                </select>
                <button onClick={handleAddColumn} className='btn btn-primary'>add column</button>
              </div>
            </div>

          </div>
          {!loaded &&
            <div className="">
              <div className='flex bumper-l'>
              </div>
              <div className='bumper-l'>
                <label>Delimiter: </label>
                <select className="form-control" onChange={e => setUploadType((e.target.value as UploadType))} value={uploadType}>
                  <option value={'comma'}>Comma</option>
                  <option value={'tab'}>Tab</option>
                </select>
              </div>
              <div className='bumper-l'>
                <label className=''>School File:</label>
                <input
                  type="file"
                  accept=".csv"

                  onChange={schoolFileSelected}
                  onClick={(evt: any) => evt.target.value = null}
                // Set target to null so the onchange event will fire again even if the user picks the same file.
                />
              </div>
              <div className='flex bumper-l'>
                <label htmlFor='headers'>Does the file include headers?</label>
                <input
                  type='checkbox'
                  name="headers"
                  id='headers'
                  className='form-input bumper-sides-out-l'
                  onChange={e => setHeadersIncluded(e.target.checked)}
                  defaultChecked={headersIncluded}
                />
              </div>


              {fileText && fileText.length > 0 && (
                <div className="csv-file-preview">
                  <table>
                    {!headersIncluded
                      &&
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Address</th>
                          <th>Address 2</th>
                          <th>City</th>
                          <th>State</th>
                          <th>Zip</th>
                          <th>County</th>
                          <th>Phone</th>
                          <th>Grade Low (number)</th>
                          <th>Grade High (number)</th>
                        </tr>
                      </thead>

                    }
                    <tbody>

                      {fileText.map((r, ri) => {
                        return (
                          <tr key={'r' + ri}>
                            {r.map((v, vi) => {
                              if (headersIncluded && ri === 0) {
                                return <th key={ri + '-' + vi}>{v}</th>;
                              }
                              return <td key={ri + '-' + vi}>{v}</td>;
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
              {parsingErrors && parsingErrors.length > 0 &&
                <table>
                  {parsingErrors.map((error, index) => (
                    <tr key={index}>
                      <td>{error}</td>
                    </tr>
                  ))}
                </table>
              }
            </div>}

          {!loaded && (fileText && fileText.length > 0)
            && (
              <div className='ready-to-load-area'>
                <h4>If the School file contents look good, press Load</h4>
                <div>
                  {/* Send welcome email: <input type='checkbox' defaultChecked={sendWelcomeEmail} onChange={e => setSendWelcomeEmail(e.target.checked)} /> */}
                  {/* Send sign-in credentials: <input type='checkbox' defaultChecked={sendSignInCredentials} onChange={e => setSendSignInCredentials(e.target.checked)} /> */}
                </div>
                <button type="button" className="btn btn-success" onClick={load}>
                  <i className="fad fa-cloud-upload"></i>
                  Load
                </button>
              </div>
            )
          }
          {loaded && <div>
            <h1>School(s) loaded!</h1>
            If you need to load more Schools refresh your page.
          </div>}
        </div>
      </div>
    </>
  );
}

export default SchoolUpload