import { useEffect, useState } from "react";
import { IState } from "../redux/redux";
import StoreX, { store } from "../redux/oldStore";
import { ServerResponse } from "../utils/Server";
import { toast } from "react-toastify";
import { EasyImage, SlickImage } from "./Tools/ExpandableDiv";


interface IProps {
    state:IState
}

interface IPhotoInfo {
    Name:string,
    Dimension:string,
    FileSizeBytes:number,
    SourceId:string,
    CreatedAtUtc:string,
}

const PhotoGallery = (props:IProps) =>{
    const [data, setData] = useState<IPhotoInfo[]>([]);
    
    useEffect(()=>{
        init();
    }, []);

    const init = () =>{
        //call server...
        let url = StoreX.BuildUrl(`../PhotoBooth/Data`);
        store.server.getApi<ServerResponse<IPhotoInfo[]>>(url)
        .then(x=>{
            if(x.Success){
                let xData = x.Value;
                setData(xData);
            } else {
                toast.error(x.Message);
            }
        });
    };


    return <>
        <div className="image-gallery">
            {data.map((x,i)=>{
                let url = StoreX.BuildUrl(`../File/Client/${x.SourceId}`);
                return <div className="image-of-gallery click" key={i}>
                    <SlickImage image={{path:url, comments:x.Name}} />
                </div>
            })}
        </div>
    </>
};

export default PhotoGallery;