import React, { useState } from 'react'
import Accordion from '../../Accordion/Accordion'
import Modal from '../../Modal/Modal'
import AddOrEditAssessment from './AddOrEditAssessment'

const RubricCategory = (props) => {
  const { category } = props
  const [selectedAssessment, setSelectedAssessment] = useState({})
  const [addOrEditModalShow, setAddOrEditModalShow] = useState(false)

  const editOrAddAssessment = (assessment) => {
    setSelectedAssessment(assessment)
    setAddOrEditModalShow(true)
  }


  const handleSave = (assessmentObj) => {
    console.log('hit save', assessmentObj)
    //see if assessmentObj.Id === category.Assessments.any
    // if yes, then update 
    // else, add new assessment
    // then call save.
    if (!category.Assessments) category.Assessments = []
    let [assessment] = category.Assessments.filter(x => x.Id === assessmentObj.Id)
    let updatedCategory;
    if (!assessment) {
      // add this to the category.Assessments array
      updatedCategory = {...category, Assessments: [...category.Assessments, assessmentObj] };
    } else {
      updatedCategory = {...category, Assessments: category.Assessments.map( x => x.Id === assessmentObj.Id ? assessmentObj : x)};
    }

    props.handleSaveCategory(updatedCategory);


  } 
  const handleAssessmentDelete = (assessmentId) => {
    let updatedCategory = {...category, Assessments: category.Assessments.filter( x => x.Id !== assessmentId) };
    props.handleSaveCategory(updatedCategory)

  }
  
  const AssessmentScoreTypes = {
    1: {MinValue:0, MaxValue:1, range: 'Yes / No'}, 
    2: {MinValue:0, MaxValue:2, range: 'Range: 1 - 3'}, 
    3: {MinValue:0, MaxValue:3, range: 'Range: 1 - 4'}, 
    4: {MinValue:0, MaxValue:4, range: 'Range: 1 - 5'}, 
    5: {MinValue:0, MaxValue:5, range: 'Range: 1 - 6'}, 
    6: {MinValue:0, MaxValue:6, range: 'Range: 1 - 7'}, 
    7: {MinValue:0, MaxValue:7, range: 'Range: 1 - 8'}, 
    8: {MinValue:0, MaxValue:8, range: 'Range: 1 - 9'}, 
    9: {MinValue:0, MaxValue:9, range: 'Range: 1 - 10'}, 
    10: {MinValue:0, MaxValue:14, range: 'Range: 1 - 15'}, 
    11: {MinValue:0, MaxValue:19, range: 'Range: 1 - 20'}, 
    12: {MinValue:0, MaxValue:24, range: 'Range: 1 - 25'}, 
    13: {MinValue:-1, MaxValue:1, range: 'Range: 0 - 2'}, 
    14: {MinValue:-1, MaxValue:2, range: 'Range: 0 - 3'}, 
    15: {MinValue:-1, MaxValue:3, range: 'Range: 0 - 4'}, 
    16: {MinValue:-1, MaxValue:4, range: 'Range: 0 - 5'}, 
    17: {MinValue:-1, MaxValue:5, range: 'Range: 0 - 6'}, 
    18: {MinValue:-1, MaxValue:6, range: 'Range: 0 - 7'}, 
    19: {MinValue:-1, MaxValue:7, range: 'Range: 0 - 8'}, 
    20: {MinValue:-1, MaxValue:8, range: 'Range: 0 - 9'}, 
    21: {MinValue:-1, MaxValue:9, range: 'Range: 0 - 10'}, 
    22: {MinValue:-1, MaxValue:14, range: 'Range: 0 - 15'}, 
    23: {MinValue:-1, MaxValue:19, range: 'Range: 0 - 20'}, 
    24: {MinValue:-1, MaxValue:24, range: 'Range: 0 - 25'},
    25: {value: 25, MinValue:-1, MaxValue:99, range: 'User Enter'},
    26: { value: 26, MinValue: -1, MaxValue: 29, range: "Range: 0 - 30" },
    27: { value: 27, MinValue: -1, MaxValue: 39, range: "Range: 0 - 40" },
    28: { value: 12, MinValue: 0, MaxValue: 24, range: "Range: 1 - 25" },
    29: { value: 28, MinValue: 0, MaxValue: 29, range: "Range: 1 - 30" },
  }

  return(
    <>
    <Accordion 
        title={`${category.Value}% - ${category.Name}`}
        expanded={true}
        subTitle='edit'
        subTitleFunction={() => props.editOrAddCategory(category)}

      >
        {category.Assessments?.sort((a,b)=>{if(a.Sort == b.Sort)return a.Question > b.Question?1:-1; return a.Sort > b.Sort ? 1:-1}).map( (assessment, i) => {
          return(
            <div 
              className='category-assessment flex-between'
              key={`${assessment.Id}${i}`}
            >
              <div className='c-a-title assessment-title'>{assessment.Question}</div>
              <div className='c-a-scoring-range text-muted'>{AssessmentScoreTypes[assessment.ScoreType].range}</div>
              <div className='c-a-weight text-muted'>
              {assessment.ValueOverride > 0 &&<> Weight: {assessment.ValueOverride}</>}
              </div>
              <div className='c-a-weight text-muted'>

              {assessment.AllowNa && <>Allow N/A</>}
              </div>
          
              {assessment.IsPenaltyAssessment 
                && 
                  <> 
                    Penalty
                    <i className="fas fa-siren-on text-danger"></i>
                  </>
              }
              
              <button 
                className='btn btn-secondary edit-assessment-btn' 
                onClick={() => editOrAddAssessment(assessment)}
              >
                <i className="far fa-edit"></i>
                edit 
              </button>
            </div>
          )
        })}
        <div 
          className='category-assessment'
          
        >
          <button 
            className='btn btn-success add-assessment-btn'
            onClick={() => editOrAddAssessment({})}
          >
            <i className="far fa-plus-circle"></i>
            Add Assessment
          </button>
        </div>
      </Accordion>
        {addOrEditModalShow
          && <Modal
              title={selectedAssessment.Question ? 'Edit Assessment.' : 'Add Assessment.'}
              setModalOpen={setAddOrEditModalShow}
              size="l"
            >
              <AddOrEditAssessment
                setModalOpen={setAddOrEditModalShow}
                assessment={selectedAssessment}
                handleSave={handleSave}
                handleDelete={handleAssessmentDelete}
              />
            </Modal>
        
        }
      </>
  )
}

export default RubricCategory