import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { store } from '../../redux/oldStore'
import Modal from '../Modal/Modal';

import io from "socket.io-client";
import IndividualChat from './IndividualChat';


export type messageType = "subscribe" | "request" | "chat" | "echo" | "join" | "removed" | "history" | "room-list" | "clear" | "clear-room-list" | undefined;

export interface IChatContainer {
  roomId: string;
  messages: IMsg[];
  name: string;
  isOpen: boolean;
}



export interface IMsg {
  type: messageType;
  subtype: string | undefined;
  roomId: string;
  message: string | null;
  personId: string;
  name: string;
  history: string[] | null;
  rooms: string[] | null;
  date: Date | null;
}

interface ISupportChatProps {
  socket: any | null;
  isSupportAgent: boolean;
  fairId: string | null;
  clientId: string | null;
  personId: string | null;
  projectId: string | null;
  name: string;
}

interface ISupportChatState {
  isReady: boolean;
  isOpen: boolean;
  socket: any;
  roomId: string | null;
  messageTape: IMsg[];
  currentMessage: string;
  isSubscribed: boolean;
  activeRoom: string | null;
  supportChats: IMsg[];
  chatList: IChatContainer[];
}


class SupportChats extends Component<ISupportChatProps, ISupportChatState> {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false,
      isOpen: false,
      socket: {},
      roomId: null,
      messageTape: [],
      currentMessage: '',
      isSubscribed: false,
      activeRoom: null,
      supportChats: [],
      chatList: [],
    }
  }
  // const [isReady, setIsReady] = useState<boolean>(false);
  // const [isOpen, setIsOpen] = useState<boolean>(false);
  // const [socket, setSocket] = useState<any>(this.props.socket);
  // const [roomId, setRoomId] = useState<string | null>(null);
  // const [messageTape, setMessageTape] = useState<IMsg[]>([]);
  // const [currentMessage, setCurrentMessage] = useState<string>('');
  // const [isSubscribed, setIsSubscribed] = useState<boolean>(false); 
  // const [activeRoom, setActiveRoom] = useState<string>();
  // const [supportChats, setSupportChats] = useState<IMsg[]>([]);

  componentDidMount() {
    this.init();

    if (this.props.projectId) {
      let cl = this.state.chatList;
      cl.push({ roomId: this.props.projectId, messages: [], name: '', isOpen: true, });
      this.setChatList(cl);
      console.log('chats', cl);
    }
  }

  setIsReady = (thing: boolean) => { this.setState({ isReady: thing }) }
  setIsOpen = (thing: boolean) => { this.setState({ isOpen: thing }); console.log(this.state) }
  setSocket = (thing: any) => { this.setState({ socket: thing }) }
  setRoomId = (thing: string | null) => { this.setState({ roomId: thing }) }
  setMessageTape = (thing: IMsg[]) => { this.setState({ messageTape: thing }) }
  setCurrentMessage = (thing: string) => { this.setState({ currentMessage: thing }) }
  setIsSubscribed = (thing: boolean) => { this.setState({ isSubscribed: thing }) }
  setActiveRoom = (thing: string | null) => { this.setState({ activeRoom: thing }) }
  setSupportChats = (thing: IMsg[]) => { this.setState({ supportChats: thing }) }
  setChatList = (thing: IChatContainer[]) => { this.setState({ chatList: thing }) }



  // useEffect(() => {
  //   if (!socket) return;
  //   socket.on('help-desk', socketFunctions);
  //   return () => socket.off('help-desk', socketFunctions);
  // }, [socket]);


  init = () => {
    let isDev = window.location.host.indexOf("localhost") > -1;
    let url = isDev
      ? "http://localhost:9192/help-desk"
      : "https://socket-help-desk.zfairs.com/help-desk";

      url = "https://socket-help-desk.zfairs.com/help-desk";
      console.log('Socket===>',url)

    let s:SocketIOClient.Socket;
    
      s = io(url, {
      query: {
        fairId: this.props.fairId,
        clientId: this.props.clientId,
        personId: this.props.personId,
        projectId: this.props.projectId,
        judge: false,
      },
      transports: ['websocket', 'polling']
    });
    s.on('error', err => {
      console.log('Socket Connection Failed' ,err);
      debugger;
    });
    s.on('connection_error', err => {
      console.log('Socket Connection Failed' ,err);
      debugger;
    });


    s.on('help-desk', this.socketFunctions);
    this.setSocket(s);

    window.setTimeout(() => {
      if (!this.props.isSupportAgent) {
        this.emitToSocket('join', '', this.props.projectId);

      }
    }, 500);

    return () => s.off('help-desk', this.socketFunctions)
  }

  addChat = (data: IMsg) => {

    let container = this.state.chatList;
    let c = container.filter(x => x.roomId === data.roomId)[0] ?? { roomId: data.roomId, messages: [], name: data.name, isOpen: true };
    if (data.personId !== this.props.personId && data.name !== 'system') c.name = data.name;
    c.messages.push(data);


    if (this.state.chatList.find(x => x.roomId !== data.roomId)) {

    }
    else {
      let container = this.state.chatList.filter(x => x.roomId !== data.roomId);
      container.push(c);
    }

    this.setChatList(container);

    window.setTimeout(() => {

      const el = window.document.getElementById(`message-tap${data.roomId}`);
      if (el) {
        el.scrollTop = el.scrollHeight;
      }

    }, 200);
  };

  setChatHistory = (roomId: string, dataArray: IMsg[]) => {

    let c = this.state.chatList.filter(x => x.roomId === roomId)[0] ?? { roomId: roomId, messages: [], name: '', isOpen: true };
    c.messages = [];
    dataArray.forEach(x => {
      if (!c.name || c.name === 'system') c.name = x.name;
      c.messages.push(x);
    });

    let container = this.state.chatList.filter(x => x.roomId !== roomId);
    container.push(c);
    this.setChatList(container);

    window.setTimeout(() => {

      const el = window.document.getElementById(`message-tap${roomId}`);
      if (el) {
        el.scrollTop = el.scrollHeight;
      }

    }, 200);
  };

  socketFunctions = (data: IMsg) => {
    console.log(`help-desk `, data);
    switch (data.type) {
      case 'chat':
        this.addChat(data);

        if (!this.props.isSupportAgent) {
          console.log(this.state.isOpen);
          this.openChat();
        }
        //this.setMessageTape([...this.state.messageTape, data]);
        break;
      case 'room-list':
        let rooms: IMsg[] = [];
        let hasRoom = {};

        if (data.rooms) {
          data.rooms.forEach(x => {
            let room = JSON.parse(x);

            if (room && !hasRoom[room.roomId]) {
              rooms.push(room);
              hasRoom[room.roomId] = true;
            }
          });
        }

        this.setSupportChats(rooms);
        break;
      case 'request':
        let chats = this.state.supportChats.filter(x => x.personId !== data.personId);
        let exists = chats.length !== this.state.supportChats.length;
        chats.push(data);
        this.setSupportChats(chats);
        if (!exists) this.addChat(data);
        break;
      case 'echo':

        break;
      case 'subscribe':
        if (data.message === 'On') {
          this.setIsSubscribed(true);
        } else {
          this.setIsSubscribed(false);
        }
        break;
      case 'join':
        data.message = `${data.name} has joined.`;
        data.name = `system`;
        this.addChat(data);

        break;
      case 'removed':
        let chatsToKeep = this.state.supportChats.filter(x => x.personId !== data.personId || x.roomId == data.roomId);
        this.setSupportChats(chatsToKeep);
        break;
      case 'history':
        let dataArray: IMsg[] = [];
        if (data.history) data.history.forEach(d => {
          dataArray.push(JSON.parse(d));
        });
        //sort data...
        //dataArray = dataArray.sort((x)=>{});

        this.setChatHistory(data.roomId, dataArray);
        break;
    }
  };

  emitToSocket = (type: messageType, message: string, roomId: string | null) => {
    let msg = {
      type,
      message,
      roomId: roomId,
      personId: this.props.personId,
      projectId: this.props.projectId,
      name: this.props.name,
    }
    this.state.socket.emit('intro', {});
    this.state.socket.emit('help-desk', msg);
    console.log('emit help-desk', msg);
  };

  openChat = () => {
    this.setRoomId(this.props.projectId);
    //this.emitToSocket('request', 'I need help.', this.props.projectId || '...');
    this.setActiveRoom(this.props.projectId || '...');
    this.setIsOpen(true);
    this.setChatList([...this.state.chatList]);
  };

  joinChat = (chat: IMsg) => {
    this.emitToSocket('join', 'joining', chat.roomId);
    this.setIsOpen(true);
    this.setActiveRoom(chat.roomId);
    let theseChats = this.state.chatList.map(c => c.roomId === chat.roomId ? {...c, isOpen: true} : c);//.filter(x=>x.name);
    this.setChatList(theseChats);

  };

  closeChat = (roomId: string) => {
    this.setIsOpen(false);
    let theseChats = this.state.chatList.map(chat => chat.roomId === roomId ? {...chat, isOpen: false} : chat);
    this.setChatList(theseChats);
    console.log('Close window...', theseChats);

    //this.setIsOpen(false);
  };

  closeThisChat = (roomId: string) => {
    if (window.confirm(`Are you sure you want to close this support ticket?`)) {
      this.emitToSocket('removed', '', roomId);
      this.setIsOpen(false);
    }
  }

  sendMessage = (roomId: string, msg: string) => {
    // const msg = this.state.currentMessage;
    // this.setCurrentMessage('');

    if (msg && msg.length > 0) {
      this.emitToSocket('chat', msg, roomId);
    }
  };

  sendClearRoom = (roomId: string) => {
    if (window.confirm(`Are you sure you want to clear this chat history?`)) {
      this.emitToSocket('clear', '', roomId);
    }
  }

  sendClearRoomList = () => {
    if (window.confirm(`Are you sure you want to clear the waiting list?`)) {
      this.emitToSocket('clear-room-list', '', '');
    }
  };



  onEnterPress = (e, roomId: string) => {
    // console.log(e.key);
    // console.log(e.KeyCode);
    if ((e.key === "Enter" || (e.key === "NumpadEnter")) && e.shiftKey === false) {
      e.preventDefault();
      this.sendMessage(roomId, e.target.value);
      e.target.value = '';
    }
  }

  subScribeToSupport = (on: boolean) => {
    this.emitToSocket('subscribe', on ? 'On' : 'Off', null);
  };

  render() {


    return (
      <>
        {this.props.isSupportAgent &&
          <div className="support-chat-subscribe">
            <div className="header">
              <h3><i className="fad fa-user-headset"></i> Support Chat</h3>
              <div>
                {this.state.isSubscribed ?
                  <span className="click text-success" onClick={() => { this.subScribeToSupport(false) }}><i className="fad fa-toggle-on"></i> Active</span>
                  : <span className="click" onClick={() => { this.subScribeToSupport(true) }}><i className="fad fa-toggle-off"></i> Not Active</span>}
              </div>
            </div>
            <div className="subscribe-body">
              {this.state.isSubscribed && <div className="support-chats">
                {this.state.supportChats && this.state.supportChats.length > 0 ? <>
                  {this.state.supportChats.map((x: IMsg, i: number) => {
                    const isActive = this.state.chatList.filter(c => c.roomId === x.roomId )?.[0]?.isOpen;
                    return (
                      <div 
                        key={`support-chat-${i}`} 
                        className={`
                          support-chat 
                          ${isActive ? 'active' : ''}
                          `} 
                        onClick={() => { this.joinChat(x); }} 
                      >
                        {x.name}
                      </div>
                    )
                  })}

                  <div>

                    <i className="fad fa-eraser click" title="clear chat list" onClick={() => { this.sendClearRoomList() }} ></i>
                  </div>
                </>
                  : <div className="alert alert-info">No active chats</div>}
              </div>
              }

            </div>
          </div>
        }
        {!this.state.isOpen 
          && !this.props.isSupportAgent 
          && (
            <div 
              className="support-chat-open-btn click" 
              onClick={this.openChat} 
            >
              <i className="fad fa-user-headset"></i> Support
            </div>
          )
        }
        {this.state.isOpen 
          && !this.props.isSupportAgent 
          && (
            <div 
              className="support-chat-open-btn click" 
              onClick={this.openChat} 
            >
              <i className="fad fa-user-headset"></i> 
            </div>
          )
        }

        <div className={`${this.props.isSupportAgent ? 'support-agent' : ''} right here...${this.state.chatList.length}`}>
          HEy I"m here
          {this.state.isOpen &&
            this.state.chatList.map((c: IChatContainer, ci: number) => {
              
              return (
                  <IndividualChat
                    key={`${c.roomId}-${ci}`}
                    c={c}
                    ci={ci}
                    isSupportAgent={this.props.isSupportAgent}
                    closeThisChat={this.closeThisChat}
                    sendClearRoom={this.sendClearRoom}
                    closeChat={this.closeChat}
                    personId={this.props.personId ?? ''}
                    onEnterPress={this.onEnterPress}
                  />
                )
            })

          }</div>
      </>
    )
  }
}

export default SupportChats